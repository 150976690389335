<div class="container" [ngClass]="{'expand': expand}" *ngIf="!isPrintMode">
    <div tid="note_detail_attachment_clip_count" class="count">
        <smart-icon name="attachment"></smart-icon>
        <div class="number">&nbsp;{{attachments.length}}</div>
    </div>
    <div class="attachment-tags" kendoTooltip position="bottom" tooltipClass="tooltip-adjust-right">
        <div tid="note_detail_attachment" class="attachment-tag" *ngFor="let attach of attachments;trackBy:trackByFn">
            <smart-icon [name]="getIconName(attach.extension)" class="file-type"></smart-icon>
            <div class="filename" [title]="attach.fileName" (click)="onFileAction(attach)">
                <span [ngClass]="{'highlight': attach.highlight }">{{attach.fileName}}</span>
            </div>
            <smart-icon name="meatball" class="light" (click)="onShowContextMenu($event, attach)">
            </smart-icon>
        </div>
    </div>
    <tam-context-menu tid="note_detail_attachment_menu" [config]="contextMenuConfig"></tam-context-menu>
    <div tid="note_detail_attachment_expand" class="toggle-area" [ngClass]="{'display': expandable}">
        <smart-icon [name]="expand ? 'angle-down' : 'angle-up'" (click)="onToggle()"></smart-icon>
    </div>

</div>
<!-- for print note -->
<div *ngIf="isPrintMode">
    <span>Attachments:&nbsp;</span>
    <span *ngFor="let attach of attachments; last as isLast">
        {{attach.fileName}}{{isLast ? '':', '}}
    </span>
</div>