<div class="container tam-form-field" [ngClass]="{'has-error':invalid||requireInvalid}">
    <div class="tam-form-label label-area">
        <field-label [label]="config.label||config.field.fieldDefinition.name"
            [tooltip]="config.description||config.field.fieldDefinition.description" [isRequired]="config.required"
            labelClassList="field-name body-3 text-ellipsis"></field-label>
    </div>
    <textarea class="height-per-rows"
        [ngClass]="{'tam-textbox':config.editable,'field-control':config.editable,'field-value':!config.editable,'body-2':!config.editable,'field-disabled':config.disabled||!config.editable}"
        [disabled]="!config.editable||config.disabled" [(ngModel)]="config.field.value" [maxlength]="config.maxlength"
        [ngStyle]="{'height': (config.maxlines * 32) + 'px'}" (keyup)="valueChange($event)"></textarea>
    <div class="tam-form-help" *ngIf="config.editable&&invalid&&config.minlength">
        {{'general.text_character_allowed' | stringLiterals: config.minlength: config.maxlength}}
    </div>
    <div class="tam-form-help" *ngIf="config.editable&&requireInvalid&&config.required">
        {{'general.required_error_message' | stringLiterals}}
    </div>
</div>