/**
 * Created by Jiu Chen on 12/03/2018.
 * Description:
 *
 * ------ maintenance history ------
 */

import { RelationshipTypeActions, RelationshipTypeActionTypes } from '../actions/relationship-type.actions';
import { AppState } from '..';

export const relationshipTypeSelector = (state: AppState) => state.relationshipType;

export function relationshipTypeReducer(state: any = [], action: RelationshipTypeActions) {
    switch (action.type) {
        case RelationshipTypeActionTypes.INITIALIZE_SUCCESS:
            return action.payload;
        case RelationshipTypeActionTypes.INITIALIZE_FAILED:
            return action.payload;
        default:
            return state;
    }
}
