/**
 * Created by Yu Zhang on 8/11/17.
 * Description:
 *
 * ------ maintenance history ------
 * modified by Todd Yu on 07/07/2020
 * Added Admin Page Entity Types support for entity type requests
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';

@Injectable()
export class EntityTypeService {
    constructor(
        private _transportService: TransportService
    ) { }

    getDefaultEntityType(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entityTypeEndpoint}`;
        const params = {
            getdefaulttype: true,
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getAvailableList() {
        const url = `${AppConfig.entityTypeEndpoint}`;
        const params = {
            sortby: 'name',
            sortorder: 'asc',
            outputformat: 'json'
        };
        const options = {
            params: params
        };
        return this._transportService.get(url, options);
    }

    getFullList() {
        const url = `${AppConfig.entityTypeEndpoint}`;
        const params = {
            showall: true,
            sortby: 'name',
            sortorder: 'asc',
            outputformat: 'json'
        };
        const options = {
            params: params
        };
        return this._transportService.get(url, options);
    }

    getAdminPageEntityList(): Observable<any> {
        const url = '/restapi/2.1/entity-type/?showall=true';
        return this._transportService.get(url);
    }

    createNewEntityType(name: string, body: any): Observable<any> {
        const url = `${AppConfig.entityTypeEndpoint}`;
        const params = {
            'name': name,
            'default-entity': body['default-entity-type'] ? body['default-entity-type'] : false,
            'hidden-types': !body['display-web']
        };
        const options = {
            params: params
        };
        return this._transportService.post(url, body, options);
    }

    deleteEntityType(Id: string): Observable<any> {
        let url = `${AppConfig.entityTypeEndpoint}`;
        url = url + '/' + Id;
        return this._transportService.delete(url);
    }

    editEntityType(name: string, Id: string, body: any): Observable<any> {
        let url = `${AppConfig.entityTypeEndpoint}`;
        url = url + '/' + Id;
        const params = {
            'name': name,
            'default-entity': body['default-entity-type'] ? body['default-entity-type'] : false,
            'hidden-types': body['display-web'] === undefined ? false : !body['display-web']
        };
        const options = {
            params: params
        };
        return this._transportService.put(url, body, options);
    }

}
