/**
 * Created by Alan Yang on 3/16/2017
 * Description:
 *   add TSGuid Type to be consistent with Tamale system
 *
 * ---------maintenance history-------------
 */
export class TSGuid {

    constructor(str?: string) {
        this.str = str || TSGuid.getNewGUIDString();
    }
    private str: string;

    private static getNewGUIDString() {
        // ex: http://stackoverflow.com/a/8809472/188246
        let d = new Date().getTime();
        if (window && window.performance && typeof window.performance.now === 'function') {
            d += performance.now();
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    toString() {
        return this.str;
    }
}
