/**
 * Created by Abner Sui on 04/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 * Marcus add mdl MarketData request 05/22/2019
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { Marketdata } from '../models/marketdata.model';
import { MdlProvider } from '../../components/template/template-control-config.model';
import { map } from 'rxjs/operators';

@Injectable()
export class MarketdataService {
    constructor(
        private _transportService: TransportService
    ) { }

    getAll(): Observable<any> {
        const url = `${AppConfig.marketdataEndPoint}`;
        return this._transportService.get(url);
    }

    parseResponse(response): Array<Marketdata> {
        const result: Array<Marketdata> = [];
        response.forEach(oneResponse => {
            result.push(Marketdata.parse(oneResponse));
        });
        return result;
    }

    getMdlData(entityId: String, mdlProvider: any): Observable<any> {
        const url = `${AppConfig.marketdataEndPoint}` + mdlProvider.providername + '?field=' + entityId
            + '&column=' + mdlProvider.mdlfieldname;
        return this._transportService.get(url);
    }

    /**
     * Queries the value on the datasource related to the given entity.
     * @param provider the MDL provider related to this query.
     * @param entityId the entity id to which the data source row is related.
     * @returns the value of the specified column of the matched row of data source.
     */
    getWebMDLData(provider: MdlProvider, entityId: string): Observable<string> {
        let url = `${AppConfig.marketdataEndPoint}${provider.providername}?field=${entityId}&column=${provider.mdlfieldname}`;
        if (MdlProvider.isWebSource(provider)) {
            // only append this parameter for provider configured on RC.
            url += '&source=web';
        }

        return this._transportService.get(url).pipe(map(r => r && r['value']));
    }
}
