/**
 * Created by Abner Sui on 02/20/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Subject } from 'rxjs';
import { RefinebyConfigType } from '../../components/calendar-refineby/calendar-refineby.model';

export enum RefinebyType {
    date, object, entity, text, priority,
}

export enum RefinebyGroup {
    standard, adhoc,
}

export class RefinebyConfig {
    actions$: Subject<any>;
    events$: Subject<any>;
    displayConfig: Array<RefinebySubConfig>;
    addConfig: Array<RefinebySubConfig>;
    category: Array<string>;
    categoryFilter: Object;
    selectedValue: Map<string, Array<any>>;
    type: string;

    constructor() {
        this.actions$ = new Subject();
        this.events$ = new Subject();
        this.displayConfig = [];
        this.addConfig = [];
        this.category = [];
        this.categoryFilter = {};
        this.selectedValue = new Map();
        this.type = RefinebyConfigType.entry;
    }

}

export class RefinebyStatus {
    collapsed = false;
    showMore = false;
    showAsName = false;
}

export class RefinebySubConfig {
    categoryId: string;
    group: RefinebyGroup;
    name: string;
    type: RefinebyType;
    options: Array<any>;
    selectedOptions: Array<any>;
    status: RefinebyStatus;
    defaultIndex: number;
    isSearching: boolean;

    constructor(categoryId: string, name: string, group: RefinebyGroup = RefinebyGroup.standard, type: RefinebyType = RefinebyType.object) {
        this.categoryId = categoryId;
        this.name = name;
        this.group = group;
        this.type = type;
        this.status = new RefinebyStatus();
        this.options = [];
        this.selectedOptions = [];
        this.defaultIndex = null;
        this.isSearching = false;
    }
}

export class RefinebyObject {
    id: string;
    name: string;
    shortName: string;
    longName: string;
    count: number;
    checked = false;
}

export class RefinebyText {
    value: string;
    count: number;
    checked = false;
}

export class RefinebyPriority {
    id: string;
    count: number;
    checked = false;
}

export const RefinebyOptionLimit = 5;
export const AddRefinebyListLimit = 10;

// Below is only used between refineby and xxxRefineby
export enum RefinebyWidgetAction {
    SELECTED_OPTION_CHANGE = 'SELECTED_CHANGE',
    INPUT_CHANGE = 'INPUT_CHANGE',
    STATUS_CHANGE = 'STATUS_CHANGE',
    RESET_CATEGORY = 'RESET_CATEGORY',
    CHANGE_CATEGORY_ORDER = 'CHANGE_CATEGORY_ORDER',
    SHOW_AS_NAME = 'SHOW_AS_NAME',
    CLEAR_ALL = 'CLEAR_ALL',
    HIDE_ADD_LIST = 'HIDE_ADD_LIST',
    SET_STATUS = 'SET_STATUS',
}

// Below is only used between refineby and refineby-xxx
export enum RefinebySubWidgetAction {
    SELECTED_OPTION_CHANGE = 'SELECTED_CHANGE',
    INPUT_CHANGE = 'INPUT_CHANGE',
    STATUS_CHANGE = 'STATUS_CHANGE',
    SEARCH = 'SEARCH',
    SEARCH_RESULT = 'SEARCH_RESULT',
    CLICK_MEATBALL = 'CLICK_MEATBALL',
    CHANGE_STATUS = 'CHANGE_STATUS',
}
