/**
 * Created by lucas wang on 08/25/2021.
 * Description:
 * UsersEditor Component
 * ------ maintenance history ------
 * Modified by Cathy Yao on 10/12/2021: removed userCredential Array
 */

export const UserEditorConstants = {
    Credentials: {
        ComplianceCredentials: 'Tamale Compliance Credential',
        ManageTeamCredentials: 'Manage Team Credentials',
        ComplianceCredentialName: 'Compliance Credentials'
    },
    User: {
        Password: 'defaultPassword'
    },

    pageConfigs: [
        {
            page: 0,
            title: 'Create a user from an existing contact',
            primaryText: 'Next',
            secondaryText: ''
        },
        {
            page: 1,
            title: 'User Details',
            primaryText: 'Save',
            secondaryText: 'Previous: Search Contact'
        },
        {
            page: 2,
            title: 'Edit User',
            primaryText: 'Update',
            secondaryText: 'Cancel'
        }
    ]
};
