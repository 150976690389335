/**
 * Created by Daniel on 05/14/2019.
 * Description:
 * dashboard reducer
 * ------ maintenance history ------
*/
import { combineReducers, createSelector } from '@ngrx/store';
import { DashboardScreenActions, DashboardScreenActionTypes } from '../actions/dashboard-screen.actions';

export function focusReducer(state: any = {}, action: DashboardScreenActions) {
    switch (action.type) {
        case DashboardScreenActionTypes.INITIALIZE_FOCUS:
            return Object.assign({}, action.payload);
        default:
            return state;
    }
}

export const getDashboardScreenState = (state: any = {}) => state;
export const getFocusState = createSelector(getDashboardScreenState, (state: any) => Object.assign({}, state.focus));

const dashboardScreenReducer = combineReducers({
    focus: focusReducer
});

export function getDashboardScreenRedcuer(state, action) {
    return dashboardScreenReducer(state, action);
}
