/**
 * created by Yu Zhang on 11/16/17.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe {
    constructor(private sanitizer: DomSanitizer) { }

    transform(htmlString: string) {
        return this.sanitizer.bypassSecurityTrustHtml(htmlString);
    }
}
