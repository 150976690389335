import * as _ from 'lodash';

import { ExchangeActions, ExchangeActionTypes } from './../actions/exchange.actions';
import { GlobalConfigActions, GlobalConfigActionTypes } from '../actions/global-config.actions';
import { SystemConfig, SsoSetting } from '../../models/global-setting';
import { IntralinksAdminVisibilityChange, INTRALINKS_ACTIONS } from '../../tamalelibs/redux/intralinks.actions';
import { CHANGE_MAP_TILE_STATUS, ChangeMapTileStatusAction } from '../../tamalelibs/redux/map-tile.actions';
import { WorkflowActionTypes, WorkflowAdminVisibilityChange } from '../../tamalelibs/redux/actions/workflow.actions';
import { businessConstants } from '../../constants/business.constants';
import { AdminPageActionTypes, UpdateDefaultSidenoteTypeSuccess } from '../actions/admin-page.actions';

export function ssoReducer(state: SsoSetting, action: GlobalConfigActions) {
    switch (action.type) {
        case GlobalConfigActionTypes.SSO_SET:
            return action.payload;
        default:
            return state;
    }
}

export function systemConfigReducer(state: SystemConfig, action: GlobalConfigActions | IntralinksAdminVisibilityChange | ChangeMapTileStatusAction | WorkflowAdminVisibilityChange | ExchangeActions | UpdateDefaultSidenoteTypeSuccess) {
    switch (action.type) {
        case GlobalConfigActionTypes.SYSTEM_CONFIG_SET:
            return action.payload;
        case CHANGE_MAP_TILE_STATUS:
            const newState_map = _.cloneDeep(state);
            newState_map.maptile.enable = action.payload.enable;
            return newState_map;
        case INTRALINKS_ACTIONS.ADMIN_VISIBILITY_CHANGE:
            if (action.payload.isManually) {
                const newState = _.cloneDeep(state);
                let part = newState;
                let l = 0;
                if (action.payload && Array.isArray(action.payload.path)) {
                    l = action.payload.path.length;
                }
                for (let i = 0; part && i < l - 1; i++) {
                    part = part[action.payload.path[i]];
                }
                if (l > 0) {
                    part[action.payload.path[l - 1]] = action.payload.value.enable;
                }
                newState.intralinks = action.payload.value;
                newState.triggerType = businessConstants.globalConfig.intralinks;
                newState['isOnlyEnable'] = action.payload.isOnlyEnable;
                return newState;
            }
            break;
        case WorkflowActionTypes.ADMIN_VISIBILITY_CHANGE:
            if (action.payload.isManually) {
                const tempState = _.cloneDeep(state);
                let data = tempState;
                let len = 0;
                if (action.payload && Array.isArray(action.payload.path)) {
                    len = action.payload.path.length;
                }
                for (let i = 0; data && i < len - 1; i++) {
                    data = data[action.payload.path[i]];
                }
                if (len > 0) {
                    data[action.payload.path[len - 1]] = action.payload.value;
                }
                tempState.triggerType = businessConstants.globalConfig.workflow;
                return tempState;
            }
            break;
        case ExchangeActionTypes.CHANGE_EXCHANGE_STATUS_SUCCESS:
            const newState_exchange = _.cloneDeep(state);
            newState_exchange.exchange.enable = action.payload;
            return newState_exchange;
        // set autoSyncSetConfig to systemConfig in store.
        case ExchangeActionTypes.CHANGE_EXCHANGE_SYNC_STATUS_SUCCESS:
            const newState_exchange_sync = _.cloneDeep(state);
            newState_exchange_sync.autoSyncSetConfig.enable = action.payload;
            return newState_exchange_sync;
        case AdminPageActionTypes.UPDATE_DEFAULT_SIDENOTE_TYPE_SUCCESS:
            const updareDefaultSidenoteTypeState = _.cloneDeep(state);
            updareDefaultSidenoteTypeState.entryConfig = action.payload;
            return updareDefaultSidenoteTypeState;
        default:
            return state;
    }
}
