/**
 * Created by Alex Xia on 3/3/2022.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';

import { AppConfig } from '../tamalelibs/models/app-config.model';
import { OfficeAttachment, OfficeAddIn } from '../tamalelibs/models/office-addin.model';
import { TransportService } from '../tamalelibs/services/transport.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { businessConstants } from '../../app/constants/business.constants';
import { AddInUtilities } from './utilities/addin-utilities';
import { of as observableOf } from 'rxjs';
import { MailBoxItemType } from '../components/note-dialog/note-dialog.model';

@Injectable()
export class OfficeAddinService {
    constructor(
        private _deviceService: DeviceDetectorService,
        private _transportService: TransportService,
    ) { }

    static isWebOffice() {
        return AddInUtilities.isOfficeJSLoaded() && Office.context.platform === Office.PlatformType.OfficeOnline ? true : false;
    }

    depositAddInFile(officeAddIn: OfficeAddIn, fileContent: string, attachment: OfficeAttachment): Observable<any> {
        // don't need to call rest api if token expired to avoid browser authentication alert
        if (!AppConfig.token) {
            return observableOf();
        }

        const url = AppConfig.fileEndpoint;
        const params: any = {
            outputformat: 'json'
        };
        const payload = new FormData();
        payload.append('attachment', attachment.isBase64 ? this._b64toBlob(fileContent, attachment.contentType, 512) : (officeAddIn.itemType === MailBoxItemType.Appointment) ? new Blob([fileContent], { type: 'text/calendar' }) : new Blob([fileContent]), attachment.fileName);
        const options = {
            params: params,
            // There is no separate timeout period and the default is one minute. This is not enough for large files，Talk with pm,set the timeout period for uploading files to 5 minutes.
            timeout: AppConfig.uploadRestapiTimeoutSpan
        };
        return this._transportService.post(url, payload, options);
    }

    getContactByPrimaryEmail(primaryEmail: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.contactEndpoint}?filterby=primary-email&filtertype=exact&page=1&rpp=1&filterstring=${primaryEmail}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     * get email content via http request
     * @param url
     * @param token
     * @returns
     */
    getEmailContent(url: string, token: string, isAppointment: boolean): Observable<string> {
        if (isAppointment) {
            return new Observable(function (observer) {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                xhr.setRequestHeader('Accept', 'application/json;odata.metadata=none');
                xhr.responseType = 'json';
                xhr.onload = function () {
                    if (xhr.status >= 200 && xhr.status < 400) {
                        observer.next(xhr.response);
                        observer.complete();
                    }
                };

                xhr.send();
            });
        } else {
            return new Observable(function (observer) {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);

                xhr.onload = function () {
                    if (xhr.status >= 200 && xhr.status < 400) {
                        observer.next(xhr.responseText);
                        observer.complete();
                    }
                };

                xhr.send();
            });
        }
    }

    /**
     *  App and web Office add-in both use Safari browser on Mac book
     */
    isSafariOrFireFoxBrowser() {
        return (Office.context.platform === Office.PlatformType.Mac) || ((Office.context.platform === Office.PlatformType.OfficeOnline) && (this._deviceService.browser === businessConstants.officeAddIn.browserType.Safari || this._deviceService.browser === businessConstants.officeAddIn.browserType.Firefox));
    }

    private _b64toBlob(b64Data: string, contentType: string, sliceSize: number) {
        contentType = contentType || 'image/png';
        sliceSize = sliceSize || 512;
        const byteArrays = [];

        if (b64Data) {
            const byteCharacters = atob(b64Data);
            if (byteCharacters && byteCharacters.length > 0) {
                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);

                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }
            }
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
