<div class="container">
    <field-config-base #base [config]="config" [dataType]="dataType"></field-config-base>
    <div tid="data_source" class="tam-form-field one-field"
        [ngClass]="{'has-error':sourceInvalid || relationshipInvalid}">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.data_source' | stringLiterals}}&nbsp;<span class="required-field">*</span>
            </div>
        </div>
        <div class="tam-textbox field-control single-special-control">
            <div class="entity-types">
                <label tam-checkbox disabled="true" ngModel="true" class="control-label body-1"><span
                        class="entrytype-content">{{'general.select_entity_types_to_include' |
                        stringLiterals}}&nbsp;</span>
                </label>
                <multi-sel-dropdown-filter class="control-content" [dropdownConfig]='dropdownConfig'>
                    <ng-template #multiCustomContent>
                        <div class="selected-content text-ellipsis" (click)='onClickSource($event)'>{{sourceName}}</div>
                    </ng-template>
                </multi-sel-dropdown-filter>
            </div>
            <div class="relationships"><label tam-checkbox class="control-label body-1"
                    [(ngModel)]="isRelationshipChecked" (click)="onRelationshipCheckedChange($event)"><span
                        class="relationship-content" [ngClass]="{'not-active':!isRelationshipChecked}">Only show
                        entities who are&nbsp;</span></label>
                <tam-single-select-dropdown-filter class="control-content" [dropdownConfig]="relationshipTypeConfig">
                    <ng-template #customContent>
                        <a class="selected-content text-ellipsis" [ngClass]="{'not-active':!isRelationshipChecked}"
                            (click)="onRelationshipTypeItemClick($event)">{{relationshipType}}</a>
                    </ng-template>
                </tam-single-select-dropdown-filter>
            </div>
            <div class="relationships"><label class="body-1"
                    [ngClass]="{'not-active':!isRelationshipChecked}">&nbsp;of&nbsp;</label>
                <tam-single-select-dropdown-filter class="control-content" [dropdownConfig]="customFieldsConfig">
                    <ng-template #customContent>
                        <a class="selected-content text-ellipsis" [ngClass]="{'not-active':!isRelationshipChecked}"
                            (click)="onCustomFieldsItemClick($event)">{{customFields}}</a>
                    </ng-template>
                </tam-single-select-dropdown-filter>
            </div>
        </div>
        <div class="tam-form-help" *ngIf="sourceInvalid || relationshipInvalid">
            {{'general.required_error_message' | stringLiterals}}
        </div>
    </div>
    <div class="tam-form-field one-field">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.default_value' | stringLiterals}}
            </div>
        </div>
        <kendo-combobox #staticDefaultValuekendo class="tam-textbox field-control"
            [popupSettings]="{appendTo:'component'}" [data]="staticDefaultValueDataSource|async" textField="shortName"
            valueField="id" [loading]="staticDefaultValueLoading" [listHeight]="210" [filterable]="true"
            [disabled]="config.source.length===0 || config.isTriggerEntityAsDefaultEnabled || config.isCurrentUserAsDefaultEnabled"
            [(ngModel)]="config.staticDefaultValue" (valueChange)="valueChangeStaticDefaultValue($event)"
            (focus)="focusStaticDefaultValue()" (filterChange)="filterStaticDefaultValue($event)"
            (blur)="blurStaticDefaultValue()">
            <ng-template kendoComboBoxItemTemplate let-dataItem>
                <tam-entity-dropdown-item [entity]="dataItem"></tam-entity-dropdown-item>
            </ng-template>
        </kendo-combobox>
        <label class="field-control" tam-checkbox [(ngModel)]="config.isTriggerEntityAsDefaultEnabled"
            [disabled]="config.isCurrentUserAsDefaultEnabled">
            {{'general.use_trigger_entity_default' | stringLiterals}}
        </label>
        <label class="field-control" tam-checkbox [(ngModel)]="config.isCurrentUserAsDefaultEnabled"
            [disabled]="config.isTriggerEntityAsDefaultEnabled">
            {{'general.use_current_user_default' | stringLiterals}}
        </label>
    </div>
</div>