/**
 * Created by Daniel on 3/14/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum HomeScreenActionTypes {
    LOAD = '[HOMESCREEN] LOAD',
    LOAD_SUCCESS = '[HOMESCREEN] LOAD_SUCCESS',
    LOAD_FAILED = '[HOMESCREEN] LOAD_FAILED',
    SHARE_LAYOUT = '[HOMESCREEN] SHARE_LAYOUT',
    CHANGE_LAYOUT = '[HOMESCREEN] CHANGE_LAYOUT',
    DELETE = '[HOMESCREEN] DELETE',
    DELETE_SUCCESS = '[HOMESCREEN] DELETE_SUCCESS',
    DELETE_FAILED = '[HOMESCREEN] DELETE_FAILED',
    CLEAN_UP = '[HOMESCREEN] CLEAN_UP'
}

export class Load implements Action {
    readonly type = HomeScreenActionTypes.LOAD;

    constructor(public payload: any) { }
}

export class LoadSuccess implements Action {
    readonly type = HomeScreenActionTypes.LOAD_SUCCESS;

    constructor(public payload: any) { }
}

export class LoadFailed implements Action {
    readonly type = HomeScreenActionTypes.LOAD_FAILED;

    constructor(public payload: any) { }
}

export class ShareLayout implements Action {
    readonly type = HomeScreenActionTypes.SHARE_LAYOUT;

    constructor(public payload: any) { }
}

export class ChangeLayout implements Action {
    readonly type = HomeScreenActionTypes.CHANGE_LAYOUT;

    constructor(public payload: any) { }
}

export class Delete implements Action {
    readonly type = HomeScreenActionTypes.DELETE;

    constructor(public payload: any) { }
}

export class DeleteSuccess implements Action {
    readonly type = HomeScreenActionTypes.DELETE_SUCCESS;

    constructor(public payload: any) { }
}

export class DeleteFailed implements Action {
    readonly type = HomeScreenActionTypes.DELETE_FAILED;

    constructor(public payload: any) { }
}

export class CleanUp implements Action {
    readonly type = HomeScreenActionTypes.CLEAN_UP;
    constructor() { }
}

export type HomeScreenActions = Load | LoadSuccess | LoadFailed | ShareLayout | ChangeLayout | Delete | DeleteSuccess | DeleteFailed | CleanUp;
