/**
 * Created by Simon Zhao on 7/19/2021.
 * Description:
 * responsible for handling transitions from one state to the next state for MDL Template state.
 * ------ maintenance history ------
 */

import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { IMDLTemplate } from '../../tamalelibs/models/template-mdl.model';
import { CreateOrUpdate, CreateOrUpdateFailed, MDLTemplateActions, MDLTemplateActionTypes } from '../actions/mdl-template.actions';

export const mdlTemplateSelector = (state: AppState) => state.mdlTemplates;
export const allMDLTemplatesSelector = createSelector(mdlTemplateSelector, (state: Map<string, IMDLTemplate>) => state);
export const singleMDLTemplateSelector = createSelector(mdlTemplateSelector,
    (state: Map<string, IMDLTemplate>, props: { typeId: string }) => !!state && 'get' in state ? state.get(props.typeId) : null);

export function mdlTemplateReducer(state: any = {}, action: MDLTemplateActions) {
    switch (action.type) {
        case MDLTemplateActionTypes.INITIALIZE_SUCCESS:
            return action.payload;
        case MDLTemplateActionTypes.INITIALIZE_FAILED:
            return action.payload;
        case MDLTemplateActionTypes.CREATE_OR_UPDATE: {
            const newState = new Map<string, IMDLTemplate>(state);
            const createAction = action as CreateOrUpdate;
            if (createAction) {
                newState.set(createAction.typeId, createAction.payload);
            }

            return newState;
        }
        case MDLTemplateActionTypes.CREATE_OR_UPDATE_FAILED: {
            const createAction = action as CreateOrUpdateFailed;
            const newState = new Map<string, IMDLTemplate>(state);
            if (newState.has(createAction.payload.typeId)) {
                newState.delete(createAction.payload.typeId);
            }

            return newState;
        }
        default:
            return state;
    }
}
