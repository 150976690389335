/**
 * Created by Alex on 16/07/19.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PluginService {
    public isPlugInEditNoteMode = false;
    public pluginSubject$ = new Subject();
}
