/**
 * Created by
 * Description:
 * ------ maintenance history ------
 * Remove  getContactTemplateValueSetState to contatct.reducer.ts
 */
import { combineReducers, createSelector, Action } from '@ngrx/store';
import { AppState } from '..';
import { recentNotesReducer } from '../../tamalelibs/redux/thread-list.reducer';
import {
    contactReducer, contactStatusReducer, contactMsgReducer, slideSheetContactReducer,
    slideSheetContactMsgReducer, contactTemplateValueSetReducer
} from '../../tamalelibs/redux/contact.reducer';

export const getContactDetailViewState = (state: AppState) => state.contactDetailView;
export const getContactState = createSelector(getContactDetailViewState, (state: any) => state.contact);
export const getContactMsgState = createSelector(getContactDetailViewState, (state: any) => state.contactMsg);
export const getSlideSheetContactState = createSelector(getContactDetailViewState, (state: any) => state.sideSheetContact);
export const getSlideSheetContactMsgState = createSelector(getContactDetailViewState, (state: any) => state.slideSheetContactMsg);
export const getRecentNotesState = createSelector(getContactDetailViewState, (state: any) => state.recentNotes);
export const getContactStatus = createSelector(getContactDetailViewState, (state: any) => state.contactStatus);

const contactDetailViewReducer = combineReducers({
    contact: contactReducer,
    sideSheetContact: slideSheetContactReducer,
    slideSheetContactMsg: slideSheetContactMsgReducer,
    recentNotes: recentNotesReducer,
    contactStatus: contactStatusReducer,
    contactMsg: contactMsgReducer,
});

export function getContactDetailViewRedcuer(state, action) {
    return contactDetailViewReducer(state, action);
}
