/**
 * Created by Abner Sui on 01/29/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { combineReducers, createSelector, Action } from '@ngrx/store';
import { DataList } from '../../tamalelibs/models/data-list.model';
import { BlastEmail } from '../../tamalelibs/models/blastemail.model';
import { BlastEmailViewBlastEmailListActionTypes, BlastEmailViewActionTypes, BlastEmailViewActions, BlastEmailViewBlastEmailListActions, BlastEmailRefinebyActionTypes, BlastEmailRefinebyActions } from '../actions/blastemail-view.actions';

export function blastEmailListItemsReducer(state: DataList<BlastEmail>, action: BlastEmailViewBlastEmailListActions) {
    switch (action.type) {
        case BlastEmailViewBlastEmailListActionTypes.RELOAD_SUCCESS:
            return action.payload.blastEmails;
        case BlastEmailViewBlastEmailListActionTypes.LOAD_MORE_SUCCESS: {
            // below logic is used to prevent duplicated data in the list
            // the basic logic is load previous page with the next page and doing data comparaion
            if (action.payload.pageIndex === 2) {
                return action.payload.blastEmails;
            } else {
                const blastEmails = Object.assign(new DataList<BlastEmail>(), state);
                blastEmails.splice((action.payload.pageIndex - 2) * action.payload.pageSize, action.payload.pageSize); // remove the previous page

                // generate set from existing liste
                const blastEmailMap = new Map();
                blastEmails.forEach((blastEmail, index) => {
                    blastEmailMap.set(blastEmail.id, index);
                });

                action.payload.blastEmails.forEach((blastEmail: BlastEmail) => {
                    if (blastEmailMap.has(blastEmail.id)) {
                        return;
                    }
                    blastEmails.push(blastEmail);
                });

                blastEmails.totalCount = action.payload.blastEmails.totalCount;
                blastEmails.hasNext = action.payload.blastEmails.hasNext;

                return blastEmails;
            }
        }
        case BlastEmailViewBlastEmailListActionTypes.RELOAD_FAILED:
            return new DataList<BlastEmail>();
        default:
            return state;
    }
}

export function blastEmailListErrorReducer(state: any, action: BlastEmailViewBlastEmailListActions) {
    switch (action.type) {
        case BlastEmailViewBlastEmailListActionTypes.RELOAD_FAILED:
        case BlastEmailViewBlastEmailListActionTypes.LOAD_MORE_FAILED:
            return action.payload;
        default:
            return state;
    }
}

export function blastEmailListSelectedIndexReducer(state = null, action: BlastEmailViewBlastEmailListActions) {
    switch (action.type) {
        case BlastEmailViewBlastEmailListActionTypes.RELOAD_SUCCESS:
            if (action.payload && action.payload.blastEmails && action.payload.blastEmails.length > 0) {
                return {
                    index: 0,
                    id: action.payload.blastEmails[0].id,
                };
            } else {
                return null;
            }
        case BlastEmailViewBlastEmailListActionTypes.SET_SELECTED_INDEX:
            return action.payload;
        default:
            return state;
    }
}

export function blastEmailListMatchedAttachmentsReducer(state: Set<string> = new Set(), action: BlastEmailViewBlastEmailListActions) {
    switch (action.type) {
        case BlastEmailViewBlastEmailListActionTypes.RELOAD_SUCCESS:
            return action.payload.matchedAttachmentSet;
        case BlastEmailViewBlastEmailListActionTypes.LOAD_MORE_SUCCESS:
            const newState = Object.assign(new Set(), state);
            action.payload.matchedAttachmentSet.forEach(id => {
                newState.add(id);
            });
            return newState;
        default:
            return state;
    }
}

export function blastEmailListLayoutReducer(state = null, action: BlastEmailViewBlastEmailListActions) {
    switch (action.type) {
        case BlastEmailViewBlastEmailListActionTypes.SET_LAYOUT_COLLAPSE:
            return action.payload;
        default:
            return state;
    }
}


export function blastEmailListCurrentPageIndexReducer(state = 0, action: BlastEmailViewBlastEmailListActions) {
    switch (action.type) {
        case BlastEmailViewBlastEmailListActionTypes.RELOAD:
        case BlastEmailViewBlastEmailListActionTypes.LOAD_MORE:
            return action.payload.pageIndex;
        default:
            return state;
    }
}

const blastEmailListReducer = combineReducers({
    currentPageIndex: blastEmailListCurrentPageIndexReducer,
    items: blastEmailListItemsReducer,
    matchedAttachmentSet: blastEmailListMatchedAttachmentsReducer,
    selectedIndex: blastEmailListSelectedIndexReducer,
    error: blastEmailListErrorReducer,
    layout: blastEmailListLayoutReducer
});

export function getBlastEmailListReducer(state, action) {
    return blastEmailListReducer(state, action);
}

export function queryInfoReducer(state: any = {}, action: BlastEmailViewActions) {
    switch (action.type) {
        case BlastEmailViewActionTypes.SET_QUERY_INFO:
            return action.payload;
        default:
            return state;
    }
}

export function keywordReducer(state: string = '', action: BlastEmailViewActions) {
    switch (action.type) {
        case BlastEmailViewActionTypes.SET_KEY_WORD:
            return action.payload;
        default:
            return state;
    }
}

export function sortInfoReducer(state: any = { sortBy: 'time', sortOrder: 'desc' }, action: BlastEmailViewActions) {
    switch (action.type) {
        case BlastEmailViewActionTypes.SET_SORT_INFO:
            return action.payload;
        default:
            return state;
    }
}

export function aggridColumnStatusReducer(state: any = {}, action: BlastEmailViewActions) {
    switch (action.type) {
        case BlastEmailViewActionTypes.SET_AGGRID_COLUMN_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export function actionMessageReducer(state: string = '', action: Action) {
    switch (action.type) {
        case BlastEmailViewBlastEmailListActionTypes.RELOAD:
        case BlastEmailViewBlastEmailListActionTypes.RELOAD_SUCCESS:
        case BlastEmailViewBlastEmailListActionTypes.LOAD_MORE:
        case BlastEmailViewBlastEmailListActionTypes.LOAD_MORE_SUCCESS:
        case BlastEmailViewBlastEmailListActionTypes.SET_SELECTED_INDEX:
        case BlastEmailViewBlastEmailListActionTypes.DELETE_BLASTEMAIL:
        case BlastEmailViewBlastEmailListActionTypes.DELETE_BLASTEMAIL_SUCESS:
        case BlastEmailViewBlastEmailListActionTypes.DELETE_BLASTEMAIL_FAILED:
        case BlastEmailViewActionTypes.GET_BLASTEMAIL_BODY:
        case BlastEmailViewActionTypes.GET_BLASTEMAIL_BODY_SUCCESS:
        case BlastEmailRefinebyActionTypes.LOAD:
        case BlastEmailRefinebyActionTypes.LOAD_SUCCESS:
        case BlastEmailRefinebyActionTypes.SET_CATEGORY_ORDER:
        case BlastEmailRefinebyActionTypes.SET_SELECTED_VALUE:
        case BlastEmailRefinebyActionTypes.SET_CATEGORY:
        case BlastEmailRefinebyActionTypes.SET_CATEGORY_STATUS:
            return action.type;
        default:
            return state;
    }
}

export function refinebyDataReducer(state: Map<string, Array<any>>, action: BlastEmailRefinebyActions) {
    switch (action.type) {
        case BlastEmailRefinebyActionTypes.LOAD_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function refinebySelectedValueReducer(state: Map<string, Array<any>>, action: BlastEmailRefinebyActions) {
    switch (action.type) {
        case BlastEmailRefinebyActionTypes.SET_SELECTED_VALUE:
            return action.payload.selectedValue;
        default:
            return state;
    }
}

export function refinebyStatusReducer(state: Map<string, Array<any>>, action: BlastEmailRefinebyActions) {
    switch (action.type) {
        case BlastEmailRefinebyActionTypes.SET_CATEGORY_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export function refinebyCategoryReducer(state: Array<string>, action: BlastEmailRefinebyActions) {
    switch (action.type) {
        case BlastEmailRefinebyActionTypes.SET_CATEGORY_ORDER:
            return action.payload;
        case BlastEmailRefinebyActionTypes.SET_CATEGORY:
            return action.payload;
        default:
            return state;
    }
}

export function refinebyCategoryFilterReducer(state: Array<string>, action: BlastEmailRefinebyActions) {
    switch (action.type) {
        case BlastEmailRefinebyActionTypes.SET_SELECTED_VALUE:
            return action.payload.categoryFilter;
        default:
            return state;
    }
}

const refinebyReducer = combineReducers({
    data: refinebyDataReducer,
    selectedValue: refinebySelectedValueReducer,
    status: refinebyStatusReducer,
    category: refinebyCategoryReducer,
    categoryFilter: refinebyCategoryFilterReducer,
});

export function getRefinebyReducer(state, action) {
    return refinebyReducer(state, action);
}

export const getBlastEmailViewState = (state: any) => state;
export const getBlastEmailListState = createSelector(getBlastEmailViewState, (state: any) => state.blastEmailList);
export const getBlastEmailListItemsState = createSelector(getBlastEmailListState, (state: any) => state.items);
export const getBlastEmailListCurrentPageIndexState = createSelector(getBlastEmailListState, (state: any) => state.currentPageIndex);
export const getBlastEmailListMatchedAttachmentState = createSelector(getBlastEmailListState, (state: any) => state.matchedAttachmentSet);
export const getBlastEmailListSelectedIndex = createSelector(getBlastEmailListState, (state: any) => state.selectedIndex);
export const getActiveBlastEmailState = createSelector(getBlastEmailListItemsState, getBlastEmailListSelectedIndex, (state1: any, state2: any) => {
    if (state1 && state1.length > 0 && state2) {
        return state1[state2.index];
    } else {
        return null;
    }
});
export const getQueryInfoState = createSelector(getBlastEmailViewState, (state: any) => state.queryInfo);
export const getKeywordState = createSelector(getBlastEmailViewState, (state: any) => state.keyword);
export const getSortInfoState = createSelector(getBlastEmailViewState, (state: any) => state.sortInfo);
export const getActionMessageState = createSelector(getBlastEmailViewState, (state: any) => state.actionMessage);
export const getFocusState = createSelector(getBlastEmailViewState, (state: any) => Object.assign({}, state.focus));
export const getAGGridColumnState = createSelector(getBlastEmailViewState, (state: any) => state.aggridColumnStatus);
export const getBlastemailRefinebyState = createSelector(getBlastEmailViewState, (state: any) => state.refineby);
export const getBlastemailRefinebyDataState = createSelector(getBlastemailRefinebyState, (state: any) => state.data);
export const getBlastemailRefinebySelectedValueState = createSelector(getBlastemailRefinebyState, (state: any) => state.selectedValue);
export const getBlastemailRefinebyStatusState = createSelector(getBlastemailRefinebyState, (state: any) => state.status);
export const getBlastemailRefinebyCategoryState = createSelector(getBlastemailRefinebyState, (state: any) => state.category);
export const getBlastemailRefinebyCategoryFilterState = createSelector(getBlastemailRefinebyState, (state: any) => state.categoryFilter);

const blastEmailViewReducer = combineReducers({
    actionMessage: actionMessageReducer,
    keyword: keywordReducer,
    queryInfo: queryInfoReducer,
    sortInfo: sortInfoReducer,
    blastEmailList: getBlastEmailListReducer,
    aggridColumnStatus: aggridColumnStatusReducer,
    refineby: getRefinebyReducer,
});

export function getBlastEmailViewRedcuer(state, action) {
    return blastEmailViewReducer(state, action);
}
