/**
 * Created by Alex on 8/10/20.
 * Description:
 *
 * ------ maintenance history ------
 */

export const GET_AUTO_TAG_MODEL = 'GET_AUTO_TAG_MODEL';
export const GET_AUTO_TAG_MODEL_SUCCESS = 'GET_AUTO_TAG_MODEL_SUCCESS';
export const GET_AUTO_TAG_MODEL_FAIL = 'GET_AUTO_TAG_MODEL_FAIL';
export const CHANGE_AUTO_TAG_STATUS = 'CHANGE_AUTO_TAG_STATUS';
export const CHANGE_AUTO_TAG_STATUS_SUCCESS = 'CHANGE_AUTO_TAG_STATUS_SUCCESS';
export const CHANGE_AUTO_TAG_STATUS_FAIL = 'CHANGE_AUTO_TAG_STATUS_FAIL';
export const CHANGE_AUTO_TAG_STATUS_FOR_LIVEWIRE = 'CHANGE_AUTO_TAG_STATUS_FOR_LIVEWIRE';
export const CHANGE_AUTO_TAG_STATUS_FOR_LIVEWIRE_SUCCESS = 'CHANGE_AUTO_TAG_STATUS_FOR_LIVEWIRE_SUCCESS';
export const CHANGE_AUTO_TAG_STATUS_FOR_LIVEWIRE_FAIL = 'CHANGE_AUTO_TAG_STATUS_FOR_LIVEWIRE_FAIL';
export const EDIT_AUTO_TAG_SETTINGS = 'EDIT_AUTO_TAG_SETTINGS';
export const EDIT_AUTO_TAG_SETTINGS_SUCCESS = 'EDIT_AUTO_TAG_SETTINGS_SUCCESS';
export const EDIT_AUTO_TAG_SETTINGS_FAIL = 'EDIT_AUTO_TAG_SETTINGS_FAIL';
export const CREATE_AUTO_TAG_MODEL = 'CREATE_AUTO_TAG_MODEL';
export const CREATE_AUTO_TAG_MODEL_SUCCESS = 'CREATE_AUTO_TAG_MODEL_SUCCESS';
export const CREATE_AUTO_TAG_MODEL_FAIL = 'CREATE_AUTO_TAG_MODEL_FAIL';

