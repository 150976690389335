import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { AgGridModule } from 'ag-grid-angular';

// pipes
import { NumberToArrayPipe } from '../tamalelibs/pipes/number.pipe';
import {
    TodayOrPastDatePipe, TodayOrPastFullDatePipe, TodayOrPastShortDatePipe, TodayOrPastShortFullDatePipe,
    TodayOrPastSimpleDatePipe, MmDdYyyySlashNoPaddingPipe, TodayOrPastSimpleDateWithShortMonthPipe
} from '../tamalelibs/pipes/date.pipe';
import { KeywordPipe, EntityTypePipe } from '../tamalelibs/pipes/keyword.pipe';
import { SafeHtmlPipe } from '../pipes/html.pipe';

// components
import { ButtonComponent } from './button/button.component';
import { SmartIconComponent } from './smart-icon/smart-icon.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DualListComponent } from './dual-list/dual-list.component';
import { EntityTagComponent } from './entity-tag/entity-tag.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { PriorityStarComponent } from './priority-star/priority-star.component';
import { PriorityStarAggridComponent } from './priority-star-aggrid/priority-star-aggrid.component';
import { RadioComponent } from './radio/radio.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TabsComponent } from './tabs/tabs.component';
import { FormsModule } from '@angular/forms';
import { AlertWindowComponent } from './alert-window/alert-window.component';
import { TamContextMenuComponent } from './tam-context-menu/tam-context-menu.component';
import { ToastComponent } from './toast/toast.component';
import { NotificationComponent } from './notification/notification.component';
import { TamDialogComponent } from './tam-dialog/tam-dialog.component';
import { TamSplitterComponent } from './tam-splitter/tam-splitter.component';
import { TamSplitterBarComponent } from './tam-splitter/tam-splitter-bar.component';
import { TamSplitterPanelComponent } from './tam-splitter/tam-splitter-panel.component';
import { TamUploadComponent } from './tam-upload/tam-upload.component';
import { RatingComponent } from './rating/rating.component';
import { TamTextFilterComponent } from './filter-item/tam-text-filter.component';
import { TamEntitiesFilterComponent } from './filter-item/tam-entities-filter.component';
import { TamDropdownFilterComponent } from './filter-item/tam-dropdown-filter.component';
import { TamPriorityFilterComponent } from './filter-item/tam-priority-filter.component';
import { TamMultiPopupFilterComponent } from './filter-item/tam-multi-popup-filter.component';
import { MultiSelDropdownFilterComponent } from './multi-sel-dropdown-filter/multi-sel-dropdown-filter.component';
import { TamDateFilterComponent } from './filter-item/tam-date-filter.component';
import { EntityDropdownItemComponent } from './entity-dropdown-item/entity-dropdown-item.component';
import { TamEntityFilterComponent } from './filter-item/tam-entity-filter.component';
import { TimelineComponent } from './timeline/timeline.component';
import { GridComponent } from './grid/grid.component';
import { RefinebyComponent } from './refineby/refineby.component';
import { RefinebyDateComponent } from './refineby/refineby-date.component';
import { RefinebyObjectComponent } from './refineby/refineby-object.component';
import { RefinebyPriorityComponent } from './refineby/refineby-priority.component';
import { FilterPanelComponent } from './filter-item/filter-panel/filter-panel.component';
import { TabSetComponent } from './tab-set/tab-set.component';
import { TabComponent } from './tab-set/tab.component';
import { KeywordSearchComponent } from './keyword-search/keyword-search.component';
import { WizardMenuComponent } from './wizard-menu/wizard-menu.component';
import { ColumnPanelComponent } from './column-panel/column-panel.component';
import { FormulaBuilderComponent } from './formula-builder/formula-builder.component';
import { FieldSelectorComponent } from './field-selector/field-selector.component';
import { PdffilePreviewComponent } from './pdffile-preview/pdffile-preview.component';
import { HtmlContainerComponent } from './html-container/html-container.component';
import { DownloadAggridComponent } from './download-aggrid/download-aggrid.component';
import { TimeSelectDropdownComponent } from './time-select-dropdown/time-select-dropdown.component';
import { SplitButtonComponent } from './split-button/split-button.component';
import { TamRelationshipFilterComponent } from './filter-item/tam-relationship-filter.component';

// directives
import { TamDropzoneDirective } from './tam-dropzone/tam-dropzone.directive';
import { DomChangeDirective } from './dom-change/dom-change.directive';
import { DropDownOutsideDirective } from './multi-sel-dropdown-filter/drop-down-outside.directive';
import { TabDragDirective } from './tab-set/tab-drag.directive';
import { DynamicTooltipDirective } from './dynamic-tooltip/dynamic-tooltip.directive';
import { TamAddEntityFilterComponent } from './filter-item/tam-add-entity-filter/tam-add-entity-filter.component';
import { MiddleEllipsisComponent } from './middle-ellipsis/middle-ellipsis.component';

import { SelectWindowComponent } from './select-window/select-window.component';
import { ImgPreviewComponent } from './img-preview/img-preview.component';
import { TamContextMenuKendoComponent } from './tam-context-menu/tam-context-menu-kendo.component';
import { SingleSelectDropdownFilterComponent } from './single-select-dropdown-filter/single-select-dropdown-filter.component';
import { SingleEntityDropdownFilterComponent } from './single-entity-dropdown-filter/single-entity-dropdown-filter.component';
import { TamCountryStateFilterComponent } from './filter-item/tam-country-state-filter/tam-country-state-filter.component';
import { GroupBoxComponent } from './group-box/group-box.component';
import { TamCustomGridColumnFilterComponent } from './ag-grid-column-filter/custom-grid-column-filter.component';
import { TamCustomGridCheckboxComponent } from './ag-grid-column-filter/custom-grid-checkbox.component';
import { TamCustomDateColumnFilterComponent } from './ag-grid-column-filter/custom-date-column-filter.component';
import { CustomGridSortPipe } from './ag-grid-column-filter/custom-grid-column.pipe';
import { TamCustomGroupRowInnerRenderer } from './ag-grid-column-filter/custom-group-row-inner-renderer.component';
import { FieldLabelComponent } from './field-label/field-label.component';
import { StringLiteralsPipe } from '../pipes/translate.pipe';
import { ButtonModule } from './button/button.module';
import { ProgressComponent } from './progress/progress.component';
import { AggregationalGridComponent } from './aggregational-grid/aggregational-grid.component';
import { MultipleFilesRendererComponent } from './ag-grid/multiple-files-renderer.component';
import { CustomActionColumnComponent } from './ag-grid/custom-action-column.component';

const widgetsComponents = [
    AlertWindowComponent,
    CheckboxComponent,
    TamDateFilterComponent,
    DualListComponent,
    EntityTagComponent,
    LoadingSpinnerComponent,
    NotificationComponent,
    PriorityStarComponent,
    PriorityStarAggridComponent,
    RadioComponent,
    SpinnerComponent,
    SmartIconComponent,
    TabsComponent,
    TamContextMenuComponent,
    TamContextMenuKendoComponent,
    TamDialogComponent,
    FilterPanelComponent,
    TamTextFilterComponent,
    TamEntitiesFilterComponent,
    TamDropdownFilterComponent,
    TamPriorityFilterComponent,
    TamMultiPopupFilterComponent,
    MultiSelDropdownFilterComponent,
    DropDownOutsideDirective,
    TamDateFilterComponent,
    TamSplitterComponent,
    TamSplitterBarComponent,
    TamSplitterPanelComponent,
    TamUploadComponent,
    TamDropzoneDirective,
    DynamicTooltipDirective,
    ToastComponent,
    DomChangeDirective,
    RatingComponent,
    EntityDropdownItemComponent,
    TamEntityFilterComponent,
    TamRelationshipFilterComponent,
    TamAddEntityFilterComponent,
    TimelineComponent,
    GridComponent,
    RefinebyComponent,
    RefinebyDateComponent,
    RefinebyObjectComponent,
    RefinebyPriorityComponent,
    TabSetComponent,
    TabComponent,
    TabDragDirective,
    KeywordSearchComponent,
    WizardMenuComponent,
    ColumnPanelComponent,
    FormulaBuilderComponent,
    FieldSelectorComponent,
    PdffilePreviewComponent,
    HtmlContainerComponent,
    DownloadAggridComponent,
    TimeSelectDropdownComponent,
    MiddleEllipsisComponent,
    SelectWindowComponent,
    TimeSelectDropdownComponent,
    ImgPreviewComponent,
    SingleSelectDropdownFilterComponent,
    SingleEntityDropdownFilterComponent,
    TamCountryStateFilterComponent,
    GroupBoxComponent,
    TamCustomGridColumnFilterComponent,
    TamCustomGridCheckboxComponent,
    TamCustomDateColumnFilterComponent,
    TamCustomGroupRowInnerRenderer,
    SplitButtonComponent,
    FieldLabelComponent,
    ProgressComponent,
    SingleSelectDropdownFilterComponent,
    SingleEntityDropdownFilterComponent,
    AggregationalGridComponent,
    MultipleFilesRendererComponent,
    CustomActionColumnComponent

];
const widgetsUsedPipes = [
    NumberToArrayPipe,
    TodayOrPastDatePipe,
    TodayOrPastSimpleDatePipe,
    TodayOrPastFullDatePipe,
    TodayOrPastShortDatePipe,
    TodayOrPastShortFullDatePipe,
    TodayOrPastSimpleDateWithShortMonthPipe,
    MmDdYyyySlashNoPaddingPipe,
    KeywordPipe,
    EntityTypePipe,
    SafeHtmlPipe,
    CustomGridSortPipe,
    StringLiteralsPipe,
];

@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        DropDownsModule,
        PopupModule,
        FormsModule,
        ContextMenuModule,
        DialogModule,
        InputsModule,
        DateInputsModule,
        AgGridModule,
        TooltipModule,
        TreeViewModule
    ],
    declarations: [
        ...widgetsComponents,
        ...widgetsUsedPipes,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ContextMenuModule,
        ButtonComponent,
        ...widgetsComponents,
        ...widgetsUsedPipes,
    ],
})
export class WidgetsModule { }
