/**
 * ------ maintenance history ------
 * updated by Alan Yang on 2019.10.12
 * moved some modules from App into feature modules
 */
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import 'ag-grid-enterprise';
import 'hammerjs';
// Froala must add this js. Issue link https://github.com/froala/angular-froala-wysiwyg/issues/305
import 'froala-editor/js/plugins.pkgd.min.js';

import { REDUCER_TOKEN, getRootReducers, clearState } from './redux';
import { ReduxDevToolsService } from './app-redux-devtools';

import { AppRoutingModule } from './app-routing.module';

/* App Root */
import { AppComponent } from './app.component';

// services
import { LoggerService } from './tamalelibs/services/logger.service';
import { AppLoadService } from './app-load.service';

import { LoginModule } from './pages/login/login.module';
import { StorageService } from './services/storage.service';
import { AuthService } from './tamalelibs/services/auth.service';
import { TransportService } from './tamalelibs/services/transport.service';
import { IExceptionHandler } from './tamalelibs/services/exception-handler.interface.service';
import { IFileDownloader } from './tamalelibs/services/file-downloader.interface.service';
import { INetworkErrorHandler } from './tamalelibs/services/network-error-handler.interface.service';
import { ILoggerInstanceService } from './tamalelibs/services/logger-instance.interface.service';
import { ContactService } from './tamalelibs/services/contact.service';
import { AdvFilterHelperService } from './tamalelibs/services/adv-filter-helper.service';
import { PlatformService } from './services/platform.service';
import { AuthHelperService } from './services/auth-helper.service';
import { AutoTagDialogService } from './components/admin-pages/admin-page-content/autotag-model-dialog/autotag-model-dialog.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormulaService } from './services/formula-service';
import { PrintModule } from './pages/print/print.module';
import { environment } from '../environments/environment';
import { PlatformRedirectionService } from './services/platform-redirection.service';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { AppConfig } from './tamalelibs/models/app-config.model';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        // angular modules
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LoginModule,
        ReduxDevToolsService.modulePerEnvironment,
        AppRoutingModule,
        PrintModule,
        HammerModule,
        TooltipModule,
        EffectsModule.forRoot([]),
        // after upgrade ngrx/store to 15, immutable issue will cause many changes, so set it to false
        StoreModule.forRoot(REDUCER_TOKEN, {
            metaReducers: [clearState],
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
    ],
    providers: [
        AdvFilterHelperService,
        AuthHelperService,
        AuthService,
        AutoTagDialogService,
        ContactService,
        DatePipe,
        FormulaService,
        ILoggerInstanceService,
        IExceptionHandler,
        IFileDownloader,
        INetworkErrorHandler,
        LoggerService,
        PlatformRedirectionService,
        PlatformService,
        StorageService,
        TransportService,
        {
            provide: LocationStrategy,
            useClass: environment.isOfficeAddin ? HashLocationStrategy : PathLocationStrategy
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initSsoValue,
            multi: true,
            deps: [
                AppLoadService,
                AuthHelperService
            ]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: checkToken,
            multi: true,
            deps: [
                AppLoadService
            ]
        },
        {
            provide: REDUCER_TOKEN,
            useFactory: getRootReducers,
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}

export function initSsoValue(appLoadService: AppLoadService, authHelper: AuthHelperService) {
    return () => {
        AppConfig.token = authHelper.getToken();
        if (AppConfig.token) {
            appLoadService.initSsoSetting();
        } else {
            appLoadService.initSsoValue();
        }
    };
}

export function checkToken(appLoadService: AppLoadService) {
    return () => appLoadService.checkLocalToken();
}
