import { GridOptions } from 'ag-grid-community';
import { ResearchView } from '../../constants/business.constants';

export const DEFAULT_PAGE_SIZE = 100;

export const GRID_OPTIONS: GridOptions = {
    defaultColDef: {
        sortable: true,
        resizable: true
    },
    suppressCellFocus: true,
    enableCellChangeFlash: true,
    enableBrowserTooltips: true,
    paginationPageSize: DEFAULT_PAGE_SIZE,
    cacheBlockSize: ResearchView.cacheBlockSize,
    colResizeDefault: 'shift' as 'shift', // Ensure colResizeDefault is assigned the correct type
    rowSelection: 'single',
    sortingOrder: ['desc', 'asc'],
    maintainColumnOrder: true,
    suppressDragLeaveHidesColumns: true,
    suppressRowGroupHidesColumns:true,
    overlayNoRowsTemplate: '<span style=\'font-size: 20px;background-color:white;color:black\'>No Data Found</span>'
};

export const Grid_ORDER = {
    DESC: 'desc',
    ASC: 'asc'
};
