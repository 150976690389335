import { EntryClass } from './entry-class.model';
import { EntryType } from './entry-type.model';
import { DocumentEntry } from './document-entry.model';
import { EntityBrief } from './entity-brief.model';
import { Priority } from './priority.model';
import { Sentiment } from './sentiment.model';
import { AdhocField } from './adhoc-field.model';

export class NoteEntry {
    blurb?: string;
    calculatedSubject?: string;
    deletable?: boolean;
    displayDate?: Date;
    entryClass?: EntryClass;
    editable?: boolean;
    entities?: Array<EntityBrief>;
    id: string;
    lastEditDate?: Date;
    priority?: Priority;
    source?: EntityBrief;
    subject?: string;
    submittedDate?: Date;
    submitter?: EntityBrief;
    sentiment?: Sentiment;
    type?: EntryType;
    // above properties should be inherited from Entry model

    adhocs?: Map<string, AdhocField>;
    attachments: Map<string, DocumentEntry>;
    body?: string;
    backdated?: boolean;
    threadPosition = 0;
    totalNotesInThread = 0;

    // obsolete properites; DO NOT USE!!!
    adHocTable: string; // obselete; Only used in old native code, DO NOT use it in Web.
    attachmentIds: string[] = [];
    // attachments: TSDictionary<DocumentEntry> = new TSDictionary<DocumentEntry>();
    bodyLink = '';

    constructor() {
        this.attachments = new Map();
        this.body = '';
        this.entities = [];
        this.entryClass = EntryClass.Note;
        this.adhocs = new Map();
    }

    static parse(serverResponse): NoteEntry {
        const entry = new NoteEntry();

        entry.backdated = serverResponse['is-backdated'] === true;
        entry.blurb = serverResponse.blurb;
        entry.calculatedSubject = serverResponse.calculatedTitle;
        entry.deletable = serverResponse.deletable;
        entry.displayDate = new Date(serverResponse['display-date']);
        entry.editable = serverResponse.editable;
        entry.entities = serverResponse.entities.data.map(item => EntityBrief.parse(item.data));
        entry.id = serverResponse.id;
        entry.lastEditDate = new Date(serverResponse['last-edited-date']);
        entry.priority = serverResponse.priority;
        entry.source = EntityBrief.parse(serverResponse.source.data);
        entry.subject = serverResponse.title;
        entry.submittedDate = new Date(serverResponse['submitted-date']);
        entry.submitter = EntityBrief.parse(serverResponse.submitter.data);
        entry.sentiment = serverResponse.sentiment;
        entry.threadPosition = serverResponse['thread-position'];
        entry.totalNotesInThread = serverResponse['thread-total'];
        entry.type = EntryType.parse(serverResponse['entry-type']);
        const entryClassString = serverResponse['entry-class'];
        switch (entryClassString) {
            case 'event':
                entry.entryClass = 0;
                break;
            case 'note':
                entry.entryClass = 1;
                break;
            case 'file':
                entry.entryClass = 2;
                break;
        }

        if (serverResponse.attachments && serverResponse.attachments.data) {
            serverResponse.attachments.data.forEach(element => {
                const documentEntry = DocumentEntry.parse(element);
                if (!documentEntry.isEmbeddedImg) {
                    entry.attachments.set(documentEntry.id, documentEntry);
                }
            });
        }

        if (serverResponse.properties && serverResponse.properties.data) {
            serverResponse.properties.data.forEach(element => {
                const adhoc = AdhocField.parse(element);
                if (adhoc) {
                    entry.adhocs.set(adhoc.id, adhoc);
                }
            });
        }

        // obsolete code
        entry.bodyLink = serverResponse.body ? serverResponse.body.link.href : ''; // Only used by iPhone App


        return entry;
    }
}
