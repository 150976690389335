/**
 * Created by Angela Stanchie on 3/14/18.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NotificationOptions } from '../notification/notification.model';
import { ToastService } from './toast.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'tam-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {
    @Input() isAddInMode: string;
    notifications$: BehaviorSubject<NotificationOptions[]> = new BehaviorSubject([]);
    private _subscriptions: Subscription[] = [];
    constructor(private _service: ToastService) {
    }
    ngOnInit(): void {
        this._subscriptions.push(
            this._service._toasts$.pipe(
                filter(action => action.type === 'TOAST'),
            ).subscribe((action: { type: string, payload: NotificationOptions[], }) => {
                this.notifications$.next(action.payload);
            }),
            this._service._toasts$.pipe(
                filter(action => action.type === 'CLEAR'),
                debounceTime(3000), // Notificaion will dispear after 3000, so here will clear it after 3000
            ).subscribe((action: { type: string, payload: NotificationOptions[], }) => {
                this.notifications$.next([]);
            }),
        );
    }
    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }
}
