/**
 * Created by Marcus Zhao on 08/03/2021.
 * Description: country and state filter
 *
 * ------ maintenance history ------
 */
import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FiltersConfig } from '../../../tamalelibs/components/filter-config';
import { FilterWidgetConfig, FilterWidgetFeedback, FilterWidgetFeedbackType } from '../../../tamalelibs/components/filter-widget.config';
import { DefaultWordCites } from '../../../tamalelibs/constants/DefaultWorldCities.constants';
import { FilterWidgetAciontType } from '../tam-text-filter.component';

@Component({
    selector: 'tam-country-state-filter',
    templateUrl: './tam-country-state-filter.component.html',
    styleUrls: ['../tam-filter-container.component.scss']
})
export class TamCountryStateFilterComponent implements OnInit {

    @Input() config: FiltersConfig; // data
    @Input() filterWidgetConfig: FilterWidgetConfig; // event

    defaultItem: { name: string, value: string };
    operators: Array<{ name: string, value: string }>;
    isItemHoverOn = false;
    isCheckBlank = true;
    // Declaration type causes combox error
    selectItemsCountry;
    selectItemsState;
    selItemCountry;
    selItemState;

    constructor(private _deviceService: DeviceDetectorService) { }

    ngOnInit() {
        this.operators = this.config.operators;
        this.defaultItem = this.config.operators.find(item => item.value === this.config.selectedOperator);
        this.selectItemsCountry = this.config.source;
        this.isCheckBlank = (this.defaultItem.value.toLocaleLowerCase().indexOf('blank') > -1) || (this.defaultItem.value.toLocaleLowerCase().indexOf('null') > -1);
        // set last edit value for country state.
        this.selItemCountry = this.config.value && this.config.value[0] ? this.config.value[0] : null;
        this._setStateData(this.selItemCountry);
        this.selItemState = this.config.value && this.config.value[1] ? this.config.value[1] : null;
        this._feedback(FilterWidgetAciontType.TEXT_CHANGE, this.selItemCountry, this.selItemState);
        // For use within normal web clients
        if (this._deviceService.isMobile() || this._deviceService.isTablet()) {
            this.isItemHoverOn = true;
        } else {
            this.isItemHoverOn = !this.config.deletable;
        }
    }
    /**
     * Country select
     *
     * @memberof TamCountryStateFilterComponent
     */
    onCountrySelect() {
        const selValues: Array<string> = this.selItemCountry;
        this.selItemState = null;
        this._setStateData(selValues);
        this._feedback(FilterWidgetAciontType.TEXT_CHANGE, selValues, 0);
    }

    /**
     * State select
     *
     * @memberof TamCountryStateFilterComponent
     */
    onStateSelect() {
        const selValues: Array<string> = this.selItemState;
        this._feedback(FilterWidgetAciontType.TEXT_CHANGE, selValues, 1);
    }

    onMouseOver() {
        if (!this.config.deletable) {
            this.isItemHoverOn = true;
        }
    }

    onMouseOut() {
        if (!this.config.deletable) {
            this.isItemHoverOn = false;
        }
    }

    onBtnCloseClicked() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.remove;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    /**
     * operator value change
     *
     * @param {{ name: string, value: string }} changeItem
     * @memberof TamCountryStateFilterComponent
     */
    valueChange(changeItem: { name: string, value: string }): void {
        this.isCheckBlank = changeItem.value.toLocaleLowerCase().indexOf('null') !== -1 || changeItem.value.toLocaleLowerCase().indexOf('blank') !== -1;
        // emits event for value change
        this._feedback(FilterWidgetAciontType.SELECTOR_CHANGE, changeItem.value);
    }

    /**
     * set all value by action . why use valueTypeIndex.due to this control include two values.
     *
     * @private
     * @param {FilterWidgetAciontType} type
     * @param {*} value
     * @param {*} [valueTypeIndex]
     * @memberof TamCountryStateFilterComponent
     */
    private _feedback(type: FilterWidgetAciontType, value: any, valueTypeIndex?) {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.update;
        if (!this.config.value) {
            this.config.value = [];
        }
        if (FilterWidgetAciontType.SELECTOR_CHANGE === type) {
            this.config.selectedOperator = value;
        } else if (FilterWidgetAciontType.TEXT_CHANGE === type && valueTypeIndex === 0) {
            this.config.value[0] = value;
            if (!this.config.value[0]) {
                delete this.config.value[1];
            }
        } else if (FilterWidgetAciontType.TEXT_CHANGE === type && valueTypeIndex === 1) {
            this.config.value[1] = value;
        }

        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    /**
     * set State data by country change
     * @private
     * @param {*} item
     * @memberof ContactAddressComponent
     */
    private _setStateData(item) {
        const cityList = DefaultWordCites.filter(data => data.country === item);
        this.selectItemsState = Array.from(new Set(cityList.map(data => data.subcountry).sort()));
        this.selectItemsState.splice(0, 0, '');
    }
}
