/**
 * Created by Cathy on 09/23/2021.
 * Description:
 * ------ maintenance history ------
 * Updated by Daniel Wang on 1/15/2024 added street events
 */

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { AdminPageActionsComponent } from '../../components/admin-pages/admin-page-actions/admin-page-actions.component';
import { AdminPageWidgetComponent } from '../../components/admin-pages/admin-page-content/admin-page-widget/admin-page-widget.component';
import { adminPagesConstants } from '../../components/admin-pages/admin-page-setting.constants';
import { EntityTypesSubjectTypes, NoteTypesSubjectTypes } from '../../components/admin-pages/admin-page-setting.model';
import { AdminPageTypes } from '../../redux/reducers/admin-page.reducer';
import { AppConfig } from '../models/app-config.model';
import { DateHelperService } from './date-helper.service';
import { TransportService } from './transport.service';
import { ScheduledJobs, Freq, adminPageType, ETLTask, LocalPythonScript, TokenManagement, WebSites, GeneralGridType, Abbreviation, FMROverrides, StopList } from '../models/admin-page.model';
import { ArrayHelperService } from './array-helper.service';

@Injectable()
/** Main Admin Page Service */
export class AdminPagesService {
    //#region usage report
    exportToExcel$: Subject<any> = new Subject();
    //#endregion

    //#region constructor
    constructor(
        private _dateHelperService: DateHelperService,
        private _datepipe: DatePipe,
        private _transportService: TransportService) {
    }
    //#endregion

    //#region public functions
    //#region common functions
    dynamicallySetColumnDef(item, headerData): any[] {
        const cellStyleHandler = () => {
            return {
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis',
                'display': 'block',
                'border': 'none'
            };
        };
        const firstRowkeys = Object.keys(item);
        const colDefs = [];
        if (headerData) {
            const headerKeys = Object.keys(headerData);
            headerKeys.forEach(key => {
                colDefs.push({
                    headerName: headerData[key.toLowerCase()],
                    field: key,
                    colId: key,
                    tooltipValueGetter: (params) => params.value,
                    enableRowGroup: true,
                    menuTabs: [],
                    sortable: true,
                    comparator: (a, b) => {
                        if (typeof a === 'string') {
                            return a.localeCompare(b);
                        } else {
                            return (a > b ? 1 : (a < b ? -1 : 0));
                        }
                    },
                    cellStyle: cellStyleHandler
                });
            });
        } else {
            firstRowkeys.forEach((key) => colDefs.push({
                headerName: key,
                field: key,
                colId: key,
                tooltipValueGetter: (params) => params.value,
                enableRowGroup: true,
                menuTabs: [],
                sortable: true,
                comparator: (a, b) => {
                    if (typeof a === 'string') {
                        return a.localeCompare(b);
                    } else {
                        return (a > b ? 1 : (a < b ? -1 : 0));
                    }
                },
                cellStyle: cellStyleHandler
            }));
        }
        return colDefs;
    }

    /**
     * get the key by the value
     * @param obj the object
     * @param value the value
     * @returns
     */
    findKeyByValue(obj, value) {
        for (const key in obj) {
            if (obj[key] === value) {
                return key;
            }
        }
        return null;
    }
    //#endregion

    //#region users/notetypes/entitytypes
    getGridColumnDefsByType(type: string): Array<any> {
        let _columnDefs = [];
        switch (type) {
            case AdminPageTypes.USERS:
                _columnDefs = this._getUserColumnDefs();
                break;
            case AdminPageTypes.NOTE_TYPES:
                _columnDefs = this._getNoteTypesColumnDefs();
                break;
            case AdminPageTypes.ENTITY_TYPES:
                _columnDefs = this._getEntityTypeColumnDefs();
                break;
            default:
                break;
        }
        return _columnDefs;
    }

    updateGeneralInfo(type: string): Observable<any> {
        const url = AppConfig.entryAdminSettingEndPoint;
        const formData: FormData = new FormData();
        formData.append('sideNoteFollowRule', type);
        return this._transportService.put(url, formData, {});
    }
    //#endregion

    //#region admin general page
    checkGeneralName(pageType, item, oldItem, isEdit = false): Observable<any> {
        const url = `${AppConfig[pageType]}` + 'checkname/';
        let key = pageType + 'Config';
        const formData = new FormData();

        if (pageType === GeneralGridType.ConfigureHTMLSecurity) {
            key = 'tagConfig';
            if (isEdit) {
                if (oldItem.tag.toLowerCase() === item.tag.toLowerCase()) {
                    return of({ message: 'true' });
                }
                formData.set('oldTag', oldItem.tag.toLowerCase());
                formData.set('newTag', item.tag.toLowerCase());
            } else {
                formData.set('newTag', item.tag.toLowerCase());
            }
        } else {
            let data = {}; // to save the form data
            let type = 'name'; // to get the name by type
            if (pageType === GeneralGridType.Abbreviation) {
                type = pageType;
            } else if (pageType === GeneralGridType.StopList) {
                type = 'ticker';
            }
            if (isEdit) {
                if (oldItem[type] === item[type]) {
                    return of({ message: 'true' });
                }
                data = { id: item['id'], name: item[type] };
            } else {
                data = { name: item[type] };
            }

            formData.set(key, JSON.stringify(data));
        }
        return this._transportService.post(url, formData);
    }

    createGeneralData(pageType, item) {
        const url = `${AppConfig[pageType]}`;
        let key = pageType + 'Config';
        const formData = new FormData();
        if (pageType === GeneralGridType.ConfigureHTMLSecurity) {
            key = 'tagConfig';
            formData.append(key, item.toLowerCase());
        } else {
            formData.append(key, JSON.stringify(item));
        }
        return this._transportService.post(url, formData);
    }

    editGeneralData(pageType, item, oldItem?) {
        let url = `${AppConfig[pageType]}${item.ID}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const key = pageType + 'Config';
        const formData = new URLSearchParams();
        if (pageType === GeneralGridType.ConfigureHTMLSecurity) {
            url = `${AppConfig[pageType]}`;
            formData.set('oldTag', oldItem.tag.toLowerCase());
            formData.set('newTag', item.tag.toLowerCase());
        } else {
            formData.set(key, JSON.stringify(item));
        }
        const options = {
            headers: headers
        };
        return this._transportService.put(url, formData.toString(), options);
    }

    deleteGeneralData(pageType, id, name) {
        let url = `${AppConfig[pageType]}${id} `;
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        if (pageType === GeneralGridType.ConfigureHTMLSecurity) {
            const tag = encodeURIComponent(name);
            url = `${AppConfig[pageType]}${tag} `;

        }
        return this._transportService.delete(url, options);
    }

    getGeneralData(pageType) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig[pageType]}`;
        const options = {
            headers: headers
        };
        return this._transportService.get(url, options);
    }

    getParseGeneralData(data, type) {
        let result = [];
        if (type === GeneralGridType.ConfigureHTMLSecurity) {
            data.forEach(item => {
                result.push({ tag: item });
            });
        } else if (type === GeneralGridType.Abbreviation) {
            data.forEach(item => {
                result.push(Abbreviation.parse(item));
            });
        } else if (type === GeneralGridType.FMROverrides) {
            data.forEach(item => {
                result.push(FMROverrides.parse(item));
            });
        } else if (type === GeneralGridType.StopList) {
            data.forEach(item => {
                result.push(StopList.parse(item));
            });
        } else {
            result = data;
        }

        return result;
    }

    getDraftNoteEntity() {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.draftNoteEntity}`;
        const options = {
            headers: headers
        };
        return this._transportService.get(url, options);
    }

    updateDraftNoteEntity(entityId) {
        const url = AppConfig.draftNoteEntity;
        const formData: FormData = new FormData();
        formData.append('draftNoteEntityID', entityId);
        return this._transportService.put(url, formData, {});
    }

    getConvertAddress() {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.convertAddress}`;
        const options = {
            headers: headers
        };
        return this._transportService.get(url, options);
    }

    updateAutoConversion(isAutoConversion) {
        const url = AppConfig.convertAddress;
        const formData: FormData = new FormData();
        formData.append('autoConversion', isAutoConversion);
        return this._transportService.post(url, formData, {});
    }

    updateConvertAddress(conversionType) {
        const url = AppConfig.convertAddress;
        const formData: FormData = new FormData();
        formData.append('conversionType', conversionType);
        return this._transportService.post(url, formData, {});
    }
    //#endregion

    //#region admin shared page
    convertUIDataToServerData(pageType, data: any) {
        let returnData = {};
        if (pageType === adminPageType.scheduledJobs) {
            const cronString = '0' + ' ' + data.scheduleTime + ' ' + this.findKeyByValue(Freq, data.freq);
            returnData = {
                name: data.name,
                taskName: data.taskName,
                cronString: cronString,
                isDefault: data.isDefault
            };
        }
        return returnData;
    }

    createData(pageType, key, item) {
        const url = `${AppConfig[pageType]}`;
        const formData = new FormData();
        if (!(pageType === adminPageType.pythonScripts ||
            pageType === adminPageType.webSites)) {
            if (item['ID']) {
                delete item['ID'];
            }
        }
        formData.append(key, JSON.stringify(item));
        return this._transportService.post(url, formData);
    }

    editData(pageType, key, item) {
        let url = `${AppConfig[pageType]}${item.ID}`;
        if (pageType === adminPageType.streetEvents) {
            url = `${AppConfig[pageType]}`;
        }
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const formData = new URLSearchParams();
        formData.set(key, JSON.stringify(item));
        const options = {
            headers: headers
        };
        return this._transportService.put(url, formData.toString(), options);
    }

    deleteData(pageType, id) {
        let url = `${AppConfig[pageType]}${id} `;
        if (pageType === adminPageType.streetEvents) {
            url = `${AppConfig[pageType]}`;
        }
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return this._transportService.delete(url, options);
    }

    getData(pageType) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig[pageType]}`;
        const options = {
            headers: headers
        };
        return this._transportService.get(url, options);
    }

    getParseData(data, type) {
        let result = data;
        if (type === adminPageType.scheduledJobs) {
            result = ScheduledJobs.parse(data);
        } else if (type === adminPageType.etlTask) {
            result = ETLTask.parse(data);
        } else if (type === adminPageType.pythonScripts) {
            result = LocalPythonScript.parse(data);
        } else if (type === adminPageType.tokenManagement) {
            result = TokenManagement.parse(data);
        } else if (type === adminPageType.webSites) {
            result = WebSites.parse(data);
        }
        return result;
    }

    handleRun(pageType, id) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.scheduledJobs}runjob/${id}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }
    updateData(type, data) {
        let result = [];
        if (type === adminPageType.webSites) {
            const createdData = data.filter(item => item.editable === true);
            ArrayHelperService.sort(createdData, 'name');
            result.push(...createdData);

            const defaultData = data.filter(item => item.editable === false);
            ArrayHelperService.sort(defaultData, 'name');
            result.push(...defaultData);
        } else {
            result = data;
        }
        return result;
    }
    //#endregion

    //#region street event config
    runStreetEventAdapter() {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.streetEvents}runadapter`;
        const options = {
            headers: headers
        };
        return this._transportService.get(url, options);
    }
    //#endregion

    //#region etl tasks
    checkETLTaskName(name: string, id: string): Observable<any> {
        const url = `${AppConfig.etlTask}` + 'checkname/';
        const eltTaskParam: any = {};
        const formData = new FormData();
        if (id) {
            eltTaskParam.id = id;
        }
        eltTaskParam.name = name;
        formData.set('etlTaskConfig', JSON.stringify(eltTaskParam));
        return this._transportService.post(url, formData);
    }

    getETLTaskProperties(id: string) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.etlTaskProperties}${id}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }
    //#endregion

    //#region scheduled job
    checkScheduledJobName(name: string, id: string, oldName: string, isEdit: boolean): Observable<any> {
        if (isEdit) {
            if (name === oldName) {
                return of({ message: 'true' });
            }
        }
        const url = `${AppConfig.scheduledJobs}` + 'checkname/';
        const eltTaskParam: any = {};
        const formData = new FormData();
        if (id) {
            eltTaskParam.id = id;
        }
        eltTaskParam.name = name;
        formData.set('etlJobConfig', JSON.stringify(eltTaskParam));
        return this._transportService.post(url, formData);
    }
    //#endregion

    //#region cache store to file
    cacheStoreToFile(data) {
        const url = `${AppConfig.cacheStore}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };

        const params = data;
        const options = {
            params: params,
            headers: headers,
        };
        return this._transportService.post(url, '', options);
    }
    //#endregion

    //#region persistent cache purge
    persistentCachePurge(data) {
        const url = `${AppConfig.cachePurge}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };

        const params = data;
        const options = {
            params: params,
            headers: headers,
        };
        return this._transportService.post(url, '', options);
    }
    //#endregion

    //#region server monitoring
    getServerMonitoring() {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.serverMonitoring}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }
    //#endregion

    //#region clamAV file
    deleteClamAVFile(filePath: string, entryFileId: string) {
        const url = `${AppConfig.clamFile}deletefile`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const formData = new URLSearchParams();
        formData.set('fileFullPath', filePath);
        formData.set('deleteFileID', entryFileId);
        const options = {
            headers: headers
        };
        return this._transportService.post(url, formData.toString(), options);
    }
    getClamAVFile(filePath: string) {
        const url = `${AppConfig.clamFile}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const formData = new URLSearchParams();
        formData.set('fileFullPath', filePath);
        const options = {
            headers: headers
        };
        return this._transportService.post(url, formData.toString(), options);
    }
    //#endregion

    //#region websites
    AddWebSiteByFile(webSiteFile) {
        const url = `${AppConfig.website}`;
        const formData = new FormData();
        formData.append('webSiteFile', JSON.stringify(webSiteFile));
        return this._transportService.post(url, formData);
    }
    //#endregion

    //#region private functions
    //#region users/notetypes/entitytypes
    private _getUserColumnDefs(): Array<any> {
        const _userColumnDefs = [
            {
                headerName: 'Full name', field: 'fullname', width: 120, headerTooltip: 'Full name', sort: 'asc', menuTabs: []
            },
            {
                headerName: 'Username', field: 'username', width: 90, headerTooltip: 'Username', menuTabs: []
            },
            {
                headerName: 'Created Date', field: 'createDate', width: 90, headerTooltip: 'Create date', menuTabs: [],
                cellRenderer: (params) => {
                    const createDate = params.data.createDate;
                    if (createDate) {
                        return createDate ? this._dateHelperService.getWeekdayByDate(createDate) + ' ' +
                            this._datepipe.transform(createDate, 'MM/dd/yyyy HH:mm') : '';
                    }
                },
            },
            {
                headerName: 'Last active date', field: 'lastActiveDate', width: 90, headerTooltip: 'Last active date', menuTabs: [],
                cellRenderer: (params) => {
                    const lastActiveDate = params.data.lastActiveDate;
                    if (lastActiveDate) {
                        return lastActiveDate ? this._dateHelperService.getWeekdayByDate(lastActiveDate) + ' ' +
                            this._datepipe.transform(lastActiveDate, 'MM/dd/yyyy HH:mm') : '';
                    }
                },
            },
            {
                headerName: 'Secure Email', field: 'secure-email', width: 90, headerTooltip: 'Secure Email', menuTabs: []
            },
            {
                headerName: 'Edit/Delete',
                field: 'edit',
                minWidth: 96,
                width: 96,
                maxWidth: 96,
                suppressMovable: true,
                headerTooltip: 'Edit/Delete Users',
                // The title of this header should be aligned to center
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation" style="width:100%;">' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="width:100%;display:flex;">' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:center;"></span>' +
                        '  </div>' +
                        '</div>'
                },
                cellRenderer: AdminPageActionsComponent,
                cellRendererParams: { feedbackSubject$: () => adminPagesConstants.users.feedbackSubject$ },
                menuTabs: []
            }
        ];
        return _userColumnDefs;
    }

    private _getNoteTypesColumnDefs(): Array<any> {
        const _noteTypesColumnDefs = [
            {
                // The title of this header should be aligned to left
                headerName: 'Name', field: 'name', width: 80, headerTooltip: 'Name', sort: 'asc', menuTabs: [],
            },
            {
                headerName: 'Default Entry Type', field: 'default-note', width: 90, headerTooltip: 'Default Note Type',
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation"style="width:100%;" >' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="display:flex;">' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:center;"></span>' +
                        '  </div>' +
                        '</div>'
                },
                cellRenderer: AdminPageWidgetComponent,
                cellRendererParams: {
                    feedbackSubject$: () => adminPagesConstants.noteTypes.feedbackSubject$,
                    feedbackType: NoteTypesSubjectTypes.DEFAULT_NOTE_TYPE,
                    type: 'radio-button'
                },
                menuTabs: []
            },
            {
                headerName: 'Default Event Type', field: 'default-event', width: 90, headerTooltip: 'Default Event Type',
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation"style="width:100%;" >' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="display:flex;">' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:center;"></span>' +
                        '  </div>' +
                        '</div>'
                },
                cellRenderer: AdminPageWidgetComponent,
                cellRendererParams: {
                    feedbackSubject$: () => adminPagesConstants.noteTypes.feedbackSubject$,
                    feedbackType: NoteTypesSubjectTypes.DEFAULT_EVENT_TYPE,
                    type: 'radio-button'
                },
                menuTabs: []
            },
            {
                headerName: 'Display in TamaleWeb', field: 'display-web', width: 90, headerTooltip: 'Display in TamaleWeb', cellRenderer:
                    AdminPageWidgetComponent,
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation"style="width:100%;" >' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="display:flex;">' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:center;"></span>' +
                        '  </div>' +
                        '</div>'
                },
                cellRendererParams: {
                    feedbackSubject$: () => adminPagesConstants.noteTypes.feedbackSubject$,
                    feedbackType: NoteTypesSubjectTypes.DISPLAY_TAM_WEB,
                    type: 'check-box'
                },
                menuTabs: []
            },
            {
                headerName: 'Default Extensions', field: 'default-extensions', width: 90,
                headerTooltip: 'Default Extensions',
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation" style="width:100%;">' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="width:100%;display:flex;">' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:left;"></span>' +
                        '  </div>' +
                        '</div>'
                },
                cellRendererParams: {
                    feedbackSubject$: () => adminPagesConstants.noteTypes.feedbackSubject$,
                    feedbackType: NoteTypesSubjectTypes.DISPLAY_TAM_WEB,
                    type: 'check-box'
                },
                menuTabs: []
            },
            {
                headerName: 'Used by', field: 'usage', width: 70, headerTooltip: 'Used by',
                headerComponentParams: {
                    template:
                        `<div class="ag-cell-label-container" role="presentation" style="width:100%;" >
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="display:flex;">
                                <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:center;"></span>
                            </div>
                        </div>`
                },
                cellStyle: { 'text-align': 'center' },
                menuTabs: []
            },
            {
                headerName: 'Default sidenote type', field: 'defaultSidenoteType', width: 70, headerTooltip: 'Default sidenote type',
                headerComponentParams: {
                    template:
                        `<div class="ag-cell-label-container" role="presentation" style="width:100%;" >
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="display:flex;">
                                <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:center;"></span>
                            </div>
                        </div>`
                },
                cellStyle: { 'text-align': 'center' },
                menuTabs: []
            },
            {
                headerName: 'Edit/Delete',
                field: 'edit',
                headerTooltip: 'Edit/Delete Entry Types',
                minWidth: 96,
                width: 96,
                maxWidth: 96,
                suppressMovable: true,
                headerComponentParams: {
                    template:
                        `<div class="ag-cell-label-container" role="presentation"style="width:100%;" >
                            <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="display:flex;">
                                 <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:center;"></span>
                            </div>
                        </div>`
                },
                cellRenderer: AdminPageActionsComponent,
                cellRendererParams: { feedbackSubject$: () => adminPagesConstants.noteTypes.feedbackSubject$ },
                menuTabs: []
            }
        ];
        return _noteTypesColumnDefs;
    }

    private _getEntityTypeColumnDefs(): Array<any> {
        const _entityTypeColumnDefs = [
            {
                headerName: 'Name', field: 'name', width: 180, headerTooltip: 'Name', sort: 'asc', menuTabs: []
            },
            {
                headerName: 'Default Entity Type', field: 'default-entity-type', width: 90, headerTooltip: 'Default Entity Type',
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation"style="width:100%;" >' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="display:flex;">' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:center;"></span>' +
                        '  </div>' +
                        '</div>'
                },
                cellRenderer: AdminPageWidgetComponent,
                cellRendererParams: {
                    feedbackSubject$: () => adminPagesConstants.entityTypes.feedbackSubject$,
                    feedbackType: EntityTypesSubjectTypes.DEFAULT_ENTITY_TYPE,
                    type: 'radio-button'
                },
                menuTabs: []
            },
            {
                headerName: 'Display in TamaleWeb', field: 'display-web', width: 90, headerTooltip: 'Display in TamaleWeb',
                /** The title of this header should be aligned to center */
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation" style="width:100%;">' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="width:100%;display:flex;">' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:center;"></span>' +
                        '  </div>' +
                        '</div>'
                },
                cellRenderer: AdminPageWidgetComponent,
                cellRendererParams: {
                    feedbackSubject$: () => adminPagesConstants.entityTypes.feedbackSubject$,
                    feedbackType: EntityTypesSubjectTypes.DISPLAY_TAM_WEB,
                    type: 'check-box'
                },
                menuTabs: []
            },
            {
                headerName: 'Edit/Delete',
                field: 'edit',
                headerTooltip: 'Edit/Delete Entity Types',
                minWidth: 96,
                width: 96,
                maxWidth: 96,
                suppressMovable: true,
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation"style="width:100%;" >' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="display:flex;">' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="flex:1;text-align:center;"></span>' +
                        '  </div>' +
                        '</div>'
                },
                cellRenderer: AdminPageActionsComponent,
                cellRendererParams: { feedbackSubject$: () => adminPagesConstants.entityTypes.feedbackSubject$ },
                menuTabs: []
            },
        ];
        return _entityTypeColumnDefs;
    }
    //#endregion
    //#endregion
}
