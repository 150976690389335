import { Subject } from 'rxjs';

export const OPEN_FILTER_DIALOG = 'OPEN_FILTER_DIALOG';
export const CHANGE_SHOW_LIST_MODE = 'CHANGE_SHOW_LIST_MODE';
export const SHOW_CALENDAR_EVENTS = 'SHOW_CALENDAR_EVENTS';

export enum ShowAsMode {
    NOTES = 0,
    THREADS = 1
}

export class DisplayConfig {
    showAsMode: ShowAsMode;
    showCalendarEvents: boolean;
    actions$: Subject<any>;
    events$: Subject<any>;
    // add filterStatusSelector
    filterStatusSelector: any;

    constructor() {
        this.showAsMode = ShowAsMode.NOTES;
        this.showCalendarEvents = false;
        this.actions$ = new Subject();
        this.events$ = new Subject();
    }
}
