<div tid="note_detail" class="container threaddetail-sidesheet-status" [ngClass]="{'maximum': isShowMaximun}"
    tabindex="-1" (keyup)="onKeyup($event)" (keydown)="onKeydown($event)" loading-spin [isLoading]="isLoading"
    [loadingSize]="'big'">
    <div class="no-data-box" *ngIf="isNoData" [ngClass]="{'no-data-box-padding': layoutMode !== 2}">
        <span>Select an item to view</span>
    </div>
    <div class="has-data-box" *ngIf="!isNoData">
        <div class="tool-bar" kendoTooltip position="bottom" tooltipClass="tooltip-adjust-right" *ngIf="!config.mode">
            <smart-icon name="window-minimize" class="light" (click)="onMaximumClick($event)"
                *ngIf="!config.singlePageMode && isShowMaximun" tid="minimize_note" title="Minimize Preview Panel">
            </smart-icon>
            <smart-icon name="window-maximize" class="light" (click)="onMaximumClick($event)"
                *ngIf="!config.singlePageMode && !isShowMaximun" tid="maximize_note" title="Maximize Preview Panel">
            </smart-icon>
            <smart-icon class="light" name="popup-entry" (click)="popupEntry($event)" title="Open note in new tab"
                tid="popup_entry" *ngIf="!config.singlePageMode">
            </smart-icon>
            <smart-icon *ngIf="!isIPAD" name="link-clone" class="light" (click)="onCopyLinkClick()" tid="copy_link"
                title="Copy Link">
            </smart-icon>
            <smart-icon *ngIf="!isIPAD" tid="print_note" name="print" class="light" title="Print (Type &quot;p&quot;)"
                (click)="printNote()">
            </smart-icon>
            <smart-icon *ngIf="!isIPAD" name="file-share" class="light" (click)="onShareClick()" tid="share_note"
                [title]="isEvent?'Email Event':'Email Note'">
            </smart-icon>
            <smart-icon *ngIf="isDeletable()" name="trash" (click)="trashClick()" class="light" tid="delete_note"
                [title]="isEvent?'Delete Event':'Delete Note'">
            </smart-icon>
            <smart-icon *ngIf="isEdit" tid="edit_note" name="note-edit" (click)="editNoteClick()" class="light"
                [title]="isEvent?'Edit Event':'Edit Note'">
            </smart-icon>
            <smart-icon name="add-sidenote" (click)="onAddSideNoteClick()" tid="new_sidenote" title="Add sidenote"
                class="light">
            </smart-icon>
        </div>
        <div #contentBox id='contentBox' class="content-box" (mousewheel)="onMouseWheel($event)">
            <div tid="note_detail_entity" class="entities-box" *ngIf="config.mode!==1" kendoTooltip position="bottom">
                <a (click)="viewEntityDetailInSideSheet(item,$event)" class="entity-tag" [href]="getEntityTagHref(item)"
                    [ngClass]="{'highlight': isEntityTagHighlight(item.shortName)}"
                    *ngFor="let item of thread.entities;trackBy:trackByFn" [title]="item.shortName">
                    <span>{{item.shortName}}</span>
                </a>
            </div>

            <!-- for print only -->
            <div tid="note_detail_entity_print" *ngIf="config.mode===1" kendoTooltip position="bottom">
                <span>Entities:&nbsp;</span>
                <span *ngFor="let item of thread.entities;trackBy:trackByFn; last as isLast" [title]="item.shortName">
                    {{item.shortName}}{{isLast ? '' : ', '}}
                </span>
            </div>

            <div class="attachment-box" *ngIf="thread.attachments && thread.attachments.size > 0">
                <attachment-list [isPrintMode]="config.mode===1" [attachments]="attachmentViewModelList"
                    [canShare]="true" (deleteAttachment)="deleteAttachmentClick($event)"></attachment-list>
            </div>

            <div class="note-list-box" *ngIf="bodyLoaded" [ngClass]="{'hide-toggle-area': thread.notes.length === 1}">
                <note-detail *ngFor="let note of thread.notes;" [isPrintMode]="config.mode===1"
                    [isEmbedMode]="config.mode===2" [note]="note" [highlightText]="keywords" [thread]="thread"
                    (editNoteOrSideNote)="editNoteOrSideNoteClick($event)" [isSideSheet]="false"
                    (deleteSideNote)="deleteSideNoteClick($event)" (deleteAttachment)="deleteAttachmentClick($event)"
                    [partialDisplay]="config.partialDisplay" id="_{{note.id}}">
                </note-detail>
                <br>
            </div>
        </div>
    </div>
</div>