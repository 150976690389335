/**
 * created by Marcus Zhao 7/28/2021.
 * Description: Copy some logic from tile-filter.service.ts
 *              Support filter contact standard control and entity adhoc control.
 * ------ maintenance history ------
 * Updated by Marcus Zhao on 06/08 add status filter and handle value is no-exist.
 * Updated by Daniel Wang 11/12/2022 to support event filter.
 */
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FiltersConfig, FilterMetadataType } from '../components/filter-config';
import { TSGuid } from '../models/ts-guid.model';
import { FilterService } from './filter.service';
import { ALL_VALUE, CURRENT_USER } from '../constants/business.constants';
import { Filter, MapFilterContent } from '../models/map-tile.model';
import * as moment from 'moment';
import { businessConstants } from '../../constants/business.constants';
import * as _lodash from 'lodash';
import { ArrayHelperService } from './array-helper.service';

@Injectable()
export class MapFilterService {
    constructor(
        private _filterService: FilterService,
        private _datepipe: DatePipe,
    ) { }

    getOptions(metadataType) {
        let fields = [];
        let selectedOperator;
        let operators = [];
        let dataType = '';
        switch (metadataType?.toLowerCase()) {
            // region to support contact
            case FilterMetadataType.Company.toLowerCase():
                fields = ['company'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Equals', value: 'Equals' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'Blank', value: 'Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'MED';
                break;
            case FilterMetadataType.JobTitle.toLowerCase():
                fields = ['jobTitle'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Equals', value: 'Equals' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'Blank', value: 'Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'MSD';
                break;
            case FilterMetadataType.JobFunction.toLowerCase():
                fields = ['jobFunction'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Equals', value: 'Equals' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'Blank', value: 'Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'MSD';
                break;
            case FilterMetadataType.Alias.toLowerCase():
                fields = ['alias'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Equals', value: 'Equals' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'Blank', value: 'Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'MSD';
                break;
            case FilterMetadataType.City.toLowerCase():
                fields = ['city'];
                selectedOperator = { name: 'Contains', value: 'Contains' };
                operators = [
                    { name: 'Contains', value: 'Contains' },
                    { name: 'Starts with', value: 'Starts with' },
                    { name: 'Ends with', value: 'Ends with' },
                    { name: 'is Blank', value: 'is Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'Textbox';
                break;
            case FilterMetadataType.CountryState.toLowerCase():
                fields = ['countryState'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'is Blank', value: 'is Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'Country&State';
                break;
            case FilterMetadataType.FormerCompany.toLowerCase():
                fields = ['formerCompany'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Equals', value: 'Equals' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'Blank', value: 'Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'MED';
                break;
            case FilterMetadataType.Status.toLowerCase():
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' }
                ];
                dataType = 'MSD';
                break;
            case FilterMetadataType.Relationship.toLowerCase():
                fields = ['relationship'];
                selectedOperator = { name: 'Any of', value: 'Any of' };
                operators = [
                    { name: 'Any of', value: 'Any of' },
                    { name: 'Equals', value: 'Equals' },
                    { name: 'Excludes', value: 'Excludes' }
                ];
                dataType = 'Relationship';
                break;
            //#endregion
            // region to support event
            case FilterMetadataType.NoteType.toLowerCase():
            case FilterMetadataType.EntryType.toLowerCase():
            case FilterMetadataType.EventType.toLowerCase():
                fields = ['entryType'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Excludes', value: 'Excludes' }
                ];
                dataType = metadataType.toLowerCase();
                break;
            case FilterMetadataType.Participants.toLowerCase():
                fields = ['participants'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Excludes', value: 'Excludes' }
                ];
                dataType = metadataType.toLowerCase();
                break;
            case FilterMetadataType.Source.toLowerCase():
                fields = ['source'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Excludes', value: 'Excludes' }
                ];
                dataType = metadataType.toLowerCase();
                break;
            case FilterMetadataType.Subject.toLowerCase():
                fields = ['subject'];
                selectedOperator = { name: 'Contains', value: 'Contains' };
                operators = [
                    { name: 'Contains', value: 'Contains' },
                    { name: 'Not Contains', value: 'Not Contains' },
                    { name: 'Starts with', value: 'Starts with' },
                    { name: 'Ends with', value: 'Ends with' },
                    { name: 'is Blank', value: 'is Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'text';
                break;
            // endregion
            // #region to support standard controls
            case FilterMetadataType.Entity.toLowerCase():
            case FilterMetadataType.Dropdown.toLowerCase():
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'is Blank', value: 'is Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'entity';
                break;
            case FilterMetadataType.Entities.toLowerCase():
                fields = ['entities'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Equals', value: 'Equals' },
                    { name: 'Excludes', value: 'Excludes' }
                ];
                dataType = 'entity';
                break;
            case FilterMetadataType.Date.toLowerCase():
                selectedOperator = this._filterService.getDefaultOperator(metadataType);
                operators = [
                    { name: 'Last', value: 'last' },
                    { name: 'Next', value: 'next' },
                    { name: 'Date Range', value: 'range' },
                    { name: 'Before', value: 'before' },
                    { name: 'After', value: 'after' },
                    { name: 'Today', value: 'today' },
                ];
                dataType = 'date';
                break;
            case FilterMetadataType.DisplayDate.toLowerCase():
                fields = ['displayDate'];
                selectedOperator = this._filterService.getDefaultOperator(metadataType);
                operators = [
                    { name: 'Last', value: 'last' },
                    { name: 'Next', value: 'next' },
                    { name: 'Date Range', value: 'range' },
                    { name: 'Before', value: 'before' },
                    { name: 'After', value: 'after' },
                    { name: 'Today', value: 'today' },
                ];
                dataType = 'date';
                break;
            case FilterMetadataType.SubmittedDate.toLowerCase():
                fields = ['submittedDate'];
                selectedOperator = this._filterService.getDefaultOperator(metadataType);
                operators = [
                    { name: 'Last', value: 'last' },
                    { name: 'Next', value: 'next' },
                    { name: 'Date Range', value: 'range' },
                    { name: 'Before', value: 'before' },
                    { name: 'After', value: 'after' },
                    { name: 'Today', value: 'today' },
                ];
                dataType = 'date';
                break;
            case FilterMetadataType.StartTime.toLowerCase():
                fields = ['startTime'];
                selectedOperator = this._filterService.getDefaultOperator(metadataType);
                operators = [
                    { name: 'Last', value: 'last' },
                    { name: 'Next', value: 'next' },
                    { name: 'Date Range', value: 'range' },
                    { name: 'Before', value: 'before' },
                    { name: 'After', value: 'after' },
                    { name: 'Today', value: 'today' },
                ];
                dataType = 'date';
                break;
            case FilterMetadataType.EndTime.toLowerCase():
                fields = ['endTime'];
                selectedOperator = this._filterService.getDefaultOperator(metadataType);
                operators = [
                    { name: 'Last', value: 'last' },
                    { name: 'Next', value: 'next' },
                    { name: 'Date Range', value: 'range' },
                    { name: 'Before', value: 'before' },
                    { name: 'After', value: 'after' },
                    { name: 'Today', value: 'today' },
                ];
                dataType = 'date';
                break;
            case FilterMetadataType.CheckBox.toLowerCase():
                fields = ['checkbox'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'Equals', value: 'Equals' },
                ];
                dataType = 'text';
                break;
            case FilterMetadataType.MSD.toLowerCase():
                fields = ['entity'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Equals', value: 'Equals' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'Blank', value: 'Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'MSD';
                break;
            case FilterMetadataType.TextBox.toLowerCase():
            case FilterMetadataType.Text.toLowerCase():
                fields = ['textbox'];
                selectedOperator = { name: 'Contains', value: 'Contains' };
                operators = [
                    { name: 'Contains', value: 'Contains' },
                    { name: 'Not Contains', value: 'Not Contains' },
                    { name: 'Starts with', value: 'Starts with' },
                    { name: 'Ends with', value: 'Ends with' },
                    { name: 'is Blank', value: 'is Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'textbox';
                break;
            case FilterMetadataType.ED.toLowerCase():
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'is Blank', value: 'is Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'MED';
                break;
            case FilterMetadataType.MED.toLowerCase():
            case FilterMetadataType.MET.toLowerCase():
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Equals', value: 'Equals' },
                    { name: 'Excludes', value: 'Excludes' },
                    { name: 'Blank', value: 'Blank' },
                    { name: 'Not Blank', value: 'Not Blank' }
                ];
                dataType = 'MED';
                break;
            case FilterMetadataType.SpecificContacts.toLowerCase():
            case FilterMetadataType.SpecificUsers.toLowerCase():
            case FilterMetadataType.SpecificTeams.toLowerCase():
            case FilterMetadataType.CustomField.toLowerCase():
                fields = ['entryType'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' }
                ];
                dataType = metadataType.toLowerCase();
                break;
            // #region to support the types which convert from desktop
            case FilterMetadataType.EntityType.toLowerCase():
                fields = ['entityType'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Excludes', value: 'Excludes' }
                ];
                dataType = metadataType.toLowerCase();
                break;
            case FilterMetadataType.Priority.toLowerCase():
                fields = ['priority'];
                // operators name same filter service but value not same
                selectedOperator = { name: 'Greater than', value: '>' };
                operators = [
                    { name: 'Greater than', value: '>' },
                    { name: 'Greater than or equal to', value: '>=' },
                    { name: 'Equals', value: '=' },
                    { name: 'Not equal to', value: '!=' },
                    { name: 'Less than', value: '<' },
                    { name: 'Less than or equal to', value: '<=' }
                ];
                dataType = 'priority';
                break;
            case FilterMetadataType.Sentiment.toLowerCase():
                fields = ['sentiment'];
                // operators name same filter service but value not same
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Excludes', value: 'Excludes' }
                ];
                dataType = 'sentiment';
                break;
            case FilterMetadataType.Submitter.toLowerCase():
                fields = ['submitter'];
                selectedOperator = { name: 'Includes', value: 'Includes' };
                operators = [
                    { name: 'Includes', value: 'Includes' },
                    { name: 'Excludes', value: 'Excludes' }
                ];
                dataType = 'contact';
                break;
            case FilterMetadataType.Team.toLowerCase():
                fields = ['team'];
                selectedOperator = this._filterService.getDefaultOperator(metadataType);
                operators = this._filterService.getOperatorOptions(metadataType);
                dataType = 'team';
                break;
            // #endregion
            // #endregion
        }
        return { fields, selectedOperator, operators, dataType };
    }

    getNewFilterDefaultInstance(title: string, metadataType: string, hideOperators = false,
        disabledDel = false, disable = false, dataSource?: Array<{ id: string, name: string }>): FiltersConfig {
        const filtersConfig = new FiltersConfig();

        filtersConfig.metadataType = metadataType;
        filtersConfig.id = new TSGuid().toString();

        filtersConfig.fields = this.getOptions(metadataType).fields;
        filtersConfig.selectedOperator = this.getOptions(metadataType).selectedOperator.value;
        filtersConfig.operators = this.getOptions(metadataType).operators;
        filtersConfig.dataType = this.getOptions(metadataType).dataType;
        // for custom field in notification filter, add specific source by default
        if (dataSource && metadataType === FilterMetadataType.CustomField) {
            // standard
            filtersConfig.source = [];
            filtersConfig.source.push({
                'id': ['participants'],
                'name': 'participants'
            });
            filtersConfig.source.push({
                'id': ['source'],
                'name': 'source'
            });
            filtersConfig.source.push({
                'id': ['submitter'],
                'name': 'submitter'
            });
            // ad hocs
            ArrayHelperService.sort<{ id: string, name: string }>(dataSource, 'name');
            dataSource.forEach(dsitem => {
                const sources = filtersConfig.source.filter(item => item.id === dsitem.id);
                if (!sources || sources.length === 0) {
                    filtersConfig.source.push({
                        'id': dsitem.id,
                        'name': dsitem.name
                    });
                }
            });
        } else {
            filtersConfig.source = this._filterService.generateFilterSource(metadataType);
        }
        filtersConfig.title = title;
        filtersConfig.disable = disable;
        filtersConfig.deletable = disabledDel;
        filtersConfig.hideOperators = hideOperators;
        if (metadataType === FilterMetadataType.Source
            || metadataType === FilterMetadataType.Submitter
            || metadataType === FilterMetadataType.Participants) {
            filtersConfig.currentUser = CURRENT_USER;
        }
        // for notification filter, do not need to show hideOperators
        if (filtersConfig.metadataType === FilterMetadataType.SpecificContacts || filtersConfig.metadataType === FilterMetadataType.SpecificUsers || filtersConfig.metadataType === FilterMetadataType.SpecificTeams || filtersConfig.metadataType === FilterMetadataType.CustomField) {
            filtersConfig.hideOperators = true;
        }
        // for notification filter, set the value to empty by default
        if (metadataType.toLowerCase() === FilterMetadataType.EventType.toLowerCase() ||
            metadataType.toLowerCase() === FilterMetadataType.EntityType.toLowerCase()) {
            filtersConfig.value = ALL_VALUE;
        }
        if (metadataType.toLowerCase() === FilterMetadataType.StartTime.toLowerCase() ||
            metadataType.toLowerCase() === FilterMetadataType.EndTime.toLowerCase() ||
            metadataType.toLowerCase() === FilterMetadataType.DisplayDate.toLowerCase() ||
            metadataType.toLowerCase() === FilterMetadataType.SubmittedDate.toLowerCase()) {
            filtersConfig.value = this._filterService.getDefaultValue(metadataType);
        }
        if (metadataType === FilterMetadataType.Priority) {
            filtersConfig.value = 0;
        }
        return filtersConfig;
    }

    getAdhocFilterDefaultInstance(data): FiltersConfig {
        const filtersConfig = new FiltersConfig();
        filtersConfig.id = new TSGuid().toString();
        if (!data.metadataType) {
            data.metadataType = data.controlType ? data.controlType : data.dataType;
        }
        filtersConfig.dataType = data.dataType;
        if (!filtersConfig.metadataType) {
            filtersConfig.metadataType = data.metadataType;
        }
        if (data.attrDefId) {
            filtersConfig.fields = data.attrDefId;
        } else {
            filtersConfig.fields = this._filterService.getFields(filtersConfig.metadataType);
        }
        filtersConfig.selectedOperator = this.getOptions(data.metadataType).selectedOperator.value;
        filtersConfig.operators = this.getOptions(data.metadataType).operators;
        filtersConfig.value = this._filterService.getDefaultValue(data.metadataType);
        filtersConfig.title = data.name ?? data.title;
        if (data.source) {
            filtersConfig.source = [];
            data.source.forEach(element => {
                filtersConfig.source.push({ 'name': element, 'value': element });
            });
        }
        // filtersConfig.source = data.source;
        filtersConfig.disable = false;
        if (filtersConfig.metadataType === FilterMetadataType.Entity) {
            filtersConfig.currentUser = CURRENT_USER;
        }
        return filtersConfig;
    }

    generateAdvFilterForEntity(item, filterData: Filter, currentUser?) {
        const value = item.value;
        if (item.selectedOperator === businessConstants.mapFilter.isBlank || item.selectedOperator === businessConstants.mapFilter.notBlank
            || item.selectedOperator === businessConstants.mapFilter.blank) {
            filterData.values = [];
        } else {
            if (value && value.length > 0) {
                const tempData = _lodash.cloneDeep(value);
                tempData.forEach(_item => {
                    if (_item.id === CURRENT_USER.id) {
                        _item.id = currentUser.id;
                    }
                });

                filterData.values = tempData.map(element => element.id);
            } else {
                filterData.values = [];
            }
        }
        return filterData;
    }

    generateAdvFilterForRelationship(item, filterData: Filter) {
        const entity = item.value;
        const relationshipType = item.relationshipType;

        filterData.values = [];
        if (relationshipType) {
            filterData.values = relationshipType.split(',');
        }
        if (entity) {
            filterData.target = entity.id;
        }
        filterData.includeCurrentEmployment = item.isIncludeCurrentEmployment;
        return filterData;
    }

    generateAdvFilterForText(item, filterData: Filter) {
        const value = item.value;
        if (item.selectedOperator === businessConstants.mapFilter.isBlank || item.selectedOperator === businessConstants.mapFilter.notBlank
            || item.selectedOperator === businessConstants.mapFilter.blank) {
            filterData.values = [];
        } else {
            if (Array.isArray(value)) {
                filterData.values = value;
            } else {
                if (!value) {
                    filterData.values = [];
                } else {
                    filterData.values = [value];
                }
            }
        }
        return filterData;
    }

    generateAdvFilterForCountryState(item, filterData: Filter) {
        const value = item.value;
        if (item.selectedOperator === businessConstants.mapFilter.isBlank || item.selectedOperator === businessConstants.mapFilter.notBlank
            || item.selectedOperator === businessConstants.mapFilter.blank) {
            filterData.values = '';
        } else {
            filterData.values = {};
            if (value && value.length > 0) {
                if (value[0]) {
                    filterData.values.country = value[0];
                }
                if (value.length > 1) {
                    filterData.values.state = value[1];
                }
            }
        }
        return filterData;
    }

    generateAdvFilterForDate(item, filterData: Filter) {
        let unit = '';
        let interval: number;
        filterData['timezone'] = moment.tz.guess();
        switch (item.selectedOperator) {
            case 'last':
                if (item.value.indexOf('day') !== -1 || item.value.indexOf('week') !== -1) {
                    unit = item.value.indexOf('day') !== -1 ? 'day' : 'week';
                } else if (item.value.indexOf('month') !== -1 || item.value.indexOf('year') !== -1) {
                    unit = item.value.indexOf('month') !== -1 ? 'month' : 'year';
                }
                interval = parseInt(item.value.split(' ')[0], 10);
                filterData['interval'] = interval;
                filterData['unit'] = unit;
                filterData.operator = 'Last';
                break;
            case 'next':
                if (item.value.indexOf('day') !== -1 || item.value.indexOf('week') !== -1) {
                    unit = item.value.indexOf('day') !== -1 ? 'day' : 'week';
                } else if (item.value.indexOf('month') !== -1 || item.value.indexOf('year') !== -1) {
                    unit = item.value.indexOf('month') !== -1 ? 'month' : 'year';
                }
                interval = parseInt(item.value.split(' ')[0], 10);
                filterData['interval'] = interval;
                filterData['unit'] = unit;
                filterData.operator = 'Next';
                break;
            case 'range':
                const startDate = item.value.split(' ')[0];
                const endDate = item.value.split(' ')[1];
                interval = parseInt(item.value.split(' ')[0], 10);
                filterData['startDate'] = this._datepipe.transform(startDate, 'yyyy-MM-dd');
                filterData['endDate'] = this._datepipe.transform(endDate, 'yyyy-MM-dd');
                filterData.operator = 'Date range';
                break;
            case 'before':
                filterData['endDate'] = this._datepipe.transform(item.value, 'yyyy-MM-dd');
                filterData.operator = 'Before';
                break;
            case 'after':
                filterData['startDate'] = this._datepipe.transform(item.value, 'yyyy-MM-dd');
                filterData.operator = 'After';
                break;
        }
        return filterData;
    }

    getFilters(paramFilters, mapfilterContent: MapFilterContent, currentUser?) {
        paramFilters.forEach((item) => {
            let filterData = new Filter();
            filterData.attrDefId = item.fields;
            filterData.dataType = item.dataType;
            filterData.operator = item.selectedOperator;
            filterData.title = item.title;
            filterData.metadataType = item.metadataType;
            if (item.metadataType.toLowerCase() === FilterMetadataType.MED.toLowerCase()
                || item.metadataType.toLowerCase() === FilterMetadataType.ED.toLowerCase()
                || item.metadataType.toLowerCase() === FilterMetadataType.MET.toLowerCase()
                || item.metadataType.toLowerCase() === FilterMetadataType.Company.toLowerCase()
                || item.metadataType.toLowerCase() === FilterMetadataType.FormerCompany.toLowerCase()
                || item.metadataType.toLowerCase() === FilterMetadataType.Source.toLowerCase()
                || item.metadataType.toLowerCase() === FilterMetadataType.Submitter.toLowerCase()
                || item.metadataType.toLowerCase() === FilterMetadataType.Participants.toLowerCase()
                || item.metadataType.toLowerCase() === FilterMetadataType.Entities.toLowerCase()
                || item.metadataType.toLowerCase() === FilterMetadataType.Entity.toLowerCase()) {
                filterData = this.generateAdvFilterForEntity(item, filterData, currentUser);
            } else {
                if (item.metadataType.toLowerCase() === FilterMetadataType.Date.toLowerCase()
                    || item.metadataType.toLowerCase() === FilterMetadataType.StartTime.toLowerCase()
                    || item.metadataType.toLowerCase() === FilterMetadataType.EndTime.toLowerCase()
                    || item.metadataType.toLowerCase() === FilterMetadataType.DisplayDate.toLowerCase()
                    || item.metadataType.toLowerCase() === FilterMetadataType.SubmittedDate.toLowerCase()) {
                    if (item.value) {
                        filterData = this.generateAdvFilterForDate(item, filterData);
                    }
                } else if (item.metadataType.toLowerCase() === FilterMetadataType.CountryState.toLowerCase()) {
                    filterData = this.generateAdvFilterForCountryState(item, filterData);
                } else if (item.metadataType === FilterMetadataType.Status) {
                    // status put to other parameter.
                    return;
                } else if (item.metadataType.toLowerCase() === FilterMetadataType.Relationship.toLowerCase()) {
                    filterData = this.generateAdvFilterForRelationship(item, filterData);
                } else {
                    if (item.metadataType.toLowerCase() === FilterMetadataType.EntryType.toLowerCase()
                        || item.metadataType.toLowerCase() === FilterMetadataType.NoteType.toLowerCase()
                        || item.metadataType.toLowerCase() === FilterMetadataType.EventType.toLowerCase()) {
                        filterData.restrictType = item?.typeValue;
                    }
                    filterData = this.generateAdvFilterForText(item, filterData);
                }
            }
            mapfilterContent.filters.push(filterData);

        });
        return mapfilterContent;
    }

    private _resetDateOperateBlankValue(metadataType, showBlank?) {
        const operators = this._filterService.getOperatorOptions(metadataType);
        if (showBlank) {
            operators[operators.length - 2] = { name: 'is Blank', value: 'is null' };
            operators[operators.length - 1] = { name: 'Not Blank', value: 'is not null' };
        }
        return operators;
    }
}
