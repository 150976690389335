/**
 * Created by Alan Yang on 3/20/2017
 * Description:
 *   Top container for a Note.
 *   This note can have a note body, multiple side notes, and attachments.
 *   Notes[0] is the parent Note, others are side notes. They can be checked via Entry.threadPosition.
 *
 *  Struture:
 *      Thread
 *          NoteEntry List
 *              NoteEntry (threadPosition=0. parent note)
 *              NoteEntry (threadPosition=1. 1st side note)
 *              ...
 *          DocumentEntry List
 *              DocumentEntry (file.  DocumentEntry.isEmbeddedImg=false)
 *                  threadId (point to top thread id)
 *                  noteId (point to direct parent id)
 *              DocumentEntry (embedded image.  DocumentEntry.isEmbeddedImg=true)
 * ---------maintenance history-------------
 */
import { NoteEntry } from './note-entry.model';
import { DocumentEntry } from './document-entry.model';
import { EntityBrief } from './entity-brief.model';

export class Thread {
    // notes: NoteEntry[] = [];
    // attachments: TSDictionary<DocumentEntry> = new TSDictionary<DocumentEntry>();

    // attachments is parent notes
    attachments: Map<string, DocumentEntry>;
    entities: Array<EntityBrief>;
    displayDate: Date;
    id: string;
    lastEditedDate: Date; // from server response, it is the "lastEditedDate" of the last side note
    lastModifiedDate: Date; // should be calculated, it is the latest Last Edited Date from all notes in the thread
    notes: Array<NoteEntry>;
    submittedDate: Date;
    parentEntry?: NoteEntry;

    constructor() {
        this.attachments = new Map();
        this.entities = [];
        this.notes = [];
    }

    static parse(serverResponse): Thread {
        const thread = new Thread();

        thread.displayDate = new Date(serverResponse['display-date']);
        thread.id = serverResponse.id;
        thread.lastEditedDate = new Date(serverResponse['last-edited-date']);
        thread.submittedDate = new Date(serverResponse['submitted-date']);

        thread.notes = serverResponse.notes.map(item => NoteEntry.parse(item.data));
        thread.parentEntry = thread.notes.find(noteEntry => noteEntry.threadPosition === 0);

        thread.lastModifiedDate = new Date(0);
        thread.notes.forEach(note => {
            if (note.lastEditDate > thread.lastModifiedDate) {
                thread.lastModifiedDate = note.lastEditDate;
            }
        });

        serverResponse.attachments.forEach(item => {
            const documentEntry = DocumentEntry.parse(item.data);
            if (!documentEntry.isEmbeddedImg) {
                thread.attachments.set(documentEntry.id, documentEntry);
            }
        });

        // assign full attachment information to each note
        // there is no detail attachment data under note when using thread endpoint
        thread.notes.forEach(note => {
            if (note.attachments.size > 0) {
                Array.from(note.attachments.keys()).forEach(key => {
                    if (thread.attachments.has(key)) {
                        note.attachments.set(key, thread.attachments.get(key));
                    } else {
                        // remove embedded image from attachment
                        note.attachments.delete(key);
                    }
                });
            }
        });

        thread.entities = thread.parentEntry.entities;
        thread.notes = thread.notes.reverse();
        return thread;
    }
}
