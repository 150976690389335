/**
 * Created by alex on 01/03/2025.
 * Description:
 * ExchangeImportCheckDialogService Service
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { DialogAction } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';

@Injectable()
export class AppendEntityConfirmDialogService {
    dialogOpen$: Subject<DialogAction> = new Subject<DialogAction>();
}
