/**
 * Created Juno Peng 1/5/2024
 * Description: reducers for portfolio
 *
 * ------ maintenance history ------
 */

import { AppState } from '..';
import { createSelector } from '@ngrx/store';
import { PortfolioActionTypes, PortfolioActions } from '../actions/portfolio.action';

export const portfolioUploadSelector = createSelector((state: AppState) => state, state => state.portfolioUpload);

export function portfolioReducer(state: any = [], action: PortfolioActions) {
    switch (action.type) {
        case PortfolioActionTypes.GETPORTFOLIOS_SUCCESS:
            return action;
        case PortfolioActionTypes.CREATEPORTFOLIOS_SUCCESS:
            return action.payload;
        case PortfolioActionTypes.CREATEPORTFOLIOS_FAILED:
            return action;
        default:
            return state;
    }
}

