/**
 * Created by Abner Sui on 03/19/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';

@Injectable()
export class RefinebyService {

    constructor() { }

    public handleRefinebyDate(options: Array<any>): void {
        let preOptionMayNeedDelete = false;
        const indexToDelete: Array<number> = [];
        options.forEach((option, index) => {
            if (option.count === 0) {
                if (preOptionMayNeedDelete) {
                    indexToDelete.push(index - 1);
                }
                if (!option.checked) {
                    preOptionMayNeedDelete = true;
                } else {
                    preOptionMayNeedDelete = false;
                }
            } else {
                preOptionMayNeedDelete = false;
            }
        });
        indexToDelete.reverse().forEach(num => options.splice(num, 1));
    }
}
