import { combineReducers, createSelector } from '@ngrx/store';

import { AppState } from '..';
import { SystemActions, SystemActionTypes } from '../../tamalelibs/redux/actions/system.actions';

export function currentContactReducer(state = null, action: SystemActions) {
    switch (action.type) {
        case SystemActionTypes.INIT_CURRENT_CONTACT_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function visibleScreenReducer(state: Array<any>, action: SystemActions) {
    let newState;
    let tempIndex;
    switch (action.type) {
        case SystemActionTypes.SWITCH_VISIBLE:
            newState = Object.assign([], state);
            tempIndex = newState.findIndex(item => item.id === action.payload.id);
            if (action.payload.visible) {
                if (tempIndex > -1) {
                    newState[tempIndex].visible = action.payload.visible;
                } else {
                    newState.push(action.payload);
                }
            } else {
                if (tempIndex > -1) {
                    newState.splice(tempIndex, 1);
                }
            }
            return newState;
        case SystemActionTypes.GET_VISIBLE_SCREENS_SUCCESS:
            return action.payload;
        case SystemActionTypes.GET_VISIBLE_SCREENS_FAILED:
            return action.payload;
        case SystemActionTypes.ADD_SCREEN:
            newState = Object.assign([], state);
            newState.push(action.payload);
            return newState;
        case SystemActionTypes.DELETE_SCREEN:
            tempIndex = state ? state.findIndex(item => item.id === action.payload.id) : -1;
            if (tempIndex > -1) {
                newState = Object.assign([], state);
                newState.splice(tempIndex, 1);
            } else {
                newState = state;
            }
            return newState;
        case SystemActionTypes.EDIT_SCREEN:
            tempIndex = state.findIndex(item => item.id === action.payload.id);
            if (tempIndex > -1) {
                newState = Object.assign([], state);
                newState[tempIndex] = action.payload;
                return newState;
            } else {
                return state;
            }
        default:
            return state;
    }
}

export function manageScreensReducer(state: Array<any>, action: SystemActions) {
    let newState;
    let tempIndex;
    switch (action.type) {
        case SystemActionTypes.ADD_SCREEN:
            newState = Object.assign([], state);
            newState.push(action.payload);
            return newState;
        case SystemActionTypes.DELETE_SCREEN:
            tempIndex = state ? state.findIndex(item => item.id === action.payload.id) : -1;
            if (tempIndex > -1) {
                newState = Object.assign([], state);
                newState.splice(tempIndex, 1);
            } else {
                newState = state;
            }
            return newState;
        case SystemActionTypes.EDIT_SCREEN:
            tempIndex = state.findIndex(item => item.id === action.payload.id);
            if (tempIndex > -1) {
                newState = Object.assign([], state);
                newState[tempIndex] = action.payload;
                return newState;
            } else {
                return state;
            }
        case SystemActionTypes.SWITCH_VISIBLE:
            tempIndex = state.findIndex(item => item.id === action.payload.id);
            if (tempIndex > -1) {
                newState = Object.assign([], state);
                newState[tempIndex].visible = action.payload.visible;
                return newState;
            }
            return state;
        case SystemActionTypes.GET_SCREENS_SUCCESS:
            return action.payload;
        case SystemActionTypes.GET_SCREENS_FAILED:
        default:
            return state;
    }
}

export function contactsReducer(state: Array<any>, action: SystemActions) {
    switch (action.type) {
        case SystemActionTypes.GET_CONTACTS_SUCCESS:
            return action.payload;
        case SystemActionTypes.GET_CONTACTS_FAILED:
        default:
            return state;
    }
}

export function unCategorizedIdReducer(state = null, action: SystemActions) {
    switch (action.type) {
        case SystemActionTypes.GET_UNCATEGORUZEEID_SUCCESS:
            return action.payload;
        case SystemActionTypes.GET_UNCATEGORUZEEID_FAILED:
        default:
            return state;
    }
}

export const systemSelector = (state: AppState) => state.system;
export const systemCurrentContactSelector = createSelector(systemSelector, (state: any) => Object.assign({}, state.currentContact));
export const visibleScreensSelector = createSelector(systemSelector, (state: any) => state.visibleScreens);
export const screensSelector = createSelector(systemSelector, (state: any) => state.screens);
export const contactsSelector = createSelector(systemSelector, (state: any) => state.contacts);
export const unCategorizedId = createSelector(systemSelector, (state: any) => state.unCategorizedId);

const systemReducer = combineReducers({
    currentContact: currentContactReducer,
    visibleScreens: visibleScreenReducer,
    screens: manageScreensReducer,
    contacts: contactsReducer,
    unCategorizedId: unCategorizedIdReducer,
});

export function getSystemReducer(state, action) {
    return systemReducer(state, action);
}
