/**
 * Created by Yu Zhang on 7/11/17.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';

import { ILoggerInstanceService } from './logger-instance.interface.service';

@Injectable()
export class LoggerService {
    private _name: string;

    constructor(private _logger: ILoggerInstanceService) { }

    setName(name: string) {
        this._name = name;
    }

    log(msg, category?) {
        this._logger.log(this._name, msg, category);
    }

    info(msg, category?) {
        this._logger.info(this._name, msg, category);
    }

    warn(msg, category?) {
        this._logger.warn(this._name, msg, category);
    }

    error(msg, category?) {
        this._logger.error(this._name, msg, category);
    }

    debug(msg, category?) {
        this._logger.debug(this._name, msg, category);
    }
}
