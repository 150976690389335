/**
 * -----------------------------------
 * Updated by Abner Sui on 06/27/2019
 * Change canActivate if part to return Observable<boolean> to fit ssoSetting may have no data
 */
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthHelperService } from '../../services/auth-helper.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../redux';
import { filter, take, map } from 'rxjs/operators';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _authHelper: AuthHelperService,
        private _store: Store<AppState>,
    ) { }

    canActivate(
    ): Observable<boolean> | Promise<boolean> | boolean {
        const token = this._authHelper.getToken();
        if (!token) {
            return this._store.pipe(
                select('ssoSetting'),
                filter(item => item),
                take(1),
                map(sso => {
                    if (sso && sso.ssoEnabled) {
                        const url = sso.idpSsoUrl;
                        window.open(url, '_self');
                        return false;
                    } else {
                        return true;
                    }
                }),
            );
        } else {
            this._router.navigate(['/']);
            return false;
        }
    }
}

@Injectable()
export class AdminLoginGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _authHelper: AuthHelperService
    ) { }

    canActivate(
    ): Observable<boolean> | Promise<boolean> | boolean {
        const token = this._authHelper.getToken();
        if (!token) {
            return true;
        } else {
            this._router.navigate(['/']);
            return false;
        }
    }
}

