/**
 * Created by Yoyo Fang on 01/17/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { EmailDigestActionTypes, EmailDigestActions } from '../actions/email-digest.actions';
import { AppState } from '..';
import { createSelector, combineReducers } from '@ngrx/store';
import { EmailDigest } from '../../tamalelibs/models/emaildigest.model';

export const emailDigestSelector = (state: AppState) => state.emailDigest;
export const emailDigestListSelector = createSelector(emailDigestSelector, (state: any) => state.items);
export const emailDigestConfigSelector = createSelector(emailDigestSelector, (state: any) => state.config);

export function emailDigestListItemsReducer(state: Array<EmailDigest>, action: EmailDigestActions) {
    let newState: Array<EmailDigest>;
    let index;
    switch (action.type) {
        case EmailDigestActionTypes.SWITCH_SUBSCRIPTION_SUCCESS:
            newState = Object.assign([], state);
            index = newState.findIndex(x => x.id === action.payload.id);
            if (index >= 0) {
                newState[index].isSubscribed = action.payload.isSubscribed;
            }
            return newState;
        case EmailDigestActionTypes.SWITCH_ACTIVE_SUCCESS:
            newState = Object.assign([], state);
            index = newState.findIndex(item => item.id === action.payload.id);
            if (index > -1) {
                newState[index].active = action.payload.active;
            }
            return newState;
        case EmailDigestActionTypes.LOAD_SUCCESS:
            return action.payload.items;
        case EmailDigestActionTypes.ADD_CONFIG:
            newState = Object.assign([], state);
            newState.unshift(action.payload);
            return newState;
        case EmailDigestActionTypes.UPDATE_CONFIG:
            newState = Object.assign([], state);
            index = newState.findIndex(item => item.id === action.payload.id);
            if (index > -1) {
                newState.splice(index, 1);
                newState.unshift(action.payload);
            }
            return newState;
        case EmailDigestActionTypes.DELETE_CONFIG:
            newState = Object.assign([], state);
            index = newState.findIndex(item => item.id === action.payload.id);
            if (index > -1) {
                newState.splice(index, 1);
            }
            return newState;
        case EmailDigestActionTypes.LOAD_FAILED:
        default:
            return state;
    }
}

export function emailDigestListCountReducer(state: number, action: EmailDigestActions) {
    switch (action.type) {
        case EmailDigestActionTypes.LOAD_SUCCESS:
            return action.payload.count;
        case EmailDigestActionTypes.LOAD_FAILED:
        default:
            return state;
    }
}

export function emailDigestConfigReducer(state: EmailDigest = null, action: EmailDigestActions) {
    switch (action.type) {
        case EmailDigestActionTypes.SET_CONFIG:
            return action.payload;
        case EmailDigestActionTypes.GET_EMAIL_DIGEST_CONFIG_SUCCESS:
            return action.payload;
        case EmailDigestActionTypes.CLEAR_CONFIG:
            return null;
        default:
            return state;
    }
}

const emailDigestReducer = combineReducers({
    count: emailDigestListCountReducer,
    items: emailDigestListItemsReducer,
    config: emailDigestConfigReducer,
});

export function getEmailDigestReducer(state, action) {
    return emailDigestReducer(state, action);
}
