/**
 * Create by Bowen: 2019/12/1
 * Description:
 * fitler panel for add entity/contact to relationship
 * ------ maintenance history ------
 */

import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FilterWidgetFeedback, FilterWidgetFeedbackType, FilterWidgetConfig } from '../../../tamalelibs/components/filter-widget.config';
import { FiltersConfig } from '../../../tamalelibs/components/filter-config';
import { take, catchError, debounceTime, filter } from 'rxjs/operators';
import { of as observableOf, Subscription, Subject } from 'rxjs';
import { EntityBrief } from '../../../tamalelibs/models/entity-brief.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppConfig } from '../../../tamalelibs/models/app-config.model';
import { EntityService } from '../../../tamalelibs/services/entity.service';
import { AppState } from '../../../redux';
import { Store, select } from '@ngrx/store';
import { EntityDialogOpenOptions, EntityDialogType, EntityBack } from '../../../components/entity-dialog/entity-dialog.model';
import { OpenDynamicComponentService } from '../../../pages/home/home-open-dynamic-component';
import { availableEntityTypeSelector } from '../../../redux/reducers/entity-type.reducer';
import { EntityType } from '../../../tamalelibs/models/entity-type.model';
import { ToastService } from '../../toast/toast.service';
import { NotificationStyles } from '../../notification/notification.model';
import { ContactTemplateOpenOptions, ContactTemplateType } from '../../../components/contact-dialog-new/contact-dialog.model';
import { Contact } from '../../../tamalelibs/models/contact.model';
import { ContactTemplateService } from '../../../components/contact-dialog-new/contact-template.service';
import { EntryService } from '../../../tamalelibs/services/entry.service';

@Component({
    selector: 'tam-add-entity-filter',
    templateUrl: './tam-add-entity-filter.component.html',
    styleUrls: ['../tam-filter-container.component.scss']
})
export class TamAddEntityFilterComponent implements OnInit, OnDestroy {

    @Input() config: FiltersConfig; // data
    @Input() filterWidgetConfig: FilterWidgetConfig; // event
    @ViewChild('entitylist', { static: true }) entitylist;

    dataItems: Array<EntityBrief> = []; // suggestion items based on search, sub set of dataItems
    filterStr = '';
    flyValue = '';
    isItemHoverOn = false;
    operators: Array<{ name: string, value: string }>;
    operator: { name: string, value: string };
    selectedItems: Array<EntityBrief> = []; // selected items, sub set of dataItems


    private _filterValue: string;
    private _page: number;
    private _pageSize: number = AppConfig.searchingPageSize;
    private _source: Array<string>;
    private _filterHandler$: Subject<string> = new Subject();
    private _destroySubscriptions: Array<Subscription> = [];
    private _entityTypes: Array<EntityType>;
    constructor(
        private _deviceService: DeviceDetectorService,
        private _entityService: EntityService,
        private _entryService: EntryService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _store: Store<AppState>,
        private _openDynamicComponentService: OpenDynamicComponentService,
        private _toast: ToastService,
        private _contactDialogService: ContactTemplateService,
    ) { }

    ngOnInit() {
        this._destroySubscriptions.push(
            this._filterHandler$.pipe(
                debounceTime(250),
                filter(filterStr => filterStr != null && filterStr.length > 0)
            ).subscribe(filterStr => this.searching(filterStr)),
            this._store.pipe(select(availableEntityTypeSelector),
                filter(res => res != null)
            ).subscribe(res => {
                this._entityTypes = res.slice();
            }));

        if (this.config.source) {
            this._source = this.config.source;
        }
        if (this.config.value) {
            this.selectedItems = this.config.value;
        }
        this.searchingFirstPage();
        // For use within normal web clients
        const isiPad = this._deviceService.isMobile() || this._deviceService.isTablet();
        if (isiPad) {
            this.isItemHoverOn = true;
        } else {
            this.isItemHoverOn = !this.config.deletable;
        }
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    createEntity() {
        const _entityDialogOpenOptions = new EntityDialogOpenOptions();
        _entityDialogOpenOptions.openType = EntityDialogType.NewEntity;
        _entityDialogOpenOptions.entityName = this.filterStr;
        _entityDialogOpenOptions.instanceId = 'dynamic-1';
        _entityDialogOpenOptions.searchValue = this.filterStr;
        // set entityTypeId for fly entity-Dialog
        if (this.config.source && this.config.source.entityType && this.config.source.entityType.length > 0) {
            // make the right entity type be from source.entityType
            _entityDialogOpenOptions.entityType = this.config.source.entityType;
            const hasType = this.config.source.entityType.some(item => {
                return this._entityTypes.findIndex(abc => abc.id === item) > -1;
            });
            if (hasType) {
                this._openDynamicComponentService.feedbackSubject$.pipe(
                    take(1),
                ).subscribe((feedback: EntityBack) => {
                    if (feedback.entityId) {
                        this._entityService.getEntityListByIdsQuick([feedback.entityId])
                            .subscribe(res => {
                                this._setFlyValue(res);
                            });
                    }
                });
                this._openDynamicComponentService.openDialog$.next(_entityDialogOpenOptions);
            } else {
                this._toast.notify({
                    message: 'You do not have access to create entity of this type.',
                    style: NotificationStyles.Info
                });
            }
        } else {
            this._openDynamicComponentService.openDialog$.next(_entityDialogOpenOptions);
        }
    }

    createContact() {
        const _contactDialogOpenOptions = new ContactTemplateOpenOptions();
        _contactDialogOpenOptions.openType = ContactTemplateType.NewContact;
        const contact = new Contact();
        contact.name = this.filterStr;
        _contactDialogOpenOptions.contact = contact;
        const entityBack = new EntityBack();
        entityBack.searchValue = this.filterStr;
        _contactDialogOpenOptions.entityBack = entityBack;
        _contactDialogOpenOptions.isFly = true;
        this._openDynamicComponentService.feedbackSubject$.pipe(
            take(1),
        ).subscribe((feedback: EntityBack) => {
            if (feedback.entityId) {
                this._entityService.getEntityListByIdsQuick([feedback.entityId])
                    .subscribe(res => {
                        this._setFlyValue(res);
                    });
            }
        });
        this._contactDialogService.dialogOpen$.next(_contactDialogOpenOptions);
    }

    handleFilter(value) {
        this.filterStr = value.trim();
        if (this._filterValue && !this.filterStr) {
            this.searchingFirstPage();
        }
        this._filterHandler$.next(this.filterStr);
        this._filterValue = this.filterStr;
    }

    onMouseOver() {
        if (!this.config.deletable) {
            this.isItemHoverOn = true;
        }
    }

    onMouseOut() {
        if (!this.config.deletable) {
            this.isItemHoverOn = false;
        }
    }

    onBtnCloseClicked() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.remove;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    searchingFirstPage() {
        this.searching('');
    }

    searching(text) {
        this._page = 0;
        this.entitylist.loading = true;
        this._destroySubscriptions.push(
            this._entityService.getEntityListBySearchTextQuick(++this._page, this._pageSize, text, this._source).pipe(
                take(1),
                catchError((e) => {
                    return observableOf();
                }))
                .subscribe(res => {
                    this.searchingDone(res);
                    this.entitylist.loading = false;
                    this._changeDetectorRef.detectChanges();
                })
        );
    }

    searchingDone(entityList) {
        this.dataItems = this._entityService.mapEntityBriefList(entityList);
        if (this.config.currentUser) {
            this.dataItems = this.dataItems.filter(item => item.id !== this.config.currentUser.id);
            this.flyValue = this._entryService.isShowOnTheFly(this.filterStr, this.dataItems);
        }
    }

    valueChange(): void {
        // emits event for value change
        if (this.selectedItems.length === 0) {
            this.searchingFirstPage();
        }
        this._filterValue = '';
        this._feedback();
    }

    private _feedback() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.update;
        this.config.value = this.selectedItems;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    // set the creating new entity to dateItem and selectedItem list
    private _setFlyValue(entityList) {
        this.flyValue = '';
        if (entityList) {
            const tempList = this._entityService.mapEntityBriefList(entityList);
            if (tempList) {
                const item = tempList[0];
                if (item) {
                    this.dataItems.push(item);
                    this.selectedItems.push(item);
                    this._feedback();
                    this._changeDetectorRef.detectChanges();
                }
            }
        }
    }

}
