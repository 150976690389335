import { CorpOfficer } from './corp-officer.mode';

export class CorporateDetail {
    address: string;
    email: string;
    fax: string;
    numberOfEmployees: string;
    officers: Array<CorpOfficer>;
    phone: string;
    latitude: number;
    longitude: number;
    zip?: string;
    country?: string;
    city?: string;
    street?: string;
    state?: string;
    street2?: string;



    static parse(serverResponse): CorporateDetail {
        return {
            address: serverResponse['address'] ? serverResponse['address'] : '',
            email: serverResponse['email-address'] ? serverResponse['email-address'] : '',
            fax: serverResponse['fax'] ? serverResponse['fax'] : '',
            numberOfEmployees: serverResponse['employee-count'] ? serverResponse['employee-count'] : '',
            officers: serverResponse['officers'] && serverResponse['officers'].data ?
                serverResponse['officers'].data.map(data => CorpOfficer.parse(data)) : [],
            phone: serverResponse['phone'],
            latitude: serverResponse['web-address'] ? serverResponse['web-address']['latitude'] : '',
            longitude: serverResponse['web-address'] ? serverResponse['web-address']['longitude'] : '',
            zip: serverResponse['web-address'] ? serverResponse['web-address']['zip'] : '',
            country: serverResponse['web-address'] ? serverResponse['web-address']['country'] : '',
            city: serverResponse['web-address'] ? serverResponse['web-address']['city'] : '',
            street: serverResponse['web-address'] ? serverResponse['web-address']['street'] : '',
            state: serverResponse['web-address'] ? serverResponse['web-address']['state'] : '',
            street2: serverResponse['web-address'] ? serverResponse['web-address']['street2'] : ''
        };
    }
}
