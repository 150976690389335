/**
 * created by Yu Zhang on 9/7/17.
 * Description:
 *
 * ------ maintenance history ------
 *  11/06/2018   Marcus Zhao   Add new function 'buildEntryTemplateParamsForWeb' for web 2.0.
 *  06/06/2019   Yoyo Fang   remove ':' from the label when it is used as warning of missing required field
 *  08/01/2019   Marcus Zhao  in edit mode, if the actual value is empty ,don't append dependence value to mdl control line 545.
 *  08/21/2019   Marcus Zhao  according the action to judge the type for date update in processSaveActionsForWeb function.
 *  09/23/2019   Marcus Zhao - Add  relationshipSaveAction() .reuse for entry\entity\event;
 *  011/05/2019  Marcus Zhao   follow logic  ahoc date need add deposit time on create entry .but not on create entity.
 */
import { Injectable } from '@angular/core';

import { StandardControlIds, NoteBodyHtmlMetadatas, ComponentTypes, NoteActions } from '../constants/business.constants';
import { StringHelperService } from './string-helper.service';
import { DateHelperService } from './date-helper.service';
import { AppConfig } from '../models/app-config.model';
import { BlastEmail } from '../models/blastemail.model';
import { DocumentEmail } from '../models/document-email.model';
import { BriefObject } from '../models/brief-object';
import { Entity } from '../models/entity.model';
import { TemplateControlValue } from '../models/template-control-value.model';

@Injectable()
export class TemplateService {
    private _delimiter = ';';
    constructor(private _dateHelper: DateHelperService) { }

    processStandardFields(valueSet) {
        if (valueSet[StandardControlIds.subject] == null) {
            valueSet[StandardControlIds.subject] = {
                value: '',
                adhocDefId: StandardControlIds.subject.toLowerCase(),
                label: StandardControlIds.subject,
                componentType: ComponentTypes.subject
            };
        }

        if (valueSet[StandardControlIds.notebody] == null) {
            valueSet[StandardControlIds.notebody] = {
                value: '',
                adhocDefId: StandardControlIds.notebody.toLowerCase(),
                label: StandardControlIds.notebody,
                componentType: ComponentTypes.notebody
            };
        }

        if (valueSet[StandardControlIds.entity] == null) {
            valueSet[StandardControlIds.entity] = {
                value: '',
                adhocDefId: StandardControlIds.entity.toLowerCase(),
                label: StandardControlIds.entity,
                isRequired: true,
                componentType: ComponentTypes.entity
            };
        }

        if (valueSet[StandardControlIds.source] == null) {
            valueSet[StandardControlIds.source] = {
                value: '',
                adhocDefId: StandardControlIds.source.toLowerCase(),
                label: StandardControlIds.source,
                isRequired: true,
                componentType: ComponentTypes.source
            };
        }

        if (valueSet[StandardControlIds.displaydate] == null) {
            valueSet[StandardControlIds.displaydate] = {
                value: '',
                adhocDefId: StandardControlIds.displaydate.toLowerCase(),
                label: StandardControlIds.displaydate,
                componentType: ComponentTypes.displaydate
            };
        }

        return valueSet;
    }

    processDisplaydateFields(valueSet) {
        valueSet[StandardControlIds.displaydate] = {
            value: '',
            adhocDefId: StandardControlIds.displaydate.toLowerCase(),
            label: StandardControlIds.displaydate,
            componentType: ComponentTypes.displaydate
        };
        return valueSet;
    }

    // tslint:disable-next-line: max-line-length
    // TODO privacyTeamId will be privacyTeam object, {id,name}, so we can share code better. Web formula need whole object. see formulaService in web
    processSaveActions(valueSet, privacyTeamId) {
        let appendEntities = '';
        // tslint:disable-next-line: forin
        for (const controlValue in valueSet) {
            if (valueSet[controlValue].saveActions
                && valueSet[controlValue].saveActions.overideNoteDisplayDate
                && valueSet[controlValue].value) {
                if (!this._dateHelper.isFutureDateByTimeString(valueSet[controlValue].value)) {
                    valueSet[StandardControlIds.displaydate].value = valueSet[controlValue].value;
                }
            }

            // when "Append the selected entities to the Entities field" is checked
            if (valueSet[controlValue].saveActions
                && valueSet[controlValue].saveActions.appendToEntities) {
                if (valueSet[controlValue].value.length > 0) { // "instanceof" return false for literals
                    appendEntities = this.appendEntities(appendEntities, valueSet[controlValue].value.split(',').join(';'));
                }
            }

            // when "Use the selected Contact as the Source" is checked
            if (valueSet[controlValue].saveActions && valueSet[controlValue].saveActions.setSource) {
                if (valueSet[controlValue].value) {
                    valueSet[StandardControlIds.source].value = valueSet[controlValue].value;
                }
            }

            // for Check Box only run save action when it is checked
            if (valueSet[controlValue].componentType === 'cb-input' && valueSet[controlValue].actualValue) {
                // when "Append the following Entity to the Entities field" is checked
                if (valueSet[controlValue].saveActions && valueSet[controlValue].saveActions.appendValueToEntities) {
                    const checkBoxEntities = valueSet[controlValue].saveActions.appendValueToEntities;
                    if (checkBoxEntities) {
                        appendEntities = this.appendEntities(appendEntities, checkBoxEntities);
                    }
                }
                // when "Append the entity from the following control to the Entities field" is checked
                if (valueSet[controlValue].saveActions && valueSet[controlValue].saveActions.appendControlValueToEntities) {
                    const tmpObj = valueSet[valueSet[controlValue].saveActions.appendControlValueToEntities];
                    if (tmpObj.value instanceof Array && tmpObj.value.length > 0) {
                        appendEntities = this.appendEntities(appendEntities, tmpObj.value.join(';'));
                    } else if (typeof tmpObj.value === 'string' && tmpObj.value) { // "instanceof" return false for literals
                        appendEntities = this.appendEntities(appendEntities, tmpObj.value);
                    }
                }
            }
        }

        // Append privacy to entities when adding note
        if (privacyTeamId) {
            // AppConfig.privacyTeamPublicId ='000000'
            if (privacyTeamId !== AppConfig.privacyTeamPublicId) {
                appendEntities = this.appendEntities(appendEntities, privacyTeamId);
            }
        }

        if (appendEntities.length > 0) {
            if (valueSet[StandardControlIds.entity].value.length > 0) {
                valueSet[StandardControlIds.entity].value += ';' + appendEntities;
            } else {
                valueSet[StandardControlIds.entity].value = appendEntities;
            }
        }

        return valueSet;
    }

    appendEntities(appendEntities, entityStr: string) {
        if (appendEntities.length > 0) {
            return appendEntities += ';' + entityStr.split(',').join(';');
        } else {
            return entityStr.split(',').join(';');
        }
    }

    processSaveActionsForWeb(valueSet: Array<any>, privacyTeam: any, isEntity: boolean = false,
        action: string, saveAction?: string) { // Will change native code to use this method
        let appendEntities = '';
        const appendEntitySet = new Array<BriefObject>();

        // tslint:disable-next-line: forin
        for (const controlValue in valueSet) {
            if (valueSet[controlValue].saveActions
                && valueSet[controlValue].saveActions.overideNoteDisplayDate
                && valueSet[controlValue].value) {
                if (!this._dateHelper.isFutureDateByTimeString(valueSet[controlValue].value.getTime().toString())) {
                    if (isEntity) {
                        valueSet[StandardControlIds.displaydate] = {
                            value: '',
                            adhocDefId: StandardControlIds.displaydate.toLowerCase(),
                            label: StandardControlIds.displaydate,
                            componentType: ComponentTypes.displaydate
                        };
                    }
                    const today = new Date();
                    // tslint:disable-next-line: triple-equals
                    const isToday = valueSet[controlValue].value.getDate() == today.getDate()
                        && valueSet[controlValue].value.getMonth() === today.getMonth()
                        && valueSet[controlValue].value.getFullYear() === today.getFullYear();
                    // For replace display date, isSameDate need judge again.
                    if (valueSet[StandardControlIds.displaydate].isSameDate) {
                        valueSet[StandardControlIds.displaydate].isSameDate =
                            this._dateHelper.compareDate(valueSet[StandardControlIds.displaydate].value, valueSet[controlValue].value);
                    }

                    if (isToday === false) {
                        valueSet[StandardControlIds.displaydate].value = valueSet[controlValue].value;
                    } else {
                        if (action !== NoteActions.createNote || action !== NoteActions.createSidenote) {
                            valueSet[StandardControlIds.displaydate].value = valueSet[controlValue].value;
                        }
                    }
                }
            }

            // when "Append the selected entities to the Entities field" is checked
            if (valueSet[controlValue].saveActions
                && valueSet[controlValue].saveActions.appendToEntities
                && saveAction !== NoteActions.saveDraft) {
                if (valueSet[controlValue].value.length > 0) { // "instanceof" return false for literals
                    const entitySet = new Array<BriefObject>();
                    if (valueSet[controlValue].actualValue instanceof Array) {
                        entitySet.push(...valueSet[controlValue].actualValue);
                    } else if (valueSet[controlValue].actualValue && valueSet[controlValue].actualValue.id) {
                        entitySet.push(valueSet[controlValue].actualValue);
                    }

                    appendEntities = this.appendEntities(appendEntities, valueSet[controlValue].value.split(',').join(';'));
                    appendEntitySet.push(...entitySet);
                }
            }
            // when "Use the selected Contact as the Source" is checked
            if (valueSet[controlValue].saveActions && valueSet[controlValue].saveActions.setSource) {
                if (valueSet[controlValue].value && !isEntity) {
                    valueSet[StandardControlIds.source].value = valueSet[controlValue].value;
                    valueSet[StandardControlIds.source].actualValue = valueSet[controlValue].actualValue;
                }
            }

            // for Check Box only run save action when it is checked
            if (valueSet[controlValue].componentType === 'cb-input' && valueSet[controlValue].actualValue) {
                // when "Append the following Entity to the Entities field" is checked
                if (valueSet[controlValue].saveActions && valueSet[controlValue].saveActions.appendValueToEntities) {
                    const checkBoxEntities = valueSet[controlValue].saveActions.appendValueToEntities.id;
                    if (checkBoxEntities) {
                        appendEntities = this.appendEntities(appendEntities, checkBoxEntities);
                        appendEntitySet.push(...[new BriefObject(checkBoxEntities,
                            valueSet[controlValue].saveActions.appendValueToEntities['short-name'])]);
                    }
                }
                // when "Append the entity from the following control to the Entities field" is checked
                if (valueSet[controlValue].saveActions && valueSet[controlValue].saveActions.appendControlValueToEntities) {
                    const tmpObj = valueSet[valueSet[controlValue].saveActions.appendControlValueToEntities];
                    if (tmpObj.value instanceof Array && tmpObj.value.length > 0) {
                        appendEntities = this.appendEntities(appendEntities, tmpObj.value.join(';'));
                    } else if (typeof tmpObj.value === 'string' && tmpObj.value) { // "instanceof" return false for literals
                        appendEntities = this.appendEntities(appendEntities, tmpObj.value);
                    }
                    if (tmpObj.actualValue instanceof Array && tmpObj.actualValue.length > 0) {
                        appendEntitySet.push(...tmpObj.actualValue);
                    } else if (tmpObj.actualValue && tmpObj.actualValue.id) {
                        appendEntitySet.push(tmpObj.actualValue);
                    }
                }
            }
        }

        if (appendEntities.length > 0 && !isEntity) {
            if (valueSet[StandardControlIds.entity].value.length > 0) {
                valueSet[StandardControlIds.entity].value += ';' + appendEntities;
                valueSet[StandardControlIds.entity].actualValue.push(...appendEntitySet);
            } else {
                valueSet[StandardControlIds.entity].value = appendEntities;
                valueSet[StandardControlIds.entity].actualValue = appendEntitySet;
            }
        }

        return valueSet;
    }

    /**
     *
     * param templateValueSet
     * param entity  :if exist ,refer to come form Entity Dialog.
     */
    relationshipSaveAction(templateValueSet: any, entity: Entity): Array<any> {
        const relationshipArray: Array<any> = [];
        // tslint:disable-next-line: forin
        for (const controlValue in templateValueSet) {
            // Get append entities
            let appendRelationship = false;
            const relationshipObj: any = {};
            let currentControlValues = [];
            let dependenceControlValues = [];
            let relField = '';
            let relTypeId = '';
            const control = templateValueSet[controlValue];
            if (control.saveActions
                && control.saveActions.createRelationship
                && control.saveActions.createRelationship.relationshipTypeId) {
                relField = control.saveActions.createRelationship.field;
                relTypeId = control.saveActions.createRelationship.relationshipTypeId;
                appendRelationship = !!control.saveActions.createRelationship.appendRelationship;
                // Get current control values
                if (relField.length > 0 && relTypeId.length > 0) {
                    if (control.value && control.value.length > 0) {
                        // get all entities
                        currentControlValues = control.value.split(';');
                    }
                }
                // Get dependent control values
                if (relField.length > 0) {
                    if (relField === 'SUBMITTER' || relField === 'CURRENTUSER') {
                        dependenceControlValues.push(AppConfig.userId);
                    } else if (relField === 'CURRENTENTITY') {
                        if (entity) {
                            dependenceControlValues.push(entity.id);
                        }
                    } else {
                        const dependenceControlValueString = templateValueSet[relField].value;
                        // get all entities, figure out the delimiter is , or ;
                        if (dependenceControlValueString && dependenceControlValueString.indexOf(',') > -1) {
                            dependenceControlValues = dependenceControlValueString.split(',');
                        } else if (dependenceControlValueString && dependenceControlValueString.indexOf(';') > -1) {
                            dependenceControlValues = dependenceControlValueString.split(';');
                        } else {
                            if (dependenceControlValueString !== '') {
                                dependenceControlValues = [dependenceControlValueString];
                            }
                        }
                    }
                }
                if (relTypeId.length > 0 && dependenceControlValues && dependenceControlValues.length > 0) {
                    relationshipObj['relTypeId'] = relTypeId;
                    relationshipObj['currentControlValues'] = currentControlValues;
                    relationshipObj['dependenceControlValues'] = dependenceControlValues;
                    relationshipObj['appendRelationship'] = appendRelationship;
                    if (appendRelationship && currentControlValues.length === 0) {
                        continue;
                    } else {
                        relationshipArray.push(relationshipObj);
                    }
                }
            }
        }
        if (relationshipArray.length > 0) {
            return relationshipArray;
        }
        return [];
    }

    appendPrivacyTeamToEntitiesForWeb(valueSet, privacyTeam) { // Need refactor native code to use this method
        const appendEntitySet = new Array<BriefObject>();
        let appendEntities = '';
        // Append privacy to entities when adding note
        if (privacyTeam && privacyTeam.id) {
            // AppConfig.privacyTeamPublicId ='000000'
            if (privacyTeam.id !== AppConfig.privacyTeamPublicId) {
                appendEntities = this.appendEntities(appendEntities, privacyTeam.id);
                appendEntitySet.push(...[new BriefObject(privacyTeam.id, privacyTeam.name)]);
            }
        }
        if (appendEntities.length > 0) {
            if (valueSet[StandardControlIds.entity].value.length > 0) {
                valueSet[StandardControlIds.entity].value += ';' + appendEntities;
                valueSet[StandardControlIds.entity].actualValue.push(...appendEntitySet);
            } else {
                valueSet[StandardControlIds.entity].value = appendEntities;
                valueSet[StandardControlIds.entity].actualValue = appendEntitySet;
            }
        }
        return valueSet;
    }

    checkRequiredFields(valueSet, isDraft: boolean = false) {
        const resultArray = [];
        if (!isDraft) {
            // tslint:disable-next-line: forin
            for (const key in valueSet) {
                const ctrl = valueSet[key];
                // checkbox required value must be 'true', 'false' means empty
                // for other control, check if the value is null or no length
                // skip displaydate and source
                if (ctrl.isRequired === true &&
                    (ctrl.value === null || ctrl.value.length === 0 || (typeof ctrl.value === 'boolean' && ctrl.value === false)) &&
                    ctrl.componentType !== ComponentTypes.displaydate &&
                    ctrl.componentType !== ComponentTypes.source) {
                    let missingRequiredField = '';
                    if (ctrl.label) {
                        missingRequiredField = ctrl.label.replace(':', '');
                    }
                    resultArray.push(missingRequiredField);
                }
            }
        }
        return resultArray;
    }

    buildEntryTemplateParams(valueSet, entryTypeId, noteAction?, entryId?) {
        const params = {};
        if (valueSet == null) {
            return params;
        }
        if (entryTypeId.length > 0) {
            params['entry-type'] = entryTypeId;
        }
        // add parent note id when creating sidenote or editing sidnote
        if (noteAction && entryId && noteAction === NoteActions.createSidenote && entryId.length > 0) {
            params['parentid'] = entryId;
        }
        for (const controlValue in valueSet) {
            // Entities use ',' as delimiter; Adhoc field use ';' as delimiter
            if ((noteAction === NoteActions.createSidenote || noteAction === NoteActions.editSidenote)
                && controlValue === StandardControlIds.entity) {
                continue;
            }
            if (controlValue === StandardControlIds.notebody) {
                continue;
            }
            if (controlValue === StandardControlIds.displaydate) {
                if (valueSet[controlValue].value.length > 0) {
                    const today = new Date();
                    const tmpDate = new Date(+valueSet[controlValue].value);
                    tmpDate.setHours(today.getHours());
                    tmpDate.setMinutes(today.getMinutes());
                    tmpDate.setSeconds(today.getSeconds());
                    tmpDate.setMilliseconds(today.getMilliseconds());
                    valueSet[controlValue].value = tmpDate.getTime();
                } else {
                    continue;
                }
            }
            if (controlValue === StandardControlIds.source && valueSet[controlValue].value.length === 0) {
                continue;
            }
            params[valueSet[controlValue].adhocDefId] = valueSet[controlValue].value;
        }
        return params;
    }

    /**
     * use to web 2.0, Change the type passed by displaydate from string to Date.
     * param valueSet
     * param entryTypeId
     * param noteAction
     * param entryId
     * param isEntity
     * param isDraft
     */
    buildEntryTemplateParamsForWeb(valueSet, entryTypeId, noteAction?, entryId?, isEntity?,
        processDisplayDate: boolean = true, isDraft: boolean = false, saveAction?, isPlugin: boolean = false) {
        const params: any = {};
        if (valueSet == null) {
            return params;
        }
        if (entryTypeId.length > 0) {
            params['entry-type'] = entryTypeId;
        }
        // add parent note id when creating sidenote or editing sidnote
        if (noteAction && entryId && noteAction === NoteActions.createSidenote && entryId.length > 0) {
            params['parentid'] = entryId;
        }
        for (const controlValue in valueSet) {
            // Entities use ',' as delimiter; Adhoc field use ';' as delimiter
            if ((noteAction === NoteActions.createSidenote || noteAction === NoteActions.editSidenote)
                && controlValue === StandardControlIds.entity) {
                continue;
            }
            if (controlValue === StandardControlIds.notebody) {
                if (noteAction !== NoteActions.createSidenote
                    && noteAction !== NoteActions.editSidenote
                    && saveAction !== NoteActions.saveDraft) {
                    const notebodyDetails = valueSet[StandardControlIds.notebody].notebodyDetails;
                    if (notebodyDetails) {
                        // check if we need to save note body as attachment
                        if (notebodyDetails.saveAsAttachedChecked) {
                            params['savebodyasattachment'] = notebodyDetails.saveAsAttachedChecked;
                        }
                        // check if it has entry form template
                        if (notebodyDetails.prepopulateTemplateChecked) {
                            const prepopulateComboText = notebodyDetails.prepopulateComboText;
                            if (prepopulateComboText && (prepopulateComboText.indexOf('~') > -1)) {
                                // tslint:disable-next-line: max-line-length
                                params['entryformattachmentname'] = prepopulateComboText.substring(prepopulateComboText.lastIndexOf('~') + 1);
                            }
                        }
                    }
                }
            }
            if (controlValue === StandardControlIds.displaydate) {
                if (valueSet[controlValue].value) {
                    const tmpDate = valueSet[controlValue].value;
                    const maxDate = new Date();
                    const min = new Date(1900, 1, 1);
                    const isToday = tmpDate.getDate() === maxDate.getDate()
                        && tmpDate.getMonth() === maxDate.getMonth()
                        && tmpDate.getFullYear() === maxDate.getFullYear();
                    // If is [Edit Draft] or plugin and the date was changed. It will add save time for displaydate.
                    if (noteAction === NoteActions.createNote
                        || noteAction === NoteActions.createSidenote
                        || (noteAction === NoteActions.createNote
                            && saveAction === NoteActions.saveDraft
                            && !valueSet[controlValue].isSameDate && !isPlugin) ||
                        (NoteActions.editNote && isDraft && !isPlugin)
                    ) {
                        tmpDate.setHours(maxDate.getHours());
                        tmpDate.setMinutes(maxDate.getMinutes());
                        tmpDate.setSeconds(maxDate.getSeconds());
                        tmpDate.setMilliseconds(maxDate.getMilliseconds());
                    }


                    if (noteAction && noteAction === NoteActions.editNote && isToday) {
                        valueSet[controlValue].value = tmpDate.getTime();
                    } else {
                        // if tmpDate greater than or equal to Today ,don't input value
                        if (tmpDate == null || tmpDate.getTime() > maxDate.getTime() || isToday ||
                            tmpDate.getTime() < min.getTime()) {
                            if (processDisplayDate) {
                                continue;
                            } else {
                                valueSet[controlValue].value = tmpDate.getTime();
                            }
                        } else {
                            valueSet[controlValue].value = tmpDate.getTime();
                        }
                    }
                    if (noteAction && noteAction === NoteActions.editNote
                        && isToday && isDraft && saveAction !== NoteActions.saveDraft
                        && !isPlugin) {
                        continue;
                    }
                    // If the display date unchanged and in edit note mode. don't send display date.
                    if (noteAction && noteAction === NoteActions.editNote
                        && !isDraft && saveAction !== NoteActions.saveDraft
                        && valueSet[controlValue].isSameDate) {
                        continue;
                    }
                } else {
                    continue;
                }
            }
            if (controlValue === StandardControlIds.source && valueSet[controlValue].value.length === 0) {
                continue;
            }
            // follow logic ahoc date need add deposit time on create entry .but not on create entity.
            if (valueSet[controlValue].value instanceof Date && controlValue !== StandardControlIds.displaydate) {
                const tmpDate = valueSet[controlValue].value;
                if (noteAction === NoteActions.createNote || noteAction === NoteActions.createSidenote && !isEntity) {
                    const currentDate = new Date();
                    tmpDate.setHours(currentDate.getHours());
                    tmpDate.setMinutes(currentDate.getMinutes());
                    tmpDate.setSeconds(currentDate.getSeconds());
                    tmpDate.setMilliseconds(currentDate.getMilliseconds());
                }
                valueSet[controlValue].value = tmpDate.getTime();
            }
            if (valueSet[controlValue].adhocDefId) {
                if (valueSet[controlValue].adhocDefId.toLowerCase() === StandardControlIds.notebody.toLowerCase()) {
                    // if it's body, ignore it. the Body value is passed in formData.
                    continue;
                } else {
                    params[valueSet[controlValue].adhocDefId] = valueSet[controlValue].value;
                }
            }
        }
        return params;
    }

    buildEntryTemplateParamsfromBlastEmail(blastEmail: BlastEmail, entryTypeId) {
        const params = {};
        if (blastEmail == null) {
            return params;
        }
        if (entryTypeId.length > 0) {
            params['entry-type'] = entryTypeId;
        }
        const entityIdList: Array<String> = new Array<String>();
        if (blastEmail.entities) {
            for (let i = 0; i < blastEmail.entities.length; i++) {
                entityIdList.push(blastEmail.entities[i].id);
            }
            params[StandardControlIds.entity.toLowerCase()] = blastEmail.entities.map(item => item.id).join(';');
        }
        if (blastEmail.contact) {
            params[StandardControlIds.source.toLowerCase()] = blastEmail.contact.id;
        } else {
            params[StandardControlIds.source.toLowerCase()] = AppConfig.userId;
        }

        params[StandardControlIds.displaydate.toLowerCase()] = blastEmail.time.getTime();
        params[StandardControlIds.priority.toLowerCase()] = 0;
        params[StandardControlIds.sentiment.toLowerCase()] = 0;
        params[StandardControlIds.subject.toLowerCase()] = blastEmail.subject;
        params['blastemail'] = JSON.stringify({
            'id': blastEmail.id,
            'attachments': this._prepareServerAttachments(blastEmail.attachments)
        });

        return params;
    }

    private _prepareServerAttachments(files: Map<string, DocumentEmail>) {
        let arr;
        arr = Array.from(files.values());
        return arr.map(file => {
            return (file.id + ':' + file.fileName);
        });

    }

    buildEntryTemplateParamsForIntralinks(fields: TemplateControlValue[], entryTypeId) {
        const params = {
            intralinks: true
        };
        if (entryTypeId.length > 0) {
            params['entry-type'] = entryTypeId;
        }
        fields.forEach((field: TemplateControlValue) => {
            if (field.adhocDefId) {
                params['x-' + field.adhocDefId] = field.value;
            } else {
                const labelAsControlId = field.label.replace(':', '').replace(/\s+/g, '').toLowerCase();
                // tslint:disable-next-line: forin
                for (const i in StandardControlIds) {
                    const standardControlId = StandardControlIds[i].toLowerCase();
                    if (standardControlId === labelAsControlId && standardControlId !== StandardControlIds.notebody.toLowerCase()) {
                        params[standardControlId] = field.value;
                    }
                }
            }
        });
        return params;
    }


    createNoteBodyHtml(valueSet) {
        let containCustomControl = false;
        for (const item in valueSet) {
            if (StringHelperService.validGUID(item) && !valueSet[item].isHidden) {
                containCustomControl = true;
                break;
            }
        }
        let noteBodyHtml = NoteBodyHtmlMetadatas.fullHtml;

        if (containCustomControl) {
            const customControls = this.getCustomControls(valueSet); // Get all custom controls
            const useTwoColumns = customControls.length > 5; // The columns we display.
            const customTableHtml = this.generateMetadataTable(customControls, useTwoColumns);
            noteBodyHtml = noteBodyHtml.replace(/<!--METADATA-TABLE-STYLE-PLACEHOLDER-->/g, NoteBodyHtmlMetadatas.metadataStyle);
            noteBodyHtml = noteBodyHtml.replace(/<!--SUBJECT-PLACEHOLDER-->/g, '');
            noteBodyHtml = noteBodyHtml.replace(/<!--METADATA-TABLE-PLACEHOLDER-->/g, customTableHtml);
        } else {
            noteBodyHtml = noteBodyHtml.replace(/<!--METADATA-TABLE-STYLE-PLACEHOLDER-->/g, '');
            noteBodyHtml = noteBodyHtml.replace(/<!--SUBJECT-PLACEHOLDER-->/g, '');
            noteBodyHtml = noteBodyHtml.replace(/<!--METADATA-TABLE-PLACEHOLDER-->/g, '');
        }

        let originHtml = '';
        if (valueSet[StandardControlIds.notebody]) {
            // this is the case for entry template
            originHtml = valueSet[StandardControlIds.notebody].value || '';
        } else {
            // this is the case for entity template
            for (const key in valueSet) {
                if (valueSet[key].componentType === 'nb-input') {
                    originHtml = valueSet[key].value || '';
                    break;
                }
            }
        }
        // originHtml = originHtml.replace("<p>", "<p style='text-align:left;text-indent:0pt;margin:0pt 0pt 0pt 0pt;'>");

        noteBodyHtml = noteBodyHtml.replace(/<!--NOTE-BODY-PLACEHOLDER-->/g, originHtml);
        return noteBodyHtml;
    }

    getCustomControls(valueSet) {
        if (valueSet === null || valueSet === undefined) {
            return valueSet;
        }
        const customControls = [];
        for (const controlValue in valueSet) {
            if (StringHelperService.validAdhocId(valueSet[controlValue].adhocDefId)
                && valueSet[controlValue].componentType !== 'nb-input'
                && !valueSet[controlValue].isHidden) {
                if (valueSet[controlValue].componentType === 'mdl-input' || valueSet[controlValue].componentType === 'mdl-entity') {
                    if (valueSet[controlValue].value && valueSet[controlValue].displayValue && valueSet[controlValue].actualValue) {
                        valueSet[controlValue].displayValue = valueSet[controlValue].displayValue
                            + '(' + valueSet[controlValue].actualValue + ')';
                    }
                }
                customControls.push(valueSet[controlValue]);
            }
        }

        return customControls;
    }

    generateMetadataTable(customControls, useTwoColumns) {
        const totalRows = useTwoColumns ? Math.ceil(customControls.length / 2.0) : customControls.length;

        let html = '';
        const htmlBegin = NoteBodyHtmlMetadatas.tableStartTag + '<table id="tabledisplay-meta" width="100%">';
        let htmlContent = '';
        const htmlEnd = '</table> ' + NoteBodyHtmlMetadatas.tableEndTag;

        let controlId = '';
        let label = '';
        let stringValue = '';

        for (let i = 0; i < totalRows; i++) {
            controlId = customControls[i].adhocDefId;
            label = customControls[i].label;
            if (customControls[i].componentType === ComponentTypes.date) {
                if (customControls[i].value && customControls[i].displayValue) {
                    stringValue = customControls[i].displayValue;
                } else {
                    stringValue = customControls[i].displayValue;
                }
            } else {
                if (customControls[i].value && customControls[i].value.length > 0 && customControls[i].displayValue) {
                    stringValue = customControls[i].displayValue;
                } else {
                    stringValue = customControls[i].displayValue;
                }
            }
            if (stringValue === null) {
                stringValue = '';
            }

            htmlContent += '<tr>';
            htmlContent += '<td nowrap class="var" style="font-weight: bold;padding: 0px 7px;padding-right: 25px;">';
            htmlContent += label.replace('*', '');
            if (useTwoColumns) {
                htmlContent += '</td><td id="' + controlId + '" class="value" style="padding: 0px 7px; width:40%; ">';
            } else {
                htmlContent += '</td><td id="' + controlId + '" class="value" style="padding: 0px 7px; width:100%; ">';
            }
            // TODO: figure out how to escapeHtml
            htmlContent += StringHelperService.escapeHtml(stringValue);

            if (useTwoColumns && i + totalRows < customControls.length) {
                htmlContent += '</td><td nowrap class="var " style="font-weight: bold;padding: 0px 7px;padding-right: 25px;">';

                controlId = customControls[i + totalRows].adhocDefId;
                label = customControls[i + totalRows].label;

                if (customControls[i].componentType === ComponentTypes.date) {
                    if (customControls[i + totalRows].value && customControls[i + totalRows].displayValue) {
                        stringValue = customControls[i + totalRows].displayValue;
                    } else {
                        stringValue = customControls[i + totalRows].displayValue;
                    }
                } else {
                    if (customControls[i + totalRows].value
                        && customControls[i + totalRows].value.length > 0
                        && customControls[i + totalRows].displayValue) {
                        stringValue = customControls[i + totalRows].displayValue;
                    } else {
                        stringValue = customControls[i + totalRows].displayValue;
                    }
                }
                if (stringValue === null) {
                    stringValue = '';
                }

                htmlContent += label.replace('*', '');
                htmlContent += '</td>';
                htmlContent += '<td id="' + controlId + '" class="value" style="padding: 0px 7px;width: 40%;">';
                htmlContent += StringHelperService.escapeHtml(stringValue);
            }
            htmlContent += '</td></tr>';
        }
        html = htmlBegin + htmlContent + htmlEnd;

        return html;
    }
}
