/**
 * Created by Alex Xia on 06/05/19.
 * Description:
 *
 * ------ maintenance history ------
 */
export const RELOAD_CALENDARS = 'RELOAD_CALENDARS';
export const RELOAD_CALENDARS_SUCCESS = 'RELOAD_CALENDARS_SUCCESS';
export const RELOAD_CALENDARS_FAIL = 'RELOAD_CALENDARS_FAIL';
export const CHANGE_CALENDARS_DETAIL = 'CHANGE_CALENDARS_DETAIL';
