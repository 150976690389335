/**
 * Created by Abner Sui on 09/26/2019. Added slide sheet action types
 * -------------------------------------
 * Updated by Daniel on 07/21/2021. Added slide sheet event types
 */

// TODO will remove all slide sheet into here
export enum SlideSheetActionTypes {
    CLOSE = 'CLOSE',
    TASK = 'TASK',
    PROCESS_INSTANCES = 'PROCESS_INSTANCE',
    QUICK_TASK = 'QUICK_TASK',
    TASK_LIST = 'TASK_LIST',
    CHANGE_WIDTH = 'CHANGE_WIDTH',
    ENTITY_PANEL = 'ENTITY_PANEL',
    CONTACT_PANEL = 'CONTACT_PANEL',
    EXCHANGE_PANEL = 'EXCHANGE_PANEL',
    EXCHANGE_SYNC_CONTACT = 'EXCHANGE_SYNC_CONTACT',
    ADMIN_CONSOLE_USERS_EDITOR = 'ADMIN_CONSOLE_USERS_EDITOR',
    ADMIN_CONSOLE_NOTE_EDITOR = 'ADMIN_CONSOLE_NOTE_EDITOR',
    ADMIN_CONSOLE_NOTIFICATION_EDITOR = 'ADMIN_CONSOLE_NOTIFICATION_EDITOR',
    ADMIN_CONSOLE_ENTITY_EDITOR = 'ADMIN_CONSOLE_ENTITY_EDITOR',
    ADMIN_CONSOLE_RELATIONSHIPS_EDITOR = 'ADMIN_CONSOLE_RELATIONSHIPS_EDITOR',
    ADMIN_CONSOLE_STREET_EVENT_CONFIG = 'ADMIN_CONSOLE_STREET_EVENT_CONFIG',
    ADMIN_CONSOLE_PORTFOLIO_UPLOAD_EDITOR = 'ADMIN_CONSOLE_PORTFOLIO_UPLOAD_EDITOR',
    ADMIN_CONSOLE_ETL_SCHEDULED_JOBS_EDITOR = 'ADMIN_CONSOLE_ETL_SCHEDULED_JOBS_EDITOR',
    ADMIN_CONSOLE_ETL_TASKS_EDITOR = 'ADMIN_CONSOLE_ETL_TASKS_EDITOR',
    ADMIN_CONSOLE_LOCAL_PYTHON_SCRIPT_EDITOR = 'ADMIN_CONSOLE_LOCAL_PYTHON_SCRIPT_EDITOR',
    ADMIN_CONSOLE_WEB_SITES_EDITOR = 'ADMIN_CONSOLE_WEB_SITES_EDITOR',
    ADMIN_CONSOLE_GENERAL = 'ADMIN_CONSOLE_GENERAL',
    TASK_DEFINITION = 'TASK_DEFINITION',
    WORKFLOW_TRIGGER_SETTINGS = 'WORKFLOW_TRIGGER_SETTINGS',
    THREAD_SIDESHEET = 'THREAD_SIDESHEET',
    // THREAD_DETAIL = 'THREAD_DETAIL', // TODO: Move thread sidesheet over here
}

// communicate using the Slide Sheet as an intermediary
export enum SlideSheetEventTypes {
    CONTACT_VALUE_CHANGE = 'CONTACT_VALUE_CHANGE',
    CALENDAR_VALUE_CHANGE = 'CALENDAR_VALUE_CHANGE',
    VALUE_CHANGE = 'VALUE_CHANGE',
    CLICK_OUTSIDE_SLIDESHEET = 'CLICK_OUTSIDE_SLIDESHEET',
    /**
     * indicate the slide sheet is being closed.
     */
    SLIDE_CLOSING = 'SLIDE_CLOSING',
}
