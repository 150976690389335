/**
 * Created by Abner Sui on 04/25/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { EntityBrief } from './entity-brief.model';

export class SectionFilterSetting {
    id?: string;
    fields: Array<string>;
    title: string;
    value: string;
    operator: string;
    metadataType: string;
    relationship?: Array<string>;
    dataType?: string;

    static parse(response): SectionFilterSetting {
        const result = new SectionFilterSetting();
        if (response['id']) {
            result.id = response['id'];
        }
        result.fields = response['fields'];
        result.title = response['title'];
        result.value = response['value'];
        result.operator = response['operator'];
        result.metadataType = response['metadataType'];
        if (response['relationship']) {
            result.relationship = response['relationship'];
        }
        if (response['dataType']) {
            result.dataType = response['dataType'];
        }
        return result;
    }
}

export enum SectionType {
    NOTE = 'notes',
    FILE = 'files',
}

export class ExportOptions {
    constructor(public pdf: boolean = true, public excel: boolean = false) { }
}

export const PrintPageSize = ['A2', 'A3', 'A4', 'A5'];

export enum Layout {
    PORTRAIT = 'Portrait',
    LANDSCAPE = 'Landscape'
}

export const PrintLayout = [Layout.PORTRAIT, Layout.LANDSCAPE];

export class DashboardPrintSetting {
    dashboardId: string;
    displaySetting: Object;
    focus: Array<string>;
    canSelectDashboard: boolean;
    configuration?: Object;
    attachment?: ExportOptions;

    constructor() {
        this.displaySetting = {
            'pageSize': 'A4',
            'layout': 'Portrait'
        };
        this.focus = [];
    }
}

const standardEntityField: Array<string> = ['entities', 'source', 'submitter'];

export class SectionColumn {
    field: string;
    display: string;
    dataType: string;
    format?: string;

    static parse(response): SectionColumn {
        const result = new SectionColumn();
        result.field = response['field'];
        result.display = response['display'];
        if (standardEntityField.indexOf(result.field) > -1) { // iPhone build does not support [].includes, Web support. So changed to indexOf
            result.dataType = 'entity'; // Handle Nextgen 1.0 bug
        } else {
            result.dataType = response['dataType'];
        }
        result.format = response['format'];
        return result;
    }
}

export enum SortOrder {
    DESC = 'desc',
    ASC = 'asc',
}

export class SectionDisplay {
    fixedColumn: Array<string>;
    sortOrder: SortOrder;
    sortBy: string;
    column: Array<SectionColumn>;
    row: number;

    constructor() {
        this.fixedColumn = ['subject', 'blurb'];
        this.sortOrder = SortOrder.DESC;
        this.row = 10;
        this.sortBy = 'submittedDate';
        this.column = [];
    }

    static parse(response): SectionDisplay {
        const result = new SectionDisplay();
        result.fixedColumn = response['fixedColumn'];
        result.sortOrder = response['sortOrder'];
        result.sortBy = response['sortBy'];
        if (response['column']) { // handle dirty data during developing
            response['column'].forEach(item => result.column.push(SectionColumn.parse(item)));
        }
        result.row = response['row'];
        return result;
    }

}

export enum Frequency {
    MONTHLY = 'monthly',
    WEEKLY = 'weekly',
    DAILY = 'daily',
}

export class Scheduler {
    time: string;
    configuration: string;
    every: number;
    startDate: Date;
    frequency: Frequency;
    on?: string;

    constructor() {
        this.every = 1;
        this.frequency = Frequency.WEEKLY;
        this.configuration = '';
        this.startDate = null;
        this.time = '06:30';
    }

    static parse(response): Scheduler {
        const result = new Scheduler();
        result.time = response['time'];
        result.configuration = response['configuration'];
        result.every = response['every'];
        result.startDate = new Date(response['startDate']);
        result.frequency = response['frequency'];
        if (response['on']) {
            result.on = response['on'];
        }
        return result;
    }

}

export enum EmailDigestType {
    DASHBOARD = 'reportData',
    SCREEN_SETTINGS = 'screen settings',
}

export class EmailSection {
    id: string;
    name: string;
    filterSetting: Array<SectionFilterSetting>;
    isIncludeEvents: boolean;
    advFilter: string;
    type: SectionType;
    needFilterByLatestExecutionTime: boolean;
    displaySetting: SectionDisplay;
    previewResult?: string;
    showAll?: boolean;

    constructor() {
        this.name = '';
        this.advFilter = '';
        this.type = SectionType.NOTE;
        this.isIncludeEvents = false;
        this.needFilterByLatestExecutionTime = false;
        this.filterSetting = [];
        this.id = '';
        this.displaySetting = new SectionDisplay();
    }

    static parse(response): EmailSection {
        const result = new EmailSection();
        result.id = response['id'];
        result.name = response['name'];
        if (response['filterSetting']) { // handle dirty data during developing
            JSON.parse(response['filterSetting'].replace(/\"/g, '"')).forEach(item => result.filterSetting.push(SectionFilterSetting.parse(item)));
        }
        result.advFilter = response['advFilter'];
        result.type = response['type'];
        result.isIncludeEvents = response['isIncludeEvents'];
        result.needFilterByLatestExecutionTime = response['needFilterByLatestExecutionTime'];
        result.displaySetting = SectionDisplay.parse(response['displaySetting']);
        return result;
    }

}

export class EmailDigest {
    id: string;
    lastModifiedDate: Date;
    createDate: Date;
    minuteOffset: number;
    author: EntityBrief;
    editable: boolean;
    deletable: boolean;
    description: string;
    emailSection: Array<EmailSection>;
    latestExecutionTime: Date;
    scheduler: Scheduler;
    isSubscribed: boolean;
    recipients: Array<string>;
    name: string;
    isSubscribable: boolean;
    hideBlankEmail: boolean;
    hideBlankSection: boolean;
    active: boolean;
    type: string;
    schedulerStr?: string;
    fireTime?: string;
    reportData?: DashboardPrintSetting;

    constructor() {
        this.name = '';
        this.description = '';
        this.recipients = [];
        this.emailSection = [];
        this.scheduler = new Scheduler();
        this.minuteOffset = 0;
        this.hideBlankEmail = false;
        this.hideBlankSection = false;
    }

    static parse(response): EmailDigest {
        const result: EmailDigest = new EmailDigest();
        result.id = response['id'];
        result.name = response['name'];
        result.description = response['description'];
        result.lastModifiedDate = new Date(response['lastModifiedDate']);
        result.editable = response['editable'];
        result.deletable = response['deletable'];
        if (response['latestExecutionTime'] > -1) {
            result.latestExecutionTime = new Date(response['latestExecutionTime']);
        } else {
            result.latestExecutionTime = null;
        }
        result.isSubscribed = response['isSubscribed'];
        result.isSubscribable = response['isSubscribable'];
        result.createDate = new Date(response['createDate']);
        result.recipients = response['recipients'];
        result.author = EntityBrief.parse(response['author']['data']);
        result.scheduler = Scheduler.parse(response['scheduler']);
        if (response['emailSection']) { // handle dirty data during developing
            response['emailSection'].forEach(item => result.emailSection.push(EmailSection.parse(item)));
        }
        result.minuteOffset = response['minuteOffset'];
        // !! is to make sure the value is boolean due to UI binding need exactly boolean value instead of null/undefined
        result.hideBlankEmail = !!response['hideBlankEmail'];
        result.hideBlankSection = !!response['hideBlankSection'];
        result.active = response['active'];
        result.type = response.type || EmailDigestType.SCREEN_SETTINGS;

        if (response.reportData) {
            result.reportData = response.reportData;
        }
        return result;
    }

}

export class TimeZone {
    id: string;
    name: string;
    offset: number;
    zonename: string;
}
