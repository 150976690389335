<div class="filter-item-container" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" [attr.tid]="config.tId"
    style="width: 99%;">
    <div class="title-action-container body-2" tid="title-action-container">
        <div class="filter-title">
            <div>{{config.title}}</div>
        </div>
        <div class="action" [style.visibility]="isItemHoverOn?'visible':'hidden'">
            <smart-icon name="trash" (click)="onBtnCloseClicked()"></smart-icon>
        </div>
    </div>
    <div class="operator-content-container" tid="operator-content-container">
        <div class="content">
            <div class="add-entity-select-box">
                <kendo-multiselect [popupSettings]="{appendTo: config.viewContainer}" #entitylist [data]="dataItems"
                    [filterable]="true" (filterChange)="handleFilter($event)" [textField]="'shortName'"
                    [valueField]="'id'" [(ngModel)]="selectedItems" (valueChange)="valueChange()">
                    <ng-template kendoMultiSelectItemTemplate let-dataItem>
                        <tam-entity-dropdown-item [entity]="dataItem"></tam-entity-dropdown-item>
                    </ng-template>

                    <ng-template kendoMultiSelectFooterTemplate *ngIf="flyValue">
                        <div  *ngIf="config.metadataType === 'FlyContact'" (click)="createContact()" class="dropdown-fly-item">
                            <span class="fly-entity-tag"> {{flyValue}} {{'general.new_contact' | stringLiterals}}</span>
                        </div>
                        <div *ngIf="config.metadataType === 'FlyEntity'" (click)="createEntity()" class="dropdown-fly-item">
                            <span class="fly-entity-tag">{{flyValue}} {{'general.new_entity' | stringLiterals}}</span>
                        </div>
                    </ng-template>
                </kendo-multiselect>
            </div>
        </div>
    </div>
</div>