/**
 * Created by Jiu Chen
 * Description:
 *
 * ------ maintenance history ------
 * v1.0
 * updated by Alan Yang on 6/18/2019
 * added print notes support via isIncludeSideNotes and isIncludeAttachments.
 */
import { Subject } from 'rxjs';
import { Thread } from '../../tamalelibs/models/thread.model';
import { ShowAsMode } from '../display/display.model';

export enum ThreadDetailMode {
    NORMAL = 0,
    PRINT = 1, // for print notes
    EMBED = 2
}
export class ThreadDetailConfig {
    keyInStore?: string; // use dynamic store if exist
    // identify whether this component is used inside Research View or Thread View;
    // There is no highlight and maximize feature in Thread View;
    singlePageMode: boolean;
    mode: ThreadDetailMode;
    thread?: Thread; // for print
    dataStoreSelector: any;
    keywordStoreSelector?: any;
    matchedAttachmentStoreSelector?: any;
    layoutSelector?: any;
    partialDisplay?: ThreadDetailPartialDisplay;
    showAsMode?: ShowAsMode;

    getNoteBodyActionName: string;

    actions$: Subject<any>;
    events$: Subject<any>;

    constructor() {
        this.actions$ = new Subject<any>();
        this.events$ = new Subject<any>();
        this.singlePageMode = false;

        this.mode = ThreadDetailMode.NORMAL;
        this.showAsMode = ShowAsMode.NOTES;

    }
}

export class ThreadDetailPartialDisplay {
    submitter = true;
    submittedDate = true;
}

export enum ThreadDetailActions {
    TOGGLE_MAXIMIZE = 'TOGGLE_MAXIMIZE',
    CLEAR = 'CLEAR',
    LOAD_SIDE_SHEET = 'LOAD_SIDE_SHEET',
}

export const DEFAULT_ARROW_DOWNUP_HEIGHT = 5;
