/**
 * Created by Abner Sui on 08/04/2020
 * -------------------------------------
 */

import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FieldConfig, FieldEvents, FieldActions } from './field.model';
import { FileUploadConfig, FileUploadFeedbackTypes, FileUploadAction, FileUploadActionTypes } from '../../file-upload/file-upload.model';
import { Subscription, Subject, fromEvent } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { TamUploadUIFile } from '../../../widgets/tam-upload/tam-upload.model';
import { FileInfo } from '../../../tamalelibs/models/workflow.model';
import { IdHelperService } from '../../../services/id-helper.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { UtilsService } from '../../../tamalelibs/services/utils.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'tam-file-field',
    templateUrl: './file-field.component.html',
    styleUrls: ['./field.component.scss']
})
export class FileFieldComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input()
    config: FieldConfig;

    @ViewChild('anchor', { static: false }) anchor;
    @ViewChild(TooltipDirective, { static: false })
    tooltipDir: TooltipDirective;

    fileUploadConfig: FileUploadConfig = new FileUploadConfig();
    isIPAD = false;
    requireInvalid = false;

    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _utils: UtilsService,
        private _deviceService: DeviceDetectorService,
    ) { }

    ngOnInit() {
        this.fileUploadConfig.height = 64;
        if (this.config.disabled) {
            this.fileUploadConfig.disabled = true;
            return;
        }
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        if (this.config.field) {
            this._parseFilesForUploadComponent(this.config.field.value, this.fileUploadConfig);
        }
        this._destroySubscriptions.push(
            this.fileUploadConfig.feedbackSubject$.subscribe(event => {
                if (event && event.type === FileUploadFeedbackTypes.OnStatusChange) {
                    const workflow = event.payload.workflow;
                    if (event.payload && workflow) {
                        this.config.config.feedbackSubject$.next({
                            type: FieldEvents.FILE_OPERATING,
                            payload: event.payload.isEditing,
                        });
                        if (event.payload.isEditing === false) {
                            this._getAttachments();
                        }
                    }
                }
            }),

            this.config.config.actionSubject$.subscribe(action => this._onAction(action)),
        );
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validateRequire(),
            },
        });
    }

    ngAfterViewInit() {
        if (this.isIPAD && this.config.field.fieldDefinition.description) {
            this._destroySubscriptions.push(
                fromEvent(document, 'click').subscribe((event) => {
                    this.showTooltip(event, this.anchor, false);
                }),
            );
        }
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    showTooltip(event, target, show: boolean): void {
        if (show === null) {
            this.tooltipDir.toggle(target);
        } else {
            this.tooltipDir.toggle(target, show);
        }
        this._utils.emptyClick(event);
    }

    validateRequire(): boolean {
        if (this.config.editable && this.config.required && (!this.config.field.value || this.config.field.value.length === 0)) {
            return true;
        } else {
            return false;
        }
    }

    valueChange(event) {
        this.requireInvalid = false;
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALUE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                value: event,
            },
        });
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validateRequire(),
            },
        });
    }

    private _getAttachments(): void {
        const action = new FileUploadAction();
        action.type = FileUploadActionTypes.GetAttachments;
        action.payload = new Subject();
        action.payload.pipe(
            filter((e: any) => e.type === FileUploadFeedbackTypes.ReturnAttachments),
            take(1),
            map((e: any) => {
                return {
                    id: this.config.field.fieldDefinition.id,
                    files: e.payload,
                };
            }),
        ).subscribe(event => {
            this.config.field.value = this._parseTamUploadUIFileToFileInfo(event.files);
            this.valueChange(this.config.field.value);
        });
        this.fileUploadConfig.actionSubject$.next(action);
    }

    private _onAction(action) {
        if (action.type === FieldActions.SHOW_VALIDATE_REQUIRE) {
            this.requireInvalid = this.validateRequire();
        } else {
            this.fileUploadConfig.actionSubject$.next(action);
        }
    }

    private _parseFilesForUploadComponent(value: Array<FileInfo>, fConfig: FileUploadConfig): void {
        const idSet: Set<string> = new Set();
        if (value && value.length) {
            value.forEach(item => {
                if (!idSet.has(item.id)) {
                    idSet.add(item.id);
                    fConfig.files.push({
                        dzid: IdHelperService.uuidv4(),
                        id: item.id,
                        name: item.fileName,
                        progress: '100%',
                        serverName: item.serverFileName,
                        isNew: false,
                        isCompleted: true
                    });
                }
            });
        }
    }

    private _parseTamUploadUIFileToFileInfo(uploads: Array<TamUploadUIFile>): Array<FileInfo> {
        const result: Array<FileInfo> = [];
        if (uploads && uploads.length) {
            uploads.forEach(item => {
                const one = new FileInfo();
                one.id = item.id === item.serverName ? null : item.id;
                one.serverFileName = item.serverName;
                one.fileName = item.name;
                one.originalId = item['originalId'];
                result.push(one);
            });
        }
        return result;
    }

}
