/**
 * Created by Jiu Chen on 12/03/2018.
 * Description:
 *
 * ------ maintenance history ------
 * Updated by Daniel on 12/19/2019 Added user setting
 */

import { Action } from '@ngrx/store';

export enum UsersActionTypes {
    INITIALIZE = '[USERS] INITIALIZE',
    INITIALIZE_SUCCESS = '[USERS] INITIALIZE_SUCCESS',
    INITIALIZE_FAILED = '[USERS] INITIALIZE_FAILED',
    LOAD_USER_SETTING = '[USERS] LOAD_USER_SETTING',
    LOAD_USER_SETTING_SUCCESS = '[USERS] LOAD_USER_SETTING_SUCCESS',
    LOAD_USER_SETTING_FAILED = '[USERS] LOAD_USER_SETTING_FAILED',
    UPDATE_USER_SETTING = '[USERS] UPDATE_USER_SETTING',
    UPDATE_USER_SETTING_SUCCESS = '[USERS] UPDATE_USER_SETTING_SUCCESS',
    UPDATE_USER_SETTING_FAILED = '[USERS] UPDATE_USER_SETTING_FAILED',
}

export class Initialize implements Action {
    readonly type = UsersActionTypes.INITIALIZE;
    constructor(public payload: any) { }
}

export class InitializeSuccess implements Action {
    readonly type = UsersActionTypes.INITIALIZE_SUCCESS;
    constructor(public payload: any) { }
}

export class InitializeFailed implements Action {
    readonly type = UsersActionTypes.INITIALIZE_FAILED;
    constructor(public payload: any) { }
}

export class LoadUserSetting implements Action {
    readonly type = UsersActionTypes.LOAD_USER_SETTING;
    constructor(public payload: any) { }
}

export class LoadUserSettingSuccess implements Action {
    readonly type = UsersActionTypes.LOAD_USER_SETTING_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadUserSettingFailed implements Action {
    readonly type = UsersActionTypes.LOAD_USER_SETTING_FAILED;
    constructor(public payload: any) { }
}

export class UpdateUserSetting implements Action {
    readonly type = UsersActionTypes.UPDATE_USER_SETTING;
    constructor(public payload: any) { }
}

export class UpdateUserSettingSuccess implements Action {
    readonly type = UsersActionTypes.UPDATE_USER_SETTING_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateUserSettingFailed implements Action {
    readonly type = UsersActionTypes.UPDATE_USER_SETTING_FAILED;
    constructor(public payload: any) { }
}


export type UsersActions = Initialize | InitializeSuccess | InitializeFailed |
    LoadUserSetting | LoadUserSettingSuccess | LoadUserSettingFailed |
    UpdateUserSetting | UpdateUserSettingSuccess | UpdateUserSettingFailed;
