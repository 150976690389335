/**
 * Created by Abner Sui on 12/15/2020
 * -------------------------------------
 * 04/08/2022 Simon Zhao added new control types CHECK_LIST.
 */

export enum ControlType {
    SINGLE_TEXT_DROP_DOWN = 'singleTextDropdown',
    TEXT = 'text',
    TEXT_AREA = 'textArea',
    SINGLE_ENTITY_DROP_DOWN = 'singleEntityDropdown',
    MULTI_ENTITY_DROP_DOWN = 'multiEntityDropdown',
    MULTI_TEXT_DROP_DOWN = 'multiTextDropdown',
    NUMBER = 'number',
    FILE = 'file',
    DATE = 'date',
    SECTION = 'section',
    RICH_EDITOR = 'richEditor',
    CHECK_LIST = 'checkList',
    SINGLE_USER_DROP_DOWN = 'singleUserDropdown'
}
