/**
 * created by Alex Xia on 4/20/2022.
 * Description:
 * provides utilities for office add-in
 * ------ maintenance history ------
 */
import { environment } from '../../../environments/environment';
import { OfficeAddIn } from '../../tamalelibs/models/office-addin.model';
export class AddInUtilities {

    static closeTaskPaneForAddIn(): void {
        if (AddInUtilities.isOfficeEnvironment()) {
            if (Office.context.diagnostics.host === Office.HostType.Outlook || Office.context.diagnostics.host === Office.HostType.Excel ||
                Office.context.diagnostics.host === Office.HostType.PowerPoint || Office.context.diagnostics.host === Office.HostType.Word) {
                if (Office.context.ui.closeContainer) {
                    Office.context.ui.closeContainer();
                }
            }
        }
    }

    /**
     * check if it's office environment
     * @returns a flag
     */
    static isOfficeEnvironment(): boolean {
        let result = false;
        if (environment.isOfficeAddin && Office && Office.context && Office.context.ui) {
            result = true;
        }

        return result;
    }

    /**
     * https://learn.microsoft.com/en-us/javascript/api/requirement-sets/outlook/requirement-set-1.8/outlook-requirement-set-1.8?view=excel-js-preview
     * @param version
     * @returns true/false
     */
    static isOfficeSupportedVersion(version = '1.8') {
        return Office.context.requirements.isSetSupported('Mailbox', version);
    }

    /**
     * Determines whether the office js is loaded.
     * @returns a flag indicating whether the office js is loaded without consideration the context.
     */
    static isOfficeJSLoaded(): boolean {
        return environment.isOfficeAddin;
    }

    /**
     * check if it's pin mode of office add-in environment
     * @returns a flag
     */
    static isOfficeAddInPinMode(_officeAddIn: OfficeAddIn): boolean {
        return AddInUtilities.isOfficeEnvironment() && _officeAddIn && _officeAddIn.isPinMode;
    }
}
