<div class="tab-set-container">
    <div [hidden]="!showSwipe">
        <smart-icon name="angle-left" class="light arrow-left" [class.disabled]="disabledLeftArrow"
            (click)="onSwipeLeft()"></smart-icon>
    </div>
    <div class="tab-set" [style.width]="tabSetWidth" [style.margin-left]="tabSetMargin"
        [style.margin-right]="tabSetMargin">
        <div class="tab" [style.left]="tabLeft">
            <ng-content select="tam-tab"></ng-content>
            <div class="js-tab-underline" [style.width]="underLineWidth" [style.left]="underLineLeft"></div>
        </div>
    </div>
    <div [hidden]="!showSwipe">
        <smart-icon name="angle-right" class="light arrow-right" [class.disabled]="disabledRightArrow"
            (click)="onSwipeRight()" [style.left]="arrowLeft"></smart-icon>
    </div>
    <div *ngIf="extra" class="extra">
        <ng-container *ngTemplateOutlet="extra;">
        </ng-container>
    </div>
</div>