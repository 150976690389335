/**
 * created by Yu Zhang on 12/7/17.
 * Description:
 *
 * ------ maintenance history ------
 */
import { ThreadList } from '../models/thread-list.model';
import {
    // thread/notes actions
    GET_FIRST_PAGE_SUCCESS, GET_FIRST_PAGE_FAILURE, GET_NEXT_PAGE_SUCCESS, GET_NEXT_PAGE_FAILURE,
    GET_THREAD_LIST_WITH_PAGGING_SUCCESS, GET_THREAD_LIST_WITH_PAGGING_FAILURE, CLEAR_THREAD_MSG,
    CLEAR_THREAD_LIST,

    // keyword search actions
    CLEAN_THREAD_SEARCH_LIST_ALL, CLEAN_THREAD_SEARCH_LIST_CURRENT,
    GET_FIRST_PAGE_WITH_KEYWORD_ALL_SUCCESS, GET_FIRST_PAGE_WITH_KEYWORD_ALL_FAILURE,
    GET_NEXT_PAGE_WITH_KEYWORD_ALL_SUCCESS, GET_NEXT_PAGE_WITH_KEYWORD_ALL_FAILURE,
    GET_FIRST_PAGE_WITH_KEYWORD_CURRENT_SUCCESS, GET_FIRST_PAGE_WITH_KEYWORD_CURRENT_FAILURE,
    GET_NEXT_PAGE_WITH_KEYWORD_CURRENT_SUCCESS, GET_NEXT_PAGE_WITH_KEYWORD_CURRENT_FAILURE,

    // entity detail recent note actions
    GET_FIRST_PAGE_RECENT_NOTES_ENTITY_SUCCESS, GET_NEXT_PAGE_RECENT_NOTES_ENTITY_SUCCESS,
    GET_FIRST_PAGE_RECENT_NOTES_ENTITY_FAILURE, GET_NEXT_PAGE_RECENT_NOTES_ENTITY_FAILURE,
    CLEAN_RECENT_NOTES_ENTITY,

    // contact detail recent note actions
    GET_FIRST_PAGE_RECENT_NOTES_CONTACT_SUCCESS, GET_NEXT_PAGE_RECENT_NOTES_CONTACT_SUCCESS,
    GET_FIRST_PAGE_RECENT_NOTES_CONTACT_FAILURE, GET_NEXT_PAGE_RECENT_NOTES_CONTACT_FAILURE,
    CLEAN_RECENT_NOTES_CONTACT, GET_RECENT_NOTES_SUCCESS, GET_RECENT_NOTES_FAILURE
} from './thread-list.actions';

export function threadListReducer(state: ThreadList = new ThreadList(), action) {
    switch (action.type) {
        case GET_FIRST_PAGE_SUCCESS:
            return action.payload;
        case GET_NEXT_PAGE_SUCCESS: {
            const threadList = new ThreadList();
            threadList.count = action.payload.count;
            threadList.next = action.payload.next;
            threadList.id = action.payload.id;
            threadList.threads = state.threads.concat(action.payload.threads);
            return threadList;
        }
        case GET_NEXT_PAGE_FAILURE:
        case GET_FIRST_PAGE_FAILURE:
            return state;
        case GET_THREAD_LIST_WITH_PAGGING_SUCCESS:
            return action.payload;
        case GET_THREAD_LIST_WITH_PAGGING_FAILURE:
            return state;
        case CLEAR_THREAD_LIST:
            return new ThreadList();
        default:
            return state;
    }
}

export function searchAllThreadListReducer(state: ThreadList = new ThreadList(), action) {
    switch (action.type) {
        case GET_FIRST_PAGE_WITH_KEYWORD_ALL_SUCCESS:
            return action.payload;
        case GET_NEXT_PAGE_WITH_KEYWORD_ALL_SUCCESS: {
            const threadList = new ThreadList();
            threadList.count = action.payload.count;
            threadList.next = action.payload.next;
            threadList.id = action.payload.id;
            threadList.threads = state.threads.concat(action.payload.threads);
            return threadList;
        }
        case GET_FIRST_PAGE_WITH_KEYWORD_ALL_FAILURE:
        case GET_NEXT_PAGE_WITH_KEYWORD_ALL_FAILURE:
            return state;
        case CLEAN_THREAD_SEARCH_LIST_ALL:
            return new ThreadList();
        default:
            return state;
    }
}

export function searchCurrentThreadListReducer(state: ThreadList = new ThreadList(), action) {
    switch (action.type) {
        case GET_FIRST_PAGE_WITH_KEYWORD_CURRENT_SUCCESS:
            return action.payload;
        case GET_NEXT_PAGE_WITH_KEYWORD_CURRENT_SUCCESS: {
            const threadList = new ThreadList();
            threadList.count = action.payload.count;
            threadList.next = action.payload.next;
            threadList.id = action.payload.id;
            threadList.threads = state.threads.concat(action.payload.threads);
            return threadList;
        }
        case GET_FIRST_PAGE_WITH_KEYWORD_CURRENT_FAILURE:
        case GET_NEXT_PAGE_WITH_KEYWORD_CURRENT_FAILURE:
            return state;
        case CLEAN_THREAD_SEARCH_LIST_CURRENT:
            return new ThreadList();
        default:
            return state;
    }
}

export function recentNotesEntityReducer(state: ThreadList = new ThreadList(), action) {
    switch (action.type) {
        case GET_FIRST_PAGE_RECENT_NOTES_ENTITY_SUCCESS:
            return action.payload;
        case GET_NEXT_PAGE_RECENT_NOTES_ENTITY_SUCCESS: {
            const threadList = new ThreadList();
            threadList.count = action.payload.count;
            threadList.next = action.payload.next;
            threadList.id = action.payload.id;
            threadList.threads = state.threads.concat(action.payload.threads);
            return threadList;
        }
        case GET_FIRST_PAGE_RECENT_NOTES_ENTITY_FAILURE:
        case GET_NEXT_PAGE_RECENT_NOTES_ENTITY_FAILURE:
            return state;
        case CLEAN_RECENT_NOTES_ENTITY:
            return new ThreadList();
        default:
            return state;
    }
}

export function recentNotesReducer(state, action) {
    switch (action.type) {
        case GET_RECENT_NOTES_SUCCESS: {
            return action.payload;
        }
        case GET_RECENT_NOTES_FAILURE:
            return state;
        default:
            return state;
    }
}

export function recentNotesMsgReducer(state: string = '', action) {
    switch (action.type) {
        case GET_RECENT_NOTES_FAILURE:
            return `${action.type}: ${action.payload}`;
        default:
            return state;
    }
}

export function recentNotesContactReducer(state: ThreadList = new ThreadList(), action) {
    switch (action.type) {
        case GET_FIRST_PAGE_RECENT_NOTES_CONTACT_SUCCESS:
            return action.payload;
        case GET_NEXT_PAGE_RECENT_NOTES_CONTACT_SUCCESS: {
            const threadList = new ThreadList();
            threadList.count = action.payload.count;
            threadList.next = action.payload.next;
            threadList.id = action.payload.id;
            threadList.threads = state.threads.concat(action.payload.threads);
            return threadList;
        }
        case GET_FIRST_PAGE_RECENT_NOTES_CONTACT_FAILURE:
        case GET_NEXT_PAGE_RECENT_NOTES_CONTACT_FAILURE:
            return state;
        case CLEAN_RECENT_NOTES_CONTACT:
            return new ThreadList();
        default:
            return state;
    }
}
