/**
 * Created by Abner, Sui on 09/28/2020
 * -------------------------------------
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ImgPreviewService } from './img-preview.service';
import { fromEvent, Subscription } from 'rxjs';
import { ImgPreviewActionTypes } from './img-preview.model';

@Component({
    selector: 'tam-img-preview',
    templateUrl: './img-preview.component.html',
    styleUrls: ['./img-preview.component.scss']
})
export class ImgPreviewComponent implements OnInit, OnDestroy {

    open = false;
    src: string;

    private _closeSubscriptions: Array<Subscription> = [];
    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _service: ImgPreviewService,
    ) { }

    ngOnInit() {
        this._destroySubscriptions.push(
            this._service.actionSubject$.subscribe(action => this.onAction(action)),
        );
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    close() {
        this.open = false;
        this.src = null;
        this._closeSubscriptions.forEach(item => item.unsubscribe());
        this._closeSubscriptions = [];
    }

    onAction(action) {
        if (action.type === ImgPreviewActionTypes.OPEN) {
            this.src = action.payload;
            this.open = true;
            this._closeSubscriptions.push(
                fromEvent(document, 'click').subscribe(event => {
                    if (!event['img-preview']) {
                        this.close();
                    }
                }),
            );
        }
    }

}
