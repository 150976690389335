/**
 * Created by Alan Yang on 8/11/17.
 * Description:
 *
 * ------ maintenance history ------
 * ver 2.0 modified by Yu Zhang on 2/13/18:
 *      use HttpClient in Angular 5
 * modified by Alex Xia on 10/29/2018:
 *      Add publish/edit contact functions
 * 06/02/2020 Marcus Zhao add _getContactTemplate.
 * 01/12/2020 Marcus Zhao add getJobFucntion.
 * 07/25/2021 Marcus Zhao add getContactAlias.
 * 09/06/2021 Marcus Zhao change get to post for getContactAndCorporateEntityList.
 * 13/04/2022 Marcus Zhao add relationships for getEntityListByNearby function.
 * 06/11/2022 Marcus Zhao according server changed. change getContactList to post request.
 * 07/01/2022 Marcus Zhao add 3 requests for bulk action.
 * 07/10/2024 Daniel Wang Add new endpoint to get employee by entity id
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransportService, URIComponentEncodingCodec } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { ContactList } from '../models/entity-list.model';
import { Contact, ExchangeBuklActionType } from '../models/contact.model';
import { HttpParams } from '@angular/common/http';
import { EntityType } from '../models/entity-type.model';
import { AdvFilterHelperService } from './adv-filter-helper.service';
import { ContactDialogSectionName } from '../../components/contact-detail-new/contact-body-detail.model';
import { map } from 'rxjs/operators';
import { Relationship } from '../models/relationship.model';
import { EntityBrief } from '../models/entity-brief.model';
import { RelationshipType } from '../models/relationship-type.model';

@Injectable()
export class ContactService {
    constructor(
        private _transportService: TransportService,
        private _advFilterHelper: AdvFilterHelperService,
    ) { }

    getTemplateConfig(templateConfig) {
        templateConfig['templateSetting'] = {};
        // need to save the control which will show in the dialog in store
        // because we can save the config in the config dialog
        // if we modify it in the config dialog, the response data cannot reflect the change in time
        // so, we need to get the template data when page load
        if (templateConfig && templateConfig.contains && templateConfig.contains.length > 0) {
            templateConfig.contains.forEach(element => {
                if (element.sectionName === ContactDialogSectionName.BasicInformation || element.sectionName === ContactDialogSectionName.Other) {
                    element.container.forEach(containerItem => {
                        if (containerItem && containerItem.length > 0) {
                            containerItem.forEach(item => {
                                if (item.config.controlID === 'Prefix' || item.config.controlID === 'Suffix' || item.config.controlID === 'Birthday' || item.config.controlID === 'Gender' || item.config.controlID === 'Middle Name') {
                                    // it stands that this control will show in the related section
                                    // use json will be easy to find
                                    templateConfig['templateSetting'][item.config.controlID] = element.sectionName;
                                }
                            });
                        }
                    });
                }
            });
            return templateConfig;
        }
    }

    getAllContactList(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.contactEndpoint}`;
        const param = new HttpParams({ encoder: new URIComponentEncodingCodec() });

        const options = {
            headers: headers,
            params: param
        };
        return this._transportService.get(url, options);
    }

    /**
     * get contact list by filter data.
     * change to post action
     * @param {number} [page=0]
     * @param {number} [pageSize=0]
     * @param {string} [keyword='']
     * @param {string} [expand=null]
     * @param {string} [filterby=null]
     * @param {string} [sortby]
     * @param {string} [sortorder]
     * @param {string} [filter] option,so it can keep other feature use it.
     * @returns {Observable<any>}
     * @memberof ContactService
     */
    getContactList(page: number = 0, pageSize: number = 0, keyword: string = '', expand: string = null, filterby: string = null, sortby?: string, sortorder?: string, filter?: string): Observable<any> {
        const headers = {
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.contactEndpoint}switch`;
        let param = new HttpParams({ encoder: new URIComponentEncodingCodec() });
        param = param.append('page', page.toString());
        param = param.append('rpp', pageSize.toString());
        // getContactList was used by mobile app and web UI, just keep the original logic for mobile app after adding the sortby and sortorder fields.
        if (sortby && sortorder) {
            param = param.append('sortby', sortby);
            param = param.append('sortorder', sortorder);

            if (keyword && keyword.length > 0) {
                param = param.append('searchstring', keyword);
            }
        } else if (keyword && keyword.length > 0) {
            param = param.append('filterstring', keyword);
            if (filterby) {
                param = param.append('filterby', filterby);
            } else {
                // tslint:disable-next-line: max-line-length
                param = param.append('filterby', 'long-name,default-type,company,primary-email,secondary-email,job-title,business-phone,home-phone,mobile-phone,home-address,business-address,business-fax,alias');
            }
        }

        if (expand) {
            param = param.append('expand', expand);
        }
        const options = {
            headers: headers,
            params: param
        };
        const formData = new FormData();
        if (filter) {
            formData.append('filter', JSON.stringify(filter));
        }
        return this._transportService.post(url, formData, options);
    }

    /**
     * Get All entity includes address.
     * use 2.1 entity endpoint and must use post.
     * @param {number} [page=0]
     * @param {number} [pageSize=0]
     * @param {string} [keyword='']
     * @param {string} [filterby=null]
     * @param {string} [sortby]
     * @param {string} [sortorder]
     * @returns {Observable<any>}
     * @memberof ContactService
     */
    getContactAndCorporateEntityList(page: number = 0, pageSize: number = 0, keyword: string = '', filterby: string = null, sortby?: string, sortorder?: string): Observable<any> {
        const headers = {
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityEndpoint}`;
        const formData = new FormData();
        let param = new HttpParams({ encoder: new URIComponentEncodingCodec() });
        param = param.append('page', page.toString());
        param = param.append('rpp', pageSize.toString());
        formData.append('sortby', sortby);
        formData.append('sortorder', sortorder);
        formData.append('operate', 'getAllMaptile');
        formData.append('hasAddress', 'true');
        if (keyword && keyword.length > 0) {
            formData.append('filterstring', keyword);
            if (filterby) {
                formData.append('filterby', filterby);
            } else {
                formData.append('filterby', 'long-name');
            }
        }

        const options = {
            headers: headers,
            params: param
        };
        return this._transportService.post(url, formData, options);
    }

    getEntityListByNearby(configuration, entityType, searchId, metadataFilter?, relationships?): Observable<any> {
        const headers = {
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityNearbyEndpoint}`;
        const formData = new FormData();
        formData.append('configuration', JSON.stringify(configuration));
        if (entityType) {
            formData.append('entitytype', entityType);
        }
        formData.append('excludes', searchId);
        if (metadataFilter) {
            formData.append('metadataFilter', JSON.stringify(metadataFilter));
        }
        formData.append('operate', 'getNearby');
        if (relationships && relationships.length > 0) {
            formData.append('relationshiptype', relationships);
            formData.append('parententity', searchId);
        }
        const options = {
            headers: headers,
        };
        return this._transportService.post(url, formData, options);
    }

    // placeholder is useless, placeholder is placeholder
    getContactListByName(page: number = 0, pageSize: number = 0, keyword: string = '', placeholder?: any): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.contactEndpoint}`;
        const params = {
            page: page,
            rpp: pageSize,
            sortby: 'short-name',
            sortorder: 'asc'
        };
        if (keyword && keyword.length > 0) {
            params['filterstring'] = keyword;
            params['filterby'] = 'long-name';
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    // get contact note and calendar thread list
    getContactNotesAndCalendar(args): Observable<any> {
        const advFilterArray = [];
        const spoolerFilter = `(! entities contains long-name "EMAIL_SPOOLER")`;
        const configDataFilter = `(! entities contains long-name "ConfigurationData")`;
        advFilterArray.push(spoolerFilter);
        advFilterArray.push(configDataFilter);
        // start date filter
        if (args.startDate) {
            const startDateFilter = `((display-date after "${args.startDate}") or (display-date equals "${args.startDate}"))`;
            advFilterArray.push(startDateFilter);
        }
        // end date filter
        if (args.endDate) {
            const endDateFilter = `((display-date before "${args.endDate}") or (display-date equals "${args.endDate}"))`;
            advFilterArray.push(endDateFilter);
        }

        const filter = this._advFilterHelper.joinFiltersByAnd(advFilterArray);
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.threadEndpoint}`;
        const params = {
            advfilter: filter,
            expand: 'entry;entities;entity;source;submitter;properties;property;propdef',
            showpermission: true,
            showblurb: true,
            sortby: 'lastediteddate',
            sortorder: 'desc',
        };

        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getSourceList(page: number = 0, pageSize: number = 0, keyword: string = '') {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.contactEndpoint}`;
        const params = {
            fields: 'id;long-name',
            page: page,
            rpp: pageSize,
            sortby: 'long-name',
            sortorder: 'asc',
            outputformat: 'json'
        };
        if (keyword && keyword.length > 0) {
            params['filterstring'] = keyword;
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getContactDetail(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.entityEndpoint}${id}`;
        const params = {
            // tslint:disable-next-line: max-line-length
            expand: 'topic-members;member-list;entity-type;entity;aliases;alias;provider-mappings;officers;officer;weblinks;weblink;relationship;child-entity;properties;property;propdef;',
            showsimple: true,
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getContactDetailInSimpleFormat(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.entityEndpoint}${id}`;
        const params = {
            expand: 'entity-type',
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    mapContactList(response): ContactList {
        const result = new ContactList();
        if (!response || !response['entity-list']) {
            return result;
        }
        response['entity-list'].forEach(element => {
            const e = Contact.parse(element) as Contact;
            e['isChecked'] = true;
            result.contacts.push(e);
        });

        if (response['next'] && response['next']['href']) {
            result.next = response['next']['href'];
        }
        result.totalCount = response['count'];

        return result;
    }

    publishContact(contact: Contact) {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const url = `${AppConfig.contactEndpoint}`;
        const formData = this._getFormData(contact);

        const params = {
            showsimple: true,
        };
        const options = {
            params: params,
            headers: headers,
        };
        return this._transportService.post(url, formData, options);
    }

    editContact(contact: Contact) {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const url = `${AppConfig.contactEndpoint}${contact.id}`;
        const formData = this._getFormData(contact);

        const params = {
            // tslint:disable-next-line: max-line-length
            expand: 'topic-members;member-list;entity-type;entity;aliases;alias;provider-mappings;officers;officer;weblinks;weblink;relationship;child-entity;properties;property;propdef;',
            showsimple: true,
            outputformat: 'json'
        };
        const options = {
            params: params,
            headers: headers
        };
        return this._transportService.post(url, formData, options);
    }

    /**
     * param page
     * param pageSize
     * param keyword
     * param placeholder
     */
    getContactListByText(keyword: string = '', placeholder?: any, entityType?: Array<string> | string): Observable<any> {
        const headers = {
            'Pragma': 'no-cache'
        };
        const url = AppConfig.entityEndpoint;
        const params = {
            advfilter: `((!entity-type id equals "${EntityType.CONTACT.id}")` + 'and' + `(!entity-type id equals "${EntityType.TEAM.id}"))`,
            expand: 'contact-details',
            fields: 'id;short-name;long-name;entity-type;contact-details;is-public',
            page: 1,
            rpp: 50,
            sortby: 'short-name',
            sortorder: 'asc'
        };
        const formData = new FormData();
        // to filter entityType
        if (entityType) {
            formData.set('entitytype', JSON.stringify(entityType));
        }

        if (keyword && keyword.length > 0) {
            params['filterstring'] = keyword;
            params['filterby'] = 'short-name,long-name';
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.post(url, formData, options);
    }

    getContactTemplate(id: string): Observable<any> {
        const url = `${AppConfig.contactEndpoint}${'template/'}${id}`;
        return this._transportService.get(url);
    }

    setContactTemplate(id: string, data): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const url = `${AppConfig.contactEndpoint}${'template/'}${id}`;
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('template', JSON.stringify(data));
        return this._transportService.post(url, formData.toString(), options);
    }


    createContactEmployment(currentEmployment): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const url = `${AppConfig.employmentEndpoint}`;
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('employment', JSON.stringify(currentEmployment));
        return this._transportService.post(url, formData.toString(), options);
    }

    detectDuplicated(detectItem): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.contactEndpoint}` + 'duplicate';
        const params = {
            contact: JSON.stringify(detectItem),
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     *get job function for contact
     *
     * @returns {Observable<any>}
     * @memberof ContactService
     */
    getJobFunction(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.contactEndpoint}` + 'jobfunction';
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     * sync tamale contact into Exchange.
     * @param data
     * @returns
     */
    synchTamaleIntoExchange(id, action): Observable<any> {
        const url = `${AppConfig.exchangeSynchContact}` + id;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const params = {
            showsimple: true,
        };
        const options = {
            headers: headers,
            params: params
        };
        const formData = new URLSearchParams();
        formData.set('action', JSON.stringify(action));
        return this._transportService.put(url, formData.toString(), options);
    }

    /**
     * sync tamale contact into Exchange.
     * @param data
     * @returns
     */
    synchAllExchangetoTamale(): Observable<any> {
        const url = `${AppConfig.exchangeSynchContact}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('action', JSON.stringify({ 'operation': 'sync' }));
        formData.set('filter', JSON.stringify({ 'exchange': { 'status': ['Connected'] } }));
        return this._transportService.put(url, formData.toString(), options);
    }

    /**
     * get all contact alias
     *
     * @returns {Observable<any>}
     * @memberof ContactService
     */
    getContactAlias(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.contactEndpoint}` + 'alias';
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     *  create contact bulk action to exchange server
     * @param filter
     * @param excludes
     * @param syncType
     * @param parentId
     * @param hasBulkOnce
     * @returns id
     */
    createContactBulkAction(keyword, filter, excludes = [], syncType, parentId, hasBulkOnce = false): Observable<any> {
        const headers = {
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.contactEndpoint}` + 'bulk-operation';
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        const formData = new FormData();
        if (parentId && hasBulkOnce) {
            formData.append('condition', JSON.stringify(['failed']));
            formData.append('parentId', parentId);
        } else {
            if (filter) {
                formData.append('filter', JSON.stringify(filter));
            }
        }
        if (keyword) {
            formData.append('searchstring', keyword);
        }
        formData.append('excludes', JSON.stringify(excludes));
        // if sync type equals 3 ,means remove ownership.
        if (syncType === ExchangeBuklActionType.remove) {
            formData.append('action', JSON.stringify({ 'operation': 'removeOwnerShip', 'syncType': syncType }));
        } else {
            formData.append('action', JSON.stringify({ 'operation': 'sync', 'syncType': syncType }));
        }
        return this._transportService.post(url, formData, options);
    }

    /**
     * get all contact alias
     *
     * @returns {Observable<any>}
     * @memberof ContactService
     */
    getOneBulkAction(id): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.contactEndpoint}bulk-operation/${id}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     * Only used for exit bulk action.
     * @param id     * @returns
    */
    deleteOneBulkOperation(id): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.contactEndpoint}bulk-operation/${id}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.delete(url, options);
    }

    /**
     * Get bulk result after finished synch.now only get failed list.
     * @param id
    * @param page
    * @param rpp
    * @returns
    */
    getBulkContactList(id, page, rpp): Observable<any> {
        const headers = {
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.contactEndpoint}bulk-operation/${id}/result?page=${page}&rpp=${rpp}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        const formData = new FormData();
        formData.append('condition', JSON.stringify(['failed']));
        return this._transportService.post(url, formData, options);
    }

    /**
     * Get contact from email body,and email source
     *
     * @param body
     * @returns
     */
    getContactFromEmailSignature(body: string): Observable<any> {
        const headers = {
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.contactEndpoint}detection`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        const formData = new FormData();
        formData.append('body', body);
        const itemMetaData = {
            sender: Office.context.mailbox.item.sender || '',
            from: Office.context.mailbox.item.from || '',
            to: Office.context.mailbox.item.to || [],
            cc: Office.context.mailbox.item.cc || [],
            itemType: Office.context.mailbox.item.itemType,
            requiredAttendees: Office.context.mailbox.item.requiredAttendees || [],
            optionalAttendees: Office.context.mailbox.item.optionalAttendees || [],
            organizer: Office.context.mailbox.item.organizer || '',
        };
        formData.append('itemMetaData', JSON.stringify(itemMetaData));
        return this._transportService.post(url, formData, options);
    }

    /**
     * contact/check
     * params: optionalAttendees,requiredAttendees
     * @returns
     */
    checkContactFromEmail(): Observable<any> {
        const headers = {
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.contactEndpoint}checkout`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        const formData = new FormData();
        formData.append('optionalAttendees', JSON.stringify(Office.context.mailbox.item.optionalAttendees));
        formData.append('requiredAttendees', JSON.stringify(Office.context.mailbox.item.requiredAttendees));
        return this._transportService.post(url, formData, options);
    }

    checkDuplicateEvent(start: number, end: number, subject: string): Observable<any> {
        const headers = {
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.eventEndpoint}duplicate`;
        const params = {
            outputformat: 'json',
            start: start,
            end: end,
            subject: subject,
            expand: 'thread;entry;entities;entity;source;submitter;properties;property;propdef;entry-type',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.post(url, null, options);
    }

    /**
     *
     * @param keyword
     * @param filter
     * @returns excel
     */
    downloadContactList(sortBy, sortOrder, keyword, filterParams): Observable<any> {
        const url = `${AppConfig.contactEndpoint}bulk-operation/excel`;

        const params = {
            sortby: sortBy,
            sortorder: sortOrder
        };
        const formData = new FormData();
        if (keyword) {
            formData.append('searchstring', keyword);
        }
        if (filterParams) {
            formData.append('filter', JSON.stringify(filterParams));
        }
        const options = {
            params: params,
            responseType: 'arraybuffer'
        };
        return this._transportService.post(url, formData, options);
    }

    /**
     * delete contact by id
     * @param id contact id
     * @returns
     */
    deleteContactById(id): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.entityEndpoint}${id}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.delete(url, options);
    }

    /**
     * get employment list by entity id
     */
    getEmploymentListByEntityId(entityId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.employmentEndpoint}type/current/entity/` + entityId;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options).pipe(
            map(response => {
                return this._mapEmploymentList(response);
            })
        );
    }

    private _getFormData(contact: Contact): string {
        const formData = new URLSearchParams();

        const contactObj = new Object();
        contactObj['name'] = contact.name;
        contactObj['alias'] = contact.aliases;
        contactObj['birthday'] = contact.birthday || '';
        contactObj['jobTitle'] = contact.jobTitle;
        contactObj['company'] = contact.company;
        contactObj['primaryEmail'] = contact.primaryEmail;
        contactObj['secondaryEmail'] = contact.secondaryEmail;
        contactObj['businessPhone'] = contact.businessPhone;
        contactObj['homePhone'] = contact.homePhone;
        contactObj['mobilePhone'] = contact.mobilePhone;
        contactObj['businessFax'] = contact.businessFax;
        contactObj['businessAddress'] = contact.businessAddress;
        contactObj['homeAddress'] = contact.homeAddress;
        contactObj['relationship'] = contact.relationship;
        contactObj['defaultRelationshipType'] = contact.defaultRelationshipType ? contact.defaultRelationshipType.id : null;
        contactObj['notes'] = contact.notes;
        contactObj['socialMedia'] = contact.socialMedia;
        contactObj['firstName'] = contact.firstName;
        contactObj['lastName'] = contact.lastName;
        contactObj['jobFunction'] = contact.jobFunction;
        contactObj['template'] = contact.template;
        contactObj['webEmail'] = contact.webEmail;
        contactObj['webPhone'] = contact.webPhone;
        contactObj['webAddress'] = contact.webAddress;
        contactObj['employment'] = contact.employment;
        contactObj['attribute'] = contact.attribute;

        formData.set('contact', JSON.stringify(contactObj));

        return formData.toString();
    }

    private _mapEmploymentList(data) {
        if (!data) {
            return null;
        }
        const employments = [];
        const employmentList = data['current_employment'];
        employmentList.forEach(item => {
            // positive relationship
            const positiveRelationship = new Relationship();
            positiveRelationship.childEntity = new EntityBrief(item['company_id'].id, item['company_id']['long-name']);
            positiveRelationship.childEntity.shortName = item['company_id']['short-name'];
            positiveRelationship.relationshipId = item.id;
            positiveRelationship.relationshipType = new RelationshipType(item['title_type'].id, item['title_type'].name);
            positiveRelationship.parentEntity = new EntityBrief(item['contact_id'].id, item['contact_id']['long-name']);
            positiveRelationship.parentEntity.shortName = item['contact_id']['short-name'];
            employments.push(positiveRelationship);
            // inverse relationship
            const inverseRelationship = new Relationship();
            inverseRelationship.childEntity = new EntityBrief(item['contact_id'].id, item['contact_id']['long-name']);
            inverseRelationship.childEntity.shortName = item['contact_id']['short-name'];
            inverseRelationship.relationshipId = item.id;
            inverseRelationship.relationshipType = new RelationshipType(item['title_type'].id, item['title_type'].name);
            inverseRelationship.parentEntity = new EntityBrief(item['company_id'].id, item['company_id']['long-name']);
            inverseRelationship.parentEntity.shortName = item['company_id']['short-name'];
            employments.push(inverseRelationship);
        });
        return employments;
    }
}
