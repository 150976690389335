<div class="portfolio-upload-editor-container" loading-spin [isLoading]="loading" [loadingSize]="'big'">
    <div class="header-container">
        <label>{{ title }}</label>
        <smart-icon name="close" (click)="onSlideSheetClose()" class="light"></smart-icon>
    </div>
    <div class="portfolio-items-container">
        <div class="input-container tam-input-tip">
            <!--Configuration Name-->
            <div class="tam-form-field" [ngClass]="{'has-error':configurationName.invalid}" tid="configName">
                <label class="required">{{'portfolio.configuration_name' | stringLiterals}}</label>
                <input [(ngModel)]="crrPortfolio['configName']" class="tam-input tam-textbox item-content"
                    (blur)="configChanged()" value="{{ crrPortfolio['configName'] }}" [formControl]="configurationName">
                <div class="tam-form-help" *ngIf="configurationName.invalid && configurationName.errors['required']">
                    {{'general.required_error_message' | stringLiterals}}
                </div>
            </div>
            <!--Flat Filename-->
            <div class="tam-form-field" [ngClass]="{'has-error':filename.invalid}" tid="portfolioFileName">
                <label class="required">{{'portfolio.flat_filename' | stringLiterals}}</label>
                <input [(ngModel)]="crrPortfolio['portfolioFileName']" class="tam-input tam-textbox item-content"
                    (blur)="configChanged()" value="{{ crrPortfolio['portfolioFileName'] }}" [formControl]="filename">
                <div class="tam-form-help" *ngIf="filename.invalid && filename.errors['required']">
                    {{'general.required_error_message' | stringLiterals}}
                </div>
            </div>
            <!-- Column Delimiter -->
            <div class="tam-form-field" tid="selectDelimiter">
                <label>{{'portfolio.column_delimiter' | stringLiterals}}</label>
                <kendo-dropdownlist [popupSettings]="{appendTo: 'component'}" [(ngModel)]='selectDelimiter'
                    (blur)="configChanged()" class="kendo-dropdownlist-element item-left section" [data]="frequencyList"
                    [textField]="'text'" [valueField]="'value'">
                </kendo-dropdownlist>
            </div>
            <!--Column Number Containing Entities-->
            <div class="tam-form-field" tid="entityColumnNum">
                <label>{{'portfolio.column_number_containing_entities' | stringLiterals}}</label>
                <input [(ngModel)]="crrPortfolio['entityColumnNum']" class="tam-input tam-textbox item-content"
                    (blur)="configChanged()" value="{{ crrPortfolio['entityColumnNum'] }}">
            </div>
            <!--Entity Matching Method-->
            <div class="tam-form-field" tid="entityMatchingMethod">
                <label class="label-2">{{'portfolio.entity_matching_method' |
                    stringLiterals}}&nbsp;</label>
                <span>
                    <tam-radio [value]="'shortname'" [disabled]="false" (blur)="configChanged()" tid="shortname"
                        [(ngModel)]="crrPortfolio['entityMatching']"></tam-radio> Shortname
                    <tam-radio [value]="'name'" [disabled]="false" class="radio"
                        [(ngModel)]="crrPortfolio['entityMatching']" (blur)="configChanged()" tid="name"></tam-radio>
                    Name
                    <tam-radio [value]="'alias'" [disabled]="false" class="radio"
                        [(ngModel)]="crrPortfolio['entityMatching']" (blur)="configChanged()" tid="alias"></tam-radio>
                    Alias
                    <input *ngIf="crrPortfolio['entityMatching'] === 'alias'" class="tam-input radio-input radio"
                        (blur)="configChanged()" [(ngModel)]="crrPortfolio['aliasPrefix']" tid="aliasPrefix "
                        value="{{ crrPortfolio['aliasPrefix'] }}" placeholder="Prefix" />
                </span>
            </div>
            <!--Start at Row Number-->
            <div class="tam-form-field" tid="startRowNum">
                <label>{{'portfolio.start_at_row_number' | stringLiterals}}</label>
                <input [(ngModel)]="crrPortfolio['startRowNum']" class="tam-input tam-textbox item-content" type="text"
                    (blur)="configChanged()" value="{{ crrPortfolio['startRowNum'] }}">
            </div>
            <!--Target Portfolio Name (Entity or Contact)-->
            <div class="tam-form-field"
                [ngClass]="{'has-error':targetEntityName.invalid && targetEntityName.errors['required']}"
                tid="targetEntity">
                <label>{{'portfolio.target_portfolio_name' | stringLiterals}}<span
                        class="required-field">&nbsp;*</span></label>
                <kendo-dropdownlist #targetEntityList [(ngModel)]="targetEntity" class="control-content"
                    (blur)="configChanged()" [data]="targetDataItems" [filterable]="true" (open)="onOpenTarget($event)"
                    (filterChange)="targetFilter($event)" [textField]="'shortName'" [valueField]="'id'"
                    [formControl]="targetEntityName" (keydown.esc)="onEnter($event)"
                    (mousedown)="$event.preventDefault()">
                    <ng-template kendoComboBoxItemTemplate let-dataItem>
                        <tam-entity-dropdown-item [entity]="dataItem"></tam-entity-dropdown-item>
                    </ng-template>
                </kendo-dropdownlist>
                <div class="tam-form-help" *ngIf="targetEntityName.invalid && targetEntityName.errors['required']">
                    {{'general.required_error_message' | stringLiterals}}
                </div>
            </div>
            <!--Relationship type-->
            <div class="tam-form-field" [ngClass]="{'has-error':relationshipTypeName.invalid}"
                tid="selectRelationshipType">
                <label>{{'portfolio.relationship_type' | stringLiterals}}<span
                        class="required-field">&nbsp;*</span></label>
                <kendo-dropdownlist #relationshipList [data]="relationshipTypeItems"
                    [(ngModel)]="selectRelationshipType" [formControl]="relationshipTypeName" [textField]="'name'"
                    class="control-content" [valueField]="'id'" [filterable]="true" (blur)="configChanged()"
                    (filterChange)="handleFilter($event,relationshipList)" (focus)="relationshipList.toggle(true)"
                    (mousedown)="$event.preventDefault()">
                </kendo-dropdownlist>
                <div class="tam-form-help"
                    *ngIf="relationshipTypeName.invalid && relationshipTypeName.errors['required']">
                    {{'general.required_error_message' | stringLiterals}}
                </div>
            </div>
            <!--Replace existing relationships-->
            <label>{{'portfolio.replace_existing_relationships' | stringLiterals}}</label>
            <kendo-switch onLabel="On" offLabel="Off" [checked]="false" tid="replaceExistingRelationships"
                [(ngModel)]="crrPortfolio['replaceExistingRelationships']" (blur)="configChanged()">
            </kendo-switch>
            <!--Create Reciprocal Relationships-->
            <label>{{'portfolio.create_reciprocal_relationships' | stringLiterals}}</label>
            <kendo-switch onLabel="On" offLabel="Off" [checked]="false" (blur)="configChanged()"
                [(ngModel)]="crrPortfolio['createReciprocals']" tid="createReciprocals">
            </kendo-switch>
            <!--Submit error report to entity-->
            <label>{{'portfolio.submit_error_report_to_entity' | stringLiterals}}<span
                    class="required-field">&nbsp;*</span></label>
            <div class="tam-form-field" [ngClass]="{'has-error':errorEntityName.invalid}" tid="errorEntity">
                <kendo-dropdownlist #entityList [(ngModel)]="errorEntity" class="control-content"
                    [formControl]="errorEntityName" [data]="relatedDataItems" [filterable]="true"
                    (open)="onOpenRelated($event)" (blur)="configChanged()" (filterChange)="relatedFilter($event)"
                    [textField]="'shortName'" [valueField]="'id'" (keydown.esc)="onEnter($event)"
                    (mousedown)="$event.preventDefault()">
                    <ng-template kendoComboBoxItemTemplate let-dataItem>
                        <tam-entity-dropdown-item [entity]="dataItem"></tam-entity-dropdown-item>
                    </ng-template>
                </kendo-dropdownlist>
                <div class="tam-form-help" *ngIf="errorEntityName.invalid && errorEntityName.errors['required']">
                    {{'general.required_error_message' | stringLiterals}}
                </div>
            </div>
            <!--Send email error report to contact-->
            <label>{{'portfolio.send_email_error_report_to_contact' |
                stringLiterals}}</label>
            <kendo-dropdownlist #contactList [data]="contactTypeItems" [(ngModel)]="selectContactType"
                tid="selectContactType" [textField]="'name'" class="control-content" [valueField]="'id'"
                [filterable]="true" (blur)="configChanged()"
                (filterChange)="contactTypeHandleFilter($event,contactList)" (focus)="contactList.toggle(true)"
                (mousedown)="$event.preventDefault()" [disabled]=true>
            </kendo-dropdownlist>
            <div class="tam-form-field" tid="ignoresList">
                <!--Entity ignore list (comma delimited)-->
                <label>{{'portfolio.entity_ignore_list' | stringLiterals}}</label>
                <input [(ngModel)]="crrPortfolio['ignoresList']" class="tam-input tam-textbox item-content"
                    (blur)="configChanged()" value="{{ crrPortfolio['ignoresList'] }}">
            </div>
        </div>
        <div class="button-container" tid="buttons">
            <button tam-button [tamType]="'primary'" [tamSize]="'normal'" class="button" [disabled]="isChanged"
                (click)="onSubmitClick()">{{modelTitleSubmit}}
            </button>
            <button tam-button [tamType]="'tertiary'" [tamSize]="'normal'" class="button" (click)="onSlideSheetClose()">
                {{'general.cancel' | stringLiterals}}
            </button>
        </div>
    </div>
</div>