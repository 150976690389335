/**
 * Created by JUNO PENG 1/5/2024.
 * Description:
 * portfolio editor componmnet
 *
 * ------ maintenance history ------
 */

import { AppState } from '../../../../../redux';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _lodash from 'lodash';
import { Subscription } from 'rxjs';
import { Store, createSelector, select } from '@ngrx/store';
import { debounceTime, filter, take } from 'rxjs/operators';
import { lastCustomActionSelector } from '../../../../../redux/reducers/action-history.reducer';
import { StringLiteralsPipe } from '../../../../../pipes/translate.pipe';
import { SlideSheetService } from '../../../../../services/slide-sheet.service';
import { SlideSheetActionTypes } from '../../../../slide-sheet/slide-sheet.model';
import { EntityFilterType, PortfolioUploadModel } from '../../../../../tamalelibs/models/portfolio-upload.model';
import { CreatePortfolios, EditPortfolios, PortfolioActionTypes } from '../../../../../redux/actions/portfolio.action';
import { BriefConfig } from '../../../../../widgets/widgets.model';
import { EntityService } from '../../../../../tamalelibs/services/entity.service';
import { relationshipTypeSelector } from '../../../../../redux/reducers/relationship-type.reducer';
import { contactsSelector } from '../../../../../redux/reducers/system.reducer';
import { portfolioUploadSelector } from '../../../../../redux/reducers/portfolio.reducer';
import { AlertWindowService } from '../../../../../widgets/alert-window/alert-window.service';
import { UntypedFormControl } from '@angular/forms';
import { ArrayHelperService } from '../../../../../tamalelibs/services/array-helper.service';
import { fullUsersSelector } from '../../../../../redux/reducers/users.reducer';

@Component({
    selector: 'portfolio-upload-editor',
    templateUrl: './portfolio-upload-editor.component.html',
    styleUrls: ['./portfolio-upload-editor.component.scss']
})
export class PortfolioUploadEditorComponent implements OnInit, OnDestroy {
    @Input() portfolioData?: any;
    @ViewChild('entityList', { static: false }) entityList;
    @ViewChild('targetEntityList', { static: false }) targetEntityList;
    @ViewChild('contactList', { static: false }) contactList;

    //#region public properties
    configurationName = new UntypedFormControl('');
    filename = new UntypedFormControl('');
    targetEntityName = new UntypedFormControl({});
    relationshipTypeName = new UntypedFormControl({});
    errorEntityName = new UntypedFormControl({});
    crrPortfolio: PortfolioUploadModel;

    frequencyList: Array<{ text: string, value: string }> = [];
    relationshipTypeItems: Array<{ id: string, name: string }> = [];
    contactTypeItems: Array<any> = [];
    selectRelationshipType = { id: '', name: '' };
    selectDelimiter = { text: 'tab', value: '\\t' };
    selectContactType = { id: '', name: '' };
    errorEntity = { id: '', name: '', shortName: '' };
    targetEntity = { id: '', name: '', shortName: '' };

    loading = true;
    isChanged = true;
    relatedDataItems: Array<any> = [];
    targetDataItems: Array<any> = [];
    portfolioListData: Array<{ id: string, name: string }> = [];

    title = StringLiteralsPipe.translate('portfolio.create_new_protfolio_upload_configuration');
    modelTitleSubmit = StringLiteralsPipe.translate('general.create');

    briefConfig = new BriefConfig();
    isProgressing = false;
    relationshipTypes: any;
    contactTypes: any;
    //#endregion

    //#region private properties
    private _destroySubscriptions: Array<Subscription> = [];
    private _isLoading = false;
    //#endregion

    constructor(
        private _alertWindow: AlertWindowService,
        private _store: Store<AppState>,
        private _slideSheetService: SlideSheetService,
        private _entityService: EntityService
    ) { }

    //#region angular functions
    ngOnInit() {
        this._initdelimiterTypeModel();
        this._destroySubscriptions.push(
            this._store.pipe(
                select(portfolioUploadSelector),
                filter(data => data),
            ).subscribe(() => {
                this.loading = false;
            }),
            this._store.pipe(
                select(lastCustomActionSelector)
            ).subscribe(action => {
                this._onSubscribeActionMessage(action);
            }),

            this.briefConfig.actionSubject$.pipe(
                debounceTime(250),
                filter(res => res != null && res.payload.length > 0)
            ).subscribe(res => {
                if (res.type === EntityFilterType.Related) {
                    this._searchingRelated(res.payload);
                } else if (res.type === EntityFilterType.Target) {
                    this._searchingTarget(res.payload);
                }
            }),
        );

        this._initExistRelationshipTypes();
        this._initExistContactTypes();
        this._editorInit();
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }
    //#endregion

    //#region public functions
    /**
     * The empty check error should be cleard when inputting some charactors
     */
    onSlideSheetClose() {
        if (!this.isChanged) {
            const message = `Are you sure you want to discard all your changes?`;
            const subscription = this._alertWindow.warn('You have unsaved changes',
                [message], StringLiteralsPipe.translate('general.discard'), StringLiteralsPipe.translate('general.go_back'))
                .subscribe((result: boolean) => {
                    if (result) {
                        this._slideSheetService.slideSheetActionSubject$.next({
                            type: SlideSheetActionTypes.CLOSE
                        });
                    }
                    subscription.unsubscribe();
                });
        } else {
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CLOSE
            });
        }
    }

    onOpenRelated(event: any): void {
        this._searchingRelated('');
    }

    onOpenTarget(event: any): void {
        this._searchingTarget('');
    }

    onEnter(event) {
        event.stopImmediatePropagation();
    }

    relatedFilter(value) {
        this.briefConfig.actionSubject$.next({
            type: EntityFilterType.Related,
            payload: value
        });
    }

    targetFilter(value) {
        this.briefConfig.actionSubject$.next({
            type: EntityFilterType.Target,
            payload: value
        });
    }

    configChanged() {
        this.isChanged = false;
    }

    onSubmitClick() {
        if (this._doValidation()) {
            this._isLoading = false;
            this.isProgressing = true;
            if (this.crrPortfolio.entityMatching !== 'alias') {
                this.crrPortfolio.aliasPrefix = '';
            }
            if (String(this.crrPortfolio.startRowNum) === '0' || String(this.crrPortfolio.startRowNum) === '') {
                this.crrPortfolio.startRowNum = 1;
            }
            if (String(this.crrPortfolio.entityColumnNum) === '0' || String(this.crrPortfolio.entityColumnNum) === '') {
                this.crrPortfolio.entityColumnNum = 1;
            }
            this.crrPortfolio.delimiter = this.selectDelimiter.value || '';
            this.crrPortfolio.errorEntityID = this.errorEntity.id || '';
            this.crrPortfolio.targetEntityID = this.targetEntity.id || '';
            this.crrPortfolio.relationshipTypeID = this.selectRelationshipType.id || '';
            this.crrPortfolio.errorContactID = this.selectContactType?.id || '';
            this.crrPortfolio.portfolioFileTime = Date.now();
            if (this.title === StringLiteralsPipe.translate('portfolio.create_new_protfolio_upload_configuration')) {
                this._store.dispatch(new CreatePortfolios({
                    data: this.crrPortfolio,
                    successMessage: `${this.crrPortfolio['configName']} is created successfully.`
                }));
            } else {
                this._store.dispatch(new EditPortfolios({
                    data: this.crrPortfolio,
                    successMessage: `${this.crrPortfolio['configName']} is updated successfully.`
                }));
            }

            this.isChanged = true;
        }
    }

    handleFilter(value, dropDownEl) {
        if (!value) {
            this.relationshipTypeItems = this.relationshipTypes;
        } else {
            this.relationshipTypeItems = this.relationshipTypes.filter((s) => s.name?.toLowerCase().indexOf(value?.toLowerCase()) !== -1);
            if (this.relationshipTypeItems.length >= 1) {
                setTimeout(() => {
                    dropDownEl.optionsList.content.nativeElement.scrollTop = 0;
                });
            }
        }
    }

    contactTypeHandleFilter(value, dropDownEl) {
        if (!value) {
            this.contactTypeItems = this.contactTypes;
        } else {
            this.contactTypeItems = this.contactTypes.filter((s) => s.name?.toLowerCase().indexOf(value?.toLowerCase()) !== -1);
            if (this.contactTypeItems.length >= 1) {
                setTimeout(() => {
                    dropDownEl.optionsList.content.nativeElement.scrollTop = 0;
                });
            }
        }
    }
    //#endregion

    //#region private functions
    private _doValidation() {
        let isValid = true;
        this.configurationName.setErrors(null);
        this.filename.setErrors(null);
        this.targetEntityName.setErrors(null);
        this.errorEntityName.setErrors(null);
        this.relationshipTypeName.setErrors(null);
        if (!this.configurationName.value) {
            this.configurationName.setErrors({
                required: true
            });
            isValid = false;
        }
        if (!this.filename.value) {
            this.filename.setErrors({
                required: true
            });
            isValid = false;
        }

        if (!this.targetEntityName.value.id) {
            this.targetEntityName.setErrors({
                required: true
            });
            isValid = false;
        }
        if (!this.errorEntityName.value.id) {
            this.errorEntityName.setErrors({
                required: true
            });
            isValid = false;
        }

        if (!this.relationshipTypeName.value.id) {
            this.relationshipTypeName.setErrors({
                required: true
            });
            isValid = false;
        }
        return isValid;
    }

    private _initExistRelationshipTypes() {
        this._destroySubscriptions.push(
            // get relationship types from store
            this._store.pipe(select(relationshipTypeSelector),
                take(1)
            ).subscribe(res => {
                // get datasource for relationship types from store
                this.relationshipTypes = [];
                this.relationshipTypes.push(...res);
                this.relationshipTypeItems = _lodash.cloneDeep(this.relationshipTypes);
            })
        );
    }

    private _initExistContactTypes() {
        // combine contact and user data
        const dataSelector = createSelector(
            contactsSelector,
            fullUsersSelector,
            (contactData, userData) => {
                let data = contactData.contacts;
                if (data && data.length > 0) {
                    data = data.filter(item => !item.isLogin)
                        .map(({ id, name, shortName }) => ({ id, name, shortName }));
                }

                let userList;
                if (userData && userData.length > 0) {
                    userList = userData.map(({ id, name, shortName }) => ({ id, name, shortName }));
                }
                data = data.concat(userList);
                ArrayHelperService.sort(data, 'name');
                return data;
            }
        );
        this._destroySubscriptions.push(
            this._store.pipe(
                select(dataSelector)
            ).subscribe((res) => {
                this.contactTypes = res;
                this.contactTypeItems = _lodash.cloneDeep(this.contactTypes);
            })
        );
    }

    private _editorInit() {
        this._isLoading = true;
        if (!this.portfolioData) {
            this.title = StringLiteralsPipe.translate('portfolio.create_new_protfolio_upload_configuration');
            this.crrPortfolio = new PortfolioUploadModel();
            this.selectDelimiter = this.frequencyList.find((item) => item.value === this.crrPortfolio.delimiter);
        } else {
            this.title = StringLiteralsPipe.translate('portfolio.edit_protfolio_upload_configuration');
            this.crrPortfolio = _lodash.cloneDeep(this.portfolioData);
            this._setPortfolioType();
        }
        this.modelTitleSubmit = this.title === StringLiteralsPipe.translate('portfolio.create_new_protfolio_upload_configuration') ? StringLiteralsPipe.translate('general.create') : StringLiteralsPipe.translate('general.update');
    }

    private _initRelatedDataItems(entityList) {
        this.relatedDataItems = this._entityService.mapEntityList(entityList).entities;
    }

    private _initTargetDataItems(targetEntityList) {
        this.targetDataItems = this._entityService.mapEntityList(targetEntityList).entities;
    }

    private _onSubscribeActionMessage(action) {
        if (action.type === PortfolioActionTypes.CREATEPORTFOLIOS_SUCCESS || action.type === PortfolioActionTypes.EDITPORTFOLIOS_SUCCESS) {
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CLOSE
            });
        } else if (!this._isLoading && action.type === PortfolioActionTypes.CREATEPORTFOLIOS_FAILED) {
            this.isProgressing = false;
        }
    }

    private _setPortfolioType() {
        this.selectDelimiter = this.frequencyList.find((item) => item.value === this.portfolioData.delimiter);
        this.errorEntity = this.portfolioData.errorEntity;
        this.targetEntity = this.portfolioData.targetEntity;
        this.selectRelationshipType = this.relationshipTypeItems.find((item) => item.id === this.portfolioData.relationshipTypeID);
        this.selectContactType = this.contactTypeItems.find((item) => item.id === this.portfolioData.errorContactID);
        this.crrPortfolio.errorEntityID = this.portfolioData.errorEntity.id || '';
        this.crrPortfolio.targetEntityID = this.portfolioData.targetEntity.id || '';
        this.crrPortfolio.aliasPrefix = this.portfolioData.aliasPrefix || '';
        this.crrPortfolio.entityMatching = this.portfolioData.entityMatching || 'shortname';
    }

    private _initdelimiterTypeModel(): void {
        this.frequencyList.push({ text: 'tab', value: '\\t' });
        this.frequencyList.push({ text: 'comma (,)', value: ',' });
        this.frequencyList.push({ text: 'pipe (|)', value: '\\|' });
        this.frequencyList.push({ text: 'semicolon (;)', value: ';' });
        this.frequencyList.push({ text: 'colon (:)', value: ':' });
        this.frequencyList.push({ text: 'exclamation (!)', value: '\\!' });
        this.frequencyList.push({ text: 'space ( )', value: '\\s' });
    }

    private _searchingRelated(filterStr: string) {
        this.entityList.loading = true;
        this.entityList.toggle(false);
        this._destroySubscriptions.push(this._entityService.getEntityListBySearchTextQuick(1, 50, filterStr)
            .subscribe(
                res => {
                    this._initRelatedDataItems(res);
                    this.entityList.loading = false;
                    this.entityList.toggle(true);
                }
            ));
    }

    private _searchingTarget(filterStr: string) {
        this.targetEntityList.loading = true;
        this.targetEntityList.toggle(false);
        this._destroySubscriptions.push(this._entityService.getEntityListBySearchTextQuick(1, 50, filterStr)
            .subscribe(
                res => {
                    this._initTargetDataItems(res);
                    this.targetEntityList.loading = false;
                    this.targetEntityList.toggle(true);
                }
            ));
    }
    //#endregion
}
