/**
 * Created by Alex on 6/4/2019.
 * Description:
 *
 * ------ maintenance history ------
 */
import { AppState } from '..';
import { createSelector, combineReducers } from '@ngrx/store';
import { EntityScreenActionTypes, EntityScreenActions } from '../actions/entity-screen.actions';

export function entityScreenDataReducer(state: any = {}, action: EntityScreenActions) {
    switch (action.type) {
        case EntityScreenActionTypes.LOAD_SUCCESS:
            return action.payload;
        case EntityScreenActionTypes.LOAD_FAILED:
            return null;
        default:
            return state;
    }
}

export const getEntityScreenState = (state: AppState) => state.entityScreen;
export const getEntityScreenSelector = createSelector(getEntityScreenState, (state: any) => state.entityScreenItem);


const entityScreenReducer = combineReducers({
    entityScreenItem: entityScreenDataReducer
});

export function getEntityScreenReducer(state, action) {
    return entityScreenReducer(state, action);
}
