<div class="check-list-field-container container tam-form-field" [ngClass]="{'borderless': isBorderless()}">
    <div class="check-list-caption tam-form-label">
        <field-label [label]="config.label||config.field.fieldDefinition.name"
            [tooltip]="config.description||config.field.fieldDefinition.description" [isRequired]="config.required"
            labelClassList="field-name"></field-label>
    </div>
    <!-- this template is used for check list's preview in the formbuilder -->
    <div class="items-container" *ngIf="isPreviewMode()">
        <div class="item-wrapper" *ngFor="let checkItem of config.checkItems">
            <label class="field-control" tam-checkbox contentClass="ml-4">
                {{checkItem.description}}
            </label>
        </div>
    </div>
    <!-- this template is used for check list field's edit in Task Detail Page -->
    <div class="items-container" *ngIf="isFullMode()" [ngClass]="{'borderless': isBorderless()}">
        <div class="item-wrapper" *ngFor="let fieldItem of fieldValue">
            <label class="field-control" tam-checkbox [ngModel]="fieldItem.value"
                [ngClass]="{'with-error': isFieldValueInvalid && isFieldItemInvalid(fieldItem)}"
                (ngModelChange)="afterItemCheckChanged($event, fieldItem.key)"
                [disabled]="!config.editable||config.disabled" contentClass="ml-4">
                <span kendoTooltip
                    title="{{getItemDescription(fieldItem.key)}}">{{getItemDescription(fieldItem.key)}}</span>
            </label>
        </div>
    </div>
    <div class="error-info" *ngIf="isFullMode() && isFieldValueInvalid">
        {{'workflow.mark_required_check_list_item' | stringLiterals}}
    </div>
</div>