/**
 * Created by Ella Ma on 1/10/2018.
 * Description:
 *	pure filter items for reusable.
 *
 * ------ maintenance history ------
 */

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FiltersConfig, FilterMetadataType } from '../../tamalelibs/components/filter-config';
import { FilterWidgetConfig, FilterWidgetFeedback, FilterWidgetFeedbackType } from '../../tamalelibs/components/filter-widget.config';
import { FilterWidgetAciontType } from './tam-text-filter.component';
import { MultiSelDropdownConfig, MultiSelDropdownFilterConfig } from '../multi-sel-dropdown-filter/multi-sel-dropdown-filter.model';
import { AppState } from '../../redux';
import { fullEntryTypeSelector } from '../../redux/reducers/entry-type.reducer';
import { fullEntityTypeSelector } from '../../redux/reducers/entity-type.reducer';
import { ALL_VALUE } from '../../tamalelibs/constants/business.constants';
import { ArrayHelperService } from '../../tamalelibs/services/array-helper.service';

export const SELECT_A_TYPE = 'select a type';
export const SELECT_ALL_TYPE = 'All entry types';
/**
 * Filter widget for metadataType is NodeType and EntityType
 *
 * @export
 * @class TamMultiPopupFilterComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'tam-multi-popup-filter',
    templateUrl: 'tam-multi-popup-filter.component.html',
    styleUrls: ['tam-filter-container.component.scss']
})

export class TamMultiPopupFilterComponent implements OnInit, OnDestroy {

    @Input() config: FiltersConfig; // data
    @Input() filterWidgetConfig: FilterWidgetConfig; // event
    @Input() isHiddenTrashIcon = false;

    defaultItem: { name: string, value: string };
    operators: Array<{ name: string, value: string }>;
    isItemHoverOn = false;
    isOnlyEvent = false;
    dropdownConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig();
    typeConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    typeConfigData: Array<MultiSelDropdownConfig> = [{
        id: 'eventTypes',
        checked: true,
        name: 'Event Types'
    }, {
        id: 'noteTypes',
        checked: true,
        name: 'Note Types'
    }];
    typeName = SELECT_ALL_TYPE;
    typeNameId = 'entryTypes';

    private _entryTypes = [];
    private _storeSubscription$: Subscription;


    constructor(
        private _store: Store<AppState>,
        private _deviceService: DeviceDetectorService) { }

    ngOnInit() {
        this.operators = this.config.operators;
        this.defaultItem = this.config.operators.find(item => item.value === this.config.selectedOperator);
        if (this.config.metadataType?.toLowerCase() === FilterMetadataType.NoteType.toLowerCase() ||
            this.config.metadataType?.toLowerCase() === FilterMetadataType.EntryType.toLowerCase()) {
            this._storeSubscription$ = this._store.pipe(select(fullEntryTypeSelector))
                .subscribe(items => this._onSubscribeFilterList(items, true));
            this._loadDefauleValue();
        } else if (this.config.metadataType?.toLowerCase() === FilterMetadataType.EntityType.toLowerCase()) {
            this._storeSubscription$ = this._store.pipe(select(fullEntityTypeSelector))
                .subscribe(items => this._onSubscribeFilterList(items));
        } else if (this.config.metadataType?.toLowerCase() === FilterMetadataType.FileType.toLowerCase() ||
            this.config.metadataType?.toLowerCase() === FilterMetadataType.EventType.toLowerCase() ||
            this.config.metadataType?.toLowerCase() === FilterMetadataType.SpecificUsers.toLowerCase() ||
            this.config.metadataType?.toLowerCase() === FilterMetadataType.SpecificContacts.toLowerCase() ||
            this.config.metadataType?.toLowerCase() === FilterMetadataType.SpecificTeams.toLowerCase() ||
            this.config.metadataType?.toLowerCase() === FilterMetadataType.CustomField.toLowerCase()) {
            const value = this.config.value;
            this.dropdownConfig.data = this.config.source.map(data => ({
                id: data.id,
                checked: this._getCheckedStatus(value, data),
                name: data.name
            }));
        }
        this.dropdownConfig.onChangeValue$.subscribe(data => {
            const value = typeof data === 'string' ? data : data.map(item => (item.id || item));
            this._feedback(FilterWidgetAciontType.TEXT_CHANGE, value);
        });
        this.typeConfig.onChangeValue$.subscribe(data => {
            const value = typeof data === 'string' ? 'entryTypes' : data.map(item => item.id).join(',');
            this._feedback(FilterWidgetAciontType.TYPE_CHANGE, value);
        });

        // For use within normal web clients
        if (this._deviceService.isMobile() || this._deviceService.isTablet()) {
            this.isItemHoverOn = true && !this.isHiddenTrashIcon;
        } else {
            this.isItemHoverOn = !this.config.deletable && !this.isHiddenTrashIcon;
        }

        this.typeConfig.data = this.typeConfigData;
        this.typeConfig.hideSearchBox = true;
        this.typeConfig.hideHeader = true;
        this.typeConfig.onChangeValue$.subscribe(data => this._onTypeActionChange(data));

        this.filterWidgetConfig.actionSubject$.subscribe(data => {
            if (this.config.metadataType?.toLowerCase() === FilterMetadataType.NoteType.toLowerCase() ||
                this.config.metadataType?.toLowerCase() === FilterMetadataType.EntryType.toLowerCase() ||
                this.config.metadataType?.toLowerCase() === FilterMetadataType.EventType.toLowerCase()) {
                if (data.type === 'reset') {
                    if (data.payload === 'ENTRY') {
                        this.isOnlyEvent = false;
                        const item = 'all';
                        this._onTypeActionChange(item);
                    } else {
                        this.isOnlyEvent = true;
                        const item = [{
                            id: 'eventTypes',
                            checked: true,
                            name: 'Event Types'
                        }];
                        this._onTypeActionChange(item);
                    }
                }
            }
        });
    }

    ngOnDestroy() {
        if (this._storeSubscription$) {
            this._storeSubscription$.unsubscribe();
        }
        if (this.dropdownConfig.onChangeValue$) {
            this.dropdownConfig.onChangeValue$.unsubscribe();
        }
    }

    onBtnCloseClicked() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.remove;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    onMouseOver() {
        if (!this.config.deletable) {
            this.isItemHoverOn = true && !this.isHiddenTrashIcon;
        }
    }

    onMouseOut() {
        if (!this.config.deletable) {
            this.isItemHoverOn = false && !this.isHiddenTrashIcon;
        }
    }

    onSelectTypeClick(event) {
        this.typeConfig.open$.next(event.target);
    }

    valueChange(changeItem: { name: string, value: string }): void {
        // emits event for value change
        this._feedback(FilterWidgetAciontType.SELECTOR_CHANGE, changeItem.value);
    }

    private _feedback(type: FilterWidgetAciontType, value: any) {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.update;

        if (FilterWidgetAciontType.SELECTOR_CHANGE === type) {
            this.config.selectedOperator = null;
            this.config.selectedOperator = value;
        } else if (FilterWidgetAciontType.TEXT_CHANGE === type) {
            this.config.value = value;
        } else if (FilterWidgetAciontType.TYPE_CHANGE === type) {
            this.config.typeValue = value;
        }

        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    private _getCheckedStatus(value, data): boolean {
        if (value) {
            if (typeof (value) === 'string') {
                return value && (value.indexOf(data.id) !== -1 || value === ALL_VALUE);
            } else {
                return value.toString().indexOf(data.id.toString()) !== -1 || value === ALL_VALUE;
            }
        } else {
            return false;
        }
    }

    private _onSubscribeFilterList(item: Array<any>, isEntryType = false): void {
        const value = this.config.value;
        if (item && item.length > 0) {

            ArrayHelperService.sort(item, 'name');
            if (isEntryType) {
                this._entryTypes = item;
            }
            this.dropdownConfig.data = item.map(data => ({
                id: data.id,
                checked: value?.indexOf(data.id) !== -1 || value === ALL_VALUE,
                name: data.name,
                usage: data.usage
            }));
        }
    }

    private _loadDefauleValue() {
        this.typeNameId = this.config.typeValue ? this.config.typeValue : 'entryTypes';
        this._setTypeConfigData();

        const value = this.config.value;
        let source = [];
        if (this.typeNameId === 'entryTypes') {
            source = this._entryTypes;
        } else {
            this._entryTypes.forEach(item => {
                item.usage.forEach(type => {
                    if (this.typeNameId.toLowerCase().indexOf(type.toLowerCase()) > -1) {
                        source.push(item);
                    }
                });
            });
        }
        this.dropdownConfig.data = source.map(data => ({
            id: data.id,
            checked: value?.indexOf(data.id) !== -1 || value === ALL_VALUE,
            name: data.name,
            usage: data.usage
        }));
    }

    private _onTypeActionChange(data): void {
        if (data) {
            if (data.length === 0) {
                this.typeNameId = '';
                this.typeName = SELECT_A_TYPE;

                this.dropdownConfig.data = [];
                this.dropdownConfig.onChangeValue$.next('');
            } else if (data === 'all') {
                const value = this.config.value;
                this.typeNameId = 'entryTypes';
                this.typeName = SELECT_ALL_TYPE;

                this.dropdownConfig.data = this._entryTypes.map(item => ({
                    id: item.id,
                    checked: value?.indexOf(item.id) !== -1 || value === ALL_VALUE,
                    name: item.name,
                    usage: item.usage
                }));
            } else {
                this.typeName = data.map(item => item.name).join(',');
                this.typeNameId = data.map(item => item.id).join(',');
                const source = [];
                this._entryTypes.forEach(item => {
                    item.usage.forEach(type => {
                        if (this.typeNameId.toLowerCase().indexOf(type.toLowerCase()) > -1) {
                            source.push(item);
                        }
                    });
                });
                // whether the value is included in the source
                let vaildValues = this.config.value;
                if (Array.isArray(this.config.value)) {
                    vaildValues = [];
                    this.config.value.forEach(item => {
                        if (source.map(_data => _data.id).includes(item)) {
                            vaildValues.push(item);
                        }
                    });
                }

                // const value = this.config.value;
                this.dropdownConfig.data = source.map(item => ({
                    id: item.id,
                    checked: vaildValues?.indexOf(item.id) !== -1 || vaildValues === ALL_VALUE,
                    name: item.name,
                    usage: item.usage
                }));
                if (!ArrayHelperService.isEqual(vaildValues, this.config.value, true)) {
                    this.dropdownConfig.onChangeValue$.next(vaildValues);
                }
            }
            this._setTypeConfigData();
            this._feedback(FilterWidgetAciontType.TYPE_CHANGE, this.typeNameId);
        }
    }

    private _setTypeConfigData() {
        if (this.typeNameId === 'entryTypes') {
            this.typeName = SELECT_ALL_TYPE;
            this.typeConfigData.forEach(item => {
                item.checked = true;
            });
        } else {
            this.typeConfigData.forEach(item => {
                if (item.id === this.typeNameId) {
                    item.checked = true;
                    this.typeName = item.name;
                } else {
                    item.checked = false;
                }

            });
        }
        this.typeConfig.data = this.typeConfigData;
    }
}
