/**
 * Created by yxzhang on 9/10/16.
 * Description: contains functions that deal with date, and date format
 *
 * ------ maintenance history ------
 * updated by Alan Yang 1/8/19
 * added getDateString.
 * updated by Marcus Zhao 8/28/19
 * added getTimeStamp.
 * added judgeTimeFormat.
 * ------ API description ------
 *
 * - formatDate: get a formatted with day, month, and year string
 * - getToday: return a formatted today date string
 * - getPastDateByDeltaDays: return a formatted date string for several days past today
 * - getFutureDateByDeltaDays: return a formatted date string for serveral days later
 * - getPastDateByDeltaMonths: return a formatted date string for several months past today
 * - getPastDateByDeltaYears: return a formatted date string for several years past today
 * - getTimeOfToday: return a time string of today
 * - getTimeOfPastDateByDeltaDays: return a time string of several days past today
 * - getTimeOfPastDateByDeltaMonths: return a time string of several months past today
 * - getTimeOfPastDateByDeltaYears: return a time string of several years past today
 * - getDateStringByDateObject: return a date string for a specific date
 * - isFutureDate: return true if a specific date is in the future
 *
 * ------ current supported date format ------
 *  mm-dd-yyyy
 *  mm/dd/yyyy
 *
 *  default: mm-dd-yyyy
 */
import { Injectable } from '@angular/core';
import { parseToShortMonth } from '../pipes/date.pipe';

@Injectable()
export class DateHelperService {
    /**
     * format date according to the format option
     * demo format: 'yyyy-MM-dd HH:mm:ss'
     * @param val: date value
     * @param fmt: format
     */
    fullFormat(val, fmt) {
        if (!(val instanceof Date)) {
            val = new Date(val);
            val.setHours(val.getHours() + val.getTimezoneOffset() / 60);
        }
        const o = {
            'M+': val.getMonth() + 1,
            'd+': val.getDate(),
            'h+': val.getHours(),
            'm+': val.getMinutes(),
            's+': val.getSeconds(),
            'q+': Math.floor((val.getMonth() + 3) / 3),
            'S': val.getMilliseconds()
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (val.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (const k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
            }
        }
        return fmt;
    }

    /**
     * format date according to the format option
     * current supported format: 'mm-dd-yyyy' and 'mm/dd/yyyy'
     * @param dd: day
     * @param mm: month
     * @param yyyy: year
     * @param format: format option
     * @returns string: formatted date string
     */
    formatDate(mm, dd, yyyy, format = '', withPadding = true) {
        if (withPadding) {
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
        }
        switch (format) {
            case 'mm-dd-yyyy':
                return mm + '-' + dd + '-' + yyyy;
            case 'yyyy-mm-dd':
                return yyyy + '-' + mm + '-' + dd;
            default:
                return mm + '/' + dd + '/' + yyyy;
        }
    }

    /**
     * format example: Sep.02.2023
     * @param dateTime: Wed May 31 2023 10:00:00
     * @returns
     */
    getSpecificFormatOfDate(dateTime, hasHour: boolean) {
        if (hasHour) {
            return `${parseToShortMonth(dateTime.getMonth() + 1)}.${dateTime.getDate().toString().padStart(2, '0')}.${dateTime.getFullYear()}` + ` ${dateTime.getHours().toString().padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}`;
        } else {
            return `${parseToShortMonth(dateTime.getMonth() + 1)}.${dateTime.getDate().toString().padStart(2, '0')}.${dateTime.getFullYear()}`;
        }
    }

    /**
     * get date of today with the required date format
     * current supported format: 'mm-dd-yyyy' and 'mm/dd/yyyy'
     * @param format: format option, as a string
     * @returns string: formatted today as a string
     */
    getToday(format) {
        const today = new Date();
        if (format == null) {
            return today;
        } else {
            const dd = today.getDate(),
                mm = today.getMonth() + 1,
                yyyy = today.getFullYear();
            return this.formatDate(mm, dd, yyyy, format);
        }
    }

    /**
     * get past date with the required date format, use days as the delta
     * current supported format: 'mm-dd-yyyy' and 'mm/dd/yyyy'
     * @param deltaDays: how many days in the past
     * @param format: date format option
     * @returns string: formatted past date as a string
     */
    getPastDateByDeltaDays(deltaDays, format) {
        let today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        today.setDate(today.getDate() - deltaDays);
        if (format == null) {
            return today;
        } else {
            const dd = today.getDate(),
                mm = today.getMonth() + 1,
                yyyy = today.getFullYear();
            return this.formatDate(mm, dd, yyyy, format);
        }
    }

    /**
     * get future date with the required date format, use days as the delta
     * current supported format: 'mm-dd-yyyy' and 'mm/dd/yyyy'
     * @param deltaDays: how many days in the future
     * @param format: date format option
     * @returns string: formatted future date as a string
     */
    getFutureDateByDeltaDays(deltaDays, format) {
        const today = new Date();
        today.setDate(today.getDate() + deltaDays);
        if (format == null) {
            return today;
        } else {
            const dd = today.getDate(),
                mm = today.getMonth() + 1,
                yyyy = today.getFullYear();

            return this.formatDate(mm, dd, yyyy, format);
        }
    }

    /**
     * get past date with the required date format, use month as the delta
     * current supported format: 'mm-dd-yyyy' and 'mm/dd/yyyy'
     * @param deltaMonths: how many months in the past
     * @param format: date format option
     * @returns string: formatted past date as a string
     */
    getPastDateByDeltaMonths(deltaMonths, format) {
        let today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        today.setMonth(today.getMonth() - deltaMonths);
        if (format == null) {
            return today;
        } else {
            const dd = today.getDate(),
                mm = today.getMonth() + 1,
                yyyy = today.getFullYear();

            return this.formatDate(mm, dd, yyyy, format);
        }
    }

    /**
     * get past date with the required date format, use years as the delta
     * current supported format: 'mm-dd-yyyy' and 'mm/dd/yyyy'
     * @param deltaYears: how many years in the past
     * @param format: date format option
     * @returns string: formatted past date as a string
     */
    getPastDateByDeltaYears(deltaYears, format) {
        let today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        today.setFullYear(today.getFullYear() - deltaYears);
        if (format == null) {
            return today;
        } else {
            const dd = today.getDate(),
                mm = today.getMonth() + 1,
                yyyy = today.getFullYear();

            return this.formatDate(mm, dd, yyyy, format);
        }
    }

    /**
     * get short date with correct format by a full date object
     * @param dateVal: full date object including date and time
     * @returns Date: short date not including time
     */
    getShortDateByDateObject(dateVal: Date) {
        const year = dateVal.getFullYear();
        const month = dateVal.getMonth() + 1;
        const day = dateVal.getDate();
        const date = new Date(this.formatDate(month, day, year));
        return date;
    }

    /**
     * get date with correct format by a date object
     * current supported format: 'mm-dd-yyyy' and 'mm/dd/yyyy'
     * @param dateObj: date object
     * @param format: format option, as a string
     * @returns string: formatted special date as a string
     */
    getDateStringByDateObject(dateObj, format) {
        if (dateObj == null || dateObj.length === 0) {
            return '';
        }

        const dd = dateObj.getDate(),
            mm = dateObj.getMonth() + 1,
            yyyy = dateObj.getFullYear();

        return this.formatDate(mm, dd, yyyy, format);
    }

    /**
     * determin if certain date is a future date or not
     * @param strDate: date str you want to judge
     * @returns boolean: true for future date, other wise, false
     */
    isFutureDate(strDate) {
        const date = new Date(strDate);
        const yy = date.getFullYear();
        const mm = date.getMonth() + 1;
        const dd = date.getDate();

        const currentDate = new Date();
        const currentYY = currentDate.getFullYear();
        const currentMM = currentDate.getMonth() + 1;
        const currentDD = currentDate.getDate();

        const d1 = new Date(this.formatDate(mm, dd, yy));
        const d2 = new Date(this.formatDate(currentMM, currentDD, currentYY));

        return Date.parse(d1.toDateString()) >= Date.parse(d2.toDateString());
    }

    /**
     * determin if certain date is a future date or not
     * param strDate
     */
    isFutureDateByTimeString(strDate) {
        return new Date().getTime().toString() < strDate;
    }

    /**
     * @returns number: timezone offset in minutes
     */
    getTimezoneOffsetInMinutes() {
        return -(new Date().getTimezoneOffset());
    }

    /**
    * get contry timezone id for daylight saving handle
    * returns {String}: timezone id like 'Asia/Shanghai'
    */
    getContryTimezoneId() {
        return new Intl.DateTimeFormat().resolvedOptions().timeZone;
    }


    /**
     * get formatted string of date object. This method coming from internet.
     * @param Date dateVal The date object
     * @param String format The format string
     */
    getDateString(dateVal: Date, format: string): string {
        const d = dateVal;
        const zeroize = this.zeroize;

        return format.replace(/"[^"]*"|'[^']*'|\b(?:d{1,4}|M{1,4}|yy(?:yy)?|([hHmstT])\1?|[lLZ])\b/g, function ($1: any) {
            switch ($1) {
                case 'd': return d.getDate();
                case 'dd': return zeroize(d.getDate());
                case 'ddd': return ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'][d.getDay()];
                case 'dddd': return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][d.getDay()];
                case 'M': return d.getMonth() + 1;
                case 'MM': return zeroize(d.getMonth() + 1);
                case 'MMM': return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()];
                case 'MMMM': return ['January', 'February', 'March', 'April', 'May', 'June',
                    'July', 'August', 'September', 'October', 'November', 'December'][d.getMonth()];
                case 'yy': return String(d.getFullYear()).substr(2);
                case 'yyyy': return d.getFullYear();
                case 'h': return d.getHours() % 12 || 12;
                case 'hh': return zeroize(d.getHours() % 12 || 12);
                case 'H': return d.getHours();
                case 'HH': return zeroize(d.getHours());
                case 'm': return d.getMinutes();
                case 'mm': return zeroize(d.getMinutes());
                case 's': return d.getSeconds();
                case 'ss': return zeroize(d.getSeconds());
                case 'l': return zeroize(d.getMilliseconds(), 3);
                case 'L': let m = d.getMilliseconds();
                    if (m > 99) { m = Math.round(m / 10); }
                    return zeroize(m);
                case 'tt': return d.getHours() < 12 ? 'am' : 'pm';
                case 'TT': return d.getHours() < 12 ? 'AM' : 'PM';
                case 'Z': return d.toUTCString().match(/[A-Z]+$/);
                // Return quoted strings with the surrounding quotes removed
                default: return $1.substr(1, $1.length - 2);
            }
        });
    }

    /**
     * public function      : get weekday by date
     * @param date          : input date
     */
    getWeekdayByDate(date: any): any {
        if (date !== undefined) {
            const weekArray = new Array('Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat');
            const myDate = new Date(date);
            const week = weekArray[myDate.getDay()];
            return week;
        }
    }

    zeroize(val: number, length: number = 2): string {
        const value = String(val);
        let zeros = '';
        for (let i = 0; i < (length - value.length); i++) {
            zeros += '0';
        }
        return zeros + value;
    }

    compareDate(dateValue: Date, compareDateValue: Date) {
        if (!dateValue || !compareDateValue) {
            return false;
        } else {
            const isSame = dateValue.getDate() === compareDateValue.getDate() && dateValue.getMonth() === compareDateValue.getMonth()
                && dateValue.getFullYear() === compareDateValue.getFullYear();
            return isSame;
        }
    }

    getTimeList(type: string) {
        const times: Array<string> = [];
        for (let i = 0; i < 48; i++) {
            const hour = Math.floor(i / 2);
            if (i % 2 === 1) {
                times.push(hour + ':30');
            } else {
                times.push(hour + ':00');
            }
        }
    }

    getTimeStamp(date: Date, time: any) {
        time = time.split(':');
        date.setHours(parseInt(time[0], 0));
        date.setMinutes(parseInt(time[1], 0));
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date.valueOf();
    }

    judgeTimeFormat(time: string) {
        const a: any = time.match(/^(\d{1,2})(:)?(\d{1,2})$/);
        if (a == null) {
            return false;
        }
        if (a[1] > 24 || a[3] > 60) {
            return false;
        }
        return true;
    }
}
