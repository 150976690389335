/**
 * Created by Simon Zhao on 7/27/2021.
 * Description:
 * a group box widget.
 * ------ maintenance history ------
 *
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'tam-group-box',
    templateUrl: './group-box.component.html',
    styleUrls: ['./group-box.component.scss']
})
export class GroupBoxComponent implements OnInit {

    @Input() groupTitle;

    @Output() deletionBtnClicked = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
    }

    onDeletionClicked(e: Event) {
        this.deletionBtnClicked.emit();
    }

}
