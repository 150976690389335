/**
 * Created by Alan Yang on 7/17/17.
 * Description:
 *
 * ------ maintenance history ------
 *
 * ver 1.1 modifyed by Yu Zhang on 8/18/17:
 *      - added entity functions for entry template usage:
 *          - getEntityListBySearchText()
 *          - getEntityListByIds()
 *          - getEntityListByIdsWithSource()
 *          - getEntityListByRelationships()
 * ver 2.0 modified by Yu Zhang on 2/13/18:
 *      - use HTTPClient in Angular 5
 * ver 3.0 modified editEntity(), merge extraofficer to editentity request
 * ver 3.1 add editEntityPatch by Marcus Zhao on 8/28/2021
 * Updated by Daniel Wang on 6/18/2024, Add function named getEntityInTopicMemberByEntityId(entityId) to show private entities which are in topci memeber list.
 */

import { concat as observableConcat, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { Entity } from '../models/entity.model';
import { EntityList } from '../models/entity-list.model';
import { AdvFilterHelperService } from './adv-filter-helper.service';
import { EntityBrief } from '../models/entity-brief.model';
import { ALL_VALUE, businessConstants } from '../constants/business.constants';
import { map } from 'rxjs/operators';
import { ImpactedEmailDigestConfig } from '../models/impacted-email-digest.model';
import { ImpactedDashboardConfig } from '../models/impacted-dashboard.model';

@Injectable()
export class EntityService {
    constructor(
        private _transportService: TransportService,
        private _advFilterHelper: AdvFilterHelperService,
    ) { }

    public createEntity(entity): Observable<any> {
        // tslint:disable-next-line: prefer-const
        let data;
        data = this._mapEntity(entity, true);
        const url = `${AppConfig.entityOldEndpoint}`;
        const formData = new FormData();
        // tslint:disable-next-line: forin
        for (const param in data) {
            formData.append(param, data[param]);
        }
        return this._transportService.post(url, formData, {});
    }

    public editEntity(entity, isTopicMember?): Observable<any> {
        let formData;
        formData = this._mapEntityToOfficer(entity);
        let params;
        params = this._mapEntity(entity, false);
        // tslint:disable-next-line: forin
        for (const param in params) {
            formData.append(param, params[param]);
        }
        if (isTopicMember) {
            if (entity.type.name === 'Team') {
                formData.append('teammember', entity.topicMembers);
            } else {
                formData.append('topicmember', entity.topicMembers);
            }
        }
        const url = `${AppConfig.entityEndpoint}` + entity.id;
        return this._transportService.put(url, formData, {});
    }

    /**
     * edit entity by patch ,currently use this request for update entity webAddress
     *
     * @param {*} data
     * @returns {Observable<any>}
     * @memberof EntityService
     */
    public editEntityPatch(entity): Observable<any> {
        const formData = new FormData();
        formData.append('webAddress', JSON.stringify(entity.webAddress));
        const url = `${AppConfig.entityOldEndpoint}` + entity.id;
        return this._transportService.patch(url, formData, {});
    }

    public createTextraOrOfficer(entity: Entity): Observable<any> {
        let formData;
        formData = this._mapEntityToOfficer(entity);

        const url = `${AppConfig.entityEndpoint}` + entity.id + '/extraOrOfficer';
        return this._transportService.put(url, formData, {});

    }

    public createTopicmember(entity: Entity): Observable<any> {
        let url;
        const formData = new FormData();
        if (entity.type.name === 'Team') {
            url = `${AppConfig.teamPoint}` + entity.id + '/teammember';
            formData.append('teammember', entity.topicMembers);
        } else {
            url = `${AppConfig.entityEndpoint}` + entity.id + '/topicmember';
            formData.append('topicmember', entity.topicMembers);
        }
        const options = {
            headers: {},
        };
        return this._transportService.put(url, formData, options);
    }

    /**
     * delete entity by id
     * @param id entity id
     * @returns
     */
    deleteEntityById(id): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.entityEndpoint}${id}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.delete(url, options);
    }

    public getImpactedEmailDigestsByEntityId(entityId: string) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.emailDigestEndpoint}entity/` + entityId;
        const params = {
            outputformat: 'json',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options).pipe(
            map(response => {
                return this._mapImpactedConfigList(response);
            })
        );
    }

    public getImpactedDashboardsByEntityId(entityId: string) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.screenEndpoint}dashboard/entity/` + entityId;
        const params = {
            outputformat: 'json',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options).pipe(
            map(response => {
                return this._mapImpactedDashboardsList(response);
            })
        );
    }

    /**
     * format address from server
     *
     * @param {string} address
     * @returns {Observable<any>}
     * @memberof EntityService
     */
    public getAddress(address: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json;charset=utf-8'
        };
        const url = `${AppConfig.entityOldEndpoint}/address`;
        const params = {
            data: address,
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getAllEnities() {
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityOldEndpoint}`;
        const params = {
            fields: 'id;long-name',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getEntityList(page: number = 0, pageSize: number = 0, keyword: string = ''): Observable<any> {
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityListEndpoint}`;
        const params = {
            advfilter: '(! entity-type name equals "Contact")',
            expand: 'entities;entity;aliases;alias;',
            filterby: 'long-name,short-name,entity-type,alias',
            page: page,
            rpp: pageSize,
            sortby: 'short-name',
            sortorder: 'asc'
        };
        if (keyword && keyword.length > 0) {
            params['filterstring'] = keyword;
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     * Get private entity, this entity will in topic member list
     * @param entityId
     * @returns
     */
    getEntityInTopicMemberByEntityId(entityId: string) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entityEndpoint}topicmember/` + entityId;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options).pipe(
            map(response => {
                return this.mapEntityBriefList(response);
            })
        );
    }

    /**
     *
     * @param page : page number
     * @param pageSize : result per page
     * @param filterBy : filter data field
     * @param keyword : search keyword
     * @param source : search against this source, could contain a list of entity type ids, or relationships
     * @param relationshipBase: if relationship searching is valid, this is the base entity id or an array of base entities
     */
    public getEntityListBySearchText(page: number = 0, pageSize: number = 0, filterBy: string = '',
        keyword: string = '', source: any = {}, relationshipBase: any = '', searchByPrefix: boolean = true) {
        const headers = {
            'Content-Type': 'application/json;charset=utf-8'
        };
        const url = `${AppConfig.entityEndpoint}`;
        let advFilter = '';
        const params = {
            expand: 'contact-details',
            fields: 'id;short-name;long-name;entity-type;contact-details;is-public',
            page: page,
            rpp: pageSize,
            outputformat: 'json'
        };
        if (keyword && keyword.length > 0) {
            params['searchstring'] = keyword;
        } else {
            params['sortby'] = 'short-name';
            params['sortorder'] = 'asc';
        }

        if (source.entityType && source.entityType.length > 0) {
            let tmpEntityTypeArr = [];
            if (source.entityType instanceof Array) {
                tmpEntityTypeArr = source.entityType;
            } else {
                tmpEntityTypeArr.push(source.entityType);
            }
            advFilter = this._advFilterHelper.entityTypeFilterSet('or', tmpEntityTypeArr);
        }
        if (advFilter.length > 0) {
            params['advfilter'] = advFilter;
        }
        if (source.relationshipFilter && relationshipBase.length > 0) {
            params['relationshiptype'] = source.relationshipFilter.relationshipTypeID;
            if (relationshipBase instanceof Array) {
                params['parententity'] = relationshipBase.join(',');
            } else {
                params['parententity'] = relationshipBase;
            }
        }
        const options = {
            headers: headers,
            params: params
        };

        const formData = '';

        return this._transportService.post(url, formData, options);
    }

    /**
     * Originated from issue: https://jiraprod.advent.com/browse/TAM-35729
     * For focus dropdown list, we need to query once and render with virtualization. But don't want to take the risk casued by the amount of user data.
     * So TPM determined just fetch the top 200.
     * @param keyword : search keyword
     * @param source : search against this source, could contain a list of entity type ids, or relationships
     * @param relationshipBase: if relationship searching is valid, this is the base entity id or an array of base entities
     */
    public getEntityListBySearchTextShortcut(keyword: string = '',
        source: any = {}, relationshipBase: any = '') {
        return this.getEntityListBySearchTextQuick(1, 200, keyword, source, relationshipBase);
    }

    /**
     *
     * @param page : page number
     * @param pageSize : result per page
     * @param keyword : search keyword
     * @param source : search against this source, could contain a list of entity type ids, or relationships
     * @param relationshipBase: if relationship searching is valid, this is the base entity id or an array of base entities
     */

    public getEntityListBySearchTextQuick(page: number = 0, pageSize: number = 0, keyword: string = '',
        source: any = {}, relationshipBase: any = '') {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityEndpoint}`;
        let advFilter = '';
        const params = {
            expand: 'contact-details',
            fields: 'id;short-name;long-name;entity-type;contact-details;is-public',
            outputformat: 'json'
        };
        const pageParams = {
            page: page,
            rpp: pageSize
        };
        if (keyword && keyword.length > 0) {
            params['searchstring'] = keyword;
        } else {
            params['sortby'] = 'short-name';
            params['sortorder'] = 'asc';
        }

        if (source.entityType && source.entityType.length > 0) {
            let tmpEntityTypeArr = [];
            if (source.entityType instanceof Array) {
                tmpEntityTypeArr = source.entityType;
            } else {
                tmpEntityTypeArr.push(source.entityType);
            }
            advFilter = this._advFilterHelper.entityTypeFilterSetQuick(tmpEntityTypeArr);
            if (advFilter.length > 0) {
                params['advfilter'] = advFilter;
            }
        }
        if (source.relationshipFilter && relationshipBase.length > 0) {
            params['relationshiptype'] = source.relationshipFilter.relationshipTypeID;
            if (relationshipBase instanceof Array) {
                params['parententity'] = relationshipBase.join(',');
            } else {
                params['parententity'] = relationshipBase;
            }
        }
        const options = {
            headers: headers,
            params: pageParams
        };
        if (pageParams.page < 0 && pageParams.rpp < 0) {
            // query without pagination.
            delete options.params;
        }

        const paramsStr = this._transportService.getUrlEncodedForm(params);
        return this._transportService.post(url, paramsStr, options);
    }

    public getEntityListByIds(entityIds: Array<string>, source?: any, relationshipBase?: any) {
        const headers = {
            'Content-Type': 'application/json'
        };
        let advFilter = this._advFilterHelper.entityFilterSetByOr(entityIds);
        let relationshipType, parentEntity;
        if (source) {
            if (source.entityType && source.entityType.length > 0) {
                let tmpEntityTypeArr = [];
                if (source.entityType instanceof Array) {
                    tmpEntityTypeArr = source.entityType;
                } else {
                    tmpEntityTypeArr.push(source.entityType);
                }
                const entityTypeFilter = this._advFilterHelper.entityTypeFilterSet('or', tmpEntityTypeArr);
                const advFilterArray = [];
                advFilterArray.push(advFilter);
                advFilterArray.push(entityTypeFilter);
                advFilter = this._advFilterHelper.joinFiltersByAnd(advFilterArray);
            }
            if (source.relationshipFilter && relationshipBase && relationshipBase.length > 0) {
                relationshipType = source.relationshipFilter.relationshipTypeID;
                if (relationshipBase instanceof Array) {
                    parentEntity = relationshipBase.join(',');
                } else {
                    parentEntity = relationshipBase;
                }
            }
        }
        const url = `${AppConfig.entityListEndpoint}`;
        const params = {
            advfilter: advFilter,
            expand: 'entity-type&fields=id;short-name;long-name;entity-type;',
            sortby: 'short-name',
            sortorder: 'asc',
            outputformat: 'json'
        };
        if (relationshipType && relationshipType.length > 0) {
            params['relationshiptype'] = relationshipType;
        }
        if (parentEntity && parentEntity.length > 0) {
            params['parententity'] = parentEntity;
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getEntityListByIdsQuick(entityIds: Array<string>, source?: any, relationshipBase?: any) {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        };
        let advFilter;
        let relationshipType, parentEntity;
        if (source) {
            if (source.entityType && source.entityType.length > 0) {
                let tmpEntityTypeArr = [];
                if (source.entityType instanceof Array) {
                    tmpEntityTypeArr = source.entityType;
                } else {
                    tmpEntityTypeArr.push(source.entityType);
                }
                const entityTypeFilter = this._advFilterHelper.entityTypeFilterSetQuick(tmpEntityTypeArr);
                const advFilterArray = [];
                advFilterArray.push(entityTypeFilter);
                advFilter = this._advFilterHelper.joinFiltersByAnd(advFilterArray);
            }
            if (source.relationshipFilter && relationshipBase && relationshipBase.length > 0) {
                relationshipType = source.relationshipFilter.relationshipTypeID;
                if (relationshipBase instanceof Array) {
                    parentEntity = relationshipBase.join(',');
                } else {
                    parentEntity = relationshipBase;
                }
            }
        }
        const url = `${AppConfig.entityEndpoint}`;
        const params = {
            // advfilter: advFilter,
            expand: 'contact-details',
            fields: 'id;short-name;long-name;entity-type;contact-details;is-public;corporate-details',
            outputformat: 'json'
        };
        const formData = new URLSearchParams();
        if (relationshipType && relationshipType.length > 0) {
            // params['relationshiptype'] = relationshipType;
            formData.set('relationshiptype', relationshipType);
        }
        if (parentEntity && parentEntity.length > 0) {
            // params['parententity'] = parentEntity;
            formData.set('parententity', parentEntity);
        }
        if (advFilter && advFilter.length > 0) {
            formData.set('advfilter', advFilter);
        }

        const _entityIds = entityIds.map((item) => item).join(',');
        formData.set('entityids', _entityIds);
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    public getEntityDetail(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityEndpoint}${id}`;
        const params = {
            // tslint:disable-next-line: max-line-length
            expand: 'topic-members;member-list;entity-type;entity;aliases;alias;provider-mappings;officers;officer;weblinks;weblink;relationship;child-entity;properties;property;propdef;',
            showsimple: true,
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    // [Web Only] to prevent get adhoc information when request entity detail
    public getEntityDetailWithoutAdhoc(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityEndpoint}${id}`;
        const params = {
            // tslint:disable-next-line: max-line-length
            expand: 'topic-members;member-list;entity-type;entity;aliases;alias;provider-mappings;officers;officer;weblinks;weblink;relationship;child-entity;properties;property;propdef;',
            showproperty: false,
            showsimple: true,
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getEntityTemplateConfig(entityTypeId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.configurationDataEndpoint}${entityTypeId}`;
        return this._transportService.get(url, { headers: headers });
    }

    public getFirstValidEntity(entityIdArray, source) {
        const observables$ = [];
        for (const entityId of entityIdArray) {
            observables$.push(this.getEntityListByIdsWithSource([entityId], source));
        }
        return observableConcat(...observables$);
    }

    public getEntityListByIdsWithSource(entityIdArray, source, ctrlVal: any = '', shortName: any = '') {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityListEndpoint}`;
        const params = {
            page: 1,
            rpp: 50,
            expand: 'entity-type',
            fields: 'id;short-name;long-name;entity-type;',
            sortby: 'short-name',
            sortorder: 'asc',
        };
        if (source) {
            if (source.entityType && source.entityType.length > 0) {
                let tmpEntityTypeArr = [];
                if (source.entityType instanceof Array) {
                    tmpEntityTypeArr = source.entityType;
                } else {
                    tmpEntityTypeArr.push(source.entityType);
                }
                const advFilterArray = [];
                for (let i = 0; i < tmpEntityTypeArr.length; i++) {
                    advFilterArray.push('(entity-type id equals "' + tmpEntityTypeArr[i] + '")');
                }
                params['advfilter'] = this._advFilterHelper.joinFiltersByOr(advFilterArray);
            }
            if (source.relationshipFilter && ctrlVal !== null && ctrlVal !== undefined && ctrlVal.length > 0) {
                params['relationshiptype'] = source.relationshipFilter.relationshipTypeID;
                if (ctrlVal instanceof Array) {
                    params['parententity'] = ctrlVal.join(',');
                } else {
                    params['parententity'] = ctrlVal;
                }
            }
        }
        if (entityIdArray && entityIdArray.length > 0) {
            const entityFilter = this._advFilterHelper.entityFilterSetByOr(entityIdArray);
            if (params['advfilter']) {
                params['advfilter'] = '(' + params['advfilter'] + ' and ' + entityFilter + ')';
            } else {
                params['advfilter'] = entityFilter;
            }
        }
        if (shortName && shortName.length > 0) {
            params['filterby'] = 'short-name';
            params['filterstring'] = shortName;
            params['filtertype'] = 'prefix';
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getEntityListByIdsWithSourceQuick(entityIdArray, source, ctrlVal: any = '', shortName: any = '') {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityListEndpoint}`;
        const params = {
            page: 1,
            rpp: 50,
            expand: 'entity-type',
            fields: 'id;short-name;long-name;entity-type;is-public',
            sortby: 'short-name',
            sortorder: 'asc',
        };
        if (source) {
            if (source.entityType && source.entityType.length > 0) {
                let tmpEntityTypeArr = [];
                if (source.entityType instanceof Array) {
                    tmpEntityTypeArr = source.entityType;
                } else {
                    tmpEntityTypeArr.push(source.entityType);
                }
                params['advfilter'] = this._advFilterHelper.entityTypeFilterSetQuick(tmpEntityTypeArr);
            }
            if (source.relationshipFilter && ctrlVal !== null && ctrlVal !== undefined && ctrlVal.length > 0) {
                params['relationshiptype'] = source.relationshipFilter.relationshipTypeID;
                if (ctrlVal instanceof Array) {
                    params['parententity'] = ctrlVal.join(',');
                } else {
                    params['parententity'] = ctrlVal;
                }
            }
        }
        if (entityIdArray && entityIdArray.length > 0) {
            const entityFilter = this._advFilterHelper.entityIdsFilter(entityIdArray);
            if (params['advfilter']) {
                params['advfilter'] = '(' + params['advfilter'] + ' and ' + entityFilter + ')';
            } else {
                params['advfilter'] = entityFilter;
            }
        }
        if (shortName && shortName.length > 0) {
            params['filterby'] = 'short-name';
            params['filterstring'] = shortName;
            params['filtertype'] = 'prefix';
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getEntityListByRelationships(entityId: any, relationshipTypeId: string = '', isTeam = false): Observable<any> {
        if (entityId == null || entityId.length <= 0) {
            return;
        }
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        let entityIdArr = [];
        if (entityId instanceof Array) {
            entityIdArr = entityId;
        } else {
            entityIdArr.push(entityId);
        }
        const url = `${AppConfig.relationshipEndpoint}`;
        const advFilterArray = [];
        for (let m = 0; m < entityIdArr.length; m++) {
            if (entityIdArr[m].trim().length > 0) {
                const combineAdvFilter = [];
                combineAdvFilter.push(this._advFilterHelper.relationshipTypeFilter(relationshipTypeId));
                if (isTeam) {
                    combineAdvFilter.push(this._advFilterHelper.childEntityFilter(entityIdArr[m]));
                } else {
                    combineAdvFilter.push(this._advFilterHelper.parentEntityFilter(entityIdArr[m]));
                }
                advFilterArray.push(this._advFilterHelper.joinFiltersByAnd(combineAdvFilter));
            }
        }
        const advFilter = this._advFilterHelper.joinFiltersByOr(advFilterArray);
        const params = {
            expand: 'child-entity;entity',
            fields: 'child-entity',
            outputformat: 'json'
        };
        const teamParams = {
            expand: 'parent-entity;entity',
            fields: 'parent-entity',
            outputformat: 'json'
        };
        if (advFilter && advFilter.length > 0) {
            params['advfilter'] = advFilter;
            teamParams['advfilter'] = advFilter;
        }
        const options = {
            headers: headers,
            params: isTeam ? teamParams : params
        };
        return this._transportService.get(url, options);
    }

    /**
     * get the list of visible adhoc fields
     * param  {Object} entity               [the object of entity]
     * param  {Object} entityTemplateConfig [the object of entity template configuration data]
     * return {Array}                       [the array of visible adhoc fields which contains id, label, value]
     */
    public getCustomMetadata(entity: Entity, entityTemplateConfig) {
        let metadata = [];

        if (!entityTemplateConfig) {
            return [];
        }

        entityTemplateConfig.contains.forEach(row => {
            row.forEach(ctrl => {
                if (ctrl.componentType === 'group' || ctrl.componentType === 'virtualgroup') {
                    const subMetadata = this.getCustomMetadata(entity, ctrl);
                    metadata = metadata.concat(subMetadata);
                } else if (ctrl.componentType === 'name-input' || ctrl.componentType === 'shortname-input') {
                    // ignore the Note Body Control of Entity Template; Should not show it in Entity Detail
                    // do nothing
                } else if (ctrl.adhocDefID && !ctrl.isHidden) {
                    const tmpAdhoc = {
                        label: ctrl.label,
                        id: ctrl.adhocDefID,
                        value: null,
                        componentType: ctrl.componentType
                    };
                    if (entity.adhocs && entity.adhocs.size > 0) {
                        entity.adhocs.forEach(adhoc => {
                            if (adhoc.id.toString() === ctrl.adhocDefID) {
                                if (ctrl.componentType === 'date-input') {
                                    if (adhoc.value) {
                                        // parse ms number to date object
                                        const dateValueInt = +adhoc.value;
                                        if (!isNaN(dateValueInt)) {
                                            tmpAdhoc.value = new Date(dateValueInt);
                                        } else {
                                            tmpAdhoc.value = new Date(adhoc.value);
                                        }
                                    }
                                } else if (ctrl.componentType === 'met-input' || ctrl.componentType === 'med-input') {
                                    const valueArr = JSON.parse(adhoc.value);
                                    const valueNames = [];
                                    valueArr.forEach(item => {
                                        valueNames.push(item.shortname);

                                    });
                                    tmpAdhoc.value = valueNames.join(', ');
                                } else if (ctrl.componentType === 'ed-input') {
                                    const obj = JSON.parse(adhoc.value);
                                    tmpAdhoc.value = obj.shortname;
                                } else {
                                    tmpAdhoc.value = adhoc.value;
                                }
                            }
                        });
                    } else {
                        tmpAdhoc.value = null;
                    }
                    metadata.push(tmpAdhoc);
                }
            });
        });
        return metadata;
    }

    public getEntityByShortName(shortName: string, fields: string = '') {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityGetByShortnameEndpoint}`;
        const params: any = {
            advfilter: '(short-name equals "' + shortName + '")'
        };
        if (fields && fields.length > 0) {
            params['fields'] = fields;
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getSpecificRelationships(entityId: string, relationshipTypeIds: Array<string> | string) {
        if (!relationshipTypeIds || relationshipTypeIds.length === 0) {
            return of([]);
        }
        const relationshipTypeSet = new Set();
        /** relationshipTypeIds typeof array or string('all') */
        if (Array.isArray(relationshipTypeIds)) {
            relationshipTypeIds.forEach(element => relationshipTypeSet.add(element));
        }
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityEndpoint}`;
        const params = {
            outputformat: 'json',
            fields: 'id',
            parententity: entityId,
            relationshiptype: relationshipTypeIds === ALL_VALUE ? ALL_VALUE : Array.from(relationshipTypeSet).join(','),
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.post(url, null, options);
    }

    public searchEntityList(page: number = 0, pageSize: number = 0, keyword: string = '') {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityEndpoint}`;
        const params = {
            filterstring: keyword,
            advfilter: '(! entity-type id equals "' + businessConstants.EntityTypes.Contact_Id + '")',
            expand: 'entities;entity;aliases;alias',
            filterby: 'long-name,short-name,entity-type,alias',
            sortby: 'short-name',
            sortorder: 'asc',
            outputformat: 'json'
        };
        const pageParams = {
            page: page,
            rpp: pageSize
        };
        const options = {
            headers: headers,
            params: pageParams
        };
        const paramsStr = this._transportService.getUrlEncodedForm(params);
        return this._transportService.post(url, paramsStr, options);
    }

    // public mapEntity(data, processTreeNode: boolean = true): Entity {
    //     let entity = Entity.parse(data);

    //     entity.id = new TSGuid(data.id);
    //     parse string to bool, equals (is-pubilc=='ture'? true:false), if is-pubic,then hide the lock icon on UI
    //     entity.public = data['is-public'] ? data['is-public'] == 'true' : true;
    //     entity.longName = data['long-name'];
    //     entity.shortName = data['short-name'];
    //     if (data['entity-type'] && data['entity-type'].link) {
    //         entity.entityType = EntityType.parse(data['entity-type']);
    //     }
    //     if (data['entity-type'].data) {
    //         entity.entityTypeID = new TSGuid(data['entity-type'].data.id);
    //     } else if (data['entity-type'].link && data['entity-type'].link.href) {
    //         let dataLink = data['entity-type'].link.href;
    //         let matchingString = '/entity-type/';
    //         let entityTypeIdIndex = dataLink.indexOf(matchingString) + matchingString.length;
    //         entity.entityTypeID = new TSGuid(dataLink.substring(entityTypeIdIndex, dataLink.length - 1));
    //     }


    //     //only corporate type has corporate-details property.
    //     if (data.hasOwnProperty('corporate-details')) {
    //         //if no corp node responese, entity.entityDetails is null
    //         entity.entityDetails = new EntityDetails();

    //         if (data['corporate-details'].hasOwnProperty('address') && data['corporate-details']['address'].length > 0) {
    //             entity.entityDetails.address = data['corporate-details']['address'].split('/r/n');
    //         }
    //         if (data['corporate-details'].hasOwnProperty('phone')) {
    //             entity.entityDetails.phone = data['corporate-details']['phone'];
    //         }
    //         if (data['corporate-details'].hasOwnProperty('fax')) {
    //             entity.entityDetails.fax = data['corporate-details']['fax'];
    //         }
    //         if (data['corporate-details'].hasOwnProperty('email-address')) {
    //             entity.entityDetails.email = data['corporate-details']['email-address'];
    //         }
    //         if (data['corporate-details'].hasOwnProperty('employee-count')) {
    //             entity.entityDetails.numberOfEmployees = data['corporate-details']['employee-count'];
    //         }
    //         var officersArray = [];
    //         if (data['corporate-details'].hasOwnProperty('officers') && data['corporate-details']['officers'].data) {
    //             for (var j = 0; j < data['corporate-details']['officers'].data.length; j++) {
    //                 let officer = data['corporate-details']['officers'].data[j]['data'];
    //                 let officerCorp = new CorpOfficer();
    //                 officerCorp.age = officer['age'];
    //                 officerCorp.name = officer['name'];
    //                 officerCorp.position = officer['position'];
    //                 officersArray.push(officerCorp);
    //             }
    //         }
    //         entity.entityDetails.officers = officersArray;
    //     }

    //     //for privacy - not team   -> for rest api bug, not retrieve from topic-member but from rel-tree, should fix rest api
    //     if (!entity.public && entity.entityType.name != 'Team') {
    //         var topicMembers = [];
    //         if (data['relationships'] && data['relationships']['data']) {
    //             for (var tIndex = 0; tIndex < data['relationships']['data'].length; ++tIndex) {
    //                 if (data['relationships']['data'][tIndex]['link']['phid'] == 'Topic Member') {
    //                     topicMembers.push(data['relationships']['data'][tIndex]['data']['child-entity']['link']['phid']);
    //                 }
    //             }
    //         }
    //         entity.topicMembers = topicMembers;
    //     }

    //     //for team members.  it won't be showed up with privacy together.
    //     if (entity.entityType.name == 'Team') {
    //         var memberList = [];
    //         if (data['member-list'] && data['member-list']['data']) {
    //             for (var mIndex = 0; mIndex < data['member-list']['data'].length; ++mIndex) {
    //                 memberList.push(data['member-list']['data'][mIndex]['link']['phid']);
    //             }
    //         }
    //         entity.teamMembers = memberList;
    //     }

    //     var weblinksArray = [];
    //     if (data.hasOwnProperty('weblinks') && data['weblinks'].data) {
    //         for (var k = 0; k < data['weblinks'].data.length; k++) {
    //             var linkObj = data['weblinks'].data[k]['data'];
    //             let corpLink = new CorpLink();
    //             corpLink.pname = linkObj['title'];
    //             corpLink.pvalue = linkObj['url'];
    //             weblinksArray.push(corpLink);
    //         }
    //         entity.webLinks = weblinksArray;
    //     }

    //     var aliasArray = [];
    //     if (data.hasOwnProperty('aliases') && data['aliases'].data) {
    //         for (var i = 0; i < data['aliases'].data.length; i++) {
    //             if (data['aliases'].data[i]['data']) {
    //                 let alias = new Alias();
    //                 alias.aliasName = data['aliases'].data[i]['data']['name'];
    //                 alias.id = data['aliases'].data[i]['data']['id'];
    //                 aliasArray.push(alias);
    //             }

    //         }
    //     }
    //     entity.aliases = aliasArray;

    //     //parsing adhoc value
    //     let adhocsArray = [];
    //     if (data.properties && data.properties.data) {
    //         for (var i = 0; i < data.properties.data.length; i++) {
    //             let adhoc: TSAttribute = new TSAttribute();
    //             adhoc.id = adhoc.definitionID = data.properties.data[i]['data']['id'];
    //             adhoc.value = data.properties.data[i]['data']['value'];

    //             let tsd: TSAttributeDefinition = new TSAttributeDefinition();
    //             tsd.id = data.properties.data[i]['data']['propdef']['data']['id'];
    //             tsd.name = data.properties.data[i]['data']['propdef']['data']['name'];
    //             tsd.title = data.properties.data[i]['data']['propdef']['data']['title'];
    //             adhoc.definition = tsd;

    //             adhocsArray.push(adhoc);
    //         }
    //     }
    //     entity.adhocs = adhocsArray;

    //     // TODO: @Alan, move this out a separate step
    //     if (data['entity-type'].data && processTreeNode == true) {
    //         entity.relationshipNode = JstreeERService.parseJSTree(data);
    //     }

    //     return entity;
    // }

    public mapEntityList(response): EntityList {
        const result = new EntityList();
        if (!response || !response['entity-list']) {
            return result;
        }
        try {
            response['entity-list'].forEach(element => {
                // let e = this.mapEntity(element, false);
                const e = Entity.parse(element);
                result.entities.push(e);
            });
            if (response['next'] && response['next']['href']) {
                result.next = response['next']['href'];
            }
            return result;
        } catch (e) {
            console.error(e);
            return result;
        }
    }

    /**
     * use the EntityBrief model
     * @param response: response
     */
    public mapEntityBriefList(response): Array<EntityBrief> {
        const result = new Array<EntityBrief>();
        if (!response || !response['entity-list']) {
            return result;
        }
        try {
            response['entity-list'].forEach(element => {
                const e = EntityBrief.parse(element);
                result.push(e);
            });
            return result;
        } catch (e) {
            console.error(e);
            return result;
        }
    }

    public mapTeamBriefList(response): Array<EntityBrief> {
        const result = new Array<EntityBrief>();
        if (!response || !response['team-list']) {
            return result;
        }
        try {
            response['team-list'].forEach(element => {
                const e = EntityBrief.parse(element);
                result.push(e);
            });
            return result;
        } catch (e) {
            return result;
        }
    }

    private _mapEntity(params: Entity, isCreateEntity: Boolean) {
        const entity = {};
        if (isCreateEntity || params.isAdminEditEntityType) {
            entity['entity-type'] = params.type.name || '';
        }
        entity['long-name'] = params.name.replace(/^\s+|\s+$/g, '') || '';
        entity['short-name'] = params.shortName.replace(/^\s+|\s+$/g, '') || '';
        if (params.webAddress) {
            entity['webAddress'] = JSON.stringify(params.webAddress);
        }

        if (params.detail.phone) {
            entity['phone'] = params.detail.phone;
        } else {
            entity['phone'] = '';
        }

        if (params.detail.fax) {
            entity['fax'] = params.detail.fax;
        } else {
            entity['fax'] = '';
        }

        if (params.detail.numberOfEmployees) {
            entity['employees'] = params.detail.numberOfEmployees;
        } else {
            entity['employees'] = '';
        }
        entity['email-address'] = params.detail.email || '';
        entity['is-public'] = params.isPublic || false;

        if (params.detail.latitude && params.detail.longitude) {
            entity['web-address'] = JSON.stringify({
                'zip': params.detail.zip,
                'country': params.detail.country,
                'city': params.detail.city,
                'street': params.detail.street,
                'latitude': params.detail.latitude,
                'state': params.detail.state,
                'street2': params.detail.street2,
                'longitude': params.detail.longitude,
            });
        }

        return entity;
    }

    private _mapEntityToOfficer(params: Entity) {
        const forData = new FormData();
        const alias = {};
        if (params.aliases) {
            alias['value'] = params.aliases.join(',');
        }
        forData.append('aliases', JSON.stringify(alias));
        if (params.webLinks) {
            forData.append('weblinks', JSON.stringify(params.webLinks));
        }
        if (params.type.name === 'Corporate') {
            forData.append('officers', JSON.stringify(params.detail.officers));
        }
        return forData;
    }

    public _mapImpactedConfigList(response): Array<ImpactedEmailDigestConfig> {
        let result = new Array<ImpactedEmailDigestConfig>();
        if (!response || !response['emaildigest-list']) {
            return result;
        }
        try {
            result = ImpactedEmailDigestConfig.parse(response);
            return result;
        } catch (e) {
            return result;
        }
    }

    public _mapImpactedDashboardsList(response): Array<ImpactedDashboardConfig> {
        let result = new Array<ImpactedDashboardConfig>();
        if (!response || !response['screen-list']) {
            return result;
        }
        try {
            result = ImpactedDashboardConfig.parse(response);
            return result;
        } catch (e) {
            return result;
        }
    }
}


