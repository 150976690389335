<div class="tam-upload-container">
    <div #dropzone>
        <div class="row file-content" [style.height.px]="inputConfig.height||96">
            <smart-icon name="upload-file" class="file-upload"></smart-icon>
            <span>
                <span *ngIf="isDraggable">{{'general.drag_and_drop_or' | stringLiterals}}</span>
                <span class="upload-click" #button>
                    <span [ngClass]="{'capitalize': !isDraggable}">{{'general.browse' | stringLiterals}}</span>
                    {{'general.for_files' | stringLiterals}}</span>
            </span>
        </div>
    </div>
    <div class="row div-margin dropzone-previews" *ngIf="inputConfig.files&&inputConfig.files.length">
        <div *ngFor='let file of inputConfig.files; let i = index; trackBy:trackByFn'
            class="dz-preview dz-file-preview padding-template">
            <div class="name-container">
                <span *ngIf="!isEditable" (click)="onShowContextMenu($event,file,i)"
                    class="hover-link">{{file.name}}</span>
                <input *ngIf="isEditable" #fileName class="inline-edit-label label-name-ellipsis dz-filename"
                    [ngClass]="'file-name'+i" [(ngModel)]="file.name" (focusout)="onNameEditingDone(file,i)"
                    (keydown.Enter)="onKeyDown($event)" type="text" [disabled]="!isEditable" />
                <tam-context-menu [config]="contextMenuConfig">
                </tam-context-menu>
            </div>
            <div class="progress-container">
                <div class="progress progress-95">
                    <div class="progress-bar" [ngClass]="{'progress-bar-success':file.isCompleted}"
                        [style.width]="file.progress"></div>
                </div>
                <div class="progress progress-5">
                    <div class="progress-bar progress-bar-success" [style.width]="file.isCompleted?'100%':'0%'">
                    </div>
                </div>
                <button (click)="remove(file,i,$event)" class="upload-delete">
                    <img  class="icon" src="./assets/images/singlesvg/delete.svg"/>
                </button>
            </div>
        </div>
    </div>
    <div #previewsContainer class="preview-template-container"></div>
    <div class="hidden-div" #hiddenDiv></div>
</div>