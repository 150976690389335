/**
 * Created by Abner Sui on 07/03/2019.
 * Description:
 *
 * ------ maintenance history ------
 *  Update by Marcus Zhao  09/22/2022 - fix print error ,add global service and other service which need to provide.
 */
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThreadDetailModule } from '../../components/thread-detail/thread-detail.module';
import { WidgetsModule } from '../../widgets/widgets.module';
import { NoteAttachmentPdfComponent } from '../../components/print/note-attachment-pdf.component';
import { PrintService } from '../../services/print.service';
import { PrintDialogComponent } from '../../components/print/print-dialog.component';
import { PrintPreviewComponent } from './print-preview.component';
import { PrintRoutingModule } from './print-routing.module';
import { ThreadService } from '../../tamalelibs/services/thread.service';
import { ES6MapService } from '../../tamalelibs/services/es6map.service';
import { StoreQuerierService } from '../../services/store-querier.service';
import { NoteService } from '../../services/note.service';
import { WindowRef } from '../../services/window-ref';
import { CopyService } from '../../services/copy.service';
import { PlatformService } from '../../services/platform.service';
import { ThreadDetailService } from '../../services/thread-detail.service';
import { DateHelperService } from '../../tamalelibs/services/date-helper.service';
import { EntryService } from '../../tamalelibs/services/entry.service';
import { EntityService } from '../../tamalelibs/services/entity.service';
import { AuditTrailDialogService } from '../../components/audit-log/audit-trail/audit-trail.service';
import { EntryTypeService } from '../../tamalelibs/services/entry-type.service';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { globalServices } from '../../services/service.index';
import { businessConstants } from '../../constants/business.constants';
import { WebTemplateViewModel } from '../../components/template/template-web.view-model';

@NgModule({
    imports: [
        CommonModule,
        ThreadDetailModule,
        WidgetsModule,
        PrintRoutingModule,
        AgmDirectionModule,
        AgmCoreModule.forRoot({
            apiKey: businessConstants.agmApiKey,
            libraries: ['places']
        }),
        AgmDirectionModule,
    ],
    declarations: [
        NoteAttachmentPdfComponent,
        PrintPreviewComponent,
        PrintDialogComponent,
    ],
    providers: [
        PrintService,
        ThreadService,
        ES6MapService,
        StoreQuerierService,
        NoteService,
        WindowRef,
        CopyService,
        PlatformService,
        ThreadDetailService,
        DateHelperService,
        EntryService,
        EntryTypeService,
        EntityService,
        AuditTrailDialogService,
        WebTemplateViewModel,
        ...globalServices
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ],
    exports: [
        PrintDialogComponent,
        PrintPreviewComponent,
    ]
})
export class PrintModule { }
