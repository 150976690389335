/**
 * created by Yoyo Fang on 06/06/2020
 * Description:
 *
 * ------ maintenance history ------
 * 08/04/2020 Marcus add action : IntralinksReLoadItemContent
 */
import { Action } from '@ngrx/store';
import { IntralinksAccountInfo } from '../models/intralinks.model';
import { SavedScreen } from '../models/saved-screen.model';
export enum INTRALINKS_ACTIONS {
    CREATE_ACCOUNT = 'CREATE_INTRALINKS_ACCOUNT',
    CREATE_ACCOUNT_FAIL = 'CREATE_INTRALINKS_ACCOUNT_FAIL',
    ADD_SCREEN = 'ADD_INTRALINKS_ACCOUNT_SCREEN',
    ADD_SCREEN_FAIL = 'ADD_INTRALINKS_ACCOUNT_SCREEN_FAIL',
    UPDATE_ACCOUNT = 'UPDATE_INTRALINKS_ACCOUNT',
    UPDATE_ACCOUNT_FAIL = 'UPDATE_INTRALINKS_ACCOUNT_FAIL',
    UPDATE_SCREEN = 'UPDATE_INTRALINKS_ACCOUNT_SCREEN',
    UPDATE_SCREEN_FAIL = 'UPDATE_INTRALINKS_ACCOUNT_SCREEN_FAIL',
    LOAD_WORKSPACE_FOLDER = 'LOAD_WORKSPACE_FOLDER',
    WORKSPACE_LOADED = 'WORKSPACE_LOADED',
    WORKSPACE_LOAD_FAILED = 'WORKSPACE_LOAD_FAILED',
    WORKSPACE_LOADED_FINISHED = 'WORKSPACE_LOADED_FINISHED',
    FOLDER_LOADED = 'FOLDER_LOADED',
    LOAD_FOLDER_CONTENT = 'LOAD_FOLDER_CONTENT',
    FOLDER_CONTENT_LOADED = 'FOLDER_CONTENT_LOADED',
    FOLDER_CONTENT_FAILED = 'FOLDER_CONTENT_FAILED',
    RELOAD_ITEM_CONTENT = 'RELOAD_ITEM_CONTENT',
    CLEAR_FOLDER_CACHE = 'CLEAR_FOLDER_CACHE',
    DELETE_SCREEN = 'DELETE_INTRALINKS_SCREEN',
    DELETE_ACCOUNT = 'DELETE_INTRALINKS_ACCOUNT',
    DELETE_ACCOUNT_SUCCESS = 'DELETE_INTRALINKS_ACCOUNT_SUCCESS',
    DELETE_ACCOUNT_FAILED = 'DELETE_INTRALINKS_ACCOUNT_FAILED',
    SET_ADMIN_VISIBILITY = 'SET_ADMIN_VISIBILITY',
    ADMIN_VISIBILITY_CHANGE = 'ADMIN_VISIBILITY_CHANGE',
    REACH_SESSION_LIMIT = 'REACH_SESSION_LIMIT'
}
export class IntralinksCreateAction implements Action {
    type: string = INTRALINKS_ACTIONS.CREATE_ACCOUNT;
    payload: IntralinksAccountInfo;
}
export class IntralinksAddScreenAction implements Action {
    type: string = INTRALINKS_ACTIONS.ADD_SCREEN;
    payload: SavedScreen;
}

export class IntralinksUpdateAction implements Action {
    type: string = INTRALINKS_ACTIONS.UPDATE_ACCOUNT;
    payload: IntralinksAccountInfo;
    screenId: string;
}

export class IntralinksUpdateScreenAction implements Action {
    type: string = INTRALINKS_ACTIONS.UPDATE_SCREEN;
    payload: SavedScreen;
}

export class IntralinksLoadWorkspacesFolders implements Action {
    type: string = INTRALINKS_ACTIONS.LOAD_WORKSPACE_FOLDER;
    payload: any;
}

export class IntralinksLoadFolderContent implements Action {
    type: string = INTRALINKS_ACTIONS.LOAD_FOLDER_CONTENT;
    payload: any;
}

export class IntralinksReLoadItemContent implements Action {
    type: string = INTRALINKS_ACTIONS.RELOAD_ITEM_CONTENT;
    payload: any;
}

export class IntralinksClearFolderCache implements Action {
    type: string = INTRALINKS_ACTIONS.CLEAR_FOLDER_CACHE;
    payload: any;
}

export class IntralinksDeleteAction implements Action {
    type: string = INTRALINKS_ACTIONS.DELETE_SCREEN;
    payload: any;
}

export class IntralinksAdminVisibility implements Action {
    type: string = INTRALINKS_ACTIONS.SET_ADMIN_VISIBILITY;
    constructor(
        public payload: any
    ) { }
}

export class IntralinksAdminVisibilityChange implements Action {
    readonly type = INTRALINKS_ACTIONS.ADMIN_VISIBILITY_CHANGE;
    payload: { path: Array<string>, value: any, isManually: boolean, isOnlyEnable: boolean };
    constructor(path: Array<string>, value: boolean, isManually = false, isOnlyEnable = false) {
        this.payload = { path: path, value: value, isManually: isManually, isOnlyEnable: isOnlyEnable };
    }
}
