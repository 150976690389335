<div class="container">
    <div class="clear-search">
        <div class="search" *ngIf="!showSearch">
            <smart-icon name="search" (click)="onSearchClick()"></smart-icon>
        </div>
        <div class="search-container" [style.display]="showSearch?'':'none'">
            <div class="icon-area">
                <smart-icon class="small light" name="search" (click)="resetCategorySearch()"></smart-icon>
            </div>
            <div class="input-area">
                <input #keywordInput type="text" [(ngModel)]="keyword" (keyup)="onKeyup($event)" autocomplete="off"
                    autocorrect="off" autocapitalize="off" spellcheck="false" placeholder="Search options"
                    (contextmenu)="onInputContextMenu($event)" />
            </div>
            <div class="clear-text-button" (click)="resetCategorySearch()" *ngIf="keyword">
                <smart-icon name="close" class="light"></smart-icon>
            </div>
        </div>
        <div class="clear" (click)="onClear()" *ngIf="!showSearch">Clear All</div>
    </div>
    <div [style.display]="!keyword || !searchDone ? '' : 'none'"
        [ngClass]="{'content': true, 'allEvent': isAllEvent,'entityEvent': !isAllEvent}">
        <div *ngFor="let subConfig of displayConfig; index as i;">
            <div [ngClass]="{'not-first': i !== 0}" [ngSwitch]="subConfig.type">
                <div *ngSwitchCase="0">
                    <refineby-date [dataConfig]="subConfig" [config]="widgetConfig"></refineby-date>
                </div>
                <div *ngSwitchCase="4">
                    <refineby-priority [dataConfig]="subConfig" [config]="widgetConfig"></refineby-priority>
                </div>
                <div *ngSwitchDefault>
                    <refineby-object [dataConfig]="subConfig" [config]="widgetConfig"></refineby-object>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="keyword&&searchDone&&sortedSearchResult.length>0"
        [ngClass]="{'content': true,'allEvent': isAllEvent,'entityEvent': !isAllEvent}">
        <div *ngFor="let subConfig of sortedSearchResult; index as i;">
            <div [ngClass]="{'not-first': i !== 0}" [ngSwitch]="subConfig.type">
                <div *ngSwitchCase="0">
                    <refineby-date [dataConfig]="subConfig" [config]="widgetConfigForSearch"></refineby-date>
                </div>
                <div *ngSwitchCase="4">
                    <refineby-priority [dataConfig]="subConfig" [config]="widgetConfigForSearch"></refineby-priority>
                </div>
                <div *ngSwitchDefault>
                    <refineby-object [dataConfig]="subConfig" [config]="widgetConfigForSearch"></refineby-object>
                </div>
            </div>
        </div>
    </div>
    <div class="content" *ngIf="keyword&&searchDone&&sortedSearchResult.length===0">
        <div class="no-search-result">No Results Found</div>
    </div>
    <tam-context-menu [config]="contextMenuConfig"></tam-context-menu>
    <div #anchor class="add-button" (click)="onAdd($event)" [style.display]="(addConfig&&addConfig.length>0)?'':'none'">
        <div class="add-icon">
            <smart-icon name="add" class="light"></smart-icon>
        </div>
        <span>Add metadata</span>
    </div>
    <kendo-popup [anchor]="anchor" [style.display]="showAddPopup?'':'none'">
        <div class='category-list-popup'>
            <div class='add-search'>
                <input #keywordForAddInput type="text" [(ngModel)]="keywordForAdd" (keyup)="onSearchKeyup($event)"
                    autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                    (click)="emptyClick($event)" (contextmenu)="onInputContextMenu($event)" />
            </div>
            <div class='category-list'>
                <div *ngFor="let subConfig of listSearchResult">
                    <div class="category-list-item" (click)="onClickListItem(subConfig)">{{subConfig.name}}</div>
                </div>
                <div class="category-list-no-item"
                    *ngIf="!keywordForAdd||(listSearchResult&&listSearchResult.length>0)">
                    Please search to add more filters
                </div>
                <div class="category-list-no-item"
                    *ngIf="keywordForAdd && (!listSearchResult || listSearchResult.length === 0)">
                    No Results Found
                </div>
            </div>
        </div>
    </kendo-popup>
</div>