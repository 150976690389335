<!-- <div orientation="horizontal" class="icon-container">
    <span orientation="horizontal" class="icon-container">
        <! first star, filled --
        <span *ngIf="value > 0">
            <smart-icon name="favorite-solid"></smart-icon>
        </span>

        <!-- second star, either empty or filled
        <span *ngIf="value > 1">
            <smart-icon name="favorite-solid"></smart-icon>
        </span>
        <span *ngIf="value < 2 && value > 0">
            <smart-icon name="favorite"></smart-icon>
        </span>

        <!-- third star, filled
        <span *ngIf="value > 2">
            <smart-icon name="favorite-solid"></smart-icon>
        </span>
        <span *ngIf="value < 3 && value > 0">
            <smart-icon name="favorite"></smart-icon>
        </span>
    </span>
</div> -->
<div class="container" [ngSwitch]="value">
    <div *ngSwitchCase="1">
        <smart-icon name="star"></smart-icon>
        <smart-icon name="star-o"></smart-icon>
        <smart-icon name="star-o"></smart-icon>
    </div>
    <div *ngSwitchCase="2">
        <smart-icon name="star"></smart-icon>
        <smart-icon name="star"></smart-icon>
        <smart-icon name="star-o"></smart-icon>
    </div>
    <div *ngSwitchCase="3">
        <smart-icon name="star"></smart-icon>
        <smart-icon name="star"></smart-icon>
        <smart-icon name="star"></smart-icon>
    </div>
</div>