/**
 * Created by Daniel on 2/18/2020.
 * Description:
 *
 * ------ maintenance history ------
 */

import { createSelector, combineReducers } from '@ngrx/store';
import { WorkflowTaskActions, WorkflowTaskActionTypes } from '../actions/workflow-task.actions';

export const workflowTaskSelector = (state: any) => state.workflowTask;
export const workflowTaskListSelector = createSelector(workflowTaskSelector, (state: any) => state.taskList);
export const workflowTaskCreateSelector = createSelector(workflowTaskSelector, (state: any) => state.createTask);
export const workflowTaskDeleteSelector = createSelector(workflowTaskSelector, (state: any) => state.deleteTask);
export const workflowTaskEditSelector = createSelector(workflowTaskSelector, (state: any) => state.editTask);
export const workflowTaskEditTaskReminderSelector = createSelector(workflowTaskSelector, (state: any) => state.editTaskReminder);
export const workflowTaskChangeStatusSelector = createSelector(workflowTaskSelector, (state: any) => state.changeTaskStatus);
export const workflowGetTaskByStatusSelector = createSelector(workflowTaskSelector, (state: any) => state.getTaskByStatus);
export const workflowSetTaskStatusSelector = createSelector(workflowTaskSelector, (state: any) => state.setTaskStatus);
export const workflowSetTaskProcessorSelector = createSelector(workflowTaskSelector, (state: any) => state.setTaskProcessor);

export function workflowTaskListReducer(state, action: WorkflowTaskActions) {
    switch (action.type) {
        case WorkflowTaskActionTypes.GET_ALL_TASK_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function workflowGetTaskByStatusReducer(state, action: WorkflowTaskActions) {
    switch (action.type) {
        case WorkflowTaskActionTypes.GET_TASK_BY_STATUS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function workflowTaskCreateReducer(state, action: WorkflowTaskActions) {
    switch (action.type) {
        case WorkflowTaskActionTypes.CREATE_QUICK_TASK_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function workflowTaskDeleteReducer(state, action: WorkflowTaskActions) {
    switch (action.type) {
        case WorkflowTaskActionTypes.DELETE_TASK_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function workflowTaskEditReducer(state, action: WorkflowTaskActions) {
    switch (action.type) {
        case WorkflowTaskActionTypes.EDIT_QUICK_TASK_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function workflowTaskEditReminderReducer(state, action: WorkflowTaskActions) {
    switch (action.type) {
        case WorkflowTaskActionTypes.EDIT_QUICK_TASK_REMIND_DATE_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function workflowTaskChangeStatusReducer(state, action: WorkflowTaskActions) {
    switch (action.type) {
        case WorkflowTaskActionTypes.CHANGE_QUICK_TASK_STATUS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function workflowSetTaskStatusReducer(state, action: WorkflowTaskActions) {
    switch (action.type) {
        case WorkflowTaskActionTypes.SET_TASK_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export function workflowSetTaskProcessorReducer(state, action: WorkflowTaskActions) {
    switch (action.type) {
        case WorkflowTaskActionTypes.SET_TASK_PROCESSOR:
            return action.payload;
        default:
            return state;
    }
}

const workflowTaskReducer = combineReducers({
    taskList: workflowTaskListReducer,
    createTask: workflowTaskCreateReducer,
    deleteTask: workflowTaskDeleteReducer,
    editTask: workflowTaskEditReducer,
    editTaskReminder: workflowTaskEditReminderReducer,
    changeTaskStatus: workflowTaskChangeStatusReducer,
    getTaskByStatus: workflowGetTaskByStatusReducer,
    setTaskStatus: workflowSetTaskStatusReducer,
    setTaskProcessor: workflowSetTaskProcessorReducer,
});

export function getWorkflowTaskReducer(state, action) {
    return workflowTaskReducer(state, action);
}
