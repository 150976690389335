/**
 * Created by Abner Sui on 01/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Component, OnInit, Input, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FilterWidgetFeedback, FilterWidgetFeedbackType, FilterWidgetConfig } from '../../tamalelibs/components/filter-widget.config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FiltersConfig, FilterMetadataType } from '../../tamalelibs/components/filter-config';
import { ContactService } from '../../tamalelibs/services/contact.service';
import { EntityService } from '../../tamalelibs/services/entity.service';
import { of as observableOf, Subscription, Subject } from 'rxjs';
import { AppConfig } from '../../tamalelibs/models/app-config.model';
import { EntityBrief } from '../../tamalelibs/models/entity-brief.model';
import { catchError, take, debounceTime, filter } from 'rxjs/operators';
import { EntryService } from '../../tamalelibs/services/entry.service';
import { UsersService } from '../../tamalelibs/services/users.service';
import { ArrayHelperService } from '../../tamalelibs/services/array-helper.service';
const SORT_BY_NAME = 'long-name';

@Component({
    selector: 'tam-entity-filter',
    templateUrl: './tam-entity-filter.component.html',
    styleUrls: ['./tam-filter-container.component.scss'],
})
export class TamEntityFilterComponent implements OnInit, OnDestroy {

    @Input() config: FiltersConfig; // data
    @Input() filterWidgetConfig: FilterWidgetConfig; // event
    @ViewChild('entitylist', { static: true }) entitylist;

    operators: Array<{ name: string, value: string }>;
    operator: { name: string, value: string };
    isItemHoverOn = false;
    dataItems: Array<EntityBrief> = []; // suggestion items based on search, sub set of dataItems
    selectedItems: Array<EntityBrief> = []; // selected items, sub set of dataItems

    readonlySource = false;

    private _filterValue: string;
    private _page: number;
    private _pageSize: number = AppConfig.searchingPageSize;
    private _functionName: string;
    private _service: any;
    private _source: any;
    private _filterHandler$: Subject<string> = new Subject();
    private _destroySubscriptions: Array<Subscription> = [];
    constructor(
        private _deviceService: DeviceDetectorService,
        private _contactService: ContactService,
        private _entityService: EntityService,
        private _entryService: EntryService,
        private _usersService: UsersService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this._destroySubscriptions.push(
            this._filterHandler$.pipe(
                debounceTime(250),
                filter(filterStr => filterStr != null && filterStr.length > 0)
            ).subscribe(filterStr => this.searching(filterStr))
        );
        this.operators = this.config.operators;
        this.operator = this.config.operators.find(item => item.value === this.config.selectedOperator);
        if (this.config.metadataType?.toLowerCase() === FilterMetadataType.Source.toLowerCase() ||
            this.config.metadataType?.toLowerCase() === FilterMetadataType.Submitter.toLowerCase() ||
            this.config.metadataType?.toLowerCase() === FilterMetadataType.Participants.toLowerCase()) {
            this._service = this._contactService;
            this._functionName = 'getContactListByName';
        } else if (this.config.metadataType?.toLowerCase() === FilterMetadataType.User.toLowerCase()) {
            this._service = this._usersService;
            this._functionName = 'getAll';
        } else if (this.config.metadataType?.toLowerCase() === FilterMetadataType.Team.toLowerCase()) {
            this._service = this._entryService;
            this._functionName = 'getTeamsgetByParams';
            this._source = true;
        } else {
            this._service = this._entityService;
            this._functionName = 'getEntityListBySearchTextQuick';
            if (this.config.source) {
                this._source = this.config.source;
            }
        }
        if (this.config.value) {
            this.selectedItems = this.config.value;
        }
        this.readonlySource = this.config.selectedOperator.toLocaleLowerCase().indexOf('null') !== -1 ||
            this.config.selectedOperator.toLocaleLowerCase().indexOf('blank') !== -1;
        this.searchingFirstPage();
        // For use within normal web clients
        const isiPad = this._deviceService.isMobile() || this._deviceService.isTablet();
        if (isiPad) {
            this.isItemHoverOn = true;
        } else {
            this.isItemHoverOn = !this.config.deletable;
        }
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    /**
     * when input value, will trigger handleFilter
     * when valueChange, kendo-multiselect will trigger handleFilter
     * BA need:
     * when clear selectedItems, init all list
     * when clear input value, init all list
     * when selected item, keep last search value, to reduce the request.
     */
    handleFilter(value) {
        const filterStr = value.trim();
        if (this._filterValue && !filterStr) {
            this.searchingFirstPage();
        }
        this._filterHandler$.next(filterStr);
        this._filterValue = filterStr;
    }

    onMouseOver() {
        if (!this.config.deletable) {
            this.isItemHoverOn = true;
        }
    }

    onMouseOut() {
        if (!this.config.deletable) {
            this.isItemHoverOn = false;
        }
    }

    onBtnCloseClicked() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.remove;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    searchingFirstPage() {
        this.searching('');
    }

    searching(text) {
        this._page = 0;
        this.entitylist.loading = true;
        this._destroySubscriptions.push(
            this._service[this._functionName](++this._page, this._pageSize, text, this._source).pipe(
                take(1),
                catchError((e) => {
                    return observableOf();
                }))
                .subscribe(res => {
                    if (res) {
                        if (this._functionName === 'getAll' && res['entity-list']) {
                            ArrayHelperService.sort(res['entity-list'], SORT_BY_NAME);
                            res['entity-list'] = res['entity-list'].filter(item => item['long-name'].toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1);
                        }
                        this.searchingDone(res);
                        this.entitylist.loading = false;
                        this._changeDetectorRef.detectChanges();
                    }
                })
        );
    }

    searchingDone(entityList) {
        if (this.config.metadataType?.toLowerCase() === FilterMetadataType.Team.toLowerCase()) {
            this.dataItems = this._entityService.mapTeamBriefList(entityList);
        } else {
            this.dataItems = this._entityService.mapEntityBriefList(entityList);
        }
        if (this.config.currentUser) {
            this.dataItems.unshift(this.config.currentUser);
        }
    }

    valueChangeOperate(changeItem: { name: string, value: string }) {
        this.readonlySource = changeItem.value.toLocaleLowerCase().indexOf('null') !== -1 || changeItem.value.toLocaleLowerCase().indexOf('blank') !== -1;
        this._feedback();
    }

    valueChange(): void {
        // emits event for value change
        if (this.selectedItems.length === 0) {
            this.searchingFirstPage();
        }
        this._filterValue = '';
        this._feedback();
    }

    private _feedback() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.update;

        this.config.selectedOperator = this.operator.value;
        this.config.value = this.selectedItems;

        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

}
