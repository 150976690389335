/**
 * Created by Alan Yang on 5/28/2019.
 * Description:
 * DataImportGuard
 *
 * ------ maintenance history ------
 * Updated by Abner Sui on 07/11/2019
 * Change if conditon to identify whether is dirty
 */
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { DataImportComponent } from './data-import.component';

@Injectable({
    providedIn: 'root'
})
export class DataImportGuard implements CanDeactivate<DataImportComponent> {
    constructor(
        private _alertWindow: AlertWindowService,
    ) { }

    /**
     * Confirm with user if leave Data Import page
     *
     * @param {DataImportComponent} component
     * @returns
     * @memberof DataImportGuard
     */
    canDeactivate(component: DataImportComponent): Observable<boolean> | boolean {
        if (component.isDirty()) {
            return this._alertWindow.warn('You have unsaved changes', ['Are you sure you want to discard all your changes?'])
                .pipe(
                    map((result: boolean) => {
                        return result;
                    }),
                );
        } else {
            return true;
        }
    }
}
