/**
 * Created by Jiu Chen on 01/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum TeamsActionTypes {
    INITIALIZE = '[TEAMS] INITIALIZE',
    INITIALIZE_SUCCESS = '[TEAMS] INITIALIZE_SUCCESS',
    INITIALIZE_FAILED = '[TEAMS] INITIALIZE_FAILED'
}

export class Initialize implements Action {
    readonly type = TeamsActionTypes.INITIALIZE;
}

export class InitializeSuccess implements Action {
    readonly type = TeamsActionTypes.INITIALIZE_SUCCESS;

    constructor(public payload: any) { }
}

export class InitializeFailed implements Action {
    readonly type = TeamsActionTypes.INITIALIZE_FAILED;

    constructor(public payload: any) { }
}

export type TeamsActions = Initialize | InitializeSuccess | InitializeFailed;
