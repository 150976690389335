<div class="container">
    <field-config-base #base [config]="config" [dataType]="dataType" isReadonlyVisible=true></field-config-base>
    <div class="tam-form-field one-field" [ngClass]="{'has-error':sourceInvalid}">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.source' | stringLiterals}}&nbsp;<span class="required-field">*</span>
            </div>
        </div>
        <textarea class="tam-textbox field-control" type="text" [(ngModel)]="source" (keyup)="valueChangeSource($event)"
            placeholder="{{sourcePlaceHolder}}"></textarea>
        <div class="tam-form-help" *ngIf="sourceInvalid">
            {{'general.required_error_message' | stringLiterals}}
        </div>
    </div>
    <div class="tam-form-field one-field">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.default_value' | stringLiterals}}
            </div>
        </div>
        <kendo-combobox #kendo [popupSettings]="{appendTo: 'component'}" class="tam-textbox field-control"
            [data]="staticDefaultValueData" [filterable]="true" [(ngModel)]="config.staticDefaultValue"
            (valueChange)="valueChange($event)" (focus)="focusStaticDefaultValue()"
            (filterChange)="filterStaticDefaultValue($event)" [disabled]="!config.source.length">
        </kendo-combobox>
    </div>
</div>