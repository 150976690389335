/**
 * Created by Abner Sui on 1/5/2021
 * -------------------------------------
 */

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlType } from '../../../tamalelibs/models/template-control.model';
import { FieldType } from '../../../tamalelibs/models/workflow.model';
import { ControlConfigEvents, IControlConfigComponent } from '../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';

@Component({
    selector: 'tam-text-config',
    templateUrl: './text-config.component.html',
    styleUrls: ['./field-config.component.scss']
})
export class TextConfigComponent implements OnInit, AfterViewInit, OnDestroy, IControlConfigComponent {

    @Input()
    config: FieldConfig;

    @ViewChild('base', { static: false }) base;

    dataType = FieldType.TEXT;
    staticDefaultValueInvalid = false;

    private _controlType = ControlType.TEXT;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor() { }

    getInvalidDetail(): FieldValidationError {
        return this.base.getInvalidDetail();
    }

    ngOnInit(): void {
        // this._destroySubscriptions.push();
    }

    ngAfterViewInit(): void {
        if (this.config.showValidateInfo) {
            this.validate();
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    validate(): boolean {
        const baseValid: boolean = this.base.validate();
        // run local validate
        const localValid = this._validateStaticDefaultValue();
        return baseValid && localValid;
    }

    valueChange(event): void {
        this.config.config.feedbackSubject$.next({
            type: ControlConfigEvents.VALUE_CHANGE,
            payload: this.config,
        });
    }

    valueChangeMinlength(event): void {
        this.config.minlength = event;
        this._validateStaticDefaultValue();
        this.valueChange(event);
    }

    valueChangeMaxlength(event): void {
        this.config.maxlength = event;
        this._validateStaticDefaultValue();
        this.valueChange(event);
    }

    valueChangeStaticDefaultValue(event): void {
        this._validateStaticDefaultValue();
        this.valueChange(event);
    }

    private _validateStaticDefaultValue(): boolean {
        this.staticDefaultValueInvalid = false;
        if (this.config.minlength !== null && this.config.minlength !== undefined) {
            if (this.config.staticDefaultValue && this.config.staticDefaultValue.length < this.config.minlength) {
                this.staticDefaultValueInvalid = true;
            }
        }
        if (this.config.maxlength !== null && this.config.maxlength !== undefined) {
            if (this.config.staticDefaultValue && this.config.staticDefaultValue.length > this.config.maxlength) {
                this.staticDefaultValueInvalid = true;
            }
        }
        return !this.staticDefaultValueInvalid;
    }

}
