/**
 * Created by Alex Xia on 06/05/23.
 * Description:
 *
 * ------ maintenance history ------
 */

import { CalendarList } from '../../tamalelibs/models/entity-list.model';
import { Contact } from '../../tamalelibs/models/contact.model';
import { CHANGE_CALENDARS_DETAIL, RELOAD_CALENDARS_FAIL, RELOAD_CALENDARS_SUCCESS } from '../../tamalelibs/redux/calendar-list.actions';
import { combineReducers, createSelector } from '@ngrx/store';
import { CalendarAction, CalendarListRefinebyActionTypes } from '../actions/calendar.action';
import { CalendarListRefinebyActions } from '../actions/calendar.action';

export function calendarListReducer(state: any = null, action) {
    switch (action.type) {
        case RELOAD_CALENDARS_SUCCESS:
            if (action.params.workflow !== 'scroll') {
                return {
                    calendarList: action.payload,
                    params: action.params
                };
            } else {
                const newCalendarList = new CalendarList();
                newCalendarList.calendars = state.calendarList.calendars.concat(action.payload.calendars);
                newCalendarList.next = action.payload.next;
                // set total count when scroll down calendar list.
                newCalendarList.totalCount = action.payload.totalCount;
                return {
                    calendarList: newCalendarList,
                    params: action.params
                };
            }
        case RELOAD_CALENDARS_FAIL:
            return null;
        case CHANGE_CALENDARS_DETAIL:
            const contact: Contact = action.payload;
            if (state && state.contactList && state.contactList.contacts) {
                state.contactList.contacts.forEach((item, index) => {
                    if (item.id === contact.id) {
                        state.contactList.contacts[index] = contact;
                    }
                });
            }
            return Object.assign([], state);
        default:
            return state;
    }
}
export function refinebyDataReducer(state: Map<string, Array<any>>, action: CalendarListRefinebyActions) {
    switch (action.type) {
        case CalendarListRefinebyActionTypes.LOAD_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
export function refinebySelectedValueReducer(state: Map<string, Array<any>>, action: CalendarListRefinebyActions) {
    switch (action.type) {
        case CalendarListRefinebyActionTypes.SET_SELECTED_VALUE:
            return action.payload.selectedValue;
        default:
            return state;
    }
}
export function refinebyStatusReducer(state: Map<string, Array<any>>, action: CalendarListRefinebyActions) {
    switch (action.type) {
        case CalendarListRefinebyActionTypes.SET_CATEGORY_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export function refinebyCategoryReducer(state: Array<string>, action: CalendarListRefinebyActions) {
    switch (action.type) {
        case CalendarListRefinebyActionTypes.SET_CATEGORY_ORDER:
            return action.payload;
        case CalendarListRefinebyActionTypes.SET_CATEGORY:
            return action.payload;
        default:
            return state;
    }
}

export function refinebyCategoryFilterReducer(state: Array<string>, action: CalendarListRefinebyActions) {
    switch (action.type) {
        case CalendarListRefinebyActionTypes.SET_SELECTED_VALUE:
            return action.payload.categoryFilter;
        default:
            return state;
    }
}
const refinebyReducer = combineReducers({
    data: refinebyDataReducer,
    selectedValue: refinebySelectedValueReducer,
    status: refinebyStatusReducer,
    category: refinebyCategoryReducer,
    categoryFilter: refinebyCategoryFilterReducer,
});

export function getRefinebyReducer(state, action) {
    return refinebyReducer(state, action);
}
export const getCalendarAllListState = (state: any) => state;
export const getCalendarListRefinebyState = createSelector(getCalendarAllListState, (state: any) => state.refineby);
export const getCalendarListRefinebyDataState = createSelector(getCalendarListRefinebyState, (state: any) => state.data);
export const getCalendarListRefinebySelectedValueState = createSelector(getCalendarListRefinebyState, (state: any) => state.selectedValue);
export const getCalendarListRefinebyStatusState = createSelector(getCalendarListRefinebyState, (state: any) => state.status);
export const getCalendarListRefinebyCategoryState = createSelector(getCalendarListRefinebyState, (state: any) => state.category);
export const getCalendarListRefinebyCategoryFilterState = createSelector(getCalendarListRefinebyState, (state: any) => state.categoryFilter);
const calendarAllListReducer = combineReducers({
    items: calendarListReducer,
    refineby: refinebyReducer,
});
export function getCalendarAllListReducer(state, action) {
    return calendarAllListReducer(state, action);
}
