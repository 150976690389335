/**
 * Created by Abner Sui on 12/13/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectWindowFeedback, SelectWindowAction } from './select-window.model';

@Injectable({
    providedIn: 'root'
})
export class SelectWindowService {

    openSubject$: Subject<SelectWindowAction> = new Subject();
    resultSubject$: Subject<SelectWindowFeedback> = new Subject();

    open(action: SelectWindowAction): Subject<any> {
        this.openSubject$.next(action);
        return this.resultSubject$;
    }

}
