import { Directive, ElementRef, AfterContentInit, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[tamDynamicTooltip]'
})
export class DynamicTooltipDirective implements AfterContentInit {

    private _element;

    constructor(private el: ElementRef, private _renderer: Renderer2) { }

    ngAfterContentInit() {
        this._element = this.el.nativeElement;
    }

    @HostListener('mouseenter') mouseover() {
        if (this._element.offsetWidth < this._element.scrollWidth ||
            this._element.offsetWidth > this._element.parentNode.clientWidth ||
            (this._element.offsetWidth === 0 && this._element.scrollWidth === 0 &&
                this._element.parentNode.clientWidth === 0)) {
            this._renderer.setAttribute(this._element, 'data-title', this._element.textContent);
        } else {
            this._renderer.setAttribute(this._element, 'data-title', '');
        }
    }
}
