/**
 * Created by Daniel on 3/14/2019.
 * Description:
 *
 * ------ maintenance history ------
 *  2019.7.2 By Daniel: Add delete layout function
 */
import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import { AppConfig } from '../tamalelibs/models/app-config.model';
import { TransportService } from '../tamalelibs/services/transport.service';
import { RelationshipTreeInfo, ResearchViewInfo, FilterInfo, RefinebyInfo } from '../pages/research/research.view-model';
import { ResearchLayoutMode } from '../components/research-layout/research-layout.data';
import { FilterService } from '../tamalelibs/services/filter.service';
import { map } from 'rxjs/operators';
import { StoreQuerierService } from './store-querier.service';
import {
    getDynamicResearchFocusSelector2, getDynamicResearchRelationshipTreeCheckedKeysSelector2,
    getDynamicResearchSortInfoSelector2, getDynamicResearchLayoutSelector2,
    getDynamicResearchFilterItemsSelector2, getDynamicResearchFilterEnableSelector2,
    getDynamicResearchFilterLogicSelector2, getDynamicResearchKeywordSelector2,
    getDynamicResearchRefinebyCategorySelector, getDynamicResearchGridStatusSelector2,
    getDynamicResearchRelationshipTreeNameFormatSelector2, getDynamicResearchDisplaySelector2, getDynamicResearchThreadListGridGropLayoutSelector
} from '../redux/reducers/research-screen.reducer';
import { FiltersConfig } from '../tamalelibs/components/filter-config';
import { getFocusState } from '../redux/reducers/blastemail-view.reducer';
import { smartSelector } from '../redux';
import { FocusInfo } from '../components/tam-dashboard/tam-dashboard.model';
import * as lodash from 'lodash';
import { businessConstants } from '../constants/business.constants';
import { ALL_VALUE } from '../tamalelibs/constants/business.constants';
import { getScreenStatusDictionarySelector } from '../redux/reducers/screen.reducer';
import { ArrayHelperService } from '../tamalelibs/services/array-helper.service';

@Injectable()
export class HomeScreenService {
    reloadingAssignedTab$: Subject<{ type: string, payload?: any }> = new Subject<{ type: string, payload?: any }>();

    constructor(
        private _transportService: TransportService,
        private _filterService: FilterService,
        private _storeQuerier: StoreQuerierService,
    ) { }

    public createNewScreenLayout(homeScreenData) {
        const homeScreenDataClone = lodash.cloneDeep(homeScreenData);
        const homeScreenTabs = [];
        homeScreenDataClone.tabs.forEach(element => {
            if (element.screenId.indexOf(businessConstants.homeScreen.prefixNewResearchViewID) === -1) {
                if (element.screenType === businessConstants.screenType.research) {
                    delete element.config; // remove config from tabs, it's only used for UI
                }
                homeScreenTabs.push(element);
            }
        });
        homeScreenDataClone.tabs = homeScreenTabs;

        const url = AppConfig.homeScreenEndpoint;
        const params: any = {
            outputformat: 'json'
        };
        const payload = new FormData();
        payload.append('homescreen', JSON.stringify(homeScreenDataClone));
        const options = {
            params: params
        };
        return this._transportService.post(url, payload, options);
    }

    public deleteHomeScreenLayoutById(layoutId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.homeScreenEndpoint}${layoutId}`;
        const options = {
            headers: headers,
        };
        return this._transportService.delete(url, options);
    }

    public getHomeScreenLayoutById(layoutId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.homeScreenEndpoint}` + `individual/` + `${layoutId}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getHomeScreens(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.homeScreenEndpoint}` + 'individual';
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    // get workflow id from configuration/screen
    public getWorkflowIdByScreen(screen): string {
        let workflowId = '';
        if (screen.configuration && screen.configuration.workflowId) {
            workflowId = screen.configuration.workflowId;
        } else {
            workflowId = screen.workflowId;
        }
        return workflowId;
    }

    public prepareResearchViewInfo(storeKey) {
        const researchViewInfo = new ResearchViewInfo();
        const finderInfo = this._storeQuerier.queryBySelector(getDynamicResearchFocusSelector2(storeKey));
        researchViewInfo.finderInfo = {
            entityId: finderInfo.entity.id,
            status: finderInfo.status
        };

        const checkedKeys: string[] = this._storeQuerier.queryBySelector(getDynamicResearchRelationshipTreeCheckedKeysSelector2(storeKey));
        checkedKeys.sort();
        const checkedKeysAfterFilter = [];
        checkedKeys.forEach((key: string) => {
            let ignore = false;
            for (const key1 of checkedKeysAfterFilter) {
                if (key.includes(key1)) {
                    ignore = true;
                    break;
                }
            }
            if (!ignore) {
                checkedKeysAfterFilter.push(key);
            }
        });
        researchViewInfo.relationshipTreeInfo = {
            showAs: 'shortName',
            checkedNodeKeys: checkedKeysAfterFilter
        };
        const sortInfo = this._storeQuerier.queryBySelector(getDynamicResearchSortInfoSelector2(storeKey));
        researchViewInfo.sortInfo = sortInfo;

        const layoutInfo = this._storeQuerier.queryBySelector(getDynamicResearchLayoutSelector2(storeKey));
        researchViewInfo.layoutInfo = layoutInfo;

        researchViewInfo.filterInfo = new FilterInfo();
        const filterConfigs: FiltersConfig[] = this._storeQuerier.queryBySelector(getDynamicResearchFilterItemsSelector2(storeKey));
        researchViewInfo.filterInfo.isFilterOn = this._storeQuerier.queryBySelector(getDynamicResearchFilterEnableSelector2(storeKey));
        researchViewInfo.filterInfo.filterLogic = this._storeQuerier.queryBySelector(getDynamicResearchFilterLogicSelector2(storeKey));
        researchViewInfo.filterInfo.filterConfigs = filterConfigs.map(config => {
            const tempConfig = Object.assign({}, config);
            if (tempConfig.hasOwnProperty('operators')) {
                delete tempConfig.operators;
            }
            if (tempConfig.hasOwnProperty('source')) {
                delete tempConfig.source;
            }
            if (tempConfig.hasOwnProperty('relationshipTypeSource')) {
                delete tempConfig.relationshipTypeSource;
            }
            return tempConfig;
        });
        researchViewInfo.refineByInfo = new RefinebyInfo();
        researchViewInfo.refineByInfo.category = this._storeQuerier.queryBySelector(getDynamicResearchRefinebyCategorySelector(storeKey));

        return researchViewInfo;
    }

    public setScreenMenuItems(screenList, menuContainer, isShowActivityInDashboard = false): void {
        const researchFixSubItems = [];
        let researchSubItems = [];
        const dashboardFixSubItems = [];
        let dashboardSubItems = [];
        const calendarFixSubItem = [];
        const workflowSubItems = [];
        researchFixSubItems.push({
            id: businessConstants.allNotesScreenId,
            caption: businessConstants.allNotesCaption,
            type: businessConstants.screenType.research,
            items: null
        });
        researchFixSubItems.push({
            id: businessConstants.myNotesScreenId,
            caption: businessConstants.myNotesCaption,
            type: businessConstants.screenType.research,
            items: null
        });
        dashboardFixSubItems.push({
            id: businessConstants.activityScreenId,
            caption: businessConstants.activityCaption,
            type: businessConstants.screenType.dashboard,
            items: null
        });
        calendarFixSubItem.push({
            id: businessConstants.eventScreen.eventScreenId,
            caption: businessConstants.eventScreen.eventsCaption,
            type: businessConstants.screenType.event,
            items: null
        });

        screenList.forEach(item => {
            if (item.type === businessConstants.screenType.research) {
                if (item.id !== businessConstants.allNotesScreenId && item.id !== businessConstants.myNotesScreenId) {
                    researchSubItems.push({ id: item.id, caption: item.name, type: businessConstants.screenType.research, items: null });
                }
            } else if (item.type === businessConstants.screenType.dashboard) {
                dashboardSubItems.push({ id: item.id, caption: item.name, type: businessConstants.screenType.dashboard, items: null });
            } else if (item.type === businessConstants.screenType.workflow) {
                let workflowId = '';
                if (item.configuration && item.configuration.id) {
                    workflowId = item.configuration.id;
                }
                workflowSubItems.push({ id: item.id, caption: item.name, type: businessConstants.screenType.workflow, items: null, workflowId: workflowId });
            }
        });
        // sort resaerch view items
        ArrayHelperService.sort(researchSubItems, 'caption');
        // sort dashboard items
        ArrayHelperService.sort(dashboardSubItems, 'caption');
        // sort worflow items
        ArrayHelperService.sort(workflowSubItems, 'caption');

        researchSubItems = researchFixSubItems.concat(researchSubItems);
        if (isShowActivityInDashboard) {
            dashboardSubItems = dashboardFixSubItems.concat(dashboardSubItems);
        }

        menuContainer.forEach(menu => {
            if (menu.type === businessConstants.screenType.research) {
                menu.items = researchSubItems;
            } else if (menu.type === businessConstants.screenType.dashboard) {
                menu.items = dashboardSubItems;
            } else if (menu.type === businessConstants.screenType.event) {
                menu.items = calendarFixSubItem;
            } else if (menu.type === businessConstants.screenType.workflow) {
                menu.items = workflowSubItems;
            }
        });
    }

    public updateEntityScreenTabs(entityScreenData, is360View = false): Observable<any> {
        const entityScreenDataClone = lodash.cloneDeep(entityScreenData);
        const screenStatusDictionary = this._storeQuerier.queryBySelector(getScreenStatusDictionarySelector);
        const entityScreenTabs = [];
        entityScreenDataClone.tabs.forEach(element => {
            if (element.screenType === businessConstants.screenType.research
                || element.screenType === businessConstants.screenType.entity) {
                element.configuration = this._getResearchScreenConfiguration(element.id, is360View);
                delete element.config; // remove config from tabs, it's only used for UI
            } else if (element.screenType === businessConstants.screenType.dashboard) {
                element.configuration = this._getDashboardScreenConfiguration(element.id);
            } else if (element.screenType === businessConstants.screenType.event) {
                delete element.config; // remove config from tabs, it's only used for UI
            }
            if (screenStatusDictionary.includes(element.id)) {
                element.configurationChanged = true;
            } else {
                element.configurationChanged = false;
            }
            entityScreenTabs.push(element);
        });
        entityScreenDataClone.tabs = entityScreenTabs;
        const url = `${AppConfig.homeScreenEndpoint}`;
        const params: any = {
            outputformat: 'json'
        };

        const payload = new FormData();
        payload.append('homescreen', JSON.stringify(entityScreenDataClone));

        const options = {
            params: params
        };
        return this._transportService.post(url, payload, options);
    }

    public updateIndividualScreen(screenId, origialScreenTabs, pageChangedId, is360View = false): Observable<any> {
        let screenTabs = lodash.cloneDeep(origialScreenTabs);
        // remove relationship from tabs
        screenTabs = screenTabs.filter(item => item.screenId !== businessConstants.relationshipScreenId);
        // add configuration for each page
        screenTabs.forEach(element => {
            if (element.screenType === businessConstants.screenType.research) {
                element.configuration = this._getResearchScreenConfiguration(element.id, is360View);
                delete element.config; // remove config from tabs, it's only used for UI
            } else if (element.screenType === businessConstants.screenType.dashboard) {
                element.configuration = this._getDashboardScreenConfiguration(element.id);
            } else if (element.screenType === businessConstants.screenType.event) {
                delete element.config; // remove config from tabs, it's only used for UI
            } else if (element.screenType === businessConstants.screenType.workflow) {
                element.configuration = this._getWorkflowScreenConfiguration(element);
            }
            if (element.id === pageChangedId) {
                element.configurationChanged = true;
            } else {
                element.configurationChanged = false;
            }
        });

        const url = `${AppConfig.homeScreenEndpoint}${screenId}` + '/individuallayout';
        const params: any = {
            outputformat: 'json'
        };

        const payload = new FormData();
        const tabs = { 'tabs': screenTabs };
        payload.append('userscreen', JSON.stringify(tabs));

        const options = {
            params: params
        };
        return this._transportService.post(url, payload, options);
    }

    public updateOriginalHomeScreen(homeScreenData, is360View = false): Observable<any> {
        const homeScreenDataClone = lodash.cloneDeep(homeScreenData);
        const homeScreenId = homeScreenDataClone.id;
        delete homeScreenDataClone.type;
        const screenStatusDictionary = this._storeQuerier.queryBySelector(getScreenStatusDictionarySelector);
        const homeScreenTabs = homeScreenDataClone.tabs.filter(item => item.screenId !== businessConstants.relationshipScreenId);
        homeScreenTabs.forEach(element => {
            if (element.screenType === businessConstants.screenType.research) {
                element.configuration = this._getResearchScreenConfiguration(element.id, is360View);
                delete element.config; // remove config from tabs, it's only used for UI
            } else if (element.screenType === businessConstants.screenType.dashboard) {
                element.configuration = this._getDashboardScreenConfiguration(element.id);
            } else if (element.screenType === businessConstants.screenType.event) {
                delete element.config; // remove config from tabs, it's only used for UI
            } else if (element.screenType === businessConstants.screenType.workflow) {
                element.configuration = this._getWorkflowScreenConfiguration(element);
            }

            if (screenStatusDictionary.includes(element.id)) {
                element.configurationChanged = true;
            } else {
                element.configurationChanged = false;
            }
        });
        homeScreenDataClone.tabs = homeScreenTabs;
        const url = `${AppConfig.homeScreenEndpoint}${homeScreenId}` + '/originallayout';
        const params: any = {
            outputformat: 'json'
        };

        const payload = new FormData();
        payload.append('homescreen', JSON.stringify(homeScreenDataClone));

        const options = {
            params: params
        };
        return this._transportService.post(url, payload, options);
    }

    /**
     * Convert the saved Research View from Web1.0 to Web2.0;
     * There are two versions of saved screen data, this function is for the version 2;
     *
     * @private
     * @param {*} researchInfoV2 the "configuration" section in saved screen data
     * @memberof ResearchComponent
     */
    public convertResearchViewInfoV2toV3(researchInfoV2, screendId): Observable<any> {
        const researchViewInfo: ResearchViewInfo = new ResearchViewInfo();
        researchViewInfo.finderInfo = {
            entityId: researchInfoV2.finderStatus.entity.id,
            status: researchInfoV2.finderStatus.status
        };
        researchViewInfo.relationshipTreeInfo = this._translateRelationshipTreeInfoV2(researchInfoV2.relTreeStatus);
        if (researchInfoV2.noteListStatus) {
            researchViewInfo.sortInfo = {
                sortBy: researchInfoV2.noteListStatus.sortBy,
                sortOrder: researchInfoV2.noteListStatus.sortOrder
            };
        }
        if (researchInfoV2.layoutStatus) {
            researchViewInfo.layoutInfo = {
                layoutMode: ResearchLayoutMode.SNIPPET,
                activeTab: researchInfoV2.layoutStatus.activeTab,
                collapsed: researchInfoV2.layoutStatus.collapsed,
                isEntityFocusHidden: false,
                isRelationshipTreeHidden: false,
                isEntityFocusDisable: false,
                isRelationshipTreeDisable: false
            };
            this.SetFocusRelationshipDiablesStatus(researchViewInfo, screendId);
        }
        if (researchInfoV2.refineByStatus && researchInfoV2.refineByStatus.dataConfig && researchInfoV2.refineByStatus.dataConfig.length) {
            researchInfoV2.refineByStatus.dataConfig.forEach(item => {
                researchViewInfo.refineByInfo.category.push(item.categoryId);
            });
        }
        // convert Web1.0 v2 filter configuration to Web 2.0 structure
        if (researchInfoV2.filterStatus) {
            if (researchInfoV2.filterStatus.filterConfig) {
                const filterConfigs = this._filterService.convertFilterV2toV3(researchInfoV2.filterStatus.filterConfig);
                const filterInfo: FilterInfo = {
                    filterConfigs: filterConfigs,
                    isFilterOn: researchInfoV2.filterStatus.filterOn,
                    filterLogic: researchInfoV2.filterStatus.filterConfig.filterLogic
                };
                researchViewInfo.filterInfo = filterInfo;
                return of(researchViewInfo);
            } else if (researchInfoV2.filterStatus.filterId) {
                return this._filterService.getById(researchInfoV2.filterStatus.filterId).pipe(
                    map(response => {
                        const filterConfigs = this._filterService.convertFilterV2toV3(JSON.parse(response.filterConfig));
                        const filterInfo: FilterInfo = {
                            filterConfigs: filterConfigs,
                            isFilterOn: researchInfoV2.filterStatus.filterOn,
                            filterLogic: JSON.parse(response.filterConfig).filterLogic
                        };
                        researchViewInfo.filterInfo = filterInfo;
                        return researchViewInfo;
                    })
                );
            } else {
                const filterInfo: FilterInfo = {
                    filterConfigs: [],
                    isFilterOn: false,
                    filterLogic: ALL_VALUE
                };
                researchViewInfo.filterInfo = filterInfo;
                return of(researchViewInfo);
            }
        } else {
            return of(researchViewInfo);
        }
    }

    public SetFocusRelationshipDiablesStatus(config, screenId) {
        if (config && config.layoutInfo) {
            if (screenId === businessConstants.allNotesScreenId
                || screenId === businessConstants.myNotesScreenId) {
                config.layoutInfo.isEntityFocusDisable = true;
                config.layoutInfo.isRelationshipTreeDisable = true;
            }
        }
    }

    private _getDashboardScreenConfiguration(storeKey) {
        let focusInfo = new FocusInfo();
        focusInfo = this._storeQuerier.queryBySelector(this._relativeSelector(storeKey, getFocusState));
        return focusInfo;
    }

    private _getResearchScreenConfiguration(screenKey, is360View) {
        const screenConfiguration = new ResearchViewInfo();
        // keyword
        const keywordInfo = this._storeQuerier.queryBySelector(getDynamicResearchKeywordSelector2(screenKey));
        screenConfiguration.keywordInfo = keywordInfo;

        // refineby
        const refinebyInfo = new RefinebyInfo();
        refinebyInfo.category = this._storeQuerier.queryBySelector(getDynamicResearchRefinebyCategorySelector(screenKey));
        screenConfiguration.refineByInfo = refinebyInfo;

        // finder
        const finderInfo = this._storeQuerier.queryBySelector(getDynamicResearchFocusSelector2(screenKey));
        if (finderInfo && JSON.stringify(finderInfo) !== '{}') {
            screenConfiguration.finderInfo = {
                entityId: finderInfo.entity.id,
                status: finderInfo.status
            };
        }

        // relationshipTree
        const checkedKeysAfterFilter = [];
        if (!is360View) {
            const checkedKeys: string[] = this._storeQuerier.queryBySelector(getDynamicResearchRelationshipTreeCheckedKeysSelector2(screenKey));
            // filter checked keys; remove all leaf nodes if their parent node is checked;
            checkedKeys.sort();
            checkedKeys.forEach((key: string) => {
                let ignore = false;
                for (const key1 of checkedKeysAfterFilter) {
                    if (key.includes(key1)) {
                        ignore = true;
                        break;
                    }
                }
                if (!ignore) {
                    checkedKeysAfterFilter.push(key);
                }
            });
        }
        const nameFormat = this._storeQuerier.queryBySelector(getDynamicResearchRelationshipTreeNameFormatSelector2(screenKey));
        screenConfiguration.relationshipTreeInfo = {
            showAs: nameFormat.join(''),
            checkedNodeKeys: checkedKeysAfterFilter
        };

        // sort
        const sortInfo = this._storeQuerier.queryBySelector(getDynamicResearchSortInfoSelector2(screenKey));
        screenConfiguration.sortInfo = sortInfo;

        // layout
        const layoutInfo = this._storeQuerier.queryBySelector(getDynamicResearchLayoutSelector2(screenKey));
        screenConfiguration.layoutInfo = layoutInfo;

        // display
        const displayInfo = this._storeQuerier.queryBySelector(getDynamicResearchDisplaySelector2(screenKey));
        screenConfiguration.displayInfo = displayInfo;

        // filter
        screenConfiguration.filterInfo = new FilterInfo();
        const filterConfigs: FiltersConfig[] = this._storeQuerier.queryBySelector(getDynamicResearchFilterItemsSelector2(screenKey));
        screenConfiguration.filterInfo.isFilterOn = this._storeQuerier.queryBySelector(getDynamicResearchFilterEnableSelector2(screenKey));
        screenConfiguration.filterInfo.filterLogic = this._storeQuerier.queryBySelector(getDynamicResearchFilterLogicSelector2(screenKey));
        if (filterConfigs) {
            screenConfiguration.filterInfo.filterConfigs = filterConfigs.map(config => {
                const tempConfig = Object.assign({}, config);
                if (tempConfig.hasOwnProperty('operators')) {
                    delete tempConfig.operators;
                }
                if (tempConfig.hasOwnProperty('source')) {
                    delete tempConfig.source;
                }
                if (tempConfig.hasOwnProperty('relationshipTypeSource')) {
                    delete tempConfig.relationshipTypeSource;
                }
                return tempConfig;
            });
        }
        // grid info
        const gridInfo = this._storeQuerier.queryBySelector(getDynamicResearchGridStatusSelector2(screenKey));
        screenConfiguration.gridColumnInfo = gridInfo;
        screenConfiguration.gridGroupLayout = this._storeQuerier.queryBySelector(getDynamicResearchThreadListGridGropLayoutSelector(screenKey));

        return screenConfiguration;
    }

    private _getWorkflowScreenConfiguration(page) {
        const workflowId = this.getWorkflowIdByScreen(page);
        return { workflowId: workflowId };
    }

    private _relativeSelector(key, selector) {
        return (state) => {
            return selector(smartSelector(key, 'dashboardScreenReducer')(state));
        };
    }

    private _translateRelationshipTreeInfoV2(relTreeStatusV2) {
        const relationshipTreeInfo: RelationshipTreeInfo = new RelationshipTreeInfo();

        if (relTreeStatusV2.showName === true) {
            relationshipTreeInfo.showAs = 'name';
        } else {
            relationshipTreeInfo.showAs = 'shortName';
        }

        const checkedKeys = [];
        if (relTreeStatusV2 && relTreeStatusV2.checkedNodes && relTreeStatusV2.checkedNodes.length > 0) {
            const rootNode = relTreeStatusV2.checkedNodes[0];
            if (rootNode.checked) {
                checkedKeys.push(rootNode.id);
            } else {
                rootNode.children.forEach(nodeL1 => {
                    if (nodeL1.id === 'entities') {
                        if (nodeL1.checked) {
                            checkedKeys.push('ENTITY_GROUP');
                        } else {
                            nodeL1.children.forEach(nodeL2 => {
                                if (nodeL2.checked) {
                                    checkedKeys.push(`ENTITY_GROUP-${nodeL2.id}`);
                                } else {
                                    nodeL2.children.forEach(nodeL3 => {
                                        if (nodeL3.checked) {
                                            checkedKeys.push(`ENTITY_GROUP-${nodeL2.id}-${nodeL3.id}`);
                                        }
                                    });
                                }
                            });
                        }
                    } else if (nodeL1.id === 'contacts') {
                        if (nodeL1.checked) {
                            checkedKeys.push('CONTACTS_GROUP');
                        } else {
                            nodeL1.children.forEach(nodeL2 => {
                                if (nodeL2.checked) {
                                    checkedKeys.push(`CONTACTS_GROUP-${nodeL2.id}`);
                                } else {
                                    nodeL2.children.forEach(nodeL3 => {
                                        if (nodeL3.checked) {
                                            checkedKeys.push(`CONTACTS_GROUP-${nodeL2.id}-${nodeL3.id}`);
                                        }
                                    });
                                }
                            });
                        }
                    } else if (nodeL1.id === 'teams') {
                        if (nodeL1.checked) {
                            checkedKeys.push('TEAMS_GROUP');
                        } else {
                            nodeL1.children.forEach(nodeL2 => {
                                if (nodeL2.checked) {
                                    checkedKeys.push(`TEAMS_GROUP-${nodeL2.id}`);
                                }
                            });
                        }
                    } else if (nodeL1.id === 'teamMembers') {
                        if (nodeL1.checked) {
                            checkedKeys.push('TEAM_MEMBER_GROUP');
                        } else {
                            nodeL1.children.forEach(nodeL2 => {
                                if (nodeL2.checked) {
                                    checkedKeys.push(`TEAM_MEMBER_GROUP-${nodeL2.id}`);
                                }
                            });
                        }
                    }
                });
            }
        }

        relationshipTreeInfo.checkedNodeKeys = checkedKeys;

        return relationshipTreeInfo;
    }
}
