/**
 * created by Yu Zhang on 2/9/18.
 * Description:
 *
 * ------ maintenance history ------
 */

export class ProtocolConfig {
    public static supportEmail = '';
    public static prefix = 'https://';
}
