/**
 * Created by Marcus Zhao on 8/25/2022.
 * Description: SignatureActionTypes
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum SignatureActionTypes {
    REMOVE = 'REMOVE',
    CLEAR = 'CLEAR',
    GET_SIGNATURE = ' GET_SIGNATURE',
    GET_SIGNATURE_DATA_SUCCESS = 'GET_SIGNATURE_DATA_SUCCESS',
    GET_SIGNATURE_DATA_FAILED = 'GET_SIGNATURE_DATA_FAILED',
}


export class RemoveSignatureItem implements Action {
    readonly type = SignatureActionTypes.REMOVE;
    constructor(public payload: any) { }
}

export class ClearSignatureData implements Action {
    readonly type = SignatureActionTypes.CLEAR;
    constructor(public payload: any) { }
}

export class GetSignatureDataSuccess implements Action {
    readonly type = SignatureActionTypes.GET_SIGNATURE_DATA_SUCCESS;
    constructor(public payload: any) { }
}
export class GetSignatureDataFailed implements Action {
    readonly type = SignatureActionTypes.GET_SIGNATURE_DATA_FAILED;
    constructor(public payload: any) { }
}

export class GetSignatureData  implements Action {
    readonly type = SignatureActionTypes.GET_SIGNATURE;
    constructor(public payload: any) { }
}

export type SignatureActions = RemoveSignatureItem | ClearSignatureData |GetSignatureDataSuccess |GetSignatureData;
