<div class="container">
    <field-config-base #base [config]="config" [dataType]="dataType" isReadonlyVisible=true></field-config-base>
    <div tid="length_of_characters_allowed" class="tam-form-field one-field">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                Length of characters allowed
            </div>
        </div>
        <div class="tam-textbox field-control combine-control">
            <kendo-numerictextbox class="combine-child" [value]="config.minlength" [min]="0" [max]="config.maxlength"
                [step]="1" [autoCorrect]="true" [format]="'n0'" (valueChange)="valueChangeMinlength($event)">
            </kendo-numerictextbox>
            <div class="combine-dash">&nbsp;-&nbsp;</div>
            <kendo-numerictextbox class="combine-child" [value]="config.maxlength" [min]="config.minlength||1"
                [max]="128" [step]="1" [autoCorrect]="true" [format]="'n0'"
                (valueChange)="valueChangeMaxlength($event)">
            </kendo-numerictextbox>
        </div>
    </div>
    <div tid="default_value" class="tam-form-field one-field" [ngClass]="{'has-error':staticDefaultValueInvalid}">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.default_value' | stringLiterals}}
            </div>
        </div>
        <input class="tam-textbox field-control" type="text" [(ngModel)]="config.staticDefaultValue"
            (keyup)="valueChangeStaticDefaultValue($event)" />
        <div class="tam-form-help" *ngIf="staticDefaultValueInvalid">
            {{'general.text_character_allowed' | stringLiterals: config.minlength: config.maxlength}}
        </div>
    </div>
</div>