import { combineReducers, createSelector } from '@ngrx/store';

import { ResearchScreenActions, ResearchScreenActionTypes } from '../actions/research-screen.actions';
import { DataList } from '../../tamalelibs/models/data-list.model';
import { Thread } from '../../tamalelibs/models/thread.model';
import { FiltersConfig } from '../../tamalelibs/components/filter-config';
import { ALL_VALUE } from '../../tamalelibs/constants/business.constants';
import { AppState } from '..';
import { ThreadEntry } from '../../tamalelibs/models/thread-entry.model';

export function threadListItemsReducer(state: DataList<ThreadEntry> = new DataList<ThreadEntry>(), action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.GROUP_LOAD_SUCCESS:
            const tempArr = [];
            const threadEntries = new DataList<ThreadEntry>();
            if (action.payload.length > 0) {
                state.forEach((item) => {
                    const index = action.payload.findIndex(data => data.id === item.id);
                    if (index === -1) {
                        tempArr.push(item);
                    }
                });
                [].concat(tempArr, action.payload).forEach(item => {
                    threadEntries.push(item);
                });
            }
            if (action.payload.totalCount !== null) {
                threadEntries.hasNext = action.payload.hasNext;
                threadEntries.totalCount = action.payload.totalCount;
            } else {
                threadEntries.hasNext = state.hasNext;
                threadEntries.totalCount = state.totalCount;
            }
            return threadEntries;
        case ResearchScreenActionTypes.RELOAD_SUCCESS:
            return action.payload;
        case ResearchScreenActionTypes.LOAD_MORE_SUCCESS: {
            // below logic is used to prevent duplicated data in the list
            // the basic logic is load previous page with the next page and doing data comparaion
            if (action.payload.pageIndex === 2) {
                return action.payload.threadEntries;
            } else {
                // tslint:disable-next-line: no-shadowed-variable
                const threadEntries = Object.assign(new DataList<ThreadEntry>(), state);
                // remove the previous page
                threadEntries.splice((action.payload.pageIndex - 2) * action.payload.pageSize, action.payload.pageSize);

                // generate set from existing list
                const threadMap = new Map();
                threadEntries.forEach((threadEntry, index) => {
                    threadMap.set(threadEntry.id, index);
                });

                action.payload.threadEntries.forEach((threadEntry: ThreadEntry) => {
                    if (threadMap.has(threadEntry.id)) {
                        return;
                    }
                    threadEntries.push(threadEntry);
                });

                threadEntries.totalCount = action.payload.threadEntries.totalCount;
                threadEntries.hasNext = action.payload.threadEntries.hasNext;

                return threadEntries;
            }
        }
        case ResearchScreenActionTypes.REMOVE_ATTACHMENT:
            const threadId = action.payload.threadId;
            const attachInfo = action.payload.attachInfo;

            const length = state.length;
            for (let i = 0; i < length; i++) {
                if (state[i].id === threadId) {
                    const tmpThread = Object.assign(new Thread(), state[i]);
                    tmpThread.notes.forEach(note => {
                        if (note.attachments.has(attachInfo.id)) {
                            note.attachments.delete(attachInfo.id);
                        }
                    });
                    tmpThread.attachments.delete(attachInfo.id);
                    state[i] = tmpThread;
                    break;
                }
            }
            return state;
        case ResearchScreenActionTypes.REMOVE_SIDENOTE:
            const idThread = action.payload.threadId;

            for (let i = 0; i < state.length; i++) {
                if (state[i].id === idThread) {
                    const tmpThread = Object.assign(new Thread(), state[i]);
                    state[i] = tmpThread;
                    break;
                }
            }
            return state;
        case ResearchScreenActionTypes.CLEAR_THREAD_LIST:
            return [];
        default:
            return state;
    }
}

export function threadListErrorReducer(state: any, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.RELOAD_FAILED:
        case ResearchScreenActionTypes.LOAD_MORE_FAILED:
            return action.payload;
        default:
            return state;
    }
}

export function threadListGroupByNoteGroupReducer(state: any, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SET_GROUP_BY_NOTE_GROUP:
            return action.payload;
        default:
            return state;
    }
}

export function threadListGroupByNoteItemsReducer(state: any, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SET_GROUP_BY_NOTE_ITEMS:
            let newState;
            if (!state) {
                newState = {
                    requestIds: [action.payload.requestId]
                };
            } else {
                newState = Object.assign({}, state);
                newState.requestIds.push(action.payload.requestId);
            }
            if (newState[action.payload.groupId]) {
                const oldList = newState[action.payload.groupId];
                const newlist = action.payload.threadEntryList;
                const tempList = [];
                oldList.forEach((item) => {
                    if (newlist.findIndex(i => i.id === item.id) === -1) {
                        tempList.push(item);
                    }
                });
                tempList.push(...newlist);
                newState[action.payload.groupId] = tempList;
            } else {
                newState[action.payload.groupId] = action.payload.threadEntryList;
            }
            return newState;
        case ResearchScreenActionTypes.CLEAR_GROUP_BY_NOTE_ITEMS:
            return null;
        default:
            return state;
    }
}

export function threadListGroupByNoteLayoutReducer(state = {}, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_OTHERS:
            return action.payload.gridGroupLayout;
        case ResearchScreenActionTypes.SET_GRIDG_ROW_MODEL_TYPE:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}


export function threadListSelectedIndexReducer(state = null, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.RELOAD_SUCCESS:
            if (action.payload && action.payload.length > 0) {
                return {
                    index: 0,
                    id: action.payload[0].id,
                };
            } else {
                return null;
            }
        case ResearchScreenActionTypes.SET_SELECTED_INDEX:
            return action.payload;
        case ResearchScreenActionTypes.CLEAR_THREAD_LIST:
            return null;
        default:
            return state;
    }
}

export function threadListMatchedAttachmentsReducer(state: Set<string> = new Set(), action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SET_MATCHED_ATTACHMENTS:
            return action.payload;
        case ResearchScreenActionTypes.ADD_MATCHED_ATTACHMENTS:
            const newState = Object.assign(new Set(), state);
            action.payload.forEach(id => {
                newState.add(id);
            });
            return newState;
        case ResearchScreenActionTypes.CLEAR_THREAD_LIST:
            return new Set();
        default:
            return state;
    }
}

export function threadListCurrentPageIndexReducer(state = 0, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.RELOAD:
        case ResearchScreenActionTypes.LOAD_MORE:
            return action.payload.pageIndex;
        case ResearchScreenActionTypes.CLEAR_THREAD_LIST:
            return 1;
        case ResearchScreenActionTypes.RESET_THREAD_LIST_PAGE:
            return action.payload;
        default:
            return state;
    }
}

const threadListReducer = combineReducers({
    currentPageIndex: threadListCurrentPageIndexReducer,
    items: threadListItemsReducer,
    matchedAttachmentSet: threadListMatchedAttachmentsReducer,
    selectedIndex: threadListSelectedIndexReducer,
    groupByNoteGroup: threadListGroupByNoteGroupReducer,
    groupByNoteItems: threadListGroupByNoteItemsReducer,
    gridGroupLayout: threadListGroupByNoteLayoutReducer,
    error: threadListErrorReducer
});

export function getThreadListReducer(state, action) {
    return threadListReducer(state, action);
}

export function aggridColumnStatusReducer(state: any = {}, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_OTHERS:
            return action.payload.gridStatus;
        case ResearchScreenActionTypes.SET_GRID_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export function fitlerEnableReducer(state: boolean = false, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_FILTER:
            if (action.payload && action.payload.isFilterOn) {
                return action.payload.isFilterOn;
            } else {
                return null;
            }
        case ResearchScreenActionTypes.SET_FILTER_ENABLE:
            return action.payload;
        default:
            return state;
    }
}

export function fitlerLogicReducer(state: string = ALL_VALUE, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_FILTER:
            if (action.payload && action.payload.filterLogic) {
                return action.payload.filterLogic;
            } else {
                return null;
            }
        case ResearchScreenActionTypes.SET_FILTER_LOGIC:
            return action.payload;
        default:
            return state;
    }
}
/**
 *
 * Sets filterList using new payload or adds filterList in payload to original filterList.
 * @export
 * @param {Array<FiltersConfig>} [state=[]]
 * @param {ResearchViewActions} action
 * @returns filterList: Array<FiltersConfig>
 */
export function filterListReducer(state: Array<FiltersConfig> = [], action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_FILTER:
            if (action.payload && action.payload.filterConfigs) {
                return Object.assign([], action.payload.filterConfigs);
            } else {
                return [];
            }
        case ResearchScreenActionTypes.SET_FILTER_LIST:
            return Object.assign([], action.payload);
        case ResearchScreenActionTypes.ADD_FILTER_LIST:
            return state.concat(action.payload);
        default:
            return state;
    }
}

const filterReducer = combineReducers({
    filterList: filterListReducer,
    fitlerEnable: fitlerEnableReducer,
    filterLogic: fitlerLogicReducer
});

export function getFilterReducer(state, action) {
    return filterReducer(state, action);
}

export function focusReducer(state: any = {}, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_FOCUS_AND_RELATIONSHIP:
            return Object.assign({}, action.payload.focus);
        case ResearchScreenActionTypes.INITIALIZE_FOCUS:
            return Object.assign({}, action.payload);
        case ResearchScreenActionTypes.SET_FOCUS_STATUS:
            return {
                ...state,
                status: action.payload
            };
        default:
            return state;
    }
}

export function keywordReducer(state: string = '', action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_OTHERS:
            return action.payload.keyword;
        case ResearchScreenActionTypes.SET_KEY_WORD:
            return action.payload;
        default:
            return state;
    }
}

export function layoutReducer(state: any = { layoutMode: 1, activeTab: 'REFINE_BY', collapsed: false }, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_OTHERS:
            return action.payload.layout;
        case ResearchScreenActionTypes.INITIALIZE_LAYOUT:
            return action.payload;
        case ResearchScreenActionTypes.SET_LAYOUT_MODE: {
            return {
                ...state,
                layoutMode: action.payload
            };
        }
        case ResearchScreenActionTypes.SET_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: action.payload
            };
        }
        case ResearchScreenActionTypes.SET_LAYOUT_COLLAPSE: {
            return {
                ...state,
                collapsed: action.payload
            };
        }
        case ResearchScreenActionTypes.HIDE_ENTITY_FOCUS: {
            return {
                ...state,
                isEntityFocusHidden: action.payload
            };
        }
        case ResearchScreenActionTypes.HIDE_RELATIONSHIP_TREE: {
            return {
                ...state,
                isRelationshipTreeHidden: action.payload
            };
        }

        default:
            return state;
    }
}

export function displayReducer(state: any = {}, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_OTHERS:
            return action.payload.display;
        case ResearchScreenActionTypes.SET_DISPLAY_INFO:
            return action.payload;
        default:
            return state;
    }
}

export function queryInfoReducer(state: any = {}, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SET_QUERY_INFO:
            return action.payload;
        default:
            return state;
    }
}

export function relationshipReducer(state: any = {}, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_FOCUS_AND_RELATIONSHIP:
            return {
                ...state,
                nodes: Object.assign([], action.payload.relationship.nodes),
                expandedKeys: Object.assign([], action.payload.relationship.expandedKeys),
                checkedKeys: Object.assign([], action.payload.relationship.checkedKeys),
                showAs: action.payload.relationship.showAs
            };
        case ResearchScreenActionTypes.SET_RELATIONSHIP:
            return {
                ...state,
                nodes: Object.assign([], action.payload.nodes),
                expandedKeys: Object.assign([], action.payload.expandedKeys),
                checkedKeys: Object.assign([], action.payload.checkedKeys),
                showAs: action.payload.showAs
            };
        case ResearchScreenActionTypes.SET_RELATIONSHIP_NODES:
            return {
                ...state,
                nodes: Object.assign([], action.payload),
            };
        case ResearchScreenActionTypes.SET_RELATIONSHIP_EXPANDED_KEYS:
            return {
                ...state,
                expandedKeys: Object.assign([], action.payload),
            };
        case ResearchScreenActionTypes.SET_RELATIONSHIP_CHECKED_KEYS:
            return {
                ...state,
                checkedKeys: Object.assign([], action.payload),
            };
        case ResearchScreenActionTypes.SET_RELATIONSHIP_SHOW_NAME_FORMAT:
            return {
                ...state,
                showAs: action.payload,
            };
        default:
            return state;
    }
}

export function sortInfoReducer(state: any = { sortBy: 'displayDate', sortOrder: 'desc' }, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_OTHERS:
            return action.payload.sort;
        case ResearchScreenActionTypes.SET_SORT_INFO:
            return action.payload;
        default:
            return state;
    }
}

// refineby related
export function refinebyDataReducer(state: Map<string, Array<any>>, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.LOAD_REFINEBY_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function refinebySelectedValueReducer(state: Map<string, Array<any>>, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SET_REFINEBY_SELECTED_VALUE:
            return action.payload.selectedValue;
        default:
            return state;
    }
}

export function refinebyStatusReducer(state: Map<string, Array<any>>, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SET_REFINEBY_CATEGORY_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export function refinebyCategoryReducer(state: Array<string>, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_OTHERS:
            if (action.payload && action.payload.refineBy) {
                return action.payload.refineBy.category;
            } else {
                return null;
            }
        case ResearchScreenActionTypes.SET_REFINEBY_CATEGORY_ORDER:
            return action.payload;
        case ResearchScreenActionTypes.SET_REFINEBY_CATEGORY:
            return action.payload;
        default:
            return state;
    }
}

export function refinebyCategoryFilterReducer(state: Object, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SET_REFINEBY_SELECTED_VALUE:
            return action.payload.categoryFilter;
        default:
            return state;
    }
}

const refinebyReducer = combineReducers({
    data: refinebyDataReducer,
    selectedValue: refinebySelectedValueReducer,
    status: refinebyStatusReducer,
    category: refinebyCategoryReducer,
    categoryFilter: refinebyCategoryFilterReducer,
});

export function getRefinebyReducer(state, action) {
    return refinebyReducer(state, action);
}

// research screen change status
export const researchChangeStatusSelector = (state: AppState) => state.researchStatus;

export function researchChangeStatusReducer(state: object, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SET_HOME_SCREEN_CHANGE_STATUS:
        case ResearchScreenActionTypes.SET_SAVE_SCREEN_CHANGE_STATUS:
            return action;
        default:
            return state;
    }
}

export function loadingReducer(state: number = 3, action: ResearchScreenActions) {
    switch (action.type) {
        case ResearchScreenActionTypes.SCREEN_INIT_OTHERS:
        case ResearchScreenActionTypes.SCREEN_INIT_FILTER:
        case ResearchScreenActionTypes.SCREEN_INIT_FOCUS_AND_RELATIONSHIP:
            return --state;
        case ResearchScreenActionTypes.INITIALIZE_ALL_FAILED:
            return -1;
        default:
            return state;
    }
}

// const homePageSelector = (state: any) => state.dynamicPages;
// const homeScreenDataSelector = createSelector(homePageSelector, (state: any) => state.screenDataList);
const researchRootSelector = (state: any) => state.dynamicPages;

export const getDynamicResearchLayoutSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => state.layout);
};

export const getDynamicResearchDisplaySelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => state.display);
};

export const getDynamicResearchFocusSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => Object.assign({}, state.focus));
};

export const getDynamicResearchKeywordSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => state.keyword);
};

export const getDynamicResearchSortInfoSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => state.sortInfo);
};

export const getDynamicResearchQueryInfoSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => state.queryInfo);
};

export const getDynamicResearchRelationshipSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => Object.assign({}, state.relationship));
};

export const getDynamicResearchRelationshipTreeNodesSelector2 = (key: string) => {
    const selector1 = getDynamicResearchRelationshipSelector2(key);
    return createSelector(selector1, (state: any) => Object.assign([], state.nodes));
};

export const getDynamicResearchRelationshipTreeCheckedKeysSelector2 = (key: string) => {
    const selector1 = getDynamicResearchRelationshipSelector2(key);
    return createSelector(selector1, (state: any) => Object.assign([], state.checkedKeys));
};

export const getDynamicResearchRelationshipTreeNameFormatSelector2 = (key: string) => {
    const selector1 = getDynamicResearchRelationshipSelector2(key);
    return createSelector(selector1, (state: any) => Object.assign([], state.showAs));
};

export const getDynamicResearchGridStatusSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => state.gridColumnStatus);
};

export const getDynamicResearchThreadListSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => state.threadList);
};

export const getDynamicResearchThreadListItemSelector2 = (key: string) => {
    const selector1 = getDynamicResearchThreadListSelector2(key);
    return createSelector(selector1, (state: any) => state ? state.items : []);
};

export const getDynamicResearchThreadListGroupItemSelector = (key: string) => {
    const selector1 = getDynamicResearchThreadListSelector2(key);
    return createSelector(selector1, (state: any) => state ? state.groupByNoteGroup : []);
};

export const getDynamicResearchThreadListGroupItemsSelector = (key: string) => {
    const selector1 = getDynamicResearchThreadListSelector2(key);
    return createSelector(selector1, (state: any) => state ? state.groupByNoteItems : []);
};

export const getDynamicResearchThreadListGridGropLayoutSelector = (key: string) => {
    const selector1 = getDynamicResearchThreadListSelector2(key);
    return createSelector(selector1, (state: any) => state ? state.gridGroupLayout : []);
};

export const getDynamicResearchThreadListtMatchedAttachmentSelector2 = (key: string) => {
    const selector1 = getDynamicResearchThreadListSelector2(key);
    return createSelector(selector1, (state: any) => state.matchedAttachmentSet);
};

export const getDynamicResearchThreadListSelectedIndexSelector = (key: string) => {
    const selector1 = getDynamicResearchThreadListSelector2(key);
    return createSelector(selector1, (state: any) => state ? state.selectedIndex : 0);
};

export const getDynamicResearchActiveThreadSelector2 = (key: string) => {
    const selector1 = getDynamicResearchThreadListItemSelector2(key);
    const selector2 = getDynamicResearchThreadListSelectedIndexSelector(key);

    return createSelector(selector1, selector2, (state1: any, state2: any) => {
        if (state1 && state1.length > 0 && state2) {
            return state1[state2.index];
        } else {
            return null;
        }
    });
};

export const getDynamicResearchFilterItemsSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => state && state.filter ? state.filter.filterList : null);
};

export const getDynamicResearchFilterEnableSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => state && state.filter ? state.filter.fitlerEnable : null);
};

export const getDynamicResearchFilterLogicSelector2 = (key: string) => {
    const selector1 = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector1, (state: any) => state && state.filter ? state.filter.filterLogic : null);
};

// refineby related
export const getDynamicResearchRefinebySelector = (key: string) => {
    const selector = createSelector(researchRootSelector, (state: any) => state[key] ? state[key] : {});
    return createSelector(selector, (state: any) => state.refineby);
};

export const getDynamicResearchRefinebyDataSelector = (key: string) => {
    const selector = getDynamicResearchRefinebySelector(key);
    return createSelector(selector, (state: any) => state ? state.data : {});
};

export const getDynamicResearchRefinebyCategorySelector = (key: string) => {
    const selector = getDynamicResearchRefinebySelector(key);
    return createSelector(selector, (state: any) => state ? state.category : null);
};

export const getDynamicResearchRefinebySelectedValueSelector = (key: string) => {
    const selector = getDynamicResearchRefinebySelector(key);
    return createSelector(selector, (state: any) => state ? state.selectedValue : null);
};

export const getDynamicResearchRefinebyStatusSelector = (key: string) => {
    const selector = getDynamicResearchRefinebySelector(key);
    return createSelector(selector, (state: any) => state ? state.status : null);
};

export const getDynamicResearchRefinebyCategoryFilterSelector = (key: string) => {
    const selector = getDynamicResearchRefinebySelector(key);
    return createSelector(selector, (state: any) => state ? state.categoryFilter : null);
};

export const researchScreenReducer = combineReducers({
    focus: focusReducer,
    keyword: keywordReducer,
    layout: layoutReducer,
    display: displayReducer,
    queryInfo: queryInfoReducer,
    relationship: relationshipReducer,
    sortInfo: sortInfoReducer,
    threadList: getThreadListReducer,
    filter: getFilterReducer,
    gridColumnStatus: aggridColumnStatusReducer,
    refineby: getRefinebyReducer,
    loading: loadingReducer
});
