import { Directive, OnInit, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';

@Directive({
    selector: '[tamDropDownOutside]'
})
export class DropDownOutsideDirective implements OnInit, OnDestroy {

    // tslint:disable-next-line: no-output-rename
    @Output('clickOutside') clickOutside: EventEmitter<Object>;
    private outsideClick$: Subscription;

    constructor(private _elRef: ElementRef) {
        this.clickOutside = new EventEmitter();
    }

    isChildNode(parent, child) {
        return parent.contains(child);
    }

    onClick(event: MouseEvent) {
        if (event instanceof MouseEvent) {
            if (this.isChildNode(this._elRef.nativeElement, event.target) === true) {
                this.clickOutside.emit({
                    target: (event.target || null),
                    value: false
                });
            } else {
                this.clickOutside.emit({
                    target: (event.target || null),
                    value: true
                });
            }
        }
    }

    ngOnInit() {
        this.outsideClick$ = fromEvent(document, 'click').subscribe((event: MouseEvent) => {
            this.onClick(event);
        });
    }

    ngOnDestroy() {
        if (this.outsideClick$) {
            this.outsideClick$.unsubscribe();
        }
    }
}
