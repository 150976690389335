
/**
 * Created by Alex Xia on 5/29/23.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../models/app-config.model';
import { TransportService } from './transport.service';
import { NotificationModel } from '../models/notification.model';

@Injectable()
export class NotificationService {

    constructor(private _transportService: TransportService) { }

    // change notification status
    changeNotificationStatus(notificationId, status): Observable<any> {
        const url = `${AppConfig.notificationEndpoint}${notificationId}/status`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('active', status);
        return this._transportService.put(url, formData.toString(), options);
    }

    // whether notification name is duplicated or not
    checkNotificationName(id, name): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.notificationEndpoint}checkname`;
        const params = {
            outputformat: 'json',
            name: name
        };
        if (id) {
            params['id'] = id;
        }
        const options = {
            header: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    convertRichclientNotifications(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.notificationEndpoint}conversion`;
        const options = {
            headers: headers,
        };

        return this._transportService.post(url, null, options);
    }

    createNotifications(data: NotificationModel): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.notificationEndpoint}`;
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('name', data.name);
        formData.set('description', data.description);
        formData.set('reminderType', data.reminderType);
        formData.set('metadataType', data.metadataType);
        formData.set('active', String(data.active));
        formData.set('config', JSON.stringify(data.configuration));
        return this._transportService.post(url, formData.toString(), options);
    }

    deleteNotifications(id: string) {
        const url = `${AppConfig.notificationEndpoint}${id}`;
        return this._transportService.delete(url);
    }

    /**
     * download sample script from server.
     * @param id: notification id
     * @returns
     */
    downloadSampleScript(id: string) {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };

        const url = `${AppConfig.notificationEndpoint}${id}/sample`;
        const options = {
            headers: headers,
            responseType: 'text',
            observe: 'response' // It's important if you want to get the other information from response besides body.
        };
        return this._transportService.get(url, options);
    }

    getNotifications(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.notificationEndpoint}`;
        const params = {
            expand: 'child-entity;entity&fields=child-entity;',
            outputformat: 'json'
        };
        const options = {
            header: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    updateNotifications(data: NotificationModel) {
        const url = `${AppConfig.notificationEndpoint}${data.id}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('name', data.name);
        formData.set('description', data.description);
        formData.set('reminderType', data.reminderType);
        formData.set('metadataType', data.metadataType);
        formData.set('active', String(data.active));
        formData.set('config', JSON.stringify(data.configuration));
        return this._transportService.put(url, formData.toString(), options);
    }
}
