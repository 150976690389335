import { ElementRef } from '@angular/core';

export class TamUploadUIFile {
    public id: string;
    public dzid: string;
    public name: string;
    public progress: string;
    public serverName: string;
    public isNew: boolean;
    public isCompleted: boolean;
    public isBlastEmail?: boolean;
}
/**
 * @export
 * @interface TamUploadConfig
 * @param   acceptedFiles           checks the file's mime type or extension
 * @param   url                     upload target
 * @param   paramName               form attribute name;
 * @param   autoQueue               make sure the files aren't queued until manually added
 * @param   dictMaxFilesExceeded    overwrite dict..Option in case that dropzone.js changes it.
 * @param   onError                 on upload error
 * @param   onSending               on upload started
 * @param   onSuccess               on upload finished and succeed
 * @param   onRemove                on user remove file, return true remove, false do not remove
 * @param   onProgress              on upload ended in error
 * @param   onAddedfile             When a file is added to the dropzone list
 * @param   onStatusChange          renaming x new file and uploading y new file => onStatusChange(x+y). when each of the rename and uploading finnished => onStatusChange(-1)
 * @param    onNameChange           validation on file name, return true keep current name, return false revert to previous name
 * @param    isPreviewable          a flag indicating whether files are able to be previewed. Optional argument, only explicit boolean value of false indicates the preview is disabled, otherwise, true or null would be treated as enabled.
 * @param    isDownloadable         a flag indicating whether files are able to be downloaded.Optional argument, only explicit boolean value of false indicates the download is disabled, otherwise, true or null would be treated as enabled.
 */
export interface TamUploadConfig {
    acceptedFiles?: any;
    files: TamUploadUIFile[];
    threadFileNameCount: Object;
    url: string;
    maxFiles: number;
    maxFilesize: number;
    paramName: string;
    parallelUploads: number;
    autoQueue: boolean;
    autoProcessQueue: boolean;
    dictMaxFilesExceeded: string;
    dictFileTooBig: string;
    readOnly: boolean;
    upload: any;
    dropzone?: ElementRef;
    onAddedfile: any;
    onError: any;
    onSending: any;
    onSuccess: any;
    onRemove: any;
    onProgress: any;
    onStatusChange: any;
    onNameChange: any;
    height?: number;
    isPreviewable?: boolean;
    isDownloadable?: boolean;
}
