
/**
 * Created by Alex Xia on 11/17/2020.
 * Description:
 * implement interface of dashboard tiles for tam dashboard map tile
 * ------ maintenance history ------
 * update by Marcus Zhao 08/29/2021
 *   1.remove corperate type.
 *   2.entity address use webAddress.
 *   3.use editEntityPatch for update entity address.
 * update by Marcus Zhao 13/04/2022
 *   add _judgeNearbyData() for get search nearby data.
 *   formart entity /contact for mapTileData.
 */
import { DashboardTileAction, TamDashboardBaseConfiguration } from '../tam-dashboard-tile.base.config';
import { Subject, Subscription, of as observableOf, Observable, of } from 'rxjs';
import { TamDashboardActions, TamDashboardEvents } from '../tam-dashboard.model';
import { catchError, filter, map, take, } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../redux';
import { DashboardActionTypes } from '../../../redux/actions/dashboard.actions';
import { DashboardTileService } from '../dashboard-tile.service';
import { MapContactModel } from './tam-dashboard-map-autocomplete/tam-dashboard-map-autocomplete.model';
import { ContactViewModel } from '../../../tamalelibs/components/contact.view-model';
import { EntityService } from '../../../tamalelibs/services/entity.service';
import { EntityViewModelForWeb } from '../../entity-dialog/entity-view-web.model';
import { Entity } from '../../../tamalelibs/models/entity.model';
import { Contact } from '../../../tamalelibs/models/contact.model';
import { businessConstants } from '../../../constants/business.constants';
import { AddressDetail } from '../../../tamalelibs/models/job-function';
import { ContactService } from '../../../tamalelibs/services/contact.service';
import { ALL_VALUE } from '../../../tamalelibs/constants/business.constants';
import * as _lodash from 'lodash';
import { MapFilterContent } from '../../../tamalelibs/models/map-tile.model';
import { ArrayHelperService } from '../../../tamalelibs/services/array-helper.service';

export class TamDashboardTileData {
    constructor(public latitude: number, public longitude: number, public entityId?: string, public entityName?: string, public entityType?: string, public detail?: any,
        public address?: string, public addressType?: string, public eventProperties?: any, public threadId?: string, public isAddStop = false,
        public iconOptions = {
            'labelOrigin': businessConstants.lableLarge,
            'url': 'icon'
        },
        public labelOptions = {
            'color': '#FFFFFF',
            'fontSize': '12px',
            'text': '',
            'font-family': businessConstants.fontFamily.textLarge,
        },
        public duplicateAddress = {}) {
    }
}

export class NearbyData {
    constructor(public latitude: number, public longitude: number, public searchText: string, public radius: number, public type?: string[]) { }
}

export class TamDashboardMapTileConfiguration implements TamDashboardBaseConfiguration {

    public data: TamDashboardTileData[];
    public id: string;
    public initialized: boolean;
    public name: string;
    public readonly: boolean;
    public seriesClickEventType: string;
    public currentMapContactModel: MapContactModel;

    public actionSubject$: Subject<any>;
    public feedbackSubject$: Subject<any>;
    public subscriptions: Subscription[] = [];

    private _focusedEntityIds;
    private _labelSettings: any;
    private _tileConfig: any;
    /** if refresh should close panel  */
    private _isRefresh = false;

    constructor(
        private _store?: Store<AppState>,
        private _dashboardTileService?: DashboardTileService,
        private _contactViewModel?: ContactViewModel,
        private _entityService?: EntityService,
        private _entityViewModel?: EntityViewModelForWeb,
        private _contactService?: ContactService,
    ) {
        this.initialized = false;
    }

    destroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            if (subscription && !subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }

    getLocation(mapContactModel: MapContactModel, location: Location, isFromShowEventOnMapTile = false) {
        if (location['lat'] === 0) {
            // can not get latitude and longitude directly, need to get predictions,
            // and save to store with the latitude and lngtitude of the first prediction
            this.currentMapContactModel = mapContactModel;
            this._dashboardTileService.getMapSearchPredictions(mapContactModel.selectedAddress, this, this.getPredictions);
        } else if (mapContactModel.contact) {
            // can get the latitude and logitude with the local address
            // need to save to server
            this._setMapData(location['lat'], location['lng'], true, mapContactModel, null, isFromShowEventOnMapTile);
        } else {
            // get latitude and longitude via google api, do not need to save to server
            this._setMapData(location['lat'], location['lng'], false, null, mapContactModel.selectedAddress, isFromShowEventOnMapTile);
        }
    }

    /**
     *  get predictions via google api
     * @param predictions call back func
     */
    getPredictions(predictions) {
        if (predictions && predictions.length > 0) {
            this._dashboardTileService.getMapSearchGeocodeAddress(predictions[0].description)
                .subscribe((location: Location) => {
                    this._setMapData(location['lat'], location['lng'], true, this.currentMapContactModel, null);
                }
                );
        } else {
            this.data = null;
            this.setTileData(this.data);
        }
    }

    init(id: string, readonly: boolean, name: string, actionSubject$: Subject<any>, feedbackSubject$?: Subject<any>): boolean {
        if (this.initialized === false) {
            this.id = id;
            this.readonly = readonly;
            this.name = name;
            this.actionSubject$ = actionSubject$;
            this.feedbackSubject$ = feedbackSubject$;
            this.subscriptions.push(
                this.actionSubject$.pipe(
                    filter((action: any) => action && action.id === this.id)
                ).subscribe((action) => this._actionHandler(action)),
                // after support maptile print will open this code.
                // this.feedbackSubject$.pipe(
                // filter((action: any) => action && action.id === this.id)
                // ).subscribe((event) => this._feedbackHandler(event)),
            );
            this.initialized = true;
            return true;
        } else {
            return false;
        }
    }

    /**
   * Get latitude and longitude from each socused entities if it's existed.
   * Get latitude and longitude with parameter webaddress via google api, save them to server.
   */
    loadData({ tileConfig, focusedEntityIds, useConfig, isPrint = false }): Observable<any> {
        return new Observable(subscriber => {
            const data: Array<TamDashboardTileData> = [];
            if (focusedEntityIds) {
                if (tileConfig && tileConfig.id && isPrint) {
                    this.id = tileConfig.id;
                }
                // set map tile loading status
                if (this.actionSubject$) {
                    this.actionSubject$.next({ id: this.id, type: TamDashboardActions.SET_MAP_LOADING_STATUS });
                }

                const focusedEntityIdArray: Array<string> = Array.from(new Set(focusedEntityIds.split(',').filter(item => item !== '')));
                let count = 0;
                // TODO need use getEntityListByIdsQuick for get entity list.Marcus
                if (focusedEntityIdArray && focusedEntityIdArray.length > 0) {
                    focusedEntityIdArray.forEach(focusedEntityId => {
                        this._entityService.getEntityDetailWithoutAdhoc(focusedEntityId).pipe(
                            // take(1),
                            catchError((err) => {
                                return observableOf(null);
                            })
                        ).subscribe((entity => {
                            count++;
                            if (entity && entity['entity-type'] && entity['entity-type']['data'] && entity['entity-type']['data']['name']) {
                                const entityType = entity['entity-type']['data']['name'];
                                if (entityType === 'Contact') {
                                    // conatct type
                                    const contact = Contact.parse(entity);
                                    const contact_webAddress = contact['webAddress'];

                                    if (contact && contact_webAddress && contact_webAddress.length > 0) {
                                        // const contact_latitude = this._dashboardTileService.getCoordinateByAddress(contact_webAddress, 'latitude');
                                        // const contact_longitude = this._dashboardTileService.getCoordinateByAddress(contact_webAddress, 'longitude');
                                        const address = this._dashboardTileService.getMatchAddressByWebAddress(contact.webAddress);

                                        if (address && address.latitude && address.longitude) {
                                            data.push(new TamDashboardTileData(address.latitude, address.longitude, contact['id'], contact['name'], contact['type']['name'], contact, address['fullAddress'], address.name)
                                            );
                                        } else {
                                            let contact_address = '';
                                            let contact_address_type = '';
                                            let isCallGoogle = false;
                                            const contact_workAddress: any = this._dashboardTileService.getWorkAddress(contact_webAddress);
                                            const contact_homeAddress: any = this._dashboardTileService.getHomeAddress(contact_webAddress);
                                            const otherAddressInfo: any = this._dashboardTileService.getOtherAddress(contact_webAddress);

                                            // use home address instead if the work address is none
                                            // use other address instead if home address and work address are both empty
                                            if (contact_workAddress) {
                                                contact_address = contact_workAddress.webAddress_work;
                                                contact_address_type = 'Work';
                                                isCallGoogle = contact_workAddress.isCallGoogle;
                                            } else if (contact_homeAddress) {
                                                contact_address = contact_homeAddress.webAddress_home;
                                                isCallGoogle = contact_homeAddress.isCallGoogle;
                                                contact_address_type = 'Home';
                                            } else if (otherAddressInfo) {
                                                contact_address = otherAddressInfo.webAddress_other;
                                                contact_address_type = otherAddressInfo.name;
                                                isCallGoogle = otherAddressInfo.isCallGoogle;
                                            }

                                            if (contact_address && !isCallGoogle) {
                                                this._dashboardTileService.getMapSearchGeocodeAddress(contact_address)
                                                    .subscribe((location: Location) => {
                                                        if (location['lat'] && location['lng']) {
                                                            data.push(new TamDashboardTileData(location['lat'], location['lng'], contact['id'], contact['name'], contact['type']['name'], contact, contact_address, contact_address_type));

                                                            // update to server
                                                            for (const item of contact_webAddress) {
                                                                if (contact_workAddress && item['name'] === 'Work') {
                                                                    item['latitude'] = location['lat'];
                                                                    item['longitude'] = location['lng'];
                                                                    break;
                                                                } else if (contact_homeAddress && item['name'] === 'Home') {
                                                                    item['latitude'] = location['lat'];
                                                                    item['longitude'] = location['lng'];
                                                                    break;
                                                                } else if (otherAddressInfo && item['name'] === otherAddressInfo['name']) {
                                                                    item['latitude'] = location['lat'];
                                                                    item['longitude'] = location['lng'];
                                                                    break;
                                                                }
                                                            }

                                                            this._contactViewModel.editContact(contact);

                                                            this._saveTileData(this.id, data);
                                                            subscriber.next(data);
                                                            subscriber.complete();

                                                        }
                                                    });
                                            }

                                        }
                                    }
                                } else {
                                    entity = Entity.parse(entity);
                                    // new logic for webAddress
                                    if (entity && entity.webAddress && entity.webAddress.length > 0) {
                                        const address: Array<AddressDetail> = entity.webAddress;
                                        if (address[0]) {
                                            if (address[0].latitude && address[0].longitude) {
                                                // entity type, had got latitude and longitude from server
                                                data.push(new TamDashboardTileData(address[0].latitude, address[0].longitude, entity.id, entity.name, businessConstants.addressType.entity, entity, this._dashboardTileService.getAddress(address[0]), address[0].name));
                                            } else {
                                                const stringAddress = this._dashboardTileService.getAddress(address[0]);
                                                this._dashboardTileService.getMapSearchGeocodeAddress(stringAddress)
                                                    .subscribe((location: Location) => {
                                                        if (location['lat'] && location['lng']) {
                                                            data.push(new TamDashboardTileData(location['lat'], location['lng'], entity['id'], entity['name'], entity['type']['name'], entity));
                                                            entity.webAddress[0].latitude = location['lat'];
                                                            entity.webAddress[0].longitude = location['lng'];
                                                            // save to server
                                                            this._entityService.editEntityPatch(entity).subscribe(res => { });
                                                        }
                                                    });
                                            }
                                        }
                                    }
                                }
                            }

                            if (data && this._store && count === focusedEntityIdArray.length) {
                                if (data.length > 0) {
                                    ArrayHelperService.sort(data, 'entityName');
                                }
                                this._saveTileData(this.id, data);
                                subscriber.next(data);
                                subscriber.complete();
                            }
                        }));

                    });

                }
            } else {
                this._saveTileData(this.id, data);
                subscriber.next(data);
                subscriber.complete();

            }
        });
    }

    setTileData(data) {
        if (this.actionSubject$) {
            this.actionSubject$.next({ id: this.id, type: TamDashboardActions.SET_TILE_DATA, payload: data, isRefresh: this._isRefresh });
            this._isRefresh = false;
        }
    }

    private _actionHandler(action: DashboardTileAction) {
        switch (action.type) {
            case TamDashboardActions.LOAD_DATA: {
                if (action.payload && action.payload['tileConfig']) {
                    const mapFilters = _lodash.cloneDeep(action.payload['tileConfig'].mapFilters);
                    this._isRefresh = true;
                    this.subscriptions.push(this.loadData(action.payload)
                        .subscribe(res => {
                            if (res && res.length > 0) {
                                // if entity Type is none ,won't send search nearby request.
                                if (mapFilters && mapFilters.filterData && mapFilters.filterData.entityType && mapFilters.filterData.entityType.length !== 0) {
                                    this._judgeNearbyData(res[0], mapFilters.filterData);
                                } else if (mapFilters === undefined) {
                                    // if old map tile dashboard should set default value for filter.
                                    this._judgeNearbyData(res[0], {
                                        radiusSelect: 0.5,
                                        entityType: 'all'
                                    });
                                }
                            }
                        }));
                }
                break;
            }
            case TamDashboardActions.SET_TILE_CONFIG: {
                this._tileConfig = action.payload;
                break;
            }
            case TamDashboardActions.SET_FOCUSED_ENTITY_IDS: {
                this._focusedEntityIds = action.payload;
                break;
            }
            case TamDashboardEvents.SET_MAP_ADDRESS: {
                this._isRefresh = false;
                this._setSelectedAddress(action.payload);
                break;
            }
        }
    }

    /**
     *  use address which past from map-tile component to retrieve lat and lng
     */
    private _feedbackHandler(event) {
        if (event.type === TamDashboardEvents.SET_MAP_ADDRESS) {
            this._setSelectedAddress(event.payload);
        }
    }

    /**
    * Judge nearby filters for send request
    *
    * @private
    * @param {*} nearbyParams
    * @memberof TamDashboardMapNearbyComponent
    * search nearby data by map filters.
    * tileData: get latitude&lngitude entity id.
    * mapConfig: get filter parameters.
    */
    private _judgeNearbyData(tileData: TamDashboardTileData, mapConfig) {
        if (mapConfig.entityType && mapConfig.entityType.length > 0 && mapConfig.entityType
            !== ALL_VALUE) {
            mapConfig.entityType = mapConfig.entityType.map(item => item.id);
        }
        if (mapConfig.relationships && mapConfig.relationships.length > 0 && mapConfig.relationships
            !== ALL_VALUE) {
            mapConfig.relationships = mapConfig.relationships.map(item => item.id).join(',');
        }

        // if contact o entity filter  is null.should new filter content for parameter .server will check this logic and refactor it.
        if (mapConfig.mapFilter) {
            if (!mapConfig.mapFilter.filters[0]) {
                mapConfig.mapFilter.filters[0] = new MapFilterContent();
                mapConfig.mapFilter.filters[0].field = businessConstants.common.contact;
            }
            if (!mapConfig.mapFilter.filters[1]) {
                mapConfig.mapFilter.filters[1] = new MapFilterContent();
                mapConfig.mapFilter.filters[1].field = businessConstants.common.adhoc;
            }
        }
        this._contactService.getEntityListByNearby({ location: { latitude: tileData.latitude, longitude: tileData.longitude }, radius: Number(mapConfig.radiusSelect) }, mapConfig.entityType, tileData.entityId, mapConfig.mapFilter, mapConfig.relationships)
            .pipe(
                catchError((err) => {
                    return of(null);
                }),
                take(1)
            ).subscribe(res => {
                if (res) {
                    const nearbyEntity = res['entity-list'];
                    if (nearbyEntity && nearbyEntity.length > 0) {
                        const entityMarkerArray: Array<TamDashboardTileData> = [tileData];
                        nearbyEntity.forEach((item, index) => {
                            const marker: TamDashboardTileData = new TamDashboardTileData(null, null);
                            if (item['entity-type']['link']['phid'] === 'Contact') {
                                // conatct type
                                const contact = Contact.parse(item);
                                marker.latitude = contact.webAddress[0].latitude;
                                marker.longitude = contact.webAddress[0].longitude;
                                marker.entityName = contact.shortName;
                                marker.entityType = businessConstants.addressType.contact;
                                marker.entityId = contact.id;
                                marker.detail = contact;
                                marker.address = this._dashboardTileService.getAddress(contact.webAddress[0]);
                                marker.addressType = contact.webAddress[0].name;
                            } else {
                                const entity = Entity.parse(item);
                                marker.latitude = entity.webAddress[0].latitude;
                                marker.longitude = entity.webAddress[0].longitude;
                                marker.entityName = entity.shortName;
                                marker.entityType = businessConstants.addressType.entity;
                                marker.entityId = entity.id;
                                marker.detail = entity;
                                marker.address = this._dashboardTileService.getAddress(entity.webAddress[0]);
                                marker.addressType = entity.webAddress[0].name;
                            }
                            entityMarkerArray.push(marker);
                        });
                        this._saveTileData(this.id, entityMarkerArray);
                    }
                }
            });
    }

    private _saveTileData(id, data) {
        // save to store
        this._store.dispatch({
            type: DashboardActionTypes.SAVE_TILE_DATA,
            payload: {
                id: this.id,
                data: data,
            }
        });
        this.setTileData(data);
    }

    /**
     * lat: latest latitude
     * lng : latest longitude
     * saveToServer: need to save latitude and longitude to server
     * mapContactModel: param data
     * predictionDescription:
     */
    private _setMapData(latitude, longitude, saveToServer?: boolean, mapContactModel?: MapContactModel, predictionDescription?: string, isFromShowEventOnMapTile = false) {
        // save lat and lng to server
        if (saveToServer && mapContactModel && mapContactModel.contact) {
            if (mapContactModel.contact['type']['name'] === 'Contact') {
                // save contact to server
                mapContactModel.contact['webAddress'].forEach(webAddressItem => {
                    if (this._dashboardTileService.getWorkAddress(webAddressItem) === mapContactModel.selectedAddress) {
                        webAddressItem['latitude'] = latitude;
                        webAddressItem['longitude'] = longitude;
                    }
                });
                this._contactViewModel.editContact(mapContactModel.contact);
                this.data = [new TamDashboardTileData(latitude, longitude, mapContactModel.contact.id, mapContactModel.contact.name, mapContactModel.contact['type']['name'], mapContactModel.contact, mapContactModel.selectedAddress, 'Work')];
            } else {
                // save entity to server
                mapContactModel.contact.webAddress.forEach(webAddressItem => {
                    if (webAddressItem.name === mapContactModel.selectedAddressType) {
                        webAddressItem.latitude = latitude;
                        webAddressItem.longitude = longitude;
                        webAddressItem.callGoogle = true;
                        webAddressItem.geocodeSearched = true;
                    }
                });
                const addressType = mapContactModel.contact.webAddress[0].name;
                this._entityService.editEntityPatch(mapContactModel.contact).subscribe(response => { });
                this.data = [new TamDashboardTileData(latitude, longitude, mapContactModel.contact.id, mapContactModel.contact.name, businessConstants.addressType.entity, mapContactModel.contact, mapContactModel.selectedAddress, addressType)];
            }
        }

        if (saveToServer && mapContactModel && mapContactModel.contact && mapContactModel.contact['webAddress']) {

        } else if (predictionDescription) {
            this.data = [new TamDashboardTileData(latitude, longitude, null, predictionDescription, businessConstants.addressType.google)];
        } else {
            const entitType = mapContactModel.entityType === businessConstants.addressType.contact ? businessConstants.addressType.contact : businessConstants.addressType.entity;
            if (mapContactModel.contact) {
                this.data = [new TamDashboardTileData(latitude, longitude, mapContactModel.contact.id, mapContactModel.contact.name, entitType, mapContactModel.contact, mapContactModel.selectedAddress, mapContactModel.selectedAddressType)];
            } else {
                this.data = [new TamDashboardTileData(latitude, longitude, '', '', entitType, mapContactModel.contact, mapContactModel.selectedAddress, mapContactModel.selectedAddressType)];
            }
        }
        if (isFromShowEventOnMapTile) {
            // Needn't to save the tile data if the map tile is triggered by event.
            // Maybe need to handle save tile data later, so comments it for now.
            // this.setTileData(this.data);
        } else {
            if (this.data && this._store) {
                // save to store
                this._store.dispatch({
                    type: DashboardActionTypes.SAVE_TILE_DATA,
                    payload: {
                        id: this.id,
                        data: this.data,
                    }
                });
                // update map with new latitude and lngitude
                this.setTileData(this.data);
            }
        }
    }


    private _setSelectedAddress(data: any) {
        if (data.mapContactModel.latitude && data.mapContactModel.longitude) {
            // the latitude and longitude information were retrieved from server, do not need to save again
            this._setMapData(data.mapContactModel.latitude, data.mapContactModel.longitude, false, data.mapContactModel, null, data.isFromShowEventOnMapTile);
        } else {
            // no latitude and longitude were saved before
            this._dashboardTileService.getMapSearchGeocodeAddress(data.mapContactModel.selectedAddress)
                .subscribe((location: Location) => {
                    this.getLocation(data.mapContactModel, location, data.isFromShowEventOnMapTile);
                }
                );
        }
    }
}

