/**
 * Created by Abner Sui on 07/03/2019.
 * Description:
 *
 * ------ maintenance history ------
 */
export enum PrintActions {
    OPEN = 'DIALOG_OPEN',
    PRINT = 'PRINT',
}
