/**
 * Created by Jiu Chen
 * Description:
 *
 * ------ maintenance history ------
 * updated by Alan Yang on 6/25/2019
 * added ThreadDetailActions.INCLUDESIDENOTES logic for print notes function.
 * updated by Daniel Wang on 6/27/2019
 * changed the code to handle displaying thread detail in sidesheet
 * updated by Daniel on 10/12/2019
 * removed the trackBy of note-detail to use object to check the changing of thread.notes
 * update by Yoyo Fang on 09/18/2019
 * change usage of parentEntry to notes where threadPosition===0
 * Update by Ella Ma on 02/11/2020 Add print note button
 */
import {
    Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, HostListener,
    ChangeDetectionStrategy, ChangeDetectorRef, ViewRef, AfterViewInit
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, of, forkJoin } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AttachmentView } from '../attachment-list/attachment-view.model';
import { NoteDialogType, NoteDialogOpenOptions } from '../note-dialog/note-dialog.model';
import { NoteDialogService } from '../note-dialog/note-dialog.service';
import { AppState } from '../../redux';
import { lastCustomActionSelector } from '../../redux/reducers/action-history.reducer';
import { systemCurrentContactSelector } from '../../redux/reducers/system.reducer';
import { StoreQuerierService } from '../../services/store-querier.service';
import { NoteService } from '../../services/note.service';
import { ThreadDetailConfig, ThreadDetailActions, DEFAULT_ARROW_DOWNUP_HEIGHT, ThreadDetailMode } from './thread-detail.view-model';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { AlertButton, AlertBtnTypes } from '../../widgets/alert-window/alert-window.model';
import { NotificationStyles } from '../../widgets/notification/notification.model';
import { ToastService } from '../../widgets/toast/toast.service';

import { DocumentEntry } from '../../tamalelibs/models/document-entry.model';
import { Thread } from '../../tamalelibs/models/thread.model';
import {
    DELETE_NOTE, DELETE_ATTACHMENT_SUCCESS, DELETE_SIDENOTE_SUCCESS, DELETE_NOTE_SUCCESS, DELETE_EVENT_SUCCESS
} from '../../tamalelibs/redux/note.actions';
import * as _lodash from 'lodash';
import { ThreadDetailService } from '../../services/thread-detail.service';
import { EntryService } from '../../tamalelibs/services/entry.service';
import { ResearchScreenActionTypes } from '../../redux/actions/research-screen.actions';
import { CalendarDialogOpenOptions, CalendarDialogType } from '../calendar-dialog/calendar-dialog.model';
import { CalendarDialogService } from '../calendar-dialog/calendar-dialog.service';
import { ThreadViewActionTypes } from '../../redux/actions/thread-view.actions';
import { EntryClass } from '../../tamalelibs/models/entry-class.model';
import { businessConstants } from '../../constants/business.constants';
import { NoteEntry } from '../../tamalelibs/models/note-entry.model';
import { EntityService } from '../../tamalelibs/services/entity.service';
import { PrintService } from '../../services/print.service';
import { getDynamicResearchThreadListSelector2 } from '../../redux/reducers/research-screen.reducer';
import { ShowAsMode } from '../display/display.model';
import { SlideSheetService } from '../../services/slide-sheet.service';
import { SlideSheetActionTypes } from '../slide-sheet/slide-sheet.model';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'thread-detail',
    templateUrl: './thread-detail.component.html',
    styleUrls: ['./thread-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreadDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() config: ThreadDetailConfig;
    @ViewChild('contentBox', { static: false }) contentBoxEle: ElementRef;

    attachmentViewModelList: Array<AttachmentView> = [];
    isIPAD = false;
    isNoData = true;
    isShowMaximun = false;
    keywords: string[] = [];
    thread: Thread;
    layoutMode = 1;
    isEdit = false;
    isDelete = false;
    isLoading = false;
    isSideNoteDialogShown = false;
    bodyLoaded = false;
    isEvent = false;

    get parentNote(): NoteEntry {
        const parentNotes = this.thread.notes.filter((note) => note.threadPosition === 0);
        return parentNotes && parentNotes.length > 0 ? parentNotes[0] : new NoteEntry();
    }

    private _searchTerm = '';
    private _destroySubscriptions: Array<Subscription> = [];
    private _pageId: string; // mark page id for distinguishing diffent pages that subscribe to the same message queue
    private _attachInfo: AttachmentView;
    private _closeSideSheetWhenClickMiniIcon = false;

    constructor(
        private _activeRoute: ActivatedRoute,
        private _store: Store<AppState>,
        private _storeQuerier: StoreQuerierService,
        private _noteService: NoteService,
        private _noteDialogService: NoteDialogService,
        private _eventDialogService: CalendarDialogService,
        private _deviceService: DeviceDetectorService,
        private _alertWindow: AlertWindowService,
        private _slideSheetService: SlideSheetService,
        private _toast: ToastService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _threadDetailService: ThreadDetailService,
        private _entryService: EntryService,
        private _entityService: EntityService,
        private _printService: PrintService,
    ) {
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
    }

    ngOnInit() {
        this._initComponent();
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
        this._destroyComponent();
    }

    deleteAttachmentClick(event) {
        // delete attachment
        this._attachInfo = event;
        this._store.dispatch({
            type: 'DELETE_ATTACHMENT',
            payload: {
                id: this._attachInfo.id,
                componentId: this._pageId,
                attachInfo: this._attachInfo
            }
        });
    }

    deleteSideNoteClick(event) {
        const indexInThread = this.thread.notes.indexOf(event);
        if (indexInThread >= 0) {
            // remove the attachment from thread
            const removeAttachments = this.thread.notes[indexInThread].attachments;
            removeAttachments.forEach(attachment => {
                this.thread.attachments.delete(attachment.id);
                // remove the attachment from attachment list
                const attachView = AttachmentView.parseFromDocumentEntry(attachment);
                let indexInAttachmentList = -1;
                for (let i = 0; i < this.attachmentViewModelList.length; i++) {
                    if (this.attachmentViewModelList[i].id === attachView.id) {
                        indexInAttachmentList = i;
                        break;
                    }
                }
                this.attachmentViewModelList.splice(indexInAttachmentList, 1);
            });
            // remove the note
            this.thread.notes.splice(indexInThread, 1);
            // update the totalNotesInThread and threadPosition·
            for (let i = 0, len = this.thread.notes.length; i < len; i++) {
                const note = this.thread.notes[i];
                note.totalNotesInThread = note.totalNotesInThread - 1;
                if (note.threadPosition > len - indexInThread) {
                    note.threadPosition = note.threadPosition - 1;
                }
            }
        }
    }

    isDeletable(): boolean {
        return this._threadDetailService.isDeletable(this.thread.notes.find(item =>
            item.threadPosition === 0), this.thread);
    }

    isEntityTagHighlight(shortName: string) {
        if (this.keywords.length === 0) {
            return false;
        }
        if (this._searchTerm.toLowerCase() === shortName.toLowerCase()) {
            return true;
        }
        for (let index = 0; index < this.keywords.length; index++) {
            if (shortName.toLowerCase() === this.keywords[index].toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    onAddSideNoteClick() {
        const _noteOption = new NoteDialogOpenOptions();
        const sidenoteInfo = this._noteService.getSidenoteInfoBySettingRule(this.thread.notes, this.parentNote.totalNotesInThread);
        if (sidenoteInfo) {
            _noteOption.entryId = sidenoteInfo.entryId;
            _noteOption.entryTypeId = sidenoteInfo.entryTypeId;
        } else {
            _noteOption.entryId = this.parentNote.id;
        }
        _noteOption.openType = NoteDialogType.AddSideNote;
        _noteOption.minimizable = true;
        _noteOption.entityFieldEditable = this._isEditable(this.thread);
        this._noteDialogService.dialogOpen$.next(_noteOption);
    }

    editNoteClick() {
        this._isEvent();
        let _noteOption;
        if (this.isEvent) {
            _noteOption = new CalendarDialogOpenOptions();
            _noteOption.openType = CalendarDialogType.EditEvent;
            _noteOption.isPluginInEditEventMode = false;
        } else {
            _noteOption = new NoteDialogOpenOptions();
            _noteOption.openType = NoteDialogType.EditNote;
        }
        _noteOption.entryId = this.parentNote.id;
        _noteOption.minimizable = true;
        _noteOption.entityFieldEditable = this._isEditable(this.thread);
        this.editNoteOrSideNoteClick(_noteOption);

    }

    editNoteOrSideNoteClick(_noteOption) {
        _noteOption.editable = this._isEditable(this.thread);
        _noteOption.entityFieldEditable = this._isEditable(this.thread);
        if (this.thread && this.thread.notes.length > 0) {
            if (this.isEvent && _noteOption.openType !== NoteDialogType.EditSideNote) {
                this._eventDialogService.dialogOpen$.next(_noteOption);
            } else {
                this._noteDialogService.dialogOpen$.next(_noteOption);
            }
        }
    }

    getEntityTagHref(item): string {
        if (item && item['type']) {
            if (item['type']['name'] === 'Contact') {
                return 'contact/' + item.id;
            } else {
                return 'entity/' + item.id;
            }
        }
        return null;
    }

    onMouseWheel() {
        if (this._threadDetailService) {
            this._threadDetailService.scroll$.next();
        }
    }

    viewEntityDetailInSideSheet(item, event: Event) {
        // prevent a.href auto redirect
        event.preventDefault();
        event.stopPropagation();
        if (item) {
            if (item['type']) {
                this._openEntityByTypeName(item['type']['name'], item.id);
            } else {
                // query service returned data does not have type info, on demand, load entity detail by id
                this._entityService.getEntityDetail(item.id).subscribe((result) => {
                    this._openEntityByTypeName(result['entity-type']['data']['name'], item.id);
                });
            }
        }
    }

    trashClick() {
        const content: string[] = [];
        const contentContainer = this.isEvent === true ? 'event' : 'note';
        content.push('Are you sure you want to delete this ' + contentContainer + '?');
        content.push('Any attachments or sidenotes will also be deleted.');
        const cancelBtn: AlertButton = {
            text: 'Cancel',
            type: AlertBtnTypes.tertiary
        };
        const confirmButton: AlertButton = {
            text: 'Delete',
            type: AlertBtnTypes.destructive
        };
        this._alertWindow.openForSideSheet$.next(true);
        const subscription = this._alertWindow.
            custom('Delete confirmation required', content, cancelBtn, confirmButton)
            .subscribe((result: boolean) => {
                if (result) {
                    const componentId = this.isEvent ? 'Calendar' : 'thread detail';
                    this._store.dispatch({
                        type: DELETE_NOTE,
                        payload: {
                            id: this.parentNote.id,
                            componentId: componentId,
                        }
                    });
                }
                this._detectChanges();
                subscription.unsubscribe();
            });
    }

    onCopyLinkClick() {
        const entryClass = this.thread.parentEntry.entryClass;
        this._noteService.shareNote(this.thread.parentEntry.id, entryClass);
    }

    onKeydown(event) {
        if (event.keyCode === 40) { // down
            this.contentBoxEle.nativeElement.scrollTop += DEFAULT_ARROW_DOWNUP_HEIGHT;
        } else if (event.keyCode === 38) { // up
            this.contentBoxEle.nativeElement.scrollTop -= DEFAULT_ARROW_DOWNUP_HEIGHT;
        }
    }

    onKeyup(event) {
        if (this.isShowMaximun && event.keyCode === 27) {
            this.onMaximumClick();
        }
    }

    onMaximumClick(event?) {
        this.isShowMaximun = !this.isShowMaximun;
        if (this._closeSideSheetWhenClickMiniIcon) {
            this._threadDetailService.close$.next();
            this._closeSideSheetWhenClickMiniIcon = false;
        }
    }

    onShareClick() {
        const entryClass = this.parentNote.entryClass;
        const contact = this._storeQuerier.queryBySelector(systemCurrentContactSelector);
        const noteLink = this._noteService.getShareNoteLink(this.thread.parentEntry.id, entryClass);
        const emailBody = this._noteService.getShareNoteEmailBody(contact.name, this.parentNote.subject, noteLink);
        this._noteService.emailNote(this.parentNote.calculatedSubject, emailBody);
    }

    @HostListener('window:keyup', ['$event'])
    onkeyup(event) {
        if (event.keyCode === 27) {
            this.isShowMaximun = false;
        }
    }

    trackByFn(index, item) {
        return item.id;
    }

    printNote() {
        this._printService.open([this.thread]);
    }

    popupEntry(event) {
        const noteLink = this._noteService.getShareNoteLink(this.thread.id, this.thread['threadEntryEntryClass']);
        window.open(noteLink, '_blank');
    }

    private _openEntityByTypeName(name, id) {
        if (name === 'Contact') {
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CONTACT_PANEL,
                payload: id
            });
        } else {
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.ENTITY_PANEL,
                payload: id
            });
        }
    }

    private _destroyComponent() {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private _initComponent() {
        if (this.config.mode !== ThreadDetailMode.NORMAL) {
            this.layoutMode = 1;
            this._onSubscribeThreadData(this.config.thread);
            this._detectChanges();
        } else {
            this._destroySubscriptions.push(
                this._store.pipe(
                    select(this.config.dataStoreSelector)
                ).subscribe(data => {
                    this._onSubscribeThreadData(data);
                    // handle the case that open add sidenote dialog from email reminder link
                    // tamaleweb/entry/15f5a991d95648e181eda59fdd9b3775/sidenote
                    if (data) {
                        const sidenote = this._activeRoute.snapshot.paramMap.get('sidenote');
                        if (sidenote === 'sidenote' && !this.isSideNoteDialogShown) {
                            this.onAddSideNoteClick();
                            this.isSideNoteDialogShown = true;
                        } else {
                            this.isSideNoteDialogShown = false;
                        }
                    }
                    this._detectChanges();
                }),

                this._store.pipe(
                    select(lastCustomActionSelector)
                ).subscribe(action => {
                    this._onSubscribeActionMessage(action);
                })
            );

            if (this.config.layoutSelector) {
                const layout = this._storeQuerier.queryBySelector(this.config.layoutSelector);
                if (layout) {
                    this.layoutMode = layout.layoutMode;
                }
            }

            this._destroySubscriptions.push(
                this.config.actions$.subscribe(action => {
                    this._onSubscribeActions(action);
                    this._detectChanges();
                })
            );
        }
    }

    private _isEvent() {
        if (this.thread && this.thread.parentEntry) {
            if (this.thread.parentEntry.entryClass === EntryClass.Event) {
                this.isEvent = true;
            } else {
                this.isEvent = false;
            }
        }
    }

    private _isEditable(thread) {
        const flag = thread.notes.find(note => {
            return note.editable === false;
        });
        if (flag) {
            return false;
        } else {
            return true;
        }

    }

    private _onSubscribeActions(action) {
        if (action.type === ThreadDetailActions.TOGGLE_MAXIMIZE) {
            this.isShowMaximun = action.payload;
        } else if (action.type === ThreadDetailActions.CLEAR) {
            this.isNoData = true;
        } else if (action.type === ThreadDetailActions.LOAD_SIDE_SHEET) {
            if (action.isMaximize) {
                this.isShowMaximun = action.isMaximize;
            }
            if (action.closeSideSheetWhenClickMiniIcon) {
                this._closeSideSheetWhenClickMiniIcon = action.closeSideSheetWhenClickMiniIcon;
            }
            this.isLoading = true;
        }
        this._detectChanges();
    }

    private _onSubscribeActionMessage(action) {
        if (action.type === DELETE_ATTACHMENT_SUCCESS && this._attachInfo) {
            // popup a toast notification
            const message = 'Attachment successfully deleted.';
            this._notification(message);

            this.config.events$.next({
                type: 'DELETE_ATTACHMENT',
                payload: {
                    threadId: this.thread && this.thread.id || '0',
                    attachInfo: this._attachInfo
                }
            });

            // remove the attachment from attachment list
            const index = this._getIndex(this.attachmentViewModelList, this._attachInfo);
            if (index > -1) {
                this.attachmentViewModelList.splice(index, 1);
            }
        } else if (action.type === DELETE_SIDENOTE_SUCCESS) {
            this.config.events$.next({
                type: 'DELETE_SIDENOTE',
                payload: {
                    threadId: this.thread && this.thread.id || '0'
                }
            });
            const messageInfo = businessConstants.note.deleteSuccessfully;
            this._notification(messageInfo);
        } else if (action.prefix === this.config.keyInStore && action.type === ResearchScreenActionTypes.GET_THREAD_NOTE_BODIES_SUCCESS) {
            this.bodyLoaded = true;
            this._setFoucsSidenote();
        } else if (action.type === DELETE_NOTE_SUCCESS) {
            const messageInfo = businessConstants.note.deleteSuccessfully;
            this._notification(messageInfo);
        } else if (action.type === DELETE_EVENT_SUCCESS) {
            const messageInfo = businessConstants.event.deleteSuccessfully;
            this._notification(messageInfo);
        } else if (action.type === ThreadViewActionTypes.GET_THREAD_SUCCESS) {
            this.isLoading = false;
        }
        this._detectChanges();
    }

    private _notification(message) {
        this._toast.notify({
            message: message,
            style: NotificationStyles.Success
        });
    }

    private _getIndex(_arr, _obj) {
        const len = _arr.length;
        for (let i = 0; i < len; i++) {
            if (_arr[i].id === _obj.id) {
                return i;
            }
        }
        return -1;
    }

    private _onSubscribeThreadData(thread: Thread): void {
        this.attachmentViewModelList = [];
        this.keywords = [];
        if (thread) {
            if (this.contentBoxEle) {
                this.contentBoxEle.nativeElement.scrollTop = 0;
            }
            this.isNoData = false;
            // keep a clone of thread for switching including or not including sidenotes.
            // this.threadWithoutSideNotes = _lodash.cloneDeep(thread);
            // this.threadWithoutSideNotes.notes = new Array(this.threadWithoutSideNotes.parentEntry);

            // set binding thread on UI
            this.thread = thread;
            this.isEdit = this.parentNote.editable;
            // check if note bodies are loaded
            this.bodyLoaded = this.thread.notes.filter(note => note.body).length === this.thread.notes.length;
            if (this.bodyLoaded === false) {
                if (this.config.mode !== ThreadDetailMode.NORMAL) {
                    // Copy from loadThreadNoteBodiesEffect$ in research-screen.effects.ts
                    const observables$ = [];
                    this.thread.notes.forEach(note => {
                        if (!note.body) {
                            observables$.push(this._entryService.getNoteBodyById(note.id));
                        } else {
                            observables$.push(of('IGNORE'));
                        }
                    });
                    const currentUser = this._storeQuerier.getCurrentUser();
                    forkJoin(observables$).subscribe(responses => {
                        this.thread.notes.forEach((note, index) => {
                            if (responses[index] === 'IGNORE') {
                            } else {
                                if (currentUser) {
                                    note.body = this._entryService.appendUserIdToImageLinksInNoteBody(responses[index], currentUser.id);
                                }
                            }
                        });
                        this.bodyLoaded = true;
                        this._setFoucsSidenote();
                        this._detectChanges();
                    });
                } else {
                    this._store.dispatch({
                        prefix: this.config.keyInStore,
                        type: this.config.getNoteBodyActionName,
                        payload: this.thread
                    });
                }
            }
            if (!this.config.singlePageMode && this.config.keywordStoreSelector) {
                // process keyword against lucence special characters
                const keyword: string = this._storeQuerier.queryBySelector(this.config.keywordStoreSelector);
                if (keyword) {
                    this._searchTerm = keyword;
                    this.keywords = this._threadDetailService.parseKeywordsFromSearchString(keyword);
                } else {
                    this._searchTerm = '';
                    this.keywords = [];
                }
            }
            const matchedAttachmentIdSet: Set<string> = this._storeQuerier.queryBySelector(this.config.matchedAttachmentStoreSelector);
            if (this.thread.attachments && this.thread.attachments.size > 0) {
                Array.from(this.thread.attachments.values()).forEach((item: DocumentEntry) => {
                    const attachment = AttachmentView.parseFromDocumentEntry(item);
                    attachment.highlight = matchedAttachmentIdSet && matchedAttachmentIdSet.has(item.id);
                    this.attachmentViewModelList.push(attachment);
                });
            }
            this._isEvent();
        } else {
            this.isNoData = true;
        }

        this.isLoading = false;
        this._setFoucsSidenote();
        this._detectChanges();
    }

    private _reloadThread(isPageLoading: boolean) {
        this.isLoading = isPageLoading;
        if (this.thread) {
            this._store.dispatch({
                type: ThreadViewActionTypes.GET_THREAD,
                payload: this.thread.id
            });
        }
    }

    private _detectChanges() {
        if (this._changeDetectorRef && !(this._changeDetectorRef as ViewRef).destroyed) {
            this._changeDetectorRef.detectChanges();
        }
    }

    private _setFoucsSidenote() {
        if (this.config.showAsMode === ShowAsMode.NOTES) {
            setTimeout(() => {
                const threadEntryList = this._storeQuerier.queryBySelector(getDynamicResearchThreadListSelector2(this.config.keyInStore));
                if (threadEntryList && threadEntryList.selectedIndex) {
                    const threadEntry = threadEntryList.items[threadEntryList.selectedIndex.index];
                    if (threadEntry && threadEntry.notes.length > 1) {
                        const queryParam = '#_' + threadEntry.id;
                        const scrollEl = this.contentBoxEle.nativeElement.querySelector(queryParam);
                        if (scrollEl) {
                            scrollEl.scrollIntoView();
                            this._detectChanges();
                        }
                    }
                }
            });
        }
    }
}
