<ng-container *ngFor="let item of data;let i = index;trackBy:trackByFn">
    <div class="container">
        <div class="left">
            <div class="line-container">
                <div class="line" *ngIf="i !== 0"></div>
            </div>
            <div class="circle" [ngClass]="circle_color[item.type] || circle_color['info']"></div>
            <div class="line-container">
                <div class="line" *ngIf="i !== data.length - 1"></div>
            </div>
        </div>
        <div class="right">
            <ng-container *ngIf="content">
                <ng-container *ngTemplateOutlet="content;context:{dataItem: item}">
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!content">
                <span>{{item}}</span>
            </ng-container>
        </div>
    </div>
</ng-container>