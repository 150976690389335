/**
 * Created by Simon Zhao on 04/24/2022.
 * This service is used to control the visibility of the automation note dialog component.
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WebTemplateViewModel } from '../../../../components/template/template-web.view-model';
import { DiagramNoteAutomationDialogOptions, DiagramNoteAutomationDialogResult } from './diagram-note-automation-dialog.model';

@Injectable()
 export class DiagramAutomationNoteDialogService {
    constructor(private _noteTemplatePageViewModel: WebTemplateViewModel) {}
    dialogClose$: Subject<DiagramNoteAutomationDialogResult> = new Subject<DiagramNoteAutomationDialogResult>();
    dialogOpen$: Subject<DiagramNoteAutomationDialogOptions> = new Subject<DiagramNoteAutomationDialogOptions>();
    getNoteTypes() {
        return this._noteTemplatePageViewModel.noteTypeForWeb().pipe(
            filter(res => res != null && res.entryTypes.length > 0 && res.defaultEntryTypeId.length > 0),
        );
    }
 }
