/**
 * Created by Alex on 3/8/2020.
 * Description:
 *
 * ------ maintenance history ------
 * update by Marcus Zhao 09/08/2021 add action for pulling coordinates.
 */

import { Action } from '@ngrx/store';

export const GET_MAP_TILE_STATUS = 'GET_MAP_TILE_STATUS';
export const GET_MAP_TILE_STATUS_SUCCESS = 'GET_MAP_TILE_STATUS_SUCCESS';
export const GET_MAP_TILE_STATUS_FAIL = 'GET_MAP_TILE_STATUS_FAIL';
export const CHANGE_MAP_TILE_STATUS = 'CHANGE_MAP_TILE_STATUS';
export const CHANGE_MAP_TILE_STATUS_SUCCESS = 'CHANGE_MAP_TILE_STATUS_SUCCESS';
export const CHANGE_MAP_TILE_STATUS_FAIL = 'CHANGE_MAP_TILE_STATUS_FAIL';

export const PULLING_COORDINATES = 'PULLING_COORDINATES';
export const PULLING_COORDINATES_PROGRESS = 'PULLING_COORDINATES_PROGRESS';
export const PULLING_COORDINATES_SUCCESS = 'PULLING_COORDINATES_SUCCESS';
export const PULLING_COORDINATES_FAIL = 'PULLING_COORDINATES_FAIL';
export const GET_PULLING_COORDINATES = 'GET_PULLING_COORDINATES';

export class ChangeMapTileStatusAction implements Action {
    type: string = CHANGE_MAP_TILE_STATUS;
    payload: any;
}

export class PullingCoordinatesAction implements Action {
    type: string = PULLING_COORDINATES;
    payload: any;
}

export class PullingCoordinatesSuccessAction implements Action {
    type: string = PULLING_COORDINATES_SUCCESS;
    payload: any;
}
