import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectWindowService } from './select-window.service';
import { Subscription } from 'rxjs';
import { SelectWindowAction } from './select-window.model';

@Component({
    selector: 'tam-select-window',
    templateUrl: './select-window.component.html',
    styleUrls: ['./select-window.component.scss']
})
export class SelectWindowComponent implements OnInit, OnDestroy {

    data: Array<string>;
    open = false;
    isMultiple: boolean;
    title: string;
    selectedData: any; // number | Array<boolean>

    private _selectedIndex: number | Array<number>;
    private _eventExtraKey: string;
    private _eventExtraValue: any;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _service: SelectWindowService,
    ) { }

    ngOnInit() {
        this._destroySubscriptions.push(
            this._service.openSubject$.subscribe((action: SelectWindowAction) => {
                this.open = action.open;
                this.isMultiple = action.isMultiple;
                this.data = action.data;
                this.title = action.title;
                this._eventExtraKey = action.eventExtraKey;
                this._eventExtraValue = action.eventExtraValue;
                if (this.isMultiple) {
                    this.selectedData = new Array(this.data.length);
                    this.data.forEach((one, index) => {
                        this.selectedData[index] = false;
                    });
                } else {
                    this.selectedData = 0;
                }
            }),
        );
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    close(event) {
        this.open = false;
        this._service.resultSubject$.next({
            result: false,
            selectedIndex: null,
        });
        if (this._eventExtraKey) {
            event[this._eventExtraKey] = this._eventExtraValue;
        }
    }

    onChangeOptions(value, index) {
        if (this.isMultiple) {
            this.selectedData[index] = value;
        } else {
            this.selectedData = index;
        }
    }

    onClick(event) {
        if (this._eventExtraKey) {
            event[this._eventExtraKey] = this._eventExtraValue;
        }
    }

    save(event) {
        if (this.isMultiple) {
            const selectedIndex = [];
            this.selectedData.forEach((one, index) => {
                if (one) {
                    selectedIndex.push(index);
                }
            });
            if (selectedIndex.length) {
                this._selectedIndex = selectedIndex;
            } else {
                this._selectedIndex = null;
            }
        } else {
            this._selectedIndex = this.selectedData;
        }
        this.open = false;
        this._service.resultSubject$.next({
            result: true,
            selectedIndex: this._selectedIndex,
        });
        if (this._eventExtraKey) {
            event[this._eventExtraKey] = this._eventExtraValue;
        }
    }

}
