import { Component, OnInit, Input, Output, forwardRef, HostBinding, EventEmitter, HostListener, ElementRef, Renderer2, ViewChild, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

@Component({
    // tslint:disable-next-line: component-selector
    selector: '[tam-checkbox]',
    templateUrl: './checkbox.component.html',
    // styleUrls: ['./checkbox.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CheckboxComponent),
        multi: true
    }]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor, OnChanges {
    private _innverValue: any = '';
    private _disabled = false;
    private _indeterminate = false;

    private el: HTMLElement;
    private prefixCls = 'tam-checkbox';

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    classMap: {};

    contentClassMap: {};

    @Input()
    set contentClass(c: string) {
        if (c && c.length > 0) {
            this.contentClassMap = {
                [`${c}`]: true
            };
        }
    }

    @Input()
    set value(v: any) {
        this._innverValue = v;
        this.updateClassMap();
    }

    get value(): any {
        return this._innverValue;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set isDisabled(value: boolean) {
        this._disabled = value;
    }

    get isDisabled(): boolean {
        return this._disabled;
    }

    @Input()
    set indeterminate(value: boolean) {
        this._indeterminate = value;
    }

    get indeterminate(): boolean {
        return this._indeterminate;
    }

    @ViewChild('contentElement', { static: true }) contentElement: ElementRef;
    @Output() checkedChange = new EventEmitter<boolean>();

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit() {
        this.renderer.addClass(this.el, 'tam-checkbox-wrapper');
        this.updateClassMap();
    }

    ngOnChanges() {
        this.updateClassMap();
    }

    isContentEmpty(): boolean {
        return this.contentElement.nativeElement.textContent === '';
    }

    onBlur() {
        this.onTouchedCallback();
    }

    @HostListener('click', ['$event'])
    onClick(e: MouseEvent): void {
        e.preventDefault();
        e.stopPropagation();
        if (!this.disabled) {
            this.updateValue(!this._innverValue);
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    updateClassMap(): void {
        this.classMap = {
            [this.prefixCls]: true,
            [`${this.prefixCls}-checked`]: this.value && (!this.indeterminate),
            [`${this.prefixCls}-disabled`]: this.disabled,
            [`${this.prefixCls}-indeterminate`]: this.indeterminate,
        };
    }

    updateValue(value: boolean) {
        this.onChangeCallback(value);
        this.checkedChange.emit(value);
        this._innverValue = value;
        this.updateClassMap();
    }

    writeValue(value: any) {
        this.value = value;
    }
}
