import { Subject } from 'rxjs';
import { ColDef, GridOptions } from 'ag-grid-community';

export enum GridEventType {
    onBodyScroll,
    onSort
}

export enum GridActionType {
    refreshGrid,
    loadMore,
    resetPage,
    setSortModel
}

export class GridConfig {
    columnDefs: Array<ColDef>;
    gridOptions?: GridOptions;
    action$: Subject<{ type: GridActionType, payload?: any }>;
    events$: Subject<{ type: GridEventType, payload?: GridPayload }>;
    constructor() {
        this.action$ = new Subject();
        this.events$ = new Subject();
    }
}

export class DataList<T> extends Array<T> {
    data: Array<any>;
    total: number;

    constructor() {
        super();
        this.total = 0;
        this.data = [];
    }
}

export class GridPayload {
    rpp?: number;
    page?: number;
    sortby?: string;
    sortorder?: string;
}
