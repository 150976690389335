<div *ngIf="options">
    <div class="tab-container">
        <div [ngClass]="{'right': options.align==='RIGHT','center': options.align==='CENTER'}">
            <div class="js-tab-underline" *ngIf="_underlineStyle"></div>
            <ul class="tab-items" [ngStyle]="options.style">
                <li class="tab-item tab-item-default-state"
                    *ngFor="let tabItem of options.tabItems; let tabIndex = index;">
                    <label id="tab-label-{{tabIndex}}" attr.tid="{{tabItem.displayText}}" class="tab-link"
                        (click)="_tabItemOnClick($event,tabItem, tabIndex)"
                        [ngClass]="{ 'tab-active' : tabIndex == options.selectedIndex, 'tab-disabled' : tabItem.disabled } ">{{tabItem.displayText}}</label>
                    <div class="centerdiv" *ngIf="!_underlineStyle"></div>
                </li>
            </ul>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="name; context: myContext"></ng-container>
</div>