/**
 * Created by Simon Zhao on 05/6/2022.
 * Description:
 * models for workflow adhoc fields.
 * ------ maintenance history ------
 * Updated by Simon Zhao on 08/31/2022 introduced system variables for template fields and provided a static function to get system variables for the given adhoc field.
 * Updated by Daniel Wang on 09/26/2022 Added expandValueObj property for AdhocField to match server structure.
 */
import { KeyValue } from '@angular/common';
import { DataModelBase } from './base.model';
import { ControlType } from './template-control.model';

export const TEMPLATE_FIELD_SYSTEM_VARIABLES = { 'date': [{ key: 'general.current_date_time', value: 'currentTime' }] };

export class AdhocField extends DataModelBase {
    title: string;
    value: string;
    expandValue = []; // for event attendees
    // tslint:disable-next-line: whitespace
    expandValueObj? = {}; // for event location expand value

    constructor() {
        super('', '');
    }

    static parse(serverResponse): AdhocField {
        if (serverResponse && serverResponse.data) {
            const adhoc = new AdhocField();

            adhoc.id = serverResponse.data.propdef.data.id;
            adhoc.name = serverResponse.data.propdef.data.name;
            adhoc.title = serverResponse.data.propdef.data.title;
            adhoc.value = serverResponse.data.value;
            adhoc.expandValue = serverResponse.data['expand-value'];
            adhoc.expandValueObj = serverResponse.data['expandValue'];

            return adhoc;
        } else {
            return null;
        }
    }
}

export class AdhocWorkflowField {
    attrDefID: string;

    lableName: string;

    controlType: string;

    fieldType: string;

    componentType?: 'met' | 'date' | 'ed' | 'tb' | 'ck' | 'dd' | 'med' | 'msd' | 'mdl' | 'user';

    /**
     * Gets an array of workflow control types available for the given adhoc field on the purpose of exporting workflow data to notes.
     * @param field an adhoc field
     * @returns an array of workflow component types.
     */
    static getApplicableControlTypes(field: AdhocWorkflowField): Array<ControlType> {
        switch (field.componentType) {
            case 'date':
                return [ControlType.DATE];
            case 'met':
                return [ControlType.MULTI_ENTITY_DROP_DOWN, ControlType.SINGLE_ENTITY_DROP_DOWN, ControlType.SINGLE_USER_DROP_DOWN];
            case 'ed':
                return [ControlType.SINGLE_ENTITY_DROP_DOWN, ControlType.SINGLE_USER_DROP_DOWN];
            case 'tb': // https://jiraprod.advent.com/browse/TAM-38573?focusedCommentId=2372590&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-2372590 Per PdM's decision, make number fields of workflow available for all text note ad-hoc fields.
                return [ControlType.TEXT, ControlType.TEXT_AREA, ControlType.SINGLE_TEXT_DROP_DOWN, ControlType.NUMBER];
            case 'dd':
                return [ControlType.SINGLE_TEXT_DROP_DOWN];
            case 'med':
                return [ControlType.MULTI_ENTITY_DROP_DOWN, ControlType.SINGLE_ENTITY_DROP_DOWN, ControlType.SINGLE_USER_DROP_DOWN];
            case 'msd':
                return [ControlType.SINGLE_TEXT_DROP_DOWN];
            case 'user':
                return [ControlType.SINGLE_USER_DROP_DOWN];
            default:
                return [];
        }
    }

    /**
    * Gets an array of workflow control types available for the given adhoc field on the purpose of importing data into workflow from notes.
    * @param field an adhoc field
    * @returns an array of workflow component types.
    */
    static getApplicableControlTypesForNoteTrigger(field: AdhocWorkflowField): Array<ControlType> {
        switch (field.componentType) {
            case 'date':
                return [ControlType.DATE];
            case 'met':
                return [ControlType.MULTI_ENTITY_DROP_DOWN];
            case 'ed':
                return [ControlType.SINGLE_ENTITY_DROP_DOWN, ControlType.MULTI_ENTITY_DROP_DOWN, ControlType.SINGLE_USER_DROP_DOWN];
            case 'tb': // https://jiraprod.advent.com/browse/TAM-38903
                return [ControlType.TEXT, ControlType.TEXT_AREA];
            case 'dd':
                return [ControlType.SINGLE_TEXT_DROP_DOWN];
            case 'med':
                return [ControlType.MULTI_ENTITY_DROP_DOWN];
            case 'msd':
                return [ControlType.SINGLE_TEXT_DROP_DOWN];
            case 'user':
                return [ControlType.SINGLE_USER_DROP_DOWN];
            default:
                return [];
        }
    }

    /**
     * Gets system variables available for the given adhoc field.
     * @param field an adhoc field
     * @returns an array of system variables.
     */
    static getFieldSystemVariables(field: AdhocWorkflowField): Array<KeyValue<string, string>> {
        return TEMPLATE_FIELD_SYSTEM_VARIABLES[field.componentType];
    }

    /**
     * Determines whether the given adhoc field is able to map to multiple fields for trigger metadata.
     * @param field an adhoc field
     * @returns a flag indicating whether the given field is able to map to multiple fields.
     */
    static isFieldMultipleValue(field: AdhocWorkflowField): boolean {
        switch (field.componentType) {
            case 'met':
            case 'med':
            case 'msd':
            case 'tb':
            case 'date':
            case 'ed':
            case 'dd':
            case 'mdl':
            case 'user':
                return true;
            default:
                return false;
        }
    }

    /**
     * Determines whether the given adhoc field is able to map to multiple fields for automation dialog.
     * @param field an adhoc field
     * @returns a flag indicating whether the given field is able to map to multiple fields.
     */
    static isFieldMultipleValueInAutomation(field: AdhocWorkflowField): boolean {
        switch (field.componentType) {
            case 'met':
            case 'med':
            case 'msd':
                return true;
            default:
                return false;
        }
    }

    /**
     * Determines whether the given adhoc field is able to map to task submitters.
     * @param field an adhoc field.
     * @returns a flag indicating whether task submitters should be included in the candidate list.
     */
    static isTaskSubmitterIncluded(field: AdhocWorkflowField): boolean {
        switch (field.componentType) {
            case 'met':
            case 'med':
            case 'ed':
                return true;
            default:
                return false;
        }
    }
}
