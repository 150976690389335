import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EntityDialogService {
    entityEditStatus$: Subject<any> = new Subject<any>();
    dialogClose$: Subject<any> = new Subject<any>();
    dialogOpen$: Subject<any> = new Subject<any>();
    index: Number;
}
