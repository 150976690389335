/**
 * Created by Jiu Chen on 04/15/2019.
 * Description:
 *  Get data throught server's query service.
 *  Currently depends on web only service, should refactor it to sharable.
 *
 * ------ maintenance history ------
 * 11/12/2019 Ella Ma fixed bug if formula column a depend formula column b, b shuold calculate before a
 * 05/07/2019 Yoyo Fang add default sorting for column/bar chart when no sort value specified
 *                      inject moment.js for parsing string to date, especially for format 'mm/dd/yyyy'
 */


import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

import { DateHelperWebService } from '../tamalelibs/services/date-helper.web.service';
import { StoreQuerierService } from './store-querier.service';
import { EntityService } from '../tamalelibs/services/entity.service';
import { TransportService } from '../tamalelibs/services/transport.service';
import { TileColumnDataType, TileColumnType } from '../tamalelibs/models/tile-column.model';
import { ProviderColumnType } from '../tamalelibs/models/dataprovider.model';
import { TileModel } from '../tamalelibs/models/tile.model';
import * as moment from 'moment';
import { FileDownload } from '../tamalelibs/models/file-list.model';
import { businessConstants } from '../constants/business.constants';

@Injectable()
export class QueryService {

    constructor(
        private _entityService: EntityService,
        private _storeQuerier: StoreQuerierService,
        private _transportService: TransportService
    ) { }

    /**
     * Execute formula in conditional formatting to get the suitable color and backgournd color
     * @param {object} columnSetting: the related column setting in Tile Configuration
     * @param {object} rowData: the data of one row in query server response
     * @return {object} the object contains color and background color. default value is {}
     */
    calculateColor(tileConfig, columnSetting, rowData) {
        const result: any = {};
        if (columnSetting?.conditionalFormattings == null || columnSetting?.conditionalFormattings.length === 0 || !rowData) {
            return result;
        }

        const columnSet = {};
        tileConfig.columns.forEach((column: any) => {
            switch (column.dataType) {
                case 'number':
                case 'currency':
                case 'percent':
                case 'ratio':
                    columnSet[column.guid] = 'number';
                    break;
                default:
                    columnSet[column.guid] = 'text';
                    break;
            }
        });

        const length = columnSetting.conditionalFormattings.length;
        for (let i = 0; i < length; i++) {
            let formula = columnSetting.conditionalFormattings[i].formulaForData;
            // tslint:disable-next-line: forin
            for (const key in columnSet) {
                let temp = rowData[key];
                if (temp === undefined || temp === null) {
                    temp = '';
                }
                if (columnSet[key] === 'text') {
                    // replace str " to \", because if str has ", example \"China-("China Hydroelectric" or "the Company")\" will error.
                    if (typeof temp === 'string') {
                        temp = temp.replace(/([\'\"\(\)\&\<\>])/g, '\\$1');
                    }
                    formula = formula.replace(new RegExp(key, 'g'), '"' + temp + '"');
                } else {
                    formula = formula.replace(new RegExp(key, 'g'), temp);
                }
            }
            try {
                // tslint:disable-next-line: no-eval
                if (eval(formula)) {
                    result.color = columnSetting.conditionalFormattings[i].color;
                    result.backgroundColor = columnSetting.conditionalFormattings[i].backgroundColor;
                    break;
                }
            } catch (e) { }
        }
        return result;
    }

    /**
     * Execute the formula column and get formatted value based on column setting
     * @param {Object} tileConfig - the whole tile configuration
     * @param {Object} columnSetting - the related column setting in Tile Configuration
     * @param {Object} rowData - the data of one row in query server response
     */
    calcuateFormula(tileConfig, columnSetting, rowData, formatDate = false) {
        const columnSet = {};
        tileConfig.columns.forEach(column => {
            switch (column.dataType) {
                case 'number':
                case 'currency':
                case 'percent':
                case 'ratio':
                    columnSet[column.guid] = 'number';
                    break;
                default:
                    columnSet[column.guid] = 'text';
                    break;
            }
        });

        let formula = columnSetting.formulaForData;
        // tslint:disable-next-line: forin
        for (const key in columnSet) {
            const column = tileConfig.columns.filter(item => item.guid === key);
            let cellValue = rowData[key];
            if (column && column[0] && rowData[`${key}_value`]) {
                if (this.isDateType(column[0].dataType)) {
                    let keyValue = rowData[`${key}_value`];
                    if (typeof keyValue === 'string' && column[0].columnType === 'MDL' &&
                        column[0].dataType === ProviderColumnType.DATE) {
                        keyValue = keyValue + ' 00:00:00';
                    }
                    cellValue = new Date(keyValue);
                } else if (column[0].dataType === 'number') {
                    const scale = column[0].settings.scale;
                    let scaleNum = 1;
                    if (scale === '2') {
                        scaleNum = 1000;
                    } else if (scale === '3') {
                        scaleNum = 1000000;
                    } else if (scale === '4') {
                        scaleNum = 1000000000;
                    } else if (scale === '5') {
                        scaleNum = 1000000000000;
                    }
                    cellValue = parseFloat(rowData[`${key}_value`]) * scaleNum;
                }
            }
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            if (columnSet[key] === 'text') {
                // replace str " to \", because if str has ", example \"China-("China Hydroelectric" or "the Company")\" will error.
                if (typeof cellValue === 'string') { // cellValue typeof may be Date
                    cellValue = cellValue.replace(/([\'\"\(\)\&\<\>])/g, '\\$1');
                }
                formula = formula.replace(new RegExp(key, 'g'), '"' + cellValue + '"');
            } else {
                formula = formula.replace(new RegExp(key, 'g'), cellValue);
            }
        }
        let result: any = '';
        try {
            // tslint:disable-next-line: no-eval
            result = eval(formula);
            if (result instanceof Error) {
                result = 'NaN';
            }
        } catch (e) {
        }

        // process calculated result based on column setting (number, date)
        // date need to process: date format (date & time, date only, month and year, year only)
        if (columnSetting.dataType === 'number' || columnSetting.dataType === 'currency' ||
            columnSetting.dataType === 'percent' || columnSetting.dataType === 'ratio') {
            if (typeof (result) !== 'number') {
                return '';
            }
            // number need to do following process by sequence: scale, x100, abs, decimal places
            if (columnSetting.settings.scale) {
                // logic to process scale for number value
                switch (columnSetting.settings.scale) {
                    case '2':
                        result = result / 1000;
                        break;
                    case '3':
                        result = result / 1000000;
                        break;
                    case '4':
                        result = result / 1000000000;
                        break;
                    case '5':
                        result = result / 1000000000000;
                        break;
                    default:
                        break;
                }
            }

            if (columnSetting.dataType === 'percent') {
                if (!columnSetting.settings.multiplyBy100) {
                    result = result / 100;
                }
                result = result.toFixed(columnSetting.settings.decimalPlaces + 2);
            } else {
                result = result.toFixed(columnSetting.settings.decimalPlaces);
            }

            if ((columnSetting.settings.negatives + '') === '2') {
                result = Math.abs(result);
            }
        } else if (this.isDateType(columnSetting.dataType)) {
            result = new Date(result);
            if (isNaN(result.getTime())) {
                return '';
            }

            if (formatDate) {
                const format = this._getDateDisplayFormat(columnSetting);
                result = DateHelperWebService.getDateString(result, format);
            }
        }

        return result;
    }

    /**
     * Execute query for Bar Chart Value based on tile config and optional focused entities
     * @param {object} tileConfig: the configuration of tile
     * @param {string} focusedEntityIds: entity ids splited by ",", could be null
     * @return {promise} the array contains Chart data object { category: 'SSNC', value: 10}
     */
    executeQueryForColumnChart(tileConfig, focusedEntityIds, useConfig = false) {
        const params: any = {
            groupby: tileConfig.visualization.settings.categoryAxis,
            groupheader: 'Category',
            minuteoffset: DateHelperWebService.getContryTimezoneId(),
            summaries: {
                column: tileConfig.visualization.settings.series
            }
        };
        if (tileConfig.visualization.settings.series !== 'efb04d645da94dacbbcd568a83b5f12p') {
            // efb04d645da94dacbbcd568a83b5f12p is the guid of Count
            params.summaries.operation = 'sum';
        } else {
            params.summaries.operation = 'count';
        }
        if (tileConfig.visualization.settings.categorySort && tileConfig.visualization.settings.categoryCount) {
            params.summaries.sortOrder = tileConfig.visualization.settings.categorySort;
            params.summaries.row = parseInt(tileConfig.visualization.settings.categoryCount, 10);
            params.summaries.sortBy = 'value';
        } else {
            params.summaries.sortBy = 'key';
            params.summaries.sortOrder = 'asc';
        }

        if (focusedEntityIds) {
            params.focus = focusedEntityIds;
        }
        return this.sendQueryRequest(tileConfig, params, useConfig).pipe(
            map(response => {
                if (response.Items.length === 0) {
                    return [];
                }

                // get the series column setting
                let columnSetting = null;
                let i = 0;
                const length = tileConfig.columns.length;
                for (i; i < length; i++) {
                    if (tileConfig.columns[i].guid === tileConfig.visualization.settings.series) {
                        columnSetting = tileConfig.columns[i];
                        break;
                    }
                }

                const result = [];
                // angular.forEach(response.Items, function(group) {
                response.Items.forEach(group => {
                    let value: any = 0;
                    if (tileConfig.visualization.settings.series === 'efb04d645da94dacbbcd568a83b5f12p') {
                        value = group.Count;
                    } else {
                        value = group[tileConfig.visualization.settings.series + 'Sum'];
                    }
                    if (value === '') { return; }
                    group.Category = this._formatPrivateCompanyColumnValue(group.Category, tileConfig, group.GroupBy);
                    result.push({
                        category: group.Category,
                        value: value,
                        valueText: this.formatNumberValue(columnSetting, value)
                    });
                });
                return result;
            })
        );
    }

    /**
     * Execute query for Bar Chart Value based on tile config and optional focused entities
     * @param {object} tileConfig: the configuration of tile
     * @param {string} focusedEntityIds: entity ids splited by ",", could be null
     * @return {observable} the array contains Grid data object { category: 'SSNC', value: 10}
     */
    executeQueryForGridTile(tileConfig: any, focusedEntityIds: string, useConfig = false, gridFilterParams = null) {
        const params: any = {
            minuteoffset: DateHelperWebService.getContryTimezoneId(),
            showtimestamp: true,
            showmultipleentityid: true
        };

        if (focusedEntityIds) {
            params.focus = focusedEntityIds;
        }
        if (gridFilterParams) {
            params.filters = gridFilterParams;
        }
        return this.sendQueryRequest(tileConfig, params, useConfig).pipe(
            map((response: any) => {
                return response.Items;
            }),
            map((rows: any[] = []) => {
                this._formatGridData(tileConfig, rows);
                return rows;
            }),
        );
    }

    /**
     * Execute query for Line Chart Value based on tile config and optional focused entities
     * @param {*} tileConfig: the configuration of tile
     * @param {*} focusedEntityIds: entity ids splited by ",", could be null
     * @return {promise} object with three properties
     *      data: arrary contains chart data object { groupName: '', category: '', value: 9 }
     *      dateType: the type of date, could be 0,1,2,3
     *      misc: contain the min and max date of the result
     */
    executeQueryForLineChart(tileConfig, focusedEntityIds, useConfig = false) {
        let categoryColumnSetting = null, seriesColumnSetting = null;
        tileConfig.columns.forEach(setting => {
            if (setting.guid === tileConfig.visualization.settings.categoryAxis) {
                categoryColumnSetting = setting;
            } else if (setting.guid === tileConfig.visualization.settings.series) {
                seriesColumnSetting = setting;
            }
        });
        let isMDLQuery = false;

        // providerType 0-rich-client mdl 1-web mdl 2-sql mdl 3-oracle mdl
        // add category_value params return, because mdl column, open drill down,
        // value should use orinial value to get grid data. if the column is other date type,
        // server return millisecond, and there is no problem, but the mdl column server can't format,
        // so should be use orinial value, otherwise, grid data may get no data in difference timezone.
        const isNotRichClientMDL = categoryColumnSetting.columnType === 'MDL' && categoryColumnSetting.config && categoryColumnSetting.config.providerType > 0 && seriesColumnSetting && seriesColumnSetting.config && seriesColumnSetting.config.providerType > 0;

        let params: any = {
            hideattribute: true,
            minuteoffset: DateHelperWebService.getContryTimezoneId(),
            showtimestamp: true
        };
        if (tileConfig.visualization.settings.series === 'efb04d645da94dacbbcd568a83b5f12p') {
            params.groupby = tileConfig.visualization.settings.groupBy + ',' + tileConfig.visualization.settings.categoryAxis;
            params.groupheader = 'Category';
        } else {
            if (categoryColumnSetting.columnType === 'MDL' && !(categoryColumnSetting.config && categoryColumnSetting.config.providerType > 0) && seriesColumnSetting && seriesColumnSetting.columnType === 'MDL' && !(seriesColumnSetting.config && seriesColumnSetting.config.providerType > 0)) {
                isMDLQuery = true;
                params = { showmultiplemdlvalue: categoryColumnSetting.guid + ',' + seriesColumnSetting.guid };
            } else {
                params.needsubmitteddate = tileConfig.visualization.settings.series;
            }
        }
        if (focusedEntityIds) {
            params.focus = focusedEntityIds;
        }
        return this.sendQueryRequest(tileConfig, params, useConfig).pipe(
            map(response => {
                if (response.Items.length === 0) {
                    return [];
                }

                const result: any = {
                    data: [],
                    dateType: categoryColumnSetting.settings.display
                };

                let maxDate = new Date(-8640000000000000);
                let minDate = new Date(8640000000000000);
                const groupSet: any = {};
                response.Items.forEach(group => {
                    group.Category = this._formatPrivateCompanyColumnValue(group.Category, tileConfig, group.GroupBy);
                });
                if (tileConfig.visualization.settings.series === 'efb04d645da94dacbbcd568a83b5f12p') {
                    // efb04d645da94dacbbcd568a83b5f12p is the guid of Count
                    // Count is based on server side 2-level group
                    if (categoryColumnSetting.settings.display === '0') {
                        // generate data set that sum Count by date
                        response.Items.forEach(groupL1 => {
                            groupSet[groupL1.Category] = {};
                            groupL1.Items.forEach(groupL2 => {
                                if (groupL2['Category_value'] === '') { return; }

                                const tmpDate = new Date(groupL2['Category_value']);
                                if (tmpDate > maxDate) {
                                    maxDate = tmpDate;
                                }
                                if (tmpDate < minDate) {
                                    minDate = tmpDate;
                                }
                                const key = (new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate())).getTime().toString();
                                if (groupSet[groupL1.Category][key]) {
                                    groupSet[groupL1.Category][key] += groupL2.Count;
                                } else {
                                    groupSet[groupL1.Category][key] = groupL2.Count;
                                }
                            });
                        });

                        // tslint:disable-next-line: forin
                        for (const groupKey in groupSet) {
                            // tslint:disable-next-line: forin
                            for (const categoryKey in groupSet[groupKey]) {
                                result.data.push({
                                    groupName: groupKey,
                                    // tslint:disable-next-line: radix
                                    category: new Date(parseInt(categoryKey)),
                                    value: groupSet[groupKey][categoryKey],
                                    valueText: groupSet[groupKey][categoryKey],
                                    category_value: '' // if series selected Count, server must return millisecond
                                });
                            }
                        }

                        maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1);
                        maxDate.setDate(maxDate.getDate() - 1);
                        maxDate.setHours(12); // add 12 hours to prevent DST issue in Kendo (minus one hour and cause maxDate one day less)

                        result.misc = {
                            minDate: new Date(minDate.getFullYear(), minDate.getMonth(), 1, 12),
                            maxDate: maxDate
                        };
                    } else if (categoryColumnSetting.settings.display === '1') {
                        response.Items.forEach(groupL1 => {
                            groupL1.Items.forEach(groupL2 => {
                                if (groupL2['Category_value'] === '') { return; }

                                const tmpDate = new Date(groupL2['Category_value']);
                                if (tmpDate > maxDate) {
                                    maxDate = tmpDate;
                                }
                                if (tmpDate < minDate) {
                                    minDate = tmpDate;
                                }
                                result.data.push({
                                    groupName: groupL1.Category,
                                    category: tmpDate,
                                    value: groupL2.Count,
                                    valueText: groupL2.Count,
                                    category_value: '' // if series selected Count, server must return millisecond
                                });
                            });
                        });
                        maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1);
                        maxDate.setDate(maxDate.getDate() - 1);
                        maxDate.setHours(12); // add 12 hours to prevent DST issue in Kendo (minus one hour and cause maxDate one day less)

                        result.misc = {
                            minDate: new Date(minDate.getFullYear(), minDate.getMonth(), 1, 12),
                            maxDate: maxDate
                        };
                    } else {
                        response.Items.forEach(groupL1 => {
                            groupL1.Items.forEach(groupL2 => {
                                if (groupL2['Category_value'] === '') { return; }

                                const tmpDate = new Date(groupL2['Category_value']);
                                if (tmpDate > maxDate) {
                                    maxDate = tmpDate;
                                }
                                if (tmpDate < minDate) {
                                    minDate = tmpDate;
                                }
                                result.data.push({
                                    groupName: groupL1.Category,
                                    category: tmpDate,
                                    value: groupL2.Count,
                                    valueText: groupL2.Count,
                                    category_value: '' // if series selected Count, server must return millisecond
                                });
                            });
                        });
                        maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1);
                        maxDate.setDate(maxDate.getDate() - 1);
                        maxDate.setHours(12); // add 12 hours to prevent DST issue in Kendo (minus one hour and cause maxDate one day less)

                        result.misc = {
                            minDate: new Date(minDate.getFullYear(), minDate.getMonth(), 1, 12),
                            maxDate: maxDate
                        };
                    }
                } else {
                    if (isMDLQuery) {
                        result.dateType = '1'; // temp code; need to remove after Tile Designer updated.

                        // logic to handle MDL response
                        response.Items.forEach(row => {
                            if (row[tileConfig.visualization.settings.series] === '') {
                                return;
                            }
                            if (row[categoryColumnSetting.guid] === '') {
                                return;
                            }
                            if (isNaN(Date.parse(row[categoryColumnSetting.guid]))) {
                                return;
                            }

                            const tmpDate = new Date(row[categoryColumnSetting.guid]);
                            if (tmpDate > maxDate) { maxDate = tmpDate; }
                            if (tmpDate < minDate) { minDate = tmpDate; }

                            result.data.push({
                                groupName: row[tileConfig.visualization.settings.groupBy],
                                category: tmpDate,
                                value: row[tileConfig.visualization.settings.series],
                                valueText: this.formatNumberValue(seriesColumnSetting, row[tileConfig.visualization.settings.series]),
                                category_value: isNotRichClientMDL ? row[categoryColumnSetting.guid] : ''
                            });
                        });

                        maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1);
                        maxDate.setDate(maxDate.getDate() - 1);
                        maxDate.setHours(12); // add 12 hours to prevent DST issue in Kendo (minus one hour and cause maxDate one day less)

                        result.misc = {
                            minDate: new Date(minDate.getFullYear(), minDate.getMonth(), 1, 12),
                            maxDate: maxDate
                        };
                    } else {
                        // logic to handle entry type response
                        response.Items.forEach(row => {
                            if (row[tileConfig.visualization.settings.categoryAxis + '_value'] === '') {
                                return;
                            }
                            if (row[tileConfig.visualization.settings.series] === '') {
                                return;
                            }

                            const groupKey = row[tileConfig.visualization.settings.groupBy];
                            let categoryKey = '';
                            const categoryValue = isNotRichClientMDL ? row[tileConfig.visualization.settings.categoryAxis] : '';
                            const tmpDate = new Date(row[tileConfig.visualization.settings.categoryAxis + '_value']);
                            if (categoryColumnSetting.settings.display === '0' || categoryColumnSetting.settings.display === '1') {
                                categoryKey = (new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate())).getTime().toString();
                            } else if (categoryColumnSetting.settings.display === '2') {
                                categoryKey = (new Date(tmpDate.getFullYear(), tmpDate.getMonth())).getTime().toString();
                            } else {
                                categoryKey = (new Date(tmpDate.getFullYear(), 0)).getTime().toString();
                            }

                            if (tmpDate > maxDate) { maxDate = tmpDate; }
                            if (tmpDate < minDate) { minDate = tmpDate; }

                            if (!groupSet[groupKey]) {
                                groupSet[groupKey] = {};
                            }

                            if (!groupSet[groupKey][categoryKey]) {
                                groupSet[groupKey][categoryKey] = {
                                    value: row[tileConfig.visualization.settings.series],
                                    submittedDate: row[tileConfig.visualization.settings.series + '_submitted_date'],
                                    category_value: categoryValue
                                };
                            } else {
                                if (row[tileConfig.visualization.settings.series + '_submitted_date'] > groupSet[groupKey][categoryKey].submittedDate) {
                                    groupSet[groupKey][categoryKey] = {
                                        value: row[tileConfig.visualization.settings.series],
                                        submittedDate: row[tileConfig.visualization.settings.series + '_submitted_date'],
                                        category_value: categoryValue
                                    };
                                }
                            }
                        });

                        for (const groupKey in groupSet) {
                            if (groupSet.hasOwnProperty(groupKey)) {
                                for (const categoryKey in groupSet[groupKey]) {
                                    if (groupSet[groupKey].hasOwnProperty(categoryKey)) {
                                        // angular.forEach(groupSet, function (groupValue, groupKey) {
                                        // angular.forEach(groupValue, function (categoryVal, categoryKey) {
                                        result.data.push({
                                            groupName: groupKey,
                                            // tslint:disable-next-line: radix
                                            category: new Date(parseInt(categoryKey)),
                                            value: groupSet[groupKey][categoryKey].value,
                                            valueText: this.formatNumberValue(seriesColumnSetting, groupSet[groupKey][categoryKey].value),
                                            category_value: groupSet[groupKey][categoryKey].category_value
                                        });
                                    }
                                }
                            }
                        }

                        maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1);
                        maxDate.setDate(maxDate.getDate() - 1);
                        maxDate.setHours(12); // add 12 hours to prevent DST issue in Kendo (minus one hour and cause maxDate one day less)

                        result.misc = {
                            minDate: new Date(minDate.getFullYear(), minDate.getMonth(), 1, 12),
                            maxDate: maxDate
                        };
                    }
                }
                return result;
            }, function failed(response) {
                const reg = /ERR_TOO_MANY_DETAILS: (.*?) \(TransactionID/gm;
                const matches = reg.exec(response.data);
                if (matches) {
                    // logHelper.error(matches[1]);
                } else {
                    // logHelper.error(response.data);
                }

                return [];
            })
        );
    }

    /**
     * Execute query for Metadata Value based on tile config and optional focused entities
     * @param {object} tileConfig: the configuration of tile
     * @param {string} focusedEntityIds: entity ids splited by ",", could be null
     * @return {promise} the array contains Metadata item object
     */
    executeQueryForMetadataTile(tileConfig, focusedEntityIds: string, useConfig = false) {
        const params: any = {
            hideattribute: true,
            minuteoffset: DateHelperWebService.getContryTimezoneId(),
            page: 1,
            rpp: 1
        };
        if (focusedEntityIds) {
            params.focus = focusedEntityIds;
        }
        return this.sendQueryRequest(tileConfig, params, useConfig).pipe(
            map((response: any) => {
                if (response.Items.length === 0) {
                    return [];
                }

                const rawData = response.Items[0];
                const columns = this._moveUpDependFormulaColumn(tileConfig.columns);
                columns.forEach((columnSetting: any) => {
                    if (columnSetting.columnType === 'Formula') {
                        const calculatedValue = this.calcuateFormula(tileConfig, columnSetting, rawData, true);
                        rawData[columnSetting.guid] = calculatedValue;
                    }
                });

                const result = [];
                tileConfig.columns.forEach((columnSetting: any) => {
                    if (columnSetting.hidden) { return; }
                    const data = this._formatPrivateCompanyColumnValue(rawData[columnSetting.guid], tileConfig, columnSetting.guid, columnSetting);
                    const item = {
                        color: this.calculateColor(tileConfig, columnSetting, rawData).color || '#282828',
                        id: columnSetting.guid,
                        title: columnSetting.title,
                        value: this.formatNumberValue(columnSetting, data),
                        dataType: columnSetting.dataType // store column datatype for url recognition (it only applies on text column)
                    };

                    result.push(item);
                });
                return result;
            }),
        );
    }

    /**
     * Execute query for Pie Chart Value based on tile config and optional focused entities
     * @param {object} tileConfig: the configuration of tile
     * @param {string} focusedEntityIds: entity ids splited by ",", could be null
     * @return {promise} the array contains Chart data object { category: 'SSNC', value: 10}
     */
    executeQueryForPieChart(tileConfig, focusedEntityIds, useConfig = false) {
        const params: any = {
            groupby: tileConfig.visualization.settings.categoryAxis,
            groupheader: 'Category',
            minuteoffset: DateHelperWebService.getContryTimezoneId()
        };
        if (tileConfig.visualization.settings.series !== 'efb04d645da94dacbbcd568a83b5f12p') {
            // efb04d645da94dacbbcd568a83b5f12p is the guid of Count
            params.summaries = {
                column: tileConfig.visualization.settings.series,
                operation: 'sum'
            };
        } else {
            params.summaries = {
                operation: 'count'
            };
        }
        // sort response by value desc
        params.summaries.sortOrder = 'desc';
        if (focusedEntityIds) {
            params.focus = focusedEntityIds;
        }

        return this.sendQueryRequest(tileConfig, params, useConfig).pipe(
            map(response => {
                if (response.Items.length === 0) {
                    return [];
                }

                // get the series column setting
                let columnSetting = null, i = 0;
                const length = tileConfig.columns.length;
                for (i; i < length; i++) {
                    if (tileConfig.columns[i].guid === tileConfig.visualization.settings.series) {
                        columnSetting = tileConfig.columns[i];
                        break;
                    }
                }

                const result = [];
                response.Items.forEach(group => {
                    // angular.forEach(response.data.Items, function (group) {
                    let value = 0;
                    if (tileConfig.visualization.settings.series === 'efb04d645da94dacbbcd568a83b5f12p') {
                        // efb04d645da94dacbbcd568a83b5f12p is the guid of Count
                        value = group.Count;
                    } else {
                        value = group[tileConfig.visualization.settings.series + 'Sum'] || 0;
                    }
                    // ignore the group that value is empty or equals 0
                    if (value !== 0) {
                        group.Category = this._formatPrivateCompanyColumnValue(group.Category, tileConfig, group.GroupBy);
                        result.push({
                            category: group.Category,
                            value: value,
                            valueText: this.formatNumberValue(columnSetting, value)
                        });
                    }
                });
                return result;
            })
        );
    }

    /**
     * Execute query for Tile Value based on tile config and optional focused entities
     * @param {object} tileConfig: the configuration of tile
     * @param {string} focusedEntityIds: entity ids splited by ",", could be null
     * @return {promise} formatted value string
     */
    executeQueryForValueTile(tileConfig, focusedEntityIds, useConfig = false) {
        const params: any = {
            minuteoffset: DateHelperWebService.getContryTimezoneId()
        };

        const settings = tileConfig.visualization.settings;
        if (!settings.summaries || settings.summaries === '0') {
            params.page = 1;
            params.rpp = 1;
        } else if (settings.summaries === '5') {
            params.showcountonly = true;
        } else {
            let operation = '';
            switch (settings.summaries) {
                case '1':
                    operation = 'avg';
                    break;
                case '2':
                    operation = 'max';
                    break;
                case '3':
                    operation = 'min';
                    break;
                case '4':
                    operation = 'sum';
                    break;
                default:
                    break;
            }
            params.summaries = {
                column: settings.column,
                operation: operation
            };
        }
        if (focusedEntityIds) {
            params.focus = focusedEntityIds;
        }
        return this.sendQueryRequest(tileConfig, params, useConfig).pipe(
            map(response => {
                let value = '';
                // get related column setting
                const columnId = tileConfig.visualization.settings.column;
                let columnSetting = null;
                let i = 0;
                const length = tileConfig.columns.length;
                for (i; i < length; i++) {
                    if (tileConfig.columns[i].guid === columnId) {
                        columnSetting = tileConfig.columns[i];
                        break;
                    }
                }
                if (!tileConfig.visualization.settings.summaries || tileConfig.visualization.settings.summaries === '0') {
                    // handle the case that Summaries is empty or 0, which means the value could be any type
                    if (response.Items.length === 0) {
                        return null;
                    }

                    const rawData = response.Items[0];
                    const columns = this._moveUpDependFormulaColumn(tileConfig.columns);
                    columns.forEach(colSetting => {
                        if (colSetting.columnType === 'Formula') {
                            const calculatedValue = this.calcuateFormula(tileConfig, colSetting, rawData, true);
                            rawData[colSetting.guid] = calculatedValue;
                        }
                    });
                    value = this._formatPrivateCompanyColumnValue(rawData[columnId], tileConfig, columnId);
                    return {
                        color: this.processConditionalFormattingForValueTile(tileConfig.conditionalFormattings, value).color || '#282828',
                        value: value,
                        valueText: this.formatNumberValue(columnSetting, value)
                    };
                } else {
                    // Value is a number when Summaries > 1
                    if (!response.Count) {
                        return null;
                    }
                    value = response.Count;
                    if (tileConfig.visualization.settings.summaries === '5') {
                        // No need to format if summary is Count
                        return {
                            color: this.processConditionalFormattingForValueTile(tileConfig.conditionalFormattings, value).color || '#282828',
                            value: value,
                            valueText: this.formatNumStrWithComma(value.toString())
                        };
                    } else {
                        return {
                            color: this.processConditionalFormattingForValueTile(tileConfig.conditionalFormattings, value).color || '#282828',
                            value: value,
                            valueText: this.formatNumberValue(columnSetting, value)
                        };
                    }
                }
            })
        );
    }

    /**
    * Execute query for Tile Value based on tile config and optional focused entities
    * @param {object} tileConfig: the configuration of tile
    * @param {string} focusedEntityIds: entity ids splited by ",", could be null
    * @return {promise} formatted value string
    */
    executeQueryForNoteTile(tileConfig: TileModel, focusedEntityIds: string, useConfig = false) {
        const urlParams: any = {};
        if (tileConfig.pageDesc) {
            urlParams['rpp'] = tileConfig.pageDesc.rpp;
            urlParams['page'] = tileConfig.pageDesc.page;
        }

        const params: any = {};
        if (focusedEntityIds) {
            params.focus = focusedEntityIds;
        }
        if (tileConfig.sorts) {
            const column = tileConfig.columns.filter(col => col.field === tileConfig.sorts.field);
            if (column && column.length > 0) {
                params['sort'] = [{
                    field: column[0].guid,
                    order: tileConfig.sorts.order
                }];
                // params['minuteoffset'] = -480;
            }
        }

        return this.sendQueryRequestForNoteTile(tileConfig, urlParams, params, useConfig);
    }

    formatNumberValue(columnSetting, value) {
        if (!columnSetting || !columnSetting.dataType || !columnSetting.settings || columnSetting.settings.decimalPlaces == null) {
            return value;
        }

        let result: any = '';
        const decimalPlaces = parseInt(columnSetting.settings.decimalPlaces, 10);
        // EDGE doesn't support option of toLocaleString
        // using Regex method instead toLocaleString
        // var langOption = {
        //     maximumFractionDigits: decimalPlaces,
        //     minimumFractionDigits: decimalPlaces
        // };
        if (columnSetting.dataType === 'number' || columnSetting.dataType === 'currency' ||
            columnSetting.dataType === 'percent' || columnSetting.dataType === 'ratio') {
            if (value && !isNaN(value) || value === 0) {
                value = +value;
            }
        }
        if (value || value === 0) {
            if (columnSetting.dataType === 'number') {
                // result = value.toLocaleString('en-US', langOption);
                result = this.formatNumStrWithComma(value.toFixed(decimalPlaces));
            } else if (columnSetting.dataType === 'currency') {
                if (value < 0) {
                    // result = Math.abs(value).toLocaleString('en-US', langOption);
                    result = this.formatNumStrWithComma(Math.abs(value).toFixed(decimalPlaces));
                    result = '($' + result + ')';
                } else {
                    // result = value.toLocaleString('en-US', langOption);
                    result = this.formatNumStrWithComma(value.toFixed(decimalPlaces));
                    result = '$' + result;
                }
            } else if (columnSetting.dataType === 'percent') {
                value = value * 100;
                // result = value.toLocaleString('en-US', langOption);
                result = this.formatNumStrWithComma(value.toFixed(decimalPlaces));
                result = result + '%';
            } else if (columnSetting.dataType === 'ratio') {
                // result = value.toLocaleString('en-US', langOption);
                result = this.formatNumStrWithComma(value.toFixed(decimalPlaces));
                result = result + 'x';
            } else {
                result = value;
            }
        } else {
            result = value;
        }


        return result;
    }

    /**
     * Format number (in string format) with thousand comma; e.g. 12345.678 -> 12,345.678
     * @param {string} numStr: number in string format
     * @return {string}
     */
    formatNumStrWithComma(numStr) {
        const tmpNum = numStr.split('.');
        tmpNum[0] = tmpNum[0].replace(/(\d)(?=(\d{3})+$)/ig, '$1,');
        return tmpNum.join('.');
    }

    /**
     * Get the focused entity id string by paring TileConfig and Dashboard Focus Setting
     * @param {object} tileConfig: the configuration of tile
     * @param {object} dashboardFocus: the dashboard focus setting
     * @return {promise} the string of entity ids splited by ","
     */
    prepareFocusForQuery(tileConfig, dashboardFocus) {
        let observable: Observable<any>;
        const currentUser = this._storeQuerier.getCurrentUser();
        if (tileConfig.focus.type === 1) {
            observable = of(null);
        } else if (tileConfig.focus.type === 2) {
            observable = this.prepareRelationshipFocusForQuery(tileConfig.focus.entityId, tileConfig.focus.relationshipTypes);
        } else if (tileConfig.focus.type === 3) {
            observable = this.prepareRelationshipFocusForQuery(currentUser.id, tileConfig.focus.relationshipTypes);
        } else if (tileConfig.focus.type === 0 && dashboardFocus.type === 1) {
            observable = of(null);
        } else if (tileConfig.focus.type === 0 && dashboardFocus.type === 2) {
            observable = this.prepareRelationshipFocusForQuery(dashboardFocus.entityId, dashboardFocus.relationshipTypes);
        } else if (tileConfig.focus.type === 0 && dashboardFocus.type === 3) {
            observable = this.prepareRelationshipFocusForQuery(currentUser.id, dashboardFocus.relationshipTypes);
        } else {
            observable = throwError('');
        }

        return observable;
    }

    prepareRelationshipFocusForQuery(entityId, relationshipTypeIdArr): Observable<string> {
        if (Array.isArray(entityId)) {
            if (Array.from(entityId).length > 1) {
                // the case in which there're more than one focus id, simply ignore the relationship configured to widgets.
                return of(Array.from(entityId).join(','));
            } else if (Array.from(entityId).length === 1) {
                // for the case with only one focus id, treat as non-array entityId. AKA, the relationship configured on Widgets is still effective.
                entityId = entityId[0];
            } else {
                throw new Error('Unexpected entityId(zero length)!');
            }
        }

        if (relationshipTypeIdArr == null || relationshipTypeIdArr.length === 0) {
            return of(entityId);
        } else {
            return this._entityService.getSpecificRelationships(entityId, relationshipTypeIdArr).pipe(
                map(res => {
                    return res['entity-list'].map(entity => entity.id).join(',');
                })
            );
        }
    }

    processConditionalFormattingForValueTile(conditionalFormattings, value) {
        // conditional formatting in value tile only has one field with fixed guid ke48d5ac4b8746e5a700e03822e0d978
        const result: any = {};
        if (conditionalFormattings == null || conditionalFormattings.length === 0) {
            return result;
        }
        if (value === null || value === undefined) {
            value = '';
        }
        let i = 0;
        const length = conditionalFormattings.length;
        for (i; i < length; i++) {
            let formula = conditionalFormattings[i].formulaForData;
            formula = formula.replace('ke48d5ac4b8746e5a700e03822e0d978', '"' + value + '"');
            try {
                // tslint:disable-next-line: no-eval
                if (eval(formula)) {
                    result.color = conditionalFormattings[i].color;
                    result.backgroundColor = conditionalFormattings[i].backgroundColor;
                    break;
                }
            } catch (e) { }
        }
        return result;
    }

    sendQueryRequest(tileConfig, params, useConfig = false) {
        let url = '/restapi/2.0/query/';
        if (!useConfig) {
            url = `/restapi/2.0/query/${tileConfig.id}`;
        }
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        };

        const options = {
            headers: headers
        };

        const formData = new URLSearchParams();
        for (const key in params) {
            if (typeof params[key] === 'object') {
                formData.set(key, JSON.stringify(params[key]));
            } else {
                formData.set(key, params[key]);
            }
        }
        if (useConfig) {
            formData.set('config', JSON.stringify(tileConfig));
        }
        return this._transportService.post(url, formData.toString(), options);
    }

    sendQueryRequestForNoteTile(tileConfig, urlParams, params, useConfig = false) {
        let url = '/restapi/2.0/query';
        if (!useConfig) {
            url = `/restapi/2.0/query/${tileConfig.id}`;
        }

        url += '/?showdetailwithcount=true&showtimestamp=true';
        for (const key in urlParams) {
            if (['string', 'boolean', 'number'].includes(typeof urlParams[key])) {
                url += '&' + key + '=' + urlParams[key];
            }
        }
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        };

        const options = {
            headers: headers
        };

        const formData = new URLSearchParams();
        for (const key in params) {
            if (typeof params[key] === 'object') {
                formData.set(key, JSON.stringify(params[key]));
            } else {
                formData.set(key, params[key]);
            }
        }
        if (useConfig) {
            formData.set('config', JSON.stringify(tileConfig));
        }
        return this._transportService.post(url, formData.toString(), options);
    }

    sendQueryRequestByChart(tileConfig, params, formData = {}) {
        const url = `/restapi/2.0/query/${tileConfig.id}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        };

        const options = {
            headers: headers,
            params: params
        };
        const paramsStr = this._transportService.getUrlEncodedForm(formData);
        return this._transportService.post(url, paramsStr, options).pipe(
            map((response: any) => {
                return response;
            }),
            map((rows: any[] = []) => {
                this._formatGridData(tileConfig, rows['Items']);
                return rows;
            })
        );
    }

    isDateType(dataType) {
        return dataType === ProviderColumnType.TIMESTAMP_WITH_TIME_ZONE ||
            dataType === ProviderColumnType.TIMESTAMP || dataType === ProviderColumnType.TIME ||
            dataType === ProviderColumnType.BYTEA || dataType === ProviderColumnType.DATE;
    }

    sortChartDataByXAxisIfNoValueSort(data, tileConfig) {
        if (tileConfig && tileConfig.visualization && tileConfig.visualization.settings && !tileConfig.visualization.settings.categorySort) {
            const xAxisColumnId = tileConfig.visualization.settings.categoryAxis;
            const column = tileConfig.columns.find(col => col.guid === xAxisColumnId);
            if (column) {
                if (column.dataType === TileColumnDataType.DATE) {
                    let format = this._getDateDisplayFormat(column);
                    format = format.replace('TT', 'A').replace('dd', 'DD').replace('yyyy', 'YYYY'); // moment.js timezone format specification
                    const result = data.sort((a, b) => moment(a.category, format).diff(moment(b.category, format)));
                    return result;
                } else {
                    return data.sort((a, b) => a.category.localeCompare(b.category));
                }
            }
        }
        return data;
    }

    private _formatGridData(tileConfig, rows) {
        const columns = this._moveUpDependFormulaColumn(tileConfig.columns);
        columns.forEach((columnSetting: any) => {
            rows.forEach((rowData: any) => {
                rowData[columnSetting.guid] = this._formatPrivateCompanyColumnValue(rowData[columnSetting.guid], tileConfig, columnSetting.guid, columnSetting);
                if (columnSetting.columnType === 'Formula') {
                    rowData[columnSetting.guid] = this.calcuateFormula(tileConfig, columnSetting, rowData);
                }
                if (columnSetting.field === businessConstants.dashboard.field.entry_file) {
                    const data = rowData[columnSetting.guid];
                    if (data) {
                        const files = data.map(item => FileDownload.parse(item));
                        rowData[columnSetting.guid] = files;
                    }
                }
            });
        });
    }

    private _formulaUseCount(columns, dependColumns) {
        let count = 0;
        dependColumns.forEach(column => {
            const arr = columns.filter(item => item.formulaForData && item.formulaForData.indexOf(column.guid) !== -1) || [];
            count += 1;
            if (arr.length > 0) {
                count += this._formulaUseCount(columns, arr);
            }
        });
        return count;
    }

    private _moveUpDependFormulaColumn(columns) {
        const newColumns = _.cloneDeep(columns);
        newColumns.forEach((column, index, allColumns) => {
            const dependColumns = allColumns.filter(item => item.formulaForData && item.formulaForData.indexOf(column.guid) !== -1) || [];
            column.count = this._formulaUseCount(columns, dependColumns);
        });
        return newColumns.sort((a, b) => b.count - a.count);
    }

    private _getDateDisplayFormat(column): string {
        if (column.settings) {
            switch (column.settings.display) {
                case '0':
                    return 'MM/dd/yyyy hh:mm TT';
                case '2':
                    return 'MMM yyyy';
                case '3':
                    return 'yyyy';
                default:
                    // use Date Only as default logic
                    return 'MM/dd/yyyy';
            }
        }
        return 'MM/dd/yyyy';
    }

    private _formatPrivateCompanyColumnValue(value, tileConfig, columnId, curColumn?) {
        let column;
        if (curColumn) {
            column = curColumn;
        } else {
            const columns = tileConfig.columns;
            column = columns.find(item => item.guid === columnId);
        }
        const isPrivateCompany = column && column.columnType === TileColumnType.ENTITY_COLUMN && column.field === 'entity__company';
        if (isPrivateCompany) {
            if (value === 'NO_ACCESS') {
                return '******';
            }
            return value;
        }
        return value;
    }
}

