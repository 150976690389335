/**
 * Created by Yoyo Fang on 10/10/2020
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../redux';
import { getFolderStructureState } from '../tamalelibs/redux/intralinks.reducer';
import { ToastService } from '../widgets/toast/toast.service';
import { NotificationOptions, NotificationStyles } from './../widgets/notification/notification.model';

@Injectable({
    providedIn: 'root'
})
export class IntralinksSessionService {
    private subscriberCount = 0;
    private subscription = null;
    constructor(
        private _store: Store<AppState>,
        private _toastService: ToastService,
    ) { }

    addListener() {
        if (this.subscriberCount === 0) {
            this.subscription = this._store.pipe(
                select(getFolderStructureState),
            ).subscribe((state: any) => {
                if (state['error'] && state['session'] === true) {
                    const notice = new NotificationOptions();
                    notice.style = NotificationStyles.Error;
                    notice.message = state['error']['message'] || state['error'];
                    this._toastService.notify(notice);
                }
            });
        }
        this.subscriberCount++;
    }

    removeListener() {
        this.subscriberCount--;
        if (this.subscriberCount === 0) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

}
