/**
 * Created by Ella on 07/16/2019.
 * Description:
 * dashboard reducer
 * ------ maintenance history ------
*/
import { combineReducers, createSelector } from '@ngrx/store';
import { DashboardActionTypes, DashboardActions } from '../actions/dashboard.actions';
import { AppState } from '..';
import * as _lodash from 'lodash';

export const getDashboardState = (state: AppState) => state.dashboard;
export const getFocusStateSelector = createSelector(getDashboardState, (state: any) => state.focus);
export const getEntireTileSelector = createSelector(getDashboardState, (state: any) => state.print);
export const getNoteInfoSelector = createSelector(getDashboardState, (state: any) => state.note);

export function focusReducer(state: any = {}, action: DashboardActions) {
    switch (action.type) {
        case DashboardActionTypes.SET_FOCUS_VALUE:
            return Object.assign({}, action.payload);
        default:
            return state;
    }
}

export function noteEntityReducer(state: any = '', action: DashboardActions) {
    switch (action.type) {
        case DashboardActionTypes.SET_NOTE_ENTITY_ID:
            return action.payload;
        default:
            return state;
    }
}

export function saveEntireTileReducer(state: any = { tile: [] }, action: DashboardActions) {
    let index;
    switch (action.type) {
        case DashboardActionTypes.SAVE_TILE_INFO:
            state.tile = action.payload;
            return state;
        case DashboardActionTypes.SAVE_TILE_DATA:
            index = state.tile.findIndex(item => item.id === action.payload.id);
            if (index === -1) {
                state.tile.push(action.payload);
            } else {
                const _data = _lodash.cloneDeep(action.payload.data);
                state.tile[index].data = _data;
                // add isPrint for hide control
                state.tile[index].data['isPrint'] = true;
            }
            return state;
        case DashboardActionTypes.SAVE_TILE_CONFIG:
            index = state.tile.findIndex(item => item.id === action.payload.id);
            if (index === -1) {
                state = {
                    ...state,
                    tile: [...state.tile, action.payload]
                };
            } else {
                state.tile[index].config = action.payload.config;
            }
            return state;
        case DashboardActionTypes.SAVE_GRID_TILE_LAYOUT:
            index = state.tile.findIndex(item => item.id === action.payload.id);
            if (state.tile[index].config) {
                state.tile[index].config.layout = action.payload.layout;
            }
            return state;
        case DashboardActionTypes.RESET_TILE_DATA:
            index = state.tile.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.tile[index].data = action.payload.data;
            }
            return state;
        case DashboardActionTypes.CLEAR_PRINT_TILE:
            state.tile = [];
            return state;
        default:
            return state;
    }
}

const dashboardReducer = combineReducers({
    focus: focusReducer,
    print: saveEntireTileReducer,
    note: noteEntityReducer
});

export function getDashboardRedcuer(state, action) {
    return dashboardReducer(state, action);
}
