/**
 * Created by Alan Yang on 12/17/2018.
 * Description:
 * The datasource between filter body and filter widgets
 *
 * ------ maintenance history ------
 */
import { Subject } from 'rxjs';
import { FiltersConfig } from './filter-config';

export class FilterWidgetConfig {
    // child -> parent
    isShowDynamicDate?: boolean;
    isHiddenTrashIcon?: boolean;
    feedbackSubject$: Subject<FilterWidgetFeedback>;
    // parent -> child
    actionSubject$: Subject<any>;

    constructor() {
        this.isShowDynamicDate = false;
        this.isHiddenTrashIcon = true;
        this.feedbackSubject$ = new Subject<FilterWidgetFeedback>();
        this.actionSubject$ = new Subject<any>();
    }
}

/**
 *
 * update a Filter widget known as FiltersConfig data type or remove it.
 * @export
 * @class FilterWidgetFeedback
 */
export class FilterWidgetFeedback {
    type: FilterWidgetFeedbackType;
    payload?: FiltersConfig;
}

/**
 * update widget value or remove the widget
 * @export
 * @enum {update, remove}
 */
export enum FilterWidgetFeedbackType {
    update,
    remove
}
