
import { Directive, ElementRef, HostListener, OnInit, Renderer2, Input, AfterContentInit, Output, EventEmitter, OnDestroy } from '@angular/core';

@Directive({
    selector: '[tamTabDrag]'
})
export class TabDragDirective implements OnInit, AfterContentInit, OnDestroy {

    private _dragging = false;
    private _disX = 0;
    private _disY = 0;
    private _createMoveDiv: HTMLElement;
    private _tabComponent: HTMLElement;
    private _tabSetComponent: HTMLElement;
    private _mousemoveListener;
    private _moveupListener;

    // tslint:disable-next-line: no-input-rename
    @Input('draggable') draggable: boolean;
    // tslint:disable-next-line: no-input-rename
    @Input('showInput') showInput: boolean;
    // tslint:disable-next-line: no-output-rename
    @Output('documentmouseup') documentmouseup: EventEmitter<any> = new EventEmitter();

    constructor(private el: ElementRef, private r2: Renderer2) { }

    ngOnInit() {
    }

    ngAfterContentInit() {
        this._tabComponent = this.el.nativeElement.parentNode.parentNode;
        this._tabSetComponent = this.el.nativeElement.parentNode.parentNode.parentNode;
        this.listenerHandler();
    }
    ngOnDestroy(): void {
        if (this._mousemoveListener) {
            this._mousemoveListener();
        }
        if (this._moveupListener) {
            this._moveupListener();
        }
    }
    listenerHandler() {
        if (!this.draggable) {
            return;
        }
        if (!this._mousemoveListener) {
            this._mousemoveListener = this.r2.listen(this._tabSetComponent, 'mousemove', (event) => {
                if (!this.draggable) {
                    return;
                }
                if (this._dragging) {
                    const newdisX = event.clientX;
                    const newdisY = event.clientY;
                    let oLeft = newdisX - this._disX;
                    let oTop = newdisY - this._disY;
                    // control move range
                    if (oLeft < 0) {
                        oLeft = 0;
                    } else if (oLeft > this._tabSetComponent.clientWidth - this.el.nativeElement.offsetWidth) {
                        oLeft = this._tabSetComponent.clientWidth - this.el.nativeElement.offsetWidth;
                    }
                    if (oTop < 0) {
                        oTop = 0;
                    } else if (oTop > this._tabSetComponent.clientHeight - this.el.nativeElement.offsetHeight) {
                        oTop = this._tabSetComponent.clientHeight - this.el.nativeElement.offsetHeight;
                    }
                    this.r2.setStyle(this._createMoveDiv, 'top', oTop + 'px');
                    this.r2.setStyle(this._createMoveDiv, 'left', oLeft + 'px');
                }
            });
        }
        if (!this._moveupListener) {
            this._moveupListener = this.r2.listen('document', 'mouseup', () => {
                if (!this.draggable) {
                    return;
                }
                if (this._dragging) {
                    document.onmousemove = null;
                    document.onmouseup = null;
                    this._dragging = false;
                    this._createMoveDiv.innerHTML = '';
                    this.r2.removeChild(this.el.nativeElement.parentNode.parentNode, this._createMoveDiv);
                    this.documentmouseup.emit();
                }
            });
        }
    }
    createMoveDiv() {
        this._createMoveDiv = this.r2.createElement('div');
        this.r2.appendChild(this._tabComponent, this._createMoveDiv);
        this._createMoveDiv.innerHTML = this.el.nativeElement.innerHTML;
        this.r2.addClass(this._createMoveDiv, 'drag-create-Hover-div');
        this.r2.setStyle(this._createMoveDiv, 'width', this.el.nativeElement.clientWidth + 'px');
        this.r2.setStyle(this._createMoveDiv, 'height', this.el.nativeElement.clientHeight + 'px');
        this.r2.setStyle(this._createMoveDiv, 'top', this._disX + 'px');
        this.r2.setStyle(this._createMoveDiv, 'left', this._disY + 'px');
    }
    @HostListener('mousedown', ['$event']) onMousedown(event) {
        if (!this.draggable || this.showInput) {
            return;
        }
        if (typeof event.target.className === 'string' && event.target.className.indexOf('drag-title') !== -1) {
            this._dragging = true;
            this._disX = event.clientX - this.el.nativeElement.offsetLeft;
            this._disY = event.clientY - this.el.nativeElement.offsetTop;
            this.createMoveDiv();
        }
    }
}
