/**
 * Created by Abner Sui on 07/31/2020
 * -------------------------------------
 * updated by Simon Zhao on 11/30/2021 added a static function responsible for converting field type into control type.
 */

import { Field } from '../../../tamalelibs/models/workflow.model';
import { Template } from '../../template-configuration/template-configuration.model';
import { ControlType } from './../../../tamalelibs/models/template-control.model';
import { FieldType } from './../../../tamalelibs/models/workflow.model';
import { CheckListItemModel } from './check-list-config/check-list-config.component';

export class FieldConfig extends Template {
    checkItems?: Array<CheckListItemModel>;
    field?: Field;
    editable?: boolean;
    // TODO
    label: string;
    description?: string;
    required: boolean;
    fieldDefinitionId: string;
    source?: Array<string>;
    isRelationshipChecked?: boolean;
    relationshipTypeId?: string;
    customFieldId?: string;
    staticDefaultValue?: any;
    minlength?: number;
    maxlength?: number;
    maxlines?: number;
    min?: number;
    max?: number;
    separator?: boolean;
    decimals?: number;
    isReadOnly?: boolean;
    isRequiredDisabled?: boolean;
    isTriggerEntityAsDefaultEnabled?: boolean;
    isCurrentUserAsDefaultEnabled?: boolean;
    isChangeAfterSubmissionEnabled?: boolean;
    static convertFieldTypeToControlType(fType: FieldType) {
        let ctrlType = 'other';
        switch (fType) {
            case FieldType.ENTITY:
            case FieldType.User:
                ctrlType = ControlType.MULTI_ENTITY_DROP_DOWN;
                break;
            case FieldType.DATE:
            case FieldType.TEXT:
            case FieldType.NUMBER:
            case FieldType.FILE:
                ctrlType = fType.toString();
                break;
            default:
                break;
        }

        return <ControlType>ctrlType;
    }

    static isFieldDisabled(fldCfg: FieldConfig): boolean {
        return !fldCfg || !fldCfg.editable || fldCfg.disabled || fldCfg.isReadOnly;
    }
}

export enum FieldEvents {
    VALUE_CHANGE = 'VALUE_CHANGE',
    FILE_OPERATING = 'FILE_OPERATING',
    VALIDATE_CHANGE = 'VALIDATE_CHANGE',
}

export enum FieldActions {
    SHOW_VALIDATE_REQUIRE = 'SHOW_VALIDATE_REQUIRE',
}

export enum TaskDefinitionTemplateEvents {
    SAVE = 'SAVE',
    SAVE_SUCCESS = 'SAVE_SUCCESS',
}

export class FieldValidationError {

    isRequiredInvalid = false;

    isWidthInvalid = false;

    isLableDuplicatedInvalid = false;

    isSourceInvalid = false;

    static isFieldValid(fieldError: FieldValidationError) {
        return !fieldError.isLableDuplicatedInvalid && !fieldError.isWidthInvalid && !fieldError.isRequiredInvalid && !fieldError.isSourceInvalid;
    }
}
