/**
 * Created by Abner Sui on 09/14/2020
 * -------------------------------------
 */

import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FieldConfig, FieldEvents, FieldActions } from './field.model';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { UtilsService } from '../../../tamalelibs/services/utils.service';
import { Subscription, fromEvent } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WorkflowService } from '../../../tamalelibs/services/workflow.service';

@Component({
    selector: 'tam-number-field',
    templateUrl: './number-field.component.html',
    styleUrls: ['./field.component.scss']
})
export class NumberFieldComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input()
    config: FieldConfig;

    @ViewChild('kendo', { static: false }) kendo;
    @ViewChild('anchor', { static: false }) anchor;
    @ViewChild(TooltipDirective, { static: false })
    tooltipDir: TooltipDirective;
    /**
         * a flag indicating whether current field control is disabled.
         */
    get isDisabled() {
        return FieldConfig.isFieldDisabled(this.config);
    }
    isIPAD = false;
    invalid = false;
    requireInvalid = false;
    format = {
        style: 'decimal',
        useGrouping: true,
        maximumFractionDigits: 9,
        minimumFractionDigits: 0,
    };

    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _utils: UtilsService,
        private _deviceService: DeviceDetectorService,
        private _workflowService: WorkflowService,
    ) { }

    ngOnInit() {
        if (this.config.disabled) {
            return;
        }
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        this.format = this._workflowService.getFormatForNumber(this.config.field);
        this.invalid = this.validate();
        this._destroySubscriptions.push(
            this.config.config.actionSubject$.subscribe(action => this._onAction(action)),
        );
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validate() || this.validateRequire(),
            },
        });
    }

    ngAfterViewInit() {
        if (this.isIPAD && this.config.field.fieldDefinition.description) {
            this._destroySubscriptions.push(
                fromEvent(document, 'click').subscribe((event) => {
                    this.showTooltip(event, this.anchor, false);
                }),
            );
        }
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    onKeyup(event) {
        if (event.keyCode === 13) { // Enter
            this.kendo.blur();
        }
    }

    showTooltip(event, target, show: boolean): void {
        if (show === null) {
            this.tooltipDir.toggle(target);
        } else {
            this.tooltipDir.toggle(target, show);
        }
        this._utils.emptyClick(event);
    }

    validate(): boolean {
        return (this.config.field.value !== null && this.config.field.value !== undefined)
            && ((this.config.min !== null && this.config.min !== undefined && this.config.min > this.config.field.value)
                || (this.config.max !== null && this.config.max !== undefined && this.config.max < this.config.field.value));
    }

    validateRequire(): boolean {
        if (this.config.editable && this.config.required && !this.config.field.value) {
            return true;
        } else {
            return false;
        }
    }

    valueChange(event): void {
        this.requireInvalid = false;
        this.config.field.value = event;

        this.invalid = this.validate();
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALUE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                value: this.config.field.value,
            },
        });
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validate() || this.validateRequire(),
            },
        });
    }

    private _onAction(action) {
        if (action.type === FieldActions.SHOW_VALIDATE_REQUIRE) {
            this.requireInvalid = this.validateRequire();
        }
    }

}
