/**
 * Created by Tamale Dev.
 *  Description:
 *  Provides various dialogs including confirmation dialog, info dialog, warninng dialog and so on.
 * ------ maintenance history ------
 * 10/28/2022 Simon Zhao - Added a compact view for add-ins.
 *
 */
import { Component, OnInit, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { AlertConfig, AlertTypes, AlertBtnTypes, AlertViewType, COMPACT_VIEW_WIDTH } from './alert-window.model';
import { AlertWindowService } from './alert-window.service';
import { Subscription } from 'rxjs';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-alert-window',
    templateUrl: './alert-window.component.html',
    styleUrls: ['./alert-window.component.scss']
})
export class AlertWindowComponent implements OnInit, OnDestroy {

    private _destroySubscriptions: Array<Subscription> = [];
    private _el: HTMLElement;
    private _hideSideSheet = false;
    open = false;

    iconName: string;
    iconColor: string;
    isResetHorizontalMargin = false;
    title: string = AlertTypes.info.toString();
    contents: string[];
    type: AlertTypes;
    multipleButton = false;
    closeButton: any = {};
    alertWindowWidth = 410;
    confirmButton: any = {};
    alertCloseClass: any = {};
    extraButton: any;
    config: AlertConfig;
    threaDetailSidesheetStatus = false;
    /**
     * The view type, normal for tamale web and compact for tamale add-in.
     */
    viewType: AlertViewType = AlertViewType.NORMAL;

    /**
     * A flag indicating whether current view type is compact.
     */
    get isCompactView() {
        return this.viewType === AlertViewType.COMPACT;
    }

    get viewMinWidth() {
        if (this.isCompactView) {
            return COMPACT_VIEW_WIDTH;
        } else {
            return 410;
        }
    }

    get viewWidth() {
        if (this.isCompactView) {
            return COMPACT_VIEW_WIDTH;
        } else {
            return this.alertWindowWidth;
        }
    }

    private _closeButton = {
        text: 'OK',
        type: AlertBtnTypes.primary,
        visible: true
    };
    private _confirmButton = {
        text: 'Discard',
        type: AlertBtnTypes.primary,
        visible: true
    };
    private alertCloseClass1: any = {
        'alert-close': false
    };
    private alertCloseClass2: any = {
        'alert-close': true
    };

    constructor(private _service: AlertWindowService,
        private renderer: Renderer2,
        private _element: ElementRef) {
        this._el = this._element.nativeElement;
    }

    ngOnInit() {
        this._destroySubscriptions.push(
            this._service.alert$.subscribe((config: AlertConfig) => {
                this.initConfig(config);
            })
        );
        this._destroySubscriptions.push(
            this._service.close$.subscribe(() => {
                this.close(null);
            })
        );
        this._destroySubscriptions.push(
            this._service.openForSideSheet$.subscribe((res) => {
                if (res) {
                    this._hideSideSheet = true;
                } else {
                    this._hideSideSheet = false;
                }
                this.threaDetailSidesheetStatus = true;
            })
        );
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    close(event) {
        this.openClose(false);
        this._service.result$.next(false);
        this.reset(event);
        if (event) {
            event.stopImmediatePropagation();
        }
    }

    confirm(event) {
        this.openClose(false);
        this._service.result$.next(true);
        // remove class threaddetail-sidesheet-status-alertwindow
        // TODO: Handle this by pass html
        if (this._hideSideSheet) {
            if (this._el.querySelector('.threaddetail-sidesheet-status-alertwindow')) {
                this.renderer.removeClass(this._el.querySelector('.threaddetail-sidesheet-status-alertwindow'), 'threaddetail-sidesheet-status-alertwindow');
            }
        }
        this.reset(event);
    }

    extra(event) {
        this.openClose(false);
        this._service.result$.next(null);
        this.reset(event);
    }

    getIconColor(): string {
        switch (this.type) {
            case AlertTypes.info:
                return '#05a1c1';
            case AlertTypes.warn:
            case AlertTypes.confirm:
                return '#da8945';
            case AlertTypes.error:
                return '#f53249';
            default:
                return '#05a1c1';
        }
    }

    getIconName(): string {
        switch (this.type) {
            case AlertTypes.info:
                return 'info';
            case AlertTypes.warn:
            case AlertTypes.confirm:
                return 'exclamation-circle';
            case AlertTypes.error:
                return 'error';
            default:
                return 'info';
        }
    }

    initConfig(config: AlertConfig) {
        this.config = config;
        if (config) {
            this.type = config.type;
            if (config.icon && config.icon.name) {
                this.iconName = config.icon.name;
            } else {
                this.iconName = this.getIconName();
            }
            if (config.icon && config.icon.color) {
                this.iconColor = config.icon.color;
            } else {
                this.iconColor = this.getIconColor();
            }
            this.title = config.title || AlertTypes.info.toString();
            this.contents = config.contents || [];
            this.closeButton = config.closeButton || this._closeButton;
            this.alertWindowWidth = config.alertWindowWidth ? config.alertWindowWidth : 410;
            this.isResetHorizontalMargin = config.isResetHorizontalMargin;
            if (config.type === AlertTypes.custom) {
                if (config.confirmButton.visible && config.closeButton.visible) {
                    this.multipleButton = true;
                    this.alertCloseClass = this.alertCloseClass2;
                } else {
                    this.multipleButton = false;
                    this.alertCloseClass = this.alertCloseClass1;
                }
                this.confirmButton = config.confirmButton || this._confirmButton;
            } else if (config.type === AlertTypes.warn) {
                this.multipleButton = true;
                this.alertCloseClass = this.alertCloseClass2;
                this.confirmButton = config.confirmButton || this._confirmButton;
            } else if (config.type === AlertTypes.confirm) {
                this.multipleButton = true;
                this.alertCloseClass = this.alertCloseClass2;
                this.confirmButton = config.confirmButton || this._confirmButton;
                this.extraButton = config.extraButton;
            } else {
                this.multipleButton = false;
                this.alertCloseClass = this.alertCloseClass1;
            }

            if (config.viewType && config.viewType === AlertViewType.COMPACT) {
                this.viewType = AlertViewType.COMPACT;
            }
            this.openClose(true);
        }
    }

    onClick(event) {
        if (this.config && this.config.eventKey) {
            event[this.config.eventKey] = this.config.eventValue;
        }
    }

    openClose(oc: boolean) {
        this.open = oc;
    }

    reset(event) {
        this.onClick(event);
        this.multipleButton = false;
        this.alertCloseClass = {};
        this.closeButton = {};
        this.confirmButton = {};
        this.extraButton = null;
        this.threaDetailSidesheetStatus = false;
        this._hideSideSheet = false;
        this.config = null;
        this.viewType = AlertViewType.NORMAL;
    }

}
