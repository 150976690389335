/**
 * Created by Abner Sui on 01/02/19.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';

@Injectable()
export class CopyService {

    private _copyArea: any;

    constructor(private _platformService: PlatformService) { }

    set copyArea(value: any) {
        this._copyArea = value;
    }

    public copy(value: string): boolean {
        this._copyArea.value = value;
        this._copyArea.focus();
        this._copyArea.select();
        try {
            const success = document.execCommand('copy');
            return success;
        } catch (e) {
            return false;
        } finally {
            this._platformService.cleanWindowSelection();
            this._copyArea.value = '';
        }
    }

}
