/**
 * Created by Marcus Zhao on 7/1/2022.
 * Description:
 * ContactListGuard
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringLiteralsPipe } from '../../pipes/translate.pipe';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { ContactListComponent } from './contact-list.component';

@Injectable({
    providedIn: 'root'
})
export class ContactListGuard implements CanDeactivate<ContactListComponent> {
    constructor(
        private _alertWindow: AlertWindowService,
    ) { }
    canDeactivate(component: ContactListComponent): Observable<boolean> | Promise<boolean> | boolean {
        if (component.isProgressing) {
            return this._alertWindow.warn(StringLiteralsPipe.translate('crm.exit_warning_1'),
                [StringLiteralsPipe.translate('crm.exit_warning_2')], 'Yes', 'Go back')
                .pipe(map((result: boolean) => {
                    if (result) {
                        if (component.interval) {
                            clearInterval(component.interval);
                        }
                    }
                    return result;
                }));
        } else {
            return true;
        }
    }
}
