/**
 * Created by Alan Yang on 8/3/17.
 * Description:
 * the logic is imported from tamale nextgen jsTreeER.js.
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { ArrayHelperService } from './array-helper.service';

@Injectable()
export class JstreeERService {
    constructor() { }

    public static parseJSTree(data) {
        // Compose tree nodes
        // data: response data
        // root: tree root node object
        // field: tree node display value: short-name
        const root = {
            'long-name': data['long-name'], 'short-name': data['short-name'], 'id': data['id'],
            'entity-type': data['entity-type']['data']['name']
        };
        const itemsObject = [];
        const contacts = [];
        const teamNodes = [];
        const entityNodes = [];
        const contactNodes = [];
        let teamMemberNodes = [];
        const itemList = data['relationships'] && data['relationships']['data'] ? data['relationships']['data'] : [];
        for (let i = 0; i < itemList.length; i++) {
            if (itemList[i]['data']['relationship-type']['link']['phid'] !== 'Topic Member') {
                if (data['entity-type']['data']['name'] === 'Team'
                    || itemList[i]['data']['relationship-type']['link']['phid'] !== 'is in team') {
                    let existed = false;
                    let hrefType;
                    for (let j = 0; j < itemsObject.length; j++) {
                        if (itemsObject[j] && itemsObject[j]['text'] === itemList[i]['link']['phid']) {
                            hrefType = 'contact';
                            if (itemList[i]['data']['child-entity']['data']['contact-details'] === undefined) {
                                hrefType = 'entity';
                            }
                            itemsObject[j]['children'][itemsObject[j]['children'].length] = {
                                'text': itemList[i]['data']['child-entity']['data']['short-name'],
                                'icon': 'none',
                                'id': itemList[i]['data']['child-entity']['data']['id'] + '_' + itemList[i]['data']['id']
                                    + '_' + hrefType + '_' + itemList[i]['data']['child-entity']['data']['entity-type']['data']['id'],
                                'a_attr': {
                                    'href': itemList[i]['data']['child-entity']['data']['id'],
                                    'shortname': itemList[i].data['child-entity'].data['short-name'],
                                    'longname': itemList[i].data['child-entity'].data['long-name']
                                }
                            };
                            existed = true;
                            break;
                        }
                    }
                    if (!existed) {
                        const arr = [];

                        // the href is different between entity and contact here
                        hrefType = 'contact';
                        if (itemList[i]['data']['child-entity']['data']['contact-details'] === undefined) {
                            contacts[i] = 0;
                            hrefType = 'entity';
                        } else {
                            contacts[i] = 1;
                        }
                        arr[0] = {
                            'text': itemList[i]['data']['child-entity']['data']['short-name'],
                            'icon': 'none',
                            'id': itemList[i]['data']['child-entity']['data']['id'] + '_' + itemList[i]['data']['id']
                                + '_' + hrefType + '_' + itemList[i]['data']['child-entity']['data']['entity-type']['data']['id'],
                            'a_attr': {
                                'href': itemList[i]['data']['child-entity']['data']['id'],
                                'shortname': itemList[i].data['child-entity'].data['short-name'],
                                'longname': itemList[i].data['child-entity'].data['long-name']
                            }
                        };

                        itemsObject[i] = {
                            'text': itemList[i]['link']['phid'],
                            'state': { 'opened': true },
                            'children': arr
                        };
                        if (itemList[i]['link']['href']) {
                            itemsObject[i].id = 'notforfilter'
                                + itemList[i]['link']['href'].substr(itemList[i]['link']['href'].length - 33, 32);
                        }
                    }
                } else {
                    teamNodes.push({
                        'id': itemList[i]['data']['parent-entity']['data']['id'] + '_' + itemList[i]['data']['id']
                            + '_Team' + '_' + itemList[i]['data']['parent-entity']['data']['entity-type']['data']['id'],
                        'text': itemList[i]['data']['parent-entity']['data']['short-name'],
                        'icon': 'none',
                        'a_attr': {
                            'href': itemList[i]['data']['parent-entity']['data']['id'],
                            'shortname': itemList[i].data['parent-entity'].data['short-name'],
                            'longname': itemList[i].data['parent-entity'].data['long-name']
                        }
                    });
                }
            }
        }
        for (let m = 0; m < contacts.length; m++) {
            if (itemsObject[m]) {
                if (contacts[m] === 1) {
                    if (itemsObject[m].text === 'is in team') {
                        itemsObject[m].text = 'Team Members';
                        teamMemberNodes = itemsObject[m].children;
                        continue;
                    }
                    contactNodes[contactNodes.length] = itemsObject[m];
                } else {
                    entityNodes[entityNodes.length] = itemsObject[m];
                }
            }
        }

        return this.setTree(entityNodes, contactNodes, teamNodes, teamMemberNodes, root);
    }

    public static setTree(entityNodes, contactNodes, teamNodes, teamMemberNodes, root) {
        const rootName = root['short-name'];
        const rootNode = {
            'text': rootName,
            'id': root['id'] + '_' + '0' + '_'
                + (root['entity-type'].toLowerCase() === 'contact' ? 'contact' :
                    (root['entity-type'].toLowerCase() === 'team' ? 'team' : 'entity')) + '_' + root['short-name'],
            'state': {
                'opened': true
            },
            'children': []
        };
        if (teamMemberNodes.length > 0) {
            teamMemberNodes.sort(ArrayHelperService.orderBy('text'));
            rootNode.children.push({
                'id': 'notforfilterteamMembers',
                'text': 'Team Members',
                'state': {
                    'opened': true
                },
                'children': teamMemberNodes
            });
        }
        if (entityNodes.length > 0) {
            entityNodes.sort(ArrayHelperService.orderBy('text'));
            for (let i = 0; i < entityNodes.length; i++) {
                entityNodes[i]['children'].sort(ArrayHelperService.orderBy('text'));
            }
            rootNode.children.push({
                'id': 'notforfilterentities',
                'text': 'Entities',
                'state': {
                    'opened': true
                },
                'children': entityNodes
            });
        }
        if (contactNodes.length > 0) {
            contactNodes.sort(ArrayHelperService.orderBy('text'));
            for (let j = 0; j < contactNodes.length; j++) {
                contactNodes[j]['children'].sort(ArrayHelperService.orderBy('text'));
            }
            rootNode.children.push({
                'id': 'notforfiltercontacts',
                'text': 'Contacts',
                'state': {
                    'opened': true
                },
                'children': contactNodes
            });
        }
        if (teamNodes.length > 0) {

            teamNodes.sort(ArrayHelperService.orderBy('text'));
            rootNode.children.push({
                'id': 'notforfilterteams',
                'text': 'Teams',
                'state': {
                    'opened': true
                },
                'children': teamNodes
            });
        }

        return rootNode;
    }
}
