/**
 * Created by Abner Sui on 01/03/19.
 * Description:
 *
 * ------ maintenance history ------
 * Updated by Daniel Wang on 6/2/2022, Added getSidenoteInfoBySettingRule to support defult side note type
 * Updated by Daniel Wang on 8/2/2022, Got note type from server if the entry type which is getten from rule is available
 * Updated by Daniel Wang on 9/2/2022, Sidenote type is not avaiable. Sidenote type -> Parent note type -> Default note type -> Tamale Note
 */
import { Injectable } from '@angular/core';
import { WindowRef } from './window-ref';
import { Router } from '@angular/router';
import { ToastService } from '../widgets/toast/toast.service';
import { CopyService } from './copy.service';
import { NotificationStyles, NotificationOptions } from '../widgets/notification/notification.model';
import { EntryClass } from '../tamalelibs/models/entry-class.model';
import { businessConstants } from '../constants/business.constants';
import { businessConstants as businessConstantsLibs } from '../tamalelibs/constants/business.constants';
import { EntryService } from '../tamalelibs/services/entry.service';
import { EntryTypeService } from '../tamalelibs/services/entry-type.service';
import { ArrayHelperService } from '../tamalelibs/services/array-helper.service';
import { StoreQuerierService } from './store-querier.service';
import { availableEntryTypeSelector, defaultEntryTypeSelector } from '../redux/reducers/entry-type.reducer';
import { StringLiteralsPipe } from '../pipes/translate.pipe';

@Injectable()
export class NoteService {

    constructor(
        private _copyService: CopyService,
        private _entryService: EntryService,
        private _entryTypeService: EntryTypeService,
        private _router: Router,
        private _storeQuerier: StoreQuerierService,
        private _toast: ToastService,
        private _winRef: WindowRef,
    ) { }

    public emailNote(subject: string, body: string) {
        subject = subject || '';
        window.location.href = 'mailto:?subject=' + encodeURIComponent(subject) + '&body=' + body;
    }

    public getShareNoteLink(entryId: string, entryClass): string {
        const win = this._winRef.nativeWindow;
        const prefixIndex = win.location.href.indexOf(this._router.url);
        let typeName = '/entry/';
        if (entryClass === EntryClass.Event) {
            typeName = '/event/';
        }
        const prefixUrl = win.location.href.substring(0, prefixIndex) + typeName;
        return prefixUrl + entryId;
    }

    public getShareNoteEmailBody(sender: string, subject: string, noteLink: string): string {
        subject = subject || '';
        return encodeURIComponent(sender) + ' has shared a Tamale Web link with you:%0D%0A%0D%0A ' + encodeURIComponent(subject) + ' %0D%0A' + noteLink;
    }

    /**
     * @param data used to get thread information by research / calenar
     * @param notesInThread the total notes in thread
     * @returns entryId and entryTypeId
     */
    getSidenoteInfoBySettingRule(notes: any, totalNotesInThread: number): any {
        let entryId = '';
        let entryTypeId = '';
        // get note type from store
        const availableEntryTypes = this._storeQuerier.queryBySelector(availableEntryTypeSelector);

        let entryBySideNoteFollowRule = null;
        const systemConfig = this._storeQuerier.queryBySelector(businessConstants.globalConfig.systemConfig);
        if (systemConfig) {
            entryBySideNoteFollowRule = this._entryService.getEntryBySideNoteFollowRule(systemConfig, notes, totalNotesInThread);
        }
        if (entryBySideNoteFollowRule) {
            const _entryTypeId = this._entryTypeService.getDefaultSidenoteType(entryBySideNoteFollowRule.type);
            if (_entryTypeId) {
                const _entryType = availableEntryTypes.filter(item => item.id === _entryTypeId)[0];
                if (_entryType) {
                    if (ArrayHelperService.arrayContains(_entryType.usage, businessConstants.EntryTypes.note)) {
                        entryId = entryBySideNoteFollowRule.id;
                        entryTypeId = _entryTypeId;
                    } else {
                        entryId = entryBySideNoteFollowRule.id;
                        entryTypeId = entryBySideNoteFollowRule.type.id;
                    }
                } else {
                    // get parent entry
                    const _parentEntry = notes.filter(item => item.threadPosition === 0)[0];
                    // whether parent entry type is available or not
                    const availableParentEntryType = availableEntryTypes.filter(item => item.id === _parentEntry.type.id)[0];
                    // whether parent entry type is available for note
                    const isAvailableParentEntryTypeForNote = _parentEntry.type.usage.includes(StringLiteralsPipe.translate('general.note'));
                    if (availableParentEntryType && isAvailableParentEntryTypeForNote) {
                        entryTypeId = availableParentEntryType.id;
                        entryId = _parentEntry.id;
                    } else {
                        // whether default note type is available or not
                        const defaultEntryType = this._storeQuerier.queryBySelector(defaultEntryTypeSelector);
                        const availableDefaultEntryType = availableEntryTypes.filter(item => item.id === defaultEntryType.id)[0];
                        if (availableDefaultEntryType) {
                            entryTypeId = availableDefaultEntryType.id;
                            entryId = _parentEntry.id;
                        } else {
                            // set Tamale_Note as entry type if no available entry type
                            entryTypeId = businessConstantsLibs.EntryTypes.Tamale_Note_Id;
                            entryId = _parentEntry.id;
                        }
                    }
                }
            } else {
                entryId = entryBySideNoteFollowRule.id;
                entryTypeId = entryBySideNoteFollowRule.type.id;
            }
        } else {
            return null;
        }
        return {
            entryId: entryId,
            entryTypeId: entryTypeId
        };
    }

    public shareNote(entryId, entryClass) {
        const win = this._winRef.nativeWindow;
        const prefixIndex = win.location.href.indexOf(this._router.url);
        let fileUrl = win.location.href.substring(0, prefixIndex);
        if (entryClass === EntryClass.Event) {
            fileUrl = fileUrl + '/event/';
        } else {
            fileUrl = fileUrl + '/entry/';
        }
        if (entryId) {
            fileUrl += entryId;
        }
        const success = this._copyService.copy(fileUrl);
        let toast: NotificationOptions;
        if (success) {
            toast = {
                message: 'Sharing url copied to clipboard',
                style: NotificationStyles.Success
            };
        } else {
            toast = {
                message: 'Sharing url copy failure',
                style: NotificationStyles.Error
            };
        }
        this._toast.notify(toast);
    }

}
