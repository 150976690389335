import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FiltersConfig, FilterMetadataType } from '../components/filter-config';
import { TSGuid } from '../models/ts-guid.model';
import { FilterService } from './filter.service';
import { CURRENT_USER } from '../constants/business.constants';

export const FilterTitleType = {
    'Note Type': 'NoteType',
    'Entity Type': 'EntityType',
    'Display Date': 'DisplayDate',
    'Submitted Date': 'SubmittedDate',
    'Source': 'Source',
    'Submitter': 'Submitter',
    'Priority': 'Priority',
    'Sentiment': 'Sentiment',
    'Subject': 'Subject',
    'Team': 'Team',
    'Start Date': 'DisplayDate',
    'Entities': 'EntryEntity'
};
export const DateOperateType = {
    '1': 'last',
    '2': 'range',
    '3': 'next',
    '4': 'before',
    '5': 'after',
    '6': 'is null',
    '7': 'is not null',
    '8': 'dynamicDate',
    '9': 'today'
};

@Injectable()
export class TileFilterService {
    constructor(
        private _filterService: FilterService,
        private _datepipe: DatePipe,
    ) { }

    getOptions(metadataType, name?) {
        let fields = [];
        let selectedOperator;
        let operators = [];
        let dataType = '';
        switch (metadataType) {
            case FilterMetadataType.DisplayDate:
                fields = ['entry__display_date'];
                selectedOperator = this._filterService.getDefaultOperator(metadataType);
                operators = this._resetDateOperateBlankValue(metadataType, false, name);
                dataType = 'date';
                break;
            case FilterMetadataType.SubmittedDate:
                fields = ['entry__submitted_date'];
                selectedOperator = this._filterService.getDefaultOperator(metadataType);
                operators = this._resetDateOperateBlankValue(metadataType);
                dataType = 'date';
                break;
            case FilterMetadataType.Date:
                selectedOperator = this._filterService.getDefaultOperator(metadataType);
                operators = this._resetDateOperateBlankValue(metadataType, true, name);
                dataType = 'date';
                break;
            case FilterMetadataType.Team:
                fields = ['entry__team'];
                selectedOperator = this._filterService.getDefaultOperator(metadataType);
                operators = this._filterService.getOperatorOptions(metadataType);
                dataType = 'team';
                break;
            case FilterMetadataType.Priority:
                fields = ['entry__priority'];
                // operators name same filter service but value not same
                selectedOperator = { name: 'Greater than', value: '>' };
                operators = [
                    { name: 'Greater than', value: '>' },
                    { name: 'Greater than or equal to', value: '>=' },
                    { name: 'Equals', value: '=' },
                    { name: 'Not equal to', value: '<>' },
                    { name: 'Less than', value: '<' },
                    { name: 'Less than or equal to', value: '<=' }
                ];
                dataType = 'priority';
                break;
            case FilterMetadataType.Sentiment:
                fields = ['entry__sentiment'];
                // operators name same filter service but value not same
                selectedOperator = { name: 'Includes', value: 'in' };
                operators = [
                    { name: 'Includes', value: 'in' },
                    { name: 'Excludes', value: 'not in' }
                ];
                dataType = 'sentiment';
                break;
            case FilterMetadataType.Source:
            case FilterMetadataType.Submitter:
                fields = [`entry__${metadataType.toLowerCase()}_id`];
                selectedOperator = { name: 'Includes', value: 'in' };
                operators = [
                    { name: 'Includes', value: 'in' },
                    { name: 'Excludes', value: 'not in' }
                ];
                dataType = 'contact';
                break;
            case FilterMetadataType.EntityType:
            case FilterMetadataType.NoteType:
                if (metadataType === FilterMetadataType.EntityType) {
                    fields = ['entity__type_id'];
                } else {
                    fields = ['entry__type_id'];
                }
                selectedOperator = { name: 'Includes', value: 'in' };
                operators = [
                    { name: 'Includes', value: 'in' },
                    { name: 'Excludes', value: 'not in' }
                ];
                dataType = metadataType.toLowerCase();
                break;
            case FilterMetadataType.Subject:
            case FilterMetadataType.TextBox:
                fields = ['entry__title'];
                // operators not same filter service
                selectedOperator = { name: 'Equals To', value: '=' };
                operators = [
                    { name: 'Equals To', value: '=' },
                    { name: 'Includes', value: 'like' },
                    { name: 'Excludes', value: 'not like' },
                    { name: 'is Blank', value: 'is null' },
                    { name: 'Not Blank', value: 'is not null' },
                    { name: 'Not Equal To', value: '<>' },
                    { name: 'In', value: 'in' },
                    { name: 'Not In', value: 'not in' },
                ];
                dataType = 'text';
                break;
            case FilterMetadataType.Entity:
                selectedOperator = { name: 'Includes', value: 'anyof' };
                operators = [
                    { name: 'Includes', value: 'anyof' },
                    { name: 'Equals', value: 'allof' },
                    { name: 'Excludes', value: 'noneof' },
                    { name: 'is Blank', value: 'is null' },
                    { name: 'Not Blank', value: 'is not null' }
                ];
                dataType = 'entity';
                break;
            case FilterMetadataType.EntryEntity:
                fields = ['entry__entity'];
                selectedOperator = { name: 'Includes', value: 'entityContains' };
                operators = [
                    { name: 'Includes', value: 'entityContains' },
                    { name: 'Equals', value: 'entityEquals' }
                ];
                dataType = 'entity';
                break;
            default:
                break;
        }
        return { fields, selectedOperator, operators, dataType };
    }

    getNewFilterDefaultInstance(title: string, metadataType: string, hideOperators = false,
        disabledDel = false, disable = false): FiltersConfig {
        const filtersConfig = new FiltersConfig();

        filtersConfig.metadataType = metadataType;
        filtersConfig.id = new TSGuid().toString();

        filtersConfig.fields = this.getOptions(metadataType).fields;
        filtersConfig.selectedOperator = this.getOptions(metadataType).selectedOperator.value;
        filtersConfig.operators = this.getOptions(metadataType).operators;
        filtersConfig.dataType = this.getOptions(metadataType).dataType;

        filtersConfig.value = this._filterService.getDefaultValue(metadataType);
        filtersConfig.source = this._filterService.generateFilterSource(metadataType);
        filtersConfig.title = title;
        filtersConfig.disable = disable;
        filtersConfig.deletable = disabledDel;
        filtersConfig.hideOperators = hideOperators;
        if (metadataType === FilterMetadataType.Source || metadataType === FilterMetadataType.Submitter) {
            filtersConfig.currentUser = CURRENT_USER;
        }
        return filtersConfig;
    }

    getAdhocFilterDefaultInstance(data): FiltersConfig {
        const filtersConfig = new FiltersConfig();

        filtersConfig.id = new TSGuid().toString();
        if (data.dataType === 'entity') {
            data.metadataType = FilterMetadataType.Entity;
        } else if (data.dataType === 'date') {
            if (data.name === 'Start Date' && data.value === 'DisplayDate') {
                data.metadataType = FilterMetadataType.DisplayDate;
            } else {
                data.metadataType = FilterMetadataType.Date;
            }
        } else {
            data.metadataType = FilterMetadataType.TextBox;
        }
        filtersConfig.dataType = data.dataType;
        filtersConfig.metadataType = data.metadataType;
        if (data.attrDefId) {
            filtersConfig.fields = [`entryadhoc__${data.attrDefId[0]}`];
        } else if (data.field && data.field.indexOf('entryadhoc') !== -1) {
            filtersConfig.fields = [data.field];
        } else {
            filtersConfig.fields = this._filterService.getFields(filtersConfig.metadataType);
        }
        filtersConfig.selectedOperator = this.getOptions(data.metadataType).selectedOperator.value;
        if (data.dataType === 'date' && data.name === 'Start Date' && data.value === 'DisplayDate') {
            filtersConfig.operators = this._resetDateOperateBlankValue(FilterMetadataType.DisplayDate, false, data.name);
            filtersConfig.fields = this.getOptions(filtersConfig.metadataType).fields;
        } else if (data.dataType === 'date' && data.name === 'EndDate') {
            filtersConfig.operators = this._resetDateOperateBlankValue(FilterMetadataType.DisplayDate, false, data.name);
        } else {
            filtersConfig.operators = this.getOptions(data.metadataType).operators;
        }
        filtersConfig.value = this._filterService.getDefaultValue(data.metadataType);
        filtersConfig.title = data.name;
        filtersConfig.source = data.source;
        filtersConfig.disable = false;
        if (filtersConfig.metadataType === FilterMetadataType.Entity) {
            filtersConfig.currentUser = CURRENT_USER;
        }
        return filtersConfig;
    }

    generateAdvFilterForEntity(item, filters) {
        const value = item.value;
        let val = '';
        value.forEach((subItemId) => {
            val = val + ',\'' + (subItemId.id || subItemId) + '\'';
        });
        val = val ? '(' + val.substr(1) + ')' : '';
        const list = [];
        if (item.source && item.source.length > 0) {
            value.forEach(data => {
                const index = item.source.findIndex(obj => obj.value === data);
                if (index !== -1) {
                    list.push({
                        id: data,
                        // name: item.source[index].name
                    });
                }
            });
        } else {
            value.forEach(data => {
                list.push({
                    id: data.id,
                    // name: data.name,
                    // shortName: data.shortName,
                    // longName: data.name,
                    // isPublic: data.isPublic,
                    // company: data.company
                });
            });
        }
        if (item.selectedOperator === 'is null' || item.selectedOperator === 'is not null') {
            filters.push({
                field: item.fields[0],
                operator: item.selectedOperator,
                value: '',
                dataType: item.dataType,
                name: item.title
            });
        } else if (val) {
            filters.push({
                field: item.fields[0],
                operator: item.selectedOperator,
                value: val,
                dataType: item.dataType,
                name: item.title,
                list: list
            });
        }
    }

    generateAdvFilterForText(item, filters) {
        if (item.selectedOperator === '=' && item.value === '') {
            return;
        }
        // to handle the operator 'in' and 'not in'
        let value = item.value;
        if (item.metadataType === FilterMetadataType.Subject || item.metadataType === FilterMetadataType.TextBox) {
            if (item.selectedOperator === 'in' || item.selectedOperator === 'not in') {
                let val = '';
                if (value) {
                    value.split(',').forEach((_item) => {
                        if (_item) {
                            val = val + '\'' + _item + '\',';
                        }
                    });
                    val = val.substring(0, val.length - 1);
                    val = val ? '(' + val + ')' : '';
                    value = val;
                } else {
                    value = '(\'\')';
                }
            }
        }
        if (item.selectedOperator === 'is null' || item.selectedOperator === 'is not null') {
            filters.push({
                field: item.fields[0],
                operator: item.selectedOperator,
                value: '',
                dataType: item.dataType,
                name: item.title
            });
        } else {
            // need too handle the value for entry_entity because the server's requirement.
            if (item.metadataType === FilterMetadataType.EntryEntity) {
                filters.push({
                    field: item.fields[0],
                    operator: item.selectedOperator,
                    value: Array.isArray(value) ? value?.map(_item => _item.id).join(',') : value,
                    dataType: item.dataType,
                    name: item.title,
                    realValue: Array.isArray(item.value) ? item.value?.map(_item => _item.id).join(',') : item.value,
                });
            } else {
                filters.push({
                    field: item.fields[0],
                    operator: item.selectedOperator,
                    value: value,
                    dataType: item.dataType,
                    name: item.title,
                    realValue: item.value
                });
            }
        }
    }

    generateAdvFilterForDate(item, filters) {
        let value = '';
        let unit = '';
        let interval: number;
        switch (item.selectedOperator) {
            case 'last':
                if (item.value.indexOf('day') !== -1 || item.value.indexOf('week') !== -1) {
                    unit = item.value.indexOf('day') !== -1 ? 'days' : 'weeks';
                    value = `startoftoday('0')+interval '1 day'-interval '${item.value}' and endoftoday('0')`;
                } else if (item.value.indexOf('month') !== -1 || item.value.indexOf('year') !== -1) {
                    unit = item.value.indexOf('month') !== -1 ? 'months' : 'years';
                    value = `startoftoday('0')-interval '${item.value}' and endoftoday('0')`;
                }
                interval = parseInt(item.value.split(' ')[0], 10);
                filters.push({
                    field: item.fields[0],
                    operator: 'between',
                    dataType: item.dataType,
                    dateOperator: '1',
                    name: item.title,
                    interval: interval,
                    unit: unit,
                    value: value
                });
                break;
            case 'next':
                if (item.value.indexOf('day') !== -1 || item.value.indexOf('week') !== -1) {
                    unit = item.value.indexOf('day') !== -1 ? 'days' : 'weeks';
                    value = `startoftoday('0') and endoftoday('0')-interval '1 day'+interval '${item.value}'`;
                } else if (item.value.indexOf('month') !== -1 || item.value.indexOf('year') !== -1) {
                    unit = item.value.indexOf('month') !== -1 ? 'months' : 'years';
                    value = `startoftoday('0') and endoftoday('0')+interval '${item.value}'`;
                }
                interval = parseInt(item.value.split(' ')[0], 10);
                filters.push({
                    field: item.fields[0],
                    operator: 'between',
                    dataType: item.dataType,
                    dateOperator: '3',
                    name: item.title,
                    interval: interval,
                    unit: unit,
                    value: value
                });
                break;
            case 'range':
                const startDate = item.value.split(' ')[0];
                const endDate = item.value.split(' ')[1];
                filters.push({
                    field: item.fields[0],
                    operator: 'between',
                    dataType: item.dataType,
                    dateOperator: '2',
                    name: item.title,
                    startDate: this._datepipe.transform(startDate, 'yyyy-MM-dd'),
                    endDate: this._datepipe.transform(endDate, 'yyyy-MM-dd'),
                    value: '\'' + this._datepipe.transform(startDate, 'yyyy-MM-dd') +
                        ' 00:00:00.000+00\' and \'' + this._datepipe.transform(endDate, 'yyyy-MM-dd') + ' 23:59:59.999+00\''
                });
                break;
            case 'before':
                filters.push({
                    field: item.fields[0],
                    operator: '<',
                    dataType: item.dataType,
                    dateOperator: '4',
                    name: item.title,
                    halfRange: this._datepipe.transform(item.value, 'yyyy-MM-dd'),
                    value: '\'' + this._datepipe.transform(item.value, 'yyyy-MM-dd') + ' 00:00:00.000+00\''
                });
                break;
            case 'after':
                filters.push({
                    field: item.fields[0],
                    operator: '>',
                    dataType: item.dataType,
                    dateOperator: '5',
                    name: item.title,
                    halfRange: this._datepipe.transform(item.value, 'yyyy-MM-dd'),
                    value: '\'' + this._datepipe.transform(item.value, 'yyyy-MM-dd') + ' 23:59:59.999+00\''
                });
                break;
            case 'is null':
            case 'is not null':
                filters.push({
                    field: item.fields[0],
                    dateOperator: item.selectedOperator === 'is null' ? '6' : '7',
                    operator: item.selectedOperator,
                    dataType: item.dataType,
                    name: item.title,
                    value: ''
                });
                break;
            case 'dynamicDate':
                // add filter parameters for dynamic date
                filters.push({
                    field: item.fields[0], // field title
                    operator: 'dynamic', // The value must be consistent with the server
                    dataType: item.dataType, // should be date
                    dateOperator: '8', // The maximum sequential increment. only used in UI
                    name: item.title,
                    value: item.value
                });
                break;
            case 'today':
                const today = new Date();
                filters.push({
                    field: item.fields[0],
                    operator: 'dynamic',
                    dataType: item.dataType,
                    dateOperator: '9',
                    name: item.title,
                    value: 'TODAY'
                });
                break;
        }
    }

    getFilters(paramFilters) {
        const filters = [];
        paramFilters.forEach((item) => {
            // generateAdvFilterForText is used when generating filter for entry_entity, because the text format is required by the server.
            if (item.metadataType === 'EntryEntity') {
                this.generateAdvFilterForText(item, filters);
            } else {
                if (item.value instanceof Array) {
                    this.generateAdvFilterForEntity(item, filters);
                } else {
                    if (item.metadataType === FilterMetadataType.SubmittedDate
                        || item.metadataType === FilterMetadataType.DisplayDate
                        || item.metadataType === FilterMetadataType.Date
                        || item.metadataType === FilterMetadataType.StartTime) {
                        if (item.value) {
                            this.generateAdvFilterForDate(item, filters);
                        }
                    } else {
                        this.generateAdvFilterForText(item, filters);
                    }
                }
            }
        });
        return filters;
    }

    convertFilterTileToCommon(item) {
        let filterConfig = new FiltersConfig();
        if (item.field.indexOf('entryadhoc') !== -1) {
            filterConfig = this.getAdhocFilterDefaultInstance(item);
        } else {
            filterConfig.metadataType = FilterTitleType[item.name];
            filterConfig = this.getNewFilterDefaultInstance(item.name, filterConfig.metadataType);
        }
        const metadataType = filterConfig.metadataType;
        filterConfig.selectedOperator = item.operator;
        switch (metadataType) {
            case FilterMetadataType.Source:
            case FilterMetadataType.Submitter:
            case FilterMetadataType.Team:
                if (item.list && item.list.length > 0) {
                    filterConfig.value = item.list.map(value => {
                        return {
                            id: value.id,
                            // name: value.longName || '',
                            // shortName: value.longName,
                            // isPublic: value.isPublic,
                            // company: value.company || '',
                            // type: { id: value.id, name: value.longName }
                        };
                    });
                } else {
                    filterConfig.value = '';
                }
                break;
            case FilterMetadataType.Entity:
            case FilterMetadataType.NoteType:
            case FilterMetadataType.EntityType:
                if (item.list && item.list.length > 0) {
                    filterConfig.value = item.list.map(value => {
                        return {
                            id: value.id,
                            // name: value.longName || '',
                            // shortName: value.shortName || '',
                            // isPublic: value.isPublic,
                            // company: value.company || ''
                        };
                    });
                } else {
                    filterConfig.value = '';
                }
                break;
            case FilterMetadataType.EntryEntity:
                // to handle the value of entry_entity, because it's different from other entity related fields
                const data = [];
                item.value.split(',').forEach(value => {
                    data.push({ id: value });
                });
                filterConfig.value = data;
                break;
            case FilterMetadataType.Sentiment:
                filterConfig.value = item.list.map(value => value.id);
                break;
            case FilterMetadataType.Subject:
            case FilterMetadataType.Priority:
            case FilterMetadataType.TextBox:
                if (item.operator === 'in' || item.operator === 'not in') {
                    filterConfig.value = item.realValue || item.value;
                } else {
                    filterConfig.value = item.value;
                }
                break;
            case FilterMetadataType.DisplayDate:
            case FilterMetadataType.SubmittedDate:
            case FilterMetadataType.Date:
                filterConfig.selectedOperator = DateOperateType[item.dateOperator];
                if (item.name === 'Start Date') {
                    filterConfig.title = 'Start Date';
                    filterConfig.operators = this._resetDateOperateBlankValue(FilterMetadataType.DisplayDate, false, item.name);
                } else if (item.name === 'EndDate') {
                    filterConfig.operators = this._resetDateOperateBlankValue(FilterMetadataType.DisplayDate, false, item.name);
                }
                if ((metadataType === FilterMetadataType.DisplayDate
                    || metadataType === FilterMetadataType.SubmittedDate) && filterConfig.title !== 'Start Date' &&
                    filterConfig.selectedOperator === 'next') {
                    return;
                }
                if (filterConfig.selectedOperator === 'last' || filterConfig.selectedOperator === 'next') {
                    filterConfig.value = `${item.interval} ${item.unit.slice(0, -1)}`;
                } else if (filterConfig.selectedOperator === 'before' || filterConfig.selectedOperator === 'after') {
                    filterConfig.value = item.halfRange;
                } else if (filterConfig.selectedOperator === 'range') {
                    filterConfig.value = `${item.startDate} ${item.endDate}`;
                } else if (filterConfig.selectedOperator === 'is null' || filterConfig.selectedOperator === 'is not null') {
                    filterConfig.value = '';
                } else if (filterConfig.selectedOperator === 'dynamicDate') {
                    filterConfig.value = item.value;
                } else if (filterConfig.selectedOperator === 'today') {
                    filterConfig.value = item.value;
                }
                break;
            default:
                break;
        }
        return filterConfig;
    }

    private _resetDateOperateBlankValue(metadataType, showBlank?, name?) {
        const operators = this._filterService.getOperatorOptions(metadataType);
        if (showBlank) {
            // dynamic date is the last one. So adjust the values to make the data match
            operators[operators.length - 3] = { name: 'is Blank', value: 'is null' };
            operators[operators.length - 2] = { name: 'Not Blank', value: 'is not null' };
        }
        return operators;
    }
}
