/**
 * Created by Abner Sui on 12/13/2018.
 * Description:
 *  Formula service
 *  Copy logic code from Tamale Web 1.0, formulaService.js, entryBusiness.js
 * ------ maintenance history ------
 * 11/06/2019 Ella Ma - delete blank space and remove repeat function iserr
 * 11/08/2019 Ella Ma - delete function errortype
 */
import { Injectable } from '@angular/core';
import { bizConstants } from '../constants/biz.constants';
import { WindowRef } from './window-ref';
import * as FORMULA from 'formulajs/dist/formula';
import { DateHelperWebService } from '../tamalelibs/services/date-helper.web.service';

@Injectable()
export class FormulaService {

    Formula: any;

    constructor(
        private _winRef: WindowRef,
    ) {
        this.Formula = this._winRef.nativeWindow.Formula;
    }

    public appendEntities(appendEntities, entityStr: string) {
        if (appendEntities.length > 0) {
            return appendEntities += ';' + entityStr.split(',').join(';');
        } else {
            return entityStr.split(',').join(';');
        }
    }

    public getAll(): Array<any> {
        return [
            {
                type: 'Date&Time',
                items: [
                    {
                        name: 'DAYS360',
                        syntax: 'DAYS360(start_date,end_date,[method])',
                        introduction: 'The DAYS360 function returns the number of days between two dates based on a 360-day year (twelve 30-day months), which is used in some accounting calculations.<br/>Method    Optional. A logical value that specifies whether to use the U.S. or European method in the calculation.'
                    },

                    {
                        name: 'daysbetween',
                        syntax: 'daysBetween(start_date, end_date)',
                        introduction: 'Returns the days between two date.'
                    },

                    {
                        name: 'date',
                        syntax: 'date(year, month, day)',
                        introduction: 'The DATE function returns the sequential serial number that represents a particular date'
                    },

                    {
                        name: 'dateadd',
                        syntax: 'dateadd("interval",number, "yyyy/mm/dd")',
                        introduction: 'Returns a date to which the specified interval has been added <br/> intervals = (d=Day, y=Day, h=Hour, n=minute,m=Month, q=Quarter, s=Second, w=Week, ww=Calendar week, yyyy=Year)'
                    },

                    {
                        name: 'datediff',
                        syntax: 'datediff("interval","yyyy/mm/dd","yyyy/mm/dd")',
                        introduction: 'Returns  a value indicating the number of intervals between two DateTime values. <br/>intervals = (d=Day, y=Day, h=Hour, n=minute,m=Month, q=Quarter, s=Second, w=Week, ww=Calendar week, yyyy=Year)'
                    },

                    {
                        name: 'datevalue',
                        syntax: 'datevalue(date_text)',
                        introduction: 'The DATEVALUE function converts a date that is stored as text to a serial number.'
                    },

                    {
                        name: 'day',
                        syntax: 'day(serial_number)',
                        introduction: 'Returns the day of a date, represented by a serial number. The day is given as an integer ranging from 1 to 31.'
                    },

                    {
                        name: 'hour',
                        syntax: 'hour(serial_number)',
                        introduction: 'Returns the hour of a time value. The hour is given as an integer, ranging from 0 (12:00 A.M.) to 23 (11:00 P.M.).'
                    },

                    {
                        name: 'minute',

                        syntax: 'minute(serial_number)',

                        introduction: 'Returns the minutes of a time value. The minute is given as an integer, ranging from 0 to 59.'
                    },

                    {
                        name: 'month',

                        syntax: 'month(serial_number)',

                        introduction: 'Returns the month of a date represented by a serial number. The month is given as an integer, ranging from 1 (January) to 12 (December).'
                    },

                    {
                        name: 'now',

                        syntax: 'now()',

                        introduction: 'Returns the serial number of the current date and time'
                    },
                    {
                        name: 'timevalue',

                        syntax: 'timevalue(time_text)',

                        introduction: 'Returns the decimal number of the time represented by a text string. The decimal number is a value ranging from 0 (zero) to 0.99988426, representing the times from 0:00:00 (12:00:00 AM) to 23:59:59 (11:59:59 P.M.).<br/>Time_text    Required. A text string that represents a time'
                    },
                    {
                        name: 'today',

                        syntax: 'today()',

                        introduction: 'Returns the the current date'
                    },
                    {
                        name: 'year',

                        syntax: 'year(serial_number)',

                        introduction: 'Returns the year corresponding to a date. The year is returned as an integer in the range 1900-9999.<br/>Serial_number    Required. The date of the year you want to find. Dates should be entered by using the DATE function, or as results of other formulas or functions. For example, use DATE(2008,5,23)'
                    },
                    {
                        name: 'edate',

                        syntax: 'edate(start_date, months)',

                        introduction: 'Returns the serial number that represents the date that is the indicated number of months before or after a specified date (the start_date).'
                    },
                    {
                        name: 'eomonth',

                        syntax: 'eomonth(start_date, months)',

                        introduction: 'Returns the serial number for the last day of the month that is the indicated number of months before or after start_date.'
                    },
                    {
                        name: 'networkdays',

                        syntax: 'networkdays(start_date, end_date, [holidays])',

                        introduction: 'Returns the number of whole working days between start_date and end_date.<br/>Holidays    Optional. An optional range of one or more dates to exclude from the working calendar, such as state and federal holidays and floating holidays. The list can be either a range of cells that contains the dates or an array constant of the serial numbers that represent the dates.'
                    },
                    {
                        name: 'second',

                        syntax: 'second(serial_number)',
                        introduction: 'Returns the seconds of a time value. The second is given as an integer in the range 0 (zero) to 59.<br/>Serial_number    Required. The time that contains the seconds you want to find. Times may be entered as text strings within quotation marks (for example, "6:45 PM"), as decimal numbers (for example, 0.78125, which represents 6:45 PM), or as results of other formulas or functions (for example, TIMEVALUE("6:45 PM")).'
                    },
                    {
                        name: 'time',

                        syntax: 'time(hour, minute, second)',

                        introduction: 'Returns the decimal number for a particular time. If the cell format was General before the function was entered, the result is formatted as a date.'
                    },
                    {
                        name: 'weekday',

                        syntax: 'weekday(serial_number,[return_type])',

                        introduction: 'Returns the day of the week corresponding to a date. The day is given as an integer, ranging from 1 (Sunday) to 7 (Saturday), by default.<br/>Serial_number    Required. A sequential number that represents the date of the day you are trying to find. Dates should be entered by using the DATE function, or as results of other formulas or functions. For example, use DATE(2008,5,23) for the 23rd day of May, 2008. Problems can occur if dates are entered as text.<br/>Return_type    Optional. A number that determines the type of return value. 	<br/>1 or omitted  Numbers 1 (Sunday) through 7 (Saturday).<br/>2  Numbers 1 (Monday) through 7 (Sunday).<br/>3 Numbers 0 (Monday) through 6 (Sunday). <br/>11 Numbers 1 (Monday) through 7 (Sunday). <br/>12 Numbers 1 (Tuesday) through 7 (Monday).<br/>13 Numbers 1 (Wednesday) through 7 (Tuesday).<br/>14 Numbers 1 (Thursday) through 7 (Wednesday). <br/>15 Numbers 1 (Friday) through 7 (Thursday).<br/>16 Numbers 1 (Saturday) through 7 (Friday).<br/>17 Numbers 1 (Sunday) through 7 (Saturday).'
                    },
                    {
                        name: 'weeknum',

                        syntax: 'weeknum(serial_number,[return_type])',

                        introduction: 'Returns the week number of a specific date.<br/>Serial_number    Required. A sequential number that represents the date of the day you are trying to find. Dates should be entered by using the DATE function, or as results of other formulas or functions. For example, use DATE(2008,5,23) for the 23rd day of May, 2008. Problems can occur if dates are entered as text.<br/>Return_type    Optional. A number that determines the type of return value. <br/>2  Numbers 1 (Monday) through 7 (Sunday).<br/>3 Numbers 0 (Monday) through 6 (Sunday). <br/>11 Numbers 1 (Monday) through 7 (Sunday). <br/>12 Numbers 1 (Tuesday) through 7 (Monday).<br/>13 Numbers 1 (Wednesday) through 7 (Tuesday).<br/>14 Numbers 1 (Thursday) through 7 (Wednesday). <br/>15 Numbers 1 (Friday) through 7 (Thursday).<br/>16 Numbers 1 (Saturday) through 7 (Friday).<br/>17 Numbers 1 (Sunday) through 7 (Saturday).'
                    },
                    {
                        name: 'workday',

                        syntax: 'workday(start_date, days, [holidays])',

                        introduction: 'Returns a number that represents a date that is the indicated number of working days before or after a date (the starting date).<br/>Days    Required. The number of nonweekend and nonholiday days before or after start_date. A positive value for days yields a future date; a negative value yields a past date.<br/>Holidays    Optional. An optional range of one or more dates to exclude from the working calendar, such as state and federal holidays and floating holidays. The list can be either a range of cells that contains the dates or an array constant of the serial numbers that represent the dates.'
                    },
                    {
                        name: 'isleapyear',

                        syntax: 'isleapyear(year)',

                        introduction: 'Returns TRUE if the year is a leap year'
                    },
                    {
                        name: 'yearfrac',

                        syntax: 'yearfrac(start_date, end_date, [basis])',

                        introduction: 'Calculates the fraction of the year represented by the number of whole days between two dates (the start_date and the end_date).<br/>Start_date Required. A date that represents the start date.<br/>End_date Required. A date that represents the end date.<br/>Basis Optional. The type of day count basis to use.<br/>0 US 30/360, 1 Actual, 2 Actual/360, 3 Actual/365, 4 European 30/360'
                    },
                    {
                        name: 'isoweeknum',

                        syntax: 'isoweeknum(date)',

                        introduction: 'Returns number of the ISO week number of the year for a given date.'
                    }

                ]
            },

            {
                type: 'Math',
                items: [
                    {
                        name: 'abs',

                        syntax: 'abs(number)',

                        introduction: 'Returns the absolute value of a number. The absolute value of a number is the number without its sign.'

                    },
                    {
                        name: 'trunc',

                        syntax: 'trunc(number, [num_digits])',

                        introduction: 'Truncates a number to an integer by removing the fractional part of the number.'

                    },
                    {
                        name: 'int',

                        syntax: 'int(number)',

                        introduction: 'Rounds a number down to the nearest integer.'

                    },
                    {
                        name: 'round',

                        syntax: 'round(number, num_digits)',

                        introduction: 'The ROUND function rounds a number to a specified number of digits. '

                    },
                    {
                        name: 'roundup',

                        syntax: 'roundup(number, num_digits)',

                        introduction: 'Rounds a number up, away from 0 (zero).'

                    },
                    {
                        name: 'rounddown',

                        syntax: 'rounddown(number, num_digits)',

                        introduction: 'Rounds a number down, toward zero.'

                    },
                    {
                        name: 'sum',

                        syntax: 'sum(number1,[number2],...)',

                        introduction: 'The SUM function, adds values'

                    },
                    {
                        name: 'acos',

                        syntax: 'acos(number)',

                        introduction: 'Returns the arccosine, or inverse cosine, of a number.'

                    },
                    {
                        name: 'acosh',

                        syntax: 'acosh(number)',

                        introduction: 'Returns the inverse hyperbolic cosine of a number.'

                    },
                    {
                        name: 'asin',

                        syntax: 'asin(number)',

                        introduction: 'Returns the arcsine, or inverse sine, of a number.'

                    },
                    {
                        name: 'asinh',

                        syntax: 'asinh(number)',

                        introduction: 'Returns the inverse hyperbolic sine of a number.'

                    },
                    {
                        name: 'atan',

                        syntax: 'atan(number)',

                        introduction: 'Returns the arctangent, or inverse tangent, of a number.'

                    },
                    {
                        name: 'atan2',

                        syntax: 'atan2(x_num, y_num)',

                        introduction: 'Returns the arctangent, or inverse tangent, of the specified x- and y-coordinates.<br/>X_num    Required. The x-coordinate of the point.<br/>Y_num    Required. The y-coordinate of the point.'



                    },
                    {
                        name: 'atanh',

                        syntax: 'atanh(number)',

                        introduction: 'Returns the inverse hyperbolic tangent of a number.'

                    },
                    {
                        name: 'ceiling',

                        syntax: 'ceiling(number, significance)',

                        introduction: 'Returns number rounded up, away from zero, to the nearest multiple of significance.<br/>Significance    Required. The multiple to which you want to round.'

                    },
                    {
                        name: 'combin',

                        syntax: 'combin(number, number_chosen)',

                        introduction: 'Returns the number of combinations for a given number of items.<br/>Number_chosen    Required. The number of items in each combination.'

                    },
                    {
                        name: 'cos',

                        syntax: 'cos(number)',

                        introduction: 'Returns the cosine of the given angle.'

                    },
                    {
                        name: 'cosh',

                        syntax: 'cosh(number)',

                        introduction: 'Returns the hyperbolic cosine of a number.'

                    },
                    {
                        name: 'degrees',

                        syntax: 'degrees(angle)',

                        introduction: 'Converts radians into degrees.'

                    },
                    {
                        name: 'even',

                        syntax: 'even(number)',

                        introduction: 'Returns number rounded up to the nearest even integer.'

                    },
                    {
                        name: 'exp',

                        syntax: 'exp(number)',

                        introduction: 'Returns e raised to the power of number.'

                    },
                    {
                        name: 'fact',

                        syntax: 'fact(number)',

                        introduction: 'Returns the factorial of a number.'

                    },
                    {
                        name: 'factdouble',

                        syntax: 'factdouble(number)',

                        introduction: 'Returns the double factorial of a number.'

                    },
                    {
                        name: 'floor',

                        syntax: 'floor(number, significance)',

                        introduction: 'Rounds number down, toward zero, to the nearest multiple of significance.'

                    },
                    {
                        name: 'gcd',

                        syntax: 'gcd(number1, [number2], ...)',

                        introduction: 'Returns the greatest common divisor of two or more integers.'

                    },
                    {
                        name: 'ln',

                        syntax: 'ln(number)',

                        introduction: 'Returns the natural logarithm of a number.'

                    },
                    {
                        name: 'lcm',

                        syntax: 'lcm(number1, [number2], ...)',

                        introduction: 'Returns the least common multiple of integers.'

                    },
                    {
                        name: 'log',

                        syntax: 'log(number, [base])',

                        introduction: 'Returns the logarithm of a number to the base you specify.<br/>Base    Optional. The base of the logarithm. If base is omitted, it is assumed to be 10.'

                    },
                    {
                        name: 'mod',

                        syntax: 'mod(number, divisor)',

                        introduction: 'Returns the remainder after number is divided by divisor.'

                    },
                    {
                        name: 'mround',

                        syntax: 'mround(number, multiple)',

                        introduction: 'Returns a number rounded to the desired multiple.<br/>Multiple    Required. The multiple to which you want to round number.'

                    },
                    {
                        name: 'multinomial',

                        syntax: 'multinomial(number1, [number2], ...)',

                        introduction: 'Returns the ratio of the factorial of a sum of values to the product of factorials.'

                    },
                    {
                        name: 'odd',

                        syntax: 'odd(number)',

                        introduction: 'Returns number rounded up to the nearest odd integer.'

                    },
                    {
                        name: 'pi',

                        syntax: 'pi()',

                        introduction: 'Returns the number 3.14159265358979, the mathematical constant pi, accurate to 15 digits.'

                    },
                    {
                        name: 'power',

                        syntax: 'power(number, power)',

                        introduction: 'Returns the result of a number raised to a power.<br/>Power    Required. The exponent to which the base number is raised.'

                    },
                    {
                        name: 'product',

                        syntax: 'product(number1, [number2], ...)',

                        introduction: 'The PRODUCT function multiplies all the numbers given as arguments and returns the product.'

                    },
                    {
                        name: 'quotient',

                        syntax: 'quotient(numerator, denominator)',

                        introduction: 'Returns the integer portion of a division.<br/>Numerator    Required. The dividend.<br/>Denominator    Required. The divisor.'

                    },
                    {
                        name: 'radians',

                        syntax: 'radians(angle)',

                        introduction: 'Converts degrees to radians.<br/>Angle    Required. An angle in degrees that you want to convert.'



                    },
                    {
                        name: 'rand',

                        syntax: 'rand()',

                        introduction: 'Returns an evenly distributed random real number greater than or equal to 0 and less than 1.'


                    },
                    {
                        name: 'randbetween',

                        syntax: 'randbetween(bottom, top)',

                        introduction: 'Returns a random integer number between the numbers you specify.'


                    },
                    {
                        name: 'sign',

                        syntax: 'sign(number)',

                        introduction: 'Determines the sign of a number. Returns 1 if the number is positive, zero (0) if the number is 0, and -1 if the number is negative.'


                    },
                    {
                        name: 'sin',

                        syntax: 'sin(number)',

                        introduction: 'Returns the sine of the given angle.'


                    },
                    {
                        name: 'sinh',

                        syntax: 'sinh(number)',

                        introduction: 'Returns the hyperbolic sine of a number.'


                    },
                    {
                        name: 'sqrt',

                        syntax: 'sqrt(number)',

                        introduction: 'Returns a positive square root.'


                    },
                    {
                        name: 'sqrtpi',

                        syntax: 'sqrtpi(number)',

                        introduction: 'Returns the square root of (number * pi).'


                    },
                    {
                        name: 'subtotal',

                        syntax: 'subtotal(function_num,ref1,[ref2],...)',

                        introduction: 'Returns a subtotal in a list or database.<br/>Function_num     Required. The number 1-11 or 101-111 that specifies the function to use for the subtotal. 1-11 includes manually-hidden rows, while 101-111 excludes them; filtered-out cells are always excluded. <br/>1 AVERAGE<br/>2 COUNT<br/>3 COUNTA<br/>4 MAX<br/>5 MIN<br/>6 PRODUCT<br/>7 STDEV<br/>8 STDEVP<br/>9 SUM<br/>10 VAR<br/>11 VARP<br/>Ref1     Required. The first named range or reference for which you want the subtotal.<br/>Ref2,...     Optional. Named ranges or references 2 to 254 for which you want the subtotal.'



                    },
                    {
                        name: 'tan',

                        syntax: 'tan(number)',

                        introduction: 'Returns the tangent of the given angle.'


                    }


                ]
            },

            {
                type: 'Financial',
                items: [
                    {
                        name: 'accrint',

                        syntax: 'accrint(issue, first_interest, settlement, rate, par, frequency, [basis], [calc_method])',

                        introduction: 'Returns the accrued interest for a security that pays periodic interest.<br/>Issue    Required. The security&quot;s issue date.<br/>First_interest    Required. The security&quot;s first interest date.<br/>Settlement    Required. The security&quot;s settlement date. The security settlement date is the date after the issue date when the security is traded to the buyer.<br/>Rate    Required. The security&quot;s annual coupon rate.<br/>Par    Required. The security&quot;s par value. If you omit par, ACCRINT uses $1,000.<br/>Frequency    Required. The number of coupon payments per year. For annual payments, frequency = 1; for semiannual, frequency = 2; for quarterly, frequency = 4.<br/>Basis    Optional. The type of day count basis to use.'

                    },
                    {
                        name: 'cumipmt',

                        syntax: 'cumipmt(rate, nper, pv, start_period, end_period, type)',

                        introduction: 'Returns the cumulative interest paid on a loan between start_period and end_period.<br/>Rate    Required. The interest rate.<br/>Nper    Required. The total number of payment periods.<br/>Pv    Required. The present value.<br/>Start_period    Required. The first period in the calculation. Payment periods are numbered beginning with 1.<br/>End_period    Required. The last period in the calculation.<br/>Type    Required. The timing of the payment.'

                    },
                    {
                        name: 'cumprinc',

                        syntax: 'cumprinc(rate, nper, pv, start_period, end_period, type)',

                        introduction: 'Returns the cumulative principal paid on a loan between start_period and end_period.<br/>Rate    Required. The interest rate.<br/>Nper    Required. The total number of payment periods.<br/>Pv    Required. The present value.<br/>Start_period    Required. The first period in the calculation. Payment periods are numbered beginning with 1.<br/>End_period    Required. The last period in the calculation.<br/>Type    Required. The timing of the payment.'

                    },
                    {
                        name: 'db',

                        syntax: 'db(cost, salvage, life, period, [month])',

                        introduction: 'Returns the depreciation of an asset for a specified period using the fixed-declining balance method.<br/>Cost    Required. The initial cost of the asset.<br/>Salvage    Required. The value at the end of the depreciation (sometimes called the salvage value of the asset).<br/>Life    Required. The number of periods over which the asset is being depreciated (sometimes called the useful life of the asset).<br/>Period    Required. The period for which you want to calculate the depreciation. Period must use the same units as life.<br/>Month    Optional. The number of months in the first year. If month is omitted, it is assumed to be 12.'

                    },
                    {
                        name: 'ddb',

                        syntax: 'ddb(cost, salvage, life, period, [factor])',

                        introduction: 'Returns the depreciation of an asset for a specified period using the double-declining balance method or some other method you specify.<br/>Cost    Required. The initial cost of the asset.<br/>Salvage    Required. The value at the end of the depreciation (sometimes called the salvage value of the asset). This value can be 0.<br/>Life    Required. The number of periods over which the asset is being depreciated (sometimes called the useful life of the asset).<br/>Period    Required. The period for which you want to calculate the depreciation. Period must use the same units as life.<br/>Factor    Optional. The rate at which the balance declines. If factor is omitted, it is assumed to be 2 (the double-declining balance method).'

                    },
                    {
                        name: 'dollarde',

                        syntax: 'dollarde(fractional_dollar, fraction)',

                        introduction: 'Converts a dollar price expressed as an integer part and a fraction part, such as 1.02, into a dollar price expressed as a decimal number. Fractional dollar numbers are sometimes used for security prices.<br/>Fractional_dollar    Required. A number expressed as an integer part and a fraction part, separated by a decimal symbol.<br/>Fraction    Required. The integer to use in the denominator of the fraction.'

                    },
                    {
                        name: 'dollarfr',

                        syntax: 'dollarfr(decimal_dollar, fraction)',

                        introduction: 'Use DOLLARFR to convert decimal numbers to fractional dollar numbers, such as securities prices.<br/>Decimal_dollar    Required. A decimal number.<br/>Fraction    Required. The integer to use in the denominator of a fraction.'

                    },
                    {
                        name: 'effect',

                        syntax: 'effect(nominal_rate, npery)',

                        introduction: 'Returns the effective annual interest rate, given the nominal annual interest rate and the number of compounding periods per year.<br/>Nominal_rate    Required. The nominal interest rate.<br/>Npery    Required. The number of compounding periods per year.'

                    },
                    {
                        name: 'fv',

                        syntax: 'fv(rate,nper,pmt,[pv],[type])',

                        introduction: 'Calculates the future value of an investment based on a constant interest rate. You can use FV with either periodic, constant payments, or a single lump sum payment<br/>Rate    Required. The interest rate per period.<br/>Nper    Required. The total number of payment periods in an annuity.<br/>Pmt    Required. The payment made each period; it cannot change over the life of the annuity. Typically, pmt contains principal and interest but no other fees or taxes. If pmt is omitted, you must include the pv argument.<br/>Pv    Optional. The present value, or the lump-sum amount that a series of future payments is worth right now. If pv is omitted, it is assumed to be 0 (zero), and you must include the pmt argument.<br/>Type    Optional. The number 0 or 1 and indicates when payments are due. If type is omitted, it is assumed to be 0.'


                    },
                    {
                        name: 'fvschedule',

                        syntax: 'fvschedule(principal, schedule)',

                        introduction: 'Returns the future value of an initial principal after applying a series of compound interest rates<br/>Principal    Required. The present value.<br/>Schedule    Required. An array of interest rates to apply.'

                    },
                    {
                        name: 'intrate',

                        syntax: 'intrate(settlement, maturity, investment, redemption, [basis])',

                        introduction: 'Returns the interest rate for a fully invested security.<br/>Settlement    Required. The securitys settlement date. The security settlement date is the date after the issue date when the security is traded to the buyer.<br/>Maturity    Required. The security&quot;s maturity date. The maturity date is the date when the security expires.<br/>Investment    Required. The amount invested in the security.<br/>Redemption    Required. The amount to be received at maturity.<br/>Basis    Optional. The type of day count basis to use.'

                    },
                    {
                        name: 'ipmt',

                        syntax: 'ipmt(rate, per, nper, pv, [fv], [type])',

                        introduction: 'Returns the interest payment for a given period for an investment based on periodic, constant payments and a constant interest rate.<br/>Rate    Required. The interest rate per period.<br/>Per    Required. The period for which you want to find the interest and must be in the range 1 to nper.<br/>Nper    Required. The total number of payment periods in an annuity.<br/>Pv    Required. The present value, or the lump-sum amount that a series of future payments is worth right now.<br/>Fv    Optional. The future value, or a cash balance you want to attain after the last payment is made. If fv is omitted, it is assumed to be 0 (the future value of a loan, for example, is 0).<br/>Type    Optional. The number 0 or 1 and indicates when payments are due. If type is omitted, it is assumed to be 0.'

                    },
                    {
                        name: 'ispmt',

                        syntax: 'ispmt(rate, per, nper, pv)',

                        introduction: 'Calculates the interest paid during a specific period of an investment. <br/>Rate    Required. The interest rate for the investment.<br/>Per    Required. The period for which you want to find the interest, and must be between 1 and nper.<br/>Nper    Required. The total number of payment periods for the investment.<br/>Pv    Required. The present value of the investment. For a loan, pv is the loan amount.'

                    },
                    {
                        name: 'irr',

                        syntax: 'irr(values, [guess])',

                        introduction: 'Returns the internal rate of return for a series of cash flows represented by the numbers in values.<br/>Values    Required. An array or a reference to cells that contain numbers for which you want to calculate the internal rate of return. Values must contain at least one positive value and one negative value to calculate the internal rate of return.IRR uses the order of values to interpret the order of cash flows. Be sure to enter your payment and income values in the sequence you want. If an array or reference argument contains text, logical values, or empty cells, those values are ignored.<br/>Guess    Optional. A number that you guess is close to the result of IRR. formula uses an iterative technique for calculating IRR. Starting with guess, IRR cycles through the calculation until the result is accurate within 0.00001 percent. If IRR can &quot; t find a result that works after 20 tries, the #NUM! error value is returned. In most cases you do not need to provide guess for the IRR calculation. If guess is omitted, it is assumed to be 0.1 (10 percent). If IRR gives the #NUM! error value, or if the result is not close to what you expected, try again with a different value for guess.'

                    },
                    {
                        name: 'mirr',

                        syntax: 'mirr(values, finance_rate, reinvest_rate)',

                        introduction: 'Returns the modified internal rate of return for a series of periodic cash flows.<br/>Values    Required. An array or a reference to cells that contain numbers. These numbers represent a series of payments (negative values) and income (positive values) occurring at regular periods. Values must contain at least one positive value and one negative value to calculate the modified internal rate of return. Otherwise, MIRR returns the #DIV/0! error value. If an array or reference argument contains text, logical values, or empty cells, those values are ignored; however, cells with the value zero are included.<br/>Finance_rate    Required. The interest rate you pay on the money used in the cash flows.<br/>Reinvest_rate    Required. The interest rate you receive on the cash flows as you reinvest them.'

                    },
                    {
                        name: 'nper',

                        syntax: 'nper(rate,pmt,pv,[fv],[type])',

                        introduction: 'Returns the number of periods for an investment based on periodic, constant payments and a constant interest rate.<br/>Rate    Required. The interest rate per period.<br/>Pmt    Required. The payment made each period; it cannot change over the life of the annuity. Typically, pmt contains principal and interest but no other fees or taxes.<br/>Pv    Required. The present value, or the lump-sum amount that a series of future payments is worth right now.<br/>Fv    Optional. The future value, or a cash balance you want to attain after the last payment is made. If fv is omitted, it is assumed to be 0 (the future value of a loan, for example, is 0).<br/>Type    Optional. The number 0 or 1 and indicates when payments are due.'

                    },
                    {
                        name: 'npv',

                        syntax: 'npv(rate,value1,[value2],...)',

                        introduction: 'Calculates the net present value of an investment by using a discount rate and a series of future payments (negative values) and income (positive values).<br/>Rate    Required. The rate of discount over the length of one period.<br/>Value1, value2, ...    Value1 is required, subsequent values are optional. 1 to 254 arguments representing the payments and income.<br/>Value1, value2, ... must be equally spaced in time and occur at the end of each period. NPV uses the order of value1, value2, ... to interpret the order of cash flows. Be sure to enter your payment and income values in the correct sequence.'
                    },
                    {
                        name: 'pduration',

                        syntax: 'pduration(rate, pv, fv)',

                        introduction: 'Returns the number of periods required by an investment to reach a specified value.<br/>Rate    Required. Rate is the interest rate per period.<br/>Pv    Required. Pv is the present value of the investment.<br/>Fv    Required. Fv is the desired future value of the investment.'

                    },
                    {
                        name: 'pv',

                        syntax: 'pv(rate, nper, pmt, [fv], [type])',

                        introduction: 'Calculates the present value of a loan or an investment, based on a constant interest rate.<br/>Rate    Required. The interest rate per period. For example, if you obtain an automobile loan at a 10 percent annual interest rate and make monthly payments, your interest rate per month is 10%/12, or 0.83%. You would enter 10%/12, or 0.83%, or 0.0083, into the formula as the rate.<br/>Nper    Required. The total number of payment periods in an annuity. For example, if you get a four-year car loan and make monthly payments, your loan has 4*12 (or 48) periods. You would enter 48 into the formula for nper.<br/>Pmt    Required. The payment made each period and cannot change over the life of the annuity. Typically, pmt includes principal and interest but no other fees or taxes. For example, the monthly payments on a $10,000, four-year car loan at 12 percent are $263.33. You would enter -263.33 into the formula as the pmt. If pmt is omitted, you must include the fv argument.<br/>Fv    Optional. The future value, or a cash balance you want to attain after the last payment is made. If fv is omitted, it is assumed to be 0 (the future value of a loan, for example, is 0). For example, if you want to save $50,000 to pay for a special project in 18 years, then $50,000 is the future value. You could then make a conservative guess at an interest rate and determine how much you must save each month. If fv is omitted, you must include the pmt argument.<br/>Type    Optional. The number 0 or 1 and indicates when payments are due.'

                    },
                    {
                        name: 'pmt',

                        syntax: 'pmt(rate, nper, pv, [fv], [type])',

                        introduction: 'Calculates the payment for a loan based on constant payments and a constant interest rate.<br/>Rate    Required. The interest rate for the loan.<br/>Nper    Required. The total number of payments for the loan.<br/>Pv    Required. The present value, or the total amount that a series of future payments is worth now; also known as the principal.<br/>Fv    Optional. The future value, or a cash balance you want to attain after the last payment is made. If fv is omitted, it is assumed to be 0 (zero), that is, the future value of a loan is 0.<br/>Type    Optional. The number 0 (zero) or 1 and indicates when payments are due.'

                    },
                    {
                        name: 'ppmt',

                        syntax: 'ppmt(rate, per, nper, pv, [fv], [type])',

                        introduction: 'Returns the payment on the principal for a given period for an investment based on periodic, constant payments and a constant interest rate.<br/>Rate    Required. The interest rate per period.<br/>Per    Required. Specifies the period and must be in the range 1 to nper.<br/>Nper    Required. The total number of payment periods in an annuity.<br/>Pv    Required. The present value — the total amount that a series of future payments is worth now.<br/>Fv    Optional. The future value, or a cash balance you want to attain after the last payment is made. If fv is omitted, it is assumed to be 0 (zero), that is, the future value of a loan is 0.<br/>Type    Optional. The number 0 or 1 and indicates when payments are due.'

                    },
                    {
                        name: 'rate',

                        syntax: 'rate(nper, pmt, pv, [fv], [type], [guess])',

                        introduction: 'Returns the interest rate per period of an annuity. RATE is calculated by iteration and can have zero or more solutions.<br/>Nper    Required. The total number of payment periods in an annuity.<br/>Pmt    Required. The payment made each period and cannot change over the life of the annuity. Typically, pmt includes principal and interest but no other fees or taxes. If pmt is omitted, you must include the fv argument.<br/>Pv    Required. The present value — the total amount that a series of future payments is worth now.<br/>Fv    Optional. The future value, or a cash balance you want to attain after the last payment is made. If fv is omitted, it is assumed to be 0 (the future value of a loan, for example, is 0). If fv is omitted, you must include the pmt argument.<br/>Type    Optional. The number 0 or 1 and indicates when payments are due.'

                    },
                    {
                        name: 'rri',

                        syntax: 'rri(nper, pv, fv)',

                        introduction: 'Returns an equivalent interest rate for the growth of an investment.<br/>Nper    Required. Nper is the number of periods for the investment.<br/>Pv    Required. Pv is the present value of the investment.<br/>Fv    Required. Fv is the future value of the investment.'

                    },
                    {
                        name: 'sln',

                        syntax: 'sln(cost, salvage, life)',

                        introduction: 'Returns the straight-line depreciation of an asset for one period.<br/>Cost    Required. The initial cost of the asset.<br/>Salvage    Required. The value at the end of the depreciation (sometimes called the salvage value of the asset).<br/>Life    Required. The number of periods over which the asset is depreciated (sometimes called the useful life of the asset).'

                    },
                    {
                        name: 'syd',

                        syntax: 'syd(cost, salvage, life, per)',

                        introduction: 'Returns the sum-of-years&quot; digits depreciation of an asset for a specified period.<br/>Cost    Required. The initial cost of the asset.<br/>Salvage    Required. The value at the end of the depreciation (sometimes called the salvage value of the asset).<br/>Life    Required. The number of periods over which the asset is depreciated (sometimes called the useful life of the asset).<br/>Per    Required. The period and must use the same units as life.'

                    },
                    {
                        name: 'tbillprice',

                        syntax: 'tbillprice(settlement, maturity, discount)',

                        introduction: 'Returns the price per $100 face value for a Treasury bill.<br/>Settlement    Required. The Treasury bill&quot;s settlement date. The security settlement date is the date after the issue date when the Treasury bill is traded to the buyer.<br/>Maturity    Required. The Treasury bill&quot;s maturity date. The maturity date is the date when the Treasury bill expires.<br/>Discount    Required. The Treasury bill&quot;s discount rate.'

                    },
                    {
                        name: 'tbillyield',

                        syntax: 'tbillyield(settlement, maturity, pr)',

                        introduction: 'Returns the yield for a Treasury bill.<br/>Settlement    Required. The Treasury bill&quot;s settlement date. The security settlement date is the date after the issue date when the Treasury bill is traded to the buyer.<br/>Maturity    Required. The Treasury bill&quot;s maturity date. The maturity date is the date when the Treasury bill expires.<br/>Pr    Required. The Treasury bill&quot;s price per $100 face value.'

                    },
                    {
                        name: 'xirr',

                        syntax: 'xirr(values, dates, [guess])',

                        introduction: 'Returns the internal rate of return for a schedule of cash flows that is not necessarily periodic.<br/>Values    Required. A series of cash flows that corresponds to a schedule of payments in dates. The first payment is optional and corresponds to a cost or payment that occurs at the beginning of the investment. If the first value is a cost or payment, it must be a negative value. All succeeding payments are discounted based on a 365-day year. The series of values must contain at least one positive and one negative value.<br/>Dates    Required. A schedule of payment dates that corresponds to the cash flow payments. Dates may occur in any order. Dates should be entered by using the DATE function, or as results of other formulas or functions. For example, use DATE(2008,5,23) for the 23rd day of May, 2008. Problems can occur if dates are entered as text. .<br/>Guess    Optional. A number that you guess is close to the result of XIRR.'

                    },
                    {
                        name: 'xnpv',

                        syntax: 'xnpv(rate, values, dates)',

                        introduction: 'Returns the net present value for a schedule of cash flows that is not necessarily periodic.<br/>Rate    Required. The discount rate to apply to the cash flows.<br/>Values    Required. A series of cash flows that corresponds to a schedule of payments in dates. The first payment is optional and corresponds to a cost or payment that occurs at the beginning of the investment. If the first value is a cost or payment, it must be a negative value. All succeeding payments are discounted based on a 365-day year. The series of values must contain at least one positive value and one negative value.<br/>Dates    Required. A schedule of payment dates that corresponds to the cash flow payments. The first payment date indicates the beginning of the schedule of payments. All other dates must be later than this date, but they may occur in any order.'

                    }
                ]
            },

            {
                type: 'Information',
                items: [
                    {
                        name: 'isblank',

                        syntax: 'isblank(value)',

                        introduction: 'Returns TRUE if value refers to an empty cell.'

                    },
                    {
                        name: 'iserr',

                        syntax: 'iserr("value")',

                        introduction: 'Returns true if the value is any error value except #N/A'

                    },
                    {
                        name: 'iserror',

                        syntax: 'iferror(value, value_if_error)',

                        introduction: 'Returns a value you specify if a formula evaluates to an error; otherwise, returns the result of the formula.'

                    },
                    {
                        name: 'iseven',

                        syntax: 'iseven(number)',

                        introduction: 'Returns TRUE if number is even, or FALSE if number is odd.'

                    },
                    {
                        name: 'islogical',

                        syntax: 'islogical(value)',

                        introduction: 'Returns TRUE if value is true, or FALSE if value is false.'

                    },
                    {
                        name: 'isna',

                        syntax: 'isna(value)',

                        introduction: 'Returns TRUE if valus is N/A'
                    },
                    {
                        name: 'isnontext',

                        syntax: 'isnontext(value)',

                        introduction: 'Returns TRUE is value not a string'
                    },
                    {
                        name: 'isnumber',

                        syntax: 'isnumber(value)',

                        introduction: 'Returns TRUE is value note a number'
                    },
                    {
                        name: 'isodd',

                        syntax: 'isodd(number)',

                        introduction: 'Returns TRUE if number is odd, or FALSE if number is even.'

                    },
                    {
                        name: 'istext',

                        syntax: 'istext(value)',

                        introduction: 'Returns TRUE is value is string'
                    },
                    {
                        name: 'n',

                        syntax: 'n(value)',

                        introduction: 'Returns a value converted to a number.'

                    },
                    {
                        name: 'na',

                        syntax: 'na()',

                        introduction: 'Returns the error value #N/A'

                    },
                    {
                        name: 'type',

                        syntax: 'type(value)',

                        introduction: 'Returns the type of value.<br/>Number: 1<br/>Text:	2<br/>Logical value: 4<br/>Error value: 16<br/>Array:  64'
                    }

                ]
            },

            {
                type: 'Logical',
                items: [
                    {
                        name: 'and',

                        syntax: 'and(logical1, [logical2], ...)',

                        introduction: 'to determine if all conditions in a test are TRUE.'

                    },
                    {
                        name: 'false',

                        syntax: 'false()',

                        introduction: 'Returns the logical value FALSE.'

                    },
                    {
                        name: 'if',

                        syntax: 'if(logical_test, value_if_true, [value_if_false])',

                        introduction: 'IF(Something is True, then do something, otherwise do something else)'

                    },
                    {
                        name: 'not',

                        syntax: 'not(logical)',

                        introduction: 'The NOT function reverses the value of its argument.'

                    },
                    {
                        name: 'or',

                        syntax: 'or(logical1, [logical2], ...)',

                        introduction: 'The OR function returns TRUE if any of its arguments evaluate to TRUE, and returns FALSE if all of its arguments evaluate to FALSE.'

                    },
                    {
                        name: 'true',

                        syntax: 'true()',

                        introduction: 'Returns the logical value TRUE. You can use this function when you want to return the value TRUE based on a condition'

                    },
                    {
                        name: 'choose',

                        syntax: 'choose(index_num, value1, [value2], ...)',

                        introduction: 'Uses index_num to return a value from the list of value arguments. Use CHOOSE to select one of up to 254 values based on the index number.'

                    },
                    {
                        name: 'iferror',

                        syntax: 'iferror(value, value_if_error)',

                        introduction: 'Returns a value you specify if a formula evaluates to an error; otherwise, returns the result of the formula. Use the IFERROR function to trap and handle errors in a formula.'

                    },
                    {
                        name: 'ifna',

                        syntax: 'ifna(value, value_if_na)',

                        introduction: ' Returns the value you specify if the formula returns the #N/A error value; otherwise returns the result of the formula.'

                    },
                    {
                        name: 'xor',

                        syntax: 'xor(logical1, [logical2],…)',

                        introduction: ' Returns a logical Exclusive Or of all arguments.'

                    },
                    {
                        name: 'switch',

                        syntax: 'switch(expression, value1, result1, [default or value2, result2],…[default or value3, result3])',

                        introduction: ' The SWITCH function evaluates one value (called the expression) against a list of values, and returns the result corresponding to the first matching value. If there is no match, an optional default value may be returned. <br/>SWITCH(Value to switch, Value to match1...[2-126], Value to return if there is a match1...[2-126], Value to return if there is no match) '

                    }

                ]
            },

            {
                type: 'Statistical',
                items: [

                    {
                        name: 'average',

                        syntax: 'average(number1, [number2], ...)',

                        introduction: 'Returns the average (arithmetic mean) of the arguments.'

                    },
                    {
                        name: 'max',

                        syntax: 'max(number1, [number2], ...)',

                        introduction: 'Returns the largest value in a set of values.'

                    },
                    {
                        name: 'count',

                        syntax: 'count(value1, [value2], ...)',

                        introduction: 'The COUNT function counts the number of cells that contain numbers, and counts numbers within the list of arguments.'

                    },
                    {
                        name: 'avedev',

                        syntax: 'avedev(number1, [number2], ...)',

                        introduction: 'Returns the average of the absolute deviations of data points from their mean. AVEDEV is a measure of the variability in a data set.'

                    },
                    {
                        name: 'averagea',

                        syntax: 'averagea(value1, [value2], ...)',

                        introduction: 'Calculates the average (arithmetic mean) of the values in the list of arguments. '

                    },
                    {
                        name: 'averageif',

                        syntax: 'averageif(range, criteria, [average_range])',

                        introduction: 'Returns the average (arithmetic mean) of all the cells in a range that meet a given criteria.<br/>Average_range    Optional. The actual set of cells to average. If omitted, range is used.'

                    },
                    {
                        name: 'averageifs',

                        syntax: 'averageifs(average_range, criteria_range1, criteria1, [criteria_range2, criteria2], ...)',

                        introduction: 'Returns the average (arithmetic mean) of all cells that meet multiple criteria.<br/>Average_range    Required. One or more cells to average, including numbers or names, arrays, or references that contain numbers.<br/>Criteria_range1, criteria_range2, …    Criteria_range1 is required, subsequent criteria_ranges are optional. 1 to 127 ranges in which to evaluate the associated criteria.<br/>Criteria1, criteria2, ...    Criteria1 is required, subsequent criteria are optional. 1 to 127 criteria in the form of a number, expression, cell reference, or text that define which cells will be averaged.'



                    },
                    {
                        name: 'maxa',

                        syntax: 'maxa(value1,[value2],...)',

                        introduction: 'Returns the largest value in a list of arguments.'

                    },
                    {
                        name: 'median',

                        syntax: 'median(number1, [number2], ...)',

                        introduction: 'Returns the median of the given numbers. The median is the number in the middle of a set of numbers.'

                    },
                    {
                        name: 'min',

                        syntax: 'min(number1, [number2], ...)',

                        introduction: 'Returns the smallest number in a set of values.'

                    },
                    {
                        name: 'mina',

                        syntax: 'mina(value1, [value2], ...)',

                        introduction: 'Returns the smallest value in the list of arguments. '

                    },
                    {
                        name: 'stdev',

                        syntax: 'stdev(number1,[number2],...)',

                        introduction: 'Estimates standard deviation based on a sample.'

                    },
                    {
                        name: 'var',

                        syntax: 'var(number1,[number2],...)',

                        introduction: 'Estimates variance based on a sample.'

                    },
                    {
                        name: 'counta',

                        syntax: 'counta(value1, [value2], ...)',

                        introduction: 'The COUNTA function counts the number of cells that are not empty in a range.'

                    },
                    {
                        name: 'countblank',

                        syntax: 'countblank(range)',

                        introduction: 'Counts empty cells in a specified range of cells.'

                    },
                    {
                        name: 'countif',

                        syntax: 'countif(range, criteria)',

                        introduction: 'to count the number of cells that meet a criterion; for example, to count the number of times a particular city appears in a customer list.'

                    },
                    {
                        name: 'countifs',

                        syntax: 'countifs(criteria_range1, criteria1, [criteria_range2, criteria2]…)',

                        introduction: 'The COUNTIFS function applies criteria to cells across multiple ranges and counts the number of times all criteria are met.'

                    },
                    {
                        name: 'columns',

                        syntax: 'columns(array)',

                        introduction: 'Returns the number of columns in an array or reference.'

                    },

                    {
                        name: 'beta.dist',

                        syntax: 'beta.dist(x,alpha,beta,cumulative,[a],[b])',

                        introduction: 'Returns the beta distribution.<br/>X     Required. The value between A and B at which to evaluate the function<br/>Alpha     Required. A parameter of the distribution.<br/>Beta     Required. A parameter of the distribution.<br/>Cumulative      Required. A logical value that determines the form of the function. If cumulative is TRUE, BETA.DIST returns the cumulative distribution function; if FALSE, it returns the probability density function.<br/>A     Optional. A lower bound to the interval of x.<br/>B     Optional. An upper bound to the interval of x.'



                    },

                    {
                        name: 'binom.dist',

                        syntax: 'binom.dist(number_s,trials,probability_s,cumulative)',

                        introduction: 'Returns the individual term binomial distribution probability. Use BINOM.DIST in problems with a fixed number of tests or trials, when the outcomes of any trial are only success or failure, when trials are independent, and when the probability of success is constant throughout the experiment.<br/>Number_s     Required. The number of successes in trials.<br/>Trials     Required. The number of independent trials.<br/>Probability_s     Required. The probability of success on each trial.<br/>Cumulative     Required. A logical value that determines the form of the function. If cumulative is TRUE, then BINOM.DIST returns the cumulative distribution function, which is the probability that there are at most number_s successes; if FALSE, it returns the probability mass function, which is the probability that there are number_s successes.'

                    },

                    {
                        name: 'chisq.inv',

                        syntax: 'chisq.inv(probability,deg_freedom)',

                        introduction: 'Returns the inverse of the left-tailed probability of the chi-squared distribution.<br/>Probability     Required. A probability associated with the chi-squared distribution.<br/>Deg_freedom     Required. The number of degrees of freedom.'



                    },


                ]
            },

            {
                type: 'Text&Data',
                items: [
                    {
                        name: 'concatenate',

                        syntax: 'concatenate(text1, [text2], ...)',

                        introduction: 'Use CONCATENATE, to join two or more text strings into one string. '

                    },
                    {
                        name: 'len',

                        syntax: 'len(text)',

                        introduction: 'LEN returns the number of characters in a text string.'

                    },
                    {
                        name: 'value',

                        syntax: 'value(text)',

                        introduction: 'Converts a text string that represents a number to a number'

                    },
                    {
                        name: 'char',

                        syntax: 'char(number)',

                        introduction: 'Returns the character specified by a number.'

                    },
                    {
                        name: 'clean',

                        syntax: 'clean(text)',

                        introduction: 'Removes all nonprintable characters from text.'

                    },
                    {
                        name: 'code',

                        syntax: 'code(text)',

                        introduction: 'Returns a numeric code for the first character in a text string.'

                    },
                    {
                        name: 'dollar',

                        syntax: 'dollar(number, [decimals])',

                        introduction: 'The function described in this Help topic converts a number to text format and applies a currency symbol.<br/>Decimals    Optional. The number of digits to the right of the decimal point.'

                    },

                    {
                        name: 'exact',

                        syntax: 'exact(text1, text2)',

                        introduction: 'Compares two text strings and returns TRUE if they are exactly the same, FALSE otherwise.'

                    },
                    {
                        name: 'find',

                        syntax: 'find(find_text, within_text, [start_num])',

                        introduction: 'FIND locate one text string within a second text string, and return the number of the starting position of the first text string from the first character of the second text string.'

                    },
                    {
                        name: 'fixed',

                        syntax: 'fixed(number, [decimals], [no_commas])',

                        introduction: 'Rounds a number to the specified number of decimals, formats the number in decimal format using a period and commas, and returns the result as text.<br/>Number    Required. The number you want to round and convert to text.<br/>Decimals    Optional. The number of digits to the right of the decimal point.<br/>No_commas    Optional. A logical value that, if TRUE, prevents FIXED from including commas in the returned text.'



                    },
                    {
                        name: 'left',

                        syntax: 'left(text, [num_chars])',

                        introduction: 'LEFT returns the first character or characters in a text string, based on the number of characters you specify.<br/>Text    Required. The text string that contains the characters you want to extract.<br/>Num_chars    Optional. Specifies the number of characters you want LEFT to extract.'



                    },
                    {
                        name: 'lower',

                        syntax: 'lower(text)',

                        introduction: 'Converts all uppercase letters in a text string to lowercase.'

                    },
                    {
                        name: 'mid',

                        syntax: 'mid(text, start_num, num_chars)',

                        introduction: 'MID returns a specific number of characters from a text string, starting at the position you specify, based on the number of characters you specify.<br/>Text    Required. The text string containing the characters you want to extract.<br/>Start_num    Required. The position of the first character you want to extract in text. The first character in text has start_num 1, and so on.<br/>Num_chars    Required. Specifies the number of characters you want MID to return from text.'



                    },
                    {
                        name: 'proper',

                        syntax: 'proper(text)',

                        introduction: 'Capitalizes the first letter in a text string and any other letters in text that follow any character other than a letter.'

                    },
                    {
                        name: 'regexextract',

                        syntax: 'regexextract(text, regular_expression)',

                        introduction: 'Extracts matching substrings according to a regular expression'
                    },
                    {
                        name: 'regexmatch',

                        syntax: 'regexmatch(text, regular_expression, full)',

                        introduction: 'Whether a piece of text matches a regular expression'
                    },
                    {
                        name: 'regexreplace',

                        syntax: 'regexreplace(text, regular_expression, replacement)',

                        introduction: 'Replaces part of a text string with a different text string using regular expressions'
                    },
                    {
                        name: 'replace',

                        syntax: 'replace(old_text, start_num, num_chars, new_text)',

                        introduction: 'Replaces part of a text string, based on the number of characters you specify, with a different text string.<br/>Old_text    Required. Text in which you want to replace some characters.<br/>Start_num    Required. The position of the character in old_text that you want to replace with new_text.<br/>Num_chars    Required. The number of characters in old_text that you want REPLACE to replace with new_text.<br/>Num_bytes    Required. The number of bytes in old_text that you want REPLACEB to replace with new_text.<br/>New_text    Required. The text that will replace characters in old_text.'



                    },
                    {
                        name: 'rept',

                        syntax: 'rept(text, number_times)',

                        introduction: 'Repeats text a given number of times. Use REPT to fill a cell with a number of instances of a text string.<br/>Text    Required. The text you want to repeat.<br/>Number_times    Required. A positive number specifying the number of times to repeat text.'



                    },
                    {
                        name: 'right',

                        syntax: 'right(text,[num_chars])',

                        introduction: 'RIGHT returns the last character or characters in a text string, based on the number of characters you specify.<br/>Text    Required. The text string containing the characters you want to extract.<br/>Num_chars    Optional. Specifies the number of characters you want RIGHT to extract.'



                    },
                    {
                        name: 'search',

                        syntax: 'search(find_text, within_text, position)',

                        introduction: 'Returns the 1-based index of one string within another, searching case insensitively'
                    },
                    {
                        name: 'split',

                        syntax: 'split(text, separator) ',

                        introduction: 'Split text by given separator'

                    },
                    {
                        name: 'substitute',

                        syntax: 'substitute(text, old_text, new_text, [instance_num])',

                        introduction: 'Substitutes new_text for old_text in a text string.<br/>Text    Required. The text or the reference to a cell containing text for which you want to substitute characters.<br/>Old_text    Required. The text you want to replace.<br/>New_text    Required. The text you want to replace old_text with.<br/>Instance_num    Optional. Specifies which occurrence of old_text you want to replace with new_text. If you specify instance_num, only that instance of old_text is replaced. Otherwise, every occurrence of old_text in text is changed to new_text.'



                    },
                    {
                        name: 'text',

                        syntax: 'text(value, format_text)',

                        introduction: 'The TEXT function lets you change the way a number appears by applying formatting to it with format codes. <br/>TEXT(Value you want to format, "Format code you want to apply") <br/>TEXT(TODAY(),"MM/DD/YY")<br/>TEXT(TODAY(),"DDDD")<br/>TEXT(NOW(),"H:MM AM/PM")<br/>TEXT(0.285,"0.0%")'



                    },
                    {
                        name: 'trim',

                        syntax: 'trim(text)',

                        introduction: 'Removes all spaces from text except for single spaces between words.'

                    },
                    {
                        name: 'upper',

                        syntax: 'upper(text)',

                        introduction: 'Converts text to uppercase.'

                    }

                ]
            }
        ];
    }

    public getChangeList(formulaString: string): Array<string> {
        // Get all watch items from formula by regular expression
        const changeList = [];
        let list = [];
        const tmp = [];

        if (formulaString && formulaString.length > 0) {
            const reg = /\[[a-zA-Z0-9]{32}]/gm;
            list = formulaString.match(reg);
            if (list) {
                list.forEach(item => {
                    if (tmp.indexOf(item) === -1) {
                        tmp.push(item);
                        changeList.push(item.substr(1, 32));
                    }
                });
            }
        }
        return changeList;
    }

    public getFormulaResult(formulaData, valueSet, changeList, entityDelimiter, isHandleSubject = false) {
        const result = [];
        // Get all values for formula
        changeList.forEach((itemId) => {
            if (!valueSet[itemId]) {// handle error when template init, each control will change value, which causes the formula control will subscribe the change, but when run this logic, some control is still init, so valueSet[itemId] will be undefined
                return '\'\'';
            }
            let itemValue: any = '';
            const conValue = valueSet[itemId].value;

            if (valueSet[itemId].componentType === 'met-input' || valueSet[itemId].componentType === 'med-input' || valueSet[itemId].componentType === 'met-entity' || valueSet[itemId].componentType === 'med-entity' || valueSet[itemId].componentType === 'entity-input') {
                if (conValue && conValue.length > 0) {
                    if (conValue && conValue.length > 0) {
                        const shortNameArr = [];
                        const actualValue = valueSet[itemId].actualValue;
                        actualValue.forEach(item => {
                            if (item.id) {
                                shortNameArr.push(item.value);
                            }
                        });
                        // For entitiy, we should use the custom symbol
                        if (valueSet[itemId].componentType === 'entity-input') {
                            itemValue = shortNameArr.join(entityDelimiter);
                        } else {
                            itemValue = shortNameArr.join(',');
                        }
                    }
                    itemValue = '\'' + itemValue + '\'';
                    result.push(itemValue);
                } else {
                    itemValue = '\'' + itemValue + '\'';
                    result.push(itemValue);
                }
            } else if (valueSet[itemId].componentType === 'ed-input' || valueSet[itemId].componentType === 'ed-entity' || valueSet[itemId].componentType === 'source-input') {
                if (conValue && conValue.length > 0) {
                    const actualValue = valueSet[itemId].actualValue;
                    // tslint:disable-next-line: no-shadowed-variable
                    let itemValue = actualValue.value;
                    itemValue = '\'' + itemValue + '\'';
                    result.push(itemValue);
                } else {
                    itemValue = '\'' + itemValue + '\'';
                    result.push(itemValue);
                }
            } else if (valueSet[itemId].componentType === 'date-input') {
                const date = conValue;
                if (date) {
                    itemValue = date;
                } else {
                    itemValue = DateHelperWebService.getToday();
                }
                result.push(itemValue);
            } else if (valueSet[itemId].componentType === 'tb-input') {
                if (valueSet[itemId].options.numbersOnly) {
                    if (!valueSet[itemId].value) {
                        itemValue = 0;
                    } else {
                        itemValue = conValue;
                    }
                } else {
                    itemValue = conValue == null ? '' : conValue;
                    if (itemValue && itemValue.length > 255) {
                        itemValue = itemValue.substring(0, 256);
                    }
                    itemValue = '\'' + itemValue + '\'';
                }
                result.push(itemValue);
            } else {
                itemValue = conValue == null ? '' : conValue;
                itemValue = '\'' + itemValue + '\'';
                result.push(itemValue);
            }
        });
        // Replace all the illegal values in formula
        changeList.forEach((item, index) => {
            const regExp = new RegExp('\\[' + changeList[index] + ']', 'gim');
            if (result[index] instanceof Date) {
                let value;
                if (isHandleSubject) {
                    value = DateHelperWebService.getDateString(result[index], 'MM/dd/yyyy');
                } else {
                    value = DateHelperWebService.getDateString(result[index], 'MM/dd/yyyy hh:mm:ss TT');
                }
                formulaData = formulaData.replace(regExp, '\'' + value + '\'');
            } else {
                formulaData = formulaData.replace(regExp, ' ' + result[index] + ' ');
            }
        });
        return formulaData;
    }

    /***
     * convert formula edit string to executable formula string
     * formula: formula string
     * */
    public handleForFormulaJS(formula) {
        const functions = this.getAll();
        let matches = [];
        for (let i = 0; i < functions.length; i++) {
            for (let j = 0; j < functions[i].items.length; j++) {
                let reg = new RegExp('([?<=>%&,\\+\\-\\*\\/(\\s]' + functions[i].items[j].name + '\\s*\\()|(\\s*^' + functions[i].items[j].name + '\\s*\\()', 'ig');
                matches = formula.match(reg);
                if (matches && matches.length > 0) {
                    for (let m = 0; m < matches.length; m++) {
                        const firstChar = matches[m].substr(0, 1);
                        if (firstChar === ',' || firstChar === '<' || firstChar === '=' || firstChar === '>' || firstChar === '%' || firstChar === '&') {
                            reg = new RegExp(firstChar + functions[i].items[j].name + '\\s*\\(', 'ig');
                            formula = formula.replace(reg, firstChar + 'Formula.' + functions[i].items[j].name.toUpperCase() + '(');
                        } else if (firstChar === '(') {
                            reg = new RegExp('\\(' + functions[i].items[j].name + '\\s*\\(', 'ig');
                            formula = formula.replace(reg, firstChar + 'Formula.' + functions[i].items[j].name.toUpperCase() + '(');
                        } else if (firstChar === ' ') {
                            reg = new RegExp('\\s' + functions[i].items[j].name + '\\s*\\(', 'ig');
                            formula = formula.replace(reg, firstChar + 'Formula.' + functions[i].items[j].name.toUpperCase() + '(');
                        } else if (firstChar === '+') {
                            reg = new RegExp('\\+' + functions[i].items[j].name + '\\s*\\(', 'ig');
                            formula = formula.replace(reg, firstChar + 'Formula.' + functions[i].items[j].name.toUpperCase() + '(');
                        } else if (firstChar === '-') {
                            reg = new RegExp('\\-' + functions[i].items[j].name + '\\s*\\(', 'ig');
                            formula = formula.replace(reg, firstChar + 'Formula.' + functions[i].items[j].name.toUpperCase() + '(');
                        } else if (firstChar === '*') {
                            reg = new RegExp('\\*' + functions[i].items[j].name + '\\s*\\(', 'ig');
                            formula = formula.replace(reg, firstChar + 'Formula.' + functions[i].items[j].name.toUpperCase() + '(');
                        } else if (firstChar === '/') {
                            reg = new RegExp('\\/' + functions[i].items[j].name + '\\s*\\(', 'ig');
                            formula = formula.replace(reg, firstChar + 'Formula.' + functions[i].items[j].name.toUpperCase() + '(');
                        } else if (firstChar === '\n') {
                            reg = new RegExp('\n' + functions[i].items[j].name + '\\s*\\(', 'ig');
                            formula = formula.replace(reg, firstChar + 'Formula.' + functions[i].items[j].name.toUpperCase() + '(');
                        } else if (firstChar === '\t') {
                            reg = new RegExp('\t' + functions[i].items[j].name + '\\s*\\(', 'ig');
                            formula = formula.replace(reg, firstChar + 'Formula.' + functions[i].items[j].name.toUpperCase() + '(');
                        } else {
                            reg = new RegExp('^' + functions[i].items[j].name + '\\s*\\(', 'ig');
                            formula = formula.replace(reg, 'Formula.' + functions[i].items[j].name.toUpperCase() + '(');
                        }
                    }
                }
            }
        }
        return formula;
    }

    // requirement: must replace <> out side of quotes(including single quotes)
    // how it works
    // 1. first find all indexes matching <>
    // 2. find all indexes matching <> inside of double quotes
    // 3. find all indexes matching <> inside of single quotes
    // 4. find all indexes in step 1 but not in step 2, 3, then result index is all <> indexes outside of quotes
    // 5. replace them one by one.
    public handleSpecialSignForFormula(formula, oldSign, newSign) {
        oldSign = oldSign || '';
        newSign = newSign || '';
        if (oldSign.length !== newSign.length) {
            console.error('oldSign and newSign parameters must have the same length for current implementation');
            return;
        }
        const allIndexSet = [], doubleQuoteIndexSet = [], singleQuoteIndexSet = [];
        formula.replace(new RegExp(oldSign, 'g'), (match, index) => {
            allIndexSet.push(index);
        });

        formula.replace(new RegExp('\".*?\"', 'g'), (match, index) => {
            doubleQuoteIndexSet.push({
                start: index,
                end: index + match.length
            });
        });

        formula.replace(new RegExp('\'.*?\'', 'g'), (match, index) => {
            singleQuoteIndexSet.push({
                start: index,
                end: index + match.length
            });
        });

        const formulaArr = formula.split('');
        const count = newSign.length;
        for (let i = 0; i < allIndexSet.length; ++i) {
            if (!this.isExcluded(allIndexSet[i], doubleQuoteIndexSet) && !this.isExcluded(allIndexSet[i], singleQuoteIndexSet)) {
                const args = [allIndexSet[i], count].concat(newSign.split(''));
                Array.prototype.splice.apply(formulaArr, args);
            }
        }

        const result = formulaArr.join('');
        return result;
    }

    public handleSubjectByFormula(tmpValueSet) {
        let entityDelimiter = bizConstants.separator.defaultEntitySeparatorInSubject;
        let formulawithkeys = '';
        let changeList = [];
        if (tmpValueSet[bizConstants.standardControlID.subject].options) {
            // The saving action of subject doesn't equal to none
            if (tmpValueSet[bizConstants.standardControlID.subject].options.savingoptions !== bizConstants.subjectSavingOption.neverUseDefaultBehavior) {
                // Replace subject
                if ((tmpValueSet[bizConstants.standardControlID.subject].options.savingoptions === bizConstants.subjectSavingOption.always) ||
                    (tmpValueSet[bizConstants.standardControlID.subject].options.savingoptions === bizConstants.subjectSavingOption.onlyWhenUserHasNotEnteredNoteSubject && tmpValueSet[bizConstants.standardControlID.subject].value.length === 0)) {
                    // Get the formula function
                    formulawithkeys = tmpValueSet[bizConstants.standardControlID.subject].options.formulawithkeys;
                }
            }
        }
        if (formulawithkeys !== null && formulawithkeys !== undefined && formulawithkeys.length > 0) {
            formulawithkeys = this.handleForFormulaJS(formulawithkeys);
            formulawithkeys = this.ReplaceOperatorForFormulaJS(formulawithkeys);
            // Set the entity delimiter in subject
            if (tmpValueSet[bizConstants.standardControlID.subject].options.delimiteroption) {
                entityDelimiter = tmpValueSet[bizConstants.standardControlID.subject].options.entitydelimiter === 'SPACE' ? '' : tmpValueSet[bizConstants.standardControlID.subject].options.entitydelimiter;
            }
            // Add the ENTITIES and SOURCE to id change list for replacing it with value
            changeList = this.getChangeList(formulawithkeys);
            if (formulawithkeys.includes(bizConstants.standardControlInFormula.entity)) {
                changeList.push(bizConstants.standardControlID.entity);
            }
            if (formulawithkeys.includes(bizConstants.standardControlInFormula.source)) {
                changeList.push(bizConstants.standardControlID.source);
            }
            // Replace custom controls
            try {
                const result = this.getFormulaResult(formulawithkeys, tmpValueSet, changeList, entityDelimiter, true);
                // tslint:disable-next-line: no-eval
                const calValue = eval(result); // jshint ignore:line
                if (!(FORMULA.ISERROR(calValue) || calValue === bizConstants.VALUEERROR)) {
                    return calValue;
                } else {
                    return '';
                }
            } catch (e) {
                return '';
            }
        } else {
            return '';
        }
    }

    public isExcluded(index, indexArr) {
        for (let i = 0; i < indexArr.length; ++i) {
            if (index >= indexArr[i].start && index < indexArr[i].end) {
                return true;
            } else if (index < indexArr[i].start) {
                return false;
            }
        }
        return false;
    }

    public ReplaceOperatorForFormulaJS(formulaData) {
        if (formulaData && formulaData.length > 0) {
            // Replace the = with == for FormulaJS
            formulaData = formulaData
                .replace(/[^<>]=/g, function (replacement) {
                    return replacement.replace(/=/, '==');
                })
                .replace(/\"(.*?)\"/g, function (replacement) {
                    return replacement.replace(/==/g, '=');
                })
                .replace(/\'(.*?)\'/g, function (replacement) {
                    return replacement.replace(/==/g, '=');
                });

            formulaData = this.handleSpecialSignForFormula(formulaData, '<>', '!=');

            // Changed the formula today to correct formate
            const today = DateHelperWebService.getDateString(DateHelperWebService.getToday(), 'MM/dd/yyyy hh:mm:ss TT');
            formulaData = formulaData.replace(/Formula.TODAY\(\)/g, '\'' + today + '\'');

            // Replace & (infragistic formula use & as connector) with + (javascript use + as connector)
            formulaData = this.handleSpecialSignForFormula(formulaData, '&', '+');
        }

        return formulaData;
    }
}
