<div class="filter-item-container" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
    <div class="title-action-container body-2" tid="title-action-container">
        <div class="filter-title">
            <div>{{this.config.title}}</div>
        </div>
        <div class="action" [style.visibility]="isItemHoverOn?'visible':'hidden'">
            <smart-icon [ngClass]="" name="trash" (click)="onBtnCloseClicked()"></smart-icon>
        </div>
    </div>
    <div class="operator-content-container body-3" tid="operator-content-container">
        <div class="operator width-180" *ngIf="!this.config.hideOperators">
            <kendo-dropdownlist [popupSettings]="{appendTo: config.viewContainer}" [(ngModel)]="defaultItem"
                [data]="operators" [textField]="'name'" [valueField]="'value'" style="width: 100%;"
                (valueChange)="valueChange($event)">
            </kendo-dropdownlist>
        </div>
        <div class="content rating" tid="Priority">
            <tam-rating [rating]='rating.rating' [itemId]='rating.id' (ratingClick)='ratingComponentClick($event)'>
            </tam-rating>
        </div>
    </div>
</div>