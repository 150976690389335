/**
 * Created by Jiu Chen on 12/03/2018.
 * Description:
 *
 * ------ maintenance history ------
 */

import { combineReducers, createSelector } from '@ngrx/store';
import { AppState } from '..';
import { EntityTypesSubjectTypes } from '../../components/admin-pages/admin-page-setting.model';
import { EntityType } from '../../tamalelibs/models/entity-type.model';
import { AdminPageActions, AdminPageActionTypes } from '../actions/admin-page.actions';
import { EntityTypeActions, EntityTypeActionTypes } from '../actions/entity-type.actions';

export const entityTypeSelector = (state: AppState) => state.entityType;
export const availableEntityTypeSelector = createSelector(entityTypeSelector, (state: any) => state.available);
export const fullEntityTypeSelector = createSelector(entityTypeSelector, (state: any) => state.full);
export const defaultEntityTypeSelector = createSelector(entityTypeSelector, (state: any) => state.default);

export function entityTypeAvailableReducer(state: Array<any>, action: EntityTypeActions | AdminPageActions) {
    switch (action.type) {
        case EntityTypeActionTypes.INITIALIZE_SUCCESS:
            return action.payload.available;
        case EntityTypeActionTypes.INITIALIZE_FAILED:
            return action.payload.available;
        case AdminPageActionTypes.CREATE_NEW_ENTITY_TYPE_SUCCESS: {
            if (!('display-web' in action.payload.newEntity && action.payload.newEntity['display-web'] === false)) {
                state.push(new EntityType(action.payload.newEntity.id, action.payload.newEntity.name));
            }
            return Object.assign([], state);
        }
        case AdminPageActionTypes.DELETE_ENTITY_TYPE_SUCCESS: {
            state = state.filter(item => item.id !== action.payload.id);
            return Object.assign([], state);
        }
        case AdminPageActionTypes.EDIT_ENTITY_TYPE_SUCCESS: {
            if ('display-web' in action.payload.newEntity && action.payload.newEntity['display-web'] === false) {
                state = state.filter(item => item.id !== action.payload.newEntity.id);
            } else {
                state.forEach(entity => {
                    if (entity.id === action.payload.newEntity['id']) {
                        entity.name = action.payload.newEntity['name'];
                    }
                });
            }
            return Object.assign([], state);
        }
        case AdminPageActionTypes.TOGGLE_ENTITY_DISPLAY_SUCCESS: {
            if ('display-web' in action.payload.data && action.payload.data['display-web'] === false) {
                state = state.filter(item => item.id !== action.payload.data.id);
            } else {
                state.push(new EntityType(action.payload.data.id, action.payload.data.name));
            }
            return Object.assign([], state);
        }
        default:
            return state;
    }
}

export function entityTypeFullReducer(state: Array<any>, action: EntityTypeActions | AdminPageActions) {
    switch (action.type) {
        case EntityTypeActionTypes.INITIALIZE_SUCCESS:
            return action.payload.full;
        case EntityTypeActionTypes.INITIALIZE_FAILED:
            return action.payload.full;
        case AdminPageActionTypes.CREATE_NEW_ENTITY_TYPE_SUCCESS: {
            state.push(new EntityType(action.payload.newEntity.id, action.payload.newEntity.name));
            return Object.assign([], state);
        }
        case AdminPageActionTypes.DELETE_ENTITY_TYPE_SUCCESS: {
            state = state.filter(item => item.id !== action.payload.id);
            return Object.assign([], state);
        }
        case AdminPageActionTypes.EDIT_ENTITY_TYPE_SUCCESS: {
            state.forEach(entity => {
                if (entity.id === action.payload.newEntity['id']) {
                    entity.name = action.payload.newEntity['name'];
                }
            });
            return Object.assign([], state);
        }
        default:
            return state;
    }
}

export function entityTypeDefaultReducer(state: EntityType, action: EntityTypeActions | AdminPageActions) {
    switch (action.type) {
        case EntityTypeActionTypes.INITIALIZE_SUCCESS:
            return action.payload.default;
        case EntityTypeActionTypes.INITIALIZE_FAILED:
            return action.payload.default;
        case AdminPageActionTypes.EDIT_ENTITY_TYPE_SUCCESS: {
            if (state.id === action.payload.newEntity['id']) {
                state.name = action.payload.newEntity['name'];
            }
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.EDIT_DEFAULT_ENTITY_SUCCESS: {
            if (action.payload.defaultTypeField === EntityTypesSubjectTypes.DEFAULT_ENTITY_TYPE) {
                state.id = action.payload.data.id;
                state.name = action.payload.data.name;
            }
            return Object.assign({}, state);
        }
        default:
            return state;
    }
}

const entityTypeCombineReducer = combineReducers({
    available: entityTypeAvailableReducer,
    full: entityTypeFullReducer,
    default: entityTypeDefaultReducer
});

export function entityTypeReducer(state, action) {
    return entityTypeCombineReducer(state, action);
}
