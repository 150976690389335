/**
 * Created by Ella Ma on 2020-09-17.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppLoadService } from '../../app-load.service';
import { SystemUser } from '../../tamalelibs/models/user.model';
import { StoreQuerierService } from '../../services/store-querier.service';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { WorkflowConfigComponent } from './workflow-config/workflow-config.component';

@Injectable({
    providedIn: 'root'
})
export class WorkflowGuard implements CanActivate, CanDeactivate<WorkflowConfigComponent> {
    private _logoutTrigger = false;

    constructor(
        private _alertWindow: AlertWindowService,
        private _router: Router,
        private _initService: AppLoadService,
        private _storeQuerierService: StoreQuerierService,
    ) {
        this._initService.actionSubject$.subscribe((res) => {
            this._logoutTrigger = true;
        });
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        const currentUser: SystemUser = this._storeQuerierService.getCurrentUser();
        if (currentUser && currentUser.credential &&
            currentUser.credential.publishPubConfiguration &&
            currentUser.credential.admin) {
            return true;
        } else {
            this._router.navigate(['error']);
            return false;
        }
    }

    canDeactivate(component: WorkflowConfigComponent): Observable<boolean> | Promise<boolean> | boolean {
        if (!this._logoutTrigger) {
            if (component.workflowEditorSubChromeConfig.changeRouterBySelf) {
                return true;
            }
            return this._alertWindow.warn('You have unsaved changes',
                ['Are you sure you want to leave this page?'], 'Leave', 'Stay')
                .pipe(map((result: boolean) => {
                    return result;
                }));
        } else {
            return true;
        }
    }

}
