/**
 * Created by Jiu Chen on 02/20/2019.
 * Description:
 *      Research View related logic;
 *
 * ------ maintenance history ------
 *
 */

import { Injectable } from '@angular/core';

import { RelationshipTreeNode } from '../components/relationship-tree/relationship-tree.view-model';
import { Subject } from 'rxjs';

@Injectable()
export class ResearchHelperService {

    researchActionSubject$: Subject<any> = new Subject();

    constructor() { }

    /**
     * restore full checked keys from compressed checked key in saved screen data
     *
     * @param {string[]} savedCheckedKeys
     * @param {RelationshipTreeNode[]} treeNodes
     * @returns
     * @memberof ResearchHelperService
     */
    restoreCheckedKeys(savedCheckedKeys: string[], treeNodes: RelationshipTreeNode[]) {
        if (savedCheckedKeys == null || savedCheckedKeys.length === 0) {
            return [];
        }

        const checkedKeys = new Set();

        savedCheckedKeys.forEach(key => {
            const node: RelationshipTreeNode = treeNodes.find(_node => _node.uuid === key);
            if (node) {
                if (node.isLeaf) {
                    checkedKeys.add(node.uuid);
                } else {
                    checkedKeys.add(node.uuid);
                    treeNodes.filter(_node => _node.uuid.includes(key)).forEach(_node => checkedKeys.add(_node.uuid));
                }
            }
        });

        return Array.from(checkedKeys);
    }


    /**
     *
     *
     * @param {string[]} checkedKeys
     * @param {RelationshipTreeNode[]} treeNodes
     * @returns {string[]}
     * @memberof ResearchHelperService
     */
    restoreEntityIdsFromTree(checkedKeys: string[], treeNodes: RelationshipTreeNode[]): string[] {
        const checkedKeySet = new Set(checkedKeys);
        if (treeNodes) {
            return treeNodes.filter(node => node.nodeType === 'ENTITY' && checkedKeySet.has(node.uuid)).map(node => node.id);
        } else {
            return null;
        }
    }
}
