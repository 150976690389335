/**
 * Created by Alex on 12/18/2024.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AppendEntitiesDialogService {
    dialogOpen$: Subject<any> = new Subject<any>();
    actions$: Subject<any> = new Subject<any>();
}
