/**
 * Created by Abner Sui on 04/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { AppState } from '..';
import { Marketdata } from '../../tamalelibs/models/marketdata.model';
import { MarketdataActions, MarketdataActionTypes } from '../actions/marketdata.actions';

export const marketdataSelector = (state: AppState) => state.marketdata;

export function marketdataReducer(state: Array<Marketdata>, action: MarketdataActions): Array<Marketdata> {
    switch (action.type) {
        case MarketdataActionTypes.INITIALIZE_SUCCESS:
            return action.payload;
        case MarketdataActionTypes.INITIALIZE_FAILED:
            return action.payload;
        default:
            return state;
    }
}
