/**
 * Created by Bowen on 6/14/2020.
 * Description: contact body detail model
 *
 * ------ maintenance history ------
 *
 */
export class ContactTileConfig {
    componentType: string;
    data: any;
    position: number;
    selectedTab?: number;
    isCollapsed?: string; // 0: not collapsed(for default); 1: is collapsed, 2: not has this attributes
    showAs?: string;
}

export enum ContactActivityDataCatgory {
    HistoryNoteList = 'HistoryNoteList',
    HistoryCalendarList = 'HistoryCalendarList',
    UpcomingCalendarList = 'UpcomingCalendarList',
    OverdueTaskList = 'OverdueTaskList',
    UpcomingTaskList = 'UpcomingTaskList',
}

export enum ContactComponentActionTypes {
    CHANGE_COMPONENT = 'ChangeComponent',
    COLLAPSE_TILE = 'Collapse Tile',
    EXPAND_TILE = 'Expand Tile',
    RELOAD_ACTIVITY = 'ReloadActivity',
    RELOAD_RELATIONSHIP = 'ReloadRelationship',
    CHANGE_TAB = 'ChangeTab',
    CHANGE_RELATIONSHIP_SHOW_AS = 'ChangeRelationshipShowAs',
}

export enum ContactComponentCategory {
    ContactBasicInformation = 'ContactBasicInformation',
    ContactRelationships = 'Relationships',
    ContactNote = 'Notes',
    ContactActivity = 'Activities',
    ContactAddress = 'Address',
    ContactOther = 'Others',
}

// is used to show in the dialog as the section part title
export enum ContactBasicInformationCategory {
    BasicInformation = 'Basic Information',
    ContactInformation = 'Contact Information',
    Address = 'Address',
    Employment = 'Employment',
    Internet = 'Internet',
    Note = 'Notes',
    Other = 'Others',
}

// is used to stand for the section, not show in the dialog
export enum ContactDialogSectionName {
    BasicInformation = 'BASIC INFORMATION',
    ContactInformation = 'CONTACT INFORMATION',
    Address = 'ADDRESS',
    Employment = 'EMPLOYMENT',
    Relationship = 'RELATIONSHIPS',
    Internet = 'INTERNET INFORMATION',
    Other = 'OTHER',
    Note = 'NOTES'
}

export enum RelationshipTreeShowAs {
    Name = 'Name',
    ShortName = 'ShortName',
}
