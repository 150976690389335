/**
 * Created by Lucas Wang on 8/16/21.
 * Description:
 * Init the model of split-button
 * Add config to add display text and click event for primary button
 * Add config to add display texts and click events for the contextmenu of secondary button
 *
 * ------ maintenance history ------
 * Marcus Zhao 07/04/2022 upate SplitButtonConfig to add feedbackSubject for fix on push can't refresh.
 * add config.disabled to set button disabled.
 */

import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { SplitButtonConfig } from './split-button.model';
import { ContextMenuConfig, ContextMenuShowAction } from '../tam-context-menu/tam-context-menu.model';
import { Subscription } from 'rxjs';


@Component({
    selector: 'tam-split-button',
    templateUrl: './split-button.component.html',
    styleUrls: ['./split-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitButtonComponent implements OnInit, OnDestroy, OnChanges {

    @Input() config: SplitButtonConfig;

    contextMenuConfig: ContextMenuConfig;
    isDisabled = false;

    private _subscriptions: Array<Subscription> = [];
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this._subscriptions.push(
            this.config.feedbackSubject$.subscribe(res => {
                this.isDisabled = res;
                this._changeDetectorRef.detectChanges();
            }));
    }

    ngOnChanges(changes: SimpleChanges) {
        this.contextMenuConfig = new ContextMenuConfig();
        this.contextMenuConfig.stateful = false;
        this.contextMenuConfig.showItemIcon = false;
        this.contextMenuConfig.clickOnDiv = false;
        this.contextMenuConfig.items = [];
        if (changes.config.currentValue) {
            const currentConfig = changes.config.currentValue;
            for (let i = 0; i < currentConfig.secondaryActions.length; i++) {
                // A divider will be pushed instead if the displayText is ''
                if (currentConfig.secondaryActions[i].displayText) {
                    this.contextMenuConfig.items.push({ id: '' + i, text: currentConfig.secondaryActions[i].displayText, disabled: currentConfig.secondaryActions[i].isDisabled });
                } else {
                    this.contextMenuConfig.items.push({ divider: true });
                }
            }
            this.contextMenuConfig.events$.subscribe(event => this.onMenuSelect(event));
        }
    }

    ngOnDestroy() {
        this._subscriptions.forEach(item => item?.unsubscribe());
        this.contextMenuConfig.events$.unsubscribe();
    }

    onMenuSelect(event) {
        if (event && event.payload) {
            const id = parseInt(event.payload.id, 0);
            if (this.config.secondaryActions[id] && this.config.secondaryActions[id].onClick) {
                this.config.secondaryActions[id].onClick();
            }
        }
    }

    onPrimaryButtonClick() {
        if (this.config.primaryAction && this.config.primaryAction.onClick) {
            this.config.primaryAction.onClick();
        }
    }

    showContextMenu(e: MouseEvent) {
        this._changeDetectorRef.detectChanges();
        this.contextMenuConfig.show$.next(new ContextMenuShowAction(e, ''));
    }

}
