/**
 * Created by Marcus Zhao on 01/23/2019.
 *  Description: Open/Close dialog.
 * ------ maintenance history ------
 * 05/17/2022 Alex Add logic for handling attachments and images of office add-in
 * 07/28/2022 Marcus Zhao Support show progressbar by set progress.
 *                        Set note body progress by 10% ,50%, 100%
 *                        Set file progress by 10%,100%.
 * 08/17/2022 Alex add logic to support word add-in.
 * */
import { Injectable } from '@angular/core';
import { Subject, Observable, forkJoin, of } from 'rxjs';
import { AddInUtilities } from '../../services/utilities/addin-utilities';
import { AddInOnTheFlyModel, AddInType } from '../../models/addin.model';
import { businessConstants } from '../../constants/business.constants';
import { catchError, map, switchMap, take, filter, takeUntil } from 'rxjs/operators';
import { OfficeAddinService } from '../../services/office-addin-service';
import { OfficeAddIn, OfficeAttachment } from '../../tamalelibs/models/office-addin.model';
import { Contact } from '../../tamalelibs/models/contact.model';
import { StoreQuerierService } from '../../services/store-querier.service';
import { of as observableOf } from 'rxjs';
import { SystemUser } from '../../tamalelibs/models/user.model';
import { IdHelperService } from '../../services/id-helper.service';
import { OpenDynamicComponentService } from '../../pages/home/home-open-dynamic-component';
import { EntityBack, EntityDialogOpenOptions } from '../entity-dialog/entity-dialog.model';
import { ContactTemplateOpenOptions } from '../contact-dialog-new/contact-dialog.model';
import { getSignatureDataState } from '../../redux/reducers/signature.reducer';
import { select } from '@ngrx/store';
import { EmailSignatureConfig, SignatureAction, SignatureData } from '../template/email-signature-content/email-signature-content.model';
import { StringLiteralsPipe } from '../../pipes/translate.pipe';
import { Base64Service } from '../../tamalelibs/services/base64.service';
import { MailBoxItemType, requirementSetVersion } from './note-dialog.model';

@Injectable({
    providedIn: 'root',
})
export class NoteDialogService {
    cancelRequest$: Subject<void> = new Subject<void>();
    dialogOpen$: Subject<any> = new Subject<any>();
    minimized = false;
    opened = false;
    progressStatus10 = '10%';
    progressStatus50 = '50%';
    progressStatus100 = '100%';
    constructor(
        private _base64: Base64Service,
        private _officeAddinService: OfficeAddinService,
        private _storeQuerier: StoreQuerierService,
        private _openDynamicComponentService: OpenDynamicComponentService,
    ) { }

    isOutlookAddinMode() {
        return AddInUtilities.isOfficeJSLoaded() && Office.context.host.toString() === AddInType.OUTLOOK && !Office.context['isDialog'];
    }

    /**
     * get all data for office add-in
     * @param officeAddIn
     */
    getAllDataForAddIn(officeAddIn: OfficeAddIn): Observable<OfficeAddIn> {
        switch (Office.context.host.toString()) {
            case AddInType.OUTLOOK:
                const officeMailboxItem = Office.context.mailbox.item;
                const imagesArray = officeMailboxItem.attachments.filter(attachment => attachment.isInline);
                const observable = new Observable(subscriber => {
                    subscriber.next();
                    subscriber.complete();
                });
                return observable.pipe(
                    switchMap(() => {
                        const observables = [];
                        // handle images in note body
                        imagesArray.forEach(att => {
                            observables.push(this._convertImagesToStreamForNoteBody(att, officeAddIn));
                        });

                        observables.push(this._getSourceByEmailId(officeAddIn));
                        if (observables.length > 0) {
                            return forkJoin(observables).pipe(
                                take(1),
                                map(() => {
                                    return officeAddIn;
                                })
                            );
                        } else {
                            // handle the case there are no attachments or images
                            return of(officeAddIn);
                        }
                    })
                );
            case AddInType.WORD:
            case AddInType.EXCEL:
            case AddInType.POWERPOINT:
                return of(officeAddIn);
            default:
                break;
        }
    }

    /**
     * handle attachments of the email, not images in email body
     * offical document: https://docs.microsoft.com/en-us/javascript/api/outlook/office.attachmentcontent?view=outlook-js-preview
     * reference:
     * https://stackoverflow.com/questions/61814624/exception-in-getattachmentcontentasync-due-to-inactivity-unable-to-catch
     * https://learn.microsoft.com/en-us/javascript/api/requirement-sets/outlook/requirement-set-1.8/outlook-requirement-set-1.8?view=excel-js-preview
     * @param att
     * @param officeAddIn
     */
    getEmailAttachmentsForAddIn(att: Office.AttachmentDetails, officeAddIn: OfficeAddIn, progressStatus): Observable<OfficeAddIn> {
        const self = this;
        const officeMailbox = Office.context.mailbox;
        const officeMailboxItem = Office.context.mailbox.item;
        if (AddInUtilities.isOfficeSupportedVersion(requirementSetVersion.version1_8)) {
            return new Observable(subscriber => {
                // getAttachmentContentAsync is supported on requirement set above 1.8
                officeMailboxItem.getAttachmentContentAsync(att.id, result => {
                    if (result.value.format === Office.MailboxEnums.AttachmentContentFormat.Base64 || result.value.format === businessConstants.officeAddIn.emailType) {
                        let fileName;
                        const fileData = result.value.content;
                        let officeAttachment = new OfficeAttachment();

                        if (result.value.format === Office.MailboxEnums.AttachmentContentFormat.Base64) {
                            fileName = att.name;
                            officeAttachment.isBase64 = true;
                        } else if (result.value.format === businessConstants.officeAddIn.emailType) {
                            // handle the case that the attachment is email
                            fileName = att.name + '.' + businessConstants.officeAddIn.emailType;
                            officeAttachment.isBase64 = false;
                        }
                        officeAttachment.dzid = IdHelperService.uuidv4();
                        officeAttachment.fileName = fileName;
                        officeAttachment.contentType = att.contentType;
                        officeAttachment.progress = self.progressStatus10;
                        officeAddIn.attachments.push(officeAttachment);

                        progressStatus(officeAddIn);
                        const subscription$ = self._officeAddinService.depositAddInFile(officeAddIn, fileData, officeAttachment).pipe(
                            catchError(() => {
                                return observableOf();
                            })
                        ).subscribe((response) => {
                            if (subscription$) {
                                subscription$.unsubscribe();
                            }
                            officeAttachment.progress = self.progressStatus100;
                            officeAttachment = OfficeAttachment.parseFromUploadFile(officeAttachment, response);
                            officeAddIn.attachments.forEach(item => {
                                if (item.fileName === officeAttachment.fileName) {
                                    item = officeAttachment;
                                    progressStatus(officeAddIn);
                                }
                            });
                            subscriber.next(officeAddIn);
                            subscriber.complete();
                        });
                    } else {
                        subscriber.next(officeAddIn);
                        subscriber.complete();
                    }
                });
            });
        } else {
            // office 2019 only support requirement set 1.5
            return new Observable(subscriber => {
                officeMailbox.getCallbackTokenAsync({ isRest: true }, function (result) {
                    const token = result.value;
                    const restId = Office.context.mailbox.convertToRestId(Office.context.mailbox.item.itemId, Office.MailboxEnums.RestVersion.v2_0);
                    const attIdRestId = Office.context.mailbox.convertToRestId(att.id, Office.MailboxEnums.RestVersion.v2_0);
                    // we should use att restId instead of att.id, otherwise some attachments may lost
                    // mail use messages ,event use events.
                    const type = self._setOutlookInterface();
                    const getAttachmentUrl = Office.context.mailbox.restUrl + '/v2.0/me/' + type + '/' + restId + '/attachments/' + attIdRestId + '/$value';

                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', getAttachmentUrl);
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    xhr.responseType = 'arraybuffer';
                    xhr.onload = function (e) {
                        if (this.status === 200) {
                            let officeAttachment = new OfficeAttachment();
                            officeAttachment.dzid = IdHelperService.uuidv4();
                            officeAttachment.contentType = att.contentType;
                            officeAttachment.fileName = att.name;
                            officeAttachment.isBase64 = false;
                            officeAttachment.progress = self.progressStatus10;
                            officeAddIn.attachments.push(officeAttachment);
                            progressStatus(officeAddIn);

                            const subscription$ = self._officeAddinService.depositAddInFile(officeAddIn, this.response, officeAttachment).pipe(
                                catchError(() => {
                                    return observableOf();
                                })
                            ).subscribe((response) => {
                                if (subscription$) {
                                    subscription$.unsubscribe();
                                }
                                officeAttachment.progress = self.progressStatus100;
                                officeAttachment = OfficeAttachment.parseFromUploadFile(officeAttachment, response);
                                officeAddIn.attachments.forEach(item => {
                                    if (item.fileName === officeAttachment.fileName) {
                                        item = officeAttachment;
                                        progressStatus(officeAddIn);
                                    }
                                });
                                subscriber.next(officeAddIn);
                                subscriber.complete();
                            });
                        }
                    };

                    xhr.onerror = function () {
                        subscriber.next(officeAddIn);
                        subscriber.complete();
                    };
                    xhr.send();
                });
            });
        }
    }

    /**
    * deposit email as an attachment
    * reference:https://docs.microsoft.com/en-us/javascript/api/outlook/office.mailbox?view=outlook-js-preview
    * @param officeAddIn
    * @param method
    */
    getEmailContentForAddIn(officeAddIn: OfficeAddIn, progressStatus: any): Observable<OfficeAddIn> {
        const self = this;
        return new Observable(subscriber => {
            self._depositEmailContentFileToServer(officeAddIn, progressStatus, subscriber);
        });
    }

    /**
     * Get value from notebody ,
     * Get signature data by store(getSignatureDataState).
     * @param self means this from reference component.
     */
    getEmailSignatureData(self) {
        self._destroySubscriptions.push(
            self.valueChangeSubject.subscribe((res) => {
                if (res.componentType === 'ckeditor-input') {
                    self.emailSignaturStatus = StringLiteralsPipe.translate('crm.create_email_signature');
                    self.isShowSignatureItemsClick = false;
                    self.signConfig.signatureData.allSignatureData = [];
                    self.signCount = -1;
                    self._bodyData = res.value;
                }

            }),

            self._store.pipe(select(getSignatureDataState)).subscribe((res) => {
                if (!res) {
                    return;
                }
                self.signConfig.signatureData = new SignatureData();
                self.signCount = res.count;
                self.emailSignaturStatus = StringLiteralsPipe.translate('crm.new_contact_from_email_signature', res.count);
                self.isShowSignatureItemsClick = true;
                res.contacts.forEach((item, index) => {
                    // Add unique identification for each item.
                    item['uuid'] = IdHelperService.createGUID();
                    if (item.webCompany.length > 1) {
                        item.webCompany.forEach(element => {
                            element['isClickable'] = true;
                        });
                    }
                    if (item.duplicates.length === 0) {
                        self.signConfig.signatureData.signatureWithoutDuplicateData.push(item);
                    } else {
                        self.signConfig.signatureData.signatureWithDuplicateData.push(item);
                    }
                });
                // conact duplicate data and normal data.
                self.signConfig.signatureData.allSignatureData = self.signConfig.signatureData.signatureWithoutDuplicateData.concat(self.signConfig.signatureData.signatureWithDuplicateData);
            }),

            self.signConfig.feedbackSubject$.subscribe(res => {
                if (res.action === SignatureAction.setValue) {
                    self._openDynamicComponentService.feedbackSubject$.next(res.payload);
                } else if (res.action === SignatureAction.setStatus) {
                    self.showPopup = res.payload;
                }

            }));
    }


    /**
     * get the whole word/excel file content for add-in
     * Reference
     * https://docs.microsoft.com/en-us/office/dev/add-ins/word/get-the-whole-document-from-an-add-in-for-word
     * https://stackoverflow.com/questions/39695223/how-to-upload-a-local-word-docx-file-on-server-using-javascript
     * https://github.com/OfficeDev/office-js-docs-pr/blob/main/docs/includes/file-get-the-whole-document-from-an-add-in-for-powerpoint-or-word.md
     * @param officeAddIn
     */
    getAddInDocuments(officeAddIn: OfficeAddIn, progressStatus): Observable<OfficeAddIn> {
        const self = this;
        const officeAttachment = new OfficeAttachment();
        officeAttachment.dzid = IdHelperService.uuidv4();
        officeAttachment.fileName = officeAddIn.subject;
        officeAttachment.isBase64 = true;
        officeAttachment.progress = self.progressStatus10;
        officeAddIn.attachments.push(officeAttachment);
        progressStatus(officeAddIn);
        // get observable with word file
        return new Observable(subscriber => {
            Office.context.document.getFileAsync(Office.FileType.Compressed,
                { sliceSize: 4194304  /* 4MB */ }, (result) => {
                    // if the file is more than 64KB, it will be divided to more than one slice.
                    // all the contents will be returned together before depositing to server
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        officeAddIn.attachments[0].progress = self.progressStatus50;
                        progressStatus(officeAddIn);

                        // get file object from the result.
                        const myFile = result.value;
                        const slicesReceived = 0, gotAllSlices = true, docdataSlices = [];
                        self._getSliceAsync(officeAddIn, myFile, 0, gotAllSlices, docdataSlices, slicesReceived).pipe(
                            filter(res => res != null),
                        ).subscribe(res => {
                            officeAddIn = res;
                            progressStatus(officeAddIn);

                            subscriber.next(officeAddIn);
                            subscriber.complete();
                        });
                    } else {
                        subscriber.next(officeAddIn);
                        subscriber.complete();
                    }
                });
        });
    }

    /**
     * Used for entity/contact on the fly
     * @param self
     * @param _entityDialogOpenOptions
     * @param _contactDialogOpenOptions
     * @param callbackSetFlyEntity
     */
    showAddInDialogBox(self: any, _entityDialogOpenOptions: EntityDialogOpenOptions, _contactDialogOpenOptions: ContactTemplateOpenOptions, callbackSetFlyEntity: Function) {
        if (AddInUtilities.isOfficeEnvironment()) {
            const options = {
                height: 90,
                width: 60,
                displayInIframe: false
            };

            const closeOnTheFly = _entityDialogOpenOptions ? businessConstants.officeAddIn.closeEntityFly : businessConstants.officeAddIn.closeContactFly;
            const isShowEntityOnTheFly = _entityDialogOpenOptions ? true : false;
            const onTheFlyString = isShowEntityOnTheFly ? businessConstants.officeAddIn.entitydialog : businessConstants.officeAddIn.contactdialog;
            const url = 'https://' + window.location.host + '/msaddin/#/' + onTheFlyString;

            // to handle the on the fly issue on web. [TAM-42152]
            const addInOnTheFlyModel = new AddInOnTheFlyModel();
            addInOnTheFlyModel.isShowEntityOnTheFly = isShowEntityOnTheFly;

            if (isShowEntityOnTheFly) {
                _entityDialogOpenOptions.isOfficeAddinMode = true;
                addInOnTheFlyModel.entityDialogOpenOptions = _entityDialogOpenOptions;
                localStorage.setItem(businessConstants.officeAddIn.entityFly, JSON.stringify(_entityDialogOpenOptions));
            } else if (_contactDialogOpenOptions) {
                _contactDialogOpenOptions.isOfficeAddinMode = true;
                addInOnTheFlyModel.contactDialogOpenOptions = _contactDialogOpenOptions;
                localStorage.setItem(businessConstants.officeAddIn.contactFly, JSON.stringify(_contactDialogOpenOptions));
            }

            // get user
            if (localStorage.getItem('user')) {
                addInOnTheFlyModel.user = JSON.parse(localStorage.getItem('user'));
            }
            // display dialog box for on the fly
            Office.context.ui.displayDialogAsync(url,
                options,
                function (asyncResult) {
                    const dialog = asyncResult.value;

                    dialog.addEventHandler(Office.EventType.DialogEventReceived, processMessage);
                    dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
                    // send user to dialog box. [TAM-42152]
                    const interval = setInterval(() => {
                        dialog.messageChild(JSON.stringify(addInOnTheFlyModel));
                    }, 100);

                    // callback function to process message from dialog box
                    function processMessage(arg) {
                        self._homeViewModel.decrement();
                        // remove value in local storage
                        if (isShowEntityOnTheFly && localStorage.getItem(businessConstants.officeAddIn.entityFly)) {
                            localStorage.removeItem(businessConstants.officeAddIn.entityFly);
                        } else if (localStorage.getItem(businessConstants.officeAddIn.contactFly)) {
                            localStorage.removeItem(businessConstants.officeAddIn.contactFly);
                        }
                        if (arg.message) {
                            if (arg.message.includes(closeOnTheFly)) {
                                // user clicks Cancel on entity dialogbox
                                dialog.close();
                            } else if (arg.message === businessConstants.officeAddIn.gotUser) {
                                // clear interval [TAM-42152]
                                window.clearInterval(interval);
                            } else {
                                // close dialog after user click 'publish'
                                dialog.close();
                                // bind entity
                                let entityBack = new EntityBack();
                                entityBack = JSON.parse(arg.message);
                                if (_contactDialogOpenOptions && _contactDialogOpenOptions.isSource) {
                                    // for source.component, we only need to send back the entity
                                    entityBack['uuid'] = _contactDialogOpenOptions.contact.uuid;
                                    callbackSetFlyEntity(self, entityBack);
                                } else {
                                    self._entityService.getEntityListByIdsQuick([entityBack.entityId], self._source, self._watchControlValue).pipe(take(1),
                                        catchError((e) => {
                                            return observableOf();
                                        }))
                                        .subscribe(entityList => {
                                            self._zone.run(() => {
                                                callbackSetFlyEntity(self, entityList);
                                            });
                                        });
                                }
                            }
                        } else if (arg.error) {
                            if (arg.error === businessConstants.officeAddIn.dialogBoxCloseCode) {
                                // close dialog when user close the dialog box manually
                                dialog.close();
                            } else {
                                self._router.navigate(['error']);
                            }
                        }
                    }
                });
        }
    }

    /**
     * show entity dialog on the fly both in add-in and web mode
     * called by multi-entity-dropdown and ed-dropdown.component
     */
    showEntityDialog(self: any, index: number, _entityDialogOpenOptions, callbackSetFlyEntity: Function) {
        if (AddInUtilities.isOfficeJSLoaded() && index === 1) {
            this.showAddInDialogBox(self, _entityDialogOpenOptions, null, callbackSetFlyEntity);
        } else {
            this._openDynamicComponentService.openDialog$.next(_entityDialogOpenOptions);
        }
    }

    //#endregion

    //#region private functions for Office Add-In

    /**
     * images need to be convert to base64 stream to be shown on note body
     * @param att
     * @param officeAddIn
     * @returns
     */
    private _convertImagesToStreamForNoteBody(att: Office.AttachmentDetails, officeAddIn: OfficeAddIn): Observable<OfficeAddIn> {
        try {
            const self = this;
            const officeMailbox = Office.context.mailbox;
            if (AddInUtilities.isOfficeSupportedVersion(requirementSetVersion.version1_8)) {
                return new Observable(subscriber => {
                    Office.context.mailbox.item.getAttachmentContentAsync(att.id, result => {
                        if (result.status === Office.AsyncResultStatus.Succeeded) {
                            officeAddIn = self._handleEmailBody(officeAddIn, att.name, result.value.content);
                        }
                        subscriber.next(officeAddIn);
                        subscriber.complete();
                    });
                });
            } else {
                return new Observable(subscriber => {
                    officeMailbox.getCallbackTokenAsync({ isRest: true }, function (result) {
                        if (result.status === Office.AsyncResultStatus.Succeeded) {
                            const token = result.value;
                            const restId = Office.context.mailbox.convertToRestId(Office.context.mailbox.item.itemId, Office.MailboxEnums.RestVersion.v2_0);
                            const attIdRestId = Office.context.mailbox.convertToRestId(att.id, Office.MailboxEnums.RestVersion.v2_0);
                            // mail use messages ,event use events.
                            const type = self._setOutlookInterface();
                            const getAttachmentUrl = Office.context.mailbox.restUrl + '/v2.0/me/' + type + '/' + restId + '/attachments/' + attIdRestId + '/$value';
                            const xhr = new XMLHttpRequest();
                            xhr.open('GET', getAttachmentUrl);
                            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                            xhr.responseType = 'arraybuffer';
                            xhr.onload = function (e) {
                                if (this.status === 200) {
                                    self._handleEmailBody(officeAddIn, att.name, self._base64.arrayBufferToBase64(this.response));
                                    subscriber.next(officeAddIn);
                                    subscriber.complete();
                                }
                            };

                            xhr.onerror = function () {
                                subscriber.next(officeAddIn);
                                subscriber.complete();
                            };
                            xhr.send();
                        } else {
                            subscriber.next(officeAddIn);
                            subscriber.complete();
                        }
                    });
                });
            }
        } catch {
            return observableOf(officeAddIn);
        }
    }

    /**
     * create ics file
     * @param officeAddIn 
     * @returns 
     */
    private async _createICS(officeAddIn: OfficeAddIn): Promise<string> {
        // Strip HTML tags for DESCRIPTION, and ensure proper line breaks
        let plainTextDescription = this._stripHtml(officeAddIn.body)
            .replace(/\r?\n{2,}/g, '\n')  // Convert multiple \n to a single \n
            .replace(/\s+\n/g, '\n')      // Remove trailing spaces before \n
            .trim();

        // Replace newlines with `\\n` (ICS requirement)
        let formattedPlainTextDescription = plainTextDescription.replace(/\n/g, '\\n');

        // Ensure ICS line wrapping (each line ≤ 75 chars with continuation)
        function wrapICSLine(line: string): string {
            return line.match(/.{1,75}/g)?.join('\r\n ') || line;
        }

        // Fetch attachments
        const attachments = await this.fetchAttachments(Office.context.mailbox.item.attachments);
        const inlineAttachments = attachments.filter(att => att.inline);
        const nonInlineAttachments = attachments.filter(att => !att.inline);

        // Map CID references in HTML
        let htmlDescription = this._escapeHtmlForICS(officeAddIn.body)
            .replace(/\r?\n{2,}/g, '')  // Remove unnecessary newlines
            .replace(/\n/g, '<br>')     // Use <br> for line breaks
            .trim();

        inlineAttachments.forEach(attachment => {
            if (attachment.cid) {
                htmlDescription = htmlDescription.replace(
                    new RegExp(`cid:${attachment.cid}`, 'g'),
                    `data:${attachment.type};base64,${attachment.content}`
                );
            }
        });

        // Format Organizer Correctly
        const cleanOrganizerEmail = officeAddIn.organizer?.emailAddress
            ? officeAddIn.organizer.emailAddress.match(/<([^>]+)>/)?.[1] || officeAddIn.organizer.emailAddress
            : 'unknown@example.com';

        const organizerLine = `ORGANIZER;CN="${officeAddIn.organizer?.displayName || 'Organizer'}":mailto:${cleanOrganizerEmail}`;

        // Process standard attachments
        const attachmentLines = nonInlineAttachments.map(attachment => {
            return `ATTACH;FMTTYPE=${attachment.type};ENCODING=BASE64;VALUE=BINARY;X-FILENAME="${attachment.name}":\r\n ${attachment.content}`;
        });

        // Final ICS file structure (with fixed line wrapping)
        const icsLines = [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'PRODID:-/SS&C/Advent/Tamale/EN',
            'BEGIN:VEVENT',
            `UID:${wrapICSLine(officeAddIn.uuid.toString())}`,
            `SUMMARY:${wrapICSLine(officeAddIn.subject)}`,
            `DTSTART:${this._formatDateToICS(officeAddIn.eventStart)}`,
            `DTEND:${this._formatDateToICS(officeAddIn.eventEnd)}`,
            `LOCATION:${wrapICSLine(officeAddIn.location)}`,
            `DESCRIPTION:${wrapICSLine(formattedPlainTextDescription)}`,
            `X-ALT-DESC;FMTTYPE=text/html:${htmlDescription}`,
            organizerLine,
            ...attachmentLines,
            'END:VEVENT',
            'END:VCALENDAR'
        ];

        return icsLines.join('\r\n');
    }

    /**
     * create eml 
     * @param officeAddIn 
     * @returns 
     */
    private async _createEML(officeAddIn: OfficeAddIn): Promise<string> {
        const boundary = 'boundary_' + Math.random().toString(36).substring(2, 15);
        const alternativeBoundary = 'alt_' + Math.random().toString(36).substring(2, 15);

        // Extract and fetch attachments
        const attachments = await this.fetchAttachments(Office.context.mailbox.item.attachments);
        const inlineAttachments = attachments.filter(att => att.inline);
        const nonInlineAttachments = attachments.filter(att => !att.inline);

        let cleanedHtmlBody = this._cleanHtmlBody(officeAddIn.body);

        const sender = officeAddIn.sender
            ? officeAddIn.sender.displayName
                ? `"${officeAddIn.sender.displayName}" <${officeAddIn.sender.emailAddress}>`
                : officeAddIn.sender.emailAddress
            : 'unknown@example.com';

        const recipientsTo = officeAddIn.recipientEmails
            ?.map(r => (r.displayName ? `"${r.displayName}" <${r.emailAddress}>` : r.emailAddress))
            .join(', ') || '';

        const subject = officeAddIn.subject || 'No Subject';
        const date = new Date().toUTCString();

        const plainTextBody = officeAddIn.body.replace(/<\/?[^>]+(>|$)/g, "").trim() || "No content";

        const htmlEmailBody = `
    <!DOCTYPE html>
    <html>
    <head>
        <meta charset="UTF-8">
        <title>${subject}</title>
    </head>
    <body>
        <p>${cleanedHtmlBody || "No content"}</p>
    </body>
    </html>`;

        // Main EML content
        const emlContent = [
            `MIME-Version: 1.0`,
            `Date: ${date}`,
            `Subject: ${subject}`,
            `From: ${sender}`,
            `To: ${recipientsTo}`,
            `Content-Type: multipart/mixed; boundary="${boundary}"`,
            ``,
            `--${boundary}`,
            `Content-Type: multipart/alternative; boundary="${alternativeBoundary}"`,
            ``,
            `--${alternativeBoundary}`,
            `Content-Type: text/plain; charset="UTF-8"`,
            `Content-Transfer-Encoding: 7bit`,
            ``,
            plainTextBody,
            ``,
            `--${alternativeBoundary}`,
            `Content-Type: text/html; charset="UTF-8"`,
            `Content-Transfer-Encoding: 7bit`,
            ``,
            htmlEmailBody,
            ``,
            `--${alternativeBoundary}--`,
            ``,
            // Add non-inline attachments
            ...nonInlineAttachments.map(attachment => [
                `--${boundary}`,
                `Content-Type: ${attachment.type}; name="${attachment.name}"`,
                `Content-Disposition: attachment; filename="${attachment.name}"`,
                `Content-Transfer-Encoding: base64`,
                ``,
                attachment.content,
                ``
            ].join('\r\n')),
            `--${boundary}--`
        ].join('\r\n');

        return emlContent;
    }

    private _cleanHtmlBody(html: string): string {
        return html
            .replace(/<\/?meta[^>]*>/g, '')   // remove <meta> 
            .replace(/<\/?head[^>]*>/g, '')   // remove <head> 
            .replace(/<\/?body[^>]*>/g, '')   // remove <body> 
            .replace(/<\/?html[^>]*>/g, '')   // remove <html> 
            .trim();
    }

    /**
     * deposit email content file to server, support both shared mailbox or not.
     * @param officeAddIn
     * @param token
     * @param isSharedMailBox
     * @param progressStatus
     * @param subscriber
     * @param sharedProperties
     */
    private async _depositEmailContentFileToServer(officeAddIn: OfficeAddIn, progressStatus: any, subscriber: any) {
        const isAppointment = (officeAddIn.itemType === MailBoxItemType.Appointment) ? true : false;
        const self = this;

        const officeAttachment = new OfficeAttachment();
        officeAttachment.dzid = IdHelperService.uuidv4();
        officeAttachment.fileName = officeAddIn.subject + (isAppointment ? '.ics' : '.eml');
        officeAttachment.contentType = Office.context.mailbox.item.itemType;
        officeAttachment.isBase64 = false;
        officeAttachment.progress = self.progressStatus10;
        officeAddIn.attachments.push(officeAttachment);
        progressStatus(officeAddIn);

        officeAddIn.attachments.forEach(item => {
            if (item.fileName === officeAttachment.fileName) {
                item.progress = self.progressStatus50;
                progressStatus(officeAddIn);
            }
        });

        let res;
        if (isAppointment) {
            res = await this._createICS(officeAddIn);
        } else {
            // Wait for the _createEML function to complete before proceeding
            res = await this._createEML(officeAddIn);
        }

        const subscription$ = self._officeAddinService.depositAddInFile(officeAddIn, res, officeAttachment).pipe(
            takeUntil(self.cancelRequest$),
            catchError(() => {
                return observableOf();
            })
        ).subscribe((response) => {
            if (subscription$) {
                subscription$.unsubscribe();
            }
            officeAddIn.attachments.forEach(item => {
                if (item.fileName === officeAttachment.fileName) {
                    item.progress = self.progressStatus100;
                    item = OfficeAttachment.parseFromUploadFile(officeAttachment, response);
                    progressStatus(officeAddIn);
                }
            });
            subscriber.next(officeAddIn);
            subscriber.complete();
        });

        subscriber.next(officeAddIn);
        subscriber.complete();
    }

    private _escapeHtmlForICS(html: string): string {
        return html
            .replace(/\\n/g, '')  // Remove incorrectly escaped `\n`
            .replace(/\r?\n/g, '') // Remove all newlines
            .replace(/\\/g, '')    // Remove backslashes before `, ;`
            .replace(/([,;])/g, '\\$1') // Escape only commas and semicolons
            .trim(); // Remove unnecessary leading/trailing spaces
    }

    private async fetchAttachments(attachments: any[]): Promise<{ cid: string; content: string; type: string; name: string; inline: boolean }[]> {
        const fetchedAttachments = [];

        for (const attachment of attachments) {
            const contentResult = await new Promise((resolve, reject) => {
                Office.context.mailbox.item.getAttachmentContentAsync(attachment.id, (result) => {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        resolve(result.value);
                    } else {
                        reject(result.error);
                    }
                });
            });

            fetchedAttachments.push({
                cid: attachment.id || `image_${Math.random().toString(36).substring(2, 15)}@example.com`,
                content: contentResult['content'],
                type: contentResult['format'] === Office.MailboxEnums.AttachmentContentFormat.Base64 ? attachment.contentType : '',
                name: attachment.name,
                inline: attachment.isInline // Check if the attachment is inline
            });
        }

        return fetchedAttachments;
    }

    private _formatDateToICS(date: Date): string {
        return date.toISOString().replace(/-|:|\.\d+/g, '');
    }

    /**
     * get Source by email id
     *
     * @private
     * @param {*} officeAddIn
     * @returns {Observable<OfficeAddIn>}
     * @memberof NoteDialogService
     */
    private _getSourceByEmailId(officeAddIn): Observable<OfficeAddIn> {
        const self = this;
        return new Observable(subscriber => {

            this._officeAddinService.getContactByPrimaryEmail(officeAddIn.senderEmail)
                .pipe(take(1),
                    catchError((e) => {
                        subscriber.next(officeAddIn);
                        subscriber.complete();
                        return of();
                    }))
                .subscribe((res) => {
                    if (res && res['entity-list'] && res['entity-list'].length > 0) {
                        const contact = Contact.parse(res['entity-list'][0]);
                        officeAddIn['source'] = contact;
                    } else {
                        const currentUser: SystemUser = self._storeQuerier.getCurrentUser();
                        officeAddIn['source'] = currentUser;
                    }
                    subscriber.next(officeAddIn);
                    subscriber.complete();
                });
        });
    }

    /**
     * recursive algorithm to get all conents by each slice
     * @param officeAddIn
     * @param file file object
     * @param nextSlice will added 1 once called
     */
    private _getSliceAsync(officeAddIn: OfficeAddIn, file, nextSlice, gotAllSlices, docdataSlices, slicesReceived): Observable<OfficeAddIn> {
        const self = this;
        return new Observable(subscriber => {
            file.getSliceAsync(nextSlice, function (sliceResult) {
                if (sliceResult.status === 'succeeded') {
                    if (!gotAllSlices) {
                        // failed to get all slices, no need to continue.
                        subscriber.next(officeAddIn);
                        subscriber.complete();
                    }

                    // got one slice, store it in a temporary array.
                    docdataSlices[sliceResult.value.index] = sliceResult.value.data;
                    if (++slicesReceived === file.sliceCount) {
                        // all slices have been received.
                        file.closeAsync();
                        self._onGotAllSlices(officeAddIn, docdataSlices).pipe(
                            filter(res => res != null),
                        ).subscribe(res => {
                            officeAddIn = res;
                            subscriber.next(officeAddIn);
                            subscriber.complete();
                        });
                    } else {
                        self._getSliceAsync(officeAddIn, file, ++nextSlice, gotAllSlices, docdataSlices, slicesReceived).pipe(
                            filter(res => res != null),
                        ).subscribe(res => {
                            officeAddIn = res;
                            subscriber.next(officeAddIn);
                            subscriber.complete();
                        }
                        );
                    }
                } else {
                    gotAllSlices = false;
                    file.closeAsync();
                    subscriber.next(officeAddIn);
                    subscriber.complete();
                }
            });
        });
    }

    /**
     * for every image in note body, use base64 stream to replace url
     * @param officeAddIn
     * @param fileName
     * @param base64Stream
     * @returns
     */
    private _handleEmailBody(officeAddIn: OfficeAddIn, fileName: string, base64Stream: string): OfficeAddIn {
        const imgReg = businessConstants.common.imgRex;
        const srcReg = businessConstants.common.srcReg;
        const arr = officeAddIn.body.match(imgReg);
        if (arr) {
            for (let i = 0; i < arr.length; i++) {
                const src = arr[i].match(srcReg);
                if (src[1] && src[1].indexOf(fileName) > -1) {
                    officeAddIn.body = officeAddIn.body.replace(new RegExp(src[1], 'g'), businessConstants.officeAddIn.image.base64StringForEmail + base64Stream);
                }
            }
        }
        return officeAddIn;
    }

    /**
     * Get all the conent of the file by joining all the slices, and then deposit to server
     * @param officeAddIn
     * @param docdataSlices
     * @returns
     */
    private _onGotAllSlices(officeAddIn: OfficeAddIn, docdataSlices): Observable<OfficeAddIn> {
        const self = this;
        return new Observable(subscriber => {
            let docdata = [];
            for (let i = 0; i < docdataSlices.length; i++) {
                docdata = docdata.concat(docdataSlices[i]);
            }

            let fileContent = '';
            for (let j = 0; j < docdata.length; j++) {
                fileContent += String.fromCharCode(docdata[j]);
            }

            const subscription$ = self._officeAddinService.depositAddInFile(officeAddIn, btoa(fileContent), officeAddIn.attachments[0]).pipe(
                filter(res => res != null),
            )
                .subscribe((response) => {
                    if (subscription$) {
                        subscription$.unsubscribe();
                    }
                    officeAddIn.attachments[0].progress = self.progressStatus100;
                    officeAddIn.attachments[0] = OfficeAttachment.parseFromUploadFile(officeAddIn.attachments[0], response);
                    subscriber.next(officeAddIn);
                    subscriber.complete();
                });
        });
    }

    /**
     * https://learn.microsoft.com/en-us/previous-versions/office/office-365-api/api/version-2.0/calendar-rest-operations#GetAttachments
     * @returns get mailbox type
     */
    private _setOutlookInterface() {
        if (Office.context.mailbox.item.itemType === MailBoxItemType.Message) {
            return 'messages';
        } else if (Office.context.mailbox.item.itemType === MailBoxItemType.Appointment) {
            return 'events';
        }
    }

    private _stripHtml(html: string): string {
        return html.replace(/<\/?[^>]+(>|$)/g, "").trim();
    }

    /**
    * Update HTML body with Content-IDs
    * @param html 
    * @param attachments 
    * @returns 
    */
    private _updateHtmlWithContentIDs(
        html: string,
        attachments: { cid: string; content: string; type: string; name: string }[]
    ): string {
        let updatedHtml = html;
        attachments.forEach((attachment) => {
            updatedHtml = updatedHtml.replace(
                new RegExp(`src="${attachment.name}"`, 'g'),
                `src="cid:${attachment.cid}"`
            );
        });
        return updatedHtml;
    }
    //#endregion
}
