/**
 * Created by Ella Ma on 1/3/2018.
 * Description:
 *	pure filter items for reusable.
 *
 * ------ maintenance history ------
 * 08/12/2021 update by Marcus Zhao add filter data for job title
 * 24/4/2022  update by Marcus Zhao  always get new function by reqeust.
 * 06/22/2022 updata by Marcus Zhao  support filter content by search.
 */

import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { AppState } from '../../redux';
import { validRelationshipTypeSelector } from '../../redux/reducers/valid-relationship-type.reducer';
import { FilterMetadataType, FiltersConfig } from '../../tamalelibs/components/filter-config';
import { FilterWidgetConfig, FilterWidgetFeedback, FilterWidgetFeedbackType } from '../../tamalelibs/components/filter-widget.config';
import { EntityType } from '../../tamalelibs/models/entity-type.model';
import { FilterWidgetAciontType } from './tam-text-filter.component';
import * as _lodash from 'lodash';
import { ContactService } from '../../tamalelibs/services/contact.service';

/**
 * Filter widget for metadataType is Sentiment
 *
 * @export
 * @class TamDropdownFilterComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'tam-dropdown-filter',
    templateUrl: 'tam-dropdown-filter.component.html',
    styleUrls: ['tam-filter-container.component.scss']
})

export class TamDropdownFilterComponent implements OnInit, OnDestroy {

    @Input() config: FiltersConfig; // data
    @Input() filterWidgetConfig: FilterWidgetConfig; // event

    defaultItem: { name: string, value: string };
    operators: Array<{ name: string, value: string }> = [];
    selectItems: Array<{ name: string, value: string }> = [];
    selItem: Array<{ name: string, value: string }> = [];
    isItemHoverOn = false;
    isCheckBlank = true;
    isShowTrash = true;
    private _originalValue = '';
    private _dataItems: Array<any> = []; // Source data for filter search
    private _destroySubscriptions: Array<Subscription> = [];
    private _filterHandler$: Subject<string> = new Subject();

    constructor(private _deviceService: DeviceDetectorService,
        private _store: Store<AppState>,
        private _contactService: ContactService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.operators = this.config.operators;
        this.defaultItem = this.config.operators.find(item => item.value === this.config.selectedOperator);
        if (this.config.value) {
            this._originalValue = this.config.value.join(',').trim();
        } else {
            this._originalValue = '';
        }
        if (this.config.metadataType?.toLowerCase() === FilterMetadataType.JobTitle.toLowerCase()) {
            this._destroySubscriptions.push(
                this._store.pipe(
                    select(validRelationshipTypeSelector),
                    filter(res => res && res.length > 0),
                ).subscribe(res => {
                    this.selectItems = this._filterRelationship(res);
                }));
        } else if (this.config.metadataType?.toLowerCase() === FilterMetadataType.JobFunction.toLowerCase()) {
            this._destroySubscriptions.push(
                this._contactService.getJobFunction().subscribe(res => {
                    if (res && res.length > 0) {
                        res.forEach(item => {
                            this.selectItems.push({
                                'value': item.id,
                                'name': item.name
                            });
                        });
                    } else {
                        this.selectItems = this.config.source;
                    }
                    if (this.config.value) {
                        this.selItem = this.selectItems.filter(item => this.config.value.indexOf(item.value) !== -1);
                    }
                    this._changeDetectorRef.detectChanges();
                }));
        } else if (this.config.metadataType?.toLowerCase() === FilterMetadataType.Status.toLowerCase()) {
            // init status source;
            this.selectItems = [
                { 'name': 'Connected', 'value': 'Connected' },
                { 'name': 'Not Connected', 'value': 'Not Connected' },
                { 'name': 'Suspended', 'value': 'Suspended' },
            ];
            this.isShowTrash = false;
        } else {
            this.selectItems = this.config.source;
        }
        if (this.config.value && this.config.metadataType?.toLowerCase() !== FilterMetadataType.JobFunction.toLowerCase()) {
            // set last save value.
            this.selItem = this.selectItems?.filter(item => this.config.value.indexOf(item.value) !== -1);
        }
        this._dataItems = this.selectItems;

        this.isCheckBlank = (this.defaultItem.value.toLocaleLowerCase().indexOf('blank') > -1) || (this.defaultItem.value.toLocaleLowerCase().indexOf('null') > -1);
        // For use within normal web clients
        if (this._deviceService.isMobile() || this._deviceService.isTablet()) {
            this.isItemHoverOn = true;
        } else {
            this.isItemHoverOn = !this.config.deletable;
        }
        this._destroySubscriptions.push(
            this._filterHandler$.pipe(
                debounceTime(250),
            ).subscribe(filterStr => this._searching(filterStr))
        );
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    handleFilter(value) {
        this._filterHandler$.next(value);
    }

    onSelect() {
        const selValues: Array<string> = this.selItem.map(item => item.value);
        const selectedValueStr = selValues.join(',').trim();
        if (this._originalValue !== selectedValueStr) {
            this._originalValue = selectedValueStr;
            this._feedback(FilterWidgetAciontType.TEXT_CHANGE, selValues);
        }
    }

    onMouseOver() {
        if (!this.config.deletable) {
            this.isItemHoverOn = true;
        }
    }

    onMouseOut() {
        if (!this.config.deletable) {
            this.isItemHoverOn = false;
        }
    }

    onBtnCloseClicked() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.remove;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    valueChange(changeItem: { name: string, value: string }): void {
        this.isCheckBlank = changeItem.value.toLocaleLowerCase().indexOf('null') !== -1 || changeItem.value.toLocaleLowerCase().indexOf('blank') !== -1;
        // emits event for value change
        this._feedback(FilterWidgetAciontType.SELECTOR_CHANGE, changeItem.value);
    }

    private _feedback(type: FilterWidgetAciontType, value: any) {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.update;

        if (FilterWidgetAciontType.SELECTOR_CHANGE === type) {
            this.config.selectedOperator = value;
        } else if (FilterWidgetAciontType.TEXT_CHANGE === type) {
            this.config.value = value;
        }

        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    private _filterRelationship(res) {
        let suggestionItems = [];
        res.forEach(element => {
            if (element.childEntityType.id === EntityType.CONTACT.id &&
                element.parentEntityType.id === EntityType.CONTACT.id) {
                suggestionItems.push({
                    'value': element.relationshipType.id,
                    'name': element.relationshipType.name
                });
            }
        });
        if (suggestionItems.length !== 0) {
            suggestionItems = _lodash.sortBy(suggestionItems, item => item.name);
        }
        return suggestionItems;
    }

    /**
     * filter data by search value.
     *
     * @private
     * @param {*} value
     * @memberof TamDropdownFilterComponent
     */
    private _searching(value) {
        this.selectItems = this._dataItems.filter((content) => content.name.toLowerCase().indexOf(value.toLowerCase()) !== -1).sort();
        this._changeDetectorRef.detectChanges();
    }
}
