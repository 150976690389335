/**
 * Created by Marcus Zhao on 8/27/19.
 * Description: add calendarDialog option for Create/Edit Calendar Event
 *
 * ------ maintenance history ------
 *
 */

import { Contact } from '../../tamalelibs/models/contact.model';
import { EntityBack } from '../entity-dialog/entity-dialog.model';

export class ContactTemplateOpenOptions {
    openType: ContactTemplateType;
    minimizable: boolean;
    entryId: string;
    entity: any;
    attachments?: any[];
    contact?: Contact;
    isBlastEmail = false;
    entityBack: EntityBack;
    eventKey?: string;
    eventValue?: string;
    instanceId = '';
    isFly = false;
    isOfficeAddinMode = false;
    isSource = false;
    isSignature = false;
    isPaticipant = false;
}


export class AddressValue {
    type: string;
    country: string;
    street: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    constructor(type, country, street, street2, city, state, zip) {
        this.type = type;
        this.country = country;
        this.street = street;
        this.street2 = street2;
        this.city = city;
        this.state = state;
        this.zip = zip;
    }
}

export enum ContactTemplateType {
    NewContact = 'New Contact',
    EditContact = 'Edit Contact',
}

export enum ControlId {
    // employment
    AddNewPosition = 'AddNewPosition',
    Employment_Company_Key = 'Employment_Company_Key',
    Employment_Company_Value = 'Employment_Company_Value',
    Current_Employment_JobTitle = 'Current_Employment_JobTitle',
    Current_Employment_StartYear = 'Current_Employment_StartYear',
    Former_Employment_Divider = 'Former_Employment_Divider',
    Former_Employment_JobTitle = 'Former_Employment_JobTitle',
    Former_Employment_Company = 'Former_Employment_Company',
    Former_Employment_StartYear = 'Former_Employment_StartYear',
    Former_Employment_EndYear = 'Former_Employment_EndYear',

    // relationship
    AddRelationshipType = 'AddRelationshipType',
    AddRelationshipEntity = 'AddRelationshipEntity',
    AddRelationships = 'AddRelationships',
    FirstName = 'FirstName',
    LastName = 'LastName',
    Alias = 'Alias',
    Company = 'Company',
    JobTitle = 'JobTitle',
    JobFunction = 'JobFunction',
    Phone = 'Phone',
    Email = 'Emails',
    Address = 'Address',
    Notes = 'Notes',
    Birthday = 'Birthday',
    Internet = 'Internet',
    Trash = 'Trash',
    Relationships = 'Relationships',
    EmploymentCompany = 'EmploymentCompany',
    CurrentEmploment = 'CurrentEmploment',
    FormerEmployment = 'FormerEmployment',
    Gender = 'Gender',
    MiddleName = 'Middle Name',
    Prefix = 'Prefix',
    Suffix = 'Suffix'
}

export enum ContactAction {
    AddControl = 'AddControl',
    RemoveControl = 'RemoveControl',
}

export enum JobTitleAction {
    ChangeJobTitle = 'ChangeJobTitle',
    ClearJobTitile = 'ClearJobTitile',
    SetCurrentEmploymentValue = 'SetCurrentEmploymentValue'
}

export enum CompanyAction {
    ChangeCompany = 'ChangeCompany',
    // this var is for invalid company, that is from richclient
    CreateInvalidCompany = 'CreateInvalidCompany',
    // this var is for valid company, that is from the non-existing result when user search in company control
    CreateValidCompany = 'CreateValidCompany',
    SetCompanyValue = 'SetCompanyValue'
}

export enum RelationshipAction {
    ChangeRelationshipType = 'ChangeRelationshipType',
    ChangeRelationshipEntity = 'ChangeRelationshipEntity',
}

export enum ContactRelationshipType {
    Contact = 'Contact',
    ContactAll = 'Contact-All',
}

export enum NavigationAction {
    ChangeDialogSection = 'ChangeDialogSection',
    ChangeNavigationSection = 'ChangeNavigationSection',
}

export enum EmailAction {
    ChangeEmailValue = 'ChangeEmailValue',
    SetPrimary = 'SetPrimary',
}

export enum NameAction {
    ChangeNameValue = 'ChangeNameValue',
}
