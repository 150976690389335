/**
 * Created by Yu Zhang on 12/1/17.
 * Description:
 *      set a static singleton file downloader instance for file downloading
 *      set a static singleton exception handler to handle exceptions
 *
 * ------ maintenance history ------
 * updated by Alan Yang Jan 24 2019
 * removed 10s timeout for downloading file.
 * 05/21/2019 add PlusCharHttpUrlEncodingCodec for any http request needs '+' sign to be encoded in url parameter
 * updated by Marcus Zhao 09/08/2021 add patch method()
 * updated by Marcus Zhao 05/31/2021 add timer as a parameter for set delay time for post action.
 * updated by Marcus Zhao 07/28/2022 set custom timeout for post action.
 */

import { of as observableOf, throwError as observableThrowError, Observable, timer } from 'rxjs';

import { catchError, debounceTime, switchMap, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpUrlEncodingCodec } from '@angular/common/http';
import { ProtocolConfig } from '../models/protocol-config.model';
import { AppConfig } from '../models/app-config.model';
import { ErrorHandlerService } from './error-handler.service';

import { InValidTokenError } from '../models/restapi-error.model';

import { IFileDownloader } from './file-downloader.interface.service';
import { IExceptionHandler } from './exception-handler.interface.service';
import { INetworkErrorHandler } from './network-error-handler.interface.service';
import { LoggerService } from '../services/logger.service';
import { NETWORK } from '../constants/logger.constants';

@Injectable()
export class TransportService {
    private NO_NETWORK: string = AppConfig.noNetworkErrorMsg;

    constructor(
        private _http: HttpClient,
        private _excpetionHandler: IExceptionHandler,
        private _fileDownloader: IFileDownloader,
        private _networkErrorHandler: INetworkErrorHandler,
        private _logger: LoggerService
    ) { }

    delete(url: string, options: any = { headers: {} }): Observable<any> {
        if (this._networkErrorHandler.noNetworkExceptionHanlder()) {
            return observableThrowError({ message: this.NO_NETWORK });
        }
        url = ProtocolConfig.prefix + AppConfig.serverAddress + url;
        if (options.headers == null) {
            options.headers = {};
        }
        if (AppConfig.token && AppConfig.token.length > 0 && options && !options.headers.hasOwnProperty('Authorization')) {
            options.headers['Authorization'] = '[token' + AppConfig.token + 'token]';
        }
        return this._http.delete(url, options).pipe(
            timeout(AppConfig.defaultRestapiTimeoutSpan),
            catchError(e => this._handleError(e)));
    }

    get(url: string, options: any = { headers: {} }): Observable<any> {
        if (this._networkErrorHandler.noNetworkExceptionHanlder()) {
            return observableThrowError({ message: this.NO_NETWORK });
        }
        url = ProtocolConfig.prefix + AppConfig.serverAddress + url;
        if (options.headers == null) {
            options.headers = {};
        }
        if (AppConfig.token && AppConfig.token.length > 0 && options && !options.headers.hasOwnProperty('Authorization')) {
            options.headers['Authorization'] = '[token' + AppConfig.token + 'token]';
        }
        return this._http.get(url, options).pipe(
            timeout(AppConfig.defaultRestapiTimeoutSpan),
            catchError(e => this._handleError(e)));
    }

    getFile(options: any, filePath: string): Observable<any> {
        if (this._networkErrorHandler.noNetworkExceptionHanlder()) {
            return observableThrowError({ message: this.NO_NETWORK });
        }
        options.url = ProtocolConfig.prefix + AppConfig.serverAddress + options.url;
        if (options.headers == null) {
            options.headers = {};
        }
        if (AppConfig.token && AppConfig.token.length > 0 && options && !options.headers.hasOwnProperty('Authorization')) {
            options.headers['Authorization'] = '[token' + AppConfig.token + 'token]';
        }
        return this._fileDownloader.getFile(options, filePath).pipe(
            catchError(e => this._handleError(e)));
    }

    // params is object, key and vlue not object and arr
    getUrlEncodedForm(params: any) {
        let param;
        const formData = new URLSearchParams();
        for (param in params) {
            if (params.hasOwnProperty(param)) {
                if (typeof params[param] === 'object') {
                    formData.set(param, JSON.stringify(params[param]));
                } else {
                    formData.set(param, params[param]);
                }
            }
        }
        return formData.toString();
    }

    options(url: string, body: any, options: any = { headers: {} }): Observable<any> {
        if (this._networkErrorHandler.noNetworkExceptionHanlder()) {
            return observableThrowError({ message: this.NO_NETWORK });
        }
        url = ProtocolConfig.prefix + AppConfig.serverAddress + url;
        if (options.headers == null) {
            options.headers = {};
        }
        if (AppConfig.token && AppConfig.token.length > 0 && options && !options.headers.hasOwnProperty('Authorization')) {
            options.headers['Authorization'] = '[token' + AppConfig.token + 'token]';
        }
        return this._http.options(url, options).pipe(
            timeout(AppConfig.defaultRestapiTimeoutSpan),
            catchError(e => this._handleError(e)));
    }

    patch(url: string, body: any, options: any = { headers: {} }): Observable<any> {
        if (this._networkErrorHandler.noNetworkExceptionHanlder()) {
            return observableThrowError({ message: this.NO_NETWORK });
        }
        url = ProtocolConfig.prefix + AppConfig.serverAddress + url;
        if (options.headers == null) {
            options.headers = {};
        }
        if (AppConfig.token && AppConfig.token.length > 0 && options && !options.headers.hasOwnProperty('Authorization')) {
            options.headers['Authorization'] = '[token' + AppConfig.token + 'token]';
        }
        return this._http.patch(url, body, options).pipe(
            timeout(AppConfig.defaultRestapiTimeoutSpan),
            catchError(e => this._handleError(e)));
    }

    /**
     *
     *
     * @param {string} url
     * @param {*} body
     * @param {*} [options={ headers: {} }]
     * @param {number} [delay=0] if need set a delay time.
     * @returns {Observable<any>}
     * @memberof TransportService
     */
    post(url: string, body: any, options: any = { headers: {} }): Observable<any> {
        if (this._networkErrorHandler.noNetworkExceptionHanlder()) {
            return observableThrowError({ message: this.NO_NETWORK });
        }
        url = ProtocolConfig.prefix + AppConfig.serverAddress + url;
        if (options.headers == null) {
            options.headers = {};
        }
        if (AppConfig.token && AppConfig.token.length > 0 && options && !options.headers.hasOwnProperty('Authorization')) {
            options.headers['Authorization'] = '[token' + AppConfig.token + 'token]';
        }

        return this._http.post(url, body, options).
            pipe(
                timeout(options.timeout || AppConfig.defaultRestapiTimeoutSpan),
                catchError(e => this._handleError(e))
            );
    }

    put(url: string, body: any, options: any = { headers: {} }): Observable<any> {
        if (this._networkErrorHandler.noNetworkExceptionHanlder()) {
            return observableThrowError({ message: this.NO_NETWORK });
        }
        url = ProtocolConfig.prefix + AppConfig.serverAddress + url;
        if (options.headers == null) {
            options.headers = {};
        }
        if (AppConfig.token && AppConfig.token.length > 0 && options && !options.headers.hasOwnProperty('Authorization')) {
            options.headers['Authorization'] = '[token' + AppConfig.token + 'token]';
        }
        return this._http.put(url, body, options).pipe(
            timeout(AppConfig.defaultRestapiTimeoutSpan),
            catchError(e => this._handleError(e)));
    }

    request(options: any): Observable<any> {
        if (this._networkErrorHandler.noNetworkExceptionHanlder()) {
            return observableThrowError({ message: this.NO_NETWORK });
        }
        options.url = ProtocolConfig.prefix + AppConfig.serverAddress + options.url;
        if (options.headers == null) {
            options.headers = {};
        }
        if (AppConfig.token && AppConfig.token.length > 0 && options && !options.headers.hasOwnProperty('Authorization')) {
            options.headers['Authorization'] = '[token' + AppConfig.token + 'token]';
        }
        return this._http.request(options).pipe(
            timeout(AppConfig.defaultRestapiTimeoutSpan),
            catchError(e => this._handleError(e)));
    }

    private _handleError(error: any): Observable<any> {
        this._logger.error(error.message ? error.message.toString() : error.toString(), NETWORK);
        const e: Error = ErrorHandlerService.getError(error);
        if (e instanceof InValidTokenError) {
            AppConfig.token = '';
            this._excpetionHandler.tokenExpriationHandler(e);
            return observableOf();
        }

        return observableThrowError(e);
    }
}

export class URIComponentEncodingCodec extends HttpUrlEncodingCodec {
    encodeValue(k: string): string {
        return encodeURIComponent(k);
    }
    decodeValue(k: string): string {
        return decodeURIComponent(k);
    }
}
