/**
 * Created by lucas wang on 08/24/2021.
 * Description: UsersEditor Component
 * ------ maintenance history ------
 * Modified by Cathy Yao on 09/9/2021: add input focus on aacl-keyword search
 * Modified by Cathy Yao on 09/10/2021: edit save credential functions
 * Modified by Cathy Yao on 12/20/2021: Ability to add teams when create a user
 * Modified by Holly Li on 09/06/2022: fix bug occured when user with admin credential change other user's username,
 */

import { ChangeDetectorRef, Component, ContentChild, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../redux';
import { AaclKeywordSearchOptions } from 'aacl-keyword-search';

import { AdminPageState, AdminPageStatusTypes, credentialSelector } from '../../../../../redux/reducers/admin-page.reducer';
import { ContactService } from '../../../../../tamalelibs/services/contact.service';
import { SlideSheetService } from '../../../../../services/slide-sheet.service';
import { SlideSheetActionTypes } from '../../../../slide-sheet/slide-sheet.model';
import { UserEditorConfig, UserCredentialConfig } from './user-editor.model';
import { UserEditorConstants } from './user-editor-constants';
import { CreateNewUser, EditUser } from '../../../../../redux/actions/admin-page.actions';
import { UntypedFormControl } from '@angular/forms';
import { Contact } from '../../../../../tamalelibs/models/contact.model';
import { ContactList } from '../../../../../tamalelibs/models/entity-list.model';
import { User, Credential } from '../../../../../tamalelibs/models/user.model';
import { HomeViewModel } from '../../../../../pages/home/home-view.model';
import { filter, take } from 'rxjs/operators';
import { ContactTemplateOpenOptions, ContactTemplateType } from '../../../../contact-dialog-new/contact-dialog.model';
import { EntityBack } from '../../../../entity-dialog/entity-dialog.model';
import { OpenDynamicComponentService } from '../../../../../pages/home/home-open-dynamic-component';
import { AuthService } from '../../../../../tamalelibs/services/auth.service';
import { businessConstants } from '../../../../../constants/business.constants';
import { allTeamSelector } from '../../../../../redux/reducers/teams.reducer';
import { UsersService } from '../../../../../tamalelibs/services/users.service';
import { AlertWindowService } from '../../../../../widgets/alert-window/alert-window.service';
import { StoreQuerierService } from '../../../../../services/store-querier.service';
import { MultiSelDropdownFilterConfig } from '../../../../../widgets/multi-sel-dropdown-filter/multi-sel-dropdown-filter.model';
import { ALL_VALUE } from '../../../../../tamalelibs/constants/business.constants';
import { ArrayHelperService } from '../../../../../tamalelibs/services/array-helper.service';
import { StringLiteralsPipe } from '../../../../../pipes/translate.pipe';

declare function tooltip(): any;

@Component({
    selector: 'tam-users-editor',
    templateUrl: './users-editor.component.html',
    styleUrls: ['./users-editor.component.scss']
})
export class UsersEditorComponent implements OnInit, OnDestroy {
    @Input() userData?: any;
    @ViewChild('itemContainer', { static: false }) itemContainer: ElementRef;
    @ContentChild('multiCustomContent', { static: false }) multiCustomContent: TemplateRef<any>;

    adminCredentialId = businessConstants.adminPage.adminCredentialId;
    confirmedPWD = new UntypedFormControl('');
    contactList: ContactList;
    createContactText: string;
    currUserData: User;
    currentPage: number;
    currentUser;
    checkedData = [];
    dropdownConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    isButtonShow = false;
    isChanged = false;
    isChangePasswordFirstTime = true;
    isCloneShow = false;
    isTeamShow = false;
    isCredentialShow = false;
    isEditAdmin = false;
    isHasComplianceCredentialsInEditMode = false;
    isProgressing = false;
    keyword = '';
    keywordSearchOptions: AaclKeywordSearchOptions = new AaclKeywordSearchOptions('', 0, ' ');
    keywordSearchOptionsUser: AaclKeywordSearchOptions = new AaclKeywordSearchOptions('', 0, ' ');
    keywordUser = '';
    nameDuplicated = false;
    password = new UntypedFormControl('');
    readOnly = false; // flag to disable auto complete
    secureEmail = new UntypedFormControl('');
    selectedContact: Contact;
    selectedUser: User;
    selectedItems: Array<any> = []; // selected items, sub set of teamItems
    searchValue: string;
    teamItems = [];
    unCheckedData = [];
    userCredentialConfig: UserCredentialConfig;
    userEditorConfig: UserEditorConfig;
    userList: User[];
    userName = new UntypedFormControl('');

    private _destroySubscriptions: Array<Subscription> = [];
    private _el: HTMLElement;
    private _filterHandler$: Subject<string> = new Subject();
    private _isClickCloneExistingUserCredentials = false;
    private teamsName: string;
    private _changeCredential: boolean;

    constructor(
        private _authService: AuthService,
        private _alertWindow: AlertWindowService,
        private _contactService: ContactService,
        private _userService: UsersService,
        private _element: ElementRef,
        private _homeViewModel: HomeViewModel,
        private _openDynamicComponentService: OpenDynamicComponentService,
        private _store: Store<AppState>,
        private _slideSheetService: SlideSheetService,
        private _storeQuerier: StoreQuerierService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
        this._el = this._element.nativeElement;
    }

    ngOnInit() {
        /** Initialize keyword search */
        this._changeCredential = false;
        this.currentUser = this._storeQuerier.getCurrentUser();
        this.dropdownConfig.hideInput = !this.currentUser.credential.manageTeam;
        this._destroySubscriptions.push(this.keywordSearchOptions.feedbackSubject$.subscribe(
            feedback => this.handleKeywordChange(feedback.payload.data)
        ));
        this._destroySubscriptions.push(this.keywordSearchOptionsUser.feedbackSubject$.subscribe(
            feedback => this.keywordUser = feedback.payload.data
        ));
        this._editorInit();
        if (this.currUserData && this.currUserData.inTeams) {
            this.teamsName = this.currUserData.inTeams.map(item => item.name).join(',');
        } else {
            this.teamsName = '';
        }
        this.createContactText = 'or ';
        this._destroySubscriptions.push(
            this._store.pipe(select('adminPage')).subscribe((pageState: AdminPageState) => {
                this._parsePageState(pageState);
            }),
            this._openDynamicComponentService.feedbackSubject$.subscribe((feedback: EntityBack) => {
                /** Should turn to User Detail page and show input values when contacts are created successfully */
                if (feedback.searchValue) {
                    this.selectedContact = new Contact();
                    this.selectedContact.id = feedback.entityId;
                    this.selectedContact.name = feedback.searchValue;
                    this.selectedContact.primaryEmail = feedback.primaryEmail;
                    this.selectedContact.jobTitle = feedback.jobTitle;
                    this.selectedContact.companyWeb = feedback.companyWeb;
                    this.currUserData.primaryEmail = this.selectedContact.primaryEmail;
                    this.currentPage = 1;
                    this.isCredentialShow = false;
                    this.isButtonShow = true;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
        this.clearCredentialConfig();
    }

    changeCredentialStatus(value: boolean, credential: Credential) {
        credential.hasCredential = value;
        this._changeCredential = true;
    }

    clearCredentialConfig() {
        for (const credential of this.userCredentialConfig.credentialConfigs) {
            credential.hasCredential = false;
        }
    }

    clearErrors() {
        this.clearUserNameError();
        this.password.setErrors(null);
        this.confirmedPWD.setErrors(null);
        this.secureEmail.setErrors(null);
    }

    clearUserNameError() {
        this.nameDuplicated = false;
        this.userName.setErrors(null);
        this._handleComplianceCredential();
    }

    // when click clone credentials the search box auto focus
    clickCloneCredentials($event) {
        this._isClickCloneExistingUserCredentials = true;
        this.isCloneShow = !this.isCloneShow;
        this._changeCredential = true;
        setTimeout(() => {
            if (this._element && this.isCloneShow) {
                this._element.nativeElement.querySelector('.aacl-keyword-search-container .input-area input').focus();
            }
        });
    }

    createContact() {
        let index;
        this._homeViewModel.increment();
        this._homeViewModel.getFlyIndex().pipe(
            take(1)
        ).subscribe(res => index = res);
        const _contactDialogOpenOptions = new ContactTemplateOpenOptions();
        _contactDialogOpenOptions.openType = ContactTemplateType.NewContact;
        const contact = new Contact();
        contact.name = this.keyword;
        _contactDialogOpenOptions.contact = contact;
        _contactDialogOpenOptions.instanceId = index.toString();
        const entityBack = new EntityBack();
        entityBack.controlId = 'ENTITIES';
        entityBack.searchValue = this.keyword;
        _contactDialogOpenOptions.entityBack = entityBack;
        _contactDialogOpenOptions.isFly = true;
        this._openDynamicComponentService.openContactDialog$.next(_contactDialogOpenOptions);
    }

    credentialInit(credentials: Credential[]) {
        this.userCredentialConfig.credentialConfigs.forEach(
            credentialCfg => credentialCfg.hasCredential = credentials.findIndex(c => c.id === credentialCfg.id) >= 0
        );
    }

    configChanged() {
        this.isChanged = true;
    }

    getCredentials(): Credential[] {
        const credentialIdList = [];
        if (!this.isCredentialShow) {
            for (const credential of this.userCredentialConfig.credentialConfigs) {
                if (credential.hasCredential) {
                    credentialIdList.push(credential.id);
                }
            }
        }
        return credentialIdList;
    }

    /** get and return the job title of the contact when job title exists */
    getJobTitle(contact: Contact): string {
        const jobName = contact.jobTitle;
        const company = contact.companyWeb;
        const companyName = company && JSON.stringify(company) !== '{}' ? company.name : '';
        if (jobName && companyName) {
            return jobName + ' at ' + companyName;
        } else if (jobName) {
            return jobName;
        } else {
            return companyName;
        }
    }

    handleEmailChange() {
        this._handleComplianceCredential();
    }

    handleKeywordChange(inputText: string) {
        this.keyword = inputText;
        const filteredContactNumber = this.itemContainer.nativeElement.children.length;
        /** There is no data found when keyword is input and filteredContactNumber is 0 */
        if (inputText.length > 1 && !filteredContactNumber) {
            this.createContactText = 'No data found, please ';
        } else {
            this.createContactText = 'or ';
        }
    }

    handlePasswordChange() {
        if (this.currentPage === 2) {
            /** confirmedPWD should be cleared when the password is changed first time */
            if (this.isChangePasswordFirstTime &&
                this.currUserData.password &&
                this.currUserData.password !== UserEditorConstants.User.Password) {
                this.currUserData.confirmedPWD = '';
                this.isChangePasswordFirstTime = false;
                this._handleComplianceCredential();
            }
        }
    }

    handleFilter(value) {
        this._filterHandler$.next(value);
    }

    /** 'input-area','icon','icon-wrapper'have involved in 'aacl-keyword-search' We need to read them extra */
    onClickUserEditor(event) {
        if (!this._isClickCloneExistingUserCredentials) {
            if (this.isCloneShow) {
                if (event.target.parentElement.getAttribute('role') === 'searchBox' ||
                    event.target.parentElement.getAttribute('class') === 'input-area' ||
                    event.target.parentElement.getAttribute('class') === 'icon' ||
                    event.target.parentElement.getAttribute('class') === 'icon-wrapper' ||
                    event.target.getAttribute('class') === 'k-popup') {
                    this.isCloneShow = true;
                } else {
                    this.isCloneShow = false;
                }
            }
        }
        this._isClickCloneExistingUserCredentials = false;
    }

    onSlideSheetClose(isNeedAlert: boolean = false) {
        if (this.isChanged && isNeedAlert) {
            const message = `Are you sure you want to discard all your changes?`;
            const subscription = this._alertWindow.warn('You have unsaved changes',
                [message], StringLiteralsPipe.translate('general.discard'), StringLiteralsPipe.translate('general.go_back'))
                .subscribe((result: boolean) => {
                    if (result) {
                        this._slideSheetService.slideSheetActionSubject$.next({
                            type: SlideSheetActionTypes.CLOSE
                        });
                    }
                    subscription.unsubscribe();
                });
        } else {
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CLOSE
            });
        }
    }

    onContactClick(contact: Contact) {
        this.selectedContact = contact;
        this.isButtonShow = true;
    }

    /** click to init the contact list, selectedContact, and button section*/
    onInitSearchClick() {
        /** should get all the contacts so that all relevant contacts can be searched with keywords */
        this._contactService.getAllContactList().subscribe(
            res => {
                if (res) {
                    this.contactList = this._contactService.mapContactList(res);
                    if (this.contactList && this.contactList.contacts && this.contactList.contacts.length > 0) {
                        this.contactList.contacts = this.contactList.contacts.filter(item => !item.isLogin);
                    }
                }
            }
        );
        this.selectedContact = new Contact();
        this.isButtonShow = false;
    }

    onPrimaryClick() {
        const isValid = this._isUserDetailValid();
        if (isValid) {
            this.isProgressing = true;
            this.isCredentialShow = false;
            if (this.currentPage === 1) {
                /** Page 1: User Detail Page, using primary button to create a user */
                if (this._isUserDetail()) {
                    if (this._isHasManageTeamCredential()) {
                        const newUser = this.currUserData;
                        newUser.password = this._authService.encryptPassword(this.currUserData.password);
                        newUser.credentials = this.getCredentials();
                        this._store.dispatch(new CreateNewUser({
                            contactId: this.selectedContact.id,
                            user: newUser,
                            successMessage: `User ${this.currUserData.username} successfully created.`
                        }));
                    } else {
                        this._isAlertWindow();
                    }
                }
                this.onSlideSheetClose();
                this.isChanged = false;
            } else if (this.currentPage === 2) {
                /** Page 2: Edit User Page, using primary button to edit a user */
                if (this._isUserDetail()) {
                    /** The default password should not be sent when password has not changed */
                    const editUser = Object.assign(this.currUserData);
                    editUser.credentials = this.getCredentials();
                    if (!this.currentUser.credential.manageTeam) {
                        delete editUser.inTeams;
                    }
                    if (!this._changeCredential) {
                        delete editUser.credentials;
                    }
                    if (editUser.password === UserEditorConstants.User.Password) {
                        delete editUser.password;
                    } else {
                        if (!this.isChangePasswordFirstTime) {
                            /** The new password should be sent when it has changed */
                            editUser.password = this._authService.encryptPassword(this.currUserData.password);
                        }
                    }
                    this._store.dispatch(new EditUser({
                        contactId: this.selectedContact.id,
                        user: editUser,
                        successMessage: `User ${this.currUserData.username} successfully edited.`
                    }));
                    this.onSlideSheetClose();
                    this.isChanged = false;
                }
            }
        }
    }

    onSecondaryClick() {
        switch (this.currentPage) {
            /** Page 1: User Detail Page */
            case 1:
                this.currentPage = 0;
                this.currUserData.username = '';
                this.currUserData.password = '';
                this.currUserData.confirmedPWD = '';
                this.currUserData.primaryEmail = '';
                this.isCredentialShow = false;
                this.isCloneShow = false;
                this.isHasComplianceCredentialsInEditMode = false;
                this.createContactText = 'or ';
                this.clearCredentialConfig();
                this.clearErrors();
                break;
            /** Page 2: Edit User Page */
            case 2:
                this.onSlideSheetClose(true);
                break;
            default:
                this.onSlideSheetClose();
                break;
        }
    }

    onShowCredentialClick() {
        this.isCredentialShow = true;
    }

    onUserClick(user: User) {
        this.selectedUser = user;
        this.credentialInit(user.credentials);
        this.isCloneShow = false;
        this.keywordUser = '';
    }

    valueChange(tappedItem) {
        this.currUserData.inTeams = [];
        tappedItem.forEach(item => {
            this.currUserData.inTeams.push({ 'id': item.id, 'name': item.name });
        });
    }

    /** Initialize editor data when menu is opened */
    private _editorInit() {
        this._initTeamData();
        this.userCredentialConfig = { credentialConfigs: [] };
        this.userEditorConfig = UserEditorConstants;
        this._store.select<any>(credentialSelector)
            .pipe(take(1))
            .subscribe((res) => this._processCredentials(res));
    }

    /** change information will accur alert in edit user page but will not prompt in user detail page */
    private _handleComplianceCredential() {
        if (this.currentPage === 2) {
            const complianceCredential = this.userData.credentials.filter(item => item.name === UserEditorConstants.Credentials.ComplianceCredentials);
            if (complianceCredential.length > 0) {
                for (const complianceCredentials of this.userCredentialConfig.credentialConfigs) {
                    // complianceCredentials.hasCredential = false;
                    // only the compliance credentials is disabled.
                    if (complianceCredentials.name === UserEditorConstants.Credentials.ComplianceCredentialName) {
                        complianceCredentials.hasCredential = false;
                    }
                }
                this.isHasComplianceCredentialsInEditMode = true;
                this._changeCredential = true;
            }
        } else if (this.currentPage === 1) {
            this.isHasComplianceCredentialsInEditMode = false;
        }
    }

    private _isAlertWindow() {
        const subscription = this._alertWindow.error('You don’t have permission to make this change', ['Please enable "Manage Team Credentials" before make this change'], 'OK')
            .subscribe((result: boolean) => {
                if (result) {
                    return;
                }
                subscription.unsubscribe();
            });
    }

    // The function will return true in below two cases.
    // 1. no team is selected,
    // 2. current user has manage team credential and one team is selected at least.
    private _isHasManageTeamCredential() {
        if (this.currUserData.inTeams && this.currUserData.inTeams.length > 0) {
            const _credential = this.currentUser.credential;
            if (_credential.manageTeam) {
                return _credential.manageTeam;
            }
        } else {
            return true;
        }
    }

    private _isUserDetailValid(): boolean {
        let isValid = true;
        if (this.currentPage === 0) {
            this.currentPage = 1;
            this.currUserData.primaryEmail = this.selectedContact.primaryEmail;
            this.currUserData.inTeams = [];
        } else if (this.currentPage === 1 || this.currentPage === 2) {
            this.userName.setErrors(null);
            if (!this.userName.value ||
                this.userName.value.trim().length === 0) {
                this.userName.setErrors({
                    required: true
                });
                isValid = false;
            } else if (this.userName.value.length > 128) {
                this.userName.setErrors({
                    maxlength: true,
                });
                isValid = false;
            }
            this.password.setErrors(null);
            if (!this.password.value ||
                this.password.value.trim().length === 0) {
                this.password.setErrors({
                    required: true
                });
                isValid = false;
            }
            this.confirmedPWD.setErrors(null);
            if (!this.confirmedPWD.value ||
                this.password.value.trim().length === 0) {
                this.confirmedPWD.setErrors({
                    required: true
                });
                isValid = false;
            } else if (this.confirmedPWD.value !== this.password.value) {
                this.confirmedPWD.setErrors({
                    notMatch: true
                });
                isValid = false;
            }
            return isValid;
        }
    }

    private _isUserDetail() {
        const isNotInvalid = true;
        if (!this.nameDuplicated && !this.userName.invalid && !this.password.invalid && !this.confirmedPWD.invalid && !this.secureEmail.invalid) {
            return isNotInvalid;
        }
    }

    /**select team when create users */
    private _initTeamData() {
        if (this._isUserDetail()) {
            this._store.pipe(
                select(allTeamSelector),
                filter((list: any) => list.length > 0),
                take(1),
            ).subscribe(list => {
                this._onSubscribeFilterList(list);
            });
        }
        this.dropdownConfig.onChangeValue$.subscribe(data => {
            const teamsForServer = [];
            data.forEach(item => {
                teamsForServer.push({
                    id: item.id,
                    name: item.name
                });
            });
            this.currUserData.inTeams = teamsForServer;
        });
    }

    private _onSubscribeFilterList(item: Array<any>): void {
        const value = this.userData && this.userData.teams ? this.userData.teams.map(teams => teams.id).join(',') : '';
        if (item && item.length > 0) {
            ArrayHelperService.sort(item, 'name');
            this.dropdownConfig.data = item.map(data => ({
                id: data.id,
                checked: value.indexOf(data.id) !== -1 || value === ALL_VALUE,
                name: data.name
            }));
        }
    }

    /** Notify user of error of duplicate name when create users */
    private _parsePageState(state: AdminPageState) {
        if (state.successStatus === AdminPageStatusTypes.FAILURE) {
            if (state.failureMessage.search('ERR_DUPLICATED_NAME') !== -1) {
                this.nameDuplicated = true;
                this.isProgressing = false;
                return;
            }
        } else if (state.successStatus === AdminPageStatusTypes.SUCCESS) {
            this.isProgressing = false;
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CLOSE,
                payload: null
            });
        }
        this.userList = state.currentPageData['user-list'];
    }

    /** Format credential data*/
    private _processCredentials(res) {
        const credentials: Credential[] = [];
        res.forEach((item) => {
            const credential = new Credential();
            credential.id = item.id;
            credential.name = item.credentialName;
            credential.hasCredential = false;
            credentials.push(credential);
        });
        this.userCredentialConfig = { credentialConfigs: credentials };
        this._processCurrentUserData();
    }

    /** According create/edit mode .load different userData ,when currentPage =2 ,need load user details. */
    private _processCurrentUserData() {
        this.currUserData = new User();
        if (!this.userData) {
            /** Page 0: Search Contact Page */
            this.currentPage = 0;
        } else {
            /** Page 2: Edit User Page */
            this.currentPage = 2;
            this.isButtonShow = true;
            this.isCredentialShow = true;
            // for edit user
            this.currUserData = User.parse(this.userData);
            this.currUserData.password = UserEditorConstants.User.Password;
            this.currUserData.confirmedPWD = UserEditorConstants.User.Password;
            this.credentialInit(this.currUserData.credentials);
            // for select contact logic
            this.selectedContact = new Contact();
            this.selectedContact.id = this.currUserData.id;
        }
    }
}
