/**
 * Created by Todd Yu on 06/20/2020.
 * Description:
 * Action Component for Editing and Deleting within grid
 * ------ maintenance history ------
 * Updated by Teddy Ding on 12/15/20
 * Updated by Lucas Wang on 5/11/2021. Fix the problem of displaying and changing the data of notetype and entitytype
 * Updated by Lucas Wang on 3/01/2021. Refine the code to match coding standard
 */

import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { businessConstants } from '../../../constants/business.constants';
import { AdminPageFeedback, AdminPageFeedbackTypes } from '../admin-page-setting.model';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'admin-page-actions',
    templateUrl: './admin-page-actions.component.html',
    styleUrls: ['./admin-page-actions.component.scss']
})
export class AdminPageActionsComponent implements OnInit, ICellRendererAngularComp {
    editable: boolean;
    deletable: boolean;

    private _data: any;
    private _feedbackSubject$: Subject<AdminPageFeedback>;
    constructor() { }

    ngOnInit() { }

    /** Initialize this widget's data within the grid */
    agInit(params: any) {
        this._data = params.data;
        this._feedbackSubject$ = params.feedbackSubject$();
        this.editable = this._data['editable'] === undefined ? true : this._data['editable'];
        this.deletable = this._data['deletable'] === undefined ? true : this._data['deletable'];
        // admin doesn't need delete function
        if (this._data.id === businessConstants.adminUserId) {
            this.deletable = false;
        }
    }

    /** Refresh widget within the grid */
    refresh(params: any): boolean {
        return false;
    }

    /** Called when edit is clicked. Dispatches edit action to grid page and opens the editor menu */
    onEditClick() {
        this._feedbackSubject$.next({
            type: AdminPageFeedbackTypes.EDIT,
            payload: {
                data: this._data
            }
        });
    }

    /** Called when delete is clicked. Dispatches delete action to grid page and opens confirmation */
    onDeleteClick() {
        this._feedbackSubject$.next({
            type: AdminPageFeedbackTypes.DELETE,
            payload: {
                data: this._data
            }
        });
    }
}
