/**
 * Created by Daniel on 07/20/2021.
 * ------ maintenance history ------
 * 04/02/2022 Simon Zhao changed all functions into static methods.
 */
import anchorme from 'anchorme';

import { ProviderColumnType } from '../../tamalelibs/models/dataprovider.model';
import { DateHelperWebService } from '../../tamalelibs/services/date-helper.web.service';
import { NOT_FORMAT_LINK_PARA_NAME } from '../../tamalelibs/services/workflow.service';
import { CustomGridServiceTriggerType, GridColumnType } from './custom-grid-column-filter.model';

const BLANK_VALUE = '(Blanks)';

export class CustomGridColumnFilterService {

    static cellRender(col, params, type = CustomGridServiceTriggerType.Filter) {
        if (CustomGridColumnFilterService._isEntityPrivacyColumn(col)) {
            if (params.value === 'true') {
                return 'Public';
            }
            if (params.value === 'false') {
                return 'Private';
            }
            return params.value;
        }
        if (CustomGridColumnFilterService._isNumberType(col.dataType)) {
            let isShowCountCell = false; // group count | bottom grid count cell
            let decimalPlaces = col.settings.decimalPlaces;
            if (params.column && params.column.aggFunc === 'count') {
                // top grid group node | bottom grid common node - count not need format
                if (params.node.group || (params.colDef && params.colDef.fromGrid === 'bottom')) {
                    isShowCountCell = true;
                    decimalPlaces = 0;
                }
            }
            const factor: boolean = (params.value || params.value === 0) && !isNaN(Number(params.value)); // value can be '(Blanks)'
            if (col.dataType === 'ratio' && factor) {
                let str = params.value.toString();
                str = CustomGridColumnFilterService._wipeOffScientificNotation(str);
                str = CustomGridColumnFilterService._formatStr(str, decimalPlaces);
                if (isShowCountCell) {
                    return str;
                }
                return str + 'x';
            }
            if (col.dataType === 'percent' && factor) {
                if (!isShowCountCell) {
                    params.value = params.value * 100;
                }
                let str = params.value.toString();
                str = CustomGridColumnFilterService._wipeOffScientificNotation(str);
                str = CustomGridColumnFilterService._formatStr(str, decimalPlaces);
                if (isShowCountCell) {
                    return str;
                }
                return str + '%';
            }
            if (col.dataType === 'currency' && factor) {
                const isMinus: boolean = params.value < 0;
                let str: string = Math.abs(params.value).toString();
                str = CustomGridColumnFilterService._wipeOffScientificNotation(str);
                str = CustomGridColumnFilterService._formatStr(str, decimalPlaces);
                if (isShowCountCell) {
                    return str;
                }
                return isMinus ? '$(' + str + ')' : '$' + str;
            }
            if (col.dataType === 'number' && factor) {
                let str = params.value.toString();
                str = CustomGridColumnFilterService._wipeOffScientificNotation(str);
                str = CustomGridColumnFilterService._formatStr(str, decimalPlaces);
                if (isShowCountCell) {
                    return str;
                }
                return str;
            }
            return isNaN(Number(params.value)) ? '' : params.value;
        }
        if (CustomGridColumnFilterService._isDateType(col.dataType) && params.value && params.value !== BLANK_VALUE) {
            const dateType = CustomGridColumnFilterService._formatDateType(col.settings.display);
            return DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(params.value), dateType);
        }

        if (CustomGridColumnFilterService._isFileType(col)) {
            // the filter data is an array, need to get display date to show in UI
            if (type === CustomGridServiceTriggerType.Filter) {
                return params.value.map(item => item.displayName).join(', ');
            } else {
                // the group data has been converted by keyConverter
                return params.value;
            }
        }
        // formula example workday('2014/12/23',4), dataType-text
        if (params.value instanceof Date) {
            return params.value.toString();
        }
        if (params[NOT_FORMAT_LINK_PARA_NAME]) {
            return params.value;
        }
        params.value = CustomGridColumnFilterService._formatLabelStrToString(params.value);
        if (typeof params.value === 'string') {
            params.value = CustomGridColumnFilterService._formatLabelStrToLink(params.value);
        }
        return params.value;
    }

    static initGridHeader(gridColumnApi) {
        const infoColumns = gridColumnApi.getColumns();
        const allColumns = gridColumnApi.getColumnState();
        const tempColumns = [];
        const pinnedLeftColumns = [];
        const pinnedRightColumns = [];
        const noPinnedColumns = [];
        let columns = [];
        allColumns.forEach((column, i) => {
            if (!column.hide) {
                if (column.pinned === 'left') {
                    pinnedLeftColumns.push(column);
                } else if (column.pinned === 'right') {
                    pinnedRightColumns.push(column);
                } else {
                    noPinnedColumns.push(column);
                }
            }
        });
        columns = [].concat(pinnedLeftColumns, noPinnedColumns, pinnedRightColumns);
        columns.forEach((column) => {
            const index = infoColumns.findIndex(item => item.colId === column.colId);
            if (index !== -1) {
                tempColumns.push({
                    name: infoColumns[index].colDef.headerName,
                    width: column.width,
                    id: column.colId
                });
            }
        });
        return tempColumns;
    }

    private static _formatDateType(type) {
        const dateFormatType = {
            '0': 'MM/dd/yyyy hh:mm TT', // Date and Time
            '1': 'MM/dd/yyyy', // Date Only
            '2': 'MMM yyyy', // Month and Year
            '3': 'yyyy' // Year Only
        };
        return dateFormatType[type] || 'MM/dd/yyyy';
    }

    private static _formatLabelStrToLink(value) {
        const newValue = anchorme.default(value, {
            attributes: [
                (urlObj) => {
                    if (urlObj.protocol !== 'mailto:') {
                        return {
                            name: 'target',
                            value: '_blank'
                        };
                    }
                }
            ]
        });
        return newValue;
    }

    private static _formatLabelStrToString(value) {
        if (typeof value === 'string' && value) {
            value = value.replace(/(\<)/g, '&lt;').replace(/(\>)/g, '&gt;');
        }
        return value;
    }

    private static _formatStr(str: string, num = '2') {
        const count = parseInt(num, 10);
        const reg = /(\d)(?=(?:\d{3})+$)/g;
        const index = str.indexOf('.');
        if (index > -1) {
            let decimal = str.slice(index + 1);
            const decimalLen = decimal.length;
            if (decimalLen < count) {
                for (let i = 0; i < count - decimalLen; i += 1) {
                    decimal += '0';
                }
            } else {
                const tempDecimal = (+`0.${decimal}`).toFixed(count);
                const i = tempDecimal.indexOf('.');
                decimal = tempDecimal.slice(i + 1);
            }
            if (count === 0) {
                str = (+str).toFixed(0);
                str = str.replace(reg, '$1,');
            } else {
                str = str.slice(0, index).replace(reg, '$1,') + '.' + decimal;
            }
        } else {
            if (count === 0) {
                str = (+str).toFixed(0);
                str = str.replace(reg, '$1,');
            } else {
                let decimal = '';
                for (let i = 0; i < count; i += 1) {
                    decimal += '0';
                }
                str = str.replace(reg, '$1,') + '.' + decimal;
            }
        }
        return str;
    }

    private static _isDateType(dataType) {
        return dataType === ProviderColumnType.TIMESTAMP_WITH_TIME_ZONE ||
            dataType === ProviderColumnType.TIMESTAMP || dataType === ProviderColumnType.TIME ||
            dataType === ProviderColumnType.BYTEA || dataType === ProviderColumnType.DATE;
    }

    private static _isEntityPrivacyColumn(data) {
        return data.columnType === GridColumnType.ENTITY_COLUMN && data.field === 'entity__is_public';
    }

    private static _isFileType(data): boolean {
        return data.columnType === GridColumnType.ENTRY_COLUMN && data.field === 'entry__file';
    }

    private static _isNumberType(dataType) {
        return dataType === 'ratio' || dataType === 'percent' ||
            dataType === 'currency' || dataType === 'number';
    }

    private static _multiplyPowerX10ForString(value: string, power: number = 2, isMultiply: boolean = true): string {
        let result: string = value;
        if (power === 0) {
            return result;
        }
        if (power < 0) {
            isMultiply = !isMultiply;
            power = -power;
        }
        let prefix = '';
        if (value.startsWith('-')) {
            prefix = '-';
            value = value.substring(1);
        }
        const decimalIndex: number = value.indexOf('.');
        let integer: string;
        let decimal: string;
        if (isMultiply) {
            if (decimalIndex > -1) {
                integer = value.substring(0, decimalIndex);
                decimal = value.substring(decimalIndex + 1);
            } else {
                integer = value.toString();
                decimal = '';
            }
            for (let i = 0; i < power; i++) {
                decimal = decimal + '0';
            }
            integer = integer + decimal.substring(0, power);
            decimal = decimal.substring(power);
            let tempDecimal = '';
            for (let i = decimal.length - 1; i > 0; i--) {
                if (+decimal[i] === 0 && +decimal[i - 1] !== 0) {
                    tempDecimal = '.' + decimal.substring(0, i);
                    break;
                }
            }
            result = prefix + integer + tempDecimal;
        } else {
            if (decimalIndex > -1) {
                integer = value.substring(0, decimalIndex);
                decimal = value.substring(decimalIndex + 1);
            } else {
                integer = value.toString();
                decimal = '';
            }
            for (let i = 0; i < power; i++) {
                integer = '0' + integer;
            }
            decimal = integer.substring(integer.length - power) + decimal;
            integer = integer.substring(0, integer.length - power);
            let tempInteger = '0';
            for (let i = 0; i < integer.length - 1; i++) {
                if (+integer[i] === 0 && +integer[i + 1] !== 0) {
                    tempInteger = integer.substring(i + 1);
                    break;
                }
            }
            result = prefix + tempInteger + '.' + decimal;
        }
        return result;
    }

    private static _wipeOffScientificNotation(value: string): string {
        const snIndex: number = value.indexOf('e');
        if (snIndex > 0 && value.length > snIndex + 2) {
            const isPositiveSN: boolean = value[snIndex + 1] === '+';
            const power: number = +value.substring(snIndex + 2);
            let numberPart: string = value.substring(0, snIndex);
            if (isPositiveSN) {
                numberPart = CustomGridColumnFilterService._multiplyPowerX10ForString(numberPart, power);
            } else {
                numberPart = CustomGridColumnFilterService._multiplyPowerX10ForString(numberPart, power, false);
            }
            return numberPart;
        } else {
            return value;
        }
    }
}
