/**
 * Created by Daniel Wang on 1/16/2024
 * ------ maintenance history ------
 */

//#region common
export enum adminPageType {
    streetEvents = 'streetEvents',
    scheduledJobs = 'scheduledJobs',
    etlTask = 'etlTask',
    pythonScripts = 'pythonScripts',
    usageReport = 'usageReport',
    tokenManagement = 'tokenManagement',
    webSites = 'website',
    webSitesByFile = 'websiteByFile',
}
//#endregion

//#region street event config
export class StreetEventConfig {
    ID: string;
    username: string;
    password: string;
    server: string;
    filename: string;
    path: string;

    static parse(res): StreetEventConfig {
        if (!res) {
            return null;
        }
        const result = new StreetEventConfig();
        result.ID = res['ID'];
        result.username = res['username'];
        result.password = res['password'];
        result.server = res['server'];
        result.filename = res['filename'];
        result.path = res['path'];
        return result;
    }
}
//#endregion

//#region Scheduled Jobs
export enum Freq {
    '* * ?' = 'Daily',
    '? * 1-5' = 'Mon-Fri',
    '? * 1' = 'Monday only',
    '1 * ?' = 'First of Month',
    '1 * ? 2099' = 'Never'
}

export enum ETLTaskName {
    Central_Website_Task = 'Central Website Task',
    Central_Script_Retrieval_Task = 'Central Script Retrieval Task',
    Fountainhead_Task = 'Fountainhead Task',
    Notifications_Persistence_Task = 'Notifications Persistence Task',
    Portfolio_Upload_Task = 'Portfolio Upload Task',
    ClogUp_Task = 'ClogUp Task',
    Default_Usage_Report = 'Default Usage Report'
}

export class ScheduledJobs {
    id: string;
    name: string;
    taskName: ETLTaskName;
    scheduleTime: string;
    freq: Freq;
    isDefault = false;
    editable = true;
    deletable = true;

    static parse(res): ScheduledJobs {
        if (!res) {
            return null;
        }
        const result = new ScheduledJobs();
        result.id = res['ID'];
        result.name = res['name'];
        result.taskName = res['taskName'];
        result.isDefault = res['isDefault'];
        if (result.isDefault) {
            result.name = '*' + result.name;
            result.deletable = false;
            result.editable = false;
            result.scheduleTime = res['cronString'].substring(2) + ' (Interval in ms)';
            result.freq = null;
        } else {
            const data = res['cronString'].split(' ');
            result.scheduleTime = data[1].length > 1 ? data[1] : '0' + data[1] + ':' + parseInt(data[2], 0);
            Object.keys(Freq).forEach(item => {
                if (res['cronString'].indexOf('-') > 0) {
                    result.freq = Freq['? * 1-5'];
                } else {
                    if (res['cronString'].indexOf(item) > 0) {
                        result.freq = Freq[item];
                    }
                }
            });
        }
        return result;
    }
}
//#endregion

//#region ETL Tasks
export enum ETLOperationType {
    ETLNullOperation = 'com.tamalesoftware.basin.etlconfig.ETLNullOperation',
    CentralWebsiteListETL = 'com.tamalesoftware.basin.etl.central.CentralWebsiteListETL',
    MorganStanleyFTPRiXMLETL = 'com.tamalesoftware.basin.rixml.etl.MorganStanleyFTPRiXMLETL',
    IntralinksETLOperation = 'com.tamalesoftware.basin.etl.intralinks.IntralinksETLOperation',
    UsageReportETL = 'com.tamalesoftware.basin.etl.usagereport.UsageReportETL'
}

export class ETLTaskProperty {
    ID: string;
    propertyName: string;
    propertyValue: string;
    static parse(res): ETLTaskProperty {
        if (!res) {
            return null;
        }
        const result = new ETLTaskProperty();
        result.ID = res['ID'];
        result.propertyName = res['propertyName'];
        result.propertyValue = res['propertyValue'];
        return result;
    }
}

export class ETLTask {
    id: string;
    name: string;
    className: ETLOperationType;
    isDefault: boolean;
    tssETLTaskPropertyList: Array<ETLTaskProperty>;
    editable = true;
    deletable = true;

    static parse(res): ETLTask {
        if (!res) {
            return null;
        }
        const result = new ETLTask();
        result.id = res['ID'];
        result.name = res['taskName'];
        result.className = res['className'];
        result.isDefault = res['isDefault'];
        result.tssETLTaskPropertyList = [];
        res['tssETLTaskPropertyList']?.forEach(property => {
            result.tssETLTaskPropertyList.push(ETLTaskProperty.parse(property));
        });
        if (result.isDefault) {
            result.name = '*' + result.name;
            result.deletable = false;
            result.editable = false;
        }
        return result;
    }
}
//#endregion

//#region local python script
export class LocalPythonScript {
    id: string;
    name: string;
    script: string;
    isDefault: boolean;
    editable = true;
    deletable = true;

    static parse(res): LocalPythonScript {
        if (!res) {
            return;
        }
        const result = new LocalPythonScript();
        result.id = res['ID'];
        result.name = res['name'];
        result.script = res['script'];
        result.isDefault = res['isDefault'];
        if (result.isDefault) {
            result.name = '*' + result.name;
            result.deletable = false;
            result.editable = false;
        }

        return result;
    }
}
//#endregion

//#region TokenManagement
export class TokenManagement {
    id: string;
    username: string;
    type: string;
    device: string;
    created: Date;
    lastUpdated: Date;
    editable = false;
    deletable = true;

    static parse(res): TokenManagement {
        if (!res) {
            return;
        }
        const result = new TokenManagement();
        result.id = res['ID'];
        result.username = res['username'];
        result.type = res['type'];
        result.device = res['device'];
        result.created = new Date(res['created']);
        result.lastUpdated = new Date(res['lastUpdated']);

        return result;
    }
}
//#endregion

//#region store cache to file
export enum CacheType {
    Notification = 'notification',
    Preferences = 'preferences',
    Entries = 'entries',
    Contacts = 'contacts'
}

export class StoreCacheToFile {
    cacheType: CacheType;
    filePath: string;
    fileName: string;

    static parse(res): StoreCacheToFile {
        if (!res) {
            return;
        }
        const result = new StoreCacheToFile();
        result.cacheType = res['cacheType'];
        result.filePath = res['filePath'];
        result.fileName = res['fileName'];
        return result;
    }
}
//#endregion

//#region persistent cache purge
export enum PurgeTypeFeedback {
    Purge = 'purge'
}
export enum PurgeCacheType {
    files = 'Purge Files',
    metadata = 'Purge Metadata',
    all = 'Purge Both'
}
//#endregion

//#region server monitoring
export enum ServerMonitoringType {
    Properties = 'properties',
    Memory = 'memory',
    Threads = 'thread'
}
//#endregion

//#region web sites
export class WebSiteDialogAttributes {
    autoRefreshChecked = false;
    focusChangeChecked = true;
    blockPopupChecked = true;
    focusChangeEnabled = true;
    autoRefreshEnabled = true;
    blockPopupEnabled = true;

    static parse(res): WebSiteDialogAttributes {
        if (!res) {
            return;
        }
        const result = new WebSiteDialogAttributes();
        result.autoRefreshChecked = res['autoRefreshChecked'];
        result.focusChangeChecked = res['focusChangeChecked'];
        result.blockPopupChecked = res['blockPopupChecked'];
        result.focusChangeEnabled = res['focusChangeEnabled'];
        result.autoRefreshEnabled = res['autoRefreshEnabled'];
        result.blockPopupEnabled = res['blockPopupEnabled'];

        return result;
    }
}

export class WebSites {
    ID: string;
    name: string;
    URL: string;
    javascript: string;
    webSiteDialogAttributes: WebSiteDialogAttributes;
    symbolFormatList: string[];
    submitPDF: boolean;
    PDF_URL: string;
    params: string;
    tamaleAuthentication: boolean;
    type: string;
    siteType: {};
    editable = true;
    deletable = true;

    static parse(res): WebSites {
        if (!res) {
            return;
        }
        const result = new WebSites();
        result.ID = res['ID'];
        result.name = res['name'];
        result.URL = res['URL'];
        result.javascript = res['javascript'];
        result.webSiteDialogAttributes = WebSiteDialogAttributes.parse(res['webSiteDialogAttributes']);
        result.symbolFormatList = res['symbolFormatList'];
        result.submitPDF = res['submitPDF'];
        result.PDF_URL = res['PDF_URL'];
        result.params = res['params'];
        result.tamaleAuthentication = res['tamaleAuthentication'];
        result.siteType = res['siteType'];
        result.type = res['siteType']?.stringValue;
        result.deletable = res['editable'];
        result.editable = res['editable'];

        return result;
    }
}
//#endregion

//#region admin general
export enum GeneralGridType {
    ConfigureHTMLSecurity = 'configureHTMLSecurity',
    Abbreviation = 'abbreviation',
    FMROverrides = 'FMROverrides',
    StopList = 'stopList'
}

export enum ConvertAddressType {
    Contacts = 'contacts',
    Corporates = 'corporates'
}

export class ConfigureHTMLSecurity {
    tag: string;

    static parse(res): ConfigureHTMLSecurity {
        if (!res) {
            return;
        }
        const result = new ConfigureHTMLSecurity();
        result.tag = res['tag'];

        return result;
    }
}

export class Abbreviation {
    ID: string;
    canonicalForm: string;
    abbreviation: string;

    static parse(res): Abbreviation {
        if (!res) {
            return;
        }
        const result = new Abbreviation();
        result.ID = res['ID'];
        result.canonicalForm = res['canonicalForm'];
        result.abbreviation = res['abbreviation'];

        return result;
    }
}

export class FMROverrides {
    ID: string;
    name: string;
    FMR: boolean;
    editable = false;

    static parse(res): FMROverrides {
        if (!res) {
            return;
        }
        const result = new FMROverrides();
        result.ID = res['ID'];
        result.name = res['name'];
        result.FMR = res['FMR'];

        return result;
    }
}

export class StopList {
    ID: string;
    ticker: string;
    editable = false;

    static parse(res): StopList {
        if (!res) {
            return;
        }
        const result = new StopList();
        result.ID = res['ID'];
        result.ticker = res['ticker'];

        return result;
    }
}
//#endregion
