import { businessConstants } from '../constants/business.constants';

export class EntityType {
    id: string;
    name: string;

    constructor(id?: string, name?: string) {
        this.id = id;
        this.name = name;
    }

    // tslint:disable-next-line: member-ordering
    static readonly CONTACT: EntityType = {
        id: businessConstants.EntityTypes.Contact_Id,
        name: 'Contact'
    };

    // tslint:disable-next-line: member-ordering
    static readonly CORPORATE: EntityType = {
        id: businessConstants.EntityTypes.Corporate_Id,
        name: 'Corporate'
    };

    // tslint:disable-next-line: member-ordering
    static readonly EVENT: EntityType = {
        id: businessConstants.EntityTypes.Event_Id,
        name: 'Event'
    };

    // tslint:disable-next-line: member-ordering
    static readonly INDEX: EntityType = {
        id: businessConstants.EntityTypes.Index_Id,
        name: 'Index'
    };

    // tslint:disable-next-line: member-ordering
    static readonly INDUSTRY: EntityType = {
        id: businessConstants.EntityTypes.Industry_Id,
        name: 'Industry'
    };

    // tslint:disable-next-line: member-ordering
    static readonly TEAM: EntityType = {
        id: businessConstants.EntityTypes.Team_Id,
        name: 'Team'
    };

    // tslint:disable-next-line: member-ordering
    static readonly TOPIC: EntityType = {
        id: businessConstants.EntityTypes.Topic_Id,
        name: 'Topic'
    };

    // tslint:disable-next-line: member-ordering
    static readonly USER: EntityType = {
        id: businessConstants.EntityTypes.User_Id,
        name: 'User'
    };

    // tslint:disable-next-line: member-ordering
    static readonly ALL: EntityType = {
        id: businessConstants.EntityTypes.All,
        name: 'all'
    };

    // tslint:disable-next-line: member-ordering
    static readonly CONTACTSFORVALIDRELTYPE: EntityType = {
        id: businessConstants.EntityTypes.Contact_To_Contact_Id,
        name: 'ContactForValidRelType'
    };

    static parse(serverResponse): EntityType {
        if (serverResponse == null) {
            return new EntityType('', '');
        }
        return {
            id: serverResponse.link.href ? serverResponse.link.href.slice(-33, -1) : '',
            name: serverResponse.link.phid
        };
    }

    static parseType(serverResponse): EntityType {
        if (!serverResponse) {
            return new EntityType('', '');
        }
        return {
            id: serverResponse.id,
            name: serverResponse.name,
        };
    }
}
