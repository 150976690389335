import { Action } from '@ngrx/store';

export enum GlobalConfigActionTypes {
    SSO_SET = '[SSO] SET',
    SYSTEM_CONFIG_SET = '[PIWIK] SET',
}

export class SsoSet implements Action {
    readonly type = GlobalConfigActionTypes.SSO_SET;
    constructor(public payload: any) { }
}

export class SystemConfigSet implements Action {
    readonly type = GlobalConfigActionTypes.SYSTEM_CONFIG_SET;
    constructor(public payload: any) { }
}

export type GlobalConfigActions = SsoSet | SystemConfigSet;
