<div class="maptile-nearby" (mouseenter)="boxHoverOn()" (mouseleave)="boxMouseOut()">
    <smart-icon class="map-expand" name="map-expand" size="32" (click)="changeBoxStatus()" *ngIf="!isBoxShow">
    </smart-icon>
    <smart-icon class="map-collapse" name="map-collapse" size="32" (click)="changeBoxStatus()" *ngIf="isBoxShow && isBoxHoverOn">
    </smart-icon>
    <div class="map-border maptile-nearby-all" [ngClass]="{'isBoxShow': isBoxShow}" [style.visibility]="isOuterBoxShow? 'visible': 'hidden'">
        <div class="map-border maptile-nearby-leave" [ngClass]="{'isBoxShow': isBoxShow}">
            <span>{{'general.search_nearby'|stringLiterals}} {{ searchName}}</span>
            <div class="split" *ngIf="isIconShow && !isFromShowEventOnMapTile"></div>
            <smart-icon class="nearby-close" name="nearby-close" size="20" (click)="closeShowNearby()" *ngIf="isIconShow && !isFromShowEventOnMapTile"></smart-icon>
        </div>
        <div class="map-border maptile-body" [ngClass]="{'isBoxShow': isBoxShow}">
            <div class="maptile-nearby-search">
                <span class="body-1">{{'general.search_range_and_criteria'|stringLiterals}}</span>
                <kendo-dropdownlist #dropDownEl [data]="radiusData" [(ngModel)]="radiusSelect" style="width: 100%"
                    [textField]="'name'" [valueField]="'value'">
                </kendo-dropdownlist>
                <div class="div-entity-dropdown">
                    <multi-sel-dropdown-filter [dropdownConfig]='entityTypeConfig'>
                        <ng-template #multiCustomContent>
                            <div class="selected-entityType text-ellipsis" (click)='onClickEntity($event)'>
                                {{entityName}}
                            </div>
                        </ng-template>
                    </multi-sel-dropdown-filter>
                </div>
                <div class="tam-textbox">
                    <span class="control-label body-1">{{'general.with'|stringLiterals}}&nbsp;</span>
                    <multi-sel-dropdown-filter class="control-content" [dropdownConfig]='dropdownConfig'>
                        <ng-template #multiCustomContent>
                            <div class="selected-content text-ellipsis" (click)='onClickSource($event)'>
                                {{sourceName.toLowerCase()}}
                            </div>
                        </ng-template>
                    </multi-sel-dropdown-filter>
                    <span class="control-label body-1">&nbsp;{{'general.from_google_service'|stringLiterals}}&nbsp;</span>
                </div>
            </div>
            <!--filter part-->
            <div class="map-filter">
                <div class="filter-title event-filter" *ngIf="isShowEventFilter&&filterEventConfig&&filterEventConfig.filtersConfigList">
                    <span>{{'general.event_filters'|stringLiterals}}
                        <label>{{'general.apply_to_tamale_data_only'|stringLiterals}}</label> </span>

                    <div class="filter-area">
                        <filter-panel [config]="filterEventConfig"></filter-panel>
                        <div class="control">
                            <tam-field-selector *ngIf="fieldEventConfig" [config]="fieldEventConfig">
                                <ng-template #customBtn>
                                    <span class="add-filter-btn hover-link">{{'general.add_event_filter'|stringLiterals}}</span>
                                </ng-template>
                            </tam-field-selector>
                        </div>
                    </div>
                </div>

                <div class="filter-title" *ngIf="isShowContactFilter&&filterConfig&&filterConfig.filtersConfigList">
                    <span>{{'general.contact_filters'|stringLiterals}}
                        <label>{{'general.apply_to_tamale_data_only'|stringLiterals}}</label>
                    </span>

                    <div class="filter-area">
                        <filter-panel [config]="filterConfig"></filter-panel>
                        <div class="control">
                            <tam-field-selector [config]="fieldConfig">
                                <ng-template #customBtn>
                                    <span class="add-filter-btn hover-link">{{'general.add_contact_filter'|stringLiterals}}</span>
                                </ng-template>
                            </tam-field-selector>
                        </div>
                    </div>

                </div>
                <div class="filter-title entity-filter" *ngIf="isShowEntityFilter&&filterEntityConfig&&filterEntityConfig.filtersConfigList">
                    <span>{{'general.entity_filters'|stringLiterals}}
                        <label>{{'general.apply_to_tamale_data_only'|stringLiterals}}</label> </span>

                    <div class="filter-area">
                        <filter-panel [config]="filterEntityConfig"></filter-panel>
                        <div class="control">
                            <tam-field-selector *ngIf="fieldEntityConfig" [config]="fieldEntityConfig">
                                <ng-template #customBtn>
                                    <span class="add-filter-btn hover-link">{{'general.add_entity_filter'|stringLiterals}}</span>
                                </ng-template>
                            </tam-field-selector>
                        </div>
                    </div>
                </div>
            </div>
            <div class="div-apply">
                <button tam-button tamType="primary" class="apply-setting" (click)="onClickApply()" *ngIf="isIconShow">{{'general.apply'|stringLiterals}}</button>
            </div>
        </div>
    </div>
</div>