import { TemplateControlValue } from './template-control-value.model';
export class IntralinksAccountInfo {
    public folderMappings: IntralinksMapping;
    public endOtherSessions = false;
    constructor(
        public username: string,
        public password: string,
        public alias: string,
        public recipients: string[],
        public lastModifiedTime?: number,
        public lastModifiedUser?: string

    ) { }
    map2ServerModel() {
        const result = {};
        result['intralinksAccountName'] = this.username;
        result['intralinksAccountNickname'] = this.alias;
        result['intralinksAccountPassword'] = this.password;
        result['intralinksAccountEndOtherSessions'] = this.endOtherSessions;
        let mapping: IntralinksMapping = null;
        if (this.folderMappings) {
            mapping = this.folderMappings.ignoreParent();
        }
        result['intralinksFolderMappings'] = mapping ? JSON.stringify(mapping) : '';
        return result;
    }
    mapFromServer(serverResponse) {
        this.username = serverResponse['intralinksAccountName'];
        this.alias = serverResponse['intralinksAccountNickname'];
        this.password = serverResponse['intralinksAccountPassword'];
        this.endOtherSessions = serverResponse['intralinksAccountEndOtherSessions'];
        // tslint:disable-next-line: no-use-before-declare
        this.folderMappings = serverResponse['intralinksFolderMappings'] ? new IntralinksMapping(JSON.parse(serverResponse['intralinksFolderMappings'])) : null;
        return;
    }
}

export class IntralinksFolder {
    id: string;
    name: string;
    parentId: string;
    createdDate: Date;
    createdBy: string;
    lastModifiedDate: Date;
    lastModifiedBy: string;
    mapFromServer(response) {
        this.id = response['id'].toString();
        this.name = response['name'];
        this.parentId = response['parent-id'].toString();
        this.createdDate = response['created-on'] ? new Date(response['created-on']['milliseconds']) : null;
        this.createdBy = response['created-by'] ? response['created-by']['first-name'] + ' ' + response['created-by']['last-name'] : null;
        this.lastModifiedDate = response['last-modified-on'] ? new Date(response['last-modified-on']['milliseconds']) : null;
        this.lastModifiedBy = response['last-modified-by'] ? response['last-modified-by']['first-name'] + ' '
            + response['last-modified-by']['last-name'] : null;
    }
}

export class IntralinksFile {
    id: string;
    name: string;
    parentId: string;
    fileSize: number;
    pageCount: number;
    workspaceId: string;
    pdfProtection: string;
    documentHash: string;
    entityType: string;
    fileHash: string;
    extension: string;
    version: string;
    mimeType: string;
    noteId: string;
    noteTitle: string;
    isSubmitted: boolean;
    createdDate: Date;
    createdBy: string;
    lastModifiedDate: Date;
    lastModifiedBy: string;
    submittedBy: string;
    submittedDate: Date;
    screenId?: string; // this parameter for submit document to entry
    mapping?: IntralinksMapping;
    mapFromServer(response) {
        this.id = response['id'].toString();
        this.name = response['name'];
        this.parentId = response['parent-id'].toString();
        this.fileSize = response['file-size'];
        this.pageCount = response['page-count'];
        this.workspaceId = response['workspace-id'].toString();
        this.pdfProtection = response['pdf-protection'];
        this.documentHash = response['document-hash'];
        this.entityType = response['entity-type'];
        this.fileHash = response['file-hash'];
        this.extension = response['extension'];
        this.version = response['version'];
        this.mimeType = response['mime-type'];
        this.noteId = response['entry'] ? response['entry']['parent-entry-id'] : null;
        this.noteTitle = response['entry'] ? response['entry']['calculated-title'] : null;
        this.isSubmitted = response['submitted'];
        this.createdDate = response['created-on'] ? new Date(parseInt(response['created-on']['milliseconds'], 0)) : null;
        this.createdBy = response['created-by'] ? response['created-by']['first-name'] + ' ' + response['created-by']['last-name'] : null;
        this.lastModifiedDate = response['last-modified-on'] ? new Date(parseInt(response['last-modified-on']['milliseconds'], 0)) : null;
        this.lastModifiedBy = response['last-modified-by'] ? response['last-modified-by']['first-name'] + ' '
            + response['last-modified-by']['last-name'] : null;
        this.submittedDate = response['submitted-on'] ? new Date(parseInt(response['submitted-on']['milliseconds'], 0)) : null;
        this.submittedBy = response['submitted-by'] ? response['submitted-by']['first-name'] + ' '
            + response['submitted-by']['last-name'] : null;
    }
}

export class IntralinksValidationObject {
    constructor(
        public screenId?: string,
        public accountEmail?: string,
        public mobileNumber?: string,
        public oneTimePassword?: string,
        public challengeQuestionAnswer?: string,
        public acceptSplash?: boolean
    ) { }

    map2ServerModel(): any {
        return {
            'screen-id': this.screenId,
            'intralinks-account-email': this.accountEmail,
            'intralinks-account-mobile-number': this.mobileNumber,
            'intralinks-account-one-time-password': this.oneTimePassword,
            'challenge-question-answer': this.challengeQuestionAnswer,
            'accept-splash': this.acceptSplash
        };
    }

    parseServerModel(model: any): void {
        this.screenId = model['screen-id'];
        this.accountEmail = model['intralinks_account_email'];
        this.mobileNumber = model['intralinks-account-mobile-number'];
        this.oneTimePassword = model['intralinks-account-one-time-password'];
        this.challengeQuestionAnswer = model['challenge-question-answer'];
        this.acceptSplash = model['accept-splash'];
    }

}

export enum IntralinksValidationResult {
    ALLOW = 'ALLOW',
    SPLASH_REQUIRED = 'ACCEPT_SPLASH',
    MFA_REQUIRED = 'OTP_DELIVERY_OPTION',
    ONE_TIME_PASSWORD = 'ONE_TIME_PASSWORD',
    SPLASH_IMAGE = 'SPLASH_IMAGE'
}

export class IntralinksMapping {
    folderId: string;
    inherit = false;
    isWorkspace = false;
    parent?: IntralinksMapping;
    children: IntralinksMapping[] = [];
    fields: TemplateControlValue[] = [];
    appliedFieldIds: string[] = []; // abandoned
    noteType: any = {};
    entities: any = [];
    requiredFields: string[] = [];

    constructor(data?: Object) {
        if (data) {
            this.mapFromScreenConfiguration(data);
        }
    }

    mapFromScreenConfiguration(data, parent?) {
        if (data) {
            this.folderId = data['folderId'];
            this.inherit = data['inherit'];
            this.isWorkspace = data['isWorkspace'];
            this.parent = parent;
            this.children = [];
            this.fields = data['fields'];
            this.appliedFieldIds = data['appliedFieldIds'];
            this.noteType = data['noteType'];
            this.entities = data['entities'];
            this.requiredFields = data['requiredFields'];

            for (let i = 0; data['children'] && i < data['children'].length; i++) {
                const mapping = new IntralinksMapping();
                if (mapping.mapFromScreenConfiguration(data['children'][i], this)) {
                    this.children.push(mapping);
                }
            }
            return true;
        } else {
            return false;
        }
    }

    ignoreParent(ignoreChild = false): IntralinksMapping {
        const result = new IntralinksMapping();
        result.folderId = this.folderId;
        result.inherit = this.inherit;
        result.isWorkspace = this.isWorkspace;
        result.fields = this.fields;
        result.children = [];
        result.appliedFieldIds = this.appliedFieldIds;
        result.noteType = this.noteType;
        result.entities = this.entities;
        result.requiredFields = this.requiredFields;

        for (let i = 0; !ignoreChild && this.children && i < this.children.length; i++) {
            if (this.children[i]) {
                const mappedChild = this.children[i].ignoreParent();
                result.children.push(mappedChild);
            }
        }
        return result;
    }

    findByFolderId(folderId): IntralinksMapping {
        if (this.folderId === folderId) {
            return this;
        } else {
            for (let i = 0; i < this.children.length; i++) {
                const result = this.children[i].findByFolderId(folderId);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }
}
