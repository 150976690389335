import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { map, switchMap, catchError, concatMap, tap } from 'rxjs/operators';

import { AuthActionTypes } from '../actions/auth.actions';
import { AuthService } from '../../tamalelibs/services/auth.service';
import { AppState } from '..';
import { SystemActionTypes } from '../../tamalelibs/redux/actions/system.actions';
import { UserMessages } from '../../constants/userMessages.constants';
import { SystemUser } from '../../tamalelibs/models/user.model';
import { StorageService } from '../../services/storage.service';

import { AppConfig } from '../../tamalelibs/models/app-config.model';
import { ContactService } from '../../tamalelibs/services/contact.service';
import { EntityBrief } from '../../tamalelibs/models/entity-brief.model';
import { AddInUtilities } from '../../services/utilities/addin-utilities';

@Injectable()
export class AuthEffects {
    constructor(
        private _actions$: Actions,
        private _authService: AuthService,
        private _contactService: ContactService,
        private _store: Store<AppState>,
        private _storageService: StorageService
    ) { }

    authEffect$: Observable<Action> = createEffect(() => this._actions$.pipe(
        ofType(AuthActionTypes.LOGIN),
        map((action: any) => action.payload),
        concatMap((payload: any) => {
            return this._authService.login(payload.username, payload.password, payload.isForPlugin);
        }),
        map(response => {
            const user = SystemUser.parse(response);

            AppConfig.token = user.token;
            AppConfig.username = user.loginName;
            AppConfig.userId = user.id;
            if (AddInUtilities.isOfficeEnvironment()) {
                localStorage.setItem('user', JSON.stringify(user));
            } else {
                this._storageService.setItem('user', user);
            }

            return user;
        }),
        concatMap(user => {
            // after validate token; should retrive current user's detail information;
            return this._contactService.getContactDetail(user.id).pipe(
                map(response => {
                    this._store.dispatch({
                        type: SystemActionTypes.INIT_CURRENT_CONTACT_SUCCESS,
                        payload: EntityBrief.parse(response)
                    });
                    return ({
                        type: AuthActionTypes.LOGIN_SUCCESS,
                        payload: user
                    });
                })
            );
        }),
        catchError((err, caught) => {
            let errorMsg = '';
            if (err.message && err.message.indexOf('401') > -1 && err.message.indexOf('Unauthorized') > -1) {
                errorMsg = UserMessages.onLoginInvalid;
            } else {
                errorMsg = UserMessages.onAPIDown;
            }
            this._store.dispatch({ type: AuthActionTypes.LOGIN_FAILED, payload: errorMsg });
            return caught;
        })
    ));
}
