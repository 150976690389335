<div class="editor-container" (click)="onClickUserEditor($event)">
    <div class="header-container">
        <label>{{ userEditorConfig.pageConfigs[currentPage].title }}</label>
        <smart-icon name="close" (click)="onSlideSheetClose(true)" class="light"></smart-icon>
    </div>

    <div class="search-contact-container" *ngIf="currentPage===0">
        <div class="input-container">
            <div class="search-box">
                <aacl-keyword-search [options]="keywordSearchOptions" (click)="onInitSearchClick()">
                </aacl-keyword-search>
            </div>
            <div class="tam-form-link">
                {{createContactText}}
                <span class="link-text" (click)="createContact()">create contact</span>
            </div>
        </div>
        <div #itemContainer class="item-container">
            <div class="item" *ngFor="let contact of (contactList | contactListFilter: keyword)"
                (click)="onContactClick(contact)" [ngClass]="{'active':selectedContact===contact}">
                <div class="item-profile">
                    <img src="./assets/images/noportrait.png" class="img-circle" alt="profile">
                </div>
                <div tid="contact-list-info" class="item-info" kendoTooltip position="bottom"
                    tooltipClass="tooltip-adjust-right">
                    <div class="info-name" title="{{contact.name}}">{{ contact.name}}</div>
                    <div class="info-job" title="{{ getJobTitle(contact)}}">{{ getJobTitle(contact) }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="user-detail-container" *ngIf="currentPage===1 || currentPage ===2">
        <div class="user-container">
            <div class="user">
                <div class="user-profile">
                    <img src="./assets/images/noportrait.png" class="img-circle" alt="profile">
                </div>
                <div class="user-info" kendoTooltip position="bottom" tooltipClass="tooltip-adjust-right">
                    <div class="user-name" title="{{selectedContact?.name }}">{{ selectedContact?.name }}</div>
                    <div class="user-job" title="{{selectedContact ? getJobTitle(selectedContact) : '' }}">{{
                        selectedContact ? getJobTitle(selectedContact) : '' }}</div>
                </div>
            </div>
            <div class="input-container">
                <div class="tam-form-field" [ngClass]="{'has-error': nameDuplicated || userName.invalid}">
                    <label class="required">Username</label>
                    <input tid="username" autocomplete="off" [(ngModel)]="currUserData.username"
                        class="tam-input tam-textbox" type="text" [formControl]="userName"
                        (input)="clearUserNameError()" (click)="configChanged()">
                    <div class="tam-form-help" *ngIf="nameDuplicated">
                        User{{'general.username_already_exist' | stringLiterals}}
                    </div>
                    <div class="tam-form-help" *ngIf="userName.invalid && userName.errors['required']">
                        Username can not be blank or just spaces.
                    </div>
                    <div class="tam-form-help" *ngIf="userName.invalid && userName.errors['maxlength']">
                        {{'general.user_name_exceed_maximum' | stringLiterals: '128'}}
                    </div>
                </div>
                <div class="tam-form-field" [ngClass]="{'has-error': password.invalid}">
                    <label class="required">Password</label>
                    <input tid="password" [(ngModel)]="currUserData.password" class="tam-input tam-textbox"
                        type="password" autocomplete="off" [formControl]="password" (input)="handlePasswordChange()"
                        (click)="configChanged()">
                    <div class="tam-form-help" *ngIf="password.invalid && password.errors['required']">
                        {{'general.required_error_message' | stringLiterals}}
                    </div>
                </div>
                <div class="tam-form-field" [ngClass]="{'has-error': confirmedPWD.invalid}">
                    <label class="required">Confirm password</label>
                    <input tid="confirm_pwd" [(ngModel)]="currUserData.confirmedPWD" class="tam-input tam-textbox"
                        type="password" autocomplete="off" [formControl]="confirmedPWD" (click)="configChanged()">
                    <div class="tam-form-help" *ngIf="confirmedPWD.invalid && confirmedPWD.errors['required']">
                        {{'general.required_error_message' | stringLiterals}}
                    </div>
                    <div class="tam-form-help" *ngIf="confirmedPWD.invalid && confirmedPWD.errors['notMatch']">
                        {{'general.password_do_not_match_message' | stringLiterals}}
                    </div>
                </div>
                <div class="tam-form-field" [ngClass]="{'has-error': secureEmail.invalid}">
                    <label>Secure Email address</label>
                    <input tid="secure_email_addr" [(ngModel)]="currUserData.primaryEmail" class="tam-input tam-textbox"
                        type="text" [formControl]="secureEmail" (input)="handleEmailChange()" (click)="configChanged()">
                </div>

                <div class="team-section-container">
                    <label class="team-section-label">Teams</label>
                    <input type="text" readonly [value]="teamsName" (click)="_isAlertWindow()" (click)="configChanged()"
                        *ngIf="dropdownConfig.hideInput" [disabled]='true' class="textInput" [placeholder]=" ''">
                    <multi-sel-dropdown-filter [dropdownConfig]="dropdownConfig">
                    </multi-sel-dropdown-filter>
                </div>
                <div class="credential-section-container">
                    <div class="tam-form-alert" *ngIf="isHasComplianceCredentialsInEditMode">
                        {{'general.user_lose_credential_message' | stringLiterals}}
                    </div>
                    <div class="tam-form-link" [hidden]="isEditAdmin">
                        You can set credentials mannually or <span #cloneAnchor class="link-text"
                            (click)="clickCloneCredentials()" (click)="configChanged()">clone an existing user's
                            credentials</span>
                    </div>
                    <div class="existing-user-credentials" role='searchBox'>
                        <kendo-popup [anchor]="cloneAnchor" class="clone-popup" *ngIf="isCloneShow">
                            <div class="search-box-user">
                                <aacl-keyword-search [options]="keywordSearchOptionsUser">
                                </aacl-keyword-search>
                            </div>
                            <div class="item-container">
                                <div class="item" *ngFor="let user of (userList | userListFilter: keywordUser)"
                                    (click)="onUserClick(user)" (click)="configChanged()"
                                    [ngClass]="{'active':selectedUser===user}">
                                    {{ user.username }}
                                </div>
                            </div>
                        </kendo-popup>
                    </div>
                </div>
                <div class="credential-container">
                    <div class="credential" *ngFor="let credential of userCredentialConfig.credentialConfigs">
                        <div>
                            <span [ngClass]="{'active': credential.hasCredential}">{{ credential.name }}</span>
                            <kendo-switch [checked]="credential.hasCredential" onLabel="Yes" offLabel="No"
                                (valueChange)="changeCredentialStatus($event, credential)" (click)="configChanged()"
                                *ngIf="!isEditAdmin || credential.id !== adminCredentialId">
                            </kendo-switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="button-container" *ngIf="isButtonShow">
        <button tam-button *ngIf="userEditorConfig.pageConfigs[currentPage].primaryText" [tamType]="'primary'"
            [tamSize]="'normal'" [tamLoading]="isProgressing" [disabled]="isProgressing" class="button"
            (click)="onPrimaryClick()">
            {{ userEditorConfig.pageConfigs[currentPage].primaryText }}
        </button>
        <button tam-button *ngIf="userEditorConfig.pageConfigs[currentPage].secondaryText" [tamType]="'tertiary'"
            [tamSize]="'normal'" class="button" (click)="onSecondaryClick()">
            {{ userEditorConfig.pageConfigs[currentPage].secondaryText }}
        </button>
    </div>
</div>