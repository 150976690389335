/**
 * Created by Simon Zhao on 9/30/2021.
 * Description:
 * provide a shortcut to reference a string literal.
 * ------ maintenance history ------
 */
import { Pipe } from '@angular/core';

const localeStrings = require('../locale/en_US.json');
// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({ name: 'stringLiterals' })
export class StringLiteralsPipe {
    static translate(stringPath: string, ...args: string[]) {
        try {
            return applyParameters(getPropViaPath(localeStrings, stringPath), ...args);
        } catch (ex) {
            throw new Error('Please check whether the specified key exists in the locale file.');
        }
    }

    transform(stringPath: string, ...args: string[]) {
        try {
            return applyParameters(getPropViaPath(localeStrings, stringPath), ...args);
        } catch (ex) {
            throw new Error('Please check whether the specified key exists in the locale file.');
        }
    }
}

function applyParameters(prop: string, ...args: string[]) {
    if (args.length > 0 && !Array.isArray(args)) {
        // only one valid argument was passed in.
        args = [args];
    }

    for (let i = 0; i < args.length; i++) {
        prop = prop.replace(`{${i}}`, args[i]);
    }

    return prop;
}

function getPropViaPath(targetObj: any, path: string) {
    const pathNodes = path.split('.');
    let currentObj = targetObj;
    for (let i = 0; i < pathNodes.length; i++) {
        if (currentObj[pathNodes[i]]) {
            currentObj = currentObj[pathNodes[i]];
        } else {
            return '';
        }
    }

    return currentObj;
}
