/**
 * Created by Simon, Zhao on 10/9/2021
 * Description:
 * FieldLabel Component
 * ------ maintenance history ------
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilsService } from '../../tamalelibs/services/utils.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Input, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
    selector: 'field-label',
    templateUrl: './field-label.component.html',
    styleUrls: ['./field-label.component.scss']
})
export class FieldLabelComponent implements OnInit {

    constructor(
        private _utils: UtilsService,
        private _deviceService: DeviceDetectorService) { }

    @ViewChild('anchor', { static: false }) anchor;

    @Input()
    isRequired = false;

    isIPAD = false;

    @Input()
    label: string;

    @Input()
    labelClassList: string;

    @ViewChild(TooltipDirective, { static: false })
    tooltipDir: TooltipDirective;

    @Input()
    tooltip: string;

    ngOnInit(): void {
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
    }

    showTooltip(event, target, show: boolean): void {
        if (show === null) {
            this.tooltipDir.toggle(target);
        } else {
            this.tooltipDir.toggle(target, show);
        }
        this._utils.emptyClick(event);
    }

}
