/**
 * Created by Ella Ma on 10/16/2020. custom date column filter
 * ------ maintenance history ------
 * Update by Daniel on 07/20/2021. convert the component to widget
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DateHelperWebService } from '../../tamalelibs/services/date-helper.web.service';
import { CustomDateColumnFilterConfig, CustomDateColumnFilterConfigActionType, CustomDateColumnFilterConfigEventType } from './custom-grid-column-filter.model';

@Component({
    selector: 'tam-custom-date-column-filter',
    templateUrl: './custom-date-column-filter.component.html',
    styleUrls: ['./custom-grid-column-filter.component.scss']
})

export class TamCustomDateColumnFilterComponent implements OnInit, OnDestroy {

    @Input() config: CustomDateColumnFilterConfig;
    data = [];

    private _destroySubscriptions: Array<Subscription> = [];

    constructor() { }

    ngOnInit() {
        this.data = this._formatItems();
        this._initSubscriptions();
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    expandGroup(yearIndex, monthIndex?) {
        if (monthIndex || monthIndex === 0) {
            this.data[yearIndex].months[monthIndex].expand = !this.data[yearIndex].months[monthIndex].expand;
            return;
        }
        this.data[yearIndex].expand = !this.data[yearIndex].expand;
    }

    onChecked(check, yearIndex, monthIndex?, dayIndex?) {
        if (dayIndex || dayIndex === 0) {
            const day = this.data[yearIndex].months[monthIndex].dates[dayIndex];
            day.check = check;
            this._formatDataCheckedStatus(this.data);
            this._triggerFilter([{
                value: day.default_format_value,
                default_value: day.default_value,
                check: check === 'all' ? true : false
            }], 'date');
            return;
        }

        if (monthIndex || monthIndex === 0) {
            const month = this.data[yearIndex].months[monthIndex];
            month.check = check;
            const filterItems = [];
            if (month.dates && month.dates.length > 0) {
                month.dates.forEach(element => {
                    element.check = check;
                    filterItems.push({
                        value: element.default_format_value,
                        default_value: element.default_value,
                        check: check === 'all' ? true : false
                    });
                });
            } else {
                filterItems.push({
                    value: month.default_format_value,
                    default_value: month.default_value,
                    check: check === 'all' ? true : false
                });
            }
            this._triggerFilter(filterItems, 'month');
            this._formatDataCheckedStatus(this.data);
            return;
        }
        const year = this.data[yearIndex];
        year.check = check;
        const filterItem = [];
        if (year.months && year.months.length > 0) {
            year.months.forEach(element => {
                element.check = check;
                if (element.dates && element.dates.length > 0) {
                    element.dates.forEach(day => {
                        day.check = check;
                        filterItem.push({
                            value: day.default_format_value,
                            default_value: element.default_value,
                            check: check === 'all' ? true : false
                        });
                    });
                } else {
                    filterItem.push({
                        value: element.default_format_value,
                        default_value: element.default_value,
                        check: check === 'all' ? true : false
                    });
                }
            });
        } else {
            filterItem.push({
                value: year.default_format_value,
                default_value: year.default_value,
                check: check === 'all' ? true : false
            });
        }
        this._formatDataCheckedStatus(this.data);
        this._triggerFilter(filterItem, 'year');
        return;
    }

    private _formatItems(expand = false) {
        const displayType = this.config.column['settings'].display;
        const data = [];
        // If CellA, CellB column type is Date, Year, Month, Date sort by asc -> Year: 2020 -> 2021, Month: Jan-Dec, Date: 1-31
        this.config.items.sort((a, b) => {
            if (a.text && b.text) {
                return a.text - b.text;
            } else if (!a.text && b.text) {
                return -1;
            } else if (a.text && !b.text) {
                return 1;
            }
            return a.textFormat.localeCompare(b.textFormat);
        }).forEach(item => {
            if (item.text) {
                const tempDate = DateHelperWebService.parseDateFunc(item.text);
                const year = tempDate.getFullYear();
                const month = tempDate.getMonth();
                const formatMonth = this._formatMonth(month);
                const formatDate = this._formatDay(tempDate, displayType);
                if (displayType === '0') {
                    this._formatDisplayTypeIsDateOrDateAndTime(item, data, expand, year, formatMonth, formatDate);
                } else if (displayType === '2') {
                    this._formatDisplayTypeIsYearAndMonth(item, data, expand, year, formatMonth);
                } else if (displayType === '3') {
                    this._formatDisplayTypeIsOnlyYear(item, data, expand, year);
                } else {
                    this._formatDisplayTypeIsDateOrDateAndTime(item, data, expand, year, formatMonth, formatDate);
                }
            } else {
                data.push({
                    year: item.textFormat,
                    default_value: item.text,
                    default_format_value: item.textFormat,
                    check: item.check ? 'all' : 'none',
                    expand: true,
                    months: [],
                    displayType: displayType
                });
            }
        });
        return this._formatDataCheckedStatus(data);
    }

    /**
     * display value is 0, 1
     */
    private _formatDisplayTypeIsDateOrDateAndTime(item, data, expand, year, month, day) {
        let tempData;
        const tempItem = data.find(i => i.year === year);
        if (tempItem) {
            const months = tempItem.months.find(i => i.month === month);
            if (months) {
                const dates = months.dates.find(i => i.date === day);
                if (!dates) {
                    tempData = {
                        date: day,
                        default_value: item.text,
                        default_format_value: item.textFormat,
                        check: item.check ? 'all' : 'none',
                        expand: expand
                    };
                    months.dates.push(tempData);
                }
            } else {
                tempData = {
                    month: month,
                    default_value: item.text,
                    default_format_value: item.textFormat,
                    check: 'none',
                    expand: expand,
                    dates: [{
                        date: day,
                        default_value: item.text,
                        default_format_value: item.textFormat,
                        check: item.check ? 'all' : 'none',
                        expand: expand,
                    }]
                };
                tempItem.months.push(tempData);
            }
        } else {
            tempData = {
                year: year,
                default_value: item.text,
                default_format_value: item.textFormat,
                check: 'none',
                expand: expand,
                months: [{
                    month: month,
                    default_value: item.text,
                    default_format_value: item.textFormat,
                    check: 'none',
                    expand: expand,
                    dates: [{
                        date: day,
                        default_value: item.text,
                        default_format_value: item.textFormat,
                        check: item.check ? 'all' : 'none',
                        expand: expand,
                    }]
                }]
            };
            data.push(tempData);
        }
    }

    /**
     * display value is 2
     */
    private _formatDisplayTypeIsYearAndMonth(item, data, expand, year, month) {
        let tempData;
        const tempItem = data.find(i => i.year === year);
        if (tempItem) {
            const months = tempItem.months.find(i => i.month === month);
            if (!months) {
                tempData = {
                    month: month,
                    default_value: item.text,
                    default_format_value: item.textFormat,
                    check: item.check ? 'all' : 'none',
                    expand: expand,
                    dates: [],
                };
                tempItem.months.push(tempData);
            }
        } else {
            tempData = {
                year: year,
                default_value: item.text,
                default_format_value: item.textFormat,
                check: 'none',
                expand: expand,
                months: [{
                    month: month,
                    default_value: item.text,
                    default_format_value: item.textFormat,
                    check: item.check ? 'all' : 'none',
                    expand: expand,
                    dates: []
                }]
            };
            data.push(tempData);
        }
    }

    /**
     * display value is 3
     */
    private _formatDisplayTypeIsOnlyYear(item, data, expand, year) {
        let tempData;
        const tempItem = data.find(i => i.year === year);
        if (!tempItem) {
            tempData = {
                year: year,
                default_value: item.text,
                default_format_value: item.textFormat,
                check: item.check ? 'all' : 'none',
                expand: expand,
                months: []
            };
            data.push(tempData);
        }
    }

    /**
     * order by item check status, calculate parent node check status
     */
    private _formatDataCheckedStatus(data) {
        data.forEach(element => {
            if (element.months.length > 0) {
                element.months.forEach(month => {
                    if (month.dates.length > 0) {
                        if (month.dates.every(item => item.check === 'all')) {
                            month.check = 'all';
                        } else if (month.dates.every(item => item.check === 'none')) {
                            month.check = 'none';
                        } else {
                            month.check = 'some';
                        }
                    }
                });
                if (element.months.every(item => item.check === 'all')) {
                    element.check = 'all';
                } else if (element.months.every(item => item.check === 'none')) {
                    element.check = 'none';
                } else {
                    element.check = 'some';
                }
            }
        });
        return data;
    }

    private _formatMonth(month) {
        const data = {
            0: 'Jan',
            1: 'Feb',
            2: 'Mar',
            3: 'Apr',
            4: 'May',
            5: 'Jun',
            6: 'Jul',
            7: 'Aug',
            8: 'Sep',
            9: 'Oct',
            10: 'Nov',
            11: 'Dec',
        };
        return data[month] || month;
    }

    private _formatDay(date, displayType) {
        const day = date.getDate().toString();
        const dayStr = day.length === 1 ? `0${day}` : day;
        return dayStr;
    }

    private _initSubscriptions() {
        this._destroySubscriptions.push(
            this.config.actions$.subscribe((events) => {
                if (events.type === CustomDateColumnFilterConfigActionType.onSelectAll) {
                    this.data.forEach(element => {
                        element.check = events.payload;
                        if (element.months.length > 0) {
                            element.months.forEach(month => {
                                month.check = events.payload;
                                if (month.dates.length > 0) {
                                    month.dates.forEach(day => {
                                        day.check = events.payload;
                                    });
                                }
                            });
                        }
                    });
                } else if (events.type === CustomDateColumnFilterConfigActionType.resetData) {
                    this.data = this._formatItems(events.payload);
                }
            })
        );
    }

    private _triggerFilter(data, type) {
        const displayType = this.config.column['settings'].display;
        const tempValue = data[0]['default_value'];
        const tempArr = [];
        if (displayType === '0' && tempValue) {
            // if checked item is '2020/12/12 12:23:24' -> all value '2020/12/12' will checked, that is ignore time
            this.config.items.forEach(item => {
                if (item.text) {
                    const tempYear = item.text.getFullYear();
                    const tempMonth = item.text.getMonth();
                    const tempDate = item.text.getDate();
                    if (tempValue) {
                        const year = tempValue.getFullYear();
                        const month = tempValue.getMonth();
                        const date = tempValue.getDate();
                        if (type === 'year') {
                            if (year === tempYear) {
                                tempArr.push({
                                    value: item.textFormat,
                                    default_value: item.text,
                                    check: data[0].check
                                });
                            }
                        } else if (type === 'month') {
                            if (year === tempYear && month === tempMonth) {
                                tempArr.push({
                                    value: item.textFormat,
                                    default_value: item.text,
                                    check: data[0].check
                                });
                            }
                        } else {
                            if (year === tempYear && month === tempMonth && date === tempDate) {
                                tempArr.push({
                                    value: item.textFormat,
                                    default_value: item.text,
                                    check: data[0].check
                                });
                            }
                        }
                    }
                }
            });
            this.config.events$.next({
                type: CustomDateColumnFilterConfigEventType.onFilter,
                payload: tempArr
            });
        } else {
            this.config.events$.next({
                type: CustomDateColumnFilterConfigEventType.onFilter,
                payload: data
            });
        }
    }
}
