<div class="rating" [attr.value]="rating">
    <label (click)='onClick(1)'>
        <smart-icon name="star-o" *ngIf="rating < 1" class="note-event-dialog-controls-status"></smart-icon>
        <smart-icon name="star" *ngIf="rating >= 1" class="note-event-dialog-controls-status"></smart-icon>
    </label>
    <label (click)='onClick(2)'>
        <smart-icon name="star-o" *ngIf="rating < 2" class="note-event-dialog-controls-status"></smart-icon>
        <smart-icon name="star" *ngIf="rating >= 2" class="note-event-dialog-controls-status"></smart-icon>
    </label>
    <label (click)='onClick(3)'>
        <smart-icon name="star-o" *ngIf="rating < 3" class="note-event-dialog-controls-status"></smart-icon>
        <smart-icon name="star" *ngIf="rating >= 3" class="note-event-dialog-controls-status"></smart-icon>
    </label>
</div>