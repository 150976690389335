/**
 * Created by Abner Sui on 01/30/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { TransportService } from './transport.service';
import { BlastEmailList } from '../models/blastemail-list.model';
import { BlastEmail } from '../models/blastemail.model';
import { AppConfig } from '../models/app-config.model';
import { DateHelperWebService } from './date-helper.web.service';

@Injectable()
export class BlastEmailService {
    constructor(private _transportService: TransportService) { }

    public deleteBlastEmailById(blastEmailId: string): Observable<any> {
        if (!blastEmailId) {
            return observableThrowError({ message: 'Empty blastEmail id' });
        }
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.blastEmailEndpoint}${blastEmailId}`;
        const params = {
            expand: 'entity',
            showpermission: true,
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.delete(url, options);
    }

    public getRefinebyData(searchText: string = '', category: Array<string>, categoryFilter?: Object): Observable<any> {
        const params: any = {
            outputformat: 'json',
        };

        if (searchText) {
            params.searchstring = searchText;
        }

        const formData = new URLSearchParams();
        formData.set('category', JSON.stringify(category));

        if (categoryFilter) {
            formData.set('categoryfilter', JSON.stringify(categoryFilter));
        }
        formData.set('timeZoneId', DateHelperWebService.getContryTimezoneId());

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };

        const options = {
            headers: headers,
            params: params
        };
        const url = `${AppConfig.blastemailRefinebyEndpoint}`;
        return this._transportService.post(url, formData.toString(), options);
    }

    public getBlastEmailList(page: number = 0, pageSize: number = 0, keyword: string = '', categoryFilter?: Object,
        timeZone: number = -new Date().getTimezoneOffset()): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.blastEmailEndpoint}`;
        const params = {
            expand: 'entity',
            page: page,
            rpp: pageSize,
            showpermission: true,
            sortby: 'time',
            sortorder: 'desc',
        };
        if (keyword && keyword.length > 0) {
            params['searchstring'] = keyword;
        }
        if (categoryFilter) {
            params['categoryfilter'] = JSON.stringify(categoryFilter);
        }
        params['timeZoneId'] = DateHelperWebService.getContryTimezoneId();
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.post(url, '', options);
    }

    public getBlastEmailListWithPagging(page: number = 0, pageSize: number = 0, keyword: string = '', sortBy: string = 'time',
        sortOrder: string = 'desc', categoryFilter?: Object, timeZone: number = -new Date().getTimezoneOffset()): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.blastEmailEndpoint}`;
        const params = {
            expand: 'entity',
            page: page,
            rpp: pageSize,
            showpermission: true,
            sortby: sortBy,
            sortorder: sortOrder,
        };
        if (keyword && keyword.length > 0) {
            params['searchstring'] = keyword;
        }
        if (categoryFilter) {
            params['categoryfilter'] = JSON.stringify(categoryFilter);
        }
        params['timeZoneId'] = DateHelperWebService.getContryTimezoneId();

        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.post(url, '', options);
    }

    public getBlastEmailById(blastEmailId: string): Observable<any> {
        if (!blastEmailId) {
            return observableThrowError({ message: 'Empty blastEmail id' });
        }
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.blastEmailEndpoint}${blastEmailId}`;
        const params = {
            expand: 'entity',
            showpermission: true,
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public mapBlastEmailList(response) {
        const blastEmailList = new BlastEmailList();
        blastEmailList.next = response.next;
        blastEmailList.count = response.count;
        const dataItems = response['blastEmailList'];
        for (const dataItem of dataItems) {
            blastEmailList.blastEmails.push(BlastEmail.parse(dataItem));
        }
        return blastEmailList;
    }

    public getBlastEmailAttachmentsById(blastEmailId: string, attachmentId: string): Observable<any> {
        if (!blastEmailId || !attachmentId) {
            return observableThrowError({ message: 'Empty blastEmail id or attachment id' });
        }
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.blastEmailEndpoint}${blastEmailId}/filedata/${attachmentId}`;
        const params = {
            expand: 'entity',
            showpermission: true,
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getBlastEmailBodyById(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.blastEmailEndpoint}${id}/body`;
        const options = {
            headers: headers,
            responseType: 'text' // add this for body since http client has stricted response type
        };
        return this._transportService.get(url, options);
    }

    updateLivewireName(subTitle: string): Observable<any> {
        const headers = {};
        const url = `${AppConfig.blastEmailEndpoint}updatename`;
        const data = new FormData();
        data.append('name', subTitle);
        return this._transportService.put(url, data, { headers: headers });
    }

    getLivewireName(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        };
        const url = `${AppConfig.blastEmailEndpoint}name/`;
        const options = {
            headers: headers,
            responseType: 'text',
        };
        return this._transportService.get(url, options);
    }

    queryByParams(
        pageIndex: number = 0,
        pageSize: number = 20,
        sortBy: string = 'time',
        sortOrder: string = 'desc',
        searchText: string = '',
        categoryFilter?: Object,
        timeZone: number = -new Date().getTimezoneOffset(),
    ) {
        const params: any = {
            page: pageIndex,
            rpp: pageSize,
            sortby: sortBy,
            sortorder: sortOrder,
            expand: 'entity',
            showpermission: true,
            outputformat: 'json',
        };

        if (searchText) {
            params.searchstring = searchText;
        }

        const formData = new URLSearchParams();

        if (categoryFilter) {
            formData.set('categoryfilter', JSON.stringify(categoryFilter));
        }
        params.timeZoneId = DateHelperWebService.getContryTimezoneId();

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };

        const options = {
            headers: headers,
            params: params
        };
        const url = `${AppConfig.blastEmailEndpoint}`;
        return this._transportService.post(url, formData.toString(), options);
    }
}
