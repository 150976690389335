/**
 * Description:
 *
 * ------ maintenance history ------
 */
import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { MiddleEllipsisText } from './middle-ellipsis.model';

@Component({
    selector: 'tam-middle-ellipsis',
    templateUrl: './middle-ellipsis.component.html',
    styleUrls: ['./middle-ellipsis.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiddleEllipsisComponent implements OnChanges {
    @Input() text: MiddleEllipsisText;

    constructor(private _changeDetectorRef: ChangeDetectorRef) {

    }
    ngOnChanges(changes: SimpleChanges): void {
        this.text.full = this.text.start + this.text.middle + this.text.end;
        this.detectChange();
    }

    private detectChange() {
        if (!this._changeDetectorRef['destroyed']) {
            this._changeDetectorRef.detectChanges();
        }
    }
}
