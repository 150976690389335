/**
 * Created by Yu Zhang on 5/24/17.
 * Description:
 * ------ maintenance history ------
 * 10/11/2019 Marcus Zhao  Add expend-value for participant
 * 07/01/2020 Marcus Zhao  change expand of getEntryById() for support attachment.
 * Updated by Daniel Wang on 6/18/2024, Add function named getEntryListByEntityId(entityId) to show entry list.
 */

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { TransportService } from './transport.service';
import { ThreadList } from '../models/thread-list.model';
import { Thread } from '../models/thread.model';
import { NoteEntry } from '../models/note-entry.model';
import { DocumentEntry } from '../models/document-entry.model';
import { AppConfig } from '../models/app-config.model';
import { ProtocolConfig } from '../models/protocol-config.model';
import { Entry } from '../models/entry.model';
import { EntityBrief } from '../models/entity-brief.model';
import { EntryType } from '../models/entry-type.model';
import { businessConstants } from '../constants/business.constants';
import { RelationshipType } from '../models/relationship-type.model';
import { DateHelperWebService } from './date-helper.web.service';
import * as moment from 'moment';
import { AdvFilterHelperService } from './adv-filter-helper.service';

@Injectable()
export class EntryService {
    constructor(
        private _advFilterHelper: AdvFilterHelperService,
        private _transportService: TransportService,
    ) { }

    public appendEntity(entryIDList: string, entryConfig: string): Observable<any> {
        const url = `${AppConfig.entryAppendEntityEndpoint}`;
        const formData = new FormData();
        formData.append('entryIDList', entryIDList);
        formData.append('entryConfig', entryConfig);
        return this._transportService.post(url, formData);
    }

    public appendAllEntity(entryConfig: string, queryInfo: any): Observable<any> {
        const url = `${AppConfig.entryAppendEntityEndpoint}`;
        const formData = new FormData();
        formData.append('entryConfig', entryConfig);
        if (queryInfo['searchText']) {
            formData.append('searchstring', queryInfo['searchText']);
        }
        if (queryInfo['categoryFilter']) {
            formData.append('categoryfilter', JSON.stringify(queryInfo['categoryFilter']));
        }
        const advFilterMap = queryInfo['advFilterMap'];
        const advFilterArr = [];
        if (advFilterMap) {
            Array.from(advFilterMap.values())
                .filter(advFilter => advFilter)
                .forEach((advFilter: string) => advFilterArr.push(advFilter));
            formData.append('advfilter', this._advFilterHelper.joinFiltersByAnd(advFilterArr));
        }
        formData.append('timeZoneId', DateHelperWebService.getContryTimezoneId());
        formData.append('draft', 'false');
        formData.append('show-calendar-list', queryInfo['showCalendarEvents']);
        formData.append('show-note-list', 'false');
        if (queryInfo['entityIds'] && queryInfo['entityIds'].length > 0) {
            formData.append('entities', queryInfo['entityIds']);
        }

        return this._transportService.post(url, formData);
    }

    /**
     * @param systemConfig
     * @param notes
     * @param threadEntryTotalNotesInThread
     * @returns entry
     */
    getEntryBySideNoteFollowRule(systemConfig: any, notes: Array<any>, threadEntryTotalNotesInThread): NoteEntry {
        let entry = null;
        let sidenoteFollowRule = '';
        if (systemConfig && systemConfig.entryConfig && systemConfig.entryConfig.sideNoteFollowRule) {
            sidenoteFollowRule = systemConfig.entryConfig.sideNoteFollowRule;
            if (notes && notes.length > 0) {
                if (sidenoteFollowRule === businessConstants.note.defaultSidenoteType.latestNote) {
                    entry = notes.filter(item => item.threadPosition + 1 === threadEntryTotalNotesInThread)[0];
                } else {
                    entry = notes.filter(item => item.threadPosition === 0)[0];
                }
            }
        }
        return entry;
    }

    /**
     * @param configuration
     * @param searchId
     * @param metadataFilter
     * @return eventlist
     */
    getEventListByNearby(configuration, searchId, metadataFilter?): Observable<any> {
        const headers = {
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.eventNearbyEndpoint}switch`;
        const formData = new FormData();
        formData.append('configuration', JSON.stringify(configuration));
        formData.append('excludes', searchId);
        if (metadataFilter) {
            formData.append('metadataFilter', JSON.stringify(metadataFilter));
        }
        formData.append('operate', 'getNearby');
        const params = {
            expand: 'thread;entry;entry-type;attachments;entities;entity;source;submitter;properties;property;propdef',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.post(url, formData, options);
    }

    // used by note page
    public getNoteById(noteId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryEndpoint}${noteId}`;
        const params = {
            expand: 'thread;entry;attachments;entities;entity;source;submitter;properties;property;propdef;',
            showpermission: true,
            showattachmentsbyentry: true,
            'exclude-embedded-img': true,
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     * used in template edit a note, to get all entry properties as well, parameters are different from above getNoteById
     * param entryId
     */
    public getEntryById(entryId: string) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryEndpoint}${entryId}`;
        const params = {
            expand: 'entities;entity;properties;property;propdef;entry-type;source;submitter;thread;attachments;entry',
            showpermission: true,
            showattachmentsbyentry: true,
            showattachmentserverfilename: true,
            outputformat: 'json',
            'exclude-embedded-img': true,
        };
        // if(AppConfig.supportDraft) {
        //     params['draft'] = true;
        // }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getNoteBodyById(noteId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryEndpoint}${noteId}/body`;
        let params;
        // To solve the issue that the image couldn't be shown correctly for note body, we get
        // image stream instead of image url for html of note body from version 18.1
        // We need to check the version to keep it work for versions that below 18.1, because there were parameters
        // verification on the server side.
        if (AppConfig.supportImgToStream) {
            params = {
                imgtostream: true
            };
        } else {
            params = {
                translateimgtags: true
            };
        }
        const options = {
            headers: headers,
            params: params,
            responseType: 'text' // add this for note body since http client has stricted response type
        };
        return this._transportService.get(url, options);
    }

    public getNoteBodyByBodyLink(bodyLink: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = bodyLink;
        let params;
        if (AppConfig.supportImgToStream) {
            params = {
                imgtostream: true
            };
        } else {
            params = {
                translateimgtags: true
            };
        }
        const options = {
            headers: headers,
            params: params,
            responseType: 'text' // add this for note body since http client has strict response type
        };
        return this._transportService.get(url, options);
    }

    /**
     * [TAM-24227] sortby lastdisplaydate;
     * [TAM-28578]
     * Add entryclass equals note; use displayddate as sortby paramemter;change advFilter
     * param adhocId change advFilter
     * param entityId
     */
    public getPreviousStored(adhocId: string, entityId: string) {
        const advFilter = '((entities contains id "' + entityId + '") and (property "' + adhocId + '" is-set))';
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryEndpoint}`;
        const params = {
            advfilter: advFilter,
            expand: 'properties;property;',
            entryclass: 'note',
            page: 1,
            rpp: 1,
            sortby: 'lastdisplaydate',
            sortorder: 'desc',
            outputformat: 'json',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options).pipe(
            map(res => {
                if (res['entry-list'].length <= 0 || res['entry-list'][0].properties.data.length <= 0) {
                    return ('');
                }
                const properties = res['entry-list'][0].properties.data;
                for (let i = 0; i < properties.length; i++) {
                    // get adhocId from data.href
                    const reg = /properties\/(\w+)/g;
                    const matches = reg.exec(properties[i].data.href);
                    if (matches && adhocId === matches[1]) {
                        return (properties[i].data.value);
                    }
                }
                return ('');
            }));
    }

    public mapNote(response) {
        const note = response;
        const noteEntry = new NoteEntry();
        noteEntry.id = note.id;

        // meta data
        noteEntry.subject = note.title;
        noteEntry.calculatedSubject = note.calculatedTitle || note.title;
        // noteEntry.entities = EntityHelperService.getEntities(note.entities);
        noteEntry.entities = note.entities.data.map(item => EntityBrief.parse(item.data));
        noteEntry.lastEditDate = new Date(note['last-edited-date']);
        noteEntry.displayDate = new Date(note['display-date']);
        noteEntry.submittedDate = new Date(note['submitted-date']);
        noteEntry.source = EntityBrief.parse(note.source.data);
        noteEntry.submitter = EntityBrief.parse(note.submitter.data);
        noteEntry.type = EntryType.parse(note['entry-type']);
        // noteEntry.sourceName = note.source.link.phid;
        // noteEntry.companyName = note.source['data']['contact-details'].company;
        // noteEntry.sourceId = note.source['data'].id;
        // noteEntry.submitterName = note.submitter.link.phid;
        // noteEntry.submitterId = note.submitter['data'].id;
        noteEntry.sentiment = note.sentiment;
        noteEntry.priority = note.priority;
        // noteEntry.noteType = note['entry-type'].link.phid;
        noteEntry.backdated = note['is-backdated'];

        // permissions
        noteEntry.editable = note.editable;
        noteEntry.deletable = note.deletable;

        // note body
        noteEntry.bodyLink = note.body.link.href;

        // save thread information
        noteEntry.threadPosition = note['thread-position'];
        noteEntry.totalNotesInThread = note['thread-total'];

        // save parent note information
        // noteEntry.parentEntryID = note.thread['data'].parentEntry['data'].id;

        // for 17.0.1 server, if subject is empty, show (<entry type>)
        if (!AppConfig.supportCalculatedSubject && noteEntry.calculatedSubject === '') {
            noteEntry.calculatedSubject = `(${noteEntry.type.name})`;
        }

        // attachments
        if (note.attachments && note.attachments.data && note.attachments.data.length > 0) {
            for (const attachmentObj of note.attachments.data) {
                const attachmentLink = attachmentObj['link'].href;
                noteEntry.attachmentIds.push(attachmentLink.substring(attachmentLink.indexOf('/entry') + 7, attachmentLink.length - 1));
                const attachment = new DocumentEntry();
                attachment.isEmbeddedImg = attachmentObj['data']['is-embedded-image'] === 'true';
                if (attachment.isEmbeddedImg === true) {
                    continue;
                }
                attachment.id = attachmentObj['data'].id;
                attachment.fileName = attachmentObj['data'].filename;
                attachment.extension = attachment.fileName.substring(attachment.fileName.lastIndexOf('.') + 1);
                attachment.fileDataLink = attachmentObj['data'].filedata.link.href;
                attachment.noteId = attachmentObj['data'].thread['link'].phid;
                attachment.deletable = attachmentObj['data'].deletable;
                attachment.editable = attachmentObj['data'].editable;
                noteEntry.attachments.set(attachment.id, attachment);
                noteEntry.attachmentIds.push(attachment.id);
            }
        }
        return noteEntry;
    }

    public formatAdhocTableStyling(response) {
        // remove all adhoc table styling
        const reg = /<head>.*?<\/head>/gm;
        return response.replace(reg,
            `<head><style type="text/css">
                body {
                    font-family: SourceSansPro-Regular;
                    width: calc(100% - 10px);
                    height: calc(100% - 10px);
                }
            </style></head>`);
    }

    public formatImageStyling(response) {
        return response.replace(/<img.*?src="\/restapi\/2\.0\/entry.*?"/gi, function (match: string) {
            const maxWidth = (AppConfig.screenWidth > 0 ? 'max-width\:' + ((AppConfig.screenWidth - 30) + 'px;') : '');
            const maxHeight = (AppConfig.screenHeight > 0 ? 'max-height:\:' + ((AppConfig.screenHeight - 30) + 'px;') : '');
            const srcIndex = match.indexOf('/restapi');
            match = '<img style="' +
                // if there are screen size predefined, set the image size max size
                maxWidth + maxHeight + '" src="' +
                ProtocolConfig.prefix + AppConfig.serverAddress + match.substring(srcIndex, match.length - 1) +
                '?userid=' + AppConfig.userId + '"';
            return match;
        });
    }

    public separateAdHocTableFromNoteBody(response) {
        // separate regular adhoc table
        // tslint:disable-next-line: max-line-length
        let reg = /(?:(?:<!--(?:\s)START:(?:\s)custom(?:\s)table(?:\s)metadata(?:\s)-->)((?:.*?\r?\n?)*?)(?:<!--(?:\s)END:(?:\s)custom(?:\s)table(?:\s)metadata(?:\s)-->))+/g;
        let adHocTable = '', body = '';
        body = response.replace(reg, function (match) {
            adHocTable = match;
            return '';
        });
        // separate entity summary adhoc table
        if (adHocTable.length === 0) {
            // tslint:disable-next-line: max-line-length
            reg = /(?:(?:<!--(?:\s)START:(?:\s)Entity(?:\s)Update(?:\s)Summary(?:\s)-->)((?:.*?\r?\n?)*?)(?:<!--(?:\s)END:(?:\s)Entity(?:\s)Update(?:\s)Summary(?:\s)-->))+/g;
            body = response.replace(reg, function (match) {
                adHocTable = match;
                return '';
            });
        }
        adHocTable = adHocTable.replace(/\ "/gi, '\"');
        adHocTable = adHocTable.replace(/style=\".*?\"/gi, '');
        adHocTable = adHocTable.replace(/width="100%"/gi, '');
        adHocTable = adHocTable.replace(/nowrap=\"\"/gi, '');
        adHocTable = adHocTable.replace(/\<\/td\><td\s*class\=\"var\"/gi, function (match) {
            return '</td></tr><tr><td class="var"';
        });

        if (adHocTable.length > 0) {
            adHocTable =
                `<html>
                    <head>
                        <style type="text/css">
                            table {
                                font-family: SourceSansPro-Regular;
                                font-size: 12pt;
                                margin: 0;
                                width: 100%;
                                border-collapse: collapse !important;
                            }
                            table tr {
                                width: 100%;
                            }
                            table tr td {
                                border-bottom: 1px solid lightgray;
                                padding: 2pt 0;
                            }
                            table tr:last-of-type td {
                                border: 0;
                            }
                            .var {
                                min-width: 120pt;
                                vertical-align: top;
                            }
                            .value {
                                vertical-align: top;
                                text-align: right;
                            }
                        </style>
                    </head>
                    <body>`
                + adHocTable.toString() +
                `</body>
                </html>`;
        }
        // remove comments in note body html string
        reg = /(?:<!--((?:.*?\r?\n?)*?)-->)+/g;
        body = body.replace(reg, '');
        reg = /<head>/gi;
        body = body.replace(reg, function (match) {
            return `<head>
                        <style>
                            body {
                                font-family: "SourceSansPro-Regular";
                                font-size: 16px !important;
                                min-height: 100pt;
                                width: 100%;
                                padding: 0 !important;
                                margin: 0 !important;
                            }

                            ul, ol, dl {
                                padding-top: 0 !important;
                                padding-bottom: 5px !important;
                                margin-top: 0 !important;
                                margin-bottom: 5px !important;
                            }

                            li, dd {
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                margin-top: 0 !important;
                                margin-bottom: 0 !important;
                                line-height: 16px;
                            }
                        </style>`;
        });
        // reg = /body {/gi;
        // body = body.replace(reg, function(match) {
        // tslint:disable-next-line: max-line-length
        // return 'body { font-family: "SourceSansPro-Regular"; font-size: 16px !important; min-height: 100pt; width: 100%; padding: 0 !important; margin: 0 !important;';
        // });
        if (body.indexOf('<body></body>') > -1) {
            body = '';
        } else {
            const bodyStart = body.indexOf('<body>');
            const bodyEnd = body.indexOf('</body>');
            const checkEmptyBody = body.substring(bodyStart + 6, bodyEnd);
            if (+checkEmptyBody === 0) {
                body = '';
            }
        }
        return {
            adHocTable: adHocTable,
            body: body
        };
    }

    // used by simple search
    public searchAttachments(keyword: string = '', recordEachType: number = 5): Observable<any> {
        const advFilter = '((! entities contains long-name "EMAIL_SPOOLER") and (! entities contains id "' + businessConstants.entity.EMAIL_SPOOLER_ID + '"))';

        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryEndpoint}`;
        const params = {
            advfilter: advFilter,
            entryclass: 'attachment',
            outputformat: 'json',
            page: 1,
            rpp: recordEachType,
            searchstring: keyword,
            sortby: 'submitteddate',
            sortorder: 'desc',
            'exclude-embedded-img': true,
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    // used by recent notes
    public getEntryList(page: number = 0, pageSize: number = 0, entityFocus: string = '',
        keyword: string = '', entityId: string = ''): Observable<any> {
        // TODO: @Alan, refactor this to use advFilterHelper for better code readability and maintenance
        // tslint:disable-next-line: max-line-length
        const advFilter = `(((entry-class equals "note") and ((! entities contains long-name "EMAIL_SPOOLER") and (! entities contains long-name "ConfigurationData"))) and (((entities contains id "${entityId}") or (source id equals "${entityId}")) or (submitter id equals "${entityId}")))`;
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryEndpoint}`;
        const params = {
            advfilter: advFilter,
            expand: 'thread;attachments;entry;entities;entity;source;submitter;',
            page: page,
            rpp: pageSize,
            showpermission: true,
            showblurb: true,
            sortby: 'lastdisplaydate',
            sortorder: 'desc',
            showattachmentsbyentry: true,
            showattachmentserverfilename: true,
            'exclude-embedded-img': true,
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     * get entry list by entity id
     * @param id
     * @returns
     */
    public getEntryListByEntityId(entityId: string) {
        const url = `${AppConfig.entryEndpoint}entity/` + entityId;
        const formData = new FormData();
        const entryConfig = {
            'configList': [{
                'fields': ['entity', 'source', 'adhoc']
            }]
        };
        formData.append('entryConfig', JSON.stringify(entryConfig));
        return this._transportService.post(url, formData).pipe(
            map(response => {
                return this._mapEntryList(response);
            })
        );
    }

    public mapEntryList(data): any[] {
        const notes = [];
        let notesNum = 0;
        const entryList = data['entry-list'];

        for (let i = 0; i < entryList.length; i++) {
            const note = new Entry();
            note.id = entryList[i].id;
            note.entitiesInfo = this.getEntitiesNames(entryList[i].entities, true);
            note.sourceName = entryList[i].source.link.phid;
            note.sourceLink = 'entity/' + entryList[i].source.link.href.split('/')[entryList[i].source.link.href.split('/').length - 2];
            note.subject = entryList[i].title !== '' ? entryList[i].title : entryList[i].thread.data.parentEntry.link.phid;
            note.calculatedSubject = entryList[i].calculatedTitle;
            note.subjectLink = 'entry/' + entryList[i].thread.link.href.split('/')[entryList[i].thread.link.href.split('/').length - 2];
            if (entryList[i].body) {
                note.bodyLink = entryList[i].body.link.href;
            }
            note.body = '';
            note.noteType = (entryList[i]['thread-position'] === '0' ? 'note' : 'sidenote');
            // note.date = (new Date(entryList[i]["last-edited-date"])).format('MM.dd.yyyy');
            note.dateSpan = this.dateTimeToWord(new Date(entryList[i]['last-edited-date']));
            note.entryType = entryList[i]['entry-type'].link.phid;
            note.editable = entryList[i].editable;
            note.deletable = entryList[i].deletable;
            note.backdated = entryList[i]['is-backdated'];
            notes[notesNum] = note;
            note.blurb = entryList[i]['blurb'];
            notesNum++;
        }
        return notes;
    }

    // tslint:disable-next-line: max-line-length
    // used by recent notes, since recent notes are using thread list component, we should map entry list data model to thread list data model
    public mapEntryListToThreadList(response): ThreadList {
        const threadList = new ThreadList();
        threadList.next = response.next;
        const dataItems = response['entry-list'];
        if (dataItems === undefined) {
            return threadList;
        } else {
            for (const dataItem of dataItems) {
                const thread = this.mapEntryToThread(dataItem);
                threadList.threads.push(thread);
            }
        }
        return threadList;
    }

    // tslint:disable-next-line: max-line-length
    // used by recent notes, since recent notes are using thread list component, we should map entry list data model to thread list data model
    public mapEntryToThread(response) {
        const thread = new Thread();
        thread.id = response.id; // TODO: discuss threadId when item is entry.

        const note = response;
        const noteEntry = new NoteEntry();
        noteEntry.id = note.id;
        noteEntry.threadPosition = note['thread-position'];
        noteEntry.subject = note.title;
        noteEntry.calculatedSubject = note.calculatedTitle || note.title;
        // noteEntry.entities = EntityHelperService.getEntities(note.entities);
        noteEntry.entities = note.entities.data.map(item => EntityBrief.parse(item.data));
        noteEntry.lastEditDate = new Date(note['last-edited-date']);
        noteEntry.displayDate = new Date(note['display-date']);
        noteEntry.submittedDate = new Date(note['submitted-date']);
        noteEntry.source = EntityBrief.parse(note.source.data);
        noteEntry.submitter = EntityBrief.parse(note.submitter.data);
        noteEntry.type = EntryType.parse(note['entry-type']);
        // noteEntry.sourceName = note.source.link.phid;
        // noteEntry.sourceId = note.source['data'].id;
        // noteEntry.submitterName = note.submitter.link.phid;
        // noteEntry.submitterId = note.submitter['data'].id;

        noteEntry.sentiment = note.sentiment;
        noteEntry.priority = note.priority;
        // noteEntry.noteType = note['entry-type'].link.phid;
        noteEntry.backdated = note['is-backdated'];

        // permissions
        noteEntry.editable = note.editable;
        noteEntry.deletable = note.deletable;

        // note body
        noteEntry.bodyLink = note.body.link.href;

        // attachments
        if (note.attachments && note.attachments.hasOwnProperty('data')) {
            for (const attachment of note.attachments.data) {
                if (attachment['data']['is-embedded-image'] === 'true') {
                    continue;
                }
                const attachmentLink = attachment['link'].href;
                noteEntry.attachmentIds.push(attachmentLink.substring(attachmentLink.indexOf('/entry') + 7, attachmentLink.length - 1));
            }
        }

        thread.notes.push(noteEntry);

        return thread;
    }

    // used by entry template edit mode, format entry standard and adhoc values
    public mapEntryValue(data) {
        const entry = {
            // common properties
            id: data.id,
            entities: [],
            calculatedTitle: data['calculatedTitle'],
            source: data['source'].data.id,
            displaydate: data['display-date'],
            sentiment: data['sentiment'],
            priority: data['priority'],
            subject: data['title'],
            entryTypeID: data['entry-type']['data'].id,
            entryTypeName: data['entry-type']['data'].name,
            body: '',
            submittedDate: data['submitted-date'],
            isDraft: data.isdraft,
            threadId: data['thread'].data.id,
            totalNotesInThread: data['thread'].data.notes.length,
            lastEditDate: data['last-edited-date'],
            entryClass: data['entry-class']
        };
        // get entities from data
        for (const entity of data.entities.data) {
            if (entity.data.confidence) {
                entry.entities.push({
                    id: entity.data.id,
                    confidence: entity.data.confidence
                });
            } else {
                entry.entities.push(entity.data.id);
            }
        }
        // get adhoc fields
        for (const adhoc of data.properties.data) {
            const adhocId = adhoc['data']['propdef']['data'].id;
            let adhocValue;
            if (adhocId === businessConstants.calendar.ownProperties.locationExpandId) {
                adhocValue = {
                    value: adhoc['data']['value'],
                    expandValue: adhoc['data']['expandValue']
                };
            } else if (adhocId === businessConstants.calendar.ownProperties.attendeesId) {
                adhocValue = adhoc['data']['expand-value'];
            } else {
                adhocValue = adhoc['data']['value'];
            }

            entry[adhocId] = adhocValue;
        }
        return entry;
    }

    public publishNote(noteId: string, params, data, publishingSidenote: boolean = false) {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        let url = `${AppConfig.entryEndpoint}`;
        if (!publishingSidenote && noteId.length > 0) {
            url += (noteId.length > 0 ? noteId + '/' : '');
        } else if (publishingSidenote && noteId.length > 0) {
            params['parentid'] = noteId;
        }
        params.outputformat = 'json';
        params.expand = 'entities;entity;properties;property;propdef;entry-type;source;submitter;thread;attachments;entry;';
        params.showpermission = true;
        params.showattachmentsbyentry = true;
        params.showattachmentserverfilename = true;
        let formData = '';
        for (const key in data) {
            if (AppConfig.entryEndpoint === '2.0/entry' && key === 'draft') {
                continue;
            }
            if (data[key] instanceof Array) {
                // When the key is 'intralinks-file', the data to be passed to the server is of the list type
                if (key === 'intralinks-file') {
                    formData += key + '=[';
                    for (let i = 0; i < data[key].length; i++) {
                        if (i === data[key].length - 1) {
                            formData += '\"' + encodeURIComponent(data[key][i]) + '\"';
                        } else {
                            formData += '\"' + encodeURIComponent(data[key][i]) + '\"' + ',';
                        }
                    }
                    formData += ']' + '&';
                } else {
                    for (const item of data[key]) {
                        formData += key + '=' + encodeURIComponent(item) + '&';
                    }
                }
            } else {
                formData += key + '=' + encodeURIComponent(data[key]) + '&';
            }
        }
        // modify : move params to formData
        for (const key in params) {
            if (params[key] instanceof Array) {
                // for saving auto tag draft
                if (key === 'entities' && params[key].length === 0) {
                    formData += key + '=' + '' + '&';
                }
                for (const item of params[key]) {
                    formData += key + '=' + encodeURIComponent(item) + '&';
                }
            } else {
                if (key === 'originInfo') {
                    formData += key + '=' + encodeURIComponent(JSON.stringify(params[key])) + '&';
                } else {
                    formData += key + '=' + encodeURIComponent(params[key]) + '&';
                }
            }
        }
        formData = formData.substring(0, formData.length - 1);
        const options = {
            params: params.intralinks ? { intralinks: true } : '',
            headers: headers
        };
        return this._transportService.post(url, formData, options);
    }

    public getPrivacyData(configData, currentUserId): Observable<any> {
        if (configData.relationshipBasedDefaultingEnable) {
            const primaryTeamRelId = configData.primaryTeamRelationshipTypeID;
            const personalTeamRelId = configData.personTeamRelationshipTypeID;
            return this.getPrivacyTeams(currentUserId, primaryTeamRelId, personalTeamRelId);
        } else {
            return this.getAllTeams();
        }
    }

    public getPrivacyTeams(currentUserid, primaryTeamRelId, personalTeamRelId): Observable<any> {
        // TODO: @Alan, use advFilterHelper to construct advFilter string
        // tslint:disable-next-line: max-line-length
        const advFilter = `((child-entity id equals "${currentUserid}") and ((relationship-type id equals "${primaryTeamRelId}") or (relationship-type id equals "${personalTeamRelId}")))`;
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.relationshipEndpoint}`;
        const params = {
            advfilter: advFilter,
            expand: 'child-entity;entity;relationship-type',
            showsimple: true,
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getAllTeams(): Observable<any> {
        const url = `${AppConfig.teamEndpoint}`;
        const params = {
            expand: 'aliases;alias',
        };

        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getTeamsByEntityId(entityId: string) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.teamPoint}entity/` + entityId;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options).pipe(
            map(response => {
                return this._mapEntityBriefList(response);
            })
        );
    }

    public getTeamsgetByParams(page: number = 0, pageSize: number = 0, keyword: string = '', isExpand: boolean = false): Observable<any> {
        const url = `${AppConfig.teamEndpoint}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        let fields = 'id;short-name;long-name;';
        if (isExpand) {
            fields = 'id;short-name;long-name;is-public;entity-type;';
        }
        const params = {
            fields: fields,
            page: page,
            rpp: pageSize
        };
        if (keyword && keyword.length > 0) {
            params['filterstring'] = keyword;
            params['filterby'] = 'short-name';
        } else {
            params['sortby'] = 'long-name';
            params['sortorder'] = 'asc';
        }

        const options = {
            headers: headers,
            params: params
        };
        const paramsStr = this._transportService.getUrlEncodedForm(options);
        return this._transportService.get(url, options);
    }

    public getContentFromBodyHtml(bodyHtml) {
        let formattedHtml;
        let reg = /(h )?table#tabledisplay-meta[\s\S]*?\}/gm;
        formattedHtml = bodyHtml.replace(reg, '');

        // fixed issue [TAM-27256]The styling of note is not correct when deposit from outlook plugin
        // reg = /<p>(<span style="mso-fareast-language:.{0,20}">)?<o:p>&nbsp;<\/o:p>(<\/span>)?<\/p>/gm;
        // formattedHtml = formattedHtml.replace(reg, '');

        // reg = /<div>&nbsp;<\/div>/gm;
        // formattedHtml = formattedHtml.replace(reg, '');

        reg = /<!-- START: custom table style -->[\s\S]*?<!-- END: custom table style -->/gm;
        formattedHtml = formattedHtml.replace(reg, '');

        reg = /<body.*?>([\s\S]*?)<\/body>/gm;
        const matches = reg.exec(formattedHtml);
        if (matches) {
            reg = /<style.*?>[\s\S]*?<\/style>/gm;
            const styles = formattedHtml.match(reg);

            formattedHtml = matches[1];
            // remove form tag as it is added by a wrong case, may remove this logic after server fix
            reg = /<\/?form>/gm;
            formattedHtml = formattedHtml.replace(reg, '');

            // remove Subject Section
            reg = /<!-- START: subject begin -->[\s\S]*?<!-- END: subject end -->/gm;
            formattedHtml = formattedHtml.replace(reg, '');

            // remove adhoc table section
            reg = /<!-- START: custom table metadata -->[\s\S]*?<!-- END: custom table metadata -->/gm;
            formattedHtml = formattedHtml.replace(reg, '');

            reg = /<style.*?>[\s\S]*?<\/style>/gm;
            formattedHtml = formattedHtml.replace(reg, '');

            // keep all Style Sheet in note body
            if (styles) {
                formattedHtml = styles.join('\r\n') + formattedHtml;
            }
            return formattedHtml;
        } else {
            // compatible the historical note before Web 17.1
            // note without template will only save a div with class 'note-body'
            return formattedHtml;
        }
    }

    public appendUserIdToImageLinksInNoteBody(bodyHtml, userId) {
        if (bodyHtml && userId) {
            /**
             * need to handle the case that the image url already have userid;
             * this may caused by change login user but didn't refreah note list well.
             * how to handle: ignore the userid in url and always use current userid.
             * sample image url:/restapi/2.0/entry/06ffbe956d1e49bf8813de501da1dad6/filedata/
             * 5986b7a457eebdd0794b1f147e66d24c.png?userid=23f03fe70a16aed0d7e210357164e401
             * current userid: 7553470dc3944ae0a64685a5000960b2
             */
            // match img tag which src attribute start with "/restapi/2.0/entry/"
            // .* is any character other than line breaks, \s\S -Any character, includes spaces, tabs, page breaks
            const reg = /<img +([\s\S]*?)src=['"](\/restapi\/2\.0\/entry\/[\s\S]*?)(\?userid=[\s\S]*?)?['"]([\s\S]*?)>/g;
            bodyHtml = bodyHtml.replace(reg, '<img $1src="$2?userid=' + userId + '"$4>');
        }
        return bodyHtml;
    }

    public deleteNoteById(noteId: string) {
        const url = `${AppConfig.entryEndpoint}${noteId}/`;
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return this._transportService.delete(url, options);
    }

    public deleteNotesByIds(noteIds: Array<string>) {
        const url = `${AppConfig.entryEndpoint}`;
        const headers = {
            'Content-Type': 'application/json',
        };
        let params = new HttpParams();
        params = params.append('entries', JSON.stringify(noteIds));
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.delete(url, options);
    }

    public updateEntryEntities(parentNoteId: string, entityIds: Array<string>) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryEndpoint}${parentNoteId}`;
        const options = {
            headers: headers,
            params: {
                entities: entityIds.join(';')
            }
        };
        return this._transportService.put(url, {}, options);
    }

    public getEntryAttachmentsByEntryId(entryId, success, failed) {
        const url = AppConfig.entryEndpoint;
        const options = {
            showattachmentserverfilename: true,
            showattachmentsbyentry: true,
            expand: 'thread;entry;entities;entity;source;attachments;',
            outputformat: 'json',
            'exclude-embedded-img': true,
        };

        let result = [];
        this._transportService.get(url, options).pipe(map(data => {
            if (data && data['attachments'] && data['attachments'].data) {
                result = data['attachments'].data;
            }
        }));
        return result;
    }

    public getEntryFormBody(entryTypeId, entityId): Observable<any> {
        const url = `${AppConfig.entryEndpoint}${'body'}`;
        const options = {
            // use 'text' to get HTML response.
            responseType: 'text',
            params: {
                translateimgpath: true,
                entrytype: entryTypeId,
                entity: entityId
            }
        };
        return this._transportService.get(url, options);
    }

    public getRefinebyData(entityIds: string[] = [],
        searchText: string = '',
        category: Array<string>,
        advFilter: string = '',
        categoryFilter?: Object,
        showCalendarEvents = false,
        timeZone: number = -new Date().getTimezoneOffset()): Observable<any> {
        const params: any = {
            outputformat: 'json',
            'show-calendar-list': showCalendarEvents,
            'show-note-list': true,
        };

        if (searchText) {
            params.searchstring = searchText;
        }

        const formData = new URLSearchParams();
        formData.set('category', JSON.stringify(category));

        if (entityIds && entityIds.length > 0) {
            formData.set('entities', entityIds.join(','));
        }

        if (advFilter) {
            formData.set('advfilter', advFilter);
        }

        if (categoryFilter) {
            formData.set('categoryfilter', JSON.stringify(categoryFilter));
        }
        formData.set('timeZoneId', DateHelperWebService.getContryTimezoneId());
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };

        const options = {
            headers: headers,
            params: params
        };
        const url = `${AppConfig.entryRefinebyEndpoint}`;
        return this._transportService.post(url, formData.toString(), options);
    }

    public queryByParams(
        pageIndex: number = 0,
        pageSize: number = 20,
        sortBy: string = 'displaydate',
        sortOrder: string = 'desc',
        advFilter: string = '',
        entityIds: string[] = [],
        searchText: string = '',
        draft: boolean = false,
        categoryFilter?: Object,
        groups?,
        groupSortOrder?,
        groupFilters?,
        showCalendarEvents = false,
        timeZoneId: string = DateHelperWebService.getContryTimezoneId(),
    ) {
        const params: any = {
            page: pageIndex,
            rpp: pageSize,
            sortby: sortBy,
            sortorder: sortOrder,
            expand: 'attachments;entities;entity;entry;source;submitter;thread;properties;property;propdef;entry-type',
            draft: draft === true ? true : false,
            showattachmentsbyentry: true,
            showattachmentserverfilename: true,
            showpermission: true,
            outputformat: 'json',
            'show-note-list': true,
            'show-calendar-list': showCalendarEvents,
            'exclude-embedded-img': true,
        };

        if (searchText) {
            params.searchstring = searchText;
        }

        const formData = new URLSearchParams();

        if (entityIds && entityIds.length > 0) {
            formData.set('entities', entityIds.join(','));
        }

        if (categoryFilter) {
            formData.set('categoryfilter', JSON.stringify(categoryFilter));
        }

        if (advFilter) {
            formData.set('advfilter', advFilter);
        }
        formData.set('timeZoneId', timeZoneId);

        if (groups) {
            formData.set('groups', groups);
            formData.set('group-sortorder', groupSortOrder);
            if (groupFilters) {
                formData.set('group-filters', groupFilters);
            }
        }

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };

        const options = {
            headers: headers,
            params: params
        };
        const url = `${AppConfig.entryEndpoint}${'post'}/`;
        return this._transportService.post(url, formData.toString(), options);
    }

    /**
     *
     * forjudge whether show on the fly item
     * @param {string} searchValue
     * @param {Array<any>} entityData
     * @returns
     * @memberof EntryService
     */
    public isShowOnTheFly(searchValue: string, entityData: Array<any>) {
        if (searchValue && entityData.findIndex(item => (item.shortName && item.shortName.toLowerCase() === searchValue.toLowerCase()) || (item.name && item.name.toLowerCase() === searchValue.toLowerCase())) === -1) {
            return searchValue;
        } else {
            return '';
        }
    }

    public judgeIsTeamRelationship(config) {
        if (config &&
            config.defaultValue &&
            config.defaultValue.relationshipEntities &&
            config.defaultValue.relationshipEntities.relationshipTypeID) {
            const relationshipId = config.defaultValue.relationshipEntities.relationshipTypeID;
            const isTeam = (relationshipId === RelationshipType.IS_IN_TEAM.id ||
                relationshipId === RelationshipType.TOPIC_MEMBER.id);
            return isTeam;
        }
    }

    // get entities names from entity list start //
    // entitiesParam is entity array
    // if withLink is false, only return string names
    // if withLink is true, then return entity names with link
    private getEntitiesNames = function (entitiesParam, withLink) {
        const name = [];
        const nameLink = [];
        const itemList = entitiesParam['data'];
        let entityType = 'entity';
        for (let i = 0; itemList && i < itemList.length; i++) {
            name[i] = itemList[i]['data']['short-name'];

            // contact uses contact; corporate,team uses entity in url
            if (itemList[i]['data']['entity-type']['link']['phid'].toLowerCase() === 'contact') {
                entityType = 'contact';
            } else {
                entityType = 'entity';
            }
            nameLink[i] = '<a target=\'_blank\' class=\'item-entities-Link body-2\' href=\''
                + entityType + '/' + itemList[i]['data']['id'] + '\'>' +
                itemList[i]['data']['short-name'] + '</a>';
        }
        const names = name.join(', ');
        const nameLinks = nameLink.join(', ');
        let result = '';
        if (withLink === 1) {
            result = nameLinks;
        } else {
            result = names;
        }
        return result;
    };

    // generate word from datetime start //
    private dateTimeToWord = function (postdate) {
        const second = 1000;
        const minutes = second * 60;
        const hours = minutes * 60;
        const days = hours * 24;
        const week = days * 7;
        const months = days * 30;
        const years = days * 365;
        const myDate = new Date(Date.parse(postdate));
        // if (isNaN(myDate)) {
        //     myDate = new Date(postdate.replace(/-/g, "/"));
        // }
        const nowtime = new Date();
        const longtime = nowtime.getTime() - myDate.getTime();
        let span = 1;
        let unit = '';
        if (longtime >= years) {
            span = Math.floor(longtime / years);
            unit = 'year';
        } else if (longtime >= months) {
            span = Math.floor(longtime / months);
            unit = 'month';
        } else if (longtime >= week) {
            span = Math.floor(longtime / week);
            unit = 'week';
        } else if (longtime >= days) {
            span = Math.floor(longtime / days);
            unit = 'day';
        } else if (longtime >= hours) {
            span = Math.floor(longtime / hours);
            unit = 'hour';
        } else if (longtime >= minutes) {
            span = Math.floor(longtime / minutes);
            unit = 'minute';
        } else if (longtime >= second) {
            span = Math.floor(longtime / second);
            unit = 'second';
        } else {
            return (longtime + ' error ');
        }

        if (span > 1) {
            return span + ' ' + unit + 's ago';
        } else {
            return span + ' ' + unit + ' ago';
        }
    };

    private _mapEntryList(data) {
        const notes = [];
        const entryList = data['entry-list'];

        for (let i = 0; i < entryList.length; i++) {
            const note = new Entry();
            note.id = entryList[i].id;
            note.entryType = entryList[i]['entry-type'].link.phid;
            const submittedDate = moment.utc(entryList[i]['submitted-date']).format('YYYY-MM-DD HH:mm:ss');
            note.submittedDate = new Date(submittedDate);
            const displayDate = moment.utc(entryList[i]['display-date']).format('YYYY-MM-DD HH:mm:ss');
            note.displayDate = new Date(displayDate);
            note['field'] = entryList[i]['field'];
            notes.push(note);
        }
        return notes;
    }

    private _mapEntityBriefList(response): Array<EntityBrief> {
        const result = new Array<EntityBrief>();
        if (!response || !response['entity-list']) {
            return result;
        }
        try {
            response['entity-list'].forEach(element => {
                const e = EntityBrief.parse(element);
                result.push(e);
            });
            return result;
        } catch (e) {
            console.error(e);
            return result;
        }
    }
}
