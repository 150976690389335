/**
 * ------------------------------
 * Updated by Abner Sui on 06/27/2019
 * Add clearState to clear whole store
 *
 * Updated by Marcus Zhao on 08/18/2020
 * Add contactTemplate for smart Reducer
 */
import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { userReducer } from './reducers/user.reducer';
import { AuthState, authReducer } from './reducers/auth.reducer';
import { SystemUser } from '../tamalelibs/models/user.model';
import { ssoReducer, systemConfigReducer } from './reducers/global-config.reducer';
import { getEntityDetailViewRedcuer } from './reducers/entity-detail-view.reducer';
import { entryTypeReducer } from './reducers/entry-type.reducer';
import { getSystemReducer } from './reducers/system.reducer';
import { getThreadViewRedcuer } from './reducers/thread-view.reducer';
import { relationshipTypeReducer } from './reducers/relationship-type.reducer';
import { notificationReducer } from './reducers/notification.reducer';
import { entityTypeReducer } from './reducers/entity-type.reducer';
import { usersReducer, userSettingReducer } from './reducers/users.reducer';
import { teamsReducer } from './reducers/teams.reducer';
import { screenReducer, getSavedScreenReducer } from './reducers/screen.reducer';
import { noteMsgReducer, removeEntitiesReducer, noteReducer, appendEntitiesReducer } from '../tamalelibs/redux/note.reducer';
import { actionHistoryReducer } from './reducers/action-history.reducer';
import { getEmailDigestReducer } from './reducers/email-digest.reducer';
import { getBlastEmailViewRedcuer } from './reducers/blastemail-view.reducer';
import { getAuditLogRedcuer } from './reducers/audit-log.reducer';
import { SmartReducerService, SmartReducerConfig } from '../tamalelibs/services/smart-reducer.service';
import { researchScreenReducer, researchChangeStatusReducer } from './reducers/research-screen.reducer';
import { getHomeScreenReducer } from './reducers/home-screen.reducer';
import { getAdhocReducer } from './reducers/adhoc.reducer';
import { getDraftViewRedcuer } from './reducers/draft-view.reducer';
import { marketdataReducer } from './reducers/marketdata.reducer';
import { dataproviderReducer } from './reducers/dataprovider.reducer';
import { contactListReducer } from './reducers/contact-list.reducer';
import { ResearchViewConstants, BlastEmailViewConstants, ContactsViewConstants, DashboardViewConstants, FileViewConstants, EntityConfigurationConstants, EntitiesViewConstants, CalendarConstants, ContactConfigurationConstants, CalendarsViewConstants } from '../tamalelibs/models/smart-reducer.model';
import { entityListReducer } from '../tamalelibs/redux/entity-list.reducer';
import { getDashboardScreenRedcuer } from './reducers/dashboard-screen.reducer';
import { getFileViewRedcuer } from './reducers/file-view.reducer';
import { getEntityScreenReducer } from './reducers/entity-screen.reducer';
import { entityTemplatesConfigurationReducer, entryTemplateReducerA, entryTemplatesConfigurationReducer } from '../tamalelibs/redux/template.reducer';
import { subChromeNameReducer } from './reducers/sub-chrome-name.reducer';
import { AuthActionTypes } from './actions/auth.actions';
import { getDashboardRedcuer } from './reducers/dashboard.reducer';
import { getCalendarReducer } from './reducers/calendar.reducer';
import { validRelationshipTypeReducer } from './reducers/valid-relationship-type.reducer';
import { adminGeneralPageReducer, adminPageReducer, adminSharedPageReducer, credentialsReducer } from './reducers/admin-page.reducer';
import { getAutoTagViewRedcuer } from './reducers/auto-tagging-view.reducer';
import { getIntralinksgRedcuer } from '../tamalelibs/redux/intralinks.reducer';
import { getWorkflowReducer } from '../tamalelibs/redux/reducers/workflow.reducer';
import { getWorkflowTaskReducer } from '../tamalelibs/redux/reducers/workflow-task.reducer';
import { getContactRedcuer, contactNewAddedJobTitleValueReducer, duplicatedContactFeedbackControlReducer, contactTemplateConfigReducer } from '../tamalelibs/redux/contact.reducer';
import { getContactDetailViewRedcuer } from './reducers/contact-detail-view.reducer';
import { getflyDialogIndexReducer } from './reducers/fly-dialog.reducer';
import { getEntityRedcuer } from './reducers/entity.reducer';
import { pullingCoordinatesReducer } from '../tamalelibs/redux/map-tile.reducer';
import { mdlTemplateReducer } from './reducers/mdl-template.reducer';
import { entityFieldsReducer, templateFieldsCacheReducer } from './reducers/template-fields.reducer';
import { TemplateField } from '../tamalelibs/models/template-field.model';
import { IMDLTemplate } from '../tamalelibs/models/template-mdl.model';
import { getSignatureReducer } from './reducers/signature.reducer';
import { getCalendarAllListReducer } from './reducers/calendar-list.reducer';
import { portfolioReducer } from './reducers/portfolio.reducer';

export interface AppState {
    actionHistory: any;
    entities: any;
    auth: AuthState;
    autoTag: any;
    draftView: any;
    contactDetailView: any;
    contactNewAddedJobTitle: any;
    credential: any;
    emailDigest: any;
    entityDetailView: any;
    entityType: any;
    entryType: any;
    relationshipType: any;
    notification: any;
    portfolioUpload: any;
    validRelationshipType: any;
    researchView: any;
    screenInfo: any;
    ssoSetting: any;
    systemConfig: any;
    system: any;
    subChromeName: any;
    teams: any;
    threadView: any;
    user: SystemUser;
    users: any;
    userSetting: any;
    blastEmailView: any;
    fileView: any;
    auditLog: any;
    homeScreen: any;
    entityScreen: any;
    adhoc: any;
    marketdata: any;
    manageScreens: any;
    dataprovider: any;
    researchStatus: any;
    savedscreen: any;
    dashboard: any;
    calendar: any;
    processDefinition: any;
    processDefinitionBrief: any;
    note: any;
    noteMsg: any;
    adminPage: any;
    adminGeneralPage: any;
    adminSharedPage: any;
    appendEntities: any;
    workflowTask: any;
    removeEntities: any;
    flyDialogIndex: number;
    signatureData: any;
    entryTemplates: any;
    entityTemplates: any;
    mdlTemplates: Map<string, IMDLTemplate>;
    templateFields: Map<string, Array<TemplateField>>;
}

const service = new SmartReducerService();
export const smartReducerConfigs = [
    new SmartReducerConfig(researchScreenReducer,
        ResearchViewConstants.ReducerName,
        ResearchViewConstants.AddActionTypeName,
        ResearchViewConstants.DeleteActionTypeName,
        ResearchViewConstants.ActionKeyName),
    new SmartReducerConfig(getBlastEmailViewRedcuer,
        BlastEmailViewConstants.ReducerName,
        BlastEmailViewConstants.AddActionTypeName,
        BlastEmailViewConstants.DeleteActionTypeName,
        BlastEmailViewConstants.ActionKeyName,
        (tabName) => 'BlastEmail-' + tabName),
    new SmartReducerConfig(entryTemplateReducerA,
        EntityConfigurationConstants.ReducerName,
        EntityConfigurationConstants.AddActionTypeName,
        EntityConfigurationConstants.DeleteActionTypeName,
        EntityConfigurationConstants.ActionKeyName,
        (tabName) => 'EntryTemplate-' + tabName),
    new SmartReducerConfig(getContactRedcuer,
        ContactConfigurationConstants.ReducerName,
        ContactConfigurationConstants.AddActionTypeName,
        ContactConfigurationConstants.DeleteActionTypeName,
        ContactConfigurationConstants.ActionKeyName,
        (tabName) => 'ContactTemplate-' + tabName),
    new SmartReducerConfig(getFileViewRedcuer,
        FileViewConstants.ReducerName,
        FileViewConstants.AddActionTypeName,
        FileViewConstants.DeleteActionTypeName,
        FileViewConstants.ActionKeyName,
        (tabName) => 'FileView-' + tabName),
    new SmartReducerConfig(contactListReducer,
        ContactsViewConstants.ReducerName,
        ContactsViewConstants.AddActionTypeName,
        ContactsViewConstants.DeleteActionTypeName,
        ContactsViewConstants.ActionKeyName,
        (tabName) => 'Contacts-' + tabName),
    new SmartReducerConfig(getCalendarAllListReducer,
        CalendarsViewConstants.ReducerName,
        CalendarsViewConstants.AddActionTypeName,
        CalendarsViewConstants.DeleteActionTypeName,
        CalendarsViewConstants.ActionKeyName,
        (tabName) => 'Calendars-' + tabName),
    new SmartReducerConfig(entityListReducer,
        EntitiesViewConstants.ReducerName,
        EntitiesViewConstants.AddActionTypeName,
        EntitiesViewConstants.DeleteActionTypeName,
        EntitiesViewConstants.ActionKeyName,
        (tabName) => 'Entities-' + tabName),
    new SmartReducerConfig(getDashboardScreenRedcuer,
        DashboardViewConstants.ReducerName,
        DashboardViewConstants.AddActionTypeName,
        DashboardViewConstants.DeleteActionTypeName,
        DashboardViewConstants.ActionKeyName),
    new SmartReducerConfig(getCalendarReducer,
        CalendarConstants.ReducerName,
        CalendarConstants.AddActionTypeName,
        CalendarConstants.DeleteActionTypeName,
        CalendarConstants.ActionKeyName,
        (tabName) => 'Calendar-' + tabName),
];
const smartReducers = service.generate(smartReducerConfigs);
const originalReducerMap = new Map<string, any>();
// currently non-dynamic research page is also under dynamicPages, so this original reducer is never used.
originalReducerMap.set(ResearchViewConstants.ReducerName, (state) => state[ResearchViewConstants.OriginalParentStateName]);
originalReducerMap.set(BlastEmailViewConstants.ReducerName, (state) => state[BlastEmailViewConstants.OriginalParentStateName]);
originalReducerMap.set(FileViewConstants.ReducerName, (state) => state[FileViewConstants.OriginalParentStateName]);
originalReducerMap.set(ContactsViewConstants.ReducerName, (state) => state[ContactsViewConstants.OriginalParentStateName]);
originalReducerMap.set(CalendarsViewConstants.ReducerName, (state) => state[CalendarsViewConstants.OriginalParentStateName]);
originalReducerMap.set(EntitiesViewConstants.ReducerName, (state) => state[EntitiesViewConstants.OriginalParentStateName]);
originalReducerMap.set(EntityConfigurationConstants.ReducerName, (state) => state[EntityConfigurationConstants.OriginalParentStateName]);
originalReducerMap.set(ContactConfigurationConstants.ReducerName, (state) => state[ContactConfigurationConstants.OriginalParentStateName]);
originalReducerMap.set(CalendarConstants.ReducerName, (state) => state[CalendarConstants.OriginalParentStateName]);
export const smartSelector = smartReducers.getSmartSelector(originalReducerMap, (state) => state['dynamicPages']);
export const smartEffect = smartReducers.getSmartEffectAction;

export const rootReducer = {
    actionHistory: actionHistoryReducer,
    entities: getEntityRedcuer,
    auth: authReducer,
    autoTag: getAutoTagViewRedcuer,
    adhoc: getAdhocReducer,
    calendar: smartReducers.originalReducers[CalendarConstants.ReducerName],
    contactList: smartReducers.originalReducers[ContactsViewConstants.ReducerName],
    calendarList: smartReducers.originalReducers[CalendarsViewConstants.ReducerName],
    contactDetailView: getContactDetailViewRedcuer,
    contactTemplate: smartReducers.originalReducers[ContactConfigurationConstants.ReducerName],
    contactNewAddedJobTitle: contactNewAddedJobTitleValueReducer,
    duplicatedContactFeedbackControl: duplicatedContactFeedbackControlReducer,
    contactTemplateConfig: contactTemplateConfigReducer,
    draftView: getDraftViewRedcuer,
    entityDetailView: getEntityDetailViewRedcuer,
    entityList: smartReducers.originalReducers[EntitiesViewConstants.ReducerName],
    entityType: entityTypeReducer,
    entryType: entryTypeReducer,
    emailDigest: getEmailDigestReducer,
    relationshipType: relationshipTypeReducer,
    notification: notificationReducer,
    portfolioUpload: portfolioReducer,
    validRelationshipType: validRelationshipTypeReducer,
    screenInfo: screenReducer,
    ssoSetting: ssoReducer, // TODO will delete this use systemConfig
    systemConfig: systemConfigReducer,
    system: getSystemReducer,
    subChromeName: subChromeNameReducer, // to change the sub-chrome name for livewire
    teams: teamsReducer,
    threadView: getThreadViewRedcuer,
    credential: credentialsReducer,
    user: userReducer,
    users: usersReducer,
    userSetting: userSettingReducer,
    note: noteReducer,
    noteMsg: noteMsgReducer,
    blastEmailView: smartReducers.originalReducers[BlastEmailViewConstants.ReducerName],
    entryTemplate: smartReducers.originalReducers[EntityConfigurationConstants.ReducerName],
    fileView: smartReducers.originalReducers[FileViewConstants.ReducerName],
    auditLog: getAuditLogRedcuer,
    dynamicPages: smartReducers.smartReducer,
    homeScreen: getHomeScreenReducer,
    entityScreen: getEntityScreenReducer,
    marketdata: marketdataReducer,
    dataprovider: dataproviderReducer,
    researchStatus: researchChangeStatusReducer,
    savedscreen: getSavedScreenReducer,
    dashboard: getDashboardRedcuer,
    adminPage: adminPageReducer,
    adminGeneralPage: adminGeneralPageReducer,
    adminSharedPage: adminSharedPageReducer,
    intralink: getIntralinksgRedcuer,
    workflow: getWorkflowReducer,
    workflowTask: getWorkflowTaskReducer,
    removeEntities: removeEntitiesReducer,
    appendEntities: appendEntitiesReducer,
    flyDialogIndex: getflyDialogIndexReducer,
    signatureData: getSignatureReducer,
    entryTemplates: entryTemplatesConfigurationReducer,
    entityTemplates: entityTemplatesConfigurationReducer,
    pullingCoordinates: pullingCoordinatesReducer,
    mdlTemplates: mdlTemplateReducer,
    templateFields: templateFieldsCacheReducer,
    entityFields: entityFieldsReducer
};

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('Registered Reducers');
export function getRootReducers() {
    return rootReducer;
}
export function clearState(reducer) {
    return function (state, action) {
        if (action.type === AuthActionTypes.LOGOUT) {
            state = undefined;
        }
        return reducer(state, action);
    };
}
