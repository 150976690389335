
/**
 * Created by Ella Ma on 4/21/20.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import {
    TamDashboardActions
} from '../../components/tam-dashboard/tam-dashboard.model';
import { QueryService } from '../../services/query.service';
import { take, switchMap, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../redux';
import { getNoteInfoSelector } from '../../redux/reducers/dashboard.reducer';
import { NoteOpType } from './dashboard.model';

@Injectable({
    providedIn: 'root'
})

export class DashboardGridNoteService {

    private _actionSubject$: Subject<any>;
    private _tileConfig;
    private _tileId: string;
    private _noteEntityIds = [];
    private _tileEntityIds;
    private _newFocusIds = [];

    constructor(
        private _queryService: QueryService,
        private _store: Store<AppState>,
    ) { }

    updateGridRow(tileId, actionSubject$, tileConfig, message, tileFocusedEntityIds, notePreviousEntityIds, gridFilterParams) {
        this._tileId = tileId;
        this._actionSubject$ = actionSubject$;
        this._tileConfig = tileConfig;
        this._noteEntityIds = [];
        this._tileEntityIds = null;
        this._newFocusIds = [];
        this._formatNoteEntityIds(message, tileFocusedEntityIds, notePreviousEntityIds, gridFilterParams);
    }

    private _formatNoteEntityIds(message, tileFocusedEntityIds, notePreviousEntityIds = [], gridFilterParams = null) {
        const observable = new Observable(subscriber => {
            subscriber.next();
            subscriber.complete();
        });
        observable.pipe(
            take(1),
            switchMap(() => {
                /**
                 * tile entityIds = [a,b,c]
                 * create note -> entityIds=([]∪[a,d])∩[a,b,c] -> noteEntityIds=[a]
                 * delete note -> entityIds=([a,c]∪[])∩[a,b,c] -> noteEntityIds=[a,c]
                 * edit note -> entityIds=[a,c]->[a,d] ->([a,c]∪[a,d])∩[a,b,c] ->noteEntityIds=[a,c]
                 */
                if (message === NoteOpType.CreateNote) {
                    return this._store.pipe(
                        take(1),
                        select(getNoteInfoSelector),
                        map((entityIds) => {
                            return entityIds.split(';');
                        })
                    );
                } else if (message === NoteOpType.DeleteNote ||
                    message === NoteOpType.DeleteSideNote ||
                    message === NoteOpType.DeleteEvent) {
                    return of(notePreviousEntityIds);
                } else if (message === NoteOpType.EditNote ||
                    message === NoteOpType.EditSideNote ||
                    message === NoteOpType.EditEvent) {
                    return this._store.pipe(
                        take(1),
                        select(getNoteInfoSelector),
                        map((entityIds) => {
                            const entityIdArr = entityIds.split(';');
                            return entityIdArr.concat(notePreviousEntityIds);
                        })
                    );
                }
                return of([]);
            }),
            map(entityIds => {
                this._noteEntityIds = Array.from(new Set(entityIds));
                return this._noteEntityIds;
            }),
            map(() => {
                if (tileFocusedEntityIds) {
                    if (tileFocusedEntityIds !== 'all') {
                        this._tileEntityIds = tileFocusedEntityIds.split(',');
                    } else {
                        this._tileEntityIds = 'all';
                    }
                }
                return this._tileEntityIds;
            }),
            switchMap(() => {
                if (this._tileEntityIds === 'all') {
                    this._newFocusIds = [].concat(this._noteEntityIds);
                } else if (this._tileEntityIds && this._tileEntityIds.length > 0 && this._noteEntityIds && this._noteEntityIds.length > 0) {
                    this._tileEntityIds.forEach((id) => {
                        if (this._noteEntityIds.indexOf(id) !== -1) {
                            this._newFocusIds.push(id);
                        }
                    });
                }
                this._newFocusIds = this._newFocusIds.filter(id => id !== '');
                if (this._newFocusIds.length > 0) {
                    return this._queryService.executeQueryForGridTile(this._tileConfig, this._newFocusIds.join(','), false, gridFilterParams)
                        .pipe(take(1));
                }
                return of(null);
            })
        ).subscribe((data) => {
            if (data) {
                this._actionSubject$.next({
                    type: TamDashboardActions.UPDATE_GRID_ROW_DATA,
                    id: this._tileId,
                    payload: {
                        removeEntityIds: this._newFocusIds,
                        addRows: data
                    }
                });
            }
        });
    }
}
