export enum FormulaBuilderEventType {
    saveFormula,
    saveConditionalFormatting,
    cancel
}
export enum FormulaBuilderActionType {
    openDialog
}

export class Config {
    sourceId: string;
    formula: string;
    formulaForData: string;
    fields: Field[];
    validLoop?: boolean;
    isConditionalFormatting?: boolean;
    constructor() {
        this.formula = '';
        this.formulaForData = '';
        this.fields = [];
        this.validLoop = false;
        this.isConditionalFormatting = false;
    }
}

// TODO
export interface Field {
    title: string;
    guid: string;
}
