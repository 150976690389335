import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PluginErrorComponent } from '../plugin-error/plugin-error.component';

import { LoginComponent } from './login.component';
import { LoginGuard, AdminLoginGuard } from './login.guard';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'webplugin/:id',
        component: LoginComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'webplugin',
        component: LoginComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'plugin-error',
        component: PluginErrorComponent,
    },
    {
        path: 'admin-login',
        component: LoginComponent,
        canActivate: [AdminLoginGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule { }
