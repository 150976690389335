<div class="container">
    <field-config-base #base [config]="config" [dataType]="dataType" isReadonlyVisible=true></field-config-base>
    <div class="tam-form-field one-field">
        <label tam-checkbox class="tam-textbox field-control" [(ngModel)]="config.separator"
            (checkedChange)="valueChangeSeparator($event)">
            Separator
        </label>
    </div>
    <div class="tam-form-field one-field">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                Decimals
            </div>
        </div>
        <kendo-combobox #decimalskendo class="tam-textbox field-control" [popupSettings]="{appendTo: 'component'}"
            [data]="decimalsData" [filterable]="false" [(ngModel)]="config.decimals"
            (valueChange)="valueChangeDecimals($event)" (focus)="focusDecimals()">
        </kendo-combobox>
    </div>
    <div class="tam-form-field one-field" tid="min_value">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                Minimum value
            </div>
        </div>
        <kendo-numerictextbox #minkendo class="tam-textbox field-control" [value]="config.min" [max]="config.max"
            [autoCorrect]="true" [format]="format" [spinners]="false" [step]="0" (valueChange)="valueChangeMin($event)"
            (keyup)="onKeyupMin($event)" (blur)="blur($event)">
        </kendo-numerictextbox>
    </div>
    <div class="tam-form-field one-field" tid="max_value">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                Maximum value
            </div>
        </div>
        <kendo-numerictextbox #maxkendo class="tam-textbox field-control" [value]="config.max" [min]="config.min"
            [autoCorrect]="true" [format]="format" [spinners]="false" [step]="0" (valueChange)="valueChangeMax($event)"
            (keyup)="onKeyupMax($event)" (blur)="blur($event)">
        </kendo-numerictextbox>
    </div>
    <div class="tam-form-field one-field" tid="default_value">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                Default value
            </div>
        </div>
        <kendo-numerictextbox #staticDefaultValuekendo class="tam-textbox field-control"
            [value]="config.staticDefaultValue" [min]="config.min" [max]="config.max" [autoCorrect]="true"
            [format]="format" [spinners]="false" [step]="0" (valueChange)="valueChangeStaticDefaultValue($event)"
            (keyup)="onKeyupStaticDefaultValue($event)">
        </kendo-numerictextbox>
    </div>
</div>