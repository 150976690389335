/**
 * Created by Abner Sui on 02/21/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { RefinebySubConfig, RefinebyGroup, RefinebyType, RefinebyStatus } from '../widgets/refineby/refineby-view.model';
import { Adhoc } from '../tamalelibs/models/adhoc.model';
import { RefinebyService } from './refineby.service';
import { ArrayHelperService } from '../tamalelibs/services/array-helper.service';

@Injectable()
export class ResearchRefinebyService {

    constructor(
        private _refinebyService: RefinebyService,
    ) { }

    private _standardCategory: Array<string> = ['noteType', 'source', 'displayDate', 'submitter', 'entities', 'priority', 'sentiment', 'submittedDate', 'lastEditedDate', 'sidenote', 'attachment'];
    private _defaultCategory: Array<string> = ['noteType', 'source', 'displayDate', 'submitter', 'submittedDate', 'attachment'];

    get DefaultCategory(): Array<string> {
        return this._defaultCategory;
    }

    get StandardCategory(): Array<string> {
        return this._standardCategory;
    }

    public generateSubConfig(id: string, data: Map<string, Array<any>>, selectedValue: Map<string, Array<any>>, status: Map<string, RefinebyStatus>, adhocTitle: Map<string, Adhoc>): RefinebySubConfig {
        let subConfig: RefinebySubConfig = null;
        const options: Array<any> = [];
        const oneOptions: Array<any> = data.get(id);
        if (oneOptions) {
            options.push(...oneOptions);
        }
        switch (id) {
            case 'noteType':
                subConfig = new RefinebySubConfig(id, 'Note Type');
                break;
            case 'source':
                subConfig = new RefinebySubConfig(id, 'Source', RefinebyGroup.standard, RefinebyType.entity);
                break;
            case 'displayDate':
                subConfig = new RefinebySubConfig(id, 'Display Date', RefinebyGroup.standard, RefinebyType.date);
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'submitter':
                subConfig = new RefinebySubConfig(id, 'Submitter', RefinebyGroup.standard, RefinebyType.entity);
                break;
            case 'entities':
                subConfig = new RefinebySubConfig(id, 'Entities', RefinebyGroup.standard, RefinebyType.entity);
                break;
            case 'priority':
                subConfig = new RefinebySubConfig(id, 'Priority', RefinebyGroup.standard, RefinebyType.priority);
                break;
            case 'sentiment':
                subConfig = new RefinebySubConfig(id, 'Sentiment');
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'submittedDate':
                subConfig = new RefinebySubConfig(id, 'Submitted Date', RefinebyGroup.standard, RefinebyType.date);
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'lastEditedDate':
                subConfig = new RefinebySubConfig(id, 'Last Modified Date', RefinebyGroup.standard, RefinebyType.date);
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'sidenote':
                subConfig = new RefinebySubConfig(id, 'Sidenotes');
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'attachment':
                subConfig = new RefinebySubConfig(id, 'Attachments');
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            default:
                subConfig = null;
                if (adhocTitle) {
                    const adhoc = adhocTitle.get(id);
                    if (adhoc) {
                        let refinebyType = RefinebyType.object;
                        if (options && options.length > 0) {
                            if (options[0].hasOwnProperty('value')) {
                                refinebyType = RefinebyType.text;
                            } else if (options[0].hasOwnProperty('shortName')) {
                                refinebyType = RefinebyType.entity;
                            } else {
                                refinebyType = RefinebyType.date;
                                options.forEach(option => {
                                    option.name = this.getItemDisplayName('Date', option.id);
                                });
                            }
                        } else {
                            if (adhoc.dataType === 'date') {
                                refinebyType = RefinebyType.date;
                            } else if (adhoc.dataType === 'entity') {
                                refinebyType = RefinebyType.entity;
                            } else if (adhoc.dataType === 'text') {
                                refinebyType = RefinebyType.text;
                            }
                        }
                        subConfig = new RefinebySubConfig(id, adhoc.name, RefinebyGroup.adhoc, refinebyType);
                    }
                }
                break;
        }
        if (subConfig) {
            const subStatus: RefinebyStatus = status.get(id);
            if (subStatus) {
                subConfig.status = subStatus;
            }
            if (options) {
                subConfig.options = options;
            }
            const selectedOptions: Array<any> = selectedValue.get(id);
            if (selectedOptions) {
                subConfig.selectedOptions = selectedOptions;
            }
            subConfig.defaultIndex = this.StandardCategory.indexOf(id);
        }
        return subConfig;
    }

    public getItemDisplayName(categoryId: string, itemId: string): string {
        let result = '';
        switch (categoryId) {
            case 'displayDate':
            case 'submittedDate':
            case 'lastEditedDate':
            case 'Date':
                switch (itemId) {
                    case '0':
                        result = 'Today';
                        break;
                    case '1':
                        result = 'Last 7 days';
                        break;
                    case '2':
                        result = 'Last 30 days';
                        break;
                    case '3':
                        result = 'Older';
                        break;
                    default:
                        break;
                }
                break;
            case 'sentiment':
                switch (itemId) {
                    case '-1':
                        result = 'Negative';
                        break;
                    case '0':
                        result = 'Neutral';
                        break;
                    case '1':
                        result = 'Positive';
                        break;
                    default:
                        break;
                }
                break;
            case 'sidenote':
                switch (itemId) {
                    case '0':
                        result = 'No Sidenote';
                        break;
                    case '1':
                        result = 'Has Sidenote(s)';
                        break;
                    default:
                        break;
                }
                break;
            case 'attachment':
                switch (itemId) {
                    case '0':
                        result = 'No Attachment';
                        break;
                    case '1':
                        result = 'Has Attachment(s)';
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        return result;
    }

    public updateSubConfig(config: RefinebySubConfig, data: Map<string, Array<any>>, selectedValue: Map<string, Array<any>>, status: Map<string, RefinebyStatus>, showAsName: boolean): void {
        const id = config.categoryId;
        const options: Array<any> = [];
        const oneOptions: Array<any> = data.get(id);
        if (oneOptions) {
            options.push(...oneOptions);
        }
        let sortField: string;
        let keyField = 'id';
        if (showAsName) {
            sortField = 'longName';
        } else {
            sortField = 'shortName';
        }
        switch (id) {
            case 'noteType':
                sortField = 'name';
                break;
            case 'source':
                break;
            case 'displayDate':
                sortField = 'id';
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'submitter':
                break;
            case 'entities':
                break;
            case 'priority':
                sortField = 'id';
                break;
            case 'sentiment':
                sortField = 'name';
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'submittedDate':
                sortField = 'id';
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'lastEditedDate':
                sortField = 'id';
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'sidenote':
                sortField = 'name';
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'attachment':
                sortField = 'name';
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            default:
                let refinebyType = RefinebyType.object;
                sortField = 'id';
                if (options && options.length > 0) {
                    if (options[0].hasOwnProperty('value')) {
                        refinebyType = RefinebyType.text;
                        sortField = 'value';
                        keyField = 'value';
                    } else if (options[0].hasOwnProperty('shortName')) {
                        refinebyType = RefinebyType.entity;
                        if (showAsName) {
                            sortField = 'longName';
                        } else {
                            sortField = 'shortName';
                        }
                    } else {
                        refinebyType = RefinebyType.date;
                        sortField = 'id';
                        options.forEach(option => {
                            option.name = this.getItemDisplayName('Date', option.id);
                        });
                    }
                }
                break;
        }
        const subStatus: RefinebyStatus = status.get(id);
        if (subStatus) {
            config.status = subStatus;
        }
        const selectedOptions: Array<any> = selectedValue.get(id);
        if (selectedOptions) {
            selectedOptions.forEach(option => {
                option.checked = true;
                const index = options.findIndex(item => item[keyField] === option[keyField]);
                if (index > -1) {
                    option.count = options[index].count;
                    options[index].checked = true;
                    if (config.type !== RefinebyType.date && config.type !== RefinebyType.priority) {
                        options.splice(index, 1);
                    }
                } else {
                    option.count = 0;
                }
            });
        }
        if (options) {
            options.sort((a, b) => {
                const alow = a[sortField].toLowerCase();
                const blow = b[sortField].toLowerCase();
                return alow === blow ? 0 : (alow > blow ? 1 : -1);
            });
            if (config.type === RefinebyType.date) {
                this._refinebyService.handleRefinebyDate(options);
            }
            config.options.splice(0, config.options.length);
            config.options.push(...options);
        }
    }

}
