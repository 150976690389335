/**
 * Created by Jiu Chen on 12/03/2018.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum EmailDigestActionTypes {
    LOAD = '[EMAILDIGEST] LOAD',
    LOAD_SUCCESS = '[EMAILDIGEST] LOAD_SUCCESS',
    LOAD_FAILED = '[EMAILDIGEST] LOAD_FAILED',
    ADD_CONFIG = '[EMAILDIGEST] ADD_CONFIG',
    UPDATE_CONFIG = '[EMAILDIGEST] UPDATE_CONFIG',
    DELETE_CONFIG = '[EMAILDIGEST] DELETE_CONFIG',
    SWITCH_SUBSCRIPTION = '[EMAILDIGEST] SUBSCRIPTION',
    SWITCH_SUBSCRIPTION_SUCCESS = '[EMAILDIGEST] SUBSCRIPTION_SUCCESS',
    SWITCH_SUBSCRIPTION_FAILED = '[EMAILDIGEST] SUBSCRIPTION_FAILED',
    SWITCH_ACTIVE = '[EMAILDIGEST] ACTIVE',
    SWITCH_ACTIVE_SUCCESS = '[EMAILDIGEST] ACTIVE_SUCCESS',
    SWITCH_ACTIVE_FAILED = '[EMAILDIGEST] ACTIVE_FAILED',
    SET_CONFIG = '[EMAILDIGEST] SET_CONFIG',
    CLEAR_CONFIG = '[EMAILDIGEST] CLEAR_CONFIG',
    GET_EMAIL_DIGEST_CONFIG = '[EMAILDIGEST] GET_EMAIL_DIGEST_CONFIG',
    GET_EMAIL_DIGEST_CONFIG_SUCCESS = '[EMAILDIGEST] GET_EMAIL_DIGEST_CONFIG_SUCCESS',
    GET_EMAIL_DIGEST_CONFIG_FAILED = '[EMAILDIGEST] GET_EMAIL_DIGEST_CONFIG_FAILED',
}

export class Load implements Action {
    readonly type = EmailDigestActionTypes.LOAD;
}

export class LoadSuccess implements Action {
    readonly type = EmailDigestActionTypes.LOAD_SUCCESS;

    constructor(public payload: any) { }
}

export class LoadFailed implements Action {
    readonly type = EmailDigestActionTypes.LOAD_FAILED;

    constructor(public payload: any) { }
}

export class Add implements Action {
    readonly type = EmailDigestActionTypes.ADD_CONFIG;

    constructor(public payload: any) { }
}

export class Update implements Action {
    readonly type = EmailDigestActionTypes.UPDATE_CONFIG;

    constructor(public payload: any) { }
}

export class Delete implements Action {
    readonly type = EmailDigestActionTypes.DELETE_CONFIG;

    constructor(public payload: any) { }
}

export class UpdateSubscription implements Action {
    readonly type = EmailDigestActionTypes.SWITCH_SUBSCRIPTION;
    constructor(public payload: any) { }
}

export class UpdateSubscriptionSuccess implements Action {
    readonly type = EmailDigestActionTypes.SWITCH_SUBSCRIPTION_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateSubscriptionFailed implements Action {
    readonly type = EmailDigestActionTypes.SWITCH_SUBSCRIPTION_FAILED;
    constructor(public payload: any) { }
}

export class UpdateActive implements Action {
    readonly type = EmailDigestActionTypes.SWITCH_ACTIVE;
    constructor(public payload: any) { }
}

export class UpdateActiveSuccess implements Action {
    readonly type = EmailDigestActionTypes.SWITCH_ACTIVE_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateActiveFailed implements Action {
    readonly type = EmailDigestActionTypes.SWITCH_ACTIVE_FAILED;
    constructor(public payload: any) { }
}

export class SetConfig implements Action {
    readonly type = EmailDigestActionTypes.SET_CONFIG;
    constructor(public payload: any) { }
}

export class ClearConfig implements Action {
    readonly type = EmailDigestActionTypes.CLEAR_CONFIG;
    constructor(public payload: any) { }
}

export class GetOneConfig implements Action {
    readonly type = EmailDigestActionTypes.GET_EMAIL_DIGEST_CONFIG;
    constructor(public payload: any) { }
}

export class GetOneConfigSuccess implements Action {
    readonly type = EmailDigestActionTypes.GET_EMAIL_DIGEST_CONFIG_SUCCESS;
    constructor(public payload: any) { }
}

export class GetOneConfigFailed implements Action {
    readonly type = EmailDigestActionTypes.GET_EMAIL_DIGEST_CONFIG_FAILED;
    constructor(public payload: any) { }
}

export type EmailDigestActions =
    Load |
    LoadSuccess |
    LoadFailed |
    Add |
    Update |
    Delete |
    UpdateSubscription |
    UpdateSubscriptionSuccess |
    UpdateSubscriptionFailed |
    UpdateActive |
    UpdateActiveSuccess |
    UpdateActiveFailed |
    SetConfig |
    ClearConfig |
    GetOneConfig |
    GetOneConfigSuccess |
    GetOneConfigFailed;
