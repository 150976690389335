/**
 * Created by Bowen on 10/20/2020.
 * Description:
 * Duplicated contact dialog
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DuplicatedContactDialogService {
    dialogOpen$: Subject<any> = new Subject<any>();
}
