/**
 * Created by Abner Sui on 12/13/2018.
 * Description:
 *  Some constants
 *  Copy logic code from Tamale Web 1.0, bizConstants.js
 * ------ maintenance history ------
 */
export const bizConstants = {
    // #region Template
    // standardControlID
    standardControlID: {
        entity: 'ENTITIES',
        source: 'SOURCE',
        displaydate: 'DISPLAYDATE',
        sentiment: 'SENTIMENT',
        priority: 'PRIORITY',
        subject: 'SUBJECT',
        notebody: 'BODY',

        name: 'NAME',
        shortname: 'SHORTNAME'
    },
    // separator
    separator: {
        entitySeparator: ';',
        commonSeparator: '_',
        propertySeparator: '-',
        defaultEntitySeparatorInSubject: ' '
    },
    // subject saving option
    subjectSavingOption: {
        always: 'ALWAYS',
        neverUseDefaultBehavior: 'NEVER_USE_DEFAULT_BEHAVIOR',
        onlyWhenUserHasNotEnteredNoteSubject: 'ONLY_WHEN_USER_HAS_NOT_ENTERED_NOTE_SUBJECT'
    },
    // standard control id in formula
    standardControlInFormula: {
        entity: '[Entities]',
        source: '[Source]'
    },
    VALUEERROR: new Error('#TMLVALUE?'),
    // #endregion Template
    blankValue: '(Blanks)'
};
