/**
 * created by Yoyo Fang on 04/09/19.
 * Description:
 * According to Simple Search requirement, shows search history before search result returned from api
 * subscribe() first, then do search(). reloadHistory() when needed
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EntryService } from './entry.service';
import { ContactService } from './contact.service';
import { EntityService } from './entity.service';

export enum SimpleSearchResultType {
    HISTORY_SEARCH = 'HISTORY',
    ATTACHMENT_SEARCH = 'ATTACHMENT_SEARCH',
    ENTITY_SEARCH = 'ENTITY_SEARCH',
    CONTACT_SEARCH = 'CONTACT_SEARCH',
    THREAD_SEARCH = 'THREAD_SEARCH'
}
export class SimpleSearchResult {
    public processing: boolean;
    constructor(public type: SimpleSearchResultType, public payload: any[] | Array<any>,
        public keyword: string = '', taskCount: number = 0) {
        this.processing = (taskCount > 0);
    }
}

@Injectable()
export class SimpleSearchService {

    constructor(private _entryService: EntryService, private _contactService: ContactService, private _entityService: EntityService) {
        this.reloadHistory();
        this._searchResult$ = new Subject<SimpleSearchResult>();
    }
    private _search = { attachment: false, thread: false, contact: false, entity: true };
    private _history: any[] = [];
    private _searchResult$: Subject<SimpleSearchResult>;
    // TODO: YOYO should make 4 loads for attachment, contact, entity, thread
    public search(keyword: string) {
        let countdown = 1;
        if (keyword && keyword.length) {
            if (this._search['attachment']) {
                countdown++;
                this._entryService.searchAttachments(keyword).subscribe((result) => {
                    const attachments = this._entryService.mapEntryList(result);
                    this._searchResult$.next(new SimpleSearchResult(SimpleSearchResultType.ATTACHMENT_SEARCH, attachments,
                        keyword, --countdown));
                });
            }
            if (this._search['contact']) {
                countdown++;
                this._contactService.getContactList(1, 5, keyword, 'entity').subscribe((result) => {
                    const contactList = this._contactService.mapContactList(result);
                    this._searchResult$.next(new SimpleSearchResult(SimpleSearchResultType.CONTACT_SEARCH, contactList.contacts,
                        keyword, --countdown));
                });
            }
            if (this._search['entity']) {
                countdown++;
                this._entityService.getEntityListBySearchTextQuick(1, 10, keyword).subscribe((result) => {
                    const entityBrief = this._entityService.mapEntityBriefList(result);
                    this._searchResult$.next(new SimpleSearchResult(SimpleSearchResultType.ENTITY_SEARCH, entityBrief,
                        keyword, --countdown));
                });
            }

            const filteredHistory = this._history.filter((s) => s.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);
            this._searchResult$.next(new SimpleSearchResult(SimpleSearchResultType.HISTORY_SEARCH, filteredHistory, keyword, --countdown));
        } else {
            this._searchResult$.next(new SimpleSearchResult(SimpleSearchResultType.HISTORY_SEARCH, this._history, keyword, --countdown));
        }
    }

    public reloadHistory(refreshAfterReload: boolean = false) {
        // TODO:YOYO should change this hard coded array to search for history Record
        const record = {
            company: 'test company',
            title: 'test title',
            name: 'test name',
            shortName: 'test shortname',
            type: { id: 'test type.id', name: 'test type.name' }
        };
        this._history = []; // [record];
        if (refreshAfterReload) {
            this._searchResult$.next(new SimpleSearchResult(SimpleSearchResultType.HISTORY_SEARCH, this._history));
        }
    }

    public subscribe(callbackFn) {
        return this._searchResult$.subscribe(callbackFn);
    }

}
