/**
 * Created by Marcus Zhao on 6/19/19.
 * Description: samrt Action .
 * TODO: will be change after coder refactor
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../tamalelibs/redux/app.state';
import { GET_ENTITY } from '../../tamalelibs/redux/app.actions';
import { ClearEntityMsg, ClearEntityTemplateConfig, CreateEntity, EditEntity, CreateExtraOrOffice, CreateMembers, GetEntity, GetTemplateConfig } from '../../redux/actions/template-web.actions';

@Injectable()
export class EntityViewModelForWeb {
    constructor(private _store: Store<IAppState>) { }

    createEntity(params, updateTopicMember, prefixId: string = '') {
        if (params) {
            this._store.dispatch(
                new CreateEntity(
                    {
                        params: params,
                        updateTopicMember: updateTopicMember
                    },
                    'prefix',
                    prefixId
                )
            );
        }
    }
    editEntity(params, updateTopicMember, prefixId: string = '') {
        if (params) {
            this._store.dispatch(
                new EditEntity(
                    {
                        params: params,
                        updateTopicMember: updateTopicMember
                    },
                    'prefix',
                    prefixId
                )
            );
        }
    }
    createExtraOrOfficer(params, prefixId: string = '') {
        if (params) {
            this._store.dispatch(
                new CreateExtraOrOffice(
                    { params: params },
                    'prefix',
                    prefixId
                )
            );
        }
    }

    createMembers(params, prefixId: string = '') {
        if (params) {
            this._store.dispatch(
                new CreateMembers(
                    { params: params },
                    'prefix',
                    prefixId
                )
            );
        }
    }

    getEntity(id: string = '', prefixId: string = '') {
        if (id.length > 0) {
            this._store.dispatch({
                type: GET_ENTITY,
                payload: {
                    id: id
                }
            });
        }
    }

    getTemplateConfig(entityTypeId: string = '', prefixId: string = '') {
        if (entityTypeId.length > 0) {
            this._store.dispatch(
                new GetTemplateConfig(
                    {
                        entityTypeId: entityTypeId
                    },
                    'prefix',
                    prefixId
                )
            );
        }
    }

    clearEntityTemplate(prefixId: string) {
        this.clearEntityMsg(prefixId);
        this.clearEntiyConfiguration(prefixId);
    }

    clearEntityMsg(prefixId: string = '') {
        this._store.dispatch(
            new ClearEntityMsg(
                '',
                'prefix',
                prefixId
            )
        );
    }

    clearEntiyConfiguration(prefixId: string = '') {
        this._store.dispatch(
            new ClearEntityTemplateConfig(
                '',
                'prefix',
                prefixId
            )
        );
    }
}
