/**
 * Create by Alex
 * Description:
 *
 * ------ maintenance history ------
 * 2024/8/22 added to support impacted configurations
 */

import { businessConstants } from '../constants/business.constants';

export class ImpactedEmailDigestConfig {
    name: string;
    type: string;
    setting: string;

    /**
     * Parse the impacted config data from response coming from "email digest or dashboard" endpoint.
     * @param serverResponse
     * @returns A array of emaildigests
     */
    static parse(serverResponse): Array<ImpactedEmailDigestConfig> {
        const serverRes = serverResponse['emaildigest-list'];
        if (!serverRes) {
            return null;
        }
        const impactedConfigs: Array<ImpactedEmailDigestConfig> = new Array<ImpactedEmailDigestConfig>();

        if (serverRes && serverRes.length > 0) {
            serverRes.forEach(item => {
                impactedConfigs.push(
                    {
                        name: item.name,
                        type: businessConstants.impactedConfigType.emailDigest,
                        setting: item.field
                    }
                );
            });
        }
        return impactedConfigs;
    }
}
