/**
 * ------ maintenance history ------
 * Updated by Abner Sui on 05/08/2019.
 * Add init piwik
 * Updated by Abner Sui on 05/28/2019.
 * refactor init piwik
 * 06/20/2019   Yoyo Fang   Add loading default spinner and related display control
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { ProtocolConfig } from './tamalelibs/models/protocol-config.model';
import { AppConfig } from './tamalelibs/models/app-config.model';
import { AppState } from './redux';
import { SsoSetting } from './models/global-setting';
import { AuthService } from './tamalelibs/services/auth.service';
import { AdminUserNames, businessConstants, SSOCookiesKey } from './constants/business.constants';
import { StorageService } from './services/storage.service';
import { PluginService } from './services/plugin.service';
import { AddInGlobalModel, AddInDepositType, AddInOnTheFlyModel } from './models/addin.model';
import { AddInUtilities } from './services/utilities/addin-utilities';
import { GlobalService } from './tamalelibs/services/global.service';

@Component({
    selector: 'tam-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
    private _ssoSetting: SsoSetting;
    private _destroySubscriptions: Array<Subscription> = [];
    loading = true;
    constructor(
        private _router: Router,
        private _store: Store<AppState>,
        private _authService: AuthService,
        private _globalService: GlobalService,
        private _storageService: StorageService,
        private _pluginService: PluginService,
        private _cookieService: CookieService,
    ) {
        ProtocolConfig.prefix = '';
        AppConfig.serverAddress = '';
        AppConfig.supportImgToStream = false;
        AppConfig.tokenEndpoint = '/restapi/2.0/token/';
        AppConfig.defaultRestapiTimeoutSpan = 60000;
        const routerSubscription = this._router.events.subscribe((event) => {
            if (!this.loading) {
                routerSubscription.unsubscribe();
            }
            switch (this.loading) {
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError:
                    this.loading = false;
                    break;
                default:
                    break;
            }
        });
    }

    ngOnInit() {
        const self = this;
        this._store.pipe(
            select('ssoSetting')
        ).subscribe(sso => this._ssoSetting = sso).unsubscribe();

        // for the on the fly feature on web outlook add-in, the local storage can not be shared
        // we need to pass data from parent to child dialog box. [TAM-42152]
        // reference: https://learn.microsoft.com/en-us/office/dev/add-ins/develop/dialog-api-in-office-add-ins
        if (AddInUtilities.isOfficeEnvironment() && Office.context['isDialog']) {
            Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived,
                self.onMessageFromParent);
        }

        this._checkWebPluginVersion();
        this._checkSendToEmailDepositType();

        this._destroySubscriptions.push(
            this._store.pipe(
                select('auth')
            ).subscribe(
                authState => {
                    if (authState.needLogin) {
                        if (this._pluginService.isPlugInEditNoteMode) {
                            this._pluginService.pluginSubject$.next();
                        } else {
                            if (!this._router.url.startsWith('/rc-dashboard')) {
                                this.logout();
                            }
                        }
                    }
                }
            )
        );
    }

    onMessageFromParent(arg) {
        if (arg && arg.message) {
            const addInOnTheFlyModel: AddInOnTheFlyModel = JSON.parse(arg.message);
            // set value to local storage for dialog box
            if (addInOnTheFlyModel?.user) {
                localStorage.setItem('user', JSON.stringify(addInOnTheFlyModel.user));
                AppConfig.userId = addInOnTheFlyModel.user.id;

                if (addInOnTheFlyModel.isShowEntityOnTheFly) {
                    localStorage.setItem(businessConstants.officeAddIn.entityFly, JSON.stringify(addInOnTheFlyModel.entityDialogOpenOptions));
                } else {
                    localStorage.setItem(businessConstants.officeAddIn.contactFly, JSON.stringify(addInOnTheFlyModel.contactDialogOpenOptions));
                }
            }
        }
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    /**
     * For outlook add-in, there are three types: send to web/only email/only file, we need to store the type
     */
    private _checkSendToEmailDepositType() {
        if (AddInUtilities.isOfficeEnvironment()) {
            // set default type
            AddInGlobalModel.currentAddInDepositType = AddInDepositType.ADDINALL;

            const url = window.location.href;
            if (url) {
                const currentType = url.substring(url.lastIndexOf('/') + 1, url.length);
                const allDepositTypes = [AddInDepositType.ADDINALL, AddInDepositType.ADDINONLYEMAIL, AddInDepositType.ADDINONLYFILE];
                const filteredItem = allDepositTypes.filter((item) => item.toLowerCase() === currentType.toString());
                if (filteredItem && filteredItem.length > 0) {
                    AddInGlobalModel.currentAddInDepositType = filteredItem[0];
                    localStorage.setItem('depositType', AddInGlobalModel.currentAddInDepositType);
                }
            }
        }
    }

    /**
     * for 21.4 release, check if the version is missing from url in TamaleWeb, redirect to 'New version  *available'.
     * for 21.5 release,If have to upgrade. check whether the version is missing from url or 21,4,  then redirect.
     */
    private _checkWebPluginVersion() {
        const url = window.location.href;

        if (url && url.indexOf('webplugin') > -1) {
            const pluginVersion = url.substring(url.indexOf('webplugin') + 10, url.length);
            if (!pluginVersion || businessConstants.webPlugin.versionArray.includes(pluginVersion)) {
                this._router.navigateByUrl('plugin-error');
            }
        }
    }

    private logout() {
        // notify server
        if (AppConfig.token) {
            this._authService.logout(AppConfig.token).subscribe(() => {
                AppConfig.token = '';
            });
        }

        // remove cookie in sso workflow
        this._cookieService.delete(SSOCookiesKey, '/');

        //  local storage cleanup
        this._storageService.removeItem('user');
        //  remove user from local storage for office add-in
        if (AddInUtilities.isOfficeJSLoaded()) {
            localStorage.removeItem('user');
        }

        // move to related page
        if (!this._router.url.startsWith('/login') && !this._router.url.startsWith('/admin-login')) {
            this._globalService.getInitialConfigData().subscribe(data => {
                if (data?.sso) {
                    this._ssoSetting = new SsoSetting(false);
                    this._ssoSetting.ssoEnabled = data?.sso?.enableSSO;
                    this._ssoSetting.idpHomeUrl = data?.sso?.idphome;

                    if (this._ssoSetting?.ssoEnabled) {
                        if (AdminUserNames.includes(AppConfig.username)) {
                            this._router.navigate(['/admin-login']);
                        } else {
                            window.open(this._ssoSetting.idpHomeUrl, '_self');
                        }
                    } else {
                        this._router.navigate(['/login']);
                    }
                }
            });
        }
    }
}
