/**
 * Created by Simon Zhao on 4/7/2022.
 * Description:
 * CheckListConfig Component
 * ------ maintenance history ------
 */
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FieldType } from '../../../../tamalelibs/models/workflow.model';
import { ControlConfigEvents, IControlConfigComponent } from '../../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from '../field.model';
import * as _lodash from 'lodash';

export class CheckListItemModel {
    /**
     * the index of current item, should be unique in a check list.
     */
    index: number;
    /**
     * the item's description.
     */
    description: string;
    /**
     * a flag indicating whether this item must be checked.
     */
    isRequired: boolean;
}

export const DEFAULT_CHECK_LIST_ITEMS = [{ index: 1, description: '', isRequired: true }, { index: 2, description: '', isRequired: true }, { index: 3, description: '', isRequired: true }];

@Component({
    selector: 'tam-check-list-config',
    templateUrl: './check-list-config.component.html',
    styleUrls: ['./check-list-config.component.scss', '../field-config.component.scss']
})
export class CheckListConfigComponent implements OnInit, OnDestroy, IControlConfigComponent {
    //#region public properties
    @Input()
    config: FieldConfig;

    @ViewChild('base', { static: false }) base;

    dataType = FieldType.CHECK_LIST;

    get itemList(): Array<CheckListItemModel> {
        if (this.config && this.config.checkItems) {
            return this.config.checkItems;
        }

        return [];
    }

    //#endregion

    //#region private properties

    /**
    * the subscription array that would be unsubscribed on destroying.
    */
    private _destroySubscriptions: Array<Subscription> = [];

    private _isValidating = false;

    //#endregion

    constructor() { }

    //#region public methods
    getInvalidDetail(): FieldValidationError {
        const baseValidationDetail: FieldValidationError = this.base.getInvalidDetail();
        baseValidationDetail.isSourceInvalid = !this.isAnyItemInvalid();
        return baseValidationDetail;
    }

    /**
     * determines whether the given item is deletable.
     * @param item the target item
     * @returns whether the target item is deletable.
     */
    isDeletable(item: CheckListItemModel) {
        return this.itemList.length > 1;
    }

    isItemInvalid(item: CheckListItemModel) {
        return this._isValidating && (!item || !item.description || item.description.trim().length === 0);
    }

    isAnyItemInvalid() {
        return this._isValidating && this.itemList.some(it => this.isItemInvalid(it));
    }

    /**
     * determines whether the add button should be visible.
     * @returns a flag indicating whether the add button should be visible.
     */
    isAddable() {
        return this.itemList.length < 10;
    }

    ngOnInit() {
        if (!this.config.checkItems || this.config.checkItems.length === 0) {
            this.config.checkItems = [];
            const defaultItems = _lodash.cloneDeep(DEFAULT_CHECK_LIST_ITEMS);
            this.config.checkItems.push(...defaultItems);
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    onDeleteItem(item: CheckListItemModel) {
        if (this.itemList.includes(item)) {
            this.itemList.splice(this.itemList.indexOf(item), 1);
            this._onValueChange();
        }
    }

    onAddItemClick() {
        if (this.isAddable()) {
            const newItem = new CheckListItemModel();
            newItem.description = '';
            newItem.isRequired = true;
            newItem.index = Math.max(...this.itemList.map(it => it.index)) + 1;
            this.itemList.push(newItem);
            this._onValueChange();
        }
    }

    validate(): boolean {
        this._isValidating = true;
        const baseValid: boolean = this.base.validate();
        const isAllItemValid = !this.isAnyItemInvalid();

        return baseValid && isAllItemValid;
    }

    //#endregion

    //#region private methods

    private _onValueChange() {
        this._isValidating = false;
        this.config.config.feedbackSubject$.next({
            type: ControlConfigEvents.VALUE_CHANGE,
            payload: this.config,
        });
    }
    //#endregion
}
