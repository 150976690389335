import { ThreadViewActions, ThreadViewActionTypes } from '../actions/thread-view.actions';
import { createSelector, combineReducers } from '@ngrx/store';
import { AppState } from '..';

export const threadViewSelector = (state: AppState) => state.threadView;
export const threadViewDataSelector = createSelector(threadViewSelector, (state: any) => state.thread);
export const threadSideSheetDataSelector = createSelector(threadViewSelector, (state: any) => state.threadSideSheet);
export const threadViewLastActionSelector = createSelector(threadViewSelector, (state: any) => state.lastAction);

export function threadViewDataReducer(state: any = null, action: ThreadViewActions) {
    switch (action.type) {
        case ThreadViewActionTypes.GET_THREAD_SUCCESS:
            return action.payload;
        case ThreadViewActionTypes.GET_THREAD_FAILED:
            return null;
        case ThreadViewActionTypes.REMOVE_ATTACHMENT:
            const attachInfo = action.payload.attachInfo;
            // delete the attachment from thread
            if (state) {
                if (state.attachments.has(attachInfo.id)) {
                    state.attachments.delete(attachInfo.id);
                }
                if (state.notes) {
                    // delete the attachment from sidenote
                    state.notes.forEach(note => {
                        if (note.attachments.has(attachInfo.id)) {
                            note.attachments.delete(attachInfo.id);
                        }
                    });
                }
            }
            return state;
        default:
            return state;
    }
}

export function threadSideSheetDataReducer(state: any = null, action: ThreadViewActions) {
    switch (action.type) {
        case ThreadViewActionTypes.GET_THREAD_SIDESHEET_SUCCESS:
            return action.payload;
        case ThreadViewActionTypes.GET_THREAD_SIDESHEET_FAILED:
            return null;
        case ThreadViewActionTypes.REMOVE_THREAD_SIDESHEET_ITEM:
            const newState = Object.assign({}, state);
            const threadId = action.payload;
            if (threadId === newState.id) {
                return null;
            } else {
                return state;
            }

        case ThreadViewActionTypes.REMOVE_ATTACHMENT:
            const attachInfo = action.payload.attachInfo;
            // delete the attachment from thread
            if (state) {
                if (state.attachments.has(attachInfo.id)) {
                    state.attachments.delete(attachInfo.id);
                }
                if (state.notes) {
                    // delete the attachment from sidenote
                    state.notes.forEach(note => {
                        if (note.attachments.has(attachInfo.id)) {
                            note.attachments.delete(attachInfo.id);
                        }
                    });
                }
            }
            return state;
        default:
            return state;
    }
}

export function actionReducer(state: string = '', action: ThreadViewActions) {
    switch (action.type) {
        case ThreadViewActionTypes.GET_THREAD:
        case ThreadViewActionTypes.GET_THREAD_SUCCESS:
        case ThreadViewActionTypes.GET_THREAD_FAILED:
            return action.type;
        default:
            return state;
    }
}

const threadViewReducer = combineReducers({
    thread: threadViewDataReducer,
    threadSideSheet: threadSideSheetDataReducer,
    lastAction: actionReducer
});

export function getThreadViewRedcuer(state, action) {
    return threadViewReducer(state, action);
}
