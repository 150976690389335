<div class="progress-container" *ngIf="isPortrait">
    <div class="node-root" kendoTooltip>
        <div *ngFor="let node of nodes;last as isLast;first as isFirst; let i = index;">
            <div class="node-wrapper">
                <div class="task-focus-indicator" [ngClass]="{'is-highlight': node.isHighlighted}"></div>
                <div class="node-right" [ngClass]="{'is-highlight': node.isHighlighted}">
                    <ng-container [ngSwitch]="node.nodeType">
                        <div [ngClass]="{'joint-gray': !isCompleteNode(nodes[i - 1]), 'joint-green': isCompleteNode(nodes[i - 1])}"
                            *ngIf="!isFirst"></div>
                        <div *ngSwitchCase="'activeNode'" class="node-container clickable-node"
                            (click)="onProgressNodeClicked(node, $event)">
                            <div class="green-circle activeNode" [ngClass]="{'is-highlight': node.isHighlighted}"
                                [title]="node.caption">
                                <span class="serial-no-span white-font">{{node.serialNo}}</span>
                            </div>
                            <ng-container *ngTemplateOutlet="taskInfoTemplate;context:{'node':node}"></ng-container>
                        </div>
                        <div *ngSwitchCase="'inactiveNode'" class="node-container">
                            <div class="gray-circle inactiveNode" [title]="node.caption">
                                <span class="serial-no-span">{{node.serialNo}}</span>
                            </div>
                            <ng-container
                                *ngTemplateOutlet="taskInfoTemplate;context:{'node':node, 'isInactive': true}">
                            </ng-container>
                        </div>
                        <div *ngSwitchDefault class="node-container clickable-node"
                            (click)="onProgressNodeClicked(node, $event)">
                            <div class="green-circle" [ngClass]="{'is-highlight': node.isHighlighted}"
                                [title]="node.caption">
                                <smart-icon name="white-tick" class="check-icon"></smart-icon>
                            </div>
                            <ng-container *ngTemplateOutlet="taskInfoTemplate;context:{'node':node}"></ng-container>
                        </div>
                        <div [ngClass]="{'connection-line-green': isCompleteNode(node), 'connection-line-gray': !isCompleteNode(node)}"
                            *ngIf="!isLast"></div>
                    </ng-container>
                </div>
            </div>

            <ng-template #taskInfoTemplate let-node="node" let-isInactive='isInactive'>
                <div class="task-info-div" *ngIf="isExpanded" [ngClass]="{'is-highlight': node.isHighlighted}">
                    <div class="task-name-caption">
                        <span class="task-name-span" [ngClass]="{'inactive': isInactive}"
                            [title]="node.caption">{{node.caption}}</span>
                    </div>
                    <div class="task-stage-caption">
                        <span class="task-stage-span" [title]="node.subcaption"
                            [ngStyle]="{'color': node.foreColor, 'background': node.background}">{{node.subcaption}}</span>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="expand-icon" [ngClass]="{'isExpanded': isExpanded}" (click)="onProgressClicked($event)">
        <smart-icon *ngIf="isExpanded" name="progress-collapse" class="light"></smart-icon>
        <smart-icon *ngIf="!isExpanded" name="progress-expand" class="light"></smart-icon>
    </div>
</div>