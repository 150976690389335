/**
 * created by Alex on 3/8/2021.
 * Description:
 *
 * ------ maintenance history ------
 * Updated by Marcus Zhao add ContactFilterModel.
 */
export class MapTile {
    enable: boolean;
    constructor(enable: boolean = false) {
        this.enable = enable;
    }

    static parse(serverResponse): MapTile {
        const maptile = new MapTile();

        if (serverResponse) {
            maptile.enable = serverResponse.enable;
        }
        return maptile;
    }
}


/**
 * Json params https://confluence.advent.com/display/TAM/Server-Filter+Entities+and+Contacts+by+metadata
 *
 * {
    "logicalOperator": "or",
    "filters": [
        {
            "logicalOperator": "and",
            "field": "adhoc",
            "filters": [
                {
                    "attrDefId": ["7d9a6af5e13243eca06c0a08570f4e75"],
                    "dataType": "entity",
                    "operator": "Includes",
                    "values": [
                        "02423c1563f44a44968cdee342365c24",
                        "40c8d491270b43c8a47358fef4bab511"
                    ]
                }
            ]
        },
        {
            "logicalOperator": "and",
            "field": "contact",
            "filters": [
                {
                    "attrDefId": ["countryState"],
                    "dataType": "text",
                    "operator": "includes",
                    "values": {
                        "country": "Zambia",
                        "state": "Central"
                    }
                }
            ]
        }
    ]
}
 *
 * @export
 * @class MapFilterModel
 */
export class MapFilterModel {
    logicalOperator = 'or';
    filters: Array<MapFilterContent>;
    constructor() {
        this.filters = new Array<MapFilterContent>();
    }
}

export class ContactFilterModel {
    exchange: ContactFilterStatusModel;
    metadataFilter: MapFilterModel;
    constructor() {
        // tslint:disable-next-line: no-use-before-declare
        this.exchange = new ContactFilterStatusModel();
        this.metadataFilter = new MapFilterModel();
    }
}

export class ContactFilterStatusModel {
    status: Array<string>;
    constructor() {
        this.status = new Array<string>();
    }
}

export class MapFilterContent {
    logicalOperator = 'and';
    field: string;
    filters: Array<Filter>;
    constructor() {
        this.filters = new Array<Filter>();
        this.field = 'contact';
    }
}

export class Filter {
    attrDefId: Array<any>;
    dataType: string;
    operator: string;
    values: any;
    target?: string;
    includeCurrentEmployment?: boolean;
    title?: string;
    metadataType?: string;
    restrictType?: string; // used to distinguish entryTypes/eventTyps/noteTypes.
}
