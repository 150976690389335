import { TSGuid } from './ts-guid.model';
import { EntryClass } from './entry-class.model';
import { Contact } from './contact.model';
import { Entry } from './entry.model';
import { CalendarDateType } from './calendar-datetype.model';
import { CalendarTouchPoint } from './calendar-touch-point.model';
import { TSDictionary } from './ts-dictionary.model';
import { NoteEntry } from './note-entry.model';

export class LocationExpandValue {
    linkedEntityLongName: string;
    linkedEntityAddress: [];
}

export class CalendarEntry extends Entry {
    AllDay: boolean;
    EndDate: Date;
    StartDateType: CalendarDateType;
    Attendees: Contact[];
    Location: string;
    LocationExpand: string; // used to store the basic linked entity information. including entity id and coordinate.
    LocationExpandValue: LocationExpandValue; // used to store the expand linked entity information. including longName and address.
    FeedEventID: string;
    EarningsConfCallCQE: string;
    EarningsConfCallCYE: string;
    EarningsReleaseCQE: string;
    EarningsReleaseCYE: string;
    PrimaryPhone: string;
    PrimaryPassword: string;
    freeTextDictionary: TSDictionary<Object>;
    touchpoints: CalendarTouchPoint[];
    isEntityFieldEditableInSideNote?: boolean;
    notesInThread?: number;
    attachmentsInThread?: number;
    notes?: Array<NoteEntry>;

    get EntryClass(): EntryClass {
        return EntryClass.Event;
    }
    get ParentEntryID(): TSGuid {
        return this.ParentEntryID;
    }
}
