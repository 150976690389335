/**
 * Created by Bowen on 28/08/2019.
 * Description:
 * get valid relationship type
 *
 * ------ maintenance history ------
 *
 */

import { RelationshipTypeActions, RelationshipTypeActionTypes } from '../actions/relationship-type.actions';
import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { ValidRelationshipType } from '../../tamalelibs/models/valid-relationship-type.model';

export const validRelationshipTypeSelector = createSelector((state: AppState) => state, state => state.validRelationshipType);

export function validRelationshipTypeReducer(state: Array<ValidRelationshipType>, action: RelationshipTypeActions) {
    switch (action.type) {
        case RelationshipTypeActionTypes.GETVALIDRELATIONSHIP_SUCCESS:
            return action.payload;
        case RelationshipTypeActionTypes.DELETE_RELATIONSHIP_TYPE_SUCCESS:
            state = state.filter(item => item.id !== action.payload.id);
            return Object.assign([], state);
        case RelationshipTypeActionTypes.CREATE_VALID_RELATIONSHIP_TYPE_SUCCESS:
            state.push(action.payload);
            return Object.assign([], state);
        case RelationshipTypeActionTypes.EDIT_RELATIONSHIP_TYPE_SUCCESS:
            state.forEach(item => {
                if (item.relationshipType.id === action.payload.oldRelationshipTypeId) {
                    item.relationshipType.name = action.payload.newRelationshipTypeName;
                    item.relationshipType.id = action.payload.newRelationshipTypeId;
                }
                if (item.reciprocalRelationshipType.id === action.payload.oldRelationshipTypeId) {
                    item.reciprocalRelationshipType.name = action.payload.newRelationshipTypeName;
                    item.reciprocalRelationshipType.id = action.payload.newRelationshipTypeId;
                }
            });
            return Object.assign([], state);
        default:
            return state;
    }
}
