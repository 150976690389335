/**
 * Created by Yoyo Fang on 3/17/19.
 * Description:
 * solution to a known issue on es6 map. Map does not work in JSON.stringify.
 * to meet the gap generated from refactoring tsdictionary to map
 * ------ maintenance history ------
 *
 */

import { Injectable } from '@angular/core';
import { Thread } from '../models/thread.model';

@Injectable()
export class ES6MapService {
    constructor() { }
    public transformMapToJSON(thread: Thread) {
        const result: object = JSON.parse(JSON.stringify(thread));
        result['attachments'] = this.strMapToObj(thread.attachments);
        // result.notes = [];
        for (let i = 0; thread && thread.notes && i < thread.notes.length; i++) {
            result['notes'][i]['attachments'] = this.strMapToObj(thread['notes'][i]['attachments']);
        }
        return result;
    }

    public strMapToObj(strMap: Map<string, any>) {
        const obj: object = {};
        if (strMap && strMap.forEach) {
            strMap.forEach((v, k) => {
                if (typeof v === 'object') {
                    obj[k] = JSON.parse(JSON.stringify(v));
                } else {
                    obj[k] = v;
                }
            });
        }
        return obj;
    }

    public transformJSONToMap(threadObj: any) {
        const result = JSON.parse(JSON.stringify(threadObj));
        if (threadObj && threadObj.notes && threadObj.notes.length) {
            threadObj.notes.forEach((note, index) => {
                if (note.attachmentIds && note.attachmentIds.length) {
                    result.notes[index].attachments = new Map<string, any>();
                    note.attachmentIds.forEach((id) => {
                        result.notes[index].attachments.set(id, result.attachments[id]);
                    });
                }

            });
        }
        if (threadObj && threadObj.attachments) {
            result.attachments = this.objToStrMap(threadObj.attachments);
        }
        return result;
    }

    public objToStrMap(obj) {
        const strMap = new Map<string, any>();
        for (const k of Object.keys(obj)) {
            if (typeof obj[k] === 'object') {
                strMap.set(k, JSON.parse(JSON.stringify(obj[k])));
            } else {
                strMap.set(k, obj[k]);
            }
        }
        return strMap;
    }
}
