<div class="container">
    <div class="title" *ngIf="!config.hideTitle">
        <div class="left-title">
            <span>{{config.titles[0]}}</span>
        </div>
        <div class="middle-title"></div>
        <div class="right-title">
            <span>{{config.titles[1]}}</span>
        </div>
    </div>
    <div class="list-area">
        <div class="left-list">
            <input type="text" class="filter-input" [(ngModel)]="leftFilterStr" [placeholder]="placeholderText">
            <div class="list-item-container">
                <ng-container *ngFor="let item of listLeft">
                    <div class="list-item" [ngClass]="item.selected ? 'selected' : ''" (click)="onItemClick(item)"
                        (dblclick)="onItemDoubleClick(item, 'left')" title="{{item.data[config.dataTextField]}}">
                        <ng-container *ngIf="!itemRender; else renderContainer">
                            <div class="default">
                                <span>{{item.data[config.dataTextField]}}</span>
                            </div>
                        </ng-container>
                        <ng-template #renderContainer [ngTemplateOutlet]="itemRender"
                            [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="actions">
            <button (click)="moveTo('right')" [disabled]="isMovable('right')">
                <smart-icon name="angle-right"></smart-icon>
            </button>
            <button (click)="moveTo('left')" [disabled]="isMovable('left')">
                <smart-icon name="angle-left"></smart-icon>
            </button>
        </div>
        <div class="right-list">
            <input type="text" class="filter-input" [(ngModel)]="rightFilterStr" [placeholder]="placeholderText">
            <div class="list-item-container">
                <ng-container *ngFor="let item of listRight">
                    <div class="list-item" [ngClass]="item.selected ? 'selected' : ''" (click)="onItemClick(item)"
                        (dblclick)="onItemDoubleClick(item, 'right')" title="{{item.data[config.dataTextField]}}">
                        <ng-container *ngIf="!itemRender; else renderContainer">
                            <div class="default">
                                <span>{{item.data[config.dataTextField]}}</span>
                            </div>
                        </ng-container>
                        <ng-template #renderContainer [ngTemplateOutlet]="itemRender"
                            [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>