/**
 * Created by Alex Xia on 2/28/2022.
 * Description:
 *  Brief Object
 * ------ maintenance history ------
 * 06/21/2022 Marcus Zhao add source for set current source for template.
 * 07/26/2022 Marcus Zhao add parseFromUploadFile() to set response value.
 */

import { UUID } from 'angular2-uuid';
import { Contact } from './contact.model';
import { AddInType } from '../../models/addin.model';

export class OfficeAddIn {
    addInType: AddInType;
    attachments: Array<OfficeAttachment>;
    body: string;
    ccEmails: any;
    eventStart: Date;
    eventEnd: Date;
    isPinMode: boolean;
    location: string;
    organizer: any;
    receiveDate: Date;
    recipientEmails: any;
    senderEmail: string;
    source: Contact;
    subject: string;
    sender: any;
    // Add a unique identity to the data of different emails.
    uuid?: UUID;
    // message or appiontment.
    itemType: string;
    constructor() {
        this.attachments = [];
        this.body = '';
        this.receiveDate = new Date();
        this.subject = '';
    }
}

export class ManifestXMLFile {
    fileName: string;
    jsonContent: string;
    constructor(_fileName: string, _jsonContent: string) {
        this.fileName = _fileName;
        this.jsonContent = _jsonContent;
    }
}

export class OfficeAddInManifest {
    files: Array<ManifestXMLFile>;
    constructor() {
        this.files = [];
    }
}

export class OfficeAttachment {
    contentType: string;
    dzid: string;
    fileId: string;
    fileName: string;
    isBase64: boolean;
    serverFileName: string;
    progress: string;
    constructor() {
        this.contentType = '';
        this.fileName = '';
        this.serverFileName = '';
        this.progress = '0%';
    }

    /**
     * set response value to attachment ,no need new OfficeAttachment();
     * @param officeAttachment
     * @param response
     * @returns
     */
    static parseFromUploadFile(officeAttachment, response): OfficeAttachment {
        officeAttachment.fileId = response['filenames'];
        officeAttachment.serverFileName = response['filenames'];
        return officeAttachment;
    }
}
