import { Component, OnInit, Input, ContentChild, TemplateRef, OnDestroy, HostListener } from '@angular/core';
import { ColumnPanelEventType } from './column-panel.model';
import { Subscription } from 'rxjs';
import { LONG_TOUCH_INTERVAL } from '../../tamalelibs/constants/business.constants';
import { BriefConfig } from '../widgets.model';

/*
    * usage:
    * <tam-column-panel [config]="columnBoxConfig">
    *   <ng-template #bar let-dataItem="dataItem">
    *        <span>{{dataItem.title}}</span>
    *   </ng-template>
    * </tam-column-panel>
    *
*/

@Component({
    selector: 'tam-column-panel',
    templateUrl: './column-panel.component.html',
    styleUrls: ['./column-panel.component.scss']
})
export class ColumnPanelComponent implements OnInit, OnDestroy {

    @Input() config: BriefConfig;
    @Input() data: Array<any>;
    @ContentChild('bar', { static: false }) bar: TemplateRef<any>;

    mouseoveringIndex: number;
    private _subscription$: Subscription[] = [];
    private _firstTime = 0;
    private _lastTIme = 0;
    private _downIndex: number;
    private _moving = false;
    constructor() { }

    ngOnInit() { }

    ngOnDestroy(): void {
        this._subscription$.forEach(item => item.unsubscribe());
    }

    moveColumn(index) {
        if (index === this._downIndex) {
            return;
        }
        this.data.splice(index, 0, this.data.splice(this._downIndex, 1)[0]);
    }

    mousedown(e, index) {
        this._firstTime = Date.now();
        this._downIndex = index;
        this._moving = true;
        this.mouseoveringIndex = null;
    }

    mouseup(index) {
        this._lastTIme = Date.now();
        this._moving = false;
        if (this._lastTIme - this._firstTime < LONG_TOUCH_INTERVAL) {
            return;
        }
        this.mouseoveringIndex = null;
        if (this.config) {
            this.config.feedbackSubject$.next({ type: ColumnPanelEventType.SORT, payload: this.data });
        }
    }

    mouseover(index) {
        if (this._moving) {
            if (index !== null && this.mouseoveringIndex !== index) {
                this.mouseoveringIndex = index;
                this.moveColumn(index);
            }
        }
    }

    mouseout(index) {
        if (this._moving && index !== null && this.mouseoveringIndex !== null) {
            this._downIndex = this.mouseoveringIndex;
        }
    }

    @HostListener('document:mouseup', ['$event']) onMouseup() {
        if (this.mouseoveringIndex !== null && this.mouseoveringIndex !== undefined) {
            this.mouseup(this._downIndex);
        } else {
            this._moving = false;
        }
    }
    trackByFn(index) {
        return index;
    }
}
