/**
 * Created by Alex Xia on 11/20/2020.
 * Description:
 * ------ maintenance history ------
 */

import { ReplaySubject, Subject } from 'rxjs';
import { TamDashboardTileData } from '../tam-dashboard-map-tile.config';

export class MapConfig {
    markerArray: Array<TamDashboardTileData>;
    actionSubject$: ReplaySubject<any>;
    feedbackSubject$: Subject<any>;
    isFromMap?: boolean;
    inputValue?: string;
    isShowInMSAddin?: boolean;

    constructor() {
        // Resolve subscriptions later than sending subscriptions
        this.actionSubject$ = new ReplaySubject<any>(10);
        this.feedbackSubject$ = new Subject<any>();
        this.isFromMap = true;
        this.isShowInMSAddin = false;
    }
}

// will delete this class after  finished code refactor.
export class Marker {
    latitude: number;
    longitude: number;
    name: string;
    entityId: string;
    entityName: string;
    entityType: string;
    icon: string;
    content: any;

    constructor(latitude?: number, longitude?: number, entityName?: string, entityType?: string, icon?: string, content?: any) {
        this.latitude = latitude;
        this.longitude = longitude;
        this.entityName = entityName;
        this.entityType = entityType;
        this.icon = icon;
        this.content = content;
    }
}

export enum MapAction {
    APPLY_NEARBY = 'APPLY_NEARBY',
    OPEN_NEARBY = 'OPEN_NEARBY',
    OPEN_TRIP = 'OPEN_TRIP',
    ADD_STOP = 'ADD_STOP',
}
