/**
 * Created by Yu Zhang on 3/28/17.
 * Description:
 *
 * ------ maintenance history ------
 * v1.1 modified by Alan Yang on 8/7/17
 *      added TodayOrPastSimpleDate to make it show date only even the date is tody. eg.(2017-08-07)
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'todayOrPastDate' })
export class TodayOrPastDatePipe implements PipeTransform {
    transform(date: Date): string {
        const today = new Date();
        const valueDate = new Date(date);
        if (today.setHours(0, 0, 0, 0) === valueDate.setHours(0, 0, 0, 0)) {
            const tmpHour = date.getHours();
            const hour = tmpHour > 13 ? tmpHour - 12 : tmpHour;
            return hour + ':' + ('0' + date.getMinutes()).slice(-2) + ' ' + (tmpHour >= 12 ? 'PM' : 'AM');
        } else {
            return ('0' + (date.getMonth() + 1)).slice(-2) + '.' + ('0' + date.getDate()).slice(-2) + '.' + date.getFullYear();
        }
    }
}

@Pipe({ name: 'TodayOrPastSimpleDate' })
export class TodayOrPastSimpleDatePipe implements PipeTransform {
    transform(date: Date): string {
        return date.getFullYear() + '-' + ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2);
    }
}

@Pipe({ name: 'mmddyyyySlashNoPadding' })
export class MmDdYyyySlashNoPaddingPipe implements PipeTransform {
    transform(date: Date): string {
        if (date == null) {
            return '';
        }
        return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    }
}

@Pipe({ name: 'todayOrPastSimpleDateWithShortMonth' })
export class TodayOrPastSimpleDateWithShortMonthPipe implements PipeTransform {
    transform(date: Date): string {
        const today = new Date();
        const valueDate = new Date(date);
        const hour = date.getHours();
        if (today.setHours(0, 0, 0, 0) === valueDate.setHours(0, 0, 0, 0)) {
            return `${hour}:${('0' + date.getMinutes()).slice(-2)}`;
        } else {
            return `${parseToShortMonth(date.getMonth() + 1)}.${date.getDate()}.${date.getFullYear()}`;
        }
    }
}

@Pipe({ name: 'todayOrPastFullDate' })
export class TodayOrPastFullDatePipe implements PipeTransform {
    transform(date: Date): string {
        const today = new Date();
        const valueDate = new Date(date);
        const tmpHour = date.getHours();
        const hour = tmpHour > 13 ? tmpHour - 12 : tmpHour;
        if (today.setHours(0, 0, 0, 0) === valueDate.setHours(0, 0, 0, 0)) {
            return hour + ':' + ('0' + date.getMinutes()).slice(-2) + ' ' + (tmpHour >= 12 ? 'PM' : 'AM');
        } else {
            return parseToDay(date.getDay()) + ', ' +
                parseToMonth(date.getMonth() + 1) + ' ' + date.getDate() + ', ' + date.getFullYear() + ' at ' +
                hour + ':' + ('0' + date.getMinutes()).slice(-2) + ' ' + (tmpHour >= 12 ? 'PM' : 'AM');
        }
    }
}

@Pipe({ name: 'todayOrPastShortDate' })
export class TodayOrPastShortDatePipe implements PipeTransform {
    transform(date: Date): string {
        const today = new Date();
        const valueDate = new Date(date);
        const tmpHour = date.getHours();
        const hour = tmpHour > 13 ? tmpHour - 12 : tmpHour;
        if (today.setHours(0, 0, 0, 0) === valueDate.setHours(0, 0, 0, 0)) {
            return hour + ':' + ('0' + date.getMinutes()).slice(-2) + ' ' + (tmpHour >= 12 ? 'PM' : 'AM');
        } else {
            return parseToShortDay(date.getDay()) + ', ' +
                parseToShortMonth(date.getMonth() + 1) + ' ' + date.getDate() + ', ' + date.getFullYear() + ' at ' +
                hour + ':' + ('0' + date.getMinutes()).slice(-2) + ' ' + (tmpHour >= 12 ? 'PM' : 'AM');
        }
    }
}

@Pipe({ name: 'todayOrPastShortFullDate' })
export class TodayOrPastShortFullDatePipe implements PipeTransform {
    transform(date: Date): string {
        const today = new Date();
        const valueDate = new Date(date);
        const tmpHour = date.getHours();
        const hour = tmpHour > 13 ? tmpHour - 12 : tmpHour;
        if (today.setHours(0, 0, 0, 0) === valueDate.setHours(0, 0, 0, 0)) {
            return 'Today at ' + hour + ':' + ('0' + date.getMinutes()).slice(-2) + ' ' + (tmpHour >= 12 ? 'PM' : 'AM');
        } else {
            return parseToShortDay(date.getDay()) + ', ' +
                parseToShortMonth(date.getMonth() + 1) + ' ' + date.getDate() + ', ' + date.getFullYear() + ' at ' +
                hour + ':' + ('0' + date.getMinutes()).slice(-2) + ' ' + (tmpHour >= 12 ? 'PM' : 'AM');
        }
    }
}

export function parseToDay(index: number) {
    switch (index) {
        case 0:
            return 'Sunday';
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        default:
            return 'Unknown Day';
    }
}

export function parseToShortDay(index: number) {
    switch (index) {
        case 0:
            return 'Sun';
        case 1:
            return 'Mon';
        case 2:
            return 'Tues';
        case 3:
            return 'Wed';
        case 4:
            return 'Thurs';
        case 5:
            return 'Fri';
        case 6:
            return 'Sat';
        default:
            return 'Unknown Day';
    }
}

export function parseToMonth(index: number) {
    switch (index) {
        case 1:
            return 'January';
        case 2:
            return 'February';
        case 3:
            return 'March';
        case 4:
            return 'April';
        case 5:
            return 'May';
        case 6:
            return 'June';
        case 7:
            return 'July';
        case 8:
            return 'August';
        case 9:
            return 'September';
        case 10:
            return 'October';
        case 11:
            return 'November';
        case 12:
            return 'December';
        default:
            return 'Unknown Month';
    }
}

export function parseToShortMonth(index: number) {
    switch (index) {
        case 1:
            return 'Jan';
        case 2:
            return 'Feb';
        case 3:
            return 'Mar';
        case 4:
            return 'Apr';
        case 5:
            return 'May';
        case 6:
            return 'Jun';
        case 7:
            return 'Jul';
        case 8:
            return 'Aug';
        case 9:
            return 'Sep';
        case 10:
            return 'Oct';
        case 11:
            return 'Nov';
        case 12:
            return 'Dec';
        default:
            return 'Unknown Month';
    }
}
