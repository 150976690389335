import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'tam-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

    @Input() rating: number;
    @Input() itemId: number;
    @Input() disabled?: boolean;
    @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
    }
    onClick(rating: number): void {
        if (this.disabled) {
            return;
        }
        if (rating === this.rating) {
            rating -= 1;
        }
        this.rating = rating;
        this.ratingClick.emit({
            itemId: this.itemId,
            rating: rating
        });
    }
}
