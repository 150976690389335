<div class="checklist-container">
    <field-config-base #base [config]="config" [dataType]="dataType"></field-config-base>
    <div class="tam-form-field check-list-items" tid="check list items">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'workflow.check_list_items' | stringLiterals}}<span class="required-field">*</span>
            </div>
        </div>
        <div class="items-container">
            <div class="item-wrapper" *ngFor="let item of itemList">
                <div class="description-wrapper">
                    <input [(ngModel)]="item.description" class="check-item-input" maxlength="128"
                        [ngClass]="{'isInvalid': isItemInvalid(item)}" />
                </div>
                <div class="operation-wrapper">
                    <kendo-switch class="kendo-switch-small-no-label" [(ngModel)]="item.isRequired">
                    </kendo-switch>
                    <smart-icon name="trash" *ngIf="isDeletable(item)" class="small light" (click)="onDeleteItem(item)">
                    </smart-icon>
                </div>
            </div>
            <div class="error-info" *ngIf="isAnyItemInvalid()">
                {{'general.required_error_message' | stringLiterals}}
            </div>
            <smart-icon (click)="onAddItemClick()" name="colorize-add-cycle" class="light"
               [ngClass]="{'disabled': !isAddable()}" iconClassList="icon add-btn-icon">
            </smart-icon>
        </div>
    </div>
</div>