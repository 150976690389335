import { Component, OnInit, OnChanges, Input, SimpleChanges, OnDestroy, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { NotificationOptions, NotificationStyles } from './notification.model';

@Component({
    selector: 'tam-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input() options: NotificationOptions;
    @Input() isAddInMode: string;
    status: NotificationOptions;
    styleClass = '';
    icon = 'announcement';
    widgetID = 'aacl-notification';
    private _el: HTMLElement;
    private _timer;

    constructor(
        private _element: ElementRef,
        private _renderer: Renderer2,
    ) {
        this._el = this._element.nativeElement;
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.isAddInMode) {
            this._renderer.addClass(this._el.querySelector('.aacl-notification__outer-box'), 'aacl-addin-notification-info');
            this._renderer.addClass(this._el.querySelector('.aacl-notification__icon-type__container'), 'aacl-addin-notification__icon-type__container');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['options']) {
            this.status = this.options;
            if (!this.status) {
                this.icon = '';
                this.styleClass = '';
                return;
            }
            if (this.status.message && this.status.message.length > 308) {
                this.status.message = this.status.message.substring(0, 308) + '...';
            }
            switch (this.status.style) {
                case NotificationStyles.Success:
                    this.icon = 'check-circle-o';
                    this.styleClass = 'aacl-notification-success';
                    break;
                case NotificationStyles.Warning:
                    this.icon = 'exclamation-circle-o';
                    this.styleClass = 'aacl-notification-warning';
                    break;
                case NotificationStyles.Error:
                    this.icon = 'error-o';
                    this.styleClass = 'aacl-notification-error';
                    break;
                default:
                    this.icon = 'info-o';
                    this.styleClass = 'aacl-notification-info';
            }
            this._autoClose(this.status);
        }
    }

    ngOnDestroy() {
    }

    clickMessage() {
        this.close();
    }

    close(): void {
        this.status = null;
    }

    timeStop(isStop: boolean) {
        if (isStop) {
            clearTimeout(this._timer);
        } else {
            this._autoClose(this.status);
        }
    }

    trackByFn(index) {
        return index;
    }

    private _autoClose(status: NotificationOptions): void {
        let delay = 3000;
        if (status.message != null && status.message.length > 50) {
            delay = 5000;
        } else {
            delay = 3000;
        }
        if (delay && delay > 0) {
            this._timer = setTimeout(() => {
                this.close();
            }, delay);
        }
    }

}
