/**
 * Created by simon zhao on 11/8/2021.
 * Description:
 * Progress Component Related Models
 * ------ maintenance history ------
 */
import { Task, TaskDefinition, TaskStatus } from './../../tamalelibs/models/workflow.model';

export enum ProgressNodeTypeEnum {
    /**
     * the node whose status is complete.
     */
    COMPLETE_NODE = 'completeNode',

    /**
     * the node whose status is active.
     */
    ACTIVE_NODE = 'activeNode',

    /**
     * the node whose status is inactive.
     */
    INACTIVE_NODE = 'inactiveNode'
}

export enum ProgressOrientationEnum {

    PORTRAIT = 'portrait',

    LANDSCAPE = 'landscape'
}

export class ProgressNodeModel {
    /**
     * the node type, different nodes have different appearance.
     *
     * @type {ProgressNodeTypeEnum}
     * @memberof ProgressNodeModel
     */
    nodeType: ProgressNodeTypeEnum;

    /**
     * the caption shown in the expanded view.
     *
     * @type {string}
     * @memberof ProgressNodeModel
     */
    caption: string;

    /**
     * the sub-caption shown under the caption in the expanded view.
     *
     * @type {string}
     * @memberof ProgressNodeModel
     */
    subcaption: string;

    /**
     * the serial no.
     *
     * @type {number}
     * @memberof ProgressNodeModel
     */
    serialNo: number;

    /**
     * the fore color for the subcaption
     *
     * @type {string}
     * @memberof ProgressNodeModel
     */
    foreColor: string;

    /**
     * the background for the subcaption.
     *
     * @type {string}
     * @memberof ProgressNodeModel
     */
    background: string;

    /**
     * the context for current progress node.
     *
     * @type {*}
     * @memberof ProgressNodeModel
     */
    nodeContext: any;

    /**
     * a flag indicating whether current node is highlighted.
     *
     * @type {boolean}
     * @memberof ProgressNodeModel
     */
    isHighlighted: boolean;

    /**
     * Creates a progress node for a given task instance
     *
     * @static
     * @param {Task} task the given task instance
     * @return {*}  {ProgressNodeModel} the new progress node created.
     * @memberof ProgressNodeModel
     */

    /**
     * Creates a progress node for a given task instance
     *
     * @static
     * @param {Task} task The task instance related to the progress node.
     * @param {Task} [currentTask] An optional task, the current task instance, used to determine whether current node should be highlighted.
     * @return {*}  {ProgressNodeModel}
     * @memberof ProgressNodeModel
     */
    static fromTaskInstance(task: Task, currentTask?: Task): ProgressNodeModel {
        const taskNode = new ProgressNodeModel();
        taskNode.caption = task.name;
        taskNode.subcaption = task.taskDefinition.stage.name;
        taskNode.nodeType = ProgressNodeModel.getTaskNodeStatus(task.status);
        taskNode.nodeContext = task;
        taskNode.isHighlighted = currentTask && currentTask.id === task.id;

        return taskNode;
    }

    /**
     * Creates a progress node for a given task definition.
     *
     * @static
     * @param {TaskDefinition} taskDef the given task definition.
     * @return {*}  {ProgressNodeModel} the new progress node just created.
     * @memberof ProgressNodeModel
     */
    static fromTaskDef(taskDef: TaskDefinition): ProgressNodeModel {
        const taskNode = new ProgressNodeModel();
        taskNode.caption = taskDef.name;
        taskNode.subcaption = taskDef.stage.name;
        taskNode.nodeType = ProgressNodeTypeEnum.INACTIVE_NODE;
        return taskNode;
    }

    /**
     * Converts a task status into a progress node status.
     *
     * @static
     * @param {TaskStatus} taskStatus the given task status
     * @return {*}  {ProgressNodeTypeEnum} the corresponding progress node status.
     * @memberof ProgressNodeModel
     */
    static getTaskNodeStatus(taskStatus: TaskStatus): ProgressNodeTypeEnum {
        switch (taskStatus) {
            case TaskStatus.DONE:
            case TaskStatus.REJECTED:
                return ProgressNodeTypeEnum.COMPLETE_NODE;
            case TaskStatus.TODO:
            case TaskStatus.IN_PROGRESS:
                return ProgressNodeTypeEnum.ACTIVE_NODE;
            default:
                return ProgressNodeTypeEnum.INACTIVE_NODE;
        }
    }
}
