/**
 * Created by Yoyo Fang on 02/21/19.
 * Description:
 *
 * ------ maintenance history ------
 *
 */

import { Directive, ElementRef, NgZone, OnInit, OnDestroy } from '@angular/core';
import { TamDropzoneService } from './tam-dropzone.service';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[tamDropzone]'
})
export class TamDropzoneDirective implements OnInit, OnDestroy {
    private counter: number;
    private _subscription: Subscription;
    constructor(private _el: ElementRef, private _ngZone: NgZone, private _outputService: TamDropzoneService) {
        window.addEventListener('dragover', this._stopEvent);
        window.addEventListener('drop', this._stopEvent);
        this.counter = 0;
    }

    ngOnInit(): any {
        // this._ngZone.runOutsideAngular(() => {
        this._el.nativeElement.addEventListener('dragenter', this.onDragEnter(this._outputService));
        this._el.nativeElement.addEventListener('dragleave', this.onDragLeave(this._outputService));
        this._el.nativeElement.addEventListener('drop', this.onDrop(this._outputService));
        this._el.nativeElement.addEventListener('click', this.onClick(this._outputService));
        this._el.nativeElement.addEventListener('mousedown', this.onMousedown(this._outputService));
        // });
        this._subscription = this._outputService.reset$.subscribe(() => this.counter = 0);
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    private onDragEnter(service) {
        return (event) => {
            event.preventDefault();
            if (this.counter === 0) {
                event.stopPropagation();
                service.dragEnter$.next(event);
            }
            this.counter++;
        };
    }

    private onDragLeave(service) {
        return (event) => {
            event.preventDefault();
            this.counter--;
            if (this.counter === 0) {
                event.stopPropagation();
                service.dragLeave$.next(event);
            }
        };
    }

    private onDrop(service) {
        return (event) => {
            event.preventDefault();
            // event.stopPropagation();
            this.counter = 0;
            service.drop$.next(event);
        };
    }

    private onClick(service) {
        return (event) => {
            // comment the below line of code to fix bug [TAM-25732] Relationship Tree - The checkbox is disabled
            // event.preventDefault();
            service.click$.next(event);
        };
    }

    private onMousedown(service) {
        return (event) => {
            service.mousedown$.next(event);
        };
    }

    private _stopEvent(e) {
        if (e) {
            e.preventDefault();
            // e.stopPropagation();
        }
        return false;
    }
}
