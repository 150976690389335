/**
 * Created by Abner Sui on 12/12/2018.
 * Description:
 *  Window Wrapper
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';

function _document(): any {
    return document;
}

function _window(): any {
    // return the global native browser window object
    return window;
}

@Injectable()
export class WindowRef {

    get nativeDocument(): any {
        return _document();
    }

    get nativeWindow(): any {
        return _window();
    }

}
