/**
 * created by Marcus  on 8/25/2020.
 * Description:  source component for entry tempalte
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { FlyDialogActionTypes } from '../../redux/actions/fly-dialog.actions';
import { getflyDialogIndexState } from '../../redux/reducers/fly-dialog.reducer';
import { AppState } from '../../redux';

@Injectable()
export class HomeViewModel {
    constructor(private _store: Store<AppState>) {
    }

    increment() {
        this._store.dispatch({ type: FlyDialogActionTypes.INCREMENT });
    }

    decrement() {
        this._store.dispatch({ type: FlyDialogActionTypes.DECREMENT });
    }

    reset() {
        this._store.dispatch({ type: FlyDialogActionTypes.RESET });
    }

    getFlyIndex() {
        return this._store.pipe(select(getflyDialogIndexState));
    }

}
