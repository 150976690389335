/**
 * Created by Alex on 5/29/2023.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum NotificationActionTypes {
    GETNOTIFICATIONS = '[NOTIFICATION] GETNOTIFICATIONS',
    GETNOTIFICATIONS_SUCCESS = '[NOTIFICATION] GETNOTIFICATIONS_SUCCESS',
    GETNOTIFICATIONS_FAILED = '[NOTIFICATION] GETNOTIFICATIONS_FAILED',

    CREATENOTIFICATIONS = '[NOTIFICATION] CREATENOTIFICATIONS',
    CREATENOTIFICATIONS_SUCCESS = '[NOTIFICATION] CREATENOTIFICATIONS_SUCCESS',
    CREATENOTIFICATIONS_FAILED = '[NOTIFICATION] CREATENOTIFICATIONS_FAILED',

    EDITNOTIFICATIONS = '[NOTIFICATION] EDITNOTIFICATIONS',
    EDITNOTIFICATIONS_SUCCESS = '[NOTIFICATION] EDITNOTIFICATIONS_SUCCESS',
    EDITNOTIFICATIONS_FAILED = '[NOTIFICATION] EDITNOTIFICATIONS_FAILED'
}

export class GetNotifications implements Action {
    readonly type = NotificationActionTypes.GETNOTIFICATIONS;

    constructor(public payload: any) { }
}

export class GetNotificationsSuccess implements Action {
    readonly type = NotificationActionTypes.GETNOTIFICATIONS_SUCCESS;

    constructor(public payload: any) { }
}

export class GetNotificationsFailed implements Action {
    readonly type = NotificationActionTypes.GETNOTIFICATIONS_FAILED;

    constructor(public payload: any) { }
}

export class CreateNotifications implements Action {
    readonly type = NotificationActionTypes.CREATENOTIFICATIONS;

    constructor(public payload: any) { }
}

export class CreateNotificationsSuccess implements Action {
    readonly type = NotificationActionTypes.CREATENOTIFICATIONS_SUCCESS;

    constructor(public payload: any) { }
}

export class CreateNotificationsFailed implements Action {
    readonly type = NotificationActionTypes.CREATENOTIFICATIONS_FAILED;

    constructor(public payload: any) { }
}

export class EditNotifications implements Action {
    readonly type = NotificationActionTypes.EDITNOTIFICATIONS;

    constructor(public payload: any) { }
}

export class EditNotificationsSuccess implements Action {
    readonly type = NotificationActionTypes.EDITNOTIFICATIONS_SUCCESS;

    constructor(public payload: any) { }
}

export class EditNotificationsFailed implements Action {
    readonly type = NotificationActionTypes.EDITNOTIFICATIONS_FAILED;

    constructor(public payload: any) { }
}

export type NotificationActions = GetNotifications | GetNotificationsSuccess | GetNotificationsFailed | CreateNotifications | CreateNotificationsSuccess | CreateNotificationsFailed;
