/**
 * Created by Yu Zhang on 3/10/17.
 * Description: read comments and put configuration data in the right section
 *
 * ------ maintenance history ------
 * Update by Marcus Zhao on 3/08/22 add interface for exchange sync.
 */

import { FileDownloadingItem } from '../components/file-download-item.model';
import { FileUploadingItem } from '../components/file-upload-item.model';
import { TSDictionary } from './ts-dictionary.model';
import { Entity } from './entity.model';
import { Contact } from './contact.model';
import { businessConstants } from '../constants/business.constants';

export class AppConfig {
    // configurations that will not be changed at run time
    public static screenWidth = 0;
    public static screenHeight = 0;
    public static pageTransitionDuration = 1000; // the time duration for page transition triggered by navigation menu
    public static subPageTransitionDuration = 250; // the time duration for page transition triggered by clicking somewhere parent screen
    public static threadListFirstPageSize = 100;
    public static threadListNextPageSize = 100; // in current version, next page size has to equal to first page size
    // tslint:disable-next-line: max-line-length
    public static threadListPageBufferSize = 20;  // threadListPageBufferSize must be smaller than noteListNextPageSize and threadListFirstPageSize
    public static recentNotesSize = 100; // recent notes
    public static UIAdjustingRetryInterval = 700; // interval between retrying to adjusting the UI styling
    public static UIAdjustingRetryCount = 10; // maximum retry number for adjusting the UI styling
    public static navBtnMaxTextLength = 25; // maximum navigation button text length
    public static defaultLoadingTime = 250;
    public static searchingPageSize = 50; // searching page size
    public static searchingDelay = 250; // searching delay
    public static recentSearchItemCount = 20; // keeps 20 items in search history queue,if the 21st comes in,removes the 1st one.
    public static defaultRestapiTimeoutSpan = 60000; // timeout duration for restapi calls
    public static uploadRestapiTimeoutSpan = 5 * 60 * 1000; // timeout duration for restapi calls
    public static loginTimeoutSpan = 7500;
    public static defaultAdvFileFilter = '((file is) and ((! entities contains long-name "EMAIL_SPOOLER") and (! entities contains id "' + businessConstants.entity.EMAIL_SPOOLER_ID + '")))';
    public static defaultAdvFileFilterForFileView = '((! entities contains long-name "EMAIL_SPOOLER") and (! entities contains id "' + businessConstants.entity.EMAIL_SPOOLER_ID + '"))';
    public static advFilterForNotes = '(entry-class equals "note")';
    public static advFilterForEvents = '(entry-class equals "event")';
    public static advFilterNotIncludeEmailSpooler = '((! entities contains long-name "EMAIL_SPOOLER") and (! entities contains id "'
        + businessConstants.entity.EMAIL_SPOOLER_ID + '"))';
    public static defaultAdvFilter = '(' + AppConfig.advFilterForNotes + ' and ' + AppConfig.advFilterNotIncludeEmailSpooler + ')';
    public static defaultAdvFilterEvent = '(' + AppConfig.advFilterForEvents + ' and ' + AppConfig.advFilterNotIncludeEmailSpooler + ')';
    public static defaultAdvFilterNoteOrEvent = '((' + AppConfig.advFilterForNotes + ' or ' + AppConfig.advFilterForEvents + ') and ' + AppConfig.advFilterNotIncludeEmailSpooler + ')';
    public static draftEntityFocusPrefix = 'draft=true&';
    public static userTypeId = businessConstants.EntityTypes.User_Id;
    public static allNotesScreenId = 'a1f329a2a3024412be00571c02152394'; // system 'All Notes' screen ID.
    public static myNotesScreenId = 'e9f34ed1bac142d9b662234bceaa2dc5'; // system 'My Notes' screen ID.
    public static privacyTeamPublicId = '000000'; // PrivacyConfiguration default PrivacyTeamPublicId
    public static privacyConfiguration = 'NotePrivacyConfiguration'; // PrivacyConfiguration.note
    public static noNetworkErrorMsg = 'NO NETWOR';
    public static couldNotFindHostname = 'specified hostname could not be found';
    // tslint:disable-next-line: max-line-length
    public static serviceUnavailable = 'Service Temporarily Unavailable'; // don't modify this because this matches the '503 Service Temporarily Unavailable' error message
    public static couldNotConnectToServer = 'Could not connect to the server'; // don't modify this
    public static timedOutErrorMsg = 'timed out'; // don't modify this because this matches the http request timed out error message
    // tslint:disable-next-line: max-line-length
    public static timeOutErrorMsg = 'Timeout has occurred'; // don't modify this because this matches the http request timed out error message
    public static offlineErrorMsg = 'offline';
    public static unknowErrorMsg = 'unknowErrorMsg';

    // global data that can be changed
    public static serverAddress: string;
    public static username: string;
    public static password: string;
    public static userId = '';
    public static token = '';
    public static ssoIdpHomeUrl = '';
    public static ssoIdpSSOUrl = '';
    // tslint:disable-next-line: max-line-length
    public static fileDownloadings: TSDictionary<FileDownloadingItem> = new TSDictionary<FileDownloadingItem>(); // mark files that are being downloaded
    // tslint:disable-next-line: max-line-length
    public static fileUploadingDictionary: TSDictionary<FileUploadingItem> = new TSDictionary<FileUploadingItem>(); // mark files that are being uploading
    public static fileUploadedDictionary: TSDictionary<string> = new TSDictionary<string>(); // mark files that are uploaded
    public static pretreatmentFileList: string[] = []; // the server file name list of the files have been uploaded.

    // logic flags
    public static fileDownloadingMark = false; // mark if the app is downloading any files
    public static isFileUploadDone = false; // if all files have been uploaded.
    public static hasBeenOpenedOfAttachment = false; // mark the attachment has been opend.

    // file paths
    public static fileFolder = 'tamalerms/cache/files'; // note attachment folder
    public static noteMetadataFolder = 'tamalerms/cache/notemetadata'; // offline note metadata folder
    public static noteBodyFolder = 'tamalerms/cache/notebody'; // offline note body folder
    // tslint:disable-next-line: max-line-length
    public static fileUploadFolder = 'filepicker'; // the name limit is from Nativescript-mediafilepicker which uses this folder to cache files.
    public static logFolder = 'tamalerms/logs/';
    public static emailAttachmentsFolder = 'tamalerms/email';

    // endpoints
    public static serverVersion: string;
    public static platformVersion: string;
    public static credentialEndpoint = '/restapi/2.0/credential/';
    public static tokenEndpoint = '/restapi/2.0/tokenmobile/';
    public static tokenServerEndpoint = '/restapi/2.0/token/detection/'; // used to get existing token from server
    public static tokenEndpointPlugin = '/restapi/2.0/tokenplugin/';
    public static threadEndpoint = '/restapi/2.1/thread/';
    public static entityTypeEndpoint = '/restapi/2.1/entity-type';
    public static emailDigestEndpoint = '/restapi/2.0/emaildigest/';
    public static firmSettingEndpoint = '/restapi/2.0/firmsetting/';
    public static entryTypeEndpoint = '/restapi/2.1/entry-type/';
    public static entryAppendEntityEndpoint = '/restapi/2.0/entry/batch';
    public static entryEndpoint = '/restapi/2.1/entry/';
    public static entityEndpoint = '/restapi/2.1/entity/';
    public static entityOldEndpoint = '/restapi/2.0/entity/';
    public static entityGetByShortnameEndpoint = '/restapi/2.0/entity/'; // used only by checkbox control to get entity by shortname
    public static entityListEndpoint = '/restapi/2.0/entity/';
    public static employmentEndpoint = '/restapi/2.0/employment/'; // used for create the employment for contact
    public static eventEndpoint = '/restapi/2.0/event/';
    public static notificationEndpoint = '/restapi/2.0/reminder/';
    public static relationshipEndpoint = '/restapi/2.0/relationship/';
    public static relationshipTypeEndpoint = '/restapi/2.1/relationship-type/';
    public static newRelationshipEndpoint = '/restapi/2.1/relationship/';
    public static validRelationshipTypeEndpointByName = '/restapi/2.0/relationship-type/valid/';
    public static validRelationshipTypeEndpoint = '/restapi/2.0/valid-relationship-type';
    public static teamEndpoint = '/restapi/2.0/team/';
    public static teamPoint = '/restapi/2.1/team/';
    public static configurationDataEndpoint = '/restapi/2.0/configurationdata/';
    public static contactEndpoint = '/restapi/2.0/contact/';
    public static contactOldEndpoint = '/restapi/2.0/entity/';
    public static fileEndpoint = '/restapi/2.0/file/';
    public static fileLookupEndpoint = '/restapi/2.1/filelookup/';
    public static screenEndpoint = '/restapi/2.0/screen/';
    public static filterconfigEndpoint = '/restapi/2.1/filterconfig/';
    public static userEndpoint = '/restapi/2.0/user/';
    public static auditTrail = '/restapi/2.0/audit-log';
    public static auditTrailDownload = '/restapi/2.0/audit-log/download';
    public static blastEmailEndpoint = '/restapi/2.0/blastemail/';
    public static threadRefinebyEndpoint = '/restapi/2.1/thread/category/';
    public static homeScreenEndpoint = '/restapi/2.0/layout/home/';
    public static entityScreenEndpoint = '/restapi/2.0/layout/home/individual/';
    public static adhocEndpoint = '/restapi/2.0/adhoc/';
    public static adhocAttributeEndpoint = '/restapi/2.0/adhoc/attributes/';
    public static blastemailRefinebyEndpoint = '/restapi/2.0/blastemail/category/';
    public static tileEndPoint = '/restapi/2.0/tile/';
    public static marketdataEndPoint = '/restapi/2.1/marketdata/';
    public static dataproviderEndPoint = '/restapi/2.1/dataprovider/';
    public static layoutEndPoint = '/restapi/2.0/layout/';
    public static workFlowTaskEndpoint = '/restapi/2.0/workflow/task/';
    public static workflowProjectHistoryEndPoint = '/restapi/2.0/workflow/history/project/';
    public static helpEndPoint = '/restapi/2.1/help/key/';
    public static readonly portfolio = '/restapi/2.0/admin/portfolio/';
    /**
     * This api is used to fetch a target candidate list when an approval task is being sent back.
     */
    public static readonly workflowSendBackTargetEndPoint = '/restapi/2.0/workflow/task/{id}/back';
    public static workFlowTaskDefinitionEndpoint = '/restapi/2.0/workflow/task/definition/';
    public static workFlowTaskDefinitionBatchEndpoint = '/restapi/2.0/workflow/task/definition/batch/';
    public static workFlowTaskDefinitionTemplateEndpoint = '/restapi/2.0/workflow/task/definition/template/';
    public static workFlowEndpoint = '/restapi/2.0/workflow/';
    public static workFlowProcessInstanceEndpoint = '/restapi/2.0/workflow/process/instance/';
    public static workFlowProcessDefinitionEndpoint = '/restapi/2.0/workflow/process/definition/';
    public static workFlowStageEndpoint = '/restapi/2.0/workflow/stage/';
    public static workFlowFieldEndpoint = '/restapi/2.0/workflow/field/definition/';
    public static workFlowGateWayEndpoint = '/restapi/2.0/workflow/gateway/';
    public static entryRefinebyEndpoint = '/restapi/2.1/entry/category/';
    public static userSettingEndPoint = '/restapi/2.0/personsetting/';
    public static globalConfigEndPoint = '/restapi/2.0/globalconfig';
    public static initialConfigEndPoint = '/restapi/2.0/globalconfig/initialconfig';
    public static autoTagSettingsEndPoint = '/restapi/2.0/globalconfig/autotag/';
    public static createAutoTagModelEndPoint = '/restapi/2.0/globalconfig/getscript/';
    public static autoTagModelEndPoint = '/restapi/2.0/globalconfig/autotagmodel/';
    public static autoTagChangeStatusEndPoint = '/restapi/2.0/globalconfig/autotagstatus/';
    public static maptileChangeStatusEndPoint = '/restapi/2.0/globalconfig/maptile/';
    public static intralinksAdminSettingEndPoint = '/restapi/2.0/globalconfig/intralinks/';
    public static workflowAdminSettingEndPoint = '/restapi/2.0/globalconfig/workflow/';
    public static entryAdminSettingEndPoint = '/restapi/2.0/globalconfig/entry/';
    public static contactCorporateEntityEndpoint = '/restapi/2.0/location/';
    public static entityNearbyEndpoint = '/restapi/2.1/entity/';
    public static eventNearbyEndpoint = '/restapi/2.0/event/';
    public static intralinksEndPoint = '/restapi/2.0/intralinks/';
    public static defaultUnCategorizedId = '/restapi/2.1/entry/default/draft/entity/';
    // task management
    public static taskManagementQuickTask = '/restapi/2.0/quick/task/';
    public static taskManagementTaskCreator = '/restapi/2.0/quick/task/creator/';
    public static taskManagementTaskAssigned = '/restapi/2.0/quick/task/subtask/';
    // exchange mapping
    public static exchangeConfigCategory = '/restapi/2.1/exchange/config/category/';
    public static exchangeConfigInfo = '/restapi/2.1/exchange/config/info/';
    public static exchangeConfigConnect = '/restapi/2.1/exchange/config/connect/';
    public static exchangeConfigSync = '/restapi/2.1/exchange/config/sync/';
    public static exchangeConfigMap = '/restapi/2.1/exchange/config/map/';
    public static exchangeConfigDefaultMap = '/restapi/2.1/exchange/config/map/default/';
    public static exchangeConfigDataSource = '/restapi/2.1/exchange/config/datasource/';
    public static exchangeSynchContact = '/restapi/2.1/exchange/contact/sync/';
    public static exchangeImportContact = '/restapi/2.1/exchange/imports/';
    public static exchangeDuplicate = '/restapi/2.1/exchange/duplicate/';
    public static exchangeSubscriptionDomain = '/restapi/2.1/exchange/subscription/domain/';
    public static exchangeConfigSynchronization = '/restapi/2.1/exchange/config/synchronization/';
    public static exchangeSubscription = '/restapi/2.1/exchange/subscription/';
    public static exchangeConfigAutomation = '/restapi/2.1/exchange/config/automation/';
    public static exchangeComparison = '/restapi/2.1/exchange/comparison/';
    // email server setting && get outbound properties

    public static emailSetting = '/restapi/2.0/email-console/';
    // system config setting
    public static systemConfig = '/restapi/2.0/systemconfig';

    //#region MDL template APIs
    public static readonly mdlTemplateEndPoint = '/restapi/2.0/configurationdata/mdltemplate/';
    public static readonly templateFieldsConfigEndPoint = '/restapi/2.0/configurationdata/{id}/fields?type=mdl';
    public static readonly entityFieldsConfigEndPoint = '/restapi/2.0/configurationdata/{id}/fields?type=entities';
    //#endregion

    //#region admin console endpoints
    public static readonly streetEvents = '/restapi/2.0/admin/street-events/';
    public static readonly scheduledJobs = '/restapi/2.0/admin/scheduled-jobs/';
    public static readonly pythonScripts = '/restapi/2.0/admin/script/';
    public static readonly etlTask = '/restapi/2.0/admin/etl-task/';
    public static readonly etlTaskProperties = '/restapi/2.0/admin/etl-task/properties/';
    public static readonly usageReport = '/restapi/2.0/admin/usage-report/';
    public static readonly tokenManagement = '/restapi/2.0/token/';
    public static readonly cacheStore = '/restapi/2.0/admin/cache-store/';
    public static readonly cachePurge = '/restapi/2.0/admin/cache-purge/';
    public static readonly serverMonitoring = '/restapi/2.0/admin/server-monitor/jvm/';
    public static readonly clamFile = '/restapi/2.0/admin/clam-file/';
    public static readonly website = '/restapi/2.0/admin/website/';
    public static readonly centralServer = '/restapi/2.0/admin/central-server';
    public static readonly configureHTMLSecurity = '/restapi/2.0/admin/configure-html-security/';
    public static readonly abbreviation = '/restapi/2.0/admin/abbreviation/';
    public static readonly FMROverrides = '/restapi/2.0/admin/fmr-overrides/';
    public static readonly stopList = '/restapi/2.0/admin/stop-list/';
    public static readonly draftNoteEntity = '/restapi/2.0/admin/draft-note-entity';
    public static readonly convertAddress = '/restapi/2.0/admin/convert-address';
    //#endregion
    //#endregion

    // ui/feature flags
    public static ssoEnabled = false;
    public static supportSSO = false; // SSO is valid for 18.1.1 and later
    public static hasOnceLoggedIn = false;
    public static supportDraft = true;
    public static supportNoteTemplate = true;
    public static supportImgToStream = false; // rest api return image stream instead of image url for html of note body from version 18.1
    public static supportFileUpload = false;
    public static supportCalculatedSubject = true; // for 17.0.1 and before server, it doesn't support calculatedSubject

    // TODO: @Alan, move the following to ngrx/store
    public static entity: Entity = new Entity(); // for entity detial and relationship share data.
    public static contact: Contact = new Contact(); // for contact detial and relationship share data.


}
