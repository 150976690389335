/**
 * Created by Abner Sui on 01/23/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from '../../widgets/widgets.module';
import { NoteDetailComponent } from './note-detail.component';
import { NoteDetailBodyComponent } from './note-detail-body.component';
import { NoteDetailHeaderComponent } from './note-detail-header.component';
import { PopoverModule } from '../popover/popover.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
        PopoverModule,
        TooltipModule,
    ],
    declarations: [
        NoteDetailComponent,
        NoteDetailBodyComponent,
        NoteDetailHeaderComponent,
    ],
    exports: [
        NoteDetailComponent,
        NoteDetailBodyComponent,
        NoteDetailHeaderComponent,
    ],
    providers: []
})
export class NoteDetailModule {
}
