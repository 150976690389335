/**
 * created by Alex on 8/10/2020.
 * Description:
 *
 * ------ maintenance history ------
 */
export class AutoTagging {
    enable: boolean;
    enableForLiveWire: boolean;
    arrayModel: Array<string>;
    model: string;
    noteTypes: string;
    confidence: number;
    constructor(enable: boolean = false, enableForLiveWire: boolean = false, model: string = 'Tamale Auto Tag Model', noteTypes: string = '', confidence = 0.5) {
        this.enable = enable;
        this.enableForLiveWire = enableForLiveWire;
        this.model = model;
        this.noteTypes = noteTypes;
        this.confidence = confidence;
    }

    static parse(serverResponse): AutoTagging {
        const autoTagging = new AutoTagging();

        if (serverResponse) {
            autoTagging.enable = serverResponse.enable;
            autoTagging.enableForLiveWire = serverResponse['enable-for-livewire'];
            autoTagging.model = serverResponse.model;
            autoTagging.noteTypes = (serverResponse.notetypes && serverResponse.notetypes.length > 0) ? serverResponse.notetypes.join(',') : '';
            autoTagging.confidence = serverResponse.confidence;
        }
        return autoTagging;
    }
}
