/**
 * Created by Yu Zhang on 8/23/17.
 * Description:
 *
 * ------ maintenance history ------
 * Update  9/16/20  MarcusZhao add childEntityFilter
 */

import { Injectable } from '@angular/core';

@Injectable()
export class AdvFilterHelperService {

    constructor() { }

    /**
     * format adv filter string in a url format
     * param advFilter
     */
    formatToUrl(advFilter) {
        advFilter = advFilter.replace(/ /g, '+');
        return advFilter.replace(/\"/g, '%22');
    }

    joinFiltersByOr(filterArray, cascading = false) {
        return this.joinFilter(filterArray, 'or', cascading);
    }

    joinFiltersByAnd(filterArray, cascading = false) {
        return this.joinFilter(filterArray, 'and', cascading);
    }

    /**
     * join adv filters together accroding to the join option
     * @param filterArray : advfilter string array
     * @param joinOption : 'and' or 'or'
     * @param cascading : true: (A and B and C); false: ((A and B) and C)
     */
    joinFilter(filterArray, joinOption, cascading = false): string {
        if (!filterArray) {
            return '';
        }
        if (filterArray.length === 0) {
            return null;
        } else if (filterArray.length === 1) {
            return filterArray[0];
        }
        let result = filterArray[0];
        filterArray.shift();
        const joinLogic = joinOption ? joinOption : 'and';
        if (cascading) {
            for (const filter of filterArray) {
                result += ' ' + joinLogic + ' ' + filter;
            }
            return '(' + result + ')';
        } else {
            for (const filter of filterArray) {
                result = '(' + result + ' ' + joinLogic + ' ' + filter + ')';
            }
            return result;
        }
    }

    /**
     * create an entity adv filter string
     * @param filterOption : 'any of' or 'all of' or 'none of'
     * @param filterValue: filter value
     * @param filterField : data property name
     * @param isPlainText : if the filter value is just string or an object with id
     */
    entityFilter(filterValue: any, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true) {
        const value = '"' + (isPlainText ? filterValue : filterValue[filterField]) + '"';
        if (filterOption.toLowerCase() !== 'none of') {
            return '(' + filterField + ' equals ' + value + ')';
        } else {
            return '(! ' + filterField + ' equals ' + value + ')';
        }
    }

    /**
     * create an entity adv filter string
     * @param filterOption : 'any of' or 'all of' or 'none of'
     * @param filterValue: filter value
     * @param filterField : data property name
     * @param isPlainText : if the filter value is just string or an object with id
     */
    entityIdsFilter(filterValue: Array<any>, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true) {
        let value;
        if (isPlainText) {
            value = '"' + filterValue.join(',') + '"';
        } else {
            value = [];
            filterValue.forEach(item => {
                value.push(item[filterField]);
            });
            value = '"' + value.join(',') + '"';
        }
        if (filterOption.toLowerCase() !== 'none of') {
            return '(' + filterField + ' belongsto ' + value + ')';
        } else {
            return '(! ' + filterField + ' belongsto ' + value + ')';
        }
    }

    /**
     * create an entity adv filter string
     * @param filterOption : 'any of' or 'all of' or 'none of'
     * @param filterValue: filter value
     * @param filterField : data property name
     * @param isPlainText : if the filter value is just string or an object with id
     */
    entityTypeFilter(filterValue: string, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true) {
        const value = '"' + (isPlainText ? filterValue : filterValue[filterField]) + '"';
        if (filterOption.toLowerCase() !== 'none of') {
            return '(entity-type ' + filterField + ' equals ' + value + ')';
        } else {
            return '(! entity-type ' + filterField + ' equals ' + value + ')';
        }
    }

    /**
     * create a note type adv filter string
     * @param filterOption : 'none of' or 'any of'
     * @param filterValue: filter value
     * @param filterField : data property name
     * @param isPlainText : if the filter value is just string or an object with id
     */
    entryTypeFilter(filterValue: any, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true) {
        const value = '"' + (isPlainText ? filterValue : filterValue[filterField]) + '"';
        if (filterOption.toLowerCase() !== 'none of') {
            return '(entry-type ' + filterField + ' equals ' + value + ')';
        } else {
            return '(! entry-type ' + filterField + ' equals ' + value + ')';
        }
    }

    /**
     * create a priority filter adv filter string
     * @param filterOption : check below swtich cases
     * @param filterValue: filter value
     */
    priorityFilter(filterValue, filterOption: string) {
        switch (filterOption.toLowerCase()) {
            case 'greater than':
                return '(priority greater-than "' + filterValue + '")';
            case 'greater than or equal to':
                return '((priority greater-than "' + filterValue + '") or (priority equals "' + filterValue + '"))';
            case 'equals':
                return '(priority equals "' + filterValue + '")';
            case 'not equal to':
                return '(! priority equals "' + filterValue + '")';
            case 'less than':
                return '(priority less-than "' + filterValue + '")';
            case 'less than or equal to':
                return '((priority less-than "' + filterValue + '") or (priority equals "' + filterValue + '"))';
            default:
                return null;
        }
    }

    /**
     * create a sentiment adv filter string
     * @param filterOption : 'show' or 'hide'
     * @param filterValue: filter value
     */
    sentimentFilter(filterValue, filterOption: string) {
        if (filterOption.toLowerCase() === 'show') {
            return '(sentiment equals "' + filterValue + '")';
        } else if (filterOption.toLowerCase() === 'hide') {
            return '(! sentiment equals "' + filterValue + '")';
        }
        return null;
    }

    /**
     * create a source adv filter string
     * @param filterOption : 'none of' or 'any of'
     * @param filterValue: filter value
     * @param filterField : data property name
     * @param isPlainText : if the filter value is just string or an object with id
     */
    sourceFilter(filterValue: any, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true) {
        const value = '"' + (isPlainText ? filterValue : filterValue[filterField]) + '"';
        if (filterOption.toLowerCase() !== 'none of') {
            return '(source ' + filterField + ' equals ' + value + ')';
        } else {
            return '(! source ' + filterField + ' equals ' + value + ')';
        }
    }

    /**
     * create a subject adv filter string
     * @param filterOption : 'show' or 'hide'
     * @param filterValue: filter value
     */
    subjectFilter(filterOption: string, filterValue) {
        if (filterOption.toLowerCase() === 'show') {
            return '(title contains "' + filterValue + '")';
        } else if (filterOption.toLowerCase() === 'hide') {
            return '(! title contains "' + filterValue + '")';
        }
        return null;
    }

    /**
     * create a submitter adv filter string
     * @param filterOption : 'none of' or 'any of'
     * @param filterValue: filter value
     * @param filterField : data property name
     * @param isPlainText : if the filter value is just string or an object with id
     */
    submitterFilter(filterValue: any, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true) {
        const value = '"' + (isPlainText ? filterValue : filterValue[filterField]) + '"';
        if (filterOption.toLowerCase() !== 'none of') {
            return '(submitter ' + filterField + ' equals ' + value + ')';
        } else {
            return '(! submitter ' + filterField + ' equals ' + value + ')';
        }
    }

    /**
     * create a relationship type adv filter string
     * @param filterOption：filter option
     * @param filterValue: filter value
     * @param filterField：filter field
     * @param isPlainText : if the filter value is just string or an object with id
     */
    relationshipTypeFilter(filterValue: any, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true) {
        const value = '"' + (isPlainText ? filterValue : filterValue[filterField]) + '"';
        if (filterOption.toLowerCase() !== 'none of') {
            return '(relationship-type ' + filterField + ' equals ' + value + ')';
        } else {
            return '(! relationship-type ' + filterField + ' equals ' + value + ')';
        }
    }

    /**
     * create a parent entity adv filter string
     * @param filterValue: filter value
     * @param filterField: filter field
     * @param filterOption: filter option
     * @param isPlainText : if the filter value is just string or an object with id
     */
    parentEntityFilter(filterValue: any, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true) {
        const value = '"' + (isPlainText ? filterValue : filterValue[filterField]) + '"';
        if (filterOption.toLowerCase() !== 'none of') {
            return '(parent-entity ' + filterField + ' equals ' + value + ')';
        } else {
            return '(! parent-entity ' + filterField + ' equals ' + value + ')';
        }
    }

    /**
     * create a child entity adv filter string
     * @param filterValue: filter value
     * @param filterField: filter field
     * @param filterOption: filter option
     * @param isPlainText : if the filter value is just string or an object with id
     */
    childEntityFilter(filterValue: any, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true) {
        const value = '"' + (isPlainText ? filterValue : filterValue[filterField]) + '"';
        if (filterOption.toLowerCase() !== 'none of') {
            return '(child-entity ' + filterField + ' equals ' + value + ')';
        } else {
            return '(! child-entity ' + filterField + ' equals ' + value + ')';
        }
    }

    /**
     * create an normal adv filter string
     * @param filterProperty: filter property
     * @param filterValue: filter value
     * @param filterField: filter field
     * @param filterOption: filter option
     */
    advFilter(filterProperty, filterValue, filterField = 'id', filterOption = 'any of') {
        if (filterOption !== 'none of') {
            return '(' + filterProperty + ' ' + filterField + ' equals "' + filterValue + '")';
        } else {
            return '(! ' + filterProperty + ' ' + filterField + ' equals "' + filterValue + '")';
        }
    }

    /**
     * create a concat adv filter string for sevearl entities
     * @param joinOption : join by 'or' or 'and'
     * @param filterOption: filter option
     * @param filterValues: filter values
     * @param filterField: filter field
     * @param isPlainText: is plain text
     * @param cascading: cascading
     */
    entityFilterSet(joinOption: string = 'or', filterValues, filterField: string = 'id',
        filterOption: string = 'any of', isPlainText = true, cascading = false) {
        const filters = [];
        for (const value of filterValues) {
            filters.push(this.entityFilter(value, filterField, filterOption, isPlainText));
        }
        return this.joinFilter(filters, joinOption.toLowerCase(), cascading);
    }

    /**
     * create a concat adv filter string for sevearl entities
     * @param joinOption : join by 'or' or 'and'
     * @param filterOption: filter option
     * @param filterValues: filter values
     * @param filterField: filter field
     * @param isPlainText: is plain text
     * @param cascading: cascading
     */
    entityTypeFilterSet(joinOption: string = 'or', filterValues, filterField: string = 'id',
        filterOption: string = 'any of', isPlainText = true, cascading = false) {
        const filters = [];
        for (const value of filterValues) {
            filters.push(this.entityTypeFilter(value, filterField, filterOption, isPlainText));
        }
        return this.joinFilter(filters, joinOption.toLowerCase(), cascading);
    }

    /**
     * create a concat adv filter string for sevearl entities
     * @param joinOption : join by 'or' or 'and'
     * @param filterOption: filter option
     * @param filterValues: filter values
     * @param filterField: filter field
     * @param isPlainText: is plain text
     * @param cascading: cascading
     */
    entityTypeFilterSetQuick(filterValues, filterField: string = 'id', filterOption: string = 'any of',
        isPlainText = true, cascading = false) {
        if (filterOption.toLowerCase() !== 'none of') {
            return '(entity-type ' + filterField + ' belongsto "' + filterValues.join(',') + '")';
        } else {
            return '(! entity-type ' + filterField + ' belongsto "' + filterValues.join(',') + '")';
        }
    }

    /**
     * createa a concat adv filter string for several note types
     * @param joinOption : join by 'or' or 'and'
     * @param filterOption: filter option
     * @param filterValues: filter values
     * @param filterField: filter field
     * @param isPlainText: is plain text
     * @param cascading: cascading
     */
    entryTypeFilterSet(joinOption: string = 'or', filterValues, filterField: string = 'id',
        filterOption: string = 'any of', isPlainText = true, cascading = false) {
        const filters = [];
        for (const value of filterValues) {
            filters.push(this.entryTypeFilter(value, filterField, filterOption, isPlainText));
        }
        return this.joinFilter(filters, joinOption.toLowerCase(), cascading);
    }

    /**
     * create a concat adv filter string for several sources
     * @param joinOption : join by 'or' or 'and'
     * @param filterOption: filter option
     * @param filterValues: filter values
     * @param filterField: filter field
     * @param isPlainText: is plain text
     * @param cascading: cascading
     */
    sourceFilterSet(joinOption: string = 'or', filterValues, filterField: string = 'id',
        filterOption: string = 'any of', isPlainText = true, cascading = false) {
        const filters = [];
        for (const value of filterValues) {
            filters.push(this.sourceFilter(value, filterField, filterOption, isPlainText));
        }
        return this.joinFilter(filters, joinOption.toLowerCase(), cascading);
    }

    /**
     * create a concat adv filter string for several submitters
     * @param joinOption : join by 'or' or 'and'
     * @param filterOption: filter option
     * @param filterValues: filter values
     * @param filterField: filter field
     * @param isPlainText: is plain text
     * @param cascading: cascading
     */
    submitterFilterSet(joinOption: string = 'or', filterValues, filterField: string = 'id',
        filterOption: string = 'any of', isPlainText = true, cascading = false) {
        const filters = [];
        for (const value of filterValues) {
            filters.push(this.submitterFilter(value, filterField, filterOption, isPlainText));
        }
        return this.joinFilter(filters, joinOption.toLowerCase(), cascading);
    }

    entityFilterSetByOr(filterValues, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true, cascading = false) {
        return this.entityFilterSet('or', filterValues, filterField, 'any of', isPlainText, cascading);
    }

    entityFilterSetByAnd(filterValues, filterField: string = 'id', filterOption: string = 'any of', isPlainText = true, cascading = false) {
        return this.entityFilterSet('and', filterValues, filterField, 'any of', isPlainText, cascading);
    }

    /**
     * common funtion to generate advfilter
     * param {*} field: field
     * param {*} operator: operator
     * param {*} value: value
     * param {*} type: type
     */
    generateFilterStr(field, operator, value, type) {
        let tmpField = '';
        if (type === 'adhoc') {
            tmpField = 'property "' + field + '"';
        } else {
            tmpField = field;
        }

        if (operator === 'blank') {
            return '(' + tmpField + ' equals "")';
        } else if (operator === 'notblank') {
            return '(! ' + tmpField + ' equals "")';
        } else if (operator === 'notcontains') {
            return '(! ' + tmpField + ' contains "' + value + '")';
        } else {
            if (value instanceof Array) {
                return '(' + tmpField + ' ' + operator + ' "' + value.join(',') + '")';
            } else {
                return '(' + tmpField + ' ' + operator + ' "' + value + '")';
            }
        }
    }
}
