/**
 * Created by Abner Sui on 07/08/2020.
 * Description:
 *
 * ------ maintenance history ------
 * Updated by Simon Zhao on 08/25/2022 stop click event's propagation of file links.
 * Updated by Simon Zhao on 12/27/2022 add a flag to determine whether the file should be previewed rather than be downloaded.
 */

import { Component, OnInit, Input } from '@angular/core';
import { FileInfo } from '../../../tamalelibs/models/workflow.model';
import { FileHelperService } from '../../../services/file-helper.service';
import { UtilsService } from '../../../tamalelibs/services/utils.service';
import { AlertWindowService } from '../../../widgets/alert-window/alert-window.service';
import { MAX_PREVIEW_FILE_SIZE } from '../../../tamalelibs/constants/business.constants';
import { AlertBtnTypes, AlertButton } from '../../../widgets/alert-window/alert-window.model';
import { StringLiteralsPipe } from '../../../pipes/translate.pipe';

@Component({
    selector: 'tam-file-info',
    templateUrl: './file-info.component.html',
    styleUrls: ['./file-info.component.scss']
})
export class FileInfoComponent implements OnInit {

    @Input()
    config: Array<FileInfo>;

    /**
     * a flag indicating whether preview the file rather than download it on clicking.
     */
    @Input()
    isDefaultToPreview = false;

    constructor(
        private _fileHelper: FileHelperService,
        private _alertWindow: AlertWindowService,
    ) { }

    ngOnInit() {
    }

    onClick(event, item: FileInfo): void {
        if (item.fileId) {
            UtilsService.stopPropagation(event);
            const fileUrl = '/restapi/2.0/workflow/task/' + item.taskId + '/filedata/' + item.id;
            if (this.isDefaultToPreview && this._fileHelper.isFilePreviewable(item)) {
                if (item.fileSize > MAX_PREVIEW_FILE_SIZE) {
                    const downloadBtn: AlertButton = {
                        text: StringLiteralsPipe.translate('general.download'),
                        type: AlertBtnTypes.primary
                    };
                    const cancelBtn: AlertButton = {
                        text: StringLiteralsPipe.translate('general.ok'),
                        type: AlertBtnTypes.tertiary
                    };
                    const subscription = this._alertWindow.custom('Warning', [StringLiteralsPipe.translate('general.can_not_preview_due_to_file_size')], cancelBtn, downloadBtn)
                        .subscribe((result: boolean) => {
                            if (result) {
                                this._fileHelper.downloadFile(fileUrl);
                            }
                            subscription.unsubscribe();
                        });
                } else {
                    this._fileHelper.previewFile(item.fileId, fileUrl, item.extension.replace('.', ''), item.fileName, false, false, true);
                }
            } else {
                this._fileHelper.downloadFile(fileUrl);
            }
        }
    }
}
