/**
 * Created by Bowen on 06/10/2019.
 * Description: change liveware name
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum SubChromeActionTypes {
    // change the sub-chrome name
    CHANGE_LIVEWIRE_SUBCHORME_NAME = '[SUB_CHROME_NAME] CHANGE_LIVEWIRE_SUBCHORME_NAME',
    CHANGE_LIVEWIRE_SUBCHORME_NAME_SUCCESS = '[SUB_CHROME_NAME] CHANGE_LIVEWIRE_SUBCHORME_NAME_SUCCESS',
    CHANGE_LIVEWIRE_SUBCHORME_NAME_FAILED = '[SUB_CHROME_NAME] CHANGE_LIVEWIRE_SUBCHORME_NAME_FAILED',
    // get the sub-chrome name
    GET_LIVEWIRE_SUBCHORME_NAME = '[SUB_CHROME_NAME] GET_LIVEWIRE_SUBCHORME_NAME',
    GET_LIVEWIRE_SUBCHORME_NAME_SUCCESS = '[SUB_CHROME_NAME] GET_LIVEWIRE_SUBCHORME_NAME_SUCCESS',
    GET_LIVEWIRE_SUBCHORME_NAME_FAILED = '[SUB_CHROME_NAME] GET_LIVEWIRE_SUBCHORME_NAME_FAILED'
}


export class SubChromeNameChange implements Action {
    readonly type = SubChromeActionTypes.CHANGE_LIVEWIRE_SUBCHORME_NAME;

    constructor(public payload: any) { }
}

export class SubChromeNameChangeSuccess implements Action {
    readonly type = SubChromeActionTypes.CHANGE_LIVEWIRE_SUBCHORME_NAME_SUCCESS;

    constructor(public payload: any) { }
}

export class SubChromeNameChangeFaild implements Action {
    readonly type = SubChromeActionTypes.CHANGE_LIVEWIRE_SUBCHORME_NAME_FAILED;

    constructor(public payload: any) { }
}

export class SubChromeNameGet implements Action {
    readonly type = SubChromeActionTypes.GET_LIVEWIRE_SUBCHORME_NAME;

    constructor(public payload: any) { }
}

export class SubChromeNameGetSuccess implements Action {
    readonly type = SubChromeActionTypes.GET_LIVEWIRE_SUBCHORME_NAME_SUCCESS;

    constructor(public payload: any) { }
}

export class SubChromeNameGetFaild implements Action {
    readonly type = SubChromeActionTypes.GET_LIVEWIRE_SUBCHORME_NAME_FAILED;

    constructor(public payload: any) { }
}
export type SubChromeActions = SubChromeNameChange |
    SubChromeNameChangeSuccess |
    SubChromeNameChangeFaild |
    SubChromeNameGet |
    SubChromeNameGetSuccess |
    SubChromeNameGetFaild;
