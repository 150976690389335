<div class="grid-container" #gridContainer>
    <ag-grid-angular [ngClass]="{'with-footer': bottomDataSource.length > 0}" #topGrid class="ag-theme-balham top-grid"
        [gridOptions]="topGridOptions" [columnDefs]="topGridColumnDefinitions"
        [suppressHorizontalScroll]="isSuppressHorizontalScroll" [rowData]="topGridDataSource"
        [excelStyles]="topExcelStyle" [aggFuncs]="aggregationFuncs" [components]="frameworkComponents"
        [groupRowInnerRenderer]="groupRowInnerRenderer" [groupRowRendererParams]="groupRowRendererParams"
        (bodyScroll)="onBodyScroll($event)" (gridReady)="onTopGridReady($event)"
        (filterChanged)="onTopGridFilterChanged($event)" (columnVisible)="onTopGridColumnVisibleChanged($event)"
        (columnMoved)="onTopGridColumnMoved($event)" (columnPinned)="onTopGridColumnPinned($event)"
        (columnResized)="onTopGridColumnResized($event)" (rowGroupOpened)="onTopGridRowGroupOpened($event)"
        (columnRowGroupChanged)="onTopGridColumnRowGroupChanged($event)"
        (expandOrCollapseAll)="onTopGridExpandOrCollapseAll($event)" (cellClicked)="onTopGridCellClicked($event)"
        (rowClicked)="onTopGridRowClicked($event)" [getRowNodeId]="getRowNodeId" [suppressAggFuncInHeader]=true>
    </ag-grid-angular>
    <div *ngIf="isBottomGridVisible" class="bottom-grid-container">
        <span class="bottom-total"> {{'general.total' | stringLiterals}}</span>
        <ag-grid-angular #bottomGrid class="ag-theme-balham bottom-grid" [rowData]="bottomDataSource"
            [gridOptions]="bottomGridOptions" headerHeight="0" [columnDefs]="bottomGridColumnDefinitions"
            [aggFuncs]="aggregationFuncs" [components]="frameworkComponents"
            [groupRowInnerRenderer]="groupRowInnerRenderer" [groupRowRendererParams]="groupRowRendererParams"
            (gridReady)="onBottomGridReady($event)">
        </ag-grid-angular>
    </div>
</div>