
/**
 * Created by Alex Xia on 26/11/2021.
 * Description: Add a module to be imported seperately
 *
 * ------ maintenance history ------
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';

@NgModule({
    declarations: [
        ButtonComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ButtonComponent
    ],
})
export class ButtonModule { }
