/**
 * Created by Daniel Wang on 2/8/2024.
 * Description:
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum ActionHistoryActionTypes {
    REMOVE_ITEM = '[ACTION_HISTORY] REMOVE_ITEM',
}

export class RemoveItem implements Action {
    readonly type = ActionHistoryActionTypes.REMOVE_ITEM;
    constructor(public payload: any) { }
}

export type ActionHistoryActions = RemoveItem;
