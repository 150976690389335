<div class="container">
    <div class="alert-message" [ngClass]="{'success' : hasNotice&&alertMessage, 'fail' : hasError&&alertMessage}">
        <span class="message-text">{{alertMessage}}</span>
    </div>
    <div class="logo">
        <embed src="./assets/images/logo_SSnC_ADVENT.svg" alt="SS&C | ADVENT">
        <div class="application-name">TAMALE RMS</div>
    </div>
    <div class="input-form">
        <form #inputForm="ngForm">
            <div class="title">
                <span>Log In</span>
            </div>
            <input type="text" class="login-form-control" [(ngModel)]="username" required name="name" autocomplete="off"
                autocorrect="off" autocapitalize="none" autofocus placeholder="Username" #usernameInput />
            <input type="password" autocomplete="off" class="login-form-control" [(ngModel)]="password" required
                name="password" placeholder="Password">
            <button tam-button [tamType]="'primary'" [tamSize]="'lg'" [tamLoading]="isBusy" (click)="login()"
                class="login-button tam-btn">LOG
                IN</button>
        </form>
    </div>
    <div class="contact-support-container">
        <a class="login-trouble-label" href="mailto:{{contactEmail}}">Having trouble logging in?</a>
    </div>
</div>
