import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'tam-html-container',
    templateUrl: './html-container.component.html',
    styleUrls: ['./html-container.component.scss']
})
export class HtmlContainerComponent implements OnInit {
    @Input() html: string;

    constructor() { }

    ngOnInit() {
    }

    getHighlightedNoteBody() {
        if (this.html) {
            return this.html;
        } else {
            return '';
        }
    }

}
