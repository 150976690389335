<div class="container">
    <div *ngIf="!isPrintMode">
        <div class="right-top-corner">
            <smart-icon tid="note_detail_note_sentiment" name="sentiment"
                [ngClass]="{'positive': note.sentiment === 1, 'negative': note.sentiment === -1}"></smart-icon>
        </div>
        <div class="container-item">
            <div class="item-row first">
                <div class="subject-area" #subject [ngClass]="{'calculated-subject': !note.subject}" kendoTooltip
                    position="bottom"
                    tooltipClass="{{isLongSubject(note.calculatedSubject) ? 'widen-tooltip-400' : 'normal-tooltip' }}">
                    <span tid="note_detail_note_subject"
                        [title]="note.calculatedSubject">{{note.calculatedSubject}}</span>
                </div>
                <div tid="note_detail_note_priority" class="priority-area">
                    <priority-star [value]="note.priority"></priority-star>
                </div>
                <div tid="note_detail_note_position" class="sequence-area"
                    *ngIf="note.totalNotesInThread>1&&!isEmbedMode">
                    <span>({{note.threadPosition + 1}}/{{note.totalNotesInThread}})</span>
                </div>
            </div>
            <div class="item-row second" kendoTooltip position="bottom">
                <div tid="note_detail_note_info" #submitInfo class="submit-info-area">
                    <div [title]="getSubmitInfoTitle()" class="submit-info">
                        <span class="note-type-info">{{note.type.name}}</span>
                        <div class='source-box'>&nbsp;from&nbsp;
                            <span (click)="viewContactDetailInSideSheet(note.source,$event)"
                                class="source-info">{{note.source.name}}</span>
                            <span *ngIf="note.source.company"> of
                                {{note.source.company}} </span>
                        </div>
                        <span class="date-box">&nbsp;on
                            <span *ngIf="!note.backdated">{{getWeekdayByDate(note.displayDate)}},&nbsp;</span>
                            <span *ngIf="!note.backdated">{{note.displayDate | date: 'dd MMM yyyy, HH:mm'}}
                            </span>
                            <span *ngIf="note.backdated">{{note.displayDate | date: 'dd MMM yyyy'}}</span>
                        </span>
                        <span class="submitter-box">
                            <span *ngIf="showHideFlags.submitter||showHideFlags.submittedDate">
                                &nbsp;(Submitted
                                <span class="submitter" *ngIf="showHideFlags.submitter">
                                    &nbsp;by
                                    <span (click)="viewContactDetailInSideSheet(note.submitter,$event)"
                                        class="source-info">
                                        &nbsp;{{note.submitter.name}}
                                    </span>
                                </span>
                                <span class="submitted-date" *ngIf="showHideFlags.submittedDate">
                                    &nbsp;on
                                    {{getWeekdayByDate(note.submittedDate)}},&nbsp;{{note.submittedDate | date: 'dd MMM
                                    yyyy, HH:mm'}}
                                </span>)
                            </span>
                        </span>
                    </div>
                </div>
                <div tid="note_detail_note_attachment_clip_count" class="attachment-area"
                    *ngIf="note.attachments.size > 0" (click)="onShowAttachmentList($event)">
                    <smart-icon name="attachment" class="attachment-icon"></smart-icon>
                    <div class="number">&nbsp;{{note.attachments.size}}</div>
                </div>
                <div tid="note_detail_note_meatball" class="operation-area" *ngIf="showMeatballInEvent">
                    <smart-icon name="meatball" class="light" (click)="onShowContextMenu($event)"></smart-icon>
                </div>
                <div *ngIf="!isEmbedMode" tid="note_detail_note_expand" class="toggle-area">
                    <smart-icon [name]="collapsed ? 'angle-up' : 'angle-down'" (click)="onToggle()"></smart-icon>
                </div>
            </div>
            <div class="item-row third" *ngIf="isEvent">
                <div class="date-area">
                    <smart-icon name="calendar" class="date"></smart-icon>
                    <span class="date-value">{{date}}</span>
                </div>
            </div>
            <div class="item-row forth" *ngIf="isEvent">
                <div class="particpants-area">
                    <smart-icon name="users-o" class="particpants-icon"></smart-icon>
                    <div class="particpants-value-container-min" *ngIf="!isShowMaximun">
                        <span class="particpants-values" (click)="viewContactDetailInSideSheet(item,$event)"
                            *ngFor="let item of particpants;let last=last;trackBy:trackByFn">{{item["short-name"]}}{{last
                            ? '' : '; '}}</span>
                    </div>
                    <div class="particpants-value-container-max" *ngIf="isShowMaximun">
                        <span class="particpants-values" (click)="viewContactDetailInSideSheet(item,$event)"
                            *ngFor="let item of particpants;let last=last;trackBy:trackByFn">{{item["short-name"]}}{{last
                            ? '' : '; '}}</span>
                    </div>
                </div>
            </div>
            <div class="item-row fifth" *ngIf="isEvent">
                <div class="location-info">
                    <div class="location-area" kendoTooltip position="bottom" [title]="locationTooltip"
                        (click)="onShowMapTile()" (mouseenter)="onLocationHovered()"
                        (mouseleave)="onLocationHoverLeave()">
                        <smart-icon
                            [name]="isLocationHovered && isExistAvailableCoordinate?'location-hovering':'location'">
                        </smart-icon>
                        <span class="location-value">{{locationShowInScreen}}</span>
                    </div>
                    <div class="location-change-message" *ngIf="isShowLinkedEntityUpdateInfo">
                        <div *ngIf="isLinkedEntityAddressUpdatedInSingleAddress">
                            <div *ngIf="!isNoUpdatePermission">
                                <span class="message-info">{{linkedEntityAddressType}} Address of
                                    {{linkedEntityLongName}}
                                    has been changed, please </span>
                                <span (click)="updateLocationInfo($event)" class="message-update"> update the
                                    location </span>
                                <span>of this event.</span>
                            </div>
                            <div *ngIf="isNoUpdatePermission">
                                <span class="message-info">{{linkedEntityAddressType}} Address of
                                    {{linkedEntityLongName}}
                                    has been changed, You do not have permission to update the event.</span>
                            </div>
                        </div>
                        <div class="multiple-address" *ngIf="isLinkedEntityAddressUpdatedInMultipleAddress">
                            <div *ngIf="!isNoUpdatePermission">
                                <tam-single-select-dropdown-filter [dropdownConfig]="linkedEntityAddressConfig">
                                    <ng-template #customContent>
                                        <div>
                                            {{linkedEntityAddressType}} address of {{linkedEntityLongName}} has been
                                            changed, please&nbsp;<a class="selected-item"
                                                (click)="onLinkedEntityAddressItemClick($event)">{{availableLocation}}</a>&nbsp;of
                                            this event.
                                        </div>
                                    </ng-template>
                                </tam-single-select-dropdown-filter>
                            </div>
                            <div *ngIf="isNoUpdatePermission">
                                <span class="message-info">{{linkedEntityAddressType}} Address of
                                    {{linkedEntityLongName}}
                                    has been changed, You do not have permission to update the event.</span>
                            </div>
                        </div>
                        <div *ngIf="isLinkedEntityAddressDeleted">
                            <span class="message-info">{{linkedEntityAddressType}} address of {{linkedEntityLongName}}
                                is cleared, please confirm the location of this event.</span>
                        </div>
                        <div *ngIf="isLinkedEntityDeleted">
                            <span class="message-info">The associated entity/contact is deleted, please confirm the
                                location of this event.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="attachment-icon-click">
            <popover [config]="popoverConfig" (delete)="onDeleteClick($event)"></popover>
        </div>
        <tam-context-menu tid="note_detail_note_menu" [config]="contextMenuConfig"></tam-context-menu>
    </div>
</div>

<!-- for print notes  -->
<div *ngIf="isPrintMode" class="container-item">
    <span style="font-size: 20px;">{{note.calculatedSubject}}</span>
    <div class="item-row second" kendoTooltip position="bottom">
        <div tid="note_detail_note_info" #submitInfo class="submit-info-area">
            <div [title]="getSubmitInfoTitle()" class="submit-info">
                <span class="note-type-info">{{note.type.name}}</span>
                <span class='source-box'>&nbsp;from&nbsp;
                    <a (click)="viewContactDetailInSideSheet(note.source,$event)" class="source-info"
                        [href]="'contact/'+note.source.id">{{note.source.name}}</a>
                    <span *ngIf="note.source.company"> of {{note.source.company}}</span>
                </span>
                <span class="date-box">&nbsp;on
                    <span *ngIf="!note.backdated">{{getWeekdayByDate(note.displayDate)}},&nbsp;</span>
                    <span *ngIf="!note.backdated">{{note.displayDate | date: 'dd MMM yyyy, HH:mm'}}</span>
                    <span *ngIf="note.backdated">{{note.displayDate | date: 'dd MMM yyyy'}}</span>
                </span>
                <span class="submitter-box"
                    *ngIf="note.backdated || note.source.id !== note.submitter.id">&nbsp;(Submitted
                    <span *ngIf="note.source.id !== note.submitter.id">&nbsp;by
                        <a class="source-info" (click)="viewContactDetailInSideSheet(note.source,$event)"
                            [href]="'contact/'+note.submitter.id">&nbsp;{{note.submitter.name}}</a></span>
                    <span *ngIf="note.backdated">&nbsp;on
                        {{getWeekdayByDate(note.submittedDate)}},&nbsp;{{note.submittedDate | date: 'dd MMM yyyy,
                        HH:mm'}}</span>)</span>
            </div>
        </div>
    </div>
    <div class="item-row third" *ngIf="isEvent">
        <div class="date-area">
            <smart-icon name="calendar" class="date"></smart-icon>
            <span class="date-value">{{date}}</span>
        </div>
    </div>
    <div class="item-row forth" *ngIf="isEvent">
        <div class="particpants-area">
            <smart-icon name="users-o" class="particpants-icon"></smart-icon>
            <div class="particpants-value-container-min" *ngIf="!isShowMaximun">
                <span class="particpants-values" (click)="viewContactDetailInSideSheet(item,$event)"
                    *ngFor="let item of particpants;let last=last;trackBy:trackByFn">{{item["short-name"]}}{{last ?
                    '' : '; '}}</span>
            </div>
            <div class="particpants-value-container-max" *ngIf="isShowMaximun">
                <span class="particpants-values" (click)="viewContactDetailInSideSheet(item,$event)"
                    *ngFor="let item of particpants;let last=last;trackBy:trackByFn">{{item["short-name"]}}{{last ?
                    '' : '; '}}</span>
            </div>
        </div>
    </div>
    <div class="item-row fifth" *ngIf="isEvent">
        <div class="location-info">
            <div class="location-area">
                <smart-icon name="location" class="location"></smart-icon>
                <span class="location-value">{{locationShowInScreen}}</span>
            </div>
            <div class="location-change-message" *ngIf="isShowLinkedEntityUpdateInfo">
                <div *ngIf="isLinkedEntityAddressUpdatedInSingleAddress">
                    <div *ngIf="!isNoUpdatePermission">
                        <span class="message-info">{{linkedEntityAddressType}} Address of {{linkedEntityLongName}}
                            has been changed, please </span>
                        <span (click)="updateLocationInfo($event)" class="message-update"> update the
                            location </span>
                        <span>of this event.</span>
                    </div>
                    <div *ngIf="isNoUpdatePermission">
                        <span class="message-info">{{linkedEntityAddressType}} Address of {{linkedEntityLongName}}
                            has been changed, You do not have permission to update the event.</span>
                    </div>
                </div>
                <div class="multiple-address" *ngIf="isLinkedEntityAddressUpdatedInMultipleAddress">
                    <div *ngIf="!isNoUpdatePermission">
                        <label class="item">{{linkedEntityAddressType}} Address of {{linkedEntityLongName}} has been
                            changed, please&nbsp;</label>
                        <tam-single-select-dropdown-filter [dropdownConfig]="linkedEntityAddressConfig">
                            <ng-template #customContent>
                                <div class="selected-item">
                                    <a (click)="onLinkedEntityAddressItemClick($event)">{{availableLocation}}</a>
                                </div>
                            </ng-template>
                        </tam-single-select-dropdown-filter>
                        <div>&nbsp;of this event.</div>
                    </div>
                    <div *ngIf="isNoUpdatePermission">
                        <span class="message-info">{{linkedEntityAddressType}} Address of {{linkedEntityLongName}}
                            has been changed, You do not have permission to update the event.</span>
                    </div>
                </div>
                <div *ngIf="isLinkedEntityAddressDeleted">
                    <span class="message-info">{{linkedEntityAddressType}} Address of {{linkedEntityLongName}}
                        is cleared, please confirm the location of this event.</span>
                </div>
                <div *ngIf="isLinkedEntityDeleted">
                    <span class="message-info">The associated entity/contact is deleted, please confirm the
                        location of this event.</span>
                </div>
            </div>
        </div>
    </div>
</div>