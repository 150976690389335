/**
 * Created by simon zhao on 12/21/2021.
 * Description:
 * A grid component based on ag-grid offering a footer for displaying the agrregation result.
 * ------ maintenance history ------
 */
import { Component, OnDestroy, OnInit, EventEmitter, Output, Input, ElementRef, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GridOptions, ColDef, ColGroupDef, ExcelStyle, IAggFunc, ICellRendererComp, ICellRendererFunc, GridApi, ColumnApi, GridReadyEvent, BodyScrollEvent, FilterChangedEvent, ColumnVisibleEvent, ColumnMovedEvent, ColumnPinnedEvent, ColumnResizedEvent, RowGroupOpenedEvent, ColumnRowGroupChangedEvent, ExpandCollapseAllEvent, CellClickedEvent, RowClickedEvent } from 'ag-grid-community';
import { TamCustomGridColumnFilterComponent } from '../ag-grid-column-filter/custom-grid-column-filter.component';
import { TamCustomGroupRowInnerRenderer } from '../ag-grid-column-filter/custom-group-row-inner-renderer.component';
import { ACTUAL_WIDTH_ATTRIB, AGGFUNC_ATTRIB, AggregationalGridComponentUtils, COL_ID_ATTRIB, defaultBottomGridOptions, VISIBLE_ATTRIB } from './aggregational-grid.component.utils';
import { ColAggregationalDef } from './aggregational-grid.model';
import { SOURCE_GRID } from '../../tamalelibs/services/workflow.service';

@Component({
    selector: 'aggregational-grid',
    templateUrl: './aggregational-grid.component.html',
    styleUrls: ['./aggregational-grid.component.scss']
})
export class AggregationalGridComponent implements OnDestroy {

    private _bottomGridApi: GridApi;

    private _bottomGridColumnApi: ColumnApi;

    private _topGridDataSource: Array<any> = [];

    private _columnIdsWithOrder: Array<string> = [];

    private _destroySubscriptions: Array<Subscription> = [];

    @ViewChild('gridContainer', { static: false }) gridContainerElement: ElementRef;
    @ViewChild('topGrid', { static: false }) topGridElement;
    @ViewChild('bottomGrid', { static: false }) bottomGridElement;

    private _topGridApi: GridApi;

    private _topGridColumnApi: ColumnApi;

    private _topGridColumnDefinitions: (ColDef | ColGroupDef)[] | null | undefined = undefined;

    private _topGridOptions: GridOptions;

    @Input()
    aggregationFuncs: { [key: string]: IAggFunc; } | undefined = AggregationalGridComponentUtils.getAllowedAggregationFunctions();

    /**
     * the data source of the bottom grid
     *
     * @type {Array<any>}
     * @memberof AggregationalGridComponent
     */
    bottomDataSource: Array<any> = [];

    /**
     * the grid options for the bottom grid.
     *
     * @type {GridOptions}
     * @memberof AggregationalGridComponent
     */
    @Input()
    bottomGridOptions: GridOptions = defaultBottomGridOptions;

    bottomGridColumnDefinitions: (ColDef | ColGroupDef)[] | null | undefined = undefined;

    /**
     * Fired when the body of the top grid was scrolled horizontally or vertically.
     *
     * @type {EventEmitter<BodyScrollEvent>}
     * @memberof AggregationalGridComponent
     */
    @Output()
    bodyScroll: EventEmitter<BodyScrollEvent> = new EventEmitter<BodyScrollEvent>();

    /**
     * Fired when a column, or group of columns, was hidden / shown.
     *
     * @type {EventEmitter<ColumnVisibleEvent>}
     * @memberof AggregationalGridComponent
     */
    @Output()
    columnVisible: EventEmitter<ColumnVisibleEvent> = new EventEmitter<ColumnVisibleEvent>();

    /**
     * Fired when a column was moved. To find out when the column move is finished you can use the dragStopped event below.
     *
     * @type {EventEmitter<ColumnMovedEvent>}
     * @memberof AggregationalGridComponent
     */
    @Output()
    columnMoved: EventEmitter<ColumnMovedEvent> = new EventEmitter<ColumnMovedEvent>();

    /**
     * Fired when a column, or group of columns, was pinned / unpinned.
     *
     * @type {EventEmitter<ColumnPinnedEvent>}
     * @memberof AggregationalGridComponent
     */
    @Output()
    columnPinned: EventEmitter<ColumnPinnedEvent> = new EventEmitter<ColumnPinnedEvent>();

    /**
     * Fired when a column was resized.
     *
     * @type {EventEmitter<ColumnResizedEvent>}
     * @memberof AggregationalGridComponent
     */
    @Output()
    columnResized: EventEmitter<ColumnResizedEvent> = new EventEmitter<ColumnResizedEvent>();

    /**
     * Fired when a row group column was added or removed.
     *
     * @type {EventEmitter<ColumnRowGroupChangedEvent>}
     * @memberof AggregationalGridComponent
     */
    @Output()
    columnRowGroupChanged: EventEmitter<ColumnRowGroupChangedEvent> = new EventEmitter<ColumnRowGroupChangedEvent>();

    /**
     * Fired when a cell is clicked.
     *
     * @type {EventEmitter<CellClickedEvent>}
     * @memberof AggregationalGridComponent
     */
    @Output()
    cellClicked: EventEmitter<CellClickedEvent> = new EventEmitter<CellClickedEvent>();

    @Input()
    domLayout: 'normal' | 'print' = 'normal';

    /**
     * Fired when calling either of the API methods expandAll() or collapseAll().
     *
     * @type {EventEmitter<ExpandCollapseAllEvent>}
     * @memberof AggregationalGridComponent
     */
    @Output()
    expandOrCollapseAll: EventEmitter<ExpandCollapseAllEvent> = new EventEmitter<ExpandCollapseAllEvent>();

    /**
     * A map of component names to angular components.
     *
     * @type {({ [p: string]: { new(): any; }; } | any | undefined)}
     * @memberof AggregationalGridComponent
     */
    @Input()
    frameworkComponents: { [p: string]: new () => any } | any | undefined = {
        customGridColumnFilter: TamCustomGridColumnFilterComponent,
        groupRowInnerRenderer: TamCustomGroupRowInnerRenderer
    };

    /**
     * Fired when filter has been modified and applied.
     *
     * @memberof AggregationalGridComponent
     */
    @Output()
    filterChanged = new EventEmitter<FilterChangedEvent>();

    @Output()
    gridReady: EventEmitter<GridReadyEvent> = new EventEmitter<GridReadyEvent>();

    @Input()
    getRowNodeId: (item: any) => string;

    /**
     *
     *
     * @type {({ new(): ICellRendererComp; } | ICellRendererFunc | string | undefined)}
     * @memberof AggregationalGridComponent
     */
    @Input()
    groupRowInnerRenderer: (new () => ICellRendererComp) | ICellRendererFunc | string | undefined = 'groupRowInnerRenderer';

    /**
     * Customise the parameters provided to the `groupRowRenderer` component.
     *
     * @type {*}
     * @memberof AggregationalGridComponent
     */
    @Input()
    groupRowRendererParams: any = {
        action$: new Subject()
    };

    /**
     * a flag indicating whether the horizontal scrolling should be suppressed
     *
     * @readonly
     * @memberof AggregationalGridComponent
     */
    get isSuppressHorizontalScroll() {
        return this.bottomDataSource.length > 0;
    }

    /**
     *  a flag indicating whether the bottom grid is visible.
     */
    get isBottomGridVisible() {
        return this.bottomDataSource.length > 0;
    }

    /**
     * Fired when a row group was opend or closed.
     *
     * @type {EventEmitter<RowGroupOpenedEvent>}
     * @memberof AggregationalGridComponent
     */
    @Output()
    rowGroupOpened: EventEmitter<RowGroupOpenedEvent> = new EventEmitter<RowGroupOpenedEvent>();

    /**
     * Fired when a row is clicked.
     *
     * @type {EventEmitter<RowClickedEvent>}
     * @memberof AggregationalGridComponent
     */
    @Output()
    rowClicked: EventEmitter<RowClickedEvent> = new EventEmitter<RowClickedEvent>();


    @Input()
    topExcelStyle: ExcelStyle[] | undefined = undefined;

    /**
     * the grid options for the top grid.
     *
     * @type {GridOptions}
     * @memberof AggregationalGridComponent
     */
    @Input()
    set topGridOptions(v: GridOptions) {
        if (v !== this._topGridOptions) {
            if (v && !v.getMainMenuItems) {
                v.getMainMenuItems = AggregationalGridComponentUtils.getMainMenuItems(this._customColumnAggFuncs);
            }
            this._topGridOptions = v;
            this._topGridOptions.alignedGrids = [];
            this._topGridOptions.alignedGrids.push(this.bottomGridOptions);
            this.bottomGridOptions.alignedGrids = [];
            this.bottomGridOptions.alignedGrids.push(this._topGridOptions);
        }
    }

    get topGridOptions() {
        return this._topGridOptions;
    }

    /**
     * By default groupDefaultExpanded = 0 which means no groups are expanded by default. To expand all row groups set groupDefaultExpanded = -1.
     *
     * @type {(-1 | 0 | 1)}
     * @memberof AggregationalGridComponent
     */
    @Input()
    topGridGroupDefaultExpanded: -1 | 0 | 1 = 0;

    /**
     * the column definitions for the top grid
     *
     * @type {((ColDef | ColGroupDef)[] | null | undefined)}
     * @memberof AggregationalGridComponent
     */
    @Input()
    set topGridColumnDefinitions(v: (ColDef | ColAggregationalDef)[] | null | undefined) {
        if (v !== this._topGridColumnDefinitions) {
            v.forEach((col: ColAggregationalDef | ColDef) => {
                if (AggregationalGridComponentUtils.isNumericalColumnDef(col)) {
                    col.enableValue = false;
                    // default allowedAggFuncs = ['sum', 'min', 'max', 'count', 'avg', 'first', 'last']
                    col.allowedAggFuncs = AggregationalGridComponentUtils.getAllowedAggrationFunctionNameArray();
                    col.headerValueGetter = AggregationalGridComponentUtils.customHeaderValueGetter.bind(this);
                }
            });
            this._topGridColumnDefinitions = v;
            this._serveBottomColDefsByTopColDefs(v);
        }
    }

    get topGridColumnDefinitions() {
        return this._topGridColumnDefinitions;
    }

    /**
     * the data source of the top grid.
     *
     * @type {Array<any>}
     * @memberof AggregationalGridComponent
     */
    @Input()
    set topGridDataSource(v: Array<any>) {
        if (v !== this._topGridDataSource) {
            this._topGridDataSource = v;
            if (this.isBottomGridVisible) {
                setTimeout(
                    () => this._refreshBottomGridData()
                );
            }
        }
    }

    get topGridDataSource() {
        return this._topGridDataSource;
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    onBodyScroll(e: BodyScrollEvent) {
        this.bodyScroll.emit(e);
    }

    onTopGridReady(e: GridReadyEvent) {
        this._topGridApi = e.api;
        this._topGridColumnApi = e.columnApi;
        this.gridReady.emit(e);
    }

    onTopGridRowClicked(event: RowClickedEvent) {
        this.rowClicked.emit(event);
    }

    onBottomGridReady(e: GridReadyEvent) {
        this._bottomGridApi = e.api;
        this._bottomGridColumnApi = e.columnApi;
        this._resetBottomState();
    }

    onTopGridFilterChanged(event: FilterChangedEvent) {
        this._refreshBottomGridData();
        this.filterChanged.emit(event);
    }

    onTopGridColumnVisibleChanged(event: any) {
        this._topGridColumnApi.autoSizeAllColumns();
        let fullWidth = 0;
        this._topGridColumnApi.getColumns().forEach((column) => {
            if (column[VISIBLE_ATTRIB]) {
                fullWidth += column[ACTUAL_WIDTH_ATTRIB];
            }
        });

        if (fullWidth < this.gridContainerElement.nativeElement.clientWidth) {
            this._topGridApi.sizeColumnsToFit();
            this._syncColumnWidthFromTopToBottom();
        }

        if (this._bottomGridColumnApi) {
            this._bottomGridColumnApi.setColumnVisible(event.column.colId, event.visible);
        }

        this.bottomGridColumnDefinitions.forEach((item: any) => {
            if (item.colId === event.column?.colId) {
                item.hide = !event.visible;
            }
        });

        this.columnVisible.emit(event);
    }

    onTopGridColumnMoved(event: ColumnMovedEvent) {
        this.columnMoved.emit(event);
    }

    onTopGridColumnPinned(event: ColumnPinnedEvent) {
        this.columnPinned.emit(event);
    }

    onTopGridColumnResized(event: ColumnResizedEvent) {
        this.columnResized.emit(event);
    }

    onTopGridRowGroupOpened(event: RowGroupOpenedEvent) {
        this.rowGroupOpened.emit(event);
    }

    onTopGridColumnRowGroupChanged(event: ColumnRowGroupChangedEvent) {
        this.columnRowGroupChanged.emit(event);
        this._resetBottomState();
    }

    onTopGridExpandOrCollapseAll(event: ExpandCollapseAllEvent) {
        this.expandOrCollapseAll.emit(event);
    }

    onTopGridCellClicked(event: CellClickedEvent) {
        this.cellClicked.emit(event);
    }

    private _customColumnAggFuncs = (func: string, col: any) => {
        this._topGridColumnApi.setColumnAggFunc(col, func);
        this._topGridColumnApi.addValueColumn(col.colId);
        this._refreshBottomGridData();
    };

    private _resetBottomState() {
        if (this._topGridColumnApi) {
            const _resetBottomColumnsFunc = (bottomColDefs: any) => {
                const newColumnState = this._topGridColumnApi.getColumnState();
                const columns = [];
                newColumnState.forEach(item => {
                    const temp = bottomColDefs.find(column => column.colId === item.colId);
                    if (temp) {
                        delete temp.rowGroupIndex;
                        temp.aggFunc = item.aggFunc;
                        temp[SOURCE_GRID] = 'bottom';
                        temp.width = item.width;

                        columns.push(temp);
                    } else {
                        delete item.rowGroupIndex;
                        item[SOURCE_GRID] = 'bottom';
                        columns.push(item);
                    }
                });
                return columns;
            };

            this.bottomGridColumnDefinitions = _resetBottomColumnsFunc(this.bottomGridColumnDefinitions);
            if (this._bottomGridApi) {
                this._bottomGridApi.setColumnDefs(this.bottomGridColumnDefinitions);
                this._bottomGridColumnApi.resetColumnState();
            }
        }
    }

    /**
     * generate the data serves for the bottom grid, i.e the aggregation result.
     * currently, we use the value from each individual cell of the given column to do the agrregation calculation.
     */
    private _refreshBottomGridData() {
        const columns = [];
        const allColumns = this._topGridColumnApi.getColumns();
        allColumns.forEach((column) => {
            if (column[AGGFUNC_ATTRIB]) {
                columns.push({
                    aggFunc: column[AGGFUNC_ATTRIB],
                    colId: column[COL_ID_ATTRIB],
                    data: [],
                    valueGetter: column.getColDef().valueGetter
                });
            }
        });

        this._topGridApi.forEachNodeAfterFilter((node) => {
            if (!node.group) {
                columns.forEach(item => {
                    if (item.valueGetter) {
                        item.data.push(+item.valueGetter(node));
                    } else {
                        return item.data.push(node.data[item.colId] ? (+node.data[item.colId]) : 0);
                    }
                });
            }
        });
        const bottomData = {};
        columns.forEach(item => {
            const aggFunc = item.aggFunc;
            bottomData[item.colId] = this.aggregationFuncs[aggFunc](item.data);
        });

        let data = [];
        if (Object.values(bottomData).every(item => item === '')) {
            data = [];
        } else {
            data = [bottomData];
        }

        this._resetBottomState();
        this.bottomDataSource = data;
        if (this.bottomDataSource.length === 0) {
            this._bottomGridApi = null;
            this._bottomGridColumnApi = null;
        }
        // sync the width of columns from top and bottom grid.
        this._syncColumnWidthFromTopToBottom();
    }

    private _serveBottomColDefsByTopColDefs(topColDefs: (ColDef | ColGroupDef)[]) {
        if (topColDefs) {
            this.bottomGridColumnDefinitions = [];
            topColDefs.forEach(topCol => {
                if (AggregationalGridComponentUtils.isNumericalColumnDef(topCol)) {
                    const getters = { tooltipGetter: null, valueGetter: null, filterValueGetter: null, cellRenderer: null };
                    const getCellValueFunc = para => !!para && para.colDef && para.colDef.colId && !!para.data[para.colDef.colId] ? para.data[para.colDef.colId] : '';
                    getters.valueGetter = (params) => getCellValueFunc(params);
                    getters.tooltipGetter = (params) => getCellValueFunc(params);
                    // do not pass cellRenderer as the cellRender component for number is already indicated.
                    this.bottomGridColumnDefinitions.push(AggregationalGridComponentUtils.generateColDefForBottomGrid(topCol, getters));
                } else {
                    const getters = { tooltipGetter: null, valueGetter: null, filterValueGetter: null, cellRenderer: null };
                    getters.valueGetter = (params) => '';
                    getters.tooltipGetter = (params) => '';
                    getters.cellRenderer = (params) => '';
                    this.bottomGridColumnDefinitions.push(AggregationalGridComponentUtils.generateColDefForBottomGrid(topCol, getters));
                }
            });
        }
    }

    private _syncColumnWidthFromTopToBottom() {
        setTimeout(() => {
            const allColumns = this._topGridColumnApi.getColumnState();
            allColumns.forEach(item => {
                // setColumnWidths - ag-grid version > 23, so use setColumnWidth
                this._topGridColumnApi.setColumnWidth(item.colId, item.width);
            });
        });
    }
}
