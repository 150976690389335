export class DataList<T> extends Array<T> {
    totalCount: number;
    hasNext: boolean;

    constructor() {
        super();
        this.totalCount = 0;
        this.hasNext = false;
    }
}
