import { NoteTileDisplayStrategy } from './tile.model';

export enum ThreadsDisplayModes {
    LATEST = 'LATEST',
    PARENT = 'PARENT'
}

export enum ThreadsDisplayOrders {
    DISPLAY_DATE = 'entry__display_date',
    SUBMITTED_DATE = 'entry__submitted_date',
    LAST_EDITED_DATE = 'entry__last_edited_date'
}

export class ThreadDisplaySettings {
    public threadsOrder: ThreadsDisplayOrders;
    public threadsCount: number;
    public threadsDisplayModes: NoteTileDisplayStrategy;
    public threadsShowFullThread: boolean;
    public entities: boolean;
    public threadAttachments: boolean;
    public entryAttachments: boolean;
    public subject: boolean;
    public source: boolean;
    public noteType: boolean;
    public displayDate: boolean;
    public submittedDate: boolean;
    public submitter: boolean;
    public sentiment: boolean;
    public priority: boolean;
    public adhocTable: boolean;
    public blurb: boolean;
    public noteBody: boolean;
    constructor() {
    }
}
