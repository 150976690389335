/**
 * Created by Marcus Zhao on 8/25/2022.
 * Description:Signature reducer, get signature data and change signature data.
 * Action: REMOVE , GET_SIGNATURE_DATA_SUCCESS ,CLEAR
 * ------ maintenance history ------
 */


import { AppState } from '..';
import { SignatureActions, SignatureActionTypes } from '../actions/signature.actions';

export const getSignatureDataState = (state: AppState) => state.signatureData;

export function getSignatureReducer(state, action: SignatureActions) {
    let newState;
    switch (action.type) {
        case SignatureActionTypes.REMOVE:
            newState = Object.assign([], state);
            const uuid = action.payload;
            // if current item has been created ,we should be remove from state list.
            const index = newState.contacts.findIndex(item =>  item.uuid === uuid);
            newState.contacts.splice(index, 1);
            newState.count--;
            return newState;
        case SignatureActionTypes.GET_SIGNATURE_DATA_SUCCESS:
            return action.payload;
        case SignatureActionTypes.CLEAR:
             return {};
        default:
            return state;
    }
}

