/**
 * created by Yu Zhang on 9/8/17.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';

@Injectable()
export class StringHelperService {
    /**
     * compare whether two strings are equeal or not
     */
    static isEqual(a1: string, a2: string): boolean {
        let result = false;
        // create regex
        const pattern = new RegExp(a1, 'gi');
        // compare the stings
        result = pattern.test(a2);
        return result;
    }

    static validGUID(value) {
        const validGuid = /^[0-9a-fA-F]{32}$/;
        return validGuid.test(value);
    }

    static validAdhocId(value) {
        const ValidAdhocId = /^x-[0-9a-fA-F]{32}$/;
        return ValidAdhocId.test(value);
    }

    static escapeHtml(source: string) {
        const entityMap = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            '\'': '&#39;',
            '/': '&#x2F;'
        };
        return String(source).replace(/[&<>"'\/]/g, s => entityMap[s]);
    }

    static escapeHtml2(html: string) {
        const dom = document.createElement('div');
        dom.innerText = html;
        return dom.innerHTML;
    }

    static escapeSearchString(searchText: string): string {
        if (searchText.startsWith('"') && searchText.endsWith('"')) {
            return '"' + this.escapeLuceneSpecialCharacters(searchText.slice(1, -1)) + '"';
        } else {
            return this.escapeLuceneSpecialCharacters(searchText);
        }
    }

    static escapeLuceneSpecialCharacters(searchText: string): string {
        let escapeString = searchText;
        const specialSingleChars = ['\\', '+', '-', '!', '(', ')', '{', '}', '[', ']', '^', '"', '~', '*', '?', ':', '&', '|'];
        specialSingleChars.forEach(character => {
            escapeString = escapeString.replace(character, '\\' + character);
        });
        return escapeString;
    }

    // obsolete function leave at here for reference, need to remove later
    static escapeSearchStringForEntry(searchText) {
        let resultString = '';
        if (searchText[0] === '"' && searchText[searchText.length - 1] === '"') {
            const escapeStr = this.escapeLuceneSpecialChars(searchText.substring(1, searchText.length - 1));
            resultString = '"' + escapeStr + '"';
        } else {
            resultString = this.escapeLuceneSpecialChars(searchText);
        }
        return resultString;
    }

    // obsolete function leave at here for reference, need to remove later
    static escapeLuceneSpecialChars(searchText) {
        let escapeString = '';
        const specialSingleChars = ['+', '-', '!', '(', ')', '{', '}', '[', ']', '^', '"', '~', '*', '?', ':', '\\', '&', '|'];
        for (let index = 0; index < searchText.length; ++index) {
            if (this.arrayContains(specialSingleChars, searchText[index])) {
                escapeString += '\\' + searchText[index];
            } else {
                escapeString += searchText[index];
            }
        }
        return escapeString;
    }

    static arrayContains(array, elem) {
        for (let index = 0; index < array.length; ++index) {
            if (elem === array[index]) {
                return true;
            }
        }
        return false;
    }

    static isJsonString(str): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
