<context-menu #tamMenu>
    <ng-container *ngFor="let menuItem of config.items">
        <ng-container *ngIf="!config.stateful">
            <ng-template contextMenuItem let-item [divider]="menuItem?.divider" [disabled]="menuItem.disabled"
                [visible]="!menuItem.hidden"
                (execute)="config.clickOnDiv?emptyClick($event):onItemClick($event, menuItem)">
                <div class="line" *ngIf="config.clickOnDiv" (click)="onItemClick($event, menuItem)"
                    (mousedown)="onItemMousedown($event)">
                    <smart-icon *ngIf="config.showItemIcon" [name]="menuItem.icon" [style.color]="menuItem.iconColor">
                    </smart-icon>
                    <span [class.menuItem]="config.showItemIcon">{{menuItem.text}}</span>
                </div>
                <div class="line" *ngIf="!config.clickOnDiv">
                    <smart-icon *ngIf="config.showItemIcon" [name]="menuItem.icon" [style.color]="menuItem.iconColor">
                    </smart-icon>
                    <span [class.menuItem]="config.showItemIcon">{{menuItem.text}}</span>
                </div>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="config.stateful">
            <ng-template contextMenuItem let-item [divider]="menuItem?.divider" [disabled]="menuItem.disabled"
                [passive]="!config.single && config.isPassive"
                (execute)="config.clickOnDiv?emptyClick($event):config.single?onItemClick($event, menuItem):onPassiveItemClick($event, menuItem)">
                <div class="line" *ngIf="config.clickOnDiv"
                    (click)="config.single?onItemClick($event, menuItem):onPassiveItemClick($event, menuItem)"
                    (mousedown)="onItemMousedown($event)">
                    <smart-icon *ngIf="menuItem.checked === true" name="check" class="checkIcon"></smart-icon>
                    <div class="inlineb placeholder" *ngIf="menuItem.checked == null || menuItem.checked === false">
                    </div>
                    <smart-icon *ngIf="menuItem.hideItemSpace?!menuItem.hideItemSpace:config.showItemIcon"
                        [name]="menuItem.icon" class="menuItem" [style.color]="menuItem.iconColor"></smart-icon>
                    <span class="menuItem">{{menuItem.text}}</span>
                </div>
                <div class="line" *ngIf="!config.clickOnDiv">
                    <smart-icon *ngIf="menuItem.checked === true" name="check" class="checkIcon"></smart-icon>
                    <div class="inlineb placeholder" *ngIf="menuItem.checked == null || menuItem.checked === false">
                    </div>
                    <smart-icon *ngIf="config.showItemIcon" [name]="menuItem.icon" class="menuItem"
                        [style.color]="menuItem.iconColor"></smart-icon>
                    <span class="menuItem">{{menuItem.text}}</span>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</context-menu>