/**
 * Created by Abner Sui on 03/14/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum AdhocActionTypes {
    INITIALIZE = '[ADHOC] INITIALIZE',
    INITIALIZE_SUCCESS = '[ADHOC] INITIALIZE_SUCCESS',
    INITIALIZE_FAILED = '[ADHOC] INITIALIZE_FAILED'
}

export class Initialize implements Action {
    readonly type = AdhocActionTypes.INITIALIZE;
}

export class InitializeSuccess implements Action {
    readonly type = AdhocActionTypes.INITIALIZE_SUCCESS;

    constructor(public payload: any) { }
}

export class InitializeFailed implements Action {
    readonly type = AdhocActionTypes.INITIALIZE_FAILED;

    constructor(public payload: any) { }
}

export type AdhocActions = Initialize | InitializeSuccess | InitializeFailed;
