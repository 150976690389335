/**
 * Created by Abner Sui on 01/22/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from '../../widgets/widgets.module';
import { AttachmentListComponent } from './attachment-list.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
        TooltipModule,
    ],
    declarations: [
        AttachmentListComponent
    ],
    exports: [
        AttachmentListComponent
    ],
    providers: []
})
export class AttachmentListModule {
}
