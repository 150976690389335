import { Subject } from 'rxjs';
import { EntityBrief } from '../../tamalelibs/models/entity-brief.model';

export enum TamDashboardActions {
    RESET = 'RESET',
    ADD_ITEM = 'ADD_ITEM',
    SET_TILE_DATA = 'SET_TILE_DATA',
    SET_LOADING_STATUS = 'SET_LOADING_STATUS',
    SET_MAP_LOADING_STATUS = 'SET_MAP_LOADING_STATUS',
    SET_GRID_COLUMN = 'SET_GRID_COLUMN',
    SET_TILE_NAME = 'SET_TILE_NAME',
    SET_TILE_ACTIVE = ' SET_TILE_ACTIVE',
    SET_TILE_GRID_FILTER_SELECT = 'SET_TILE_GRID_FILTER_SELECT',
    SET_TILE_PREVIEW_STATUS = 'SET_TILE_PREVIEW_STATUS',
    SET_TILE_PREVIEW_STATUS_SUCCESS = 'SET_TILE_PREVIEW_STATUS_SUCESS',
    SAVE_TILE_GRID_LAYOUT = 'SAVE_TILE_GRID_LAYOUT',
    SET_PIR_CHART_DISPLAY = 'SET_PIR_CHART_DISPLAY',
    SET_CHART_LABEL_SETTING = 'SET_CHART_LABEL_SETTING',
    SET_TILE_CONFIG = 'SET_TILE_CONFIG',
    SET_FOCUSED_ENTITY_IDS = 'SET_FOCUSED_ENTITY_IDS',
    SET_TILE_TYPE = 'SET_TILE_TYPE',
    DELETE_ITEM = 'DELETE_ITEM',
    COPY_ITEM = 'COPY_ITEM',
    GET_TILE_GRID_LAYOUT = 'GET_TILE_GRID_LAYOUT',
    GET_TILE_GRID_ALL_DATA = 'GET_TILE_GRID_ALL_DATA',
    SET_GRID_COLUMN_SORT = 'SET_GRID_COLUMN_SORT',
    SET_GRID_SORT_MODEL = 'SET_GRID_SORT_MODEL',
    SET_EXPAND_OR_COLLAPSE_ALL = 'SET_EXPAND_OR_COLLAPSE_ALL',
    LOAD_DATA = 'LOAD_DATA', // dashboard->tile.config, to ask tile instance loading data
    INIT_TILE = 'INIT_TILE', // dashboard->tile-container, to init tile instance
    SET_GRID_COLUMN_DEF = 'SET_GRID_COLUMN_DEF',
    SET_GRID_LAYOUT = 'SET_GRID_LAYOUT',
    LOAD_GRID_LAYOUT = 'LOAD_GRID_LAYOUT',
    CHANGE_STYLE = 'CHANGE_STYLE',
    SET_GRID_TOTAL_ITEMS = 'SET_GRID_TOTAL_ITEMS',
    DISABLED_COLUMN_SORT = 'DISABLED_COLUMN_SORT',
    UPDATE_EXPORT_STYLES = 'UPDATE_EXPORT_STYLES',
    CHANGE_MODE = 'CHANGE_MODE',
    PULL_TILE_SETTINGS = 'PULL_TILE_SETTINGS',
    UPDATE_GRID_ROW_DATA = 'UPDATE_GRID_ROW_DATA',
    SET_COLUMN_SORT_COMPARATOR = 'SET_COLUMN_SORT_COMPARATOR',
    SET_TILE_BOTTOM_GRID_DATA = 'SET_TILE_BOTTOM_GRID_DATA',
    RESET_BOTTOM_GRID_COLUMN_WIDTH = 'RESET_BOTTOM_GRID_COLUMN_WIDTH',
    SET_BOTTOM_GRID_COLUMN_VISIBLE = 'SET_BOTTOM_GRID_COLUMN_VISIBLE',
    REDRAW_GRID_ROWS = 'REDRAW_GRID_ROWS'
}

export enum TamDashboardCustomComponentsActions {
    UPDATE_COLUMN_WIDTH_BY_OPERATION = 'UPDATE_COLUMN_WIDTH_BY_OPERATION',
    UPDATE_COLUMN_WIDTH_BY_SCROLL = 'UPDATE_COLUMN_WIDTH_BY_SCROLL'
}

export enum TamDashboardEvents {
    ITEM_CHANGED = 'ITEM_CHANGED',
    ITEM_CLICKED = 'ITEM_CLICKED',
    ITEM_TRIGGER_REFRESH = 'ITEM_TRIGGER_REFRESH',
    ITEM_ICON_CLICK = 'ITEM_ICON_CLICK',
    OPEN_DRILL_DOWN = 'OPEN_DRILL_DOWN',
    DRILL_DOWN_SERIES_CLICK = 'DRILL_DOWN_SERIES_CLICK',
    ITEM_DELETE = 'ITEM_DELETE',
    LOAD_DEFAULT_LAYOUT = 'LOAD_DEFAULT_LAYOUT',
    ON_COLUMN_ORDER_CHANGED = 'ON_COLUMN_ORDER_CHANGED',
    SET_CONFIG_FINISHED = 'SET_CONFIG_FINISHED',
    SET_DATA_FINISHED = 'SET_DATA_FINISHED',
    TYPE_CHANGED = 'TYPE_CHANGED',
    EXPAND_COLLAPSE = 'EXPAND_COLLAPSE',
    TILE_SETTINGS_LOADED = 'TILE_SETTINGS_LOADED',
    TILE_AFTER_VIEW_INIT = 'TILE_AFTER_VIEW_INIT',
    TILE_LOADED_DATA = 'TILE_LOADED_DATA',
    SET_VALUE = 'SET_VALUE',
    RESIZE_COLUMN_WIDTH = 'RESIZE_COLUMN_WIDTH',
    SET_MAP_ADDRESS = 'SET_MAP_ADDRESS',
    REFRESH_MAP_TILE = 'REFRESH_MAP_TILE',
    REFRESH_MAP_TILE_BACK = 'REFRESH_MAP_TILE_BACK',
    REFRESH_MAP_TRIP = 'REFRESH_MAP_TRIP',
}

export enum TamTileSettingsEvents {
    LOAD_SETTING = 'LOAD_SETTING',
    CHANGE_SCREEN_FOCUS_TYPE = 'CHANGE_SCREEN_FOCUS_TYPE',
    CHANGE_TILE_DEFAULT_LAYOUT = 'CHANGE_TILE_DEFAULT_LAYOUT',
    CHANGE_ITEM_CLICK = 'CHANGE_ITEM_CLICK',
    UPDATE_GRID_SETTINGS = 'UPDATE_GRID_SETTINGS',
    RETURN_LATEST_SETTINGS = 'RETURN_LATEST_SETTINGS'
}

export enum PieChartDisplaySetting {
    PERCENTAGE = 'PERCENTAGE',
    VALUE = 'VALUE'
}

export class TamDashboardOptions {
    icons: Array<any>;
    readOnly: boolean;
    print?: string;
    actionSubject$: Subject<any>;
    feedbackSubject$: Subject<any>;

    constructor(readOnly: boolean) {
        this.readOnly = readOnly;
        this.actionSubject$ = new Subject();
        this.feedbackSubject$ = new Subject();
    }
}

export class TamDashboardItem {
    height: number;
    width: number;
    positionX: number;
    positionY: number;

    id: string;
    name: string;
    data?: any;
    type: TamDashboardTileType;
    isLoading?: boolean;
    notPreview?: boolean;
}

export enum TamDashboardTileType {
    VALUE = 3,
    METADATA = 4,
    GRID = 5,
    BAR_CHART = 7,
    COLUMN_CHART = 1,
    PIE_CHART = 0,
    MAP = 10,
    LINE_CHART = 2,
    NONE = -1,
    NOTES = 6,
    EDITOR = 8,
    TEXT = 9
}

export class Tile {
    id: string;
    name: string;
    description: string;
    focus: any;
    filters: any;
    type: string;
    columns: any;
    columnGroups: any;
    lastModifiedDate: any;
    author: any;
    createDate: any;
    conditionalFormatting?: any;
}

export class FocusInfo {
    entity: Array<EntityBrief> | EntityBrief;
}

export const TileIcons = {
    REFRESH: {
        icon: 'refresh',
        title: 'Refresh'
    },
    EDIT: {
        icon: 'pencil',
        title: 'Edit Tile'
    },
    COPY: {
        icon: 'copy',
        title: 'Copy'
    },
    DELETE: {
        icon: 'trash',
        title: 'Delete'
    },
    HIDE_BORDER: {
        icon: 'no-border',
        title: 'Hide Border'
    },
    HIDE_TITLE: {
        icon: 'no-title',
        title: 'Hide Title'
    }
};

export enum TileMode {
    READ = 0,
    WRITE = 1
}

