/**
 * ---------maintenance history-------------
 * 2019.5.22 By Bowen Li: modified the standard order when click the add icon in dashboard detail page
 */
import { Component, OnInit, OnDestroy, Input, ContentChild, TemplateRef, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FieldActionType, FieldConfig, FieldEventType } from './field-selector.model';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../redux';
import { Subscription } from 'rxjs';
import { Adhoc } from '../../tamalelibs/models/adhoc.model';
import { ArrayHelperService } from '../../tamalelibs/services/array-helper.service';

@Component({
    selector: 'tam-field-selector',
    templateUrl: './field-selector.component.html',
    styleUrls: ['./field-selector.component.scss']
})
export class FieldSelectorComponent implements OnInit, OnDestroy {

    @Input() config: FieldConfig;
    @ContentChild('customBtn', { static: false }) customBtn: TemplateRef<any>;
    @ViewChild('keywordInput', { static: false }) keywordInputEle: ElementRef;

    searchValue = '';
    showAddPopup = false;
    private _subscriptions: Array<Subscription> = [];

    filterSource: Array<any> = [];
    constructor(
        private _store: Store<AppState>
    ) { }

    ngOnInit() {
        this._initFilterTypes();
        if (this.config.config.showAdhoc) {
            this._subscriptions.push(
                this._store.pipe(
                    select(this.config.config.selector)
                ).subscribe((data: Map<string, Adhoc>) => {
                    if (data && data.size > 0) {
                        const tempList: Array<Adhoc> = Array.from(data.values());
                        if (this.config.config.otherAdhoc && this.config.config.otherAdhoc.length > 0) {
                            tempList.push(...this.config.config.otherAdhoc);
                        }
                        ArrayHelperService.sort(tempList, 'name');
                        tempList.forEach((item) => {
                            this._initAdhocFilterTypes(item);
                        });
                    }
                })
            );
        }

        this._subscriptions.push(
            this.config.actions$.subscribe(res => {
                if (res.type === FieldActionType.changeEntityList) {
                    this.config.config.entityAdhoc = res.payload;

                } else if (res.type === FieldActionType.changeEventList) {
                    this.config.config.eventAdhoc = res.payload;
                } else if (res.type === FieldActionType.changeRecipientList) {
                    this.filterSource = [];
                    this._initFilterTypes();
                }
                this._filterListChange();
            }));

        this._filterListChange();
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(item => item.unsubscribe());
    }

    @HostListener('document:click', ['$event'])
    clickSomewhere(event) {
        if (this.showAddPopup && (!event['field-selector'] || (event['fieldIndex'] !== undefined && event['fieldIndex'] !== this.config.fieldIndex))) {
            this.showAddPopup = false;
        }
    }

    onClick(event) {
        event['field-selector'] = true;
        event['fieldIndex'] = this.config.fieldIndex;
        this.showAddPopup = true;
        if (this.config.config.showSearch) {
            setTimeout(() => {
                this.keywordInputEle.nativeElement.focus();
            });
        }
    }

    onSearch(event) {
        event['field-selector'] = true;
    }

    filterValueChange(item): void {
        this.showAddPopup = false;
        this.searchValue = '';
        this.config.events$.next({ type: FieldEventType.select, payload: item });
    }

    trackByFn(index) {
        return index;
    }

    /**
     * Reset filter list change and sort it by name.
     *
     * @private
     * @memberof FieldSelectorComponent
     */
    private _filterListChange(): void {
        let data;
        if (this.config.config.entityAdhoc) {
            this.filterSource = [];
            data = this.config.config.entityAdhoc;
        } else if (this.config.config.eventAdhoc) {
            this.filterSource = [];
            data = this.config.config.eventAdhoc;
            this._initFilterTypes();
        }
        if (data) {
            const tempList: Array<Adhoc> = Array.from(data.values());
            ArrayHelperService.sort(tempList, 'name');
            tempList.forEach((item) => {
                this._initAdhocFilterTypes(item);
            });
        }
    }

    private _initAdhocFilterTypes(data) {
        data.text = data.name;
        data.value = data.id;
        this.filterSource.push(data);
    }

    private _initFilterTypes() {
        this.config.config.standardItem.forEach(element => {
            this.filterSource.push(element);
        });
    }
}
