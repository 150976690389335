/**
 * Created by Daniel on 09/04/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { combineReducers, createSelector } from '@ngrx/store';
import { DataList } from '../../tamalelibs/models/data-list.model';
import { CalendarEntry } from '../../tamalelibs/models/calendar-entry.model';
import { CalendarEventsActionTypes, CalendarActions } from '../actions/calendar.action';

export function calendarEventsItemsReducer(state: DataList<CalendarEntry>, action: CalendarActions) {
    switch (action.type) {
        case CalendarEventsActionTypes.RELOAD_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function focusReducer(state: any, action: CalendarActions) {
    switch (action.type) {
        case CalendarEventsActionTypes.INITIALIZE_FOCUS:
            return action.payload;
        default:
            return state;
    }
}

export const getCalendarState = (state: any) => state;
export const getCalendarEventsItemsState = createSelector(getCalendarState, (state: any) => state ? state.items : null);
export const getCalendarEventsFocusState = createSelector(getCalendarState, (state: any) => state ? state.focus : null);


const calendarReducer = combineReducers({
    items: calendarEventsItemsReducer,
    focus: focusReducer,
});

export function getCalendarReducer(state, action) {
    return calendarReducer(state, action);
}
