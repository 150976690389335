/**
 * Created by Daniel Wang 3/11/2024.
 * Description: custom action column for ag-grid
 * ------ maintenance history ------
 */

import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { GridCustomColumnFeedback, GridCustomColumnFeedbackTypes } from './custom-action-column.model';

@Component({
    selector: 'tam-custom-action-column',
    templateUrl: './custom-action-column.component.html',
    styleUrls: ['./custom-action-column.component.scss']
})
export class CustomActionColumnComponent implements OnInit, ICellRendererAngularComp {
    editable: boolean;
    deletable: boolean;

    private _data: any;
    private _feedbackSubject$: Subject<GridCustomColumnFeedback>;
    constructor() { }

    ngOnInit() { }

    /** Initialize this widget's data within the grid */
    agInit(params: any) {
        this._data = params.data;
        this._feedbackSubject$ = params.feedbackSubject$();
        this.deletable = this._data['deletable'] === undefined ? true : this._data['deletable'];
    }

    /** Refresh widget within the grid */
    refresh(params: any): boolean {
        return false;
    }

    /** Called when delete is clicked. Dispatches delete action to grid */
    onDeleteClick() {
        this._feedbackSubject$.next({
            type: GridCustomColumnFeedbackTypes.DELETE,
            payload: {
                data: this._data
            }
        });
    }
}
