/**
 * Created by Chen Jiu on 3/16/2017
 * Description:
 *   entry Type mode
 *
 * ---------maintenance history-------------
 * 05/18/2022 Marcus Zhao added usage in constructor and function :parseEntryTypeFromList()
 */
import { businessConstants } from '../constants/business.constants';

export class EntryTypeConfig {
    customDefaultSideNoteType: string;

    constructor(_customDefaultSideNoteType: string) {
        this.customDefaultSideNoteType = _customDefaultSideNoteType;

    }
}

export class EntryType {
    id: string;
    name: string;
    // tslint:disable-next-line: whitespace
    useNER?= false;
    href?: string;
    usage?: Array<string>;
    config?: EntryTypeConfig;

    constructor(id?: string, name?: string, useNER?: boolean, href?: string, usage?: [], config?: EntryTypeConfig) {
        this.id = id;
        this.name = name;
        this.useNER = useNER;
        this.href = href;
        this.usage = usage;
        this.config = config;
    }

    // tslint:disable-next-line: member-ordering
    static readonly TAMALE_NOTE: EntryType = {
        id: businessConstants.EntryTypes.Tamale_Note_Id,
        name: 'TamaleNote'
    };

    // tslint:disable-next-line: member-ordering
    static readonly DEPOSITED_BLAST_EMAIL: EntryType = {
        id: businessConstants.EntryTypes.Deposited_Blast_Email_Id,
        name: 'Deposited Blast Email'
    };

    // tslint:disable-next-line: member-ordering
    static readonly TAMALE_EVENT: EntryType = {
        id: businessConstants.EntryTypes.Tamale_Event_Id,
        name: 'TamaleEvent'
    };

    static parse(serverResponse): EntryType {
        let config = new EntryTypeConfig('');
        let usage = [];
        let useNER = false;
        if (serverResponse.data) {
            config = serverResponse.data.config;
            usage = serverResponse.data.usage;
            useNER = serverResponse.data.useNER;
        }
        return {
            id: serverResponse.link.href.slice(-33, -1),
            name: serverResponse.link.phid,
            useNER: useNER,
            config: config,
            usage: usage
        };
    }

    static parseEntryTypeFromList(serverResponse): EntryType {
        return {
            id: serverResponse.id,
            name: serverResponse.name,
            usage: serverResponse.usage
        };
    }
}

export class EntryTypeList {

    get TypeList(): Array<EntryType> {
        return this[EntryTypeList._propertyName];
    }

    set TypeList(_v: Array<EntryType>) {
        this[EntryTypeList._propertyName] = _v;
    }

    private static readonly _propertyName = 'entry-type-list';
}
