/**
 * Created by Abner Sui on 05/22/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { FileList } from '../models/file-list.model';
import { DocumentEntry } from '../models/document-entry.model';

@Injectable()
export class FileViewService {
    constructor(private _transportService: TransportService) { }

    public getFileList(page: number = 0, pageSize: number = 0, keyword: string = ''): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entryEndpoint}`;
        const params = {
            expand: 'source;submitter;entity;entities',
            page: page,
            rpp: pageSize,
            showpermission: true,
            sortby: 'lastediteddate',
            sortorder: 'desc',
            advfilter: AppConfig.defaultAdvFileFilterForFileView,
            showattachmentonly: true,
            showattachmentserverfilename: true,
            'exclude-embedded-img': true,
        };
        if (keyword && keyword.length > 0) {
            params['searchstring'] = keyword;
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getFileListWithPagging(page: number = 0, pageSize: number = 0, keyword: string = '',
        sortBy: string = 'lastediteddate', sortOrder: string = 'desc'): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entryEndpoint}`;
        const params = {
            expand: 'source;submitter;entity;entities',
            page: page,
            rpp: pageSize,
            showpermission: true,
            sortby: sortBy,
            sortorder: sortOrder,
            advfilter: AppConfig.defaultAdvFileFilterForFileView,
            showattachmentonly: true,
            showattachmentserverfilename: true,
            'exclude-embedded-img': true,
        };
        if (keyword && keyword.length > 0) {
            params['searchstring'] = keyword;
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getFileEntryById(fileEntryId: string): Observable<any> {
        if (!fileEntryId) {
            return observableThrowError({ message: 'Empty id' });
        }
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryEndpoint}${fileEntryId}`;
        const params = {
            expand: 'source;submitter;entity;entities',
            showpermission: true,
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public mapFileList(response) {
        const fileList = new FileList();
        fileList.next = response.next;
        fileList.count = response.count;
        const dataItems = response['entry-List'];
        for (const dataItem of dataItems) {
            fileList.files.push(DocumentEntry.parse(dataItem));
        }
        return fileList;
    }

    queryByParams(
        pageIndex: number = 0,
        pageSize: number = 20,
        sortBy: string = 'lastediteddate',
        sortOrder: string = 'desc',
        searchText: string = '',
    ) {
        const params: any = {
            page: pageIndex,
            rpp: pageSize,
            sortby: sortBy,
            sortorder: sortOrder,
            expand: 'source;submitter;entity;entities',
            showpermission: true,
            outputformat: 'json',
            advfilter: AppConfig.defaultAdvFileFilterForFileView,
            showattachmentonly: true,
            showattachmentserverfilename: true,
            'exclude-embedded-img': true,
        };

        if (searchText) {
            params.searchstring = searchText;
        }

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };

        const options = {
            headers: headers,
            params: params,
        };
        const url = `${AppConfig.entryEndpoint}`;
        return this._transportService.get(url, options);
    }
}
