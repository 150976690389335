/**
 * Created by Abner SUi on April 22, 2020
 * Delete this after workflow merge
 * Updated by Daniel Wang on 2021/11/30: Remove this service after merging the two slidesheet.
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SlideSheetTempService {
    actionSubject$: Subject<any> = new Subject();
}
