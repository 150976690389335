import { businessConstants } from './../constants/business.constants';
import { DataModelBase } from './base.model';

export class RelationshipType extends DataModelBase {
    constructor(id: string, name: string) {
        super(id, name);
    }

    static readonly EMAIL_SOURCE: RelationshipType = {
        id: 'e12f789f19a5463c82740f2b7bead4c9',
        name: 'Email Source'
    };

    static readonly IS_IN_TEAM: RelationshipType = {
        id: businessConstants.RelationshipTypes.IS_IN_TEAM_ID,
        name: 'is in team'
    };

    static readonly OTHER: RelationshipType = {
        id: '23f035570a16aed0b1a7e4bda92a6552',
        name: 'Other'
    };

    static readonly TOPIC_MEMBER: RelationshipType = {
        id: businessConstants.RelationshipTypes.TOPIC_MEMBER_ID,
        name: 'Topic Member'
    };

    static parse(serverResponse): RelationshipType {
        if (serverResponse == null) {
            return new RelationshipType('', '');
        }
        if (serverResponse.hasOwnProperty('link')) {
            return {
                id: serverResponse.link.href.slice(-33, -1),
                name: serverResponse.link.phid
            };
        } else {
            return {
                id: serverResponse.id,
                name: serverResponse.name
            };
        }
    }
}
