/**
 * Created by mzhao on 09/28/2021.
 * Description:
 * ExchangeImportCheckDialogService Service
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EmailDigest } from '../../../tamalelibs/models/emaildigest.model';

@Injectable()
export class ConfirmSendDialogService {
    dialogClose$: Subject<any> = new Subject<any>();
    dialogOpen$: Subject<EmailDigest> = new Subject<EmailDigest>();
}
