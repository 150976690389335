import { Subject } from 'rxjs';
import { AppConfig } from '../../tamalelibs/models/app-config.model';

export class QueryInfo {
    searchText: string;
    entityIds: Array<string>;
    advFilterMap: Map<string, string>;
    sortBy: string;
    sortOrder: string;
    category: Array<string>;
    categoryFilter: Object;
    showCalendarEvents: boolean;

    constructor() {
        this.searchText = '';
        this.entityIds = [];
        this.advFilterMap = new Map();
        this.advFilterMap.set('default', AppConfig.defaultAdvFilterEvent);
        this.sortBy = 'displayDate';
        this.sortOrder = 'desc';
        this.category = [];
        this.categoryFilter = null;
        this.showCalendarEvents = false;
    }
}

export class SortInfo {
    sortBy: string;
    sortOrder: 'asc' | 'desc';

    constructor() {
        this.sortBy = 'displayDate';
        this.sortOrder = 'desc';
    }
}

export class CalendarRefinebyConfig {
    actions$: Subject<any>;
    events$: Subject<any>;

    constructor(public prefix: string = null, public instanceId: string = null) {
        this.actions$ = new Subject();
        this.events$ = new Subject();
    }
}
export enum RefinebyConfigType {
    entityEvent = 'ENTITYEVENT',
    allEvent = 'ALLEVENT',
    entry = 'ENTRY'
}
export enum CalendarRefinebyActions {
    UPDATE = 'UPDATE',
    RELOAD = 'RELOAD',
    RELOAD_DATA = 'RELOAD_DATA'
}

export enum CalendarRefinebyEvents {
    UPDATE = 'UPDATE',
    RELOAD = 'RELOAD',
    LOAD_REFINEBY = 'LOAD_REFINEBY',
    CHANGE_CATEGORY_ORDER = 'CHANGE_CATEGORY_ORDER'
}

