/**
 * Created by Abner Sui on 09/05/2019
 * ------ maintenance history ------
 * 9/23/2021 Simon Zhao added a model for PostNotification.
 * 5/5/2022 Simon Zhao added attributes to ConnectionLine model.
 */

import { EntityBrief } from './entity-brief.model';
import { ControlType } from './template-control.model';
import { DefinitionSetting, PrimaryEntityModel, WorkflowTriggerSettings } from './workflow-config.model';
import { AutomationModelBase } from './automation-base.model';
import { KeyValue } from '@angular/common';
import { StringLiteralsPipe } from '../../pipes/translate.pipe';
export const COLORS = ['#f44336', '#9c27b0', '#3f51b5', '#2196f3', '#00bcd4', '#4caf50', '#ff9800', '#795548', '#607d8b'];
export const DONE_COLOR = '#48A651';
export const REJECT_COLOR = '#F53249';
export const TODO_COLOR = '#595959';
export const NOT_START_FIELD_BACKGROUND_COLOR = '#efefef';
export const FIELD_DEFINITION_NODE_NAME = 'field-definition';
export const NOT_START_TASK_FIELD_STYLE = `<span style = 'background-color:${NOT_START_FIELD_BACKGROUND_COLOR};color:${TODO_COLOR};padding:0 6px 0 6px;border-radius: 2.5px;'> ${StringLiteralsPipe.translate('general.not_start')}</span>`;
export const NOT_START_APPROVE_TASK_FIELD_STYLE = `<span style = 'background-color:${NOT_START_FIELD_BACKGROUND_COLOR};color:${TODO_COLOR};padding:0 6px 0 6px;border-radius: 2.5px;'> ${StringLiteralsPipe.translate('workflow.task_status_pending')}</span>`;
export class ExpandGroup {
    id: string;
    key: string;
    parent: ExpandGroup;
}

export class Stage {
    id: string;
    name: string;
    color?: number;

    static parse(res): Stage {
        if (!res) {
            return null;
        }
        const result = new Stage();
        result.id = res;
        result.name = res;
        return result;
    }

    /**
     * Obtains an unused color for a stage.
     *
     * Currently, we have a predefined color library including 9 colors, and a color index not being used by any stage would be returned.
     * The less-used color index would be returned if all color have been used.
     * @param stages an array of existing stages.
     * @returns a color index
     */
    static obtainUnusedColor(stages: Array<Stage>): number {
        const usedColorsInStages = new Array(COLORS.length).fill(0);
        stages.forEach(s => {
            if (s && s.color >= 0) {
                usedColorsInStages[s.color % COLORS.length]++;
            }
        });
        const minUsage = usedColorsInStages.reduce((preItem: number, currentItem: number) => preItem < currentItem ? preItem : currentItem);
        const minUsedColorIndex = usedColorsInStages.findIndex(it => it === minUsage);
        return minUsedColorIndex >= 0 ? minUsedColorIndex : 0;
    }
}

export enum FieldType {
    ENTITY = 'entity',
    DATE = 'date',
    NUMBER = 'number',
    TEXT = 'text',
    TEXT_AREA = 'textArea',
    BOOLEAN = 'boolean',
    CURRENCY = 'currency',
    FILE = 'file',
    RICH_EDITOR = 'richEditor',
    CHECK_LIST = 'checkList',
    User = 'user'
}

export enum TaskDefinitionOpType {
    EDIT = 'EDIT',
    NEW = 'NEW'
}

export enum WorkflowKanbanTabType {
    TASK_BOARD_KANBAN = 'TASK_BOARD_KANBAN',
    PROJECT_BOARD_KANBAN = 'PROJECT_BOARD_KANBAN'
}

export enum WorkflowKanbanFilterType {
    SHOW_FILTER_STATUS = 'SHOW_FILTER_STATUS',
    ASSIGN_TO_ME = 'ASSIGN_TO_ME',
    OVER_DUE = 'OVER_DUE',
    HIGH_PRIORITY = 'HIGH_PRIORITY',
    VIEW_TYPE = 'VIEW_TYPE',
}

export enum WorkflowKanbanFilterStatus {
    IN_PROCESS = 'In Progress',
    CLOSED = 'Closed',
    ALL = 'All'
}

export class FileInfo {
    id: string;
    fileId: string;
    fileName: string;
    serverFileName: string;
    title: string;
    extension: string;
    fileSize: number;
    hash: string;
    hex: string;
    taskId: string;
    originalId?: string;

    static parse(res): FileInfo {
        if (!res) {
            return null;
        }
        const result = new FileInfo();
        result.id = res['id'];
        result.fileId = res['file-id'];
        result.fileName = res['file-name'];
        result.serverFileName = res['server-file-name'];
        result.title = res['title'];
        result.extension = res['extension'];
        result.fileSize = res['file-size'];
        result.hash = res['hash'];
        result.hex = res['hex'];
        result.taskId = res['task-id'];
        return result;
    }
}

export class FieldDefinitionConfiguration { // Only used for number control in grid, which comes from template
    // source?: Array<string>;
    // minlength?: number;
    // maxlength?: number;
    // staticDefaultValue?: any;
    type: ControlType;
    min?: number;
    max?: number;
    separator?: boolean;
    decimals?: number;

    static parse(res): FieldDefinitionConfiguration {
        if (!res) {
            return null;
        }
        if (Object.keys(res).length === 0) {
            return null;
        }
        const result = new FieldDefinitionConfiguration();
        result.type = res['type'];
        // if (res['source']) {
        //     result.source = res['source'];
        // }
        // if (res['staticDefaultValue'] !== null && res['staticDefaultValue'] !== undefined) {
        //     if (result.type === ControlType.SINGLE_ENTITY_DROP_DOWN) {
        //         result.staticDefaultValue = [EntityBrief.parse(res['staticDefaultValue'].data)];
        //     } else {
        //         result.staticDefaultValue = res['staticDefaultValue'];
        //     }
        // }
        // if (res['minlength'] !== null && res['minlength'] !== undefined) {
        //     result.minlength = res['minlength'];
        // }
        // if (res['maxlength'] !== null && res['maxlength'] !== undefined) {
        //     result.maxlength = res['maxlength'];
        // }
        result.separator = res['separator'];
        if (res['max'] !== null && res['max'] !== undefined) {
            result.max = res['max'];
        }
        if (res['min'] !== null && res['min'] !== undefined) {
            result.min = res['min'];
        }
        if (res['decimals'] !== null && res['decimals'] !== undefined) {
            result.decimals = res['decimals'];
        }
        return result;
    }
}

export class FieldDefinition {
    id: string;
    name: string;
    description: string;
    type: FieldType;
    createDate: Date;
    lastModifiedDate: Date;
    configuration?: FieldDefinitionConfiguration; // Only used for number control in grid, which comes from template

    static parse(res): FieldDefinition {
        if (!res) {
            return null;
        }
        const result = new FieldDefinition();
        result.id = res['id'];
        result.name = res['name'];
        result.description = res['description'];
        result.type = res['type'];
        result.createDate = new Date(res['create-date']);
        result.lastModifiedDate = new Date(res['last-modified-date']);
        return result;
    }

    static parseControl(res): FieldDefinition {
        if (!res) {
            return null;
        }
        const result = new FieldDefinition();
        result.id = res['fieldDefinitionId'];
        result.name = res['label'];
        result.description = res['description'];
        result.type = FieldDefinition.parseType(res['type']);
        return result;
    }

    static parseType(controlType: ControlType): FieldType {
        switch (controlType) {
            case ControlType.TEXT:
            case ControlType.SINGLE_TEXT_DROP_DOWN:
                return FieldType.TEXT;
            case ControlType.SINGLE_ENTITY_DROP_DOWN:
            case ControlType.MULTI_ENTITY_DROP_DOWN:
                return FieldType.ENTITY;
            case ControlType.SINGLE_USER_DROP_DOWN:
                return FieldType.User;
            case ControlType.NUMBER:
                return FieldType.NUMBER;
            case ControlType.FILE:
                return FieldType.FILE;
            case ControlType.DATE:
                return FieldType.DATE;
            case ControlType.CHECK_LIST:
                return FieldType.CHECK_LIST;
            case ControlType.RICH_EDITOR:
                return FieldType.RICH_EDITOR;
            default:
                return FieldType.TEXT;
        }
    }
}

export enum GatewayType {
    PARALLEL = 'parallel',
    EXCLUSIVE = 'exclusive',
    CONDITIONAL = 'conditional',
    AND = 'and',
    OR = 'or',
    SKIP = 'skip',
}

export const GatewayTypes: Array<TaskType | GatewayType> = [GatewayType.SKIP, GatewayType.AND, GatewayType.EXCLUSIVE, GatewayType.OR, GatewayType.PARALLEL, GatewayType.CONDITIONAL];
/**
 * the gateway related node types including exclusive, parallel and conditional.
 */
export const NodeTypes: Array<GatewayType> = [GatewayType.EXCLUSIVE, GatewayType.PARALLEL, GatewayType.CONDITIONAL];

export enum DefaultColumnID {
    PROJECT_NAME = 'project-name',
    STAGE = 'stage',
    CREATED_DATE = 'start-date',
    ORIGINATOR = 'originator'
}
/**
 * the defualt columns which are shown on project board
 */
export const DefaultColumnIDs: Array<string> = [DefaultColumnID.PROJECT_NAME, DefaultColumnID.STAGE, DefaultColumnID.CREATED_DATE, DefaultColumnID.ORIGINATOR];

export class GatewayDefinition {
    id: string;
    name: string;
    type: GatewayType;
    source?: string;
    target?: string;

    static parse(res): GatewayDefinition {
        if (!res) {
            return null;
        }
        const result = new GatewayDefinition();
        result.id = res['id'];
        result.name = res['name'];
        result.type = res['type'];
        result.source = res['source'];
        result.target = res['target'];
        return result;
    }
}

export class Field {
    id: string;
    fieldDefinition: FieldDefinition;
    value: any;

    static parse(res): Field {
        if (!res) {
            return null;
        }
        const oneDef = FieldDefinition.parse(res.data);
        if (oneDef) {
            const result = new Field();
            result.fieldDefinition = oneDef;
            result.id = result.fieldDefinition.id;
            return result;
        }
        return null;
    }

    static loadRawFieldValue(fld: Field, fldValue: any) {
        if (fld.fieldDefinition.type === FieldType.ENTITY || fld.fieldDefinition.type === FieldType.User) {
            const entitiesValue: Array<EntityBrief> = [];
            fldValue.forEach(item => {
                const one: EntityBrief = EntityBrief.parse(item.data);
                if (one) {
                    entitiesValue.push(one);
                }
            });
            if (entitiesValue.length === 0) {
                fld.value = null;
            } else {
                fld.value = entitiesValue;
            }
        } else if (fld.fieldDefinition.type === FieldType.DATE) {
            if (fldValue) {
                fld.value = new Date(+fldValue);
            }
        } else if (fld.fieldDefinition.type === FieldType.NUMBER
            || fld.fieldDefinition.type === FieldType.CURRENCY) {
            if (fldValue != null) {
                fld.value = +fldValue;
            }
        } else if (fld.fieldDefinition.type === FieldType.FILE) {
            const files: Array<FileInfo> = [];
            fldValue.forEach(item => {
                const one: FileInfo = FileInfo.parse(item.data);
                if (one) {
                    files.push(one);
                }
            });
            fld.value = files;
        } else {
            fld.value = fldValue;
        }
    }
}

export class FieldBrief {
    fieldId: string;
    controlId: string;
    controlName: string;
    taskDefinitionId: string;

    static parse(res): FieldBrief {
        if (!res) {
            return null;
        }
        const result = new FieldBrief();
        result.fieldId = res['fieldDefinitionId'];

        result.controlId = res['id'];
        result.controlName = res['label'];

        return result;
    }
}

export enum TaskType {
    APPROVAL = 'approval',
    INPUT = 'input',
}

/**
 * the task node type including approval and input.
 */
export const TaskTypes: Array<TaskType | GatewayType> = [TaskType.APPROVAL, TaskType.INPUT];

export enum TaskSubType {
    SINGLE = 'single',
    MAJORITY = 'majority',
    PERCENTAGE = 'percentage',
    ALL = 'all',
}

export class AssigneeDefinition {
    teams?: string;
    users?: string;
    dependField?: RelationshipAssignee;
}

export class Parent {
    fieldDefId: string;
    controlId: string;

    constructor() {
        this.fieldDefId = '';
        this.controlId = '';
    }

    static parse(res): Parent {
        if (!res) {
            return null;
        }
        const result = new Parent();
        result.fieldDefId = res['fieldDefId'];
        result.controlId = res['controlId'];

        return result;
    }
}

export class Relationship {
    parent: Parent;
    type: string;
    child: string;
    append: boolean;

    constructor() {
        this.parent = new Parent();
        this.type = '';
        this.child = '';
        this.append = false;
    }

    static parse(res): Relationship {
        if (!res) {
            return null;
        }
        const result = new Relationship();
        result.parent = Parent.parse(res['parent']);
        result.type = res['type'];
        result.child = res['child'];
        result.append = res['append'];
        return result;
    }
}

export class PostAction {
    relationship: Relationship;

    constructor() {
        this.relationship = new Relationship();
    }

    static parse(res): PostAction {
        if (!res) {
            return null;
        }
        const result = new PostAction();
        result.relationship = Relationship.parse(res['relationship']);

        return result;
    }
}

export class RelationshipAssignee {
    constructor(public fieldDefId: string, public relationshipType: string) { }

    static readonly FieldDefIdPropName = 'fieldDefId';

    static readonly RelationshipTypePropName = 'relationshipType';
}

export class PostNotification {
    dynamicReceivers: Array<any> = [];
    fixedReceivers: Array<string> = [];
    message = '';
    inApp = true;
    email = true;

    static parse(res): PostNotification {
        if (!res) {
            return null;
        }

        return res;
    }

    static integrityCheck(p: any) {
        if (!('message' in p)) {
            p['message'] = '';
        }
    }

    static getNullValue(p: PostNotification) {
        return {};
    }

    static isPostNotificationOn(p: any): boolean {
        return !!p && 'inApp' in p;
    }
}

export class ApprovalTaskConfiguration {
    type: TaskSubType;
    percentage?: any;

    static parse(res): ApprovalTaskConfiguration {
        if (!res) {
            return null;
        }
        const result = new ApprovalTaskConfiguration();
        result.type = res['type'];
        if (res['percentage'] !== null && res['percentage'] !== undefined) {
            result.percentage = res['percentage'];
        }

        return result;
    }
}

export class TaskDefinition {
    id: string;
    guid: string;
    name: string;
    type: TaskType;
    description: string;
    assignee: AssigneeDefinition = new AssigneeDefinition();
    // backup: Array<EntityBrief>;
    dueDate: any;
    createDate: Date;
    stage?: Stage;
    stageColor?: number;
    // adhocField: Array<AdhocField>; or Map<string, AdhocField>
    // action: Array<Action>;
    priority: any; // TODO
    fields: Map<string, Field> = new Map();
    template?: any;
    postAction: PostAction = new PostAction();
    postNotification: PostNotification;
    approvalTask?: ApprovalTaskConfiguration;

    static parse(res, template: Array<any>): TaskDefinition {
        if (!res) {
            return null;
        }
        if (!res['id']) {
            res = res.data;
        }
        const result = new TaskDefinition();
        result.id = res['id'];
        result.guid = res['guid'];
        result.name = res['name'];
        result.type = res['type'];
        result.description = res['description'];
        result.assignee = res['assignee']; // TODO
        result.dueDate = res['due-date']; // TODO
        result.createDate = new Date(res['create-date']);
        if (res['stage']) {
            result.stage = Stage.parse(res['stage']);
            result.stageColor = res['stageColor'];
        }
        result.priority = res['priority'];
        if (res['fields']) {
            res['fields'].forEach(item => {
                const one = Field.parse(item);
                if (one) {
                    result.fields.set(one.fieldDefinition.id, one);
                }
            });
        }
        if (res['approval-task']) {
            result.approvalTask = ApprovalTaskConfiguration.parse(res['approval-task']);
        }
        if (result.type === TaskType.INPUT && template) {
            const index = template.findIndex(item => item.id === result.id);
            if (index > -1) {
                result.template = template[index];
            }
        }
        result.postAction = PostAction.parse(res['post-action']);
        result.postNotification = PostNotification.parse(res['post-notification']);
        return result;
    }

    /**
     * determines whether the given task is a manual task that needs people to do something.
     *
     * @static
     * @param {TaskDefinition} taskDef the given task definition
     * @return {*}  {boolean} True --- the given task is either a input task or a approval task; Otherwise, it's a gateway or something.
     * @memberof TaskDefinition
     */
    static isManualTask(taskDef: TaskDefinition): boolean {
        return taskDef && taskDef.type && (taskDef.type === TaskType.APPROVAL || taskDef.type === TaskType.INPUT);
    }

    /**
     * Determines whether the source task definition is equal to the target one.
     * @param src the source task definition.
     * @param tar the target task definition.
     */
    static isEqualTo(src: TaskDefinition, tar: TaskDefinition): boolean {
        return src.id === tar.id;
    }

    /** gets the name of stage column.
     *
     * @static
     * @return {*}  {string}
     * @memberof TaskDefinition
     */
    static getStageColumnKey(): string {
        return 'stage';
    }

}

export class ApprovalTask {
    id: string;
    status: TaskStatus;
    submittedDate: Date;
    lastModifiedDate: Date;
    user: EntityBrief;

    static parse(res): ApprovalTask {
        if (!res) {
            return null;
        }
        const result = new ApprovalTask();
        result.id = res['id'];
        result.status = res['status'];
        result.submittedDate = new Date(res['submitted-date']);
        result.lastModifiedDate = new Date(res['last-modified-date']);
        if (res['user']) {
            result.user = EntityBrief.parse(res['user']);
        }
        return result;
    }
}

export const enum TaskStatus {
    TODO = 'ready',
    IN_PROGRESS = 'inprogress',
    DONE = 'completed',
    REJECTED = 'rejected',
    SEND_BACK = 'sendback',
    CALL_BACK = 'callback',
    COMPLETE_UPDATE = 'complete-update'
}

export class Comment {
    id: string;
    parentId: string;
    submitter: EntityBrief;
    comment: string;
    submittedDate: Date;
    lastModifiedDate: Date;

    static parse(res): Comment {
        if (!res) {
            return null;
        }
        const result = new Comment();
        result.id = res['id'];
        result.parentId = res['parent-id'];
        result.submitter = EntityBrief.parse(res['submitter']);
        result.comment = res['comment'];
        result.submittedDate = new Date(res['submitted-date']);
        result.lastModifiedDate = new Date(res['last-modified-date']);
        return result;
    }
}

export class TaskAttachment {
    id: string;
    fileId: string;
    fileName: string;
    title: string;
    extension: string;
    fileSize: number;
    hash: string;
    hex: string;

    static parse(res): TaskAttachment {
        if (!res) {
            return null;
        }
        const result = new TaskAttachment();
        result.id = res['id'];
        result.fileId = res['fileId'];
        result.fileName = res['fileName'];
        result.title = res['title'];
        result.extension = res['extension'];
        result.fileSize = res['fileSize'];
        result.hash = res['hash'];
        result.hex = res['hex'];
        return result;
    }
}

export class Task {
    id: string;
    name: string;
    taskDefinition?: TaskDefinition;
    gatewayDefinition?: GatewayDefinition;
    status: TaskStatus; // TODO TaskStatus
    owner: EntityBrief;
    // accessId: string;
    processInstance: ProcessInstance;
    startDate: Date;
    endDate: Date;
    submittedDate?: Date;
    lastModifiedDate?: Date;
    dueDate: Date;
    takeDate: Date;
    version: number;
    approvalTask: Array<ApprovalTask> = [];
    currentApprovalTask: ApprovalTask;
    assignee: Array<EntityBrief> = [];
    displayAssignee: Array<EntityBrief> = [];
    priority: number;
    /**
     * the field value map, contains field values on completing task instead of updating after committing it.
     */
    completedFields: Map<string, Field> = new Map();
    fields: Map<string, Field> = new Map();
    comments: Array<Comment> = [];
    attachments: Array<TaskAttachment> = [];

    static getSortColumnName() {
        return 'startDate';
    }

    static parse(res, instanceId: string, template: Array<any>): Task {
        if (!res) {
            return null;
        }
        const result = new Task();
        result.id = res['id'];
        result.name = res['name'];
        if (res['task-definition']) {
            result.taskDefinition = TaskDefinition.parse(res['task-definition'], template);
        }
        if (res['gateway-definition'] && res['gateway-definition'].data) {
            result.gatewayDefinition = GatewayDefinition.parse(res['gateway-definition'].data);
        }
        result.status = res['status'];
        if (res['owner'] && JSON.stringify(res['owner']) !== '{}') {
            result.owner = EntityBrief.parse(res['owner']);
        }
        if (res['process-instance']) {
            let tempInstance: ProcessInstance;
            if (res['process-instance'].data) {
                // tslint:disable-next-line: no-use-before-declare
                tempInstance = ProcessInstance.parse(res['process-instance'].data, null, template);
            } else if (res['process-instance'].link) {
                // tslint:disable-next-line: no-use-before-declare
                tempInstance = new ProcessInstance();
                tempInstance.id = res['process-instance'].link.phid;
            } else if (instanceId) {
                // tslint:disable-next-line: no-use-before-declare
                tempInstance = new ProcessInstance();
                tempInstance.id = instanceId;
            }
            result.processInstance = tempInstance;
        }
        result.startDate = new Date(res['start-date']);
        if (res['submitted-date']) {
            result.submittedDate = new Date(res['submitted-date']);
        }
        if (res['last-modified-date']) {
            result.lastModifiedDate = new Date(res['last-modified-date']);
        }
        if (res['end-date']) {
            result.endDate = new Date(res['end-date']);
        }
        if (res['due-date']) {
            result.dueDate = new Date(res['due-date']);
        }
        if (res['take-date']) {
            result.takeDate = new Date(res['take-date']);
        }
        result.version = res['version'];
        result.approvalTask = [];
        if (res['approval-task']) {
            res['approval-task'].forEach(item => {
                const one = ApprovalTask.parse(item);
                if (one) {
                    result.approvalTask.push(one);
                }
            });
        }
        result.currentApprovalTask = ApprovalTask.parse(res['current-approval-task']);
        result.assignee = [];
        if (res['assignee']) {
            res['assignee'].forEach(item => {
                if (item) {
                    result.assignee.push(EntityBrief.parse(item));
                }
            });
        }
        result.displayAssignee = [];
        if (res['display-assignee']) {
            res['display-assignee'].forEach(item => {
                if (item) {
                    result.displayAssignee.push(EntityBrief.parse(item));
                }
            });
        }
        result.priority = res['priority'];
        const loadFieldListFunc = (map: Map<string, Field>, fieldObjs: { fields: Array<any> }, taskDef?: TaskDefinition) => {
            fieldObjs.fields.forEach(oneTaskTempField => {
                const oneTaskField: Field = Field.parse(oneTaskTempField[FIELD_DEFINITION_NODE_NAME]);
                const oneTaskFieldValue: any = oneTaskTempField['value'];
                const key: string = oneTaskField.fieldDefinition.id;
                if (key.indexOf('_version') < 0) { // TODO server will change
                    let tempField: Field;
                    if (taskDef && taskDef.fields.has(key)) {
                        tempField = taskDef.fields.get(key);
                    } else {
                        tempField = oneTaskField;
                    }
                    Field.loadRawFieldValue(tempField, oneTaskFieldValue);
                    map.set(key, tempField);
                }
            });
        };
        if (res['version']) {
            if (res['version'].fields) {
                loadFieldListFunc(result.fields, res['version'], result.taskDefinition);
                if (result.taskDefinition) {
                    Array.from(result.taskDefinition.fields.values()).forEach(item => {
                        if (!result.fields.has(item.fieldDefinition.id)) {
                            result.fields.set(item.fieldDefinition.id, item);
                        }
                    });
                }
            }
        }
        if (res['completedVersion'] && res['completedVersion'].fields) {
            loadFieldListFunc(result.completedFields, res['completedVersion']);
        }
        if (res['comment']) {
            res['comment'].forEach(com => {
                result.comments.push(Comment.parse(com));
            });
        }
        return result;
    }

    /**
     * determines whether a given task instance is a manual task(input or approval so far).
     *
     * @static
     * @param {Task} t the given task instance
     * @return {*}  {boolean} a flag indicating whether the given task is a manual task.
     * @memberof Task
     */
    static isManualTask(t: Task): boolean {
        const manualTaskTypes = [TaskType.APPROVAL, TaskType.INPUT];

        return t.taskDefinition && manualTaskTypes.includes(t.taskDefinition.type);
    }
}

export enum ProcessInstanceStatus {
    IN_PROCESS = 'inprocess',
    DONE = 'completed',
    REJECTED = 'rejected',
}

export class InstanceCompleteUpdateHistoryData {
    comment: string;
    eventID: string;
    targetTaskID: string;
    targetTaskName: string;
    updateFields: Array<{}> = [];
    static extractFieldMap(data: InstanceCompleteUpdateHistoryData): Map<string, Field> {
        const fieldMap = new Map<string, Field>();
        data.updateFields.forEach(fI => {
            const field = Field.parse(fI[FIELD_DEFINITION_NODE_NAME]);
            Field.loadRawFieldValue(field, fI['value']);
            fieldMap.set(field.id, field);
        });
        return fieldMap;
    }
}

export enum ProjectHistoryActionType {
    COMPLETE = 'complete',
    SENDBACK = 'sendback',
    COMPLETE_UPDATE = 'complete-update',
    REJECT = 'reject',
    APPROVE = 'approve'
}

export class ProjectHistoryItemData {
    comment?: string;
    eventID?: string;
    fields?: Array<KeyValue<string, any>>;
    taskName?: string;
    taskID?: string;
    targetTaskID?: string;
    targetTaskName?: string;
    updateFields?: Array<KeyValue<string, any>>;

    static parseFieldList(fields: []) {
        const fieldArray = [];
        if (fields && fields.length > 0) {
            fields.forEach(fld => {
                fieldArray.push(
                    {
                        key: fld['field-definition-id'],
                        value: fld['value']
                    }
                );
            });
        }
        return fieldArray;
    }

    static parse(dataRe: {}) {
        const dataPartItem = new ProjectHistoryItemData();
        if (dataRe) {
            dataPartItem.taskName = dataRe['taskName'];
            dataPartItem.comment = dataRe['comment'];
            dataPartItem.fields = ProjectHistoryItemData.parseFieldList(dataRe['fields']);
            dataPartItem.eventID = dataRe['eventID'];
            dataPartItem.fields = dataRe['fields'] ? ProjectHistoryItemData.parseFieldList(dataRe['fields']) : undefined;
            dataPartItem.updateFields = dataRe['updateFields'] ? ProjectHistoryItemData.parseFieldList(dataRe['updateFields']) : undefined;
            dataPartItem.taskID = dataRe['taskID'];
            dataPartItem.targetTaskID = dataRe['targetTaskID'];
            dataPartItem.targetTaskName = dataRe['targetTaskName'];
        }
        return dataPartItem;
    }
}

export class ProjectHistoryItem {
    action: ProjectHistoryActionType;
    data: ProjectHistoryItemData;
    time: Date;
    type: string;
    user: EntityBrief;
    static parse(itemRe: {}) {
        const newItem = new ProjectHistoryItem();
        if (itemRe) {
            newItem.action = itemRe['action'] ? <ProjectHistoryActionType>itemRe['action'] : undefined;
            newItem.data = ProjectHistoryItemData.parse(itemRe['data']);
            newItem.time = new Date(itemRe['time']);
            newItem.type = itemRe['type'];
            newItem.user = EntityBrief.parse(itemRe['user']);
        }
        return newItem;
    }
}

export class ProjectHistory {
    fieldDefinitions: Array<FieldDefinition> = [];
    items: Array<ProjectHistoryItem> = [];
    templates: Array<any> = [];
    static parse(historyRe: {}): ProjectHistory {
        const projectHistory = new ProjectHistory();
        if (historyRe && historyRe['history']) {
            const historyObj = historyRe['history'];
            projectHistory.fieldDefinitions = historyObj['field-definition'] && Array.isArray(historyObj['field-definition']) ?
                Array.from(historyObj['field-definition']).map(fld => FieldDefinition.parse(fld['data'])) : [];
            projectHistory.templates = historyObj['template'];
            projectHistory.items = historyObj['history'] && Array.isArray(historyObj['history']) ?
                Array.from(historyObj['history']).map(it => ProjectHistoryItem.parse(it)) : [];
        }
        return projectHistory;
    }
}

export class HistoryRecord {
    attachments: Array<TaskAttachment> = [];
    approvalTask: Array<ApprovalTask>;
    comments: Array<Comment> = [];
    endDate?: Date;
    /**
     * the event id, used to reference to the corresponding history event item.
     */
    eventId?: string;
    id: string; // task id
    name: string;
    owner?: EntityBrief;
    startDate: Date;
    status: TaskStatus;
    takeDate?: Date;
    taskDefinition?: TaskDefinition;
    fields: Map<string, Field> = new Map();
    targetTask?: Task;
    type?: string; // for timeline.component
    isUpdate?: boolean;

    // TODO server may need optimize to simplify below logic
    static parseTask(res: Task): HistoryRecord {
        if (!res) {
            return null;
        }
        const result = new HistoryRecord();
        result.id = res.id;
        result.name = res.name;
        result.owner = res.owner;
        result.endDate = res.endDate;
        result.startDate = res.startDate;
        result.status = res.status;
        result.takeDate = res.takeDate;
        result.taskDefinition = res.taskDefinition;
        result.fields = res.fields;
        result.comments = res.comments;
        result.attachments = res.attachments;
        result.approvalTask = res.approvalTask;
        result.type = 'success';
        return result;
    }

    static parseEventDetail(res: ProcessInstanceEventDetail): HistoryRecord {
        if (!res) {
            return null;
        }
        const result = new HistoryRecord();
        result.id = res.id;
        result.name = res.taskDefinition.name;
        result.owner = res.owner;
        result.endDate = res.endDate;
        result.startDate = res.startDate;
        result.status = res.status;
        result.takeDate = res.takeDate;
        result.taskDefinition = res.taskDefinition;
        result.fields = res.fields;
        result.comments = res.comments;
        result.attachments = res.attachments;
        result.approvalTask = res.approvalTask;
        return result;
    }

    static parseEvent(res: ProcessInstanceEvent): HistoryRecord {
        if (!res) {
            return null;
        }
        const result = new HistoryRecord();
        if (res.triggerTaskDefinition) {
            result.id = res.triggerTaskId;
            result.name = res.triggerTaskDefinition.name;
        }

        if (res.id) {
            result.eventId = res.id;
        }

        result.owner = res.operateUser;
        result.endDate = res.operateTime;
        result.startDate = res.operateTime;
        result.status = res.type;
        result.takeDate = res.operateTime;
        if (res.triggerTaskDefinition) {
            result.taskDefinition = res.triggerTaskDefinition;
        } else {
            result.taskDefinition = res.targetTaskDefinition;
        }
        if (res.triggerTaskApprovalTask) {
            result.approvalTask = res.triggerTaskApprovalTask;
        }
        if (res.targetTaskDefinition) {
            result.targetTask = new Task();
            result.targetTask.id = res.targetTaskId;
            result.targetTask.taskDefinition = res.targetTaskDefinition;
            result.targetTask.name = res.targetTaskDefinition.name;
        }

        return result;
    }

    static attachEventDetailForDoneTask(item: HistoryRecord, instanceEvent: ProcessInstanceEvent, instance: ProcessInstance) {
        if (item.status === TaskStatus.COMPLETE_UPDATE && item.eventId && instance.processInstanceHistory.length > 0) {
            const historyItem = instance.processInstanceHistory.find(hI => hI.data.eventID === item.eventId);
            if (historyItem) {
                item.fields = InstanceCompleteUpdateHistoryData.extractFieldMap(historyItem.data);
            }
        }
    }
}

export class ProcessInstanceEventDetail {
    id: string; // task id
    owner?: EntityBrief;
    endDate?: Date;
    startDate: Date;
    status: TaskStatus;
    takeDate?: Date;
    taskDefinition?: TaskDefinition;
    fields: Map<string, Field> = new Map();
    comments: Array<Comment> = [];
    attachments: Array<TaskAttachment> = [];
    approvalTask: Array<ApprovalTask>;

    static parse(res, template: Array<any>): ProcessInstanceEventDetail {
        if (!res) {
            return null;
        }
        const result = new ProcessInstanceEventDetail();
        result.id = res['id'];
        result.startDate = new Date(res['start-date']);
        if (res['end-date']) {
            result.endDate = new Date(res['end-date']);
        }
        if (res['task-definition'] && res['task-definition'].data) {
            result.taskDefinition = TaskDefinition.parse(res['task-definition'].data, template);
        }
        result.status = res['status'];
        if (res['owner'] && JSON.stringify(res['owner']) !== '{}') {
            result.owner = EntityBrief.parse(res['owner']);
        }
        if (res['take-date']) {
            result.takeDate = new Date(res['take-date']);
        }
        result.approvalTask = [];
        if (res['approval-task']) {
            res['approval-task'].forEach(item => {
                const one = ApprovalTask.parse(item);
                if (one) {
                    result.approvalTask.push(one);
                }
            });
        }
        if (res['version']) {
            if (res['version'].fields) {
                const tempFields = res['version'].fields;
                tempFields.forEach(oneTaskTempField => {
                    const oneTaskField: Field = Field.parse(oneTaskTempField[FIELD_DEFINITION_NODE_NAME]);
                    const oneTaskFieldValue: any = oneTaskTempField['value'];
                    const key: string = oneTaskField.fieldDefinition.id;
                    if (key.indexOf('_version') < 0) { // TODO server will change
                        let tempField: Field;
                        if (result.taskDefinition && result.taskDefinition.fields.has(key)) {
                            tempField = result.taskDefinition.fields.get(key);
                        } else {
                            tempField = oneTaskField;
                        }
                        if (tempField.fieldDefinition.type === FieldType.ENTITY || tempField.fieldDefinition.type === FieldType.User) {
                            const entitiesValue: Array<EntityBrief> = [];
                            oneTaskFieldValue.forEach(item => {
                                const one: EntityBrief = EntityBrief.parse(item.data);
                                if (one) {
                                    entitiesValue.push(one);
                                }
                            });
                            if (entitiesValue.length === 0) {
                                tempField.value = null;
                            } else {
                                tempField.value = entitiesValue;
                            }
                        } else if (tempField.fieldDefinition.type === FieldType.DATE) {
                            if (oneTaskFieldValue) {
                                tempField.value = new Date(+oneTaskFieldValue);
                            }
                        } else if (tempField.fieldDefinition.type === FieldType.NUMBER
                            || tempField.fieldDefinition.type === FieldType.CURRENCY) {
                            if (oneTaskFieldValue != null) {
                                tempField.value = +oneTaskFieldValue;
                            }
                        } else if (tempField.fieldDefinition.type === FieldType.FILE) {
                            const files: Array<FileInfo> = [];
                            oneTaskFieldValue.forEach(item => {
                                const one: FileInfo = FileInfo.parse(item.data);
                                if (one) {
                                    files.push(one);
                                }
                            });
                            tempField.value = files;
                        } else {
                            tempField.value = oneTaskFieldValue;
                        }
                        result.fields.set(key, tempField);
                    }
                });
                if (result.taskDefinition) {
                    Array.from(result.taskDefinition.fields.values()).forEach(item => {
                        if (!result.fields.has(item.fieldDefinition.id)) {
                            result.fields.set(item.fieldDefinition.id, item);
                        }
                    });
                }
            }
        }
        if (res['comment']) {
            res['comment'].forEach(com => {
                result.comments.push(Comment.parse(com));
            });
        }
        return result;
    }
}

export enum InstanceActionEnum {
    COMPLETE = 'complete',
    COMPLETE_UPDATE = 'complete_update',
    UNKNOWN = 'unknown'
}

export class ProcessInstanceEvent {
    id: string;
    triggerTaskId: string;
    triggerTaskDefinition: TaskDefinition;
    triggerTaskApprovalTask: Array<ApprovalTask>;
    targetTaskId: string;
    targetTaskDefinition: TaskDefinition;
    type: TaskStatus;
    operateTime: Date;
    operateUser: EntityBrief;
    detail: Array<ProcessInstanceEventDetail>;

    static parse(res, template: Array<any>): ProcessInstanceEvent {
        if (!res) {
            return null;
        }
        const result = new ProcessInstanceEvent();
        result.id = res['id'];
        result.triggerTaskId = res['trigger-task-id'];
        // the trigger-task-definition node might be null
        const triggerTaskDef = res['trigger-task-definition'];
        if (triggerTaskDef) {
            result.triggerTaskDefinition = TaskDefinition.parse(triggerTaskDef.data, template);
        }
        result.triggerTaskApprovalTask = [];
        if (res['trigger-task-approval-task']) {
            res['trigger-task-approval-task'].forEach(item => {
                const one = ApprovalTask.parse(item);
                if (one) {
                    result.triggerTaskApprovalTask.push(one);
                }
            });
        }
        if (res['target-task-id']) {
            result.targetTaskId = res['target-task-id'];
            result.targetTaskDefinition = TaskDefinition.parse(res['target-task-definition'].data, template);
        }
        result.type = res['type'];
        result.operateTime = new Date(res['operate-time']);
        result.operateUser = EntityBrief.parse(res['operate-user']);
        result.detail = [];
        res['detail'].forEach(item => {
            const one: ProcessInstanceEventDetail = ProcessInstanceEventDetail.parse(item, template);
            if (one) {
                result.detail.push(one);
            }
        });
        return result;
    }
}

export class ProcessInstanceHistoryItem {
    action: InstanceActionEnum;
    data: InstanceCompleteUpdateHistoryData;
    time: Date;
    type: string;
    user: string;
    static parse(rawObj: {}): ProcessInstanceHistoryItem {
        const item = new ProcessInstanceHistoryItem();
        Object.assign(item, rawObj);
        return item;
    }
}

export enum TriggerBy {
    SCHEDULE_JOB = 'scheduleJob',
    MANUAL = 'manual',
    NOTE_DEPOSIT = 'noteDeposit'
}

export class ProcessInstance {
    processInstanceHistory: Array<ProcessInstanceHistoryItem> = [];
    id: string;
    projectName: string;
    primaryEntity: PrimaryEntityModel;
    processDefinition: ProcessDefinition;
    status: ProcessInstanceStatus;
    statusValue: string;
    startDate: Date;
    endDate: Date;
    // version: number;
    stage: Stage;
    processInstanceEvent: Array<ProcessInstanceEvent> = [];
    task: Array<Task> = [];
    originator: EntityBrief;
    priority: number;
    dueDate: Date;
    triggerBy: TriggerBy;
    triggerValue?: string;
    // an ordered array of task definitions no matter the task is instantiated or not.
    // this is intended to describe a potential path for current project instance.
    taskExecuteOrder: Array<{ id: string, name: string }> = [];
    /**
     * filter out all gateway tasks on the given process instance.
     * @param instance the given process instance
     */
    static filterOutGateways(instance: ProcessInstance) {
        if (instance) {
            instance.task = instance.task.filter(item => item.taskDefinition && TaskTypes.includes(item.taskDefinition.type));
        }
    }

    /**
     * Determinnes whether the given task is not a unfinished task of rejected projects.
     * @param project the given process instance
     * @param task the given task instance.
     * @returns a flag, true -- either the given project is not rejected or the given task has been done or rejected.
     */
    static isNotRejectedOngoingTask(project: ProcessInstance, task: Task) {
        const isRejectedInstance = project.status === ProcessInstanceStatus.REJECTED;
        const completeTaskStatus = [TaskStatus.DONE, TaskStatus.REJECTED];
        return !isRejectedInstance || (isRejectedInstance && completeTaskStatus.includes(task.status));
    }

    static parse(res, definitionId: string, template: Array<any>): ProcessInstance {
        try {
            if (!res) {
                return null;
            }
            const result = new ProcessInstance();
            result.id = res['id']; // TODO + '_' + res['version'];.
            if (res['process-definition']) {
                let tempDefinition: ProcessDefinition;
                if (res['process-definition'].data) {
                    // tslint:disable-next-line: no-use-before-declare
                    tempDefinition = ProcessDefinition.parse(res['process-definition'].data);
                } else if (res['process-definition'].link) {
                    // tslint:disable-next-line: no-use-before-declare
                    tempDefinition = new ProcessDefinition();
                    tempDefinition.id = res['process-definition'].link.href.slice(res['process-definition'].link.href.lastIndexOf('/'));
                } else if (definitionId) {
                    // tslint:disable-next-line: no-use-before-declare
                    tempDefinition = new ProcessDefinition();
                    tempDefinition.id = definitionId;
                }
                result.processDefinition = tempDefinition;
            }
            // TODO server will change state to object
            result.projectName = res['project-name'];
            result.status = res['status'];
            result.statusValue = res['status-value'];
            result.startDate = new Date(res['start-date']);
            if (res['end-date']) {
                result.endDate = new Date(res['end-date']);
            }
            // result.version = res['version'];
            if (res['stage']) {
                result.stage = Stage.parse(res['stage']);
            }
            result.task = [];
            if (res['task']) {
                res['task'].forEach(item => {
                    const one = Task.parse(item.data, result.id, template ? template : result.processDefinition.template);
                    if (one) {
                        result.task.push(one);
                    }
                });
            }
            result.processInstanceEvent = [];
            if (res['process-instance-event']) {
                res['process-instance-event'].forEach(item => {
                    const one = ProcessInstanceEvent.parse(item, template ? template : result.processDefinition.template);
                    if (one) {
                        result.processInstanceEvent.push(one);
                    }
                });
                result.processInstanceEvent.sort((a, b) => b.operateTime.getTime() - a.operateTime.getTime());
            }
            const history = res['history'];
            if (history && Array.isArray(history)) {
                Array.from(history).forEach(hi => {
                    result.processInstanceHistory.push(ProcessInstanceHistoryItem.parse(hi));
                });
            }

            if (res['originator']) {
                result.originator = EntityBrief.parse(res['originator']);
            }
            if (res['due-date']) {
                result.dueDate = new Date(res['due-date']);
            }
            if (res['priority'] !== null && res['priority'] !== undefined) {
                result.priority = res['priority'];
            }
            if (res['primaryEntity']) {
                result.primaryEntity = PrimaryEntityModel.parse(res['primaryEntity']);
            }
            if (res['taskExecuteOrder'] && Array.isArray(res['taskExecuteOrder'])) {
                result.taskExecuteOrder = Array.from(res['taskExecuteOrder']);
            }

            result.triggerBy = res['trigger-by'];
            result.triggerValue = res['trigger-value'];
            return result;
        } catch (ex) {
            throw ex;
        }
    }
}

export class ProcessTaskSequenceFlow {
    id: string;
    condition: any;
    processDefinitonId: string;
    processDefinitionVersion: number;
    sourceTaskDefinitionId: string;
    targetTaskDefinitionId: string;

    static parse(res): ProcessTaskSequenceFlow {
        if (!res) {
            return null;
        }
        const result = new ProcessTaskSequenceFlow();
        result.id = res['id'];
        result.condition = res['condition'];
        result.processDefinitonId = res['processDefinitonId'];
        result.processDefinitionVersion = res['processDefinitionVersion'];
        result.sourceTaskDefinitionId = res['sourceTaskDefinitionId'];
        result.targetTaskDefinitionId = res['targetTaskDefinitionId'];
        return result;
    }
}

export class ConnectionLine {
    /**
     * the tamale automation configurations.
     */
    automation?: Array<AutomationModelBase>;

    condition?: any;
    /**
     * the condition of passing through this line.
     * usually used by the conditional gateway, possible values like below shows:
     * "conditionSpEL": "#greaterThanNumber('i3e1exf9', 40, ##placeHolder##) || #lessThanNumber('i3e1exf9', 10, ##placeHolder##) "
       "conditionSpEL": "#greaterThanNumber('8qvgfxk9', 10, ##placeHolder##) && #containsText('pilb3oxs', 'lisa', ##placeHolder##)"
       "conditionSpEL": "#includeEntity('qrnyerhv', {'8050b2f2104748628be9e9003d3d38a3','678b26tyh474865rfhye9003dfgthu3'}, ##placeHolder##)"
       "conditionSpEL": true
     */
    conditionSpEL?: any;

    sourceRef: string;

    sort?: number;

    targetRef: string;

    static parse(res): ConnectionLine {
        if (!res) {
            return null;
        }
        const result = new ConnectionLine();
        result.sourceRef = res['sourceRef']; // TODO change name to be reasonable
        result.targetRef = res['targetRef'];
        result.condition = res['condition'];
        result.conditionSpEL = res['conditionSpEL'];
        result.automation = res['automation'];
        result.sort = res['sort'];
        return result;
    }
}

export class ProcessDefinitionStatus {
    inprocess: Array<string>;
    rejected: Array<string>;
    completed: Array<string>;

    static parse(res): ProcessDefinitionStatus {
        if (!res) {
            return null;
        }
        const result = new ProcessDefinitionStatus();
        result.inprocess = res['inprocess'];
        result.rejected = res['rejected'];
        result.completed = res['completed'];
        return result;
    }
}

export class ProcessDefinitionConfiguration {
    startTask: string;
    processDefinitionId: string;
    connectionLine: Array<ConnectionLine> = [];
    status: ProcessDefinitionStatus;
    triggerCondition: WorkflowTriggerSettings;
    id?: string;
    draft?: boolean;
    draftConfig?: Object;
    definitionSetting: DefinitionSetting;
    itemDefinition: Array<TaskDefinition> = [];

    constructor() {
        this.triggerCondition = new WorkflowTriggerSettings();
        this.definitionSetting = new DefinitionSetting();
        // below should be configurable and bind to UI, TAM-31914
        this.status = new ProcessDefinitionStatus();
        this.status[ProcessInstanceStatus.REJECTED] = ['Rejected'];
        this.status[ProcessInstanceStatus.IN_PROCESS] = ['In Progress'];
        this.status[ProcessInstanceStatus.DONE] = ['Done'];
    }

    static parse(res): ProcessDefinitionConfiguration {
        if (!res) {
            return null;
        }
        const result = new ProcessDefinitionConfiguration();
        result.startTask = res['startTask'];
        result.connectionLine = [];
        if (res['connectionLine']) {
            res['connectionLine'].forEach(item => {
                const one = ConnectionLine.parse(item);
                if (one) {
                    result.connectionLine.push(one);
                }
            });
        }
        if (res['definition']) {
            result.processDefinitionId = res['definition'].id;
        }
        if (res['status']) {
            result.status = ProcessDefinitionStatus.parse(res['status']);
        }
        if (res['triggerCondition']) {
            result.triggerCondition = WorkflowTriggerSettings.parse(res['triggerCondition']);
        }
        if (res['definitionSetting']) {
            result.definitionSetting = DefinitionSetting.parse(res['definitionSetting']);
        }
        if (res['id']) {
            result.id = res['id'];
        }
        if (res['draft']) {
            result.draft = res['draft'];
        }
        if (res['draftConfig']) {
            result.draftConfig = res['draftConfig'];
        }
        result.itemDefinition = [];
        if (res['itemDefinition']) {
            res['itemDefinition'].forEach(item => {
                const taskDefinition = TaskDefinition.parse(item, res['template']);
                if (taskDefinition) {
                    result.itemDefinition.push(taskDefinition);
                }
            });
        }
        return result;
    }
}

export class ProcessDefinition {
    id: string;
    name: string;
    configuration: ProcessDefinitionConfiguration;
    createDate: Date;
    submitter: EntityBrief;
    // version: number;
    gatewayDefinition: Array<GatewayDefinition> = [];
    taskDefinition: Array<TaskDefinition> = [];
    processTaskSequenceFlow: Array<any>;
    processInstance: Array<ProcessInstance> = [];
    template: Array<any>;

    /**
     * Do a shallow clone for the given process definition object.
     * @param processDef the process definition object
     * @returns a new cloned process definition object
     */
    static clone(processDef: ProcessDefinition) {
        const newProcessDef = new ProcessDefinition();
        const cloneArrayFunc = (arr: Array<any>) => {
            if (arr && arr.length && arr.length > 0) {
                return [...arr];
            }

            return [];
        };
        newProcessDef.id = processDef.id;
        newProcessDef.name = processDef.name;
        newProcessDef.configuration = processDef.configuration;
        newProcessDef.createDate = processDef.createDate;
        newProcessDef.submitter = processDef.submitter;
        newProcessDef.gatewayDefinition = cloneArrayFunc(processDef.gatewayDefinition);
        newProcessDef.taskDefinition = cloneArrayFunc(processDef.taskDefinition);
        newProcessDef.processTaskSequenceFlow = cloneArrayFunc(processDef.processTaskSequenceFlow);
        newProcessDef.processInstance = cloneArrayFunc(processDef.processInstance);
        newProcessDef.template = cloneArrayFunc(processDef.template);
        return newProcessDef;
    }

    /**
     * filters out all gateway tasks from the process instances' task attribute.
     * @param processDef the process definition object
     * @returns a cloned process definition object with the process instances on which all gateway tasks are removed.
     */
    static filterOutGateways(processDef: ProcessDefinition) {
        if (processDef) {
            processDef.processInstance.forEach(item => ProcessInstance.filterOutGateways(item));
            return processDef;
        } else {
            return null;
        }
    }

    /**
     * Compares two process definitions to work out task definitions only existing on the target process definition.
     * @param src the source process definition
     * @param tar the target process definition.
     */
    static getDifferentTaskDefs(src: ProcessDefinition, tar: ProcessDefinition): Array<TaskDefinition> {
        const taskDefs = [];
        if (src && src.taskDefinition && tar && tar.taskDefinition) {
            tar.taskDefinition.forEach(tarTaskDef => {
                if (src.taskDefinition.findIndex(srcTask => TaskDefinition.isEqualTo(srcTask, tarTaskDef)) < 0) {
                    taskDefs.push(tarTaskDef);
                }
            });
        }
        return taskDefs;
    }

    static parse(res): ProcessDefinition {
        if (!res) {
            return null;
        }
        const result = new ProcessDefinition();
        result.id = res['id']; // TODO + '_' + res['version'];
        result.name = res['name'];
        if (res['template']) {
            result.template = res['template'];
        }
        result.configuration = ProcessDefinitionConfiguration.parse(res['configuration']);
        result.createDate = new Date(res['create-date']);
        if (res['submitter']) {
            result.submitter = EntityBrief.parse(res['submitter']);
        }
        // result.version = res['version'];
        result.processTaskSequenceFlow = [];
        if (res['process-task-sequence-flow']) {
            res['process-task-sequence-flow'].forEach(item => {
                const one = ProcessTaskSequenceFlow.parse(item);
                if (one) {
                    result.processTaskSequenceFlow.push(one);
                }
            });
        }
        result.processInstance = [];
        if (res['process-instance']) {
            res['process-instance'].forEach(item => {
                const one = ProcessInstance.parse(item.data, result.id, result.template);
                if (one) {
                    result.processInstance.push(one);
                }
            });
        }
        result.taskDefinition = [];
        result.gatewayDefinition = [];
        if (res['task-definition']) {
            res['task-definition'].forEach(item => {
                if (GatewayTypes.indexOf(item.data.type) > -1) {
                    const one = GatewayDefinition.parse(item.data);
                    if (one) {
                        result.gatewayDefinition.push(one);
                    }
                } else {
                    const one = TaskDefinition.parse(item.data, result.template);
                    if (one) {
                        result.taskDefinition.push(one);
                    }
                }
            });
        }
        return result;
    }
}

// Task management priority mode
export enum PriorityMode {
    Low = 0,
    Normal = 1,
    High = 2
}

// Task management  operation type
export enum OperationType {
    Create = 0,
    Edit = 1
}

// Task management status
export const enum QuickTaskStatus {
    TODO = 'Todo',
    IN_PROGRESS = 'In Progress',
    DONE = 'Done',
}

// Task management processor type
export const enum Processor {
    AssignedToMe = 0,
    CreatedByMe = 1
}

// Task management filter status
export const enum TaskStatusFilter {
    Ongoing = 0,
    Closed = 1
}

// The base slass of task management
export class BaseTask {
    definitionId: string;
    subTaskId: string;
    entities: Array<EntityBrief>;
    subject: string;
    status: string;
    remindDate: string;

    constructor() {
        this.entities = [];
        this.subject = '';
        this.status = QuickTaskStatus.TODO;
    }
}

// The sub task which is assigned by creator
export class SubTask extends BaseTask {
    assignedDate: number;
    assignee: EntityBrief;
    endDate: number;
    lastModifiedDate: number;
    quickTask: QuickTask;
    status: string;
    dueDate: string;

    constructor() {
        super();

        this.status = QuickTaskStatus.TODO;
    }

    static parse(res): SubTask {
        if (!res) {
            return null;
        }
        const subTask = new SubTask();
        subTask.subTaskId = res.id;
        subTask.assignedDate = res['assigned-date'];
        if (res['assignee']) {
            const assignee = res['assignee'];
            const entityBrief = new EntityBrief(assignee.id, assignee['long-name']);
            subTask.assignee = entityBrief;
        }
        subTask.endDate = res['end-date'];
        subTask.lastModifiedDate = res['last-modified-date'];
        subTask.remindDate = res['remind-date'];
        subTask.status = res.status;
        if (res['task-definition']) {
            subTask.definitionId = res['task-definition'].id;
            subTask.subject = res['task-definition'].subject;
            subTask.dueDate = res['task-definition']['due-date'];
            const relatedTo = res['task-definition'].entities;
            if (relatedTo) {
                relatedTo.forEach(item => {
                    if (item) {
                        const entityBrief = new EntityBrief(item.id, item['long-name']);
                        entityBrief.shortName = item['short-name'];
                        entityBrief.isPublic = item['is-public'];
                        subTask.entities.push(entityBrief);
                    }
                });
            }
        }

        return subTask;
    }
}

// The task definition
export class QuickTask extends BaseTask {
    originator: EntityBrief;
    priority: PriorityMode;
    submittedDate: number;
    dueDate: string;
    finishFlag: number;
    finishNumber: number;
    subTask: Array<SubTask>;
    detail: string;
    assignee: Array<EntityBrief>;

    constructor() {
        super();

        this.priority = PriorityMode.Normal;
        this.finishFlag = 1;
        this.finishNumber = 0;
        this.subTask = [];
        this.detail = '';
        this.assignee = [];
    }

    static parse(res): QuickTask {
        if (!res) {
            return null;
        }
        const quickTask = new QuickTask();
        quickTask.definitionId = res.id;
        quickTask.subject = res.subject;
        quickTask.detail = res.detail;
        quickTask.priority = res.priority;
        quickTask.dueDate = res['due-date'];
        quickTask.remindDate = res['remind-date'];
        quickTask.submittedDate = res['submitted-date'];
        quickTask.finishFlag = res['finish-flag'];
        quickTask.finishNumber = res['finish-number'];

        quickTask.entities = [];
        if (res['entities'] && res['entities'].length > 0) {
            res['entities'].forEach(item => {
                if (item) {
                    const entityBrief = new EntityBrief(item.id, item['long-name']);
                    entityBrief.shortName = item['short-name'];
                    entityBrief.isPublic = item['is-public'];
                    quickTask.entities.push(entityBrief);
                }
            });
        }
        quickTask.assignee = [];
        if (res['assignee'] && res['assignee'].length > 0) {
            res['assignee'].forEach(item => {
                if (item) {
                    const entityBrief = new EntityBrief(item.id, item['long-name']);
                    entityBrief.shortName = item['short-name'];
                    entityBrief.isPublic = item['is-public'];
                    quickTask.assignee.push(entityBrief);
                }
            });
        }

        const originator = res['originator'];
        if (originator) {
            const entityBrief = new EntityBrief(originator.id, originator['long-name']);
            quickTask.originator = entityBrief;
        }

        const subTaskContainer = [];
        if (res['quick-task'] && res['quick-task'].length > 0) {
            res['quick-task'].forEach(subTask => {
                if (subTask) {
                    let item = new SubTask();
                    item = SubTask.parse(subTask);
                    subTaskContainer.push(item);
                }
            });
            quickTask.subTask = subTaskContainer;
        }
        quickTask.status = res.status;

        return quickTask;
    }
}

// The item is shown in task list
export class TaskItem extends BaseTask {
    id: string;
    allTasksNum = 0;
    allTasksRealNum = 0;
    doneTasks = [];
    doneTasksNum = 0;
    dueDate: Date;
    finishFlag: number;
    finishNumber: number;
    ongoingTasks = [];
    showTime: boolean;
    subTasks = [];
    taskOverdue: boolean;

    constructor() {
        super();

        this.showTime = false;
        this.taskOverdue = false;
        this.finishFlag = 0;
    }

    // parse task definition to task item
    static parseQuickTask(quick): TaskItem {
        if (quick) {
            const taskItem = new TaskItem();
            taskItem.id = taskItem.definitionId = quick.definitionId;
            taskItem.subject = quick.subject;
            taskItem.entities = quick.entities;
            taskItem.subTaskId = '';
            taskItem.status = quick.status;
            taskItem.remindDate = quick.remindDate;
            taskItem.finishFlag = quick.finishFlag;
            taskItem.finishNumber = quick.finishNumber;
            taskItem.subTasks = quick.subTask;

            if (taskItem.subTasks) {
                taskItem.subTasks.sort(function (a, b) {
                    return a.assignee.name.localeCompare(b.assignee.name);
                });

                taskItem.subTasks.forEach(subTask => {
                    if (subTask.status === 'done') {
                        taskItem.doneTasksNum = taskItem.doneTasksNum + 1;
                        taskItem.doneTasks.push(subTask);
                    } else if (subTask.status === 'todo') {
                        if (taskItem.status !== 'done') {
                            taskItem.ongoingTasks.push(subTask);
                        }
                    }
                });

                if (taskItem.finishFlag === 0) {
                    taskItem.allTasksNum = 1;
                    if (taskItem.doneTasksNum > 0) {
                        taskItem.doneTasksNum = 1;
                    }
                } else {
                    taskItem.allTasksNum = taskItem.finishNumber === 0 ? taskItem.subTasks.length : taskItem.finishNumber;
                }
                taskItem.allTasksRealNum = taskItem.subTasks.length;
            }

            if (quick.dueDate) {
                taskItem.showTime = false;

                const container = quick.dueDate.split('-');
                const year = container[0];
                const month = container[1];
                const day = container[2];
                const date = month + '/' + day + '/' + year;

                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth() + 1;
                const currentDay = currentDate.getDate() - 1;
                const current = currentMonth + '/' + currentDay + '/' + currentYear;

                if (new Date(date).getTime() <= new Date(current).getTime()) {
                    taskItem.taskOverdue = true;
                }
                taskItem.dueDate = new Date(date);
            }

            let status = '';
            if (taskItem.status) {
                switch (taskItem.status.toLowerCase()) {
                    case 'todo':
                        status = QuickTaskStatus.TODO;
                        break;
                    case 'progress':
                        status = QuickTaskStatus.IN_PROGRESS;
                        break;
                    case 'done':
                        status = QuickTaskStatus.DONE;
                        break;
                    default:
                        status = QuickTaskStatus.TODO;
                        break;
                }
            }
            taskItem.status = status;

            return taskItem;
        } else {
            return null;
        }
    }

    // parse sub task to task item
    static parseSubTask(sub): TaskItem {
        if (sub) {
            const taskItem = new TaskItem();
            taskItem.definitionId = sub.definitionId;
            taskItem.subject = sub.subject;
            taskItem.entities = sub.entities;
            taskItem.id = taskItem.subTaskId = sub.subTaskId;
            taskItem.status = sub.status;
            taskItem.remindDate = sub.remindDate;
            if (sub.dueDate) {
                taskItem.showTime = false;

                const container = sub.dueDate.split('-');
                const year = container[0];
                const month = container[1];
                const day = container[2];
                const date = month + '/' + day + '/' + year;


                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth() + 1;
                const currentDay = currentDate.getDate() - 1;
                const current = currentMonth + '/' + currentDay + '/' + currentYear;

                if (new Date(date).getTime() <= new Date(current).getTime()) {
                    taskItem.taskOverdue = true;
                }
                taskItem.dueDate = new Date(date);
            }

            let status = '';
            if (taskItem.status) {
                switch (taskItem.status.toLowerCase()) {
                    case 'todo':
                        status = QuickTaskStatus.TODO;
                        break;
                    case 'done':
                        status = QuickTaskStatus.DONE;
                        break;
                    default:
                        status = QuickTaskStatus.TODO;
                        break;
                }
            }
            taskItem.status = status;

            return taskItem;
        } else {
            return null;
        }
    }
}

export class WorkflowConfig {
    isPublic: boolean;
    name: string;
    type: string;
    accessList: string[];
    description: string;
    configuration: ProcessDefinitionConfiguration;
    id?: string;
    visible?: boolean;
    isLandingPage?: boolean;

    constructor() {
        this.isPublic = true;
        this.name = '';
        this.type = 'workflow';
        this.accessList = [];
        this.description = '';
        this.configuration = new ProcessDefinitionConfiguration();
        this.visible = true;
        this.isLandingPage = false;
    }
}

export class TaskColor {
    constructor(public backColor: string, public fontColor: string) {
    }
}

export class TemplateControlInfo {
    fieldId: string;
    controlValue: any; // controlValue, must be string or number
    controlType: string;

    constructor(fieldId: string, controlValue: any = '', controlType: string) {
        this.fieldId = fieldId;
        this.controlValue = controlValue;
        this.controlType = controlType;
    }
}

