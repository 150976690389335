/**
 * Created by Marcus Zhao 1/22/2022
 * Support on the fly entity,current used on workflow entity control
 * ------ maintenance history ------
 *
 */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs-compat';
import { filter, take } from 'rxjs/operators';
import { ContactTemplateOpenOptions, ContactTemplateType } from '../../components/contact-dialog-new/contact-dialog.model';
import { EntityBack, EntityDialogOpenOptions, EntityDialogType } from '../../components/entity-dialog/entity-dialog.model';
import { OpenDynamicComponentService } from '../../pages/home/home-open-dynamic-component';
import { HomeViewModel } from '../../pages/home/home-view.model';
import { Contact } from '../models/contact.model';
import { EntityType } from '../models/entity-type.model';
import { EntityService } from './entity.service';


@Injectable()
export class FlyService {
    public feedbackEntity$: Subject<any> = new Subject();

    constructor(
        private _openDynamicComponentService: OpenDynamicComponentService,
        private _homeViewModel: HomeViewModel,
        private _entityService: EntityService,
    ) { }

    /**
     * Create Entity on the fly
     */
    createEntity(searchValue, controlId, entityTypeIds) {
        if (entityTypeIds && entityTypeIds.length === 1 && entityTypeIds[0] === EntityType.ALL.name) {
            entityTypeIds = null;
        }
        let index;
        this._homeViewModel.increment();
        this._homeViewModel.getFlyIndex().pipe(take(1)).subscribe(res => index = res);
        const _entityDialogOpenOptions = new EntityDialogOpenOptions();
        _entityDialogOpenOptions.openType = EntityDialogType.NewEntity;
        _entityDialogOpenOptions.searchValue = searchValue;
        _entityDialogOpenOptions.instanceId = index.toString();
        _entityDialogOpenOptions.controlId = controlId;
        _entityDialogOpenOptions.entityType = entityTypeIds;
        this._openDynamicComponentService.openDialog$.next(_entityDialogOpenOptions);
    }
    /**
     * Create Contact on the fly
     */
    createContact(searchValue, controlId) {
        let index;
        this._homeViewModel.increment();
        this._homeViewModel.getFlyIndex().pipe(take(1)).subscribe(res => index = res);
        const _contactDialogOpenOptions = new ContactTemplateOpenOptions();
        _contactDialogOpenOptions.openType = ContactTemplateType.NewContact;
        const contact = new Contact();
        contact.name = searchValue;
        _contactDialogOpenOptions.contact = contact;
        const entityBack = new EntityBack();
        _contactDialogOpenOptions.instanceId = index.toString();
        entityBack.controlId = controlId;
        entityBack.searchValue = searchValue;
        _contactDialogOpenOptions.entityBack = entityBack;
        _contactDialogOpenOptions.isFly = true;
        this._openDynamicComponentService.openContactDialog$.next(_contactDialogOpenOptions);
    }

    /**
     * Judge show create contact or entity by source
     *
     * @param {*} source
     * @returns
     * @memberof FlyService
     */
    invalidOnTheFly(source) {
        // tslint:disable-next-line: no-use-before-declare
        const valid = new ValidShow();
        // check all
        if (source && source.findIndex(item => item === EntityType.ALL.id) !== -1) {
            valid.isShowCreateContact = true;
            valid.isShowCreateEntity = true;
            return valid;
        }
        // only entity types
        if (source && source.findIndex(item => item === EntityType.ALL.id) === -1 && source.findIndex(item => item === EntityType.CONTACT.id) === -1 && source.findIndex(item => item === EntityType.USER.id) === -1) {
            valid.isShowCreateEntity = true;
            valid.isShowCreateContact = false;
            return valid;
        }

        // contact or entity
        if (source && source.findIndex(item => item === EntityType.CONTACT.id) !== -1 || source.findIndex(item => item === EntityType.USER.id) !== -1) {
            valid.isShowCreateContact = true;
            if (source.length === 1) {
                valid.isShowCreateEntity = false;
            } else if (source.length === 2 && source.findIndex(item => item === EntityType.CONTACT.id) !== -1 && source.findIndex(item => item === EntityType.USER.id) !== -1) {
                valid.isShowCreateEntity = false;
            } else {
                valid.isShowCreateEntity = true;
            }
        }
        return valid;
    }

    getEntityValue(id) {
        const feebackSubscribe = this._openDynamicComponentService.feedbackSubject$.subscribe((feedback: EntityBack) => {
            if (feedback.controlId === id) {
                this._entityService.getEntityListByIdsQuick([feedback.entityId], '', '')
                    .subscribe(res => this._setFlyValue(res, id));
            }
        });
        return feebackSubscribe;
    }

    private _setFlyValue(entityList, id) {
        if (entityList) {
            const entity = this._entityService.mapEntityBriefList(entityList)[0];
            const payload = {
                id: id,
                entity: entity
            };
            this.feedbackEntity$.next(payload);
        }
    }
}

export class ValidShow {
    isShowCreateContact: boolean;
    isShowCreateEntity: boolean;
    constructor() {
        this.isShowCreateContact = true;
        this.isShowCreateEntity = true;
    }
}


