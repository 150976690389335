/**
 * Created by Yu Zhang on 7/11/17.
 * Description:
 *
 * ------ maintenance history ------
 */
export const NORMAL = 'Normal';
export const COMPONENT_LIFE = 'ComponentLife';
export const ENVIRONMENT = 'Environment';
export const NETWORK = 'Network';
export const STYLE = 'Style';
