/**
 * Created by simon zhao on 11/8/2021.
 * Description:
 * Progress Component
 * ------ maintenance history ------
 */
import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressNodeModel } from './progress.model';
import { ProgressOrientationEnum, ProgressNodeTypeEnum } from './progress.model';
import { EventEmitter } from '@angular/core';
@Component({
    selector: 'tam-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit, OnDestroy {

    @Input()
    progressOrientation: ProgressOrientationEnum = ProgressOrientationEnum.PORTRAIT;

    @Input()
    nodes: Array<ProgressNodeModel> = [];

    /**
     * a flag indicating whether progress widget is expanded.
     *
     * @memberof ProgressComponent
     */
    @Input()
    isExpanded = false;

    /**
     * notify the parent that the width of progress widget is changed.
     * this usually is caused by the expansion or collapse action.
     *
     * @memberof ProgressComponent
     */
    @Output()
    widthChanged = new EventEmitter<boolean>();

    /**
     * Notify the parent that a node of progress is clicked
     *
     * @memberof ProgressComponent
     */
    @Output()
    nodeClicked = new EventEmitter<ProgressNodeModel>();

    private _destroySubscriptions: Array<Subscription> = [];

    constructor() { }

    get isPortrait(): boolean {
        return this.progressOrientation === ProgressOrientationEnum.PORTRAIT;
    }

    isCompleteNode(node: ProgressNodeModel): boolean {
        return node.nodeType === ProgressNodeTypeEnum.COMPLETE_NODE;
    }

    isInactiveNode(node: ProgressNodeModel): boolean {
        return node.nodeType === ProgressNodeTypeEnum.INACTIVE_NODE;
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    onProgressClicked(e: any) {
        this.isExpanded = !this.isExpanded;
        this.widthChanged.emit(this.isExpanded);
        e.preventDefault();
        e.stopPropagation();
    }

    onProgressNodeClicked(node: ProgressNodeModel, e: any) {
        this.nodeClicked.emit(node);
        e.preventDefault();
        e.stopPropagation();
    }
}
