import { Action } from '@ngrx/store';
import { CustomAction } from './custom.actions';
import { ResearchScreenInitializationPayload, ResearchScreenInternalPayload, FilterInfo, ResearchScreenInitFocusRelationshipPayload, ResearchScreenInitOthersPayload } from '../../pages/research/research.view-model';

export enum ResearchScreenActionTypes {
    // thread list related
    SET_SELECTED_INDEX = '[DYNAMIC][RESEARCH][THREAD_LIST] SET_SELECTED_INDEX',
    SET_MATCHED_ATTACHMENTS = '[DYNAMIC][RESEARCH][THREAD_LIST] SET_MATCHED_ATTACHMENTS',
    ADD_MATCHED_ATTACHMENTS = '[DYNAMIC][RESEARCH][THREAD_LIST] ADD_MATCHED_ATTACHMENTS',
    RELOAD = '[DYNAMIC][RESEARCH][THREAD_LIST] RELOAD',
    RELOAD_SUCCESS = '[DYNAMIC][RESEARCH][THREAD_LIST] RELOAD_SUCCESS',
    RELOAD_FAILED = '[DYNAMIC][RESEARCH][THREAD_LIST] RELOAD_FAILED',
    LOAD_MORE = '[DYNAMIC][RESEARCH][THREAD_LIST] LOAD_MORE',
    LOAD_MORE_SUCCESS = '[DYNAMIC][RESEARCH][THREAD_LIST] LOAD_MORE_SUCCESS',
    LOAD_MORE_FAILED = '[DYNAMIC][RESEARCH][THREAD_LIST] LOAD_MORE_FAILED',
    SET_GRID_STATUS = '[DYNAMIC][RESEARCH][THREAD_LIST] SET_GRID_STATUS',
    CLEAR_THREAD_LIST = '[DYNAMIC][RESEARCH][THREAD_LIST] CLEAR',
    REMOVE_ATTACHMENT = '[DYNAMIC][RESEARCH][THREAD_LIST] REMOVE_ATTACHMENT',
    REMOVE_SIDENOTE = '[DYNAMIC][RESEARCH][THREAD_LIST] REMOVE_SIDENOTE',
    // query info related
    SET_QUERY_INFO = '[DYNAMIC][RESEARCH][QUERY_INFO] SET_VALUE',
    // focus related
    INITIALIZE_FOCUS = '[DYNAMIC][RESEARCH][FOCUS] INITIALIZE',
    SET_FOCUS_STATUS = '[DYNAMIC][RESEARCH][FOCUS] SET_STATUS',
    // keyword search related
    SET_KEY_WORD = '[DYNAMIC][RESEARCH][KEYWORD] SET_VALUE',
    // relationship tree related
    SET_RELATIONSHIP = '[DYNAMIC][RESEARCH][RELATIONSHIP] SET_VALUE',
    SET_RELATIONSHIP_NODES = '[DYNAMIC][RESEARCH][RELATIONSHIP] SET_NODES',
    SET_RELATIONSHIP_EXPANDED_KEYS = '[DYNAMIC][RESEARCH][RELATIONSHIP] SET_EXPANDED_KEYS',
    SET_RELATIONSHIP_CHECKED_KEYS = '[DYNAMIC][RESEARCH][RELATIONSHIP] SET_CHECKED_KEYS',
    SET_RELATIONSHIP_SHOW_NAME_FORMAT = '[DYNAMIC][RESEARCH][RELATIONSHIP] SET_NAME_FORMAT',
    RELOAD_GROUP_SUCCESS = '[DYNAMIC][RESEARCH][RELATIONSHIP]RELOAD_GROUP_SUCCESS',

    // sort related
    SET_SORT_INFO = '[DYNAMIC][RESEARCH][SORT_INFO] SET_VALUE',
    // layout related
    INITIALIZE_LAYOUT = '[DYNAMIC][RESEARCH][LAYOUT] INITIALIZE',
    SET_LAYOUT_MODE = '[DYNAMIC][RESEARCH][LAYOUT] SET_LAYOUT_MODE',
    SET_LAYOUT_COLLAPSE = '[DYNAMIC][RESEARCH][LAYOUT] SET_COLLSPASE_MODE',
    HIDE_ENTITY_FOCUS = '[DYNAMIC][RESEARCH][LAYOUT] HIDE_ENTITY_FOCUS',
    HIDE_RELATIONSHIP_TREE = '[DYNAMIC][RESEARCH][LAYOUT] HIDE_RELATIONSHIP_TREE',
    SET_ACTIVE_TAB = '[DYNAMIC][RESEARCH][LAYOUT] SET_ACTIVE_TAB',
    // display related
    SET_DISPLAY_INFO = '[DYNAMIC][RESEARCH][DISPLAY] SET_DISPLAY_INFO',
    // thread detail related
    GET_THREAD_NOTE_BODIES = '[DYNAMIC][RESEARCH][THREAD_DETAIL] GET_THREAD_NOTE_BODIES',
    GET_THREAD_NOTE_BODIES_SUCCESS = '[DYNAMIC][RESEARCH][THREAD_DETAIL] GET_THREAD_NOTE_BODIES_SUCCESS',
    GET_THREAD_NOTE_BODIES_FAILED = '[DYNAMIC][RESEARCH][THREAD_DETAIL] GET_THREAD_NOTE_BODIES_FAILED',
    // filter related
    SET_FILTER_LIST = '[DYNAMIC][RESEARCH][FILTER] SET_FILTER_LIST',
    ADD_FILTER_LIST = '[DYNAMIC][RESEARCH][FILTER] ADD_FILTER_LIST',
    SET_FILTER_ENABLE = '[DYNAMIC][RESEARCH][FILTER] SET_FILTER_ENABLE',
    SET_FILTER_LOGIC = '[DYNAMIC][RESEARCH][FILTER] SET_FILTER_LOGIC',
    // refineby related
    LOAD_REFINEBY = '[DYNAMIC][RESEARCH][REFINEBY] LOAD_REFINEBY',
    LOAD_REFINEBY_SUCCESS = '[DYNAMIC][RESEARCH][REFINEBY] LOAD_REFINEBY_SUCCESS',
    LOAD_REFINEBY_FAILED = '[DYNAMIC][RESEARCH][REFINEBY] LOAD_REFINEBY_FAILED',
    SET_REFINEBY_SELECTED_VALUE = '[DYNAMIC][RESEARCH][REFINEBY] SET_REFINEBY_SELECTED_VALUE',
    SET_REFINEBY_CATEGORY = '[DYNAMIC][RESEARCH][REFINEBY] SET_REFINEBY_CATEGORY',
    SET_REFINEBY_CATEGORY_ORDER = '[DYNAMIC][RESEARCH][REFINEBY] SET_REFINEBY_CATEGORY_ORDER',
    SET_REFINEBY_CATEGORY_STATUS = '[DYNAMIC][RESEARCH][REFINEBY] SET_REFINEBY_CATEGORY_STATUS',
    // research screen change status
    SET_HOME_SCREEN_CHANGE_STATUS = '[HOMESCREEN][RESEARCH][CHANGE][STATUS] SET_HOME_SCREEN_CHANGE_STATUS',
    SET_SAVE_SCREEN_CHANGE_STATUS = '[SAVESCREEN][RESEARCH][CHANGE][STATUS] SET_SAVE_SCREEN_CHANGE_STATUS',
    // optimized initialization
    INITIALIZE_ALL = '[DYNAMIC][RESEARCH]INITIALIZE_ALL',
    SCREEN_MAP_CONFIGURATION = '[DYNAMIC][RESEARCH]SCREEN_MAP_CONFIGURATION',
    SCREEN_MAP_CONFIGURATION_SUCCESS = '[DYNAMIC][RESEARCH]SCREEN_MAP_CONFIGURATION_SUCCESS',
    SCREEN_INIT_OTHERS = '[DYNAMIC][RESEARCH]SCREEN_INIT_OTHERS',
    SCREEN_PREPARE_FILTER = '[DYNAMIC][RESEARCH]SCREEN_PREPARE_FILTER',
    SCREEN_INIT_FILTER = '[DYNAMIC][RESEARCH]SCREEN_INIT_FILTER',
    SCREEN_PREPARE_FOCUS = '[DYNAMIC][RESEARCH]SCREEN_PREPARE_FOCUS',
    SCREEN_INIT_FOCUS_AND_RELATIONSHIP = '[DYNAMIC][RESEARCH]SCREEN_INIT_FOCUS_AND_RELATIONSHIP',
    INITIALIZE_ALL_FAILED = '[DYNAMIC][RESEARCH]INITIALIZE_ALL_FAILED',
    SET_GROUP_BY_NOTE_GROUP = '[DYNAMIC][RESEARCH]SET_GROUP_BY_NOTE_GROUP',
    SET_GROUP_BY_NOTE_ITEMS = '[DYNAMIC][RESEARCH]SET_GROUP_BY_NOTE_ITEMS',
    GROUP_LOAD_SUCCESS = '[DYNAMIC][RESEARCH]GROUP_LOAD_SUCCESS',
    SET_GRIDG_ROW_MODEL_TYPE = '[DYNAMIC][RESEARCH]SET_GRIDG_ROW_MODEL_TYPE',
    RESET_THREAD_LIST_PAGE = '[DYNAMIC][RESEARCH]RESET_THREAD_LIST_PAGE',
    CLEAR_GROUP_BY_NOTE_ITEMS = '[DYNAMIC][RESEARCH]CLEAR_GROUP_BY_NOTE_ITEMS',
    RELOAD_GROUP = '[DYNAMIC][RESEARCH]RELOAD_GROUP'
}

//#region thread list related
export class ResearchScreenThreadListReload implements CustomAction {
    readonly type = ResearchScreenActionTypes.RELOAD;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListReloadSuccess implements CustomAction {
    readonly type = ResearchScreenActionTypes.RELOAD_SUCCESS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListReloadGroup implements CustomAction {
    readonly type = ResearchScreenActionTypes.RELOAD_GROUP;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListLoadGroupByNoteSuccess implements CustomAction {
    readonly type = ResearchScreenActionTypes.GROUP_LOAD_SUCCESS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListReloadFailed implements CustomAction {
    readonly type = ResearchScreenActionTypes.RELOAD_FAILED;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListLoadMore implements CustomAction {
    readonly type = ResearchScreenActionTypes.LOAD_MORE;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListLoadMoreSuccess implements CustomAction {
    readonly type = ResearchScreenActionTypes.LOAD_MORE_SUCCESS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListLoadMoreFailed implements CustomAction {
    readonly type = ResearchScreenActionTypes.LOAD_MORE_FAILED;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListSetSelectedIndex implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_SELECTED_INDEX;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListSetMatchedAttachments implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_MATCHED_ATTACHMENTS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListSetGroupByNoteGroup implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_GROUP_BY_NOTE_GROUP;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListSetGroupByNoteItems implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_GROUP_BY_NOTE_ITEMS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListClearGroupByNoteItems implements CustomAction {
    readonly type = ResearchScreenActionTypes.CLEAR_GROUP_BY_NOTE_ITEMS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListSetGridRowModelType implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_GRIDG_ROW_MODEL_TYPE;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListAddMatchedAttachments implements CustomAction {
    readonly type = ResearchScreenActionTypes.ADD_MATCHED_ATTACHMENTS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListSetGridStatus implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_GRID_STATUS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenClearThreadList implements CustomAction {
    readonly type = ResearchScreenActionTypes.CLEAR_THREAD_LIST;

    constructor(
        public prefix,
    ) { }
}

export class ResearchScreenResetThreadListPage implements CustomAction {
    readonly type = ResearchScreenActionTypes.RESET_THREAD_LIST_PAGE;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListRemoveAttachment implements Action {
    readonly type = ResearchScreenActionTypes.REMOVE_ATTACHMENT;

    constructor(
        public prefix: any,
        public payload: any
    ) { }
}

export class ResearchScreenThreadListRemoveSidenote implements Action {
    readonly type = ResearchScreenActionTypes.REMOVE_SIDENOTE;

    constructor(
        public prefix: any,
        public payload: any
    ) { }
}
//#endregion

export class ResearchScreenSetQueryInfo implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_QUERY_INFO;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetSortInfo implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_SORT_INFO;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenInitializeFocus implements CustomAction {
    readonly type = ResearchScreenActionTypes.INITIALIZE_FOCUS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetFocusStatus implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_FOCUS_STATUS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetKeyWord implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_KEY_WORD;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

//#region layout related
export class ResearchScreenInitializeLayout implements CustomAction {
    readonly type = ResearchScreenActionTypes.INITIALIZE_LAYOUT;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetLayoutMode implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_LAYOUT_MODE;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetLayoutCollapse implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_LAYOUT_COLLAPSE;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenHideEntityFocus implements CustomAction {
    readonly type = ResearchScreenActionTypes.HIDE_ENTITY_FOCUS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenHideRelationshipTree implements CustomAction {
    readonly type = ResearchScreenActionTypes.HIDE_RELATIONSHIP_TREE;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetActiveTab implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_ACTIVE_TAB;

    constructor(
        public prefix,
        public payload: string
    ) { }
}

//#endregion

// display related
export class ResearchScreenSetDisplayInfo implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_DISPLAY_INFO;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetRelationship implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_RELATIONSHIP;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetRelationshipNodes implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_RELATIONSHIP_NODES;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetRelationshipExpandedKeys implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_RELATIONSHIP_EXPANDED_KEYS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetRelationshipCheckedKeys implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_RELATIONSHIP_CHECKED_KEYS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetRelationshipNameFormat implements CustomAction {
    readonly type = ResearchScreenActionTypes.SET_RELATIONSHIP_SHOW_NAME_FORMAT;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenGetThreadNoteBodies implements Action {
    readonly type = ResearchScreenActionTypes.GET_THREAD_NOTE_BODIES;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenGetThreadNoteBodiesSuccess implements Action {
    readonly type = ResearchScreenActionTypes.GET_THREAD_NOTE_BODIES_SUCCESS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenGetThreadNoteBodiesFailed implements Action {
    readonly type = ResearchScreenActionTypes.GET_THREAD_NOTE_BODIES_FAILED;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetFilterEnable implements Action {
    readonly type = ResearchScreenActionTypes.SET_FILTER_ENABLE;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetFilterLogic implements Action {
    readonly type = ResearchScreenActionTypes.SET_FILTER_LOGIC;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenSetFilterList implements Action {
    readonly type = ResearchScreenActionTypes.SET_FILTER_LIST;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenAddFilterList implements Action {
    readonly type = ResearchScreenActionTypes.ADD_FILTER_LIST;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

// refineby related
export class ResearchScreenRefinebyLoad implements Action {
    readonly type = ResearchScreenActionTypes.LOAD_REFINEBY;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenRefinebyLoadSuccess implements Action {
    readonly type = ResearchScreenActionTypes.LOAD_REFINEBY_SUCCESS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenRefinebyLoadFailed implements Action {
    readonly type = ResearchScreenActionTypes.LOAD_REFINEBY_FAILED;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenRefinebySetSelectedValue implements Action {
    readonly type = ResearchScreenActionTypes.SET_REFINEBY_SELECTED_VALUE;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenRefinebySetCategory implements Action {
    readonly type = ResearchScreenActionTypes.SET_REFINEBY_CATEGORY;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenRefinebySetCategoryOrder implements Action {
    readonly type = ResearchScreenActionTypes.SET_REFINEBY_CATEGORY_ORDER;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export class ResearchScreenRefinebySetCategoryStatus implements Action {
    readonly type = ResearchScreenActionTypes.SET_REFINEBY_CATEGORY_STATUS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

// home screen research change status
export class HomeScreenResearchViewChangeStatus implements Action {
    readonly type = ResearchScreenActionTypes.SET_HOME_SCREEN_CHANGE_STATUS;

    constructor(public payload: any) { }
}

// save screen research chanage status
export class SaveScreenResearchViewChangeStatus implements Action {
    readonly type = ResearchScreenActionTypes.SET_SAVE_SCREEN_CHANGE_STATUS;

    constructor(public payload: any) { }
}

export class ResearchScreenInitialization implements Action {
    readonly type = ResearchScreenActionTypes.INITIALIZE_ALL;
    constructor(public prefix, public payload: ResearchScreenInitializationPayload) { }
}

export class ResearchScreenInitializationFailed implements Action {
    readonly type = ResearchScreenActionTypes.INITIALIZE_ALL_FAILED;
    constructor(public prefix, public payload: ResearchScreenInitializationPayload) { }
}

export class MapConfiguration implements Action {
    readonly type = ResearchScreenActionTypes.SCREEN_MAP_CONFIGURATION;
    constructor(public prefix, public payload: ResearchScreenInternalPayload) { }
}

export class MapConfigurationSuccess implements Action {
    readonly type = ResearchScreenActionTypes.SCREEN_MAP_CONFIGURATION_SUCCESS;
    constructor(public prefix, public payload: ResearchScreenInternalPayload) { }
}

export class InitOthers implements Action {
    readonly type = ResearchScreenActionTypes.SCREEN_INIT_OTHERS;
    constructor(public prefix, public payload: ResearchScreenInitOthersPayload) { }
}

export class LoadFilter implements Action {
    readonly type = ResearchScreenActionTypes.SCREEN_PREPARE_FILTER;
    constructor(public prefix, public payload: ResearchScreenInternalPayload) { }
}

export class InitFilter implements Action {
    readonly type = ResearchScreenActionTypes.SCREEN_INIT_FILTER;
    constructor(public prefix, public payload: FilterInfo) { }
}

export class LoadFocus implements Action {
    readonly type = ResearchScreenActionTypes.SCREEN_PREPARE_FOCUS;
    constructor(public prefix, public payload: ResearchScreenInternalPayload) { }
}

export class InitFocusAndRelationship implements Action {
    readonly type = ResearchScreenActionTypes.SCREEN_INIT_FOCUS_AND_RELATIONSHIP;
    constructor(public prefix, public payload: ResearchScreenInitFocusRelationshipPayload) { }
}

export type ResearchScreenActions =
    // thread list related
    ResearchScreenThreadListReload |
    ResearchScreenThreadListReloadSuccess |
    ResearchScreenThreadListLoadGroupByNoteSuccess |
    ResearchScreenThreadListReloadGroup |
    ResearchScreenThreadListReloadFailed |
    ResearchScreenThreadListLoadMore |
    ResearchScreenThreadListLoadMoreSuccess |
    ResearchScreenThreadListLoadMoreFailed |
    ResearchScreenThreadListAddMatchedAttachments |
    ResearchScreenThreadListSetMatchedAttachments |
    ResearchScreenThreadListSetGroupByNoteGroup |
    ResearchScreenThreadListSetGroupByNoteItems |
    ResearchScreenThreadListClearGroupByNoteItems |
    ResearchScreenThreadListSetGridRowModelType |
    ResearchScreenThreadListSetSelectedIndex |
    ResearchScreenThreadListSetGridStatus |
    ResearchScreenClearThreadList |
    ResearchScreenResetThreadListPage |
    ResearchScreenThreadListRemoveAttachment |
    ResearchScreenThreadListRemoveSidenote |
    // query info related
    ResearchScreenSetQueryInfo |
    // keyword related
    ResearchScreenSetKeyWord |
    // relationship tree related
    ResearchScreenSetRelationship |
    ResearchScreenSetRelationshipNodes |
    ResearchScreenSetRelationshipExpandedKeys |
    ResearchScreenSetRelationshipCheckedKeys |
    ResearchScreenSetRelationshipNameFormat |
    // layout related
    ResearchScreenInitializeLayout |
    ResearchScreenSetLayoutMode |
    ResearchScreenSetLayoutCollapse |
    ResearchScreenHideEntityFocus |
    ResearchScreenHideRelationshipTree |
    ResearchScreenSetActiveTab |
    // display related
    ResearchScreenSetDisplayInfo |
    // sort related
    ResearchScreenSetSortInfo |
    // focus related
    ResearchScreenInitializeFocus |
    ResearchScreenSetFocusStatus |
    // filter related
    ResearchScreenSetFilterEnable |
    ResearchScreenSetFilterLogic |
    ResearchScreenSetFilterList |
    ResearchScreenAddFilterList |
    // refineby related
    ResearchScreenRefinebyLoad |
    ResearchScreenRefinebyLoadSuccess |
    ResearchScreenRefinebyLoadFailed |
    ResearchScreenRefinebySetSelectedValue |
    ResearchScreenRefinebySetCategory |
    ResearchScreenRefinebySetCategoryStatus |
    ResearchScreenRefinebySetCategoryOrder |
    // home screen research view change status
    HomeScreenResearchViewChangeStatus |
    // save screen research view change status
    SaveScreenResearchViewChangeStatus |
    // init screen research view action series
    ResearchScreenInitialization |
    ResearchScreenInitializationFailed |
    MapConfiguration | MapConfigurationSuccess | LoadFilter | LoadFocus |
    InitOthers | InitFilter | InitFocusAndRelationship;

