import { Subject } from 'rxjs';

export class TabItem {
    displayText: string;
    disabled: boolean;

    constructor(displayText: string, disabled: boolean = false) {
        this.displayText = displayText;
        this.disabled = disabled;
    }
}

export enum TabActionTypes {
    click
}

export class TabAction {
    type: TabActionTypes;
    payload: any;
}
export enum TabAlignTypes {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    CENTER = 'CENTER',

}
export class TabOptions {
    events$: Subject<any>;
    tabItems: Array<TabItem>;
    tabActionSubject$: Subject<TabAction>;
    selectedIndex: number;
    isRefresh: boolean;
    align: TabAlignTypes = TabAlignTypes.LEFT;
    style: Object = { 'font-size': '16px', 'height': '44px' };

    constructor(tabItems: Array<TabItem>, selectedIndex: number = 0, isRefresh?, style?: object, align?: TabAlignTypes | string) {
        this.events$ = new Subject();
        this.tabItems = tabItems;
        this.selectedIndex = selectedIndex;
        this.tabActionSubject$ = new Subject<TabAction>();
        this.isRefresh = isRefresh;
        if (style) {
            this.style = style;
        }
        if (align) {
            this.align = TabAlignTypes[align];
        }
    }
}
