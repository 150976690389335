/**
 * Created by Abner Sui on 1/5/2021
 * -------------------------------------
 */

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { AppState } from '../../../redux';
import { StoreQuerierService } from '../../../services/store-querier.service';
import { FieldDefinition, FieldType } from '../../../tamalelibs/models/workflow.model';
import { WorkflowActionTypes } from '../../../tamalelibs/redux/actions/workflow.actions';
import { workflowFieldsSelector } from '../../../tamalelibs/redux/reducers/workflow.reducer';
import { WorkflowService } from '../../../tamalelibs/services/workflow.service';
import { ControlConfigEvents, IControlConfigComponent } from '../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';
import * as _lodash from 'lodash';
import { TemplateBubbleService } from '../../template-configuration/template-bubble.service';

@Component({
    selector: 'field-config-base',
    templateUrl: './field-config-base.component.html',
    styleUrls: ['./field-config.component.scss']
})
export class FieldConfigBaseComponent implements OnInit, OnDestroy, IControlConfigComponent {

    @Input()
    config: FieldConfig;

    @Input()
    dataType: FieldType;
    /**
     * a flag indicating whether the read-only option is visible.
     */
    @Input()
    isReadonlyVisible = false;

    @ViewChild('fieldkendo', { static: false }) fieldkendo;
    fieldLoading = false;
    fieldDefinition: FieldDefinition;
    labelRequireInvalid = false;
    private _filterFieldSubject$: Subject<string> = new Subject();
    private _allFieldData: Array<FieldDefinition>;
    private _usedFieldName: Array<string>;
    private _preFieldDefinitionId: string;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _store: Store<AppState>,
        private _storeQuerier: StoreQuerierService,
        private _workflowService: WorkflowService,
        private _bubbleService: TemplateBubbleService,
    ) { }

    getInvalidDetail(): FieldValidationError {
        const fieldError = new FieldValidationError();
        fieldError.isRequiredInvalid = this.labelRequireInvalid;
        fieldError.isWidthInvalid = !this.config.width;
        fieldError.isLableDuplicatedInvalid = this.config.isDuplicated;
        return fieldError;
    }

    ngOnInit(): void {
        this._preFieldDefinitionId = this.config.fieldDefinitionId;
        this._destroySubscriptions.push(
            this._store.pipe(
                select(workflowFieldsSelector),
                filter(data => {
                    if (data) {
                        return true;
                    } else {
                        this.fieldLoading = true;
                        return false;
                    }
                }),
            ).subscribe((res: Array<FieldDefinition>) => {
                this._allFieldData = [...res];
                this.fieldDefinition = this._allFieldData.find(item => item.id === this.config.fieldDefinitionId);
                this.fieldLoading = false;
            }),
            this._filterFieldSubject$.pipe(
                debounceTime(250),
            ).subscribe(str => {
                this.labelRequireInvalid = !this.validateLabelRequire();
                let match: FieldDefinition = null;
                if (str) {
                    if (!this.labelRequireInvalid) {
                        match = new FieldDefinition();
                        if (!this.config.fieldDefinitionId) {
                            match.id = this._workflowService.getIdFromServerInStore(this._store);
                        } else {
                            match.id = this.config.fieldDefinitionId;
                        }
                        match.name = str;
                        match.type = this.dataType;
                        this.config.fieldDefinitionId = match.id;
                    }
                } else {
                    this.config.isDuplicated = false;
                }

                if (this.config.fieldDefinitionId && match) {
                    this._store.dispatch({
                        type: WorkflowActionTypes.USE_ONE_FIELD,
                        payload: {
                            fieldId: this.config.fieldDefinitionId,
                            fieldDefinition: match,
                        },
                    });

                    // create a new control
                    const newControl = { taskDefinitionId: this.config.fieldDefinitionId };
                    Object.assign(newControl, this.config);
                    // remove useless properties
                    delete newControl['component'];
                    delete newControl['configComponent'];
                    delete newControl['config'];
                    // add the new control to the store
                    this._store.dispatch({
                        type: WorkflowActionTypes.UPDATE_CURRENT_WORKFLOW_ALL_FIELDS,
                        payload: {
                            taskDefinitionId: this.config.fieldDefinitionId,
                            changedFields: [newControl]
                        }
                    });
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    validate(): boolean {
        this.labelRequireInvalid = !this.validateLabelRequire();
        return !this.labelRequireInvalid && !!this.config.width && !this.config.isDuplicated;
    }

    validateLabelDuplicate(): boolean {
        return this.config.isDuplicated;
    }

    validateLabelRequire(): boolean {
        return !!this.config.label;
    }

    valueChange(event): void {
        this.config.config.feedbackSubject$.next({
            type: ControlConfigEvents.VALUE_CHANGE,
            payload: this.config,
        });
    }

    valueChangeLabel(event): void {
        this._filterFieldSubject$.next(this.config.label);
        this.labelRequireInvalid = !this.validateLabelRequire();
        this.valueChange(event);
        this._bubbleService.fieldLabelChangeSubject$.next(this.config);
    }

    valueChangeWidth(event, value: number): void {
        if (value <= this.config.maxWidth && !this.config.fixWidth) {
            const preValue = this.config.width;
            this.config.width = value;
            this.config.config.feedbackSubject$.next({
                type: ControlConfigEvents.CHANGE_WIDTH,
                payload: {
                    preValue: preValue,
                    curValue: value,
                },
            });
            this.valueChange(event);
        }
    }
}
