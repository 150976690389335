import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import * as MarkJS from 'mark.js/dist/mark';
import * as juice from 'juice';
import * as anchorme from 'anchorme';
import { NoteBodyHtmlMetadatas } from '../../tamalelibs/constants/business.constants';
import { ImgPreviewService } from '../../widgets/img-preview/img-preview.service';
import { ImgPreviewActionTypes } from '../../widgets/img-preview/img-preview.model';

@Component({
    selector: 'note-detail-body',
    templateUrl: './note-detail-body.component.html',
    styleUrls: ['./note-detail-body.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteDetailBodyComponent implements OnInit, AfterViewInit {
    @Input() body: string;
    @Input() highlightText: string[];
    hightLightedBody = '';
    renderDone = false; // for print
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _imgPreviewService: ImgPreviewService,
    ) { }


    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.body) {
            this.hightLightedBody = this._getHighlightedNoteBody();
            this._changeDetectorRef.detectChanges();
        }
        setTimeout(() => {
            this.renderDone = true;
            if (!this._changeDetectorRef['destroyed']) {
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    previewImg(event) {
        if (event.srcElement.nodeName === 'IMG' && event.srcElement.attributes
            && event.srcElement.attributes.src && event.srcElement.attributes.src.nodeValue) {
            this._imgPreviewService.actionSubject$.next({
                type: ImgPreviewActionTypes.OPEN,
                payload: event.srcElement.attributes.src.nodeValue,
            });
            event['img-preview'] = true;
        }
    }

    private _getHighlightedNoteBody(): string {
        if (this.body) {
            const ele = document.createElement('div');
            // reg define entry.service.ts
            const reg = /(h )?table#tabledisplay-meta[\s\S]*?\}/gm;
            if (this.body.match(reg) || this.body.includes(NoteBodyHtmlMetadatas.fullHtml.slice(0, 100))) {
                ele.innerHTML = juice(this.body);
                // add target for the link in note body
                const eleCollection = ele.getElementsByTagName('a');
                if (eleCollection && eleCollection.length > 0) {
                    for (let i = 0; i < eleCollection.length; i++) {
                        eleCollection[i].target = '_blank';
                    }
                }
            } else { // body come from server api or rich client
                const newValue = anchorme.default(this.body, {
                    attributes: [
                        (urlObj) => {
                            if (urlObj.protocol !== 'mailto:') {
                                return {
                                    name: 'target',
                                    value: '_blank'
                                };
                            }
                        }
                    ]
                });
                ele.innerHTML = newValue;
            }

            // high light the text
            if (this.highlightText) {
                const bodyMark = new MarkJS(ele);
                bodyMark.mark(this.highlightText, {
                    'element': 'span',
                    'className': 'highlight',
                    'separateWordSearch': false
                });
            }
            return ele.innerHTML;
        } else {
            return '';
        }
    }



}
