<div class="container">
    <field-config-base #base [config]="config" [dataType]="dataType"></field-config-base>
    <div tid="data_source" class="tam-form-field one-field" [ngClass]="{'has-error':sourceInvalid}">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.data_source' | stringLiterals}}&nbsp;<span class="required-field">*</span>
            </div>
        </div>
        <div class="tam-textbox field-control special-control">
            <div class="control-label body-1">{{'general.select_entity_types_to_include' | stringLiterals}}&nbsp;</div>
            <multi-sel-dropdown-filter class="control-content" [dropdownConfig]='dropdownConfig'>
                <ng-template #multiCustomContent>
                    <div class="selected-content text-ellipsis" (click)='onClickSource($event)'>{{sourceName}}</div>
                </ng-template>
            </multi-sel-dropdown-filter>
        </div>
        <div class="tam-form-help" *ngIf="sourceInvalid">
            {{'general.required_error_message' | stringLiterals}}
        </div>
    </div>
    <div class="tam-form-field one-field">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.default_value' | stringLiterals}}
            </div>
        </div>
        <kendo-multiselect #staticDefaultValuekendo class="tam-textbox field-control"
            [popupSettings]="{appendTo:'component'}" [data]="staticDefaultValueDataSource|async" textField="shortName"
            valueField="id" [loading]="staticDefaultValueLoading" [listHeight]="210" [filterable]="true"
            [disabled]="config.source.length===0" [(ngModel)]="config.staticDefaultValue"
            (valueChange)="valueChangeStaticDefaultValue($event)" (focus)="focusStaticDefaultValue()"
            (filterChange)="filterStaticDefaultValue($event)" (blur)="blurStaticDefaultValue()">
            <ng-template kendoComboBoxItemTemplate let-dataItem>
                <tam-entity-dropdown-item [entity]="dataItem"></tam-entity-dropdown-item>
            </ng-template>
        </kendo-multiselect>
        <label class="field-control" tam-checkbox [(ngModel)]="config.isTriggerEntityAsDefaultEnabled">
            {{'general.use_trigger_entity_default' | stringLiterals}}
        </label>
        <label class="field-control" tam-checkbox [(ngModel)]="config.isCurrentUserAsDefaultEnabled">
            {{'general.use_current_user_default' | stringLiterals}}
        </label>
    </div>
</div>