/**
 * Created by Alan Yang on 7/31/17.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../redux/app.state';
import { GET_ENTITY, GET_TEMPLATE_CONFIG, CLEAN_TEMPLATE_CONFIG, GET_SLIDESHEET_ENTITY, GET_SLIDESHEET_TEMPLATE_CONFIG } from '../redux/app.actions';
import { CLEAR_ENTITY_MSG, CREATE_ENTITY, CREATE_EXTRAOROFFICE, CREATE_MEMBER, EDIT_ENTITY } from '../redux/entity.actions';
import { CLEAN_ENTITY_TEMPLATE_CONFIGURATION } from '../redux/template.actions';

@Injectable()
export class EntityViewModel {
    constructor(private _store: Store<IAppState>) { }

    createEntity(params) {
        if (params) {
            this._store.dispatch({
                type: CREATE_ENTITY,
                payload: {
                    params: params
                }
            });
        }
    }
    editEntity(params) {
        if (params) {
            this._store.dispatch({
                type: EDIT_ENTITY,
                payload: {
                    params: params
                }
            });
        }
    }
    createExtraOrOfficer(params) {
        if (params) {
            this._store.dispatch({
                type: CREATE_EXTRAOROFFICE,
                payload: {
                    params: params
                }
            });
        }
    }

    createMembers(params) {
        if (params) {
            this._store.dispatch({
                type: CREATE_MEMBER,
                payload: {
                    params: params
                }
            });
        }
    }

    getEntity(id: string = '') {
        if (id.length > 0) {
            this._store.dispatch({
                type: GET_ENTITY,
                payload: {
                    id: id
                }
            });
        }
    }

    getSlideSheetEntity(id: string = '') {
        if (id.length > 0) {
            this._store.dispatch({
                type: GET_SLIDESHEET_ENTITY,
                payload: {
                    id: id
                }
            });
        }
    }

    getTemplateConfig(entityTypeId: string = '') {
        if (entityTypeId.length > 0) {
            this._store.dispatch({
                type: GET_TEMPLATE_CONFIG,
                payload: {
                    entityTypeId: entityTypeId
                }
            });
        }
    }

    getSlideSheetTemplateConfig(entityTypeId: string = '') {
        if (entityTypeId.length > 0) {
            this._store.dispatch({
                type: GET_SLIDESHEET_TEMPLATE_CONFIG,
                payload: {
                    entityTypeId: entityTypeId
                }
            });
        }
    }

    clearTemplateConfig() {
        this._store.dispatch({
            type: CLEAN_TEMPLATE_CONFIG
        });
    }

    clearEntityTemplate() {
        this.clearEntityMsg();
        this.clearEntiyConfiguration();
    }

    clearEntityMsg() {
        this._store.dispatch({
            type: CLEAR_ENTITY_MSG,
        });
    }

    clearEntiyConfiguration() {
        this._store.dispatch({
            type: CLEAN_ENTITY_TEMPLATE_CONFIGURATION,
        });
    }
}
