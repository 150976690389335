/**
 * Created by Abner Sui on 01/23/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from '../../widgets/widgets.module';
import { PopoverComponent } from './popover.component';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
    ],
    declarations: [
        PopoverComponent,
    ],
    exports: [
        PopoverComponent,
    ],
    providers: []
})
export class PopoverModule {
}
