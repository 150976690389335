/**
 * Created by JUNO PENG 1/5/2024.
 * Description:
 * portfolio model
 *
 * ------ maintenance history ------
 */

export class PortfolioUploadModel {
    configName = '';
    ignoresList = '';
    targetEntity = { id: '', name: '', shortName: '', };
    startRowNum = 1;
    entityMatching = 'shortname';
    portfolioFileName = '';
    relationshipTypeID: string;
    errorContactID: string;
    createReciprocals = false;
    replaceExistingRelationships = false;
    configID: string;
    delimiter = '\\t';
    errorEntity = { id: '', name: '', shortName: '', };
    entityColumnNum = 1;
    portfolioFileTime: number;
    aliasPrefix = '';
    errorEntityID: string;
    targetEntityID: string;

    static parse(res): PortfolioUploadModel {
        if (!res) {
            return null;
        }
        const result = new PortfolioUploadModel();
        result.configName = res['configName'];
        result.ignoresList = res['ignoresList'];
        if (res['targetEntity'] && res['targetEntity']['targetEntity']) {
            result.targetEntity.id = res['targetEntity']['targetEntity']?.['ID'];
            result.targetEntity.name = res['targetEntity']['targetEntity']?.['name'];
            result.targetEntity.shortName = res['targetEntity']['targetEntity']?.['shortName'];
        }
        result.startRowNum = res['startRowNum'];
        result.entityMatching = res['entityMatching'];
        result.portfolioFileName = res['portfolioFileName'];
        result.relationshipTypeID = res['relationshipTypeID'];
        result.errorContactID = res['errorContactID'];
        result.createReciprocals = res['createReciprocals'];
        result.replaceExistingRelationships = res['replaceExistingRelationships'];
        result.configID = res['configID'];
        result.delimiter = res['delimiter'];
        if (res['errorEntity'] && res['errorEntity']['errorEntity']) {
            result.errorEntity.name = res['errorEntity']['errorEntity']?.['name'];
            result.errorEntity.id = res['errorEntity']['errorEntity']?.['ID'];
            result.errorEntity.shortName = res['errorEntity']['errorEntity']?.['shortName'];
        }
        result.entityColumnNum = res['entityColumnNum'];
        result.portfolioFileTime = res['portfolioFileTime'];
        result.aliasPrefix = res?.['aliasPrefix'];
        result.errorEntityID = res['errorEntityID'];
        result.targetEntityID = res['targetEntityID'];
        return result;
    }
}

export enum EntityFilterType {
    Related = 'related',
    Target = 'target'
}
