import { combineReducers } from '@ngrx/store';
import { AppState } from '..';
import { systemConfigReducer } from './global-config.reducer';

export const getSystemConfigState = (state: AppState) => state.systemConfig;

const systemConfigViewReducer = combineReducers({
    systemConfig: systemConfigReducer,
});

export function getSystemConfigRedcuer(state, action) {
    return systemConfigViewReducer(state, action);
}
