/**
 * Created by Marcus Zhao
 * Description: ExchangeContactModel
 * ------ maintenance history ------
 * Updated by Daniel Wang on 4/21/2022, Added models for merging contacts manually during import.
 * UPdated by Daniel Wang on 09/01/202, Added sync type for contact which need to support one-way connnect.
*/

import { TSGuid } from './ts-guid.model';

export class Address {
    city: string;
    country: string;
    state: string;
    street: string;
    street2: string;
    zip: string;


    static parse(res) {
        if (!res) {
            return null;
        }

        const data: Address = new Address();
        data.city = res.city;
        data.country = res.country;
        data.state = res.state;
        data.street = res.street;
        data.street2 = res.street2;
        data.zip = res.zip;

        return data;
    }
}

export class AddressOtherProperty {
    geocodeSearched: boolean;
    callGoogle: boolean;
    entityID: string;
    latitude: number;
    longitude: number;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data: AddressOtherProperty = new AddressOtherProperty();
        data.geocodeSearched = res.geocodeSearched;
        data.callGoogle = res.callGoogle;
        data.entityID = res.entityID;
        data.latitude = res.latitude;
        data.longitude = res.longitude;

        return data;
    }
}

export class Attribute {
    prefix: string;
    suffix: string;
    middleName: string;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data = new Attribute();
        data.prefix = res.prefix;
        data.middleName = res.middleName;
        data.suffix = res.suffix;
    }
}

export class Company {
    id: string;
    name: string;

    constructor(_id: string, _name: string) {
        this.id = _id;
        this.name = _name;
    }

    static parse(res) {
        if (!res) {
            return null;
        }

        const data = new Company(res.id, res.name);
        return data;
    }
}

export class ExchangeDataContainer {
    exchange: ExchangeModel; // to save exchange information
    duplicates: Array<DuplicateItem>; // duplicate items
    sames: Array<SameItem>; // no-duplicate itemss
    isMergedAll: boolean; // UI behavior, user select merge all for the duplicate items
    tamaleId: string; // tamale id
    tamaleModifyDate: number; // tamale modify date
    exchangeModifyDate: number; // exchange modify date
    syncType ? = 2;

    constructor(_exchange: ExchangeModel, _tamaleId: string, _duplicates: Array<DuplicateItem>, _sames: Array<SameItem>, _isMergedAll: boolean = false, _tamaleModifyDate: number = 0, _exchangeModifyDate: number = 0, _syncType: number = 2) {
        this.exchange = _exchange;
        this.tamaleId = _tamaleId;
        this.duplicates = _duplicates;
        this.sames = _sames;
        this.isMergedAll = _isMergedAll;
        this.tamaleModifyDate = _tamaleModifyDate;
        this.exchangeModifyDate = _exchangeModifyDate;
        this.syncType = _syncType;
    }
}

export class ExchangeReturnData {
    contacts: Array<ExchangeContacts>;
    exchangeUserId: string;
    tamaleUserId: string;
}

/**
 * The model used to show the duplicate item in UI, and set the status
 */
export class DuplicateItem {
    id: string;
    duplicateName: string;
    duplicateNameShowOnUI: string;
    tamaleValue: Array<IntegratedValue>;
    exchangeValue: Array<IntegratedValue>;
    orderBy: string; // used to handle the order of the duplicate items
    isMergedItem: boolean; // whether the duplicate item is merged or not.
    isTamaleValueSelected: boolean; // whether the tamale value is selected if the items aren't merged
    isExchangeValueSelected: boolean; // wehter the exchange value is selected if the items aren't merged
    isUseMostRecentData: boolean; // whether we use most recent data when merging the items

    constructor(_duplicateName: string, _duplicateNameShowOnUI: string = '', _tamaleValue: Array<IntegratedValue>, _exchangeValue: Array<IntegratedValue>, _orderBy: string = 'a', _isMergedItem = false, _isTamaleValueSelected = false, _isExchangeValueSelected = false, _isUseMostRecentData = false) {
        this.id = new TSGuid().toString();
        this.duplicateName = _duplicateName;
        this.duplicateNameShowOnUI = _duplicateNameShowOnUI;
        this.tamaleValue = _tamaleValue;
        this.exchangeValue = _exchangeValue;
        this.orderBy = _orderBy;
        this.isMergedItem = _isMergedItem;
        this.isTamaleValueSelected = _isTamaleValueSelected;
        this.isExchangeValueSelected = _isExchangeValueSelected;
        this.isUseMostRecentData = _isUseMostRecentData;
    }
}

/**
 * The model used to integrate the properties
 */
export class IntegratedValue {
    id: string;
    name: string;
    value: string | Address;
    primary: boolean;

    constructor(_id: string, _name: string, _value: string | Address = '', _primary: boolean = false) {
        this.id = _id;
        this.name = _name;
        this.value = _value;
        this.primary = _primary;
    }
}

export class SameItem {
    name: string;
    value: any;

    constructor(_name: string, _value: any) {
        this.name = _name;
        this.value = _value;
    }
}

export class Email {
    address: string;
    name: string;
    primary: boolean;
    valid: boolean;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data: Email = new Email();
        data.address = res.address;
        data.name = res.name;
        data.primary = res.primary;
        data.valid = res.valid;

        return data;
    }
}

export class ExchangeContact {
    webCompany: Company;
    webEmail: Array<any>;
    firstName: string;
    id: string;
    webJobTitle: Array<JobTitle>;
    lastName: string;
    ownerID?: string;
    ownerName?: string;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data: ExchangeContact = new ExchangeContact();
        data.webCompany = res['webCompany'];
        data.webEmail = res['webEmail'];
        data.firstName = res['firstName'];
        data.id = res.id;
        data.webJobTitle = res['webJobTitle'];
        data.lastName = res['lastName'];
        if (res['ownerID']) {
            data.ownerID = res['ownerID'];
        }
        if (res['ownerName']) {
            data.ownerName = res['ownerName'];
        }

        return data;
    }
}

export class JobTitle {
    id: string;
    name: string;

    static parse(res): JobTitle {
        if (!res) {
            return null;
        }

        const data = new JobTitle();
        data.id = res.id;
        data.name = res.name;

        return data;
    }
}

export class WebAddress {
    id: string;
    address: Address;
    addressOtherProperty: AddressOtherProperty;
    name: string;
    vaild: boolean;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data = new WebAddress();
        data.id = new TSGuid().toString();
        data.address = Address.parse(res.address);
        data.addressOtherProperty = AddressOtherProperty.parse(res.address);
        data.name = res.name;
        data.vaild = res.vaild;
        return data;
    }
}

export class Phone {
    name: string;
    phone: string;
    valid: boolean;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data = new Phone();
        data.name = res.name;
        data.phone = res.phone;
        data.valid = res.valid;

        return data;
    }
}

export class SocialMedia {
    name: string;
    link: string;
    valid: boolean;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data = new SocialMedia();
        data.name = res.name;
        data.link = res.link;
        data.valid = res.valid;

        return data;
    }
}

export class ExchangeContactDetail extends ExchangeContact {
    webAddress: Array<WebAddress>;
    alias: Array<string>;
    attribute: Attribute;
    birthday: string;
    webEmail: Array<Email>;
    notes: string;
    webPhone: Array<Phone>;
    socialMedia: Array<SocialMedia>;
    modifyDate: number;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data: ExchangeContactDetail = new ExchangeContactDetail();
        data.firstName = res['firstName'];
        data.id = res.id;
        data.lastName = res['lastName'];
        const webAddresses = new Array<WebAddress>();
        res.webAddress.forEach(item => {
            if (item) {
                webAddresses.push(WebAddress.parse(item));
            }
        });
        data.webAddress = webAddresses;
        const jobTitles = new Array<JobTitle>();
        res['webJobTitle'].forEach(item => {
            if (item) {
                jobTitles.push(JobTitle.parse(item));
            }
        });
        data.webJobTitle = jobTitles;
        data.webCompany = Company.parse(res['webCompany']);
        data.alias = res.alias;
        data.attribute = res.attribute;
        data.birthday = res.birthday;
        const emails = new Array<Email>();
        res.webEmail.forEach(item => {
            if (item) {
                emails.push(Email.parse(item));
            }
        });
        data.webEmail = emails;
        data.notes = res.notes;
        const phones = new Array<Phone>();
        res.webPhone.forEach(item => {
            if (item) {
                phones.push(Phone.parse(item));
            }
        });
        data.webPhone = phones;
        const socialMedias = new Array<SocialMedia>();
        res.socialMedia.forEach(item => {
            if (item) {
                socialMedias.push(SocialMedia.parse(item));
            }
        });
        data.socialMedia = socialMedias;
        data.modifyDate = res['modifyDate'];

        return data;
    }
}

export class DuplicateContact {
    exchange: ExchangeContactDetail;
    tamale: ExchangeContactDetail;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data = new DuplicateContact();
        data.exchange = ExchangeContactDetail.parse(res.contactFromExchange);
        data.tamale = ExchangeContactDetail.parse(res.contactFromTamale);

        return data;
    }
}

export class ExchangeModel {
    emailAddress: string;
    id: string;
    name: string;

    static parse(res) {
        if (!res) {
            return null;
        }
        const data = new ExchangeModel();
        data.emailAddress = res.emailAddress;
        data.id = res.id;
        data.name = res.name;

        return data;
    }
}

export class ExchangeContacts {
    duplicates: Array<ExchangeContact>;
    exchange: ExchangeModel;
    select: boolean;
    syncType ? = 2;
}

export class ExchangeImportDataModel {
    contacts: Array<ExchangeContacts>;
    exchangeUserId: string;
    tamaleUserId: string;

    static parse(serverResponse) {
        const data: ExchangeImportDataModel = {
            contacts: serverResponse.contacts,
            exchangeUserId: serverResponse.exchangeUserId,
            tamaleUserId: serverResponse.tamaleUserId,
        };
        if (serverResponse.contacts) {
            serverResponse.contacts.forEach(element => {
                element.duplicates.forEach((item, index) => {
                    element.duplicates[index] = ExchangeContact.parse(item);
                });
            });
            data.contacts = serverResponse.contacts;
        }

        return data;
    }
}

export class ExchangeImportBase {
    exchangeUserId: string;
    tamaleUserId: string;

    static parse(serverResponse) {
        if (!serverResponse) {
            return null;
        }

        const data = new ExchangeImportBase();
        data.exchangeUserId = serverResponse.exchangeUserId;
        data.tamaleUserId = serverResponse.tamaleUserId;

        return data;
    }
}

export class MergedContactBase {
    exchange: ExchangeModel;
    select: boolean;
    tamaleId: string;
    syncType ? = 2;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data = new MergedContactBase();
        data.exchange = ExchangeModel.parse(res.exchange);
        data.select = res.select;
        data.tamaleId = res.tamaleId;
        data.syncType = res.syncType;

        return data;
    }
}

export class ExchangeImportInputDataModel extends ExchangeImportBase {
    contacts: Array<MergedContactBase>;

    constructor(_exchangeUserId: string, _tamaleUserId: string, _contacts: Array<MergedContactBase>) {
        super();
        this.exchangeUserId = _exchangeUserId;
        this.tamaleUserId = _tamaleUserId;
        this.contacts = _contacts;
    }
}

export class NeedMergedContact extends MergedContactBase {
    duplicate: DuplicateContact;

    static parse(res) {
        if (!res) {
            return null;
        }

        const data = new NeedMergedContact();
        data.duplicate = DuplicateContact.parse(res.contactComparison);
        data.exchange = ExchangeModel.parse(res.exchange);
        data.select = res.select;
        data.tamaleId = res.tamaleId;
        data.syncType = res.syncType;

        return data;
    }
}

export class ExchangeImportDetailDataModel extends ExchangeImportBase {
    needMergedContacts: Array<NeedMergedContact>;

    static parse(serverResponse) {
        if (!serverResponse) {
            return null;
        }

        const data = new ExchangeImportDetailDataModel();
        data.exchangeUserId = serverResponse.exchangeUserId;
        data.needMergedContacts = new Array<NeedMergedContact>();
        if (serverResponse.contacts) {
            serverResponse.contacts.forEach(item => {
                if (item) {
                    data.needMergedContacts.push(NeedMergedContact.parse(item));
                }
            });
        }
        data.tamaleUserId = serverResponse.tamaleUserId;

        return data;
    }
}

export class ExchangeReturnDataModel extends ExchangeImportBase {
    contacts: Array<NeedReturnContact>;

    constructor(_contacts: Array<NeedReturnContact>, _exchangeUserId: string, _tamaleUserId: string) {
        super();
        this.exchangeUserId = _exchangeUserId;
        this.tamaleUserId = _tamaleUserId;
        this.contacts = _contacts;
    }
}

export class NeedReturnContact extends MergedContactBase {
    tamaleContact: ExchangeContactDetail;

    constructor(_tamaleContact: ExchangeContactDetail, _exchange: ExchangeModel, _tamaleId: string, _select: boolean = true, _syncType: number = 2) {
        super();
        this.select = _select;
        this.syncType = _syncType;
        if (_tamaleContact) {
            this.tamaleContact = _tamaleContact;
        }
        if (_tamaleId) {
            this.tamaleId = _tamaleId;
        }
        this.exchange = _exchange;
    }
}
