/**
 * Created by Alex on 6/06/19.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TransportService } from '../tamalelibs/services/transport.service';
import { AppConfig } from '../tamalelibs/models/app-config.model';
import { businessConstants } from '../constants/business.constants';
import { ResearchViewConstants, DashboardViewConstants, CalendarConstants } from '../tamalelibs/models/smart-reducer.model';

@Injectable()
export class ScreenHelperService {
    open$: Subject<any> = new Subject<any>();
    saveScreen$: Subject<any> = new Subject<any>();
    constructor(
        private _transportService: TransportService,
    ) { }

    public getEntityScreens(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.entityScreenEndpoint}${id}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    // get add screen type by screen type
    public getAddScreenType(screenType) {
        let addScreenType = ResearchViewConstants.AddActionTypeName;
        switch (screenType) {
            case businessConstants.screenType.dashboard:
                addScreenType = DashboardViewConstants.AddActionTypeName;
                break;
            case businessConstants.screenType.research:
                addScreenType = ResearchViewConstants.AddActionTypeName;
                break;
            case businessConstants.screenType.event:
                addScreenType = CalendarConstants.AddActionTypeName;
                break;
            default:
                addScreenType = ResearchViewConstants.AddActionTypeName;
                break;
        }
        return addScreenType;
    }

    // get delete screen type by screen type
    public getDeleteScreenType(screenType) {
        let deleteScreenType = ResearchViewConstants.DeleteActionTypeName;
        switch (screenType) {
            case businessConstants.screenType.dashboard:
                deleteScreenType = DashboardViewConstants.DeleteActionTypeName;
                break;
            case businessConstants.screenType.research:
                deleteScreenType = ResearchViewConstants.DeleteActionTypeName;
                break;
            case businessConstants.screenType.event:
                deleteScreenType = CalendarConstants.DeleteActionTypeName;
                break;
            default:
                deleteScreenType = ResearchViewConstants.DeleteActionTypeName;
                break;
        }
        return deleteScreenType;
    }
}

