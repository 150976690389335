/**
 * Created by Yoyo Fang on 04/18/19.
 * Description:
 *
 * ------ maintenance history ------
 * Marcus Zhao 07/1/2022 add totalCount for contact list;
 */

import { RELOAD_CONTACTS_SUCCESS, RELOAD_CONTACTS_FAIL, DELETE_CONTACT_SUCCESS, DELETE_CONTACT_FAIL } from '../../tamalelibs/redux/contact-list.actions';
import { ContactList } from '../../tamalelibs/models/entity-list.model';
import { CHANGE_CONTACT_DETAIL, CONTACT_STATUS_EDIT } from '../../tamalelibs/redux/app.actions';
import { Contact } from '../../tamalelibs/models/contact.model';

export function contactListReducer(state: any = null, action) {
    switch (action.type) {
        case RELOAD_CONTACTS_SUCCESS:
            if (action.params.workflow !== 'scroll') {
                return {
                    contactList: action.payload,
                    params: action.params
                };
            } else {
                const newContactList = new ContactList();
                newContactList.id = action.payload.id;
                newContactList.contacts = state.contactList.contacts.concat(action.payload.contacts);
                newContactList.next = action.payload.next;
                // set total count when scroll down contact list.
                newContactList.totalCount = action.payload.totalCount;
                return {
                    contactList: newContactList,
                    params: action.params
                };
            }
        case RELOAD_CONTACTS_FAIL:
            return null;
        case CHANGE_CONTACT_DETAIL:
            const contact: Contact = action.payload;
            if (state && state.contactList && state.contactList.contacts) {
                state.contactList.contacts.forEach((item, index) => {
                    if (item.id === contact.id) {
                        state.contactList.contacts[index] = contact;
                    }
                });
            }
            return Object.assign([], state);
        case DELETE_CONTACT_SUCCESS:
            const newData = Object.assign({}, state);
            const deletedContactId = action.payload.id;
            const newContacts = newData?.contactList?.contacts.filter(item => item.id !== deletedContactId);
            newData.contactList.contacts = newContacts;
            return newData;
        case DELETE_CONTACT_FAIL:
            return state;
        default:
            return state;
    }
}
