import { Subject } from 'rxjs';
import { ResearchFocusStatus, FinderSetting } from '../../components/finder/finder.view-model';
import { EntityBrief } from '../../tamalelibs/models/entity-brief.model';
import { FiltersConfig } from '../../tamalelibs/components/filter-config';
import { ResearchLayoutMode } from '../../components/research-layout/research-layout.data';
import { ALL_VALUE } from '../../tamalelibs/constants/business.constants';
import { ContextMenuItem } from '../../widgets/tam-context-menu/tam-context-menu.model';
import { AppConfig } from '../../tamalelibs/models/app-config.model';
import { ShowAsMode } from '../../components/display/display.model';

export class QueryInfo {
    searchText: string;
    entityIds: Array<string>;
    advFilterMap: Map<string, string>;
    sortBy: string;
    sortOrder: string;
    category: Array<string>;
    categoryFilter: Object;
    showCalendarEvents: boolean;

    constructor() {
        this.searchText = '';
        this.entityIds = [];
        this.advFilterMap = new Map();
        this.advFilterMap.set('default', AppConfig.defaultAdvFilter);
        this.sortBy = 'displayDate';
        this.sortOrder = 'desc';
        this.category = [];
        this.categoryFilter = null;
        this.showCalendarEvents = false;
    }
}

export class ThreadListConfig {
    loadMoreAction: string;
    reloadAction: string;
    reloadGroupAction: string;
    setGridStatusAction: string;
    setSelectedIndexAction: string;
    setQueryInfoAction: string;
    clearThreadListAction: string;
    setGridRowModelTypeAction: string;
    clearGroupByNoteItemsAction: string;

    gridInfoSelector: any;
    queryInfoSelector: any;
    sortInfoSelector: any;
    threadListSelector: any;
    threadListItemSelector: any;
    threadListGroupItemSelector: any;
    threadListGroupItemsSelector: any;
    threadListGridGroupLayoutSelector: any;

    actions$: Subject<any>;
    events$: Subject<any>;
    keyInStore?: string; // use dynamic store if exist
    showAsMode?: ShowAsMode;

    constructor() {
        this.actions$ = new Subject();
        this.events$ = new Subject();
        this.showAsMode = ShowAsMode.NOTES;
    }
}

export class GridColumnInfo {
    colId: string;
    hide: boolean;
    width: number;

    constructor() {
        this.colId = '';
        this.hide = false;
        this.width = 50;
    }
}

export class FilterInfo {
    filterConfigs: Array<FiltersConfig>;
    isFilterOn: boolean;
    filterLogic: string;

    constructor() {
        this.filterConfigs = [];
        this.isFilterOn = false;
        this.filterLogic = ALL_VALUE;
    }
}

export class FinderInfo {
    entityId: string;
    status: ResearchFocusStatus;
}

export class RefinebyInfo {
    category: Array<string>;

    constructor() {
        this.category = [];
    }
}

export class SortInfo {
    sortBy: string;
    sortOrder: 'asc' | 'desc';

    constructor() {
        this.sortBy = 'displayDate';
        this.sortOrder = 'desc';
    }
}

export class DisplayInfo {
    showAsMode: ShowAsMode;
    showCalendarEvents: boolean;

    constructor() {
        this.showAsMode = ShowAsMode.NOTES;
        this.showCalendarEvents = false;
    }
}

export class RelationshipTreeInfo {
    entity?: EntityBrief;
    checkedNodeKeys?: string[];
    expandedNodeKeys?: string[];
    showAs: 'name' | 'shortName';
}

export class ResearchLayoutInfo {
    // layoutMode: ResearchLayoutMode;
    // collapsed: boolean;
    // isEntityFocusHidden: boolean;
    // isRelationshipTreeHidden: boolean;
    activeTab: string;
    constructor(
        public layoutMode = ResearchLayoutMode.SNIPPET,
        public isEntityFocusHidden = false,
        public isRelationshipTreeHidden = false,
        public isEntityFocusDisable = false,
        public isRelationshipTreeDisable = false,
        public collapsed = false) {
    }
}

export class ResearchRefinebyConfig {
    actions$: Subject<any>;
    events$: Subject<any>;
    contextMenuItems: ContextMenuItem[];
    keyInStore?: string; // use dynamic store if exist
    showAsName = false;

    getResearchViewRefinebyDataStateSelector: any;
    getResearchViewRefinebyCategoryStateSelector: any;
    getResearchViewRefinebySelectedValueStateSelector: any;
    getResearchViewRefinebyStatusStateSelector: any;
    getResearchViewRefinebyCategoryFilterStateSelector: any;

    researchRefinebySetCategoryAction: string;
    researchRefinebyLoadAction: string;
    researchRefinebySetSelectedValueAction: string;
    researchRefinebySetCategoryStatusAction: string;
    researchRefinebySetCategoryOrderAction: string;

    constructor() {
        this.actions$ = new Subject();
        this.events$ = new Subject();
    }
}

export class ExpandGroupItem {
    key: string;
    id: string;
    parent: ExpandGroupItem;
}

export class GroupSortColumn {
    sortBy: string;
    sortOrder: string;
}

export class GridGroupLayout {
    rowModelType: string;
    expandGroups: ExpandGroupItem[];
    groupSortOrder: GroupSortColumn[];
}

export class ResearchViewInfo {
    keywordInfo?: string;
    finderInfo: FinderInfo;
    sortInfo: SortInfo;
    relationshipTreeInfo: RelationshipTreeInfo;
    filterInfo: FilterInfo;
    refineByInfo: RefinebyInfo;
    layoutInfo: ResearchLayoutInfo;
    gridColumnInfo: Array<GridColumnInfo>;
    displayInfo: DisplayInfo;
    gridGroupLayout?: GridGroupLayout;
    version: '3.0';

    constructor() {
        this.sortInfo = new SortInfo();
        this.filterInfo = new FilterInfo();
        this.layoutInfo = new ResearchLayoutInfo();
        this.refineByInfo = new RefinebyInfo();
        this.gridColumnInfo = [];
        this.displayInfo = new DisplayInfo();
    }
}

export class ResearchScreenInitializeFilter {
    constructor(public enable: boolean = false, public logic: string = '', public list: any[] = []) { }
}

export class ResearchScreenInitializationPayload {
    constructor(
        public page: any,
        public user: any
    ) { }
}

export class ResearchScreenInternalPayload {
    constructor(
        public screenConfig: ResearchViewInfo,
        public page: any,
        public user: any,
        public finder: any
    ) { }
}

export class ResearchScreenInitOthersPayload {
    constructor(
        public layout: ResearchLayoutInfo,
        public sort: SortInfo,
        public keyword: string = '',
        public gridStatus: any = '',
        public refineBy: RefinebyInfo = null,
        public display: DisplayInfo,
        public gridGroupLayout?: GridGroupLayout
    ) { }
}

export class ResearchScreenInitFocusRelationshipPayload {
    constructor(
        public focus: any,
        public relationship: any
    ) { }
}
