import { OnInit, ElementRef, Renderer2, ContentChild, TemplateRef, Input, Component, Output, EventEmitter, OnDestroy, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FloatMenuService } from '../../services/float-menu.service';
import { BriefConfig } from '../widgets.model';
import { DialogAction } from './tam-dialog.model';

@Component({
    selector: 'tam-dialog',
    templateUrl: './tam-dialog.component.html',
    styleUrls: ['./tam-dialog.component.scss'],
})
export class TamDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() contentHeight;
    @Input() closable;
    @Input() config?: BriefConfig;
    @Input() dialogWidth;
    @Input() opened;
    @Input() titleName;

    @ContentChild('body', { static: false }) body: TemplateRef<any>;
    @ContentChild('footer', { static: false }) footer: TemplateRef<any>;
    @ContentChild('title', { static: false }) title: TemplateRef<any>;
    @ViewChild('viewContainer', { static: false, read: ViewContainerRef }) viewContainer: ViewContainerRef;

    @Output() closeminDialog = new EventEmitter<boolean>();
    @Output() containerEvent = new EventEmitter<ViewContainerRef>();

    minimized = false;

    private _el: HTMLElement;
    private _onlyShowFloatMenu: boolean;
    private _draftCount: number;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _element: ElementRef,
        private renderer: Renderer2,
        public _floatMenuService: FloatMenuService,
    ) {
        this._el = this._element.nativeElement;
    }

    ngOnInit() {
        if (this._floatMenuService.floatMenuSubject$) {
            this._destroySubscriptions.push(
                this._floatMenuService.floatMenuSubject$.subscribe(
                    (subjectData: any) => {
                        this._onlyShowFloatMenu = subjectData.onlyShowFloatMenu;
                        this._draftCount = subjectData.draftCount;

                        this._changePostion();
                    }
                ),
            );
        }
        if (this.config) {
            this._destroySubscriptions.push(
                this.config.actionSubject$.subscribe(action => {
                    if (action.type === DialogAction.MINIMIZE) {
                        this.renderer.setStyle(this._el.querySelector('.minimize-interface'), 'visibility', 'visible');
                        this.minimized = true;
                    } else if (action.type === DialogAction.MAXIMIZE) {
                        this.maxmize();
                    }
                }),
            );
        }
    }

    ngAfterViewInit() {
        this.containerEvent.emit(this.viewContainer);
    }

    public ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    /**
       * dialog maxmize
       */
    public maxmize() {
        this.renderer.setStyle(this._el.querySelector('.minimize-interface'), 'visibility', 'hidden');
        this.minimized = false;
    }
    /**
    * click the  minimize window close button.
    */
    public minimizeClose($event) {
        if ($event) {
            $event.stopPropagation();
            this.closeminDialog.emit();
        }
        this.renderer.setStyle(this._el.querySelector('.minimize-interface'), 'visibility', 'hidden');
        this.minimized = false;
    }

    /**
     *  the distance between float menu and minimized new note panel should be 16px
     *  it will get the draft count and the information of showing float menu or float menu with draft list to adjust the position
     */
    private _changePostion() {
        if (this._onlyShowFloatMenu) {
            if (this._draftCount === 0) {
                this.renderer.setStyle(this._el.querySelector('.minimize-interface'), 'right', '164px');
            } else if (this._draftCount > 0) {
                this.renderer.setStyle(this._el.querySelector('.minimize-interface'), 'right', '256px');
            }
        } else {
            this.renderer.setStyle(this._el.querySelector('.minimize-interface'), 'right', '426px');
        }
    }

}
