import { NoteTileDisplayStrategy } from '../../../../../tamalelibs/models/tile.model';
import { ThreadsDisplayOrders } from '../../../../../tamalelibs/models/thread-display-settings.model';

export const DEFAULT_SETTINGS_DATA = {
    threadsCount: 1,
    threadsDisplayModes: NoteTileDisplayStrategy.ParentNote,
    threadsShowFullThread: false,
    threadsOrder: ThreadsDisplayOrders.DISPLAY_DATE
};

export const PROPERTY_FIELD_MAP: Map<string, string> = new Map<string, string>([
    ['entities', ''],
    ['threadAttachments', ''],
    ['entryAttachments', ''],
    ['subject', 'entry__title'],
    ['source', 'entry__source_id'],
    ['noteType', 'entry__type_name'],
    ['displayDate', 'entry__display_date'],
    ['submitter', 'entry__submitter_id'],
    ['submittedDate', 'entry__submitted_date'],
    ['sentiment', 'entry__sentiment'],
    ['priority', 'entry__priority'],
    ['adhocTable', ''],
    ['blurb', 'entry__note_body'],
    ['noteBody', 'entry__id'],
]);

export const PAGE_LAYOUT_SETTINGS = [{
    id: 1, cols: 2,
    fields: [{
        id: 1, row: 1, col: 1, width: 1, type: 'dropdown', disabled: false,
        displayName: 'Number of Results', required: true, propertyName: 'threadsCount', options: [
            { display: 1, value: 1 },
            { display: 2, value: 2 },
            { display: 3, value: 3 },
            { display: 4, value: 4 },
            { display: 5, value: 5 },
            { display: 6, value: 6 },
            { display: 7, value: 7 },
            { display: 8, value: 8 },
            { display: 9, value: 9 },
            { display: 10, value: 10 },
            { display: 'All', value: 100000 } // server receive max value, rpp is a required field
        ]
    }, {
        id: 2, row: 2, col: 1, width: 1, type: 'dropdown', disabled: false,
        displayName: 'Parent Note or Latest Sidenote', required: true, propertyName: 'threadsDisplayModes', options: [
            { display: 'Parent Note', value: NoteTileDisplayStrategy.ParentNote },
            { display: 'Latest Sidenote', value: NoteTileDisplayStrategy.LatestSidenote }
        ]
    }, {
        id: 3, row: 2, col: 2, width: 1, type: 'dropdown', disabled: false,
        displayName: 'Show Full Thread', required: true, propertyName: 'threadsShowFullThread', options: [
            { display: 'Yes', value: true },
            { display: 'No', value: false }
        ]
    }, {
        id: 4, row: 3, col: 1, width: 1, type: 'dropdown', disabled: false,
        displayName: 'Sort Order', required: true, propertyName: 'threadsOrder', options: [
            { display: 'Display Date', value: ThreadsDisplayOrders.DISPLAY_DATE },
            { display: 'Submitted Date', value: ThreadsDisplayOrders.SUBMITTED_DATE },
            { display: 'Last Modified Date', value: ThreadsDisplayOrders.LAST_EDITED_DATE }
        ]
    }]
}, {
    id: 2, cols: 2, title: 'DISPLAY SETTINGS',
    fields: [
        {
            id: 5, row: 1, col: 1, width: 1, type: 'checkbox', displayName: 'Entities',
            propertyName: 'entities', value: ''
        }, {
            id: 6, row: 2, col: 1, width: 1, type: 'checkbox', displayName: 'Full Thread Attachments',
            propertyName: 'threadAttachments', value: ''
        }, {
            id: 7, row: 2, col: 2, width: 1, type: 'checkbox', displayName: 'Individual Entry Attachments',
            propertyName: 'entryAttachments', value: ''
        }, {
            id: 8, row: 3, col: 1, width: 1, type: 'checkbox', displayName: 'Subject',
            propertyName: 'subject', value: ''
        }, {
            id: 9, row: 4, col: 1, width: 1, type: 'checkbox', displayName: 'Source',
            propertyName: 'source', value: ''
        }, {
            id: 10, row: 5, col: 1, width: 1, type: 'checkbox', displayName: 'Submitter (if different)',
            propertyName: 'submitter', value: ''
        }, {
            id: 11, row: 6, col: 1, width: 1, type: 'checkbox', displayName: 'Note Type',
            propertyName: 'noteType', value: ''
        }, {
            id: 12, row: 7, col: 1, width: 1, type: 'checkbox', displayName: 'Display Date',
            propertyName: 'displayDate', value: ''
        }, {
            id: 13, row: 8, col: 1, width: 1, type: 'checkbox', displayName: 'Submitted Date (if different)',
            propertyName: 'submittedDate', value: ''
        }, {
            id: 14, row: 9, col: 1, width: 1, type: 'checkbox', displayName: 'Sentiment',
            propertyName: 'sentiment', value: ''
        }, {
            id: 15, row: 10, col: 1, width: 1, type: 'checkbox', displayName: 'Priority',
            propertyName: 'priority', value: ''
        }, {
            id: 16, row: 11, col: 1, width: 1, type: 'checkbox', displayName: 'Adhoc Table',
            propertyName: 'adhocTable', value: ''
        }, {
            id: 17, row: 12, col: 1, width: 1, type: 'checkbox', displayName: 'Blurb',
            propertyName: 'blurb', value: ''
        }, {
            id: 18, row: 13, col: 1, width: 1, type: 'checkbox', displayName: 'Note Body',
            propertyName: 'noteBody', value: ''
        }
    ]
}];

export const NOTE_TILE_DEFAULT_FIELDS = ['entity__short_name', 'entity__name', 'entry__source_id', 'entry__submitter_id', 'entry__title', 'entry__last_edited_date', 'entry__submitted_date', 'entry__display_date', 'entry__class'];
