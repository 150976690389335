import { Subject } from 'rxjs';

export enum Status {
    default,
    selected,
    disabled,
    notSet
}

export enum ValidStatus {
    default,
    validPass,
    validFailed,
}

export interface MenuItem {
    id?: string;
    title?: string;
    status?: Status;
    icon: string;
    validStatus?: ValidStatus;
    tid?: string;
}


export class Config {
    active: number;
    menuData: MenuItem[];
    constructor() {
        this.active = 0;
        this.menuData = [];
    }
}


export enum WizardMenuEventType {
    changeSelect
}

export enum WizardMenuActionType {
    changeActive
}


export class WizardMenuConfig {
    config: Config;
    events$: Subject<{ type: WizardMenuEventType, payload?: any }>;
    actions$: Subject<{ type: WizardMenuActionType, payload?: any }>;
    constructor() {
        this.config = new Config();
        this.events$ = new Subject();
        this.actions$ = new Subject();
    }
}
