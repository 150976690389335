/**
 * Created by Abner Sui on 09/29/2019.
 * Description:
 *
 * ------ maintenance history ------
 * 08/11/2021 Macus Zhao update add coordinates type for receive update coordinate status
 * 07/01/2022 Marcus Zhao add an action for bulk operation.
 */

import { Injectable } from '@angular/core';
import { Message, MessageOperateType, MessageType, MessageSubtype, WorkflowMessageType } from '../tamalelibs/models/message.model';
import { take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { Task, ProcessInstance, TaskStatus, TaskType, QuickTask, SubTask } from '../tamalelibs/models/workflow.model';
import { SlideSheetService } from './slide-sheet.service';
import { WorkflowService } from '../tamalelibs/services/workflow.service';
import { Store } from '@ngrx/store';
import { ProcessInstanceAddOrUpdateSuccess } from '../tamalelibs/redux/actions/workflow.actions';
import { WorkflowTaskService } from '../tamalelibs/services/workflow-task.service';
import { WorkflowTaskActionTypes } from '../tamalelibs/redux/actions/workflow-task.actions';
import { SystemUser } from '../tamalelibs/models/user.model';
import { StoreQuerierService } from './store-querier.service';
import { AddOneEntityTemplateConfiguration, AddOneEntryTemplateConfiguration } from '../tamalelibs/redux/template.actions';
import { PullingCoordinatesSuccessAction } from '../tamalelibs/redux/map-tile.actions';
import { ContactInformationItemSubject } from '../components/contact-detail-new/contact-detail-template/contact-information-item/contact-information-item.model';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    messageSubject$: Subject<{ type: MessageOperateType, payload?: any }> = new Subject();

    private _processInstanceIdArray: string[] = [];

    constructor(
        private _slideSheetService: SlideSheetService,
        private _workflowService: WorkflowService,
        private _store: Store<any>,
        private _taskService: WorkflowTaskService,
        private _storeQuerier: StoreQuerierService,
    ) { }

    /**
     * dispatch action [ProcessInstanceAddOrUpdateSuccess]
     * -----------------------------------------------------
     * this action usually would be sent twice for one new process operation, one is from here,
     * and the other one is triggered by the stomp message.
     * Provide the mutex inside this method to avoid such cases.
     *
     * @param {ProcessInstance} instance
     * @memberof MessageService
     */
    dispatchProcessInstanceAction(instance: ProcessInstance) {
        const idIndex = this._processInstanceIdArray.indexOf(instance.id);
        if (idIndex >= 0) {
            // ignore messages matched with anyone in the message id array and then remove it from the array.
            this._processInstanceIdArray.splice(idIndex, 1);
        } else {
            this._processInstanceIdArray.push(instance.id);
            this._store.dispatch(new ProcessInstanceAddOrUpdateSuccess(instance));
        }
    }

    parseMessage(action: any, messages: Array<Message>): void {
        if (action.type) {

            if (action.type === MessageType.TASK || action.type === WorkflowMessageType.TASK_COMPLETION) {
                this._workflowService.getProcessInstanceById(action.payload.detail.processInstanceId).pipe(
                    catchError(err => {
                        return of(null);
                    }),
                    take(1),
                ).subscribe(res => {
                    if (res) {
                        const instance: ProcessInstance = ProcessInstance.parse(res, null, null);
                        const task: Task = instance.task.find(item => item.id === action.payload.objectId);
                        let title = '', header = '';
                        const subTitle = null;
                        title = action.payload.title;
                        if (action.payload.detail.projectName) {
                            title = title + ' of ' + action.payload.detail.projectName;
                        }
                        if (action.payload.detail.type === MessageSubtype.NEW_TASK) {
                            header = 'Task Assignment';
                        } else if (action.payload.detail.type === MessageSubtype.SENDBACK_TASK) {
                            header = 'Task SendBack';
                        } else if (action.payload.detail.type === WorkflowMessageType.TASK_COMPLETION) {
                            title = action.payload.detail.title;
                            header = 'Task Completion';
                        }

                        const oneMessage: Message = {
                            id: action.payload.id ? action.payload.id : task.id,
                            header: header,
                            icon: 'tasks',
                            title: title,
                            subtitle: subTitle,
                            type: MessageType.TASK,
                            receiveDate: Date.now(),
                            date: task.dueDate,
                            data: {
                                task: task,
                                instance: instance,
                                processDefinition: instance.processDefinition,
                            },
                        };
                        const index = messages.findIndex(item => item.id === oneMessage.id);
                        if (index > -1) {
                            messages.splice(index, 1, oneMessage);
                        } else {
                            messages.unshift(oneMessage);
                        }
                        this.dispatchProcessInstanceAction(instance);
                    }
                });
            } else if (action.type === MessageType.QUICK_TASK) {
                this._taskService.getSubTaskById(action.payload.objectId).pipe(
                    catchError(err => {
                        return of(null);
                    }),
                    take(1),
                ).subscribe(resBaseTask => {
                    if (resBaseTask !== null) {
                        this._taskService.getQuickTaskDefinitionById(resBaseTask['task-definition'].id).pipe(
                            catchError(err => {
                                return of(null);
                            }),
                            take(1),
                        ).subscribe(resQuickTask => {
                            if (resQuickTask) {
                                const quickTask = QuickTask.parse(resQuickTask);
                                const subTask = SubTask.parse(resBaseTask);
                                const remindDate = quickTask.remindDate;
                                const date = (remindDate.indexOf(':') <= 0) ? (new Date(remindDate + ' ' + '00:00')) : (new Date(remindDate));
                                const oneMessage: Message = {
                                    id: quickTask.definitionId,
                                    icon: 'tasks',
                                    header: 'Task Reminder',
                                    title: quickTask.subject,
                                    type: MessageType.QUICK_TASK,
                                    receiveDate: Date.now(),
                                    date: date,
                                    data: {
                                        quickTask: quickTask,
                                        subTask: subTask,
                                    },
                                };
                                const index = messages.findIndex(item => item.id === oneMessage.id);
                                if (index > -1) {
                                    messages.splice(index, 1, oneMessage);
                                } else {
                                    messages.unshift(oneMessage);
                                }

                                const _status = 0;
                                const _processor = 1;
                                // set task status for store
                                this._store.dispatch({
                                    type: WorkflowTaskActionTypes.SET_TASK_STATUS,
                                    payload: _status
                                });

                                // set task proecssor for store
                                this._store.dispatch({
                                    type: WorkflowTaskActionTypes.SET_TASK_PROCESSOR,
                                    payload: _processor
                                });

                                this._store.dispatch({
                                    type: WorkflowTaskActionTypes.GET_TASK_BY_STATUS,
                                    payload: {
                                        status: _status,
                                        processor: _processor
                                    }
                                });
                            }
                        });
                    }
                });
            } else if (action.type === MessageType.ENTITY_TEMPLATE) {
                this._store.dispatch(new AddOneEntityTemplateConfiguration({
                    id: action.payload.objectId,
                    template: action.payload.detail,
                }));
            } else if (action.type === MessageType.ENTRY_TEMPLATE) {

                this._store.dispatch(new AddOneEntryTemplateConfiguration({
                    id: action.payload.objectId,
                    template: action.payload.detail,
                }));
            } else if (action.type === MessageType.PULL_GEOCODE) {
                const data = action.payload;
                const oneMessage: Message = {
                    id: data.id,
                    icon: 'coordinate',
                    header: 'Pulling Coordinates',
                    title: '',
                    subtitle: data.detail.msg,
                    type: MessageType.PULL_GEOCODE,
                    receiveDate: Date.now(),
                    date: null
                };
                const index = messages.findIndex(item => item.id === oneMessage.id);
                if (index > -1) {
                    messages.splice(index, 1, oneMessage);
                } else {
                    messages.unshift(oneMessage);
                }
                this._store.dispatch(new PullingCoordinatesSuccessAction());
            } else if (action.type === MessageType.EXCHANGE_IMPORT || action.type === MessageType.EXCHANGE_SYNC) {
                const data = action.payload;
                const tip = action.type === MessageType.EXCHANGE_IMPORT ? 'contact(s) have been imported and connected successfully.' : 'contact(s) have been synced successfully.';
                const oneMessage: Message = {
                    id: '',
                    icon: 'synch',
                    header: ContactInformationItemSubject.ContactSynch,
                    title: '',
                    subtitle: `${data.successNum} ${tip}`,
                    type: action.type,
                    receiveDate: Date.now(),
                    date: null
                };
                const index = messages.findIndex(item => item.id === oneMessage.id);
                if (index > -1) {
                    messages.splice(index, 1, oneMessage);
                } else {
                    messages.unshift(oneMessage);
                }
            } else if (action.type === MessageType.BULK_OPERATOIN) {
                this.messageSubject$.next({
                    type: action.type,
                    payload: action.payload
                });
            }

            if (action.type === MessageType.ENTITY_TEMPLATE || action.type === MessageType.ENTRY_TEMPLATE) {
                this.messageSubject$.next({
                    type: action.type,
                    payload: {
                        id: action.payload.objectId,
                        name: action.payload.title
                    },
                });
            }
            if (action.type === MessageType.APPEND_ENTITIES) {
                this.messageSubject$.next({
                    type: action.type,
                    payload: {
                        id: action.payload.objectId,
                        results: action.payload.detail.results,
                    },
                });
            }
        }
    }

    displayMessageDetail(message: Message, currentUser: SystemUser): void {
        if (message.type === MessageType.TASK) {
            this._slideSheetService.showSlideSheetForTask(null, message.data.task, message.data.instance, message.data.processDefinition, this._taskService.getTaskEditable(message.data.task, currentUser));
        }
    }

}
