import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { LoginFormConfig } from '../../components/login-form/login-form.config';
import { AppState } from '../../redux';
import { AppLoadService } from '../../app-load.service';
import { GlobalService } from '../../tamalelibs/services/global.service';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'tam-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    loginFormConfig: LoginFormConfig;

    constructor(
        private _router: Router,
        private _store: Store<AppState>,
        private _appLoadService: AppLoadService,
        private _globalService: GlobalService,
        private _cookieService: CookieService,
        private _storageService: StorageService,

    ) { }

    ngOnInit() {
        this.loginFormConfig = new LoginFormConfig();
        this.loginFormConfig.eventSubject$.subscribe(() => this.onLoginSuccess());
    }

    ngOnDestroy() {
        this.loginFormConfig.eventSubject$.unsubscribe();
    }

    onLoginSuccess() {
        // if it's redirected from https://server/api or https://server/install.jsp, it should be redirected back after logging in Tamale Web
        let redirect = this._cookieService.get('redirect');
        if (redirect) {
            // Decode the URL in case it's URL-encoded (like https%3A%2F%2F10.2.111.82%2Fapi%2F)
            const decodedRedirectUrl = decodeURIComponent(redirect);
            this._storageService.removeItem('redirect');

            window.location.href = decodedRedirectUrl;
            // Add return here to prevent executing the subsequent logic.
            return;
        }

        this._appLoadService.initSsoSetting().then(data => {
            let returnUrl;
            this._store.select('auth').subscribe(data => {
                returnUrl = data.returnUrl;
            }).unsubscribe();
            if (returnUrl) {
                this._router.navigateByUrl(returnUrl);
            } else {
                this._router.navigate(['/']);
            }
        });
    }
}
