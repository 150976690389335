/**
 * Created by Abner Sui on 02/20/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { RefinebyConfig, RefinebyStatus, RefinebyWidgetAction, RefinebySubConfig, RefinebySubWidgetAction, RefinebyType, RefinebyGroup } from './refineby-view.model';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { ContextMenuConfig, ContextMenuShowAction } from '../tam-context-menu/tam-context-menu.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BriefConfig } from '../widgets.model';
import { RefinebyConfigType } from '../../components/calendar-refineby/calendar-refineby.model';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'refineby',
    templateUrl: './refineby.component.html',
    styleUrls: ['./refineby.component.scss']
})
export class RefinebyComponent implements OnInit, OnDestroy {

    @Input()
    config: RefinebyConfig;
    @ViewChild('keywordInput', { static: false })
    keywordInputEle: ElementRef;
    @ViewChild('keywordForAddInput', { static: false })
    keywordForAddInputEle: ElementRef;

    contextMenuConfig: ContextMenuConfig;
    widgetConfig: BriefConfig;
    displayConfig: Array<RefinebySubConfig>;
    addConfig: Array<RefinebySubConfig>;
    showSearch = false;
    keyword: string;
    sortedSearchResult: Array<RefinebySubConfig> = [];
    searchResult: Map<string, RefinebySubConfig> = new Map();
    searchDone = false;
    widgetConfigForSearch: BriefConfig;
    showAddPopup = false;
    keywordForAdd = '';
    listSearchResult: Array<RefinebySubConfig> = [];
    isIPAD: boolean;
    isAllEvent: boolean;

    private _selectedValue: Map<string, Array<any>> = new Map();
    private _status: Map<string, RefinebyStatus> = new Map();
    private _category: Array<string> = [];
    private _categoryFilter: Object = {};
    private _searchHandler$: Subject<string> = new Subject();
    private _searchListHandler$: Subject<string> = new Subject();
    private _searchedCategoryCount = 0;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _deviceService: DeviceDetectorService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
        this.widgetConfig = new BriefConfig();
        this.widgetConfigForSearch = new BriefConfig();
    }

    ngOnInit() {
        if (this.config.type === RefinebyConfigType.allEvent) {
            this.isAllEvent = true;
        } else {
            this.isAllEvent = false;
        }
        this._initContextMenu();
        this._destroySubscriptions.push(this.widgetConfig.feedbackSubject$.subscribe(event => this._onSubscribeSubEvents(event)));
        this._destroySubscriptions.push(this.widgetConfigForSearch.feedbackSubject$.subscribe(event => this._onSubscribeSubEvents(event)));
        this._destroySubscriptions.push(this.config.actions$.subscribe(action => this._onSubscribeActions(action)));
        this.displayConfig = this.config.displayConfig;
        this.addConfig = this.config.addConfig;
        this._resetListSearch();
        this._category = this.config.category;
        this._categoryFilter = this.config.categoryFilter;
        this._selectedValue = this.config.selectedValue;
        this._destroySubscriptions.push(
            this._searchHandler$.pipe(
                debounceTime(250),
                filter(searchstring => searchstring !== null && searchstring !== undefined)
            ).subscribe(() => this._handleSearch())
        );
        this._destroySubscriptions.push(
            this._searchListHandler$.pipe(
                debounceTime(250),
                filter(searchstring => searchstring !== null && searchstring !== undefined)
            ).subscribe(() => this._handleListSearch())
        );
        // For use within normal web clients
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
    }


    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    @HostListener('document:click', ['$event'])
    clickSomewhere(event) {
        if (this.showAddPopup && !event['refineby']) {
            this.showAddPopup = false;
        }
    }

    @HostListener('document:contextmenu', ['$event'])
    rightClickSomewhere(event) {
        if (this.showAddPopup && !event['refineby']) {
            this.showAddPopup = false;
        }
    }

    emptyClick($event) {
        if ($event.preventDefault) {
            $event.preventDefault();
        }
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
    }

    onAdd(event) {
        this.showAddPopup = !this.showAddPopup;
        if (this.showAddPopup) {
            setTimeout(() => {
                this.keywordForAddInputEle.nativeElement.focus();
            }
            );
        }
        this._resetListSearch();
        event['refineby'] = true;
    }

    onClear() {
        if (this._selectedValue.size > 0) {
            this._selectedValue.clear();
            this._categoryFilter = {};
            this.config.events$.next({
                type: RefinebyWidgetAction.CLEAR_ALL,
            });
        }
        setTimeout(() => {
            if (!this._changeDetectorRef['destroyed']) {
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    onClickListItem(subConfig: RefinebySubConfig) {
        this.showAddPopup = false;
        this._category.push(subConfig.categoryId);
        const index = this.addConfig.findIndex(item => item.categoryId === subConfig.categoryId);
        if (index > -1) {
            this.addConfig.splice(index, 1);
            this._resetListSearch();
        }
        this.config.events$.next({
            type: RefinebyWidgetAction.CHANGE_CATEGORY_ORDER,
            payload: {
                category: this._category
            }
        });
        this.config.events$.next({
            type: RefinebyWidgetAction.RESET_CATEGORY,
            payload: {
                category: this._category
            }
        });
        this.resetCategorySearch();
    }

    onKeyup(event) {
        this._searchHandler$.next(this.keyword);
    }

    onInputContextMenu(event) {
        event['refineby'] = true;
    }

    onSearchClick() {
        this.showSearch = true;
        setTimeout(() => {
            this.keywordInputEle.nativeElement.focus();
            if (!this._changeDetectorRef['destroyed']) {
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    onSearchKeyup(event) {
        this._searchListHandler$.next(this.keywordForAdd);
    }

    resetCategorySearch() {
        this.showSearch = false;
        this.keyword = '';
        setTimeout(() => {
            if (!this._changeDetectorRef['destroyed']) {
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    private _handleListSearch() {
        if (this.keywordForAdd) {
            this.listSearchResult = [];
            const tempStr = this.keywordForAdd.toLowerCase();
            this.addConfig.forEach(subConfig => {
                if (subConfig.name.toLowerCase().includes(tempStr)) {
                    this.listSearchResult.push(subConfig);
                }
            });
            this._handlelistSort();
        } else {
            this._resetListSearch();
        }
    }

    private _handlelistSort() {
        this.listSearchResult.sort((config1, config2) => {
            return config1.defaultIndex - config2.defaultIndex;
        });
        setTimeout(() => {
            if (!this._changeDetectorRef['destroyed']) {
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    private _handleSearch() {
        this.searchDone = false;
        this.searchResult.clear();
        this.sortedSearchResult.splice(0, this.sortedSearchResult.length);
        if (this.keyword) {
            this._searchedCategoryCount = 0;
            this.widgetConfig.actionSubject$.next({
                type: RefinebySubWidgetAction.SEARCH,
                payload: this.keyword,
            });
        }
        setTimeout(() => {
            if (!this._changeDetectorRef['destroyed']) {
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    private _handleSort() {
        this.sortedSearchResult.splice(0, this.sortedSearchResult.length);
        this._category.forEach((categoryId) => {
            if (this.searchResult.has(categoryId)) {
                this.sortedSearchResult.push(this.searchResult.get(categoryId));
            }
        });
        this.searchDone = true;
        setTimeout(() => {
            if (!this._changeDetectorRef['destroyed']) {
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    private _initContextMenu() {
        this.contextMenuConfig = new ContextMenuConfig();
        this.contextMenuConfig.stateful = false;
        this.contextMenuConfig.single = true;
        this.contextMenuConfig.items = [
            { id: '0', text: 'Move Up', disabled: false },
            { id: '1', text: 'Move Down', disabled: false },
            { id: '2', text: 'Move to Top', disabled: false },
            { divider: true },
            { id: '3', text: 'Delete', disabled: false }
        ];
        this._destroySubscriptions.push(this.contextMenuConfig.events$.subscribe(event => this._onMenuSelect(event)));
    }

    private _delete(data) {
        const index = data.index;
        if (index > -1 && this._category.length > 1) {
            this._category.splice(index, 1);
            this.config.events$.next({
                type: RefinebyWidgetAction.CHANGE_CATEGORY_ORDER,
                payload: {
                    category: this._category
                }
            });
            const subConfigs = this.displayConfig.splice(index, 1);
            this.addConfig.push(subConfigs[0]);
            this.addConfig.sort((config1, config2) => {
                return config1.defaultIndex - config2.defaultIndex;
            });
            if (this._status.has(data.categoryId)) {
                this._status.delete(data.categoryId);
                this.config.events$.next({
                    type: RefinebyWidgetAction.STATUS_CHANGE,
                    payload: {
                        status: this._status
                    }
                });
            }
            if (this._selectedValue.has(data.categoryId)) {
                this._selectedValue.delete(data.categoryId);
                delete this._categoryFilter[data.categoryId];
                this.config.events$.next({
                    type: RefinebyWidgetAction.SELECTED_OPTION_CHANGE,
                    payload: {
                        categoryFilter: this._categoryFilter,
                        selectedValue: this._selectedValue
                    }
                });
                this.resetCategorySearch();
            } else {
                setTimeout(() => {
                    if (!this._changeDetectorRef['destroyed']) {
                        this._changeDetectorRef.detectChanges();
                    }
                });
            }
        }
    }

    private _movedown(data) {
        const index = data.index;
        if (index < this._category.length - 1) {
            this._category.splice(index, 1);
            this._category.splice(index + 1, 0, data.categoryId);
            const subConfigs = this.displayConfig.splice(index, 1);
            this.displayConfig.splice(index + 1, 0, subConfigs[0]);
            this.config.events$.next({
                type: RefinebyWidgetAction.CHANGE_CATEGORY_ORDER,
                payload: {
                    category: this._category
                }
            });
            this._handleSort();
        }
    }

    private _movetop(data) {
        const index = data.index;
        if (index > 0) {
            this._category.splice(index, 1);
            this._category.unshift(data.categoryId);
            const subConfigs = this.displayConfig.splice(index, 1);
            this.displayConfig.unshift(subConfigs[0]);
            this.config.events$.next({
                type: RefinebyWidgetAction.CHANGE_CATEGORY_ORDER,
                payload: {
                    category: this._category
                }
            });
            this._handleSort();
        }
    }

    private _moveup(data) {
        const index = data.index;
        if (index > 0) {
            this._category.splice(index, 1);
            this._category.splice(index - 1, 0, data.categoryId);
            const subConfigs = this.displayConfig.splice(index, 1);
            this.displayConfig.splice(index - 1, 0, subConfigs[0]);
            this.config.events$.next({
                type: RefinebyWidgetAction.CHANGE_CATEGORY_ORDER,
                payload: {
                    category: this._category
                }
            });
            this._handleSort();
        }
    }

    private _onMeatball(event) {
        const tempIndex = this._category.indexOf(event.categoryId);
        this.contextMenuConfig.items[0].disabled = tempIndex === 0;
        this.contextMenuConfig.items[1].disabled = tempIndex === this.displayConfig.length - 1;
        this.contextMenuConfig.items[2].disabled = tempIndex === 0;
        this.contextMenuConfig.items[4].disabled = this.displayConfig.length === 1;
        event.index = tempIndex;
        this._changeDetectorRef.detectChanges();
        this.contextMenuConfig.show$.next(new ContextMenuShowAction(event.event, event));
    }

    private _onMenuSelect(event) {
        const type = +event.payload.id;
        const data = event.payload.data;
        switch (type) {
            case 0:
                this._moveup(data);
                break;
            case 1:
                this._movedown(data);
                break;
            case 2:
                this._movetop(data);
                break;
            case 3:
                this._delete(data);
                break;
            default:
                break;
        }
    }

    private _onSubscribeActions(action) {
        if (action.type === RefinebyWidgetAction.SHOW_AS_NAME) {
            if (this.displayConfig.findIndex(item => item.type === RefinebyType.entity) > -1) {
                this.displayConfig.forEach(subConfig => {
                    if (subConfig.type === RefinebyType.entity) {
                        subConfig.status.showAsName = action.payload;
                        this._status.set(subConfig.categoryId, subConfig.status);
                    }
                });
                this.config.events$.next({
                    type: RefinebyWidgetAction.STATUS_CHANGE,
                    payload: {
                        status: this._status
                    }
                });
                this.widgetConfig.actionSubject$.next({
                    type: RefinebySubWidgetAction.CHANGE_STATUS,
                });
                this.widgetConfigForSearch.actionSubject$.next({
                    type: RefinebySubWidgetAction.CHANGE_STATUS,
                });
            }
        } else if (action.type === RefinebyWidgetAction.HIDE_ADD_LIST) {
            if (this.showAddPopup) {
                this.showAddPopup = false;
            }
        } else if (action.type === RefinebyWidgetAction.SET_STATUS) {
            this._status = action.payload;
        }
    }

    private _onSubscribeSubEvents(event) {
        const data = event.payload;
        if (event.type === RefinebySubWidgetAction.SELECTED_OPTION_CHANGE) {
            if (data.selectedOptions.length > 0) {
                this._selectedValue.set(data.categoryId, data.selectedOptions);
            } else {
                this._selectedValue.delete(data.categoryId);
            }
            if (data.categoryFilter && data.categoryFilter.length > 0) {
                this._categoryFilter[data.categoryId] = data.categoryFilter;
            } else {
                delete this._categoryFilter[data.categoryId];
            }
            this.config.events$.next({
                type: RefinebyWidgetAction.SELECTED_OPTION_CHANGE,
                payload: {
                    categoryFilter: this._categoryFilter,
                    selectedValue: this._selectedValue
                }
            });
            this.resetCategorySearch();
        } else if (event.type === RefinebySubWidgetAction.STATUS_CHANGE) {
            this._status.set(data.categoryId, data.status);
            this.config.events$.next({
                type: RefinebyWidgetAction.STATUS_CHANGE,
                payload: {
                    status: this._status
                }
            });
        } else if (event.type === RefinebySubWidgetAction.SEARCH_RESULT) {
            const categoryId: string = event.payload.categoryId;
            const result: Array<any> = event.payload.searchResult;
            if ((result && result.length > 0) || this._selectedValue.has(categoryId)) {
                const preConfig: RefinebySubConfig = this.displayConfig.find(config => config.categoryId === categoryId);
                const newConfig = new RefinebySubConfig(categoryId, preConfig.name, preConfig.group, preConfig.type);
                newConfig.status = preConfig.status;
                newConfig.selectedOptions = preConfig.selectedOptions;
                newConfig.isSearching = true;
                newConfig.options.push(...result);
                this.searchResult.set(categoryId, newConfig);
            }
            this._searchedCategoryCount++;
            if (this._searchedCategoryCount === this._category.length) {
                this._handleSort();
            }
        } else if (event.type === RefinebySubWidgetAction.CLICK_MEATBALL) {
            this._onMeatball(event.payload);
        }
    }

    private _resetListSearch() {
        this.keywordForAdd = '';
        this.listSearchResult = [];
        const tempLength = this.addConfig.length;
        for (let i = 0; i < tempLength; i++) {
            if (this.addConfig[i].group === RefinebyGroup.standard) {
                this.listSearchResult.push(this.addConfig[i]);
            } else {
                break;
            }
        }
        this._handlelistSort();
    }
}

