import { Subject } from 'rxjs';
import { EntityBrief } from '../../tamalelibs/models/entity-brief.model';

export class FinderConfig {
    initState: FinderSetting;
    actions$: Subject<any>;
    events$: Subject<any>;

    constructor() {
        this.actions$ = new Subject();
        this.events$ = new Subject();
    }
}

export enum FinderActions {
    SET_VALUE = 'SET_VALUE',
    SET_VALUE_STATUS = 'SET_VALUE_STATUS',
    FOCUS_FINDER = 'FOCUS_FINDER',
}

export class FinderSetting {
    entity: EntityBrief;
    status: ResearchFocusStatus;
}

export enum ResearchFocusStatus {
    NONE = 'none',
    ONE_DEGREE = 'oneDegree',
    ALL = 'all',
}
