/**
 * Created by Daniel Wang on 5/11/2023
 * ------ maintenance history ------
 */

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { User } from '../../../../tamalelibs/models/user.model';
import { FieldType } from '../../../../tamalelibs/models/workflow.model';
import { ArrayHelperService } from '../../../../tamalelibs/services/array-helper.service';
import { UsersService } from '../../../../tamalelibs/services/users.service';
import { ControlConfigEvents, IControlConfigComponent } from '../../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from '../field.model';

@Component({
    selector: 'tam-single-user-dropdown-config',
    templateUrl: './single-user-dropdown-config.component.html',
    styleUrls: ['../field-config.component.scss']
})
export class SingleUserDropdownConfigComponent implements OnInit, AfterViewInit, OnDestroy, IControlConfigComponent {
    // #region properties
    // #region public properties
    @Input() config: FieldConfig;

    @ViewChild('base', { static: false }) base;
    @ViewChild('staticDefaultValueKendo', { static: false }) staticDefaultValueKendo;
    /**
     * the attribute name of the config object, which indicates how to set the default value for current user dropdown.
     */
    dataType = FieldType.User;
    staticDefaultValueDataSource: BehaviorSubject<Array<User>> = new BehaviorSubject<Array<User>>([]);
    sourceInvalid = false;
    // #endregion

    // #region private properties
    private _sourceData: Array<User>;
    private _filterHandlerStaticDefaultValue$: Subject<string> = new Subject();
    private _focusedStaticDefaultValue = false;
    private _destroySubscriptions: Array<Subscription> = [];
    // #endregion

    // #region constructor
    constructor(
        private _usersService: UsersService,
    ) { }
    // #endregion

    // #region ngOnInit
    ngOnInit(): void {
        this.config.source = ['all'];
        this._destroySubscriptions.push(
            this._filterHandlerStaticDefaultValue$.pipe(
                debounceTime(250),
                filter(filterStr => filterStr !== null && filterStr !== undefined)
            ).subscribe(filterStr => this._filterHandlerStaticDefaultValue(filterStr))
        );
        if (this.config.staticDefaultValue) {
            this.staticDefaultValueDataSource.next([this.config.staticDefaultValue]);
        }
    }
    // #endregion

    // #region ngAfterViewInit
    ngAfterViewInit(): void {
        if (this.config.showValidateInfo) {
            this.validate();
        }
    }
    // #endregion

    // #region ngOnDestroy
    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }
    // #endregion

    // #region events
    onBlurStaticDefaultValue(): void {
        this._focusedStaticDefaultValue = false;
    }

    onFilterStaticDefaultValue(event): void {
        if (this._focusedStaticDefaultValue) {
            this._filterHandlerStaticDefaultValue$.next(event);
        }
    }

    onFocusStaticDefaultValue(): void {
        this._focusedStaticDefaultValue = true;
        this.onFilterStaticDefaultValue('');
    }

    onValueChangeStaticDefaultValue(event): void {
        this._focusedStaticDefaultValue = true;
        this.onFilterStaticDefaultValue('');
        this.valueChange(event);
    }
    // #endregion

    // #region public functions
    clearValue() {
        const event = new UIEvent('kendodropdown_keydown');
        event['keyCode'] = 27;
        this.staticDefaultValueKendo.keydown(event);
        this.config.staticDefaultValue = null;
    }

    getInvalidDetail(): FieldValidationError {
        const baseValidationDetail: FieldValidationError = this.base.getInvalidDetail();
        baseValidationDetail.isSourceInvalid = !this.validateSource();
        return baseValidationDetail;
    }

    validate(): boolean {
        const baseValid: boolean = this.base.validate();
        // run local validate
        this.sourceInvalid = !this.validateSource();
        return baseValid && !this.sourceInvalid;
    }

    validateSource(): boolean {
        return this.config.source && this.config.source.length > 0;
    }

    valueChange(event): void {
        this.config.config.feedbackSubject$.next({
            type: ControlConfigEvents.VALUE_CHANGE,
            payload: this.config,
        });
    }
    // #endregion

    // #region privat functions
    private _getSourceByResponse(res) {
        const list = res['entity-list'].map(element => {
            return {
                id: element.id,
                name: element['long-name'],
                shortName: element['short-name'],
                aliases: element['aliases'].data,
                primaryEmail: element['primary-email'],
                isPublic: element['is-public']
            };
        });
        ArrayHelperService.sort(list, 'name');
        return list;
    }

    private _filterHandlerStaticDefaultValue(filterStr: string): void {
        if (!this.sourceInvalid && this._focusedStaticDefaultValue) {
            // get the all user from store
            this._usersService.getAll().subscribe(res => {
                this._sourceData = this._getSourceByResponse(res);
                const data = this._sourceData.filter(item => item.shortName.toLowerCase().includes(filterStr.toLowerCase()));
                this.staticDefaultValueDataSource.next(data);
            });
        }
    }
    // #endregion
}
