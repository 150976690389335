<div class="item">
    <div class="icon-container" [style.visibility]="entity.isPublic?'hidden':'visible'">
        <smart-icon name="locked"></smart-icon>
    </div>
    <div class="right-content">
        <div class="main-info">
            <div class="entity-name">{{entity.shortName}}</div>
            <div *ngIf="entity.type" class="entity-type">{{entity.type.name}}</div>
        </div>
        <div class="side-info">{{sideInfo}}</div>
    </div>
</div>