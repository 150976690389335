/**
 * Created by Abner Sui on 02/21/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { businessConstants } from '../tamalelibs/constants/business.constants';
import { RefinebySubConfig, RefinebyGroup, RefinebyType, RefinebyStatus } from '../widgets/refineby/refineby-view.model';
import { RefinebyService } from './refineby.service';
import { ArrayHelperService } from '../tamalelibs/services/array-helper.service';

@Injectable()
export class BlastemailRefinebyService {

    constructor(
        private _refinebyService: RefinebyService,
    ) { }

    private _standardCategory: Array<string> = ['entities', 'time', 'source', 'attachment'];
    private _defaultCategory: Array<string> = ['entities', 'time', 'source', 'attachment'];

    get DefaultCategory(): Array<string> {
        return this._defaultCategory;
    }

    get StandardCategory(): Array<string> {
        return this._standardCategory;
    }

    public generateSubConfig(id: string, data: Map<string, Array<any>>, selectedValue: Map<string, Array<any>>, status: Map<string, RefinebyStatus>): RefinebySubConfig {
        let subConfig: RefinebySubConfig;
        const options: Array<any> = [];
        const oneOptions: Array<any> = data.get(id);
        if (oneOptions) {
            options.push(...oneOptions);
        }
        switch (id) {
            case 'entities':
                subConfig = new RefinebySubConfig(id, 'Entities', RefinebyGroup.standard, RefinebyType.entity);
                break;
            case 'type':
                subConfig = new RefinebySubConfig(id, 'Type', RefinebyGroup.standard, RefinebyType.object);
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'time':
                subConfig = new RefinebySubConfig(id, 'Date', RefinebyGroup.standard, RefinebyType.date);
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'source':
                subConfig = new RefinebySubConfig(id, 'Source', RefinebyGroup.standard, RefinebyType.object);
                break;
            case 'attachment':
                subConfig = new RefinebySubConfig(id, 'Attachments');
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            default:
                subConfig = null;
                break;
        }
        const subStatus: RefinebyStatus = status.get(id);
        if (subStatus) {
            subConfig.status = subStatus;
        }
        if (options) {
            subConfig.options = options;
        }
        const selectedOptions: Array<any> = selectedValue.get(id);
        if (selectedOptions) {
            subConfig.selectedOptions = selectedOptions;
        }
        subConfig.defaultIndex = this.StandardCategory.indexOf(id);
        return subConfig;
    }

    public getItemDisplayName(categoryId: string, itemId: string): string {
        let result = '';
        switch (categoryId) {
            case 'type':
                switch (itemId) {
                    case businessConstants.entity.Uncategorized_Fixed_ID:
                        result = 'UnCategorized';
                        break;
                    case businessConstants.entity.UnSpecified_Fixed_ID:
                        result = 'UnSpecified';
                        break;
                    case '0':
                        result = 'Other';
                        break;
                    default:
                        break;
                }
                break;
            case 'time':
                switch (itemId) {
                    case '0':
                        result = 'Today';
                        break;
                    case '1':
                        result = 'Last 7 days';
                        break;
                    case '2':
                        result = 'Last 30 days';
                        break;
                    case '3':
                        result = 'Older';
                        break;
                    default:
                        break;
                }
                break;
            case 'attachment':
                switch (itemId) {
                    case '0':
                        result = 'No Attachment';
                        break;
                    case '1':
                        result = 'Has Attachment(s)';
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        return result;
    }

    public updateSubConfig(config: RefinebySubConfig, data: Map<string, Array<any>>, selectedValue: Map<string, Array<any>>, status: Map<string, RefinebyStatus>, showAsName: boolean): void {
        const id = config.categoryId;
        const options: Array<any> = [];
        const oneOptions: Array<any> = data.get(id);
        if (oneOptions) {
            options.push(...oneOptions);
        }
        let sortField: string;
        const keyField = 'id';
        if (showAsName) {
            sortField = 'longName';
        } else {
            sortField = 'shortName';
        }
        switch (id) {
            case 'entities':
                break;
            case 'type':
                sortField = 'name';
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'time':
                sortField = 'id';
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            case 'source':
                sortField = 'name';
                break;
            case 'attachment':
                sortField = 'name';
                if (options) {
                    options.forEach(option => {
                        option.name = this.getItemDisplayName(id, option.id);
                    });
                }
                break;
            default:
                break;
        }
        const subStatus: RefinebyStatus = status.get(id);
        if (subStatus) {
            config.status = subStatus;
        }
        const selectedOptions: Array<any> = selectedValue.get(id);
        if (selectedOptions) {
            selectedOptions.forEach(option => {
                option.checked = true;
                const index = options.findIndex(item => item[keyField] === option[keyField]);
                if (index > -1) {
                    option.count = options[index].count;
                    options[index].checked = true;
                    if (config.type !== RefinebyType.date) {
                        options.splice(index, 1);
                    }
                } else {
                    option.count = 0;
                }
            });
        }
        if (options) {
            ArrayHelperService.sort(options, sortField);
            if (config.type === RefinebyType.date) {
                this._refinebyService.handleRefinebyDate(options);
            }
            config.options.splice(0, config.options.length);
            config.options.push(...options);
        }
    }

}
