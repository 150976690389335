/**
 * Created by Marcus Zhao on 12/25/2021
 * -------------------------------------
 */

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { AppState } from '../../../redux';
import { fullEntityTypeSelector } from '../../../redux/reducers/entity-type.reducer';
import { ALL_VALUE } from '../../../tamalelibs/constants/business.constants';
import { EntityBrief } from '../../../tamalelibs/models/entity-brief.model';
import { EntityType } from '../../../tamalelibs/models/entity-type.model';
import { FieldType } from '../../../tamalelibs/models/workflow.model';
import { EntityService } from '../../../tamalelibs/services/entity.service';
import { MultiSelDropdownConfig, MultiSelDropdownFilterConfig } from '../../../widgets/multi-sel-dropdown-filter/multi-sel-dropdown-filter.model';
import { ControlConfigEvents, IControlConfigComponent } from '../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';
import { ArrayHelperService } from '../../../tamalelibs/services/array-helper.service';

export const EMPTY_SOURCE_NAME = 'None';

@Component({
    selector: 'tam-multi-entity-dropdown-config',
    templateUrl: './entity-dropdown-config.component.html',
    styleUrls: ['./field-config.component.scss']
})
export class EntityDropdownConfigComponent implements OnInit, AfterViewInit, OnDestroy, IControlConfigComponent {

    @Input()
    config: FieldConfig;

    @ViewChild('base', { static: false }) base;
    @ViewChild('staticDefaultValuekendo', { static: false }) staticDefaultValuekendo;

    dataType = FieldType.ENTITY;
    dropdownConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    sourceName = EMPTY_SOURCE_NAME;
    sourceInvalid = false;
    staticDefaultValueDataSource: BehaviorSubject<Array<EntityBrief>> = new BehaviorSubject<Array<EntityBrief>>([]);
    staticDefaultValueLoading = false;

    private _filterHandlerStaticDefaultValue$: Subject<string> = new Subject();
    private _focusedStaticDefaultValue = false;
    private _destroySubscriptions: Array<Subscription> = [];
    private _sourceData: Array<EntityType>;

    constructor(
        private _store: Store<AppState>,
        private _entityService: EntityService,
    ) { }

    /**
     * #init Entity Type and sort
     * #int _filterHandlerStaticDefaultValue for search values
     * @memberof EntityDropdownConfigComponent
     */
    ngOnInit(): void {
        this._destroySubscriptions.push(
            this._store.pipe(
                select(fullEntityTypeSelector),
            ).subscribe(res => {
                this._sourceData = res;
                ArrayHelperService.sort(this._sourceData, 'name');
                this._sourceData.forEach(item => {
                    const checked = this.config.source.indexOf(item.id) > -1 || (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE);
                    this.dropdownConfig.data.push(MultiSelDropdownConfig.parse(item.id, checked, item.name, '', false));
                });
                this.config.source = (this.config.source && this.config.source.length === 1 && this.config.source[0] === ALL_VALUE) ? [ALL_VALUE] : this.dropdownConfig.data.filter(item => item.checked).map(item => item.id);
                if (this.validateSource()) {
                    const sources: Array<any> = (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE) ? [{ id: ALL_VALUE, name: 'All' }] : this.dropdownConfig.data.filter(item => item.checked);
                    this.sourceName = sources.map(item => item.name).join(', ');
                }
            }),

            this._filterHandlerStaticDefaultValue$.pipe(
                debounceTime(250),
                filter(filterStr => filterStr !== null && filterStr !== undefined)
            ).subscribe(filterStr => this._filterHandlerStaticDefaultValue(filterStr)),
            this.dropdownConfig.onChangeValue$.subscribe(data => this.valueChangeSource(data)),
        );
    }

    ngAfterViewInit(): void {
        if (this.config.showValidateInfo) {
            this.validate();
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    blurStaticDefaultValue(): void {
        this._focusedStaticDefaultValue = false;
    }

    filterStaticDefaultValue(event): void {
        if (this._focusedStaticDefaultValue) {
            this.staticDefaultValuekendo.toggle(false);
            this._filterHandlerStaticDefaultValue$.next(event);
        }
    }

    focusStaticDefaultValue(): void {
        this._focusedStaticDefaultValue = true;
        this.filterStaticDefaultValue('');
    }

    getInvalidDetail(): FieldValidationError {
        const baseValidationDetail: FieldValidationError = this.base.getInvalidDetail();
        baseValidationDetail.isSourceInvalid = !this.validateSource();
        return baseValidationDetail;
    }

    onClickSource(event) {
        this.dropdownConfig.open$.next(event.target);
    }

    validate(): boolean {
        const baseValid: boolean = this.base.validate();
        // run local validate
        this.sourceInvalid = !this.validateSource();
        return baseValid && !this.sourceInvalid;
    }

    validateSource(): boolean {
        return this.config.source && this.config.source.length > 0;
    }

    valueChange(event): void {
        this.config.config.feedbackSubject$.next({
            type: ControlConfigEvents.VALUE_CHANGE,
            payload: this.config,
        });
    }

    /**
     * Check whether the default values are valid when the source changes
     *
     * @param {*} event
     * @memberof EntityDropdownConfigComponent
     */
    valueChangeSource(event): void {
        const value = event === ALL_VALUE ? [{ id: event, name: 'All' }] : event;
        this.config.source = value.map(item => item.id);
        this.sourceInvalid = !this.validateSource();
        this.sourceName = this.sourceInvalid ? EMPTY_SOURCE_NAME : value.map(item => item.name).join(', ');
        const defaultValues = this.config.staticDefaultValue;
        if (defaultValues && defaultValues.length > 0) {
            let validValues = defaultValues.filter((element) => {
                const checked = this.config.source.indexOf(element.type.id) > -1 || (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE);
                return checked;
            });
            validValues = this.config.staticDefaultValue;
        }
        this.valueChange(event);
    }

    valueChangeStaticDefaultValue(event): void {
        this._focusedStaticDefaultValue = true;
        this.filterStaticDefaultValue('');
        this.valueChange(event);
    }

    private _filterHandlerStaticDefaultValue(filterStr: string): void {
        if (!this.sourceInvalid && this._focusedStaticDefaultValue) {
            this.staticDefaultValueLoading = true;
            const source = (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE) ? {} : { entityType: this.config.source };
            this._entityService.getEntityListBySearchTextQuick(1, 20, filterStr, source).pipe(
                take(1),
                map(response => this._entityService.mapEntityBriefList(response))
            ).subscribe(data => {
                this.staticDefaultValueDataSource.next(data);
                this.staticDefaultValueLoading = false;
                this.staticDefaultValuekendo.toggle(true);
            });
        }
    }
}
