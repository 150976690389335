/**
 * Created by Abner Sui on 04/04/2019.
 * Description:
 * ------ maintenance history ------
 * Ella Ma - 2020/12/07, remove entity 'Default Contact type'(TAM-32438)
 */

import { ProviderColumn, Dataprovider, ProviderColumnType } from '../../../../tamalelibs/models/dataprovider.model';
import { Marketdata } from '../../../../tamalelibs/models/marketdata.model';
import { TileColumn, TileColumnType, TileColumnDataType } from '../../../../tamalelibs/models/tile-column.model';

export class Provider {
    id: string;
    name: string;
    columns: Array<ProviderColumn>;
    type: number;

    constructor() {
        this.columns = [];
    }

    static parseDataprovider(item: Dataprovider): Provider {
        const result = new Provider();
        result.id = item.id;
        result.name = item.name;
        result.columns.push(...item.columns);
        result.type = item.type;
        return result;
    }

    static parseMarketdata(item: Marketdata): Provider {
        const result = new Provider();
        result.id = item.name;
        result.name = item.name;
        item.columns.forEach(column => {
            result.columns.push(new ProviderColumn(column, ProviderColumnType.TEXT));
        });
        result.type = 0;
        return result;
    }

}

export const DEFAULT_ITEM_HEIGHT = 30;

export const ENTITY_COLUMN_LIST: Array<TileColumn> = [
    {
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {
            'display': '0'
        },
        field: 'entity__address',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Address',
        fixDataType: true,
        guid: '',
        title: 'Address',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        field: 'entity__aliases',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Aliases',
        fixDataType: true,
        guid: '',
        title: 'Aliases',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'entity__email',
        name: 'Email',
        title: 'Email',
        fixDataType: true,
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'entity__employees',
        name: 'Employees',
        title: 'Employees',
        fixDataType: true,
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'entity__id',
        name: 'Entity ID',
        title: 'Entity ID',
        fixDataType: true,
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'entity__entity_type',
        name: 'Entity Type',
        title: 'Entity Type',
        fixDataType: true,
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'entity__fax',
        name: 'Fax',
        title: 'Fax',
        fixDataType: true,
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    // {
    //     field: 'entity__im_service',
    //     name: 'IM Service',
    //     title: 'IM Service',
    //     fixDataType: true,
    //     columnType: TileColumnType.ENTITY_COLUMN,
    //     settings: {},
    //     hidden: false,
    //     dataType: TileColumnDataType.TEXT,
    //     formattingConfig: {},
    //     guid: '',
    //     config: {
    //         parameterValue: '',
    //     },
    // },
    {
        field: 'entity__links',
        name: 'Links',
        title: 'Links',
        fixDataType: true,
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'entity__members',
        name: 'Members',
        title: 'Members',
        fixDataType: true,
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'entity__officers',
        name: 'Officers',
        title: 'Officers',
        fixDataType: true,
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'entity__phone',
        name: 'Phone',
        title: 'Phone',
        fixDataType: true,
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.ENTITY_COLUMN,
        settings: {},
        field: 'entity__is_public',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Privacy',
        fixDataType: true,
        guid: '',
        title: 'Privacy',
        config: {
            parameterValue: '',
        },
    },
];

export const ENTRY_COLUMN_LIST: Array<TileColumn> = [
    {
        field: 'entry__display_date',
        name: 'Display Date',
        title: 'Display Date',
        dataType: TileColumnDataType.DATE,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {
            display: '1',
        },
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__last_edited_date',
        name: 'Last Edited Date',
        title: 'Last Edited Date',
        fixDataType: true,
        dataType: TileColumnDataType.DATE,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {
            display: '1',
        },
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__note_body',
        name: 'Note Body',
        title: 'Note Body',
        fixDataType: true,
        dataType: TileColumnDataType.TEXT,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {},
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__id',
        name: 'Note ID',
        title: 'Note ID',
        fixDataType: true,
        dataType: TileColumnDataType.TEXT,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {},
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__type_name',
        name: 'Note Type',
        title: 'Note Type',
        fixDataType: true,
        dataType: TileColumnDataType.TEXT,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {},
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__priority',
        name: 'Priority',
        title: 'Priority',
        fixDataType: true,
        dataType: TileColumnDataType.TEXT,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {},
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__sentiment',
        name: 'Sentiment',
        title: 'Sentiment',
        fixDataType: true,
        dataType: TileColumnDataType.TEXT,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {},
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__source_id',
        name: 'Source',
        title: 'Source',
        fixDisplay: true,
        dataType: TileColumnDataType.ENTITY,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {
            display: '0',
        },
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__title',
        name: 'Subject',
        title: 'Subject',
        fixDataType: true,
        dataType: TileColumnDataType.TEXT,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {},
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__submitted_date',
        name: 'Submitted Date',
        title: 'Submitted Date',
        dataType: TileColumnDataType.DATE,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {
            display: '1',
        },
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__submitter_id',
        name: 'Submitter',
        title: 'Submitter',
        fixDisplay: true,
        dataType: TileColumnDataType.ENTITY,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: '',
        settings: {
            display: '0',
        },
        hidden: false,
        formattingConfig: {},
    },
    {
        field: 'entry__file',
        name: 'File',
        title: 'File',
        fixDisplay: true,
        dataType: TileColumnDataType.TEXT,
        top: 0,
        columnType: TileColumnType.ENTRY_COLUMN,
        guid: 'ba34ra056tee4acaaddefff44rfbe',
        settings: {
            display: '0',
        },
        hidden: false,
        formattingConfig: {},
    },
];

export const CONTACT_COLUMN_LIST: Array<TileColumn> = [
    {
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        field: 'contact__full_name',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Full Name',
        fixDataType: true,
        guid: '',
        title: 'Full Name',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        field: 'contact__first_name',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'First Name',
        fixDataType: true,
        guid: '',
        title: 'First Name',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        field: 'contact__last_name',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Last Name',
        fixDataType: true,
        guid: '',
        title: 'Last Name',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        field: 'contact__gender',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Gender',
        fixDataType: true,
        guid: '',
        title: 'Gender',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        field: 'contact__aliases',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Alias',
        fixDataType: true,
        guid: '',
        title: 'Alias',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        field: 'contact__job_title',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Job Title',
        fixDataType: true,
        guid: '',
        title: 'Job Title',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        field: 'contact__job_function',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Job Function',
        fixDataType: true,
        guid: '',
        title: 'Job Function',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {
            display: '0',
        },
        field: 'contact__company',
        hidden: false,
        dataType: TileColumnDataType.ENTITY,
        formattingConfig: {},
        name: 'Company',
        fixDataType: true,
        guid: '',
        title: 'Company',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__primary_email',
        name: 'Primary Email',
        title: 'Primary Email',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__work_email',
        name: 'Work Email',
        title: 'Work Email',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__personal_email',
        name: 'Personal Email',
        title: 'Personal Email',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__other_email', // Secondary -> Other
        name: 'Other Email',
        title: 'Other Email',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__assistant_email',
        name: 'Assistant Email',
        title: 'Assistant Email',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.CONTACT_COLUMN, // business -> work
        settings: {},
        field: 'contact__work_phone',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Work Phone',
        fixDataType: true,
        guid: '',
        title: 'Work Phone',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__home_phone',
        name: 'Home Phone',
        title: 'Home Phone',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__mobile_phone',
        name: 'Mobile Phone',
        title: 'Mobile Phone',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        field: 'contact__business_fax',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Fax',
        fixDataType: true,
        guid: '',
        title: 'Fax',
        config: {
            parameterValue: '',
        },
    },
    {
        columnType: TileColumnType.CONTACT_COLUMN, // business -> work
        settings: {
            'display': '0'
        },
        field: 'contact__work_address',
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        name: 'Work Address',
        fixDataType: true,
        guid: '',
        title: 'Work Address',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__home_address',
        name: 'Home Address',
        title: 'Home Address',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {
            'display': '0'
        },
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__linkedin_social_media',
        name: 'Linkedin',
        title: 'Linkedin',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__twitter_social_media',
        name: 'Twitter',
        title: 'Twitter',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__facebook_social_media',
        name: 'Facebook',
        title: 'Facebook',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__im_social_media',
        name: 'IM',
        title: 'IM',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__birthday',
        name: 'Birthday',
        title: 'Birthday',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {
            display: '1'
        },
        hidden: false,
        dataType: TileColumnDataType.DATE,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
    {
        field: 'contact__notes',
        name: 'Notes',
        title: 'Notes',
        fixDataType: true,
        columnType: TileColumnType.CONTACT_COLUMN,
        settings: {},
        hidden: false,
        dataType: TileColumnDataType.TEXT,
        formattingConfig: {},
        guid: '',
        config: {
            parameterValue: '',
        },
    },
];
