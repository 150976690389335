<div class="container">
    <field-config-base #base [config]="config" [dataType]="dataType"></field-config-base>
    <div class="tam-form-field one-field">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.default_value' | stringLiterals}}
            </div>
        </div>
        <kendo-dropdownlist #staticDefaultValueKendo class="tam-textbox field-control"
            [popupSettings]="{appendTo:'component'}" [data]="staticDefaultValueDataSource|async"
            [textField]="'shortName'" [valueField]="'id'" [listHeight]="210" [filterable]="true"
            [disabled]="config.source.length===0 || config.isCurrentUserAsDefaultEnabled"
            [(ngModel)]="config.staticDefaultValue" (valueChange)="onValueChangeStaticDefaultValue($event)"
            (focus)="onFocusStaticDefaultValue()" (filterChange)="onFilterStaticDefaultValue($event)"
            (blur)="onBlurStaticDefaultValue()">
            <ng-template kendoMultiSelectHeaderTemplate>
                <div class="popup-template-clear-header">
                    <span class="single-select-Clear" (click)="clearValue()">Clear</span>
                </div>
            </ng-template>
        </kendo-dropdownlist>
        <label class="field-control" tam-checkbox [(ngModel)]="config.isCurrentUserAsDefaultEnabled"
            [disabled]="config.isTriggerEntityAsDefaultEnabled">
            {{'general.use_current_user_default' | stringLiterals}}
        </label>
    </div>
</div>