/**
 * Created by Ella Ma on 1/9/17.
 * Description:
 *
 * ------ maintenance history ------
 * 05/18/2022 Marcus Zhao added hideHeader and hideSearchBox.
 * 07/06/2022 Simon Zhao added customized display text for all-value.
 */
import { Subject } from 'rxjs';

export class MultiSelDropdownFilterConfig {
    allDisplayText?: string;
    clear$: Subject<any> = new Subject<any>();
    disabledInput: boolean;
    data: MultiSelDropdownConfig[];
    hideInput: boolean;
    onChangeValue$: Subject<any>;
    open$: Subject<any> = new Subject<any>();
    placeholder?: string;
    hideHeader: boolean;
    hideSearchBox: boolean;
    constructor(hideInput?: boolean, disabledInput?: boolean) {
        this.onChangeValue$ = new Subject<any>();
        this.data = [];
        this.hideInput = !!hideInput;
        this.disabledInput = !!disabledInput;
        this.hideHeader = false;
        this.hideSearchBox = false;
    }
}

export class MultiSelDropdownConfig {
    id: string;
    checked: boolean;
    name: string;
    type?: string;
    isDisabled?: boolean;
    static parse(id: string, checked: boolean, name: string, type: string, isDisabled = false): MultiSelDropdownConfig {
        return {
            'id': id,
            'checked': checked,
            'name': name,
            'type': type,
            'isDisabled': isDisabled
        };
    }
}
