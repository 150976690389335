/**
 * Created by Alex on 6/4/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum EntityScreenActionTypes {
    LOAD = '[ENTITYSCREEN] LOAD',
    LOAD_SUCCESS = '[ENTITYSCREEN] LOAD_SUCCESS',
    LOAD_FAILED = '[ENTITYSCREEN] LOAD_FAILED'
}

export class Load implements Action {
    readonly type = EntityScreenActionTypes.LOAD;

    constructor(public payload: any) { }
}

export class LoadSuccess implements Action {
    readonly type = EntityScreenActionTypes.LOAD_SUCCESS;

    constructor(public payload: any) { }
}

export class LoadFailed implements Action {
    readonly type = EntityScreenActionTypes.LOAD_FAILED;

    constructor(public payload: any) { }
}

export type EntityScreenActions = Load | LoadSuccess | LoadFailed;
