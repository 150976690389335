<div #anchor (click)="onClick($event)">
    <div *ngIf="customBtn">
        <ng-container *ngTemplateOutlet="customBtn;">
        </ng-container>
    </div>
    <div class="button" *ngIf="!customBtn">
        <smart-icon name="add-cycle"></smart-icon>
    </div>
</div>
<kendo-popup [anchor]="anchor" [style.display]="showAddPopup?'':'none'">
    <div class="row" style="position: relative;" *ngIf="config.config.showSearch" (click)="onSearch($event)">
        <input #keywordInput type="text" placeholder="Search" [(ngModel)]="searchValue" class="searchInput" autofocus>
        <div class="icon-search">
            <smart-icon name="search"></smart-icon>
        </div>
    </div>
    <div class="list-container">
        <ng-container *ngFor="let item of filterSource;trackBy:trackByFn">
            <ng-container *ngIf="item.text.toLocaleLowerCase().indexOf(searchValue.trim().toLocaleLowerCase()) !== -1">
                <div class="textField" (click)="filterValueChange(item)">{{item.text}}</div>
            </ng-container>
        </ng-container>
    </div>
</kendo-popup>