/**
 * Created by Alex on 11/03/19.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NoteDialogService } from '../components/note-dialog/note-dialog.service';
import { NoteDialogOpenOptions, NoteDialogType } from '../components/note-dialog/note-dialog.model';
import { EntityDialogOpenOptions, EntityDialogType } from '../components/entity-dialog/entity-dialog.model';
import { EntityDialogService } from '../components/entity-dialog/entity-dialog.service';
import { ContactTemplateService } from '../components/contact-dialog-new/contact-template.service';
import { ContactTemplateOpenOptions, ContactTemplateType } from '../components/contact-dialog-new/contact-dialog.model';

@Injectable({
    providedIn: 'root'
})
export class FloatMenuService {
    public onlyShowFloatMenu = true;
    public floatMenuSubject$ = new Subject();
    public actionSubject$ = new Subject<{ type: DraftActionType, payload: any }>();
    public floatMenuBottomSubject$ = new Subject<boolean>();

    constructor(
        private _noteDialogService: NoteDialogService,
        private _entityDialogService: EntityDialogService,
        private _contactTemplateService: ContactTemplateService
    ) { }

    showAddContactTemplate() {
        const _contactTemplateOpenOptions = new ContactTemplateOpenOptions();
        _contactTemplateOpenOptions.openType = ContactTemplateType.NewContact;
        this._contactTemplateService.dialogOpen$.next(_contactTemplateOpenOptions);
    }

    showAddContactDialog() {
        const _contactTemplateOpenOptions = new ContactTemplateOpenOptions();
        _contactTemplateOpenOptions.openType = ContactTemplateType.NewContact;
        this._contactTemplateService.dialogOpen$.next(_contactTemplateOpenOptions);
    }

    showAddNoteDialog() {
        const _noteDialogOpenOptions = new NoteDialogOpenOptions();
        _noteDialogOpenOptions.openType = NoteDialogType.CreateNote;
        this._noteDialogService.dialogOpen$.next(_noteDialogOpenOptions);
    }

    showAddEntityDialog() {
        const _entityDialogOpenOptions = new EntityDialogOpenOptions();
        _entityDialogOpenOptions.openType = EntityDialogType.NewEntity;
        this._entityDialogService.dialogOpen$.next(_entityDialogOpenOptions);
    }

    /**
     * According to BA's requirement, min-height is 360px, max-height is 0.7*screen height, 93 stands for the height of one draft list item
     * 50 stands for the height of menu header
     * @param draftCount
     */
    getHeightOfFloatMenuDraftPanel(draftCount: number): number {
        const defaultHeight = window.innerHeight * 0.7;
        let acturalHeight = defaultHeight;
        if (draftCount * 93 < (360 - 100)) {
            acturalHeight = 360;
        } else if (draftCount * 93 > defaultHeight) {
            acturalHeight = defaultHeight;
        } else {
            acturalHeight = draftCount * 93 + 100;
        }
        return acturalHeight;
    }
}

export enum DraftActionType {
    RELOAD = 'reload',
}
