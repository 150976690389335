
import { of as observableOf, Observable } from 'rxjs';
/**
 * created by Yu Zhang on 11/29/17.
 * Description:
 *      implement the methods in each platform folder and use Angular provider to provide the right instance
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';

@Injectable()
export class IFileDownloader {
    getFile(options: any, filePath: string): Observable<any> {
        return observableOf();
    }
}
