/**
 * Created by Simon Zhao on 7/22/21.
 * Description:
 * responsible for handling transitions from one state to the next state for template fields.
 * ------ maintenance history ------
 *
 */
import { createSelector } from '@ngrx/store';
import { TemplateField } from '../../tamalelibs/models/template-field.model';
import { ADD_ENTITY_FIELDS_TO_CACHE, ADD_TEMPLATE_FIELDS_TO_CACHE } from '../../tamalelibs/redux/template.actions';
import { EntityFieldsActions, TemplateFieldsActions } from '../actions/template-web.actions';
export const templateFieldsSelector = (state: any) => state.templateFields;
export const singleTemplateFieldsByIdSelector = createSelector(templateFieldsSelector,
    (tMaps: Map<string, Array<TemplateField>>, props: { id: string }) => tMaps.get(props.id));

export const entityFieldsSelector = (state: any) => state.entityFields;
export const singleTemplateEntityFieldsSelector = createSelector(entityFieldsSelector,
    (eMaps: Map<string, Array<TemplateField>>, props: { id: string }) => eMaps.get(props.id));

export function templateFieldsCacheReducer(state: Map<string, Array<TemplateField>> = new Map(), action: TemplateFieldsActions) {
    let newState: Map<string, Array<TemplateField>>;
    switch (action.type) {
        case ADD_TEMPLATE_FIELDS_TO_CACHE:
            newState = new Map(state);
            newState.set(action.id, Array.isArray(action.payload) ? action.payload : []);
            return newState;
        default:
            return state;
    }
}

export function entityFieldsReducer(state: Map<string, Array<TemplateField>> = new Map(), action: EntityFieldsActions) {
    let newState: Map<string, Array<TemplateField>>;
    switch (action.type) {
        case ADD_ENTITY_FIELDS_TO_CACHE: {
            newState = new Map(state);
            newState.set(action.id, Array.isArray(action.payload) ? action.payload : []);
            return newState;
        }
        default:
            return state;
    }
}
