/**
 *
 * Description:
 *
 * ------ maintenance history ------
 *  2019.7.11 By Bowen: Add changeSubTitleTopOpen$ for change the layout:
 *  when it's homescreen/setting page, the content-container need to move top to 111px
 */
import { Injectable } from '@angular/core';

import { TransportService } from '../tamalelibs/services/transport.service';
import { AppConfig } from '../tamalelibs/models/app-config.model';
import { Subject } from 'rxjs';

@Injectable()
export class LayoutService {
    changeSubTitleTopOpen$: Subject<any> = new Subject<any>();
    url = AppConfig.layoutEndPoint;
    constructor(
        private _transportService: TransportService,
    ) { }

    getTileLayout(tileId) {

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };

        const params = {
            'tile_id': tileId
        };
        const options = {
            headers: headers,
            params: params
        };

        return this._transportService.get(this.url, options);
    }
}
