<kendo-dialog class="template-dialog" *ngIf="opened" [minWidth]="250" [width]="dialogWidth"
    [style.display]="minimized?'none':'flex'">
    <div class="template-dialog-header">
        <ng-container *ngTemplateOutlet="title;"></ng-container>
    </div>

    <div class="template-dialog-body" [style.height.px]="contentHeight">
        <ng-container *ngTemplateOutlet="body;"></ng-container>
    </div>

    <div class="template-dialog-footer">
        <ng-container *ngTemplateOutlet="footer;"></ng-container>
    </div>
</kendo-dialog>

<div style="height:0px;">
    <ng-template #viewContainer></ng-template>
</div>
<div class="minimize-interface" style="visibility: hidden;" (click)="maxmize()">
    <span class="minimize-title">{{titleName}}</span>
    <div class="minimize-action">
        <smart-icon class="minimize-close" name="close" (click)="minimizeClose($event)" [hidden]="closable">
        </smart-icon>
    </div>
</div>