import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PluginErrorComponent } from './plugin-error.component';
import { WidgetsModule } from '../../widgets/widgets.module';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
    ],
    declarations: [PluginErrorComponent]
})
export class PluginErrorModule { }
