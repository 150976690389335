import { Subject } from 'rxjs';

export enum TabEventType {
    onTabClick,
    onMouseDown,
    onMouseUp,
    onEdit,
    onDeleteTab,
    initTabInfo,
    initTabId
}

export enum TabActionType {
    setActiveTab,
    showTempUnderline
}

export class TabConfig {
    events$: Subject<{ type: TabEventType, payload?: any }>;
    constructor() {
        this.events$ = new Subject();
    }
}
