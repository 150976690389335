<div class="textarea-container">
    <field-config-base #base [config]="config" [dataType]="dataType"></field-config-base>
    <div class="tam-form-field line-num" tid="Row Height">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'workflow.row_height' | stringLiterals}}
            </div>
        </div>
        <div class="tam-textbox field-control">
            <kendo-numerictextbox [value]="config.maxlines" min="2" max="5" step="1" [autoCorrect]="true"
                [format]="'n0'" (valueChange)="onLineLengthChanged($event)">
            </kendo-numerictextbox>
        </div>
    </div>
    <div tid="default_value" class="tam-form-field one-field" [ngClass]="{'has-error':staticDefaultValueInvalid}">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.default_value' | stringLiterals}}
            </div>
        </div>
        <textarea class="height-per-rows" (keyup)="valueChangeStaticDefaultValue($event)"
            [(ngModel)]="config.staticDefaultValue" [ngStyle]="{'height': (config.maxlines * 32) + 'px'}"></textarea>
        <div class="tam-form-help" *ngIf="staticDefaultValueInvalid">
            {{'general.text_character_allowed' | stringLiterals: config.minlength: config.maxlength}}
        </div>
    </div>
</div>