/**
 * ---------maintenance history-------------
 * 2019.5.22  By Bowen Li: fixed the home screen tabs underline
 * 05/22/2019 Yoyo Fang add ability to change selected tab from parent
 * 08/16/2019 Ella Ma: remove DOM operate and setTimeout
 * 08/21/2019 Ella Ma: remove tab-set input data, remove tab index and selectIndex, and add a unique id to the tab, remove setTimeout
 */
import {
    Component, OnInit, AfterViewInit, OnDestroy, ContentChild, TemplateRef, Input, ElementRef,
    EventEmitter, Output, HostListener, ChangeDetectorRef, SimpleChanges, OnChanges
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { TabEventType, TabActionType } from './tab.config';
import { MOVE_STEP, ARROW_WIDTH, TAB_PADDING } from './tab-set.model';

export const TAB_DEFAULT_WIDTH = 100;

@Component({
    selector: 'tam-tab-set',
    templateUrl: './tab-set.component.html',
    styleUrls: ['./tab-set.component.scss']
})
export class TabSetComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Output() reSortTab = new EventEmitter<any>();
    @Output() selectChange = new EventEmitter<number>();
    @Output() editTitle = new EventEmitter<any>();

    @Input() selectedIndex: number;
    @Input() draggable?: boolean;

    @ContentChild('extra', { static: false }) extra: TemplateRef<any>;
    /**
     *  dragging, tabActionSubject$ used by tab component
     */
    // Record the current drag status for children Tab components
    dragging = false;
    tabActionSubject$ = new Subject();

    disabledRightArrow = true;
    disabledLeftArrow = true;
    showSwipe = false;
    tabLeft = '0px';
    tabSetWidth: string;
    tabSetMargin = '0px';
    underLineLeft = '0px';
    underLineWidth = '0px';
    arrowLeft = '0px';

    private _element: HTMLElement;
    private _containerEl: HTMLElement;
    private _containerClientWidth = 0;
    private _tabEl: HTMLElement;
    private _tabClientWidth = 0;
    private _tabInfoArr = []; // tab id, width, left
    private _tabSetEl: HTMLElement;
    private _extraClientWidth = 0;
    private _step = 0;
    private _destroySubscriptions: Array<Subscription> = [];
    private _upTabIndex;
    private _downTabIndex;
    private _showTempUnderline = false;

    private _selectedIndex = 0;
    private _previousSelectedIndex = 0;

    constructor(
        private elementRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this._element = this.elementRef.nativeElement;
        if (this.selectedIndex) {
            this._selectedIndex = this.selectedIndex;
        }
    }

    ngAfterViewInit(): void {
        this._containerEl = this._element.querySelector('.tab-set-container');
        this._tabSetEl = this._element.querySelector('.tab-set');
        this._tabEl = this._element.querySelector('.tab');
        if (this.extra) {
            this._extraClientWidth = this._element.querySelector('.extra').clientWidth;
        }
        let width, left = 0;
        // Using the linkage of El to get the width of tab, we can reconstruct the tab set in the future
        this._tabInfoArr.forEach((tab, index) => {
            if (index === this._selectedIndex) {
                left = tab.left;
                width = tab.width === 0 ? TAB_DEFAULT_WIDTH : tab.width; // enhance tab set to avoid the underline can't be loaded when the width of tab is 0
            }
        });
        this.showSwipe = this.resizePage();
        this.onLineSlide(false, {
            width: width,
            left: left
        });

        if (width === 0) {
            const parentNode = this._getParent(this._element);
            if (parentNode && parentNode.length > 0 &&
                parentNode.some(item => item.className && item.className.indexOf('dialog-body') !== -1)) {
                this._showTempUnderline = true;
                this.tabActionSubject$.next({
                    type: TabActionType.showTempUnderline,
                    payload: this._showTempUnderline
                });
            }
        }
        this._changeDetectorRef.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedIndex && changes.selectedIndex.previousValue !== changes.selectedIndex.currentValue) {
            /**
             * selectedIndex change is divided into two types,
             * one is the external change caused by the tab-set trigger,
             * and the other is the change caused by external direct assignment
             * either internal or external should be consistent
             */
            this._selectedIndex = changes.selectedIndex.currentValue;
            this._previousSelectedIndex = changes.selectedIndex.previousValue || this._selectedIndex;
            if (this._tabInfoArr[this._selectedIndex]) {
                this.onLineSlide(false, this._tabInfoArr[this._selectedIndex]);
                this.tabActionSubject$.next({
                    type: TabActionType.setActiveTab,
                    payload: this._tabInfoArr[this._selectedIndex].id,
                });
            }
            this._changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.showSwipe = false;
        this._containerClientWidth = 0;
        this._tabClientWidth = 0;
        this._extraClientWidth = 0;
        this._step = 0;
        this._destroySubscriptions.forEach((item) => item.unsubscribe());
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.showSwipe = this.resizePage();
        this.onLineSlide();
    }

    resizePage(tabClientWidth?): boolean {
        let tempShowSwipe = this.showSwipe;
        if (this._containerEl) {
            this._containerClientWidth = this._containerEl.clientWidth;
            if (this._containerClientWidth) {
                this._tabClientWidth = tabClientWidth || this._tabEl.clientWidth;
                const showSwipe = this._containerClientWidth < this._tabClientWidth + this._extraClientWidth;
                let tabSetWidth = this._tabClientWidth;
                let tabSetMargin = 0;
                if (showSwipe) {
                    tabSetWidth = this._containerClientWidth - this._extraClientWidth - ARROW_WIDTH * 2;
                    tabSetMargin = ARROW_WIDTH;
                    this.arrowLeft = `${tabSetWidth + tabSetMargin}px`;
                } else {
                    this.tabLeft = '0px';
                    this._step = 0;
                    this.arrowLeft = '0px';
                }
                this.tabSetWidth = `${tabSetWidth}px`;
                this.tabSetMargin = `${tabSetMargin}px`;
                tempShowSwipe = showSwipe;
            }
        }
        return tempShowSwipe;
    }

    onLineSlide(initial = false, info = null) {
        if (this._showTempUnderline) {
            this._showTempUnderline = false;
            this.tabActionSubject$.next({
                type: TabActionType.showTempUnderline,
                payload: this._showTempUnderline
            });
        }
        if (!initial && this._selectedIndex === -1) {
            this.underLineLeft = '0px';
            this.underLineWidth = '0px';
        } else {
            /**
             * TAB_PADDING = padding-left + padding-right
             * tab padding is padding-left: $tabPaddingLeft, padding-right: $tabPaddingRight
             * offsetLeft = activeTabEl.offsetLeft + $tabPaddingLeft
             */
            let underLineWidth, underLineLeft;
            if (info && info.width) {
                underLineWidth = `${info.width - TAB_PADDING}px`;
                underLineLeft = `${info.left + TAB_PADDING / 2}px`;
            } else {
                const activeTabEl = <HTMLElement>this._element.querySelector('.active-tab');
                if (activeTabEl) {
                    underLineWidth = `${activeTabEl.clientWidth - TAB_PADDING}px`;
                    underLineLeft = `${activeTabEl.offsetLeft + TAB_PADDING / 2}px`;
                }
            }
            this.underLineWidth = underLineWidth;
            this.underLineLeft = underLineLeft;
        }
        if (!this._changeDetectorRef['destroyed']) {
            this._changeDetectorRef.detectChanges();
        }
    }

    onSwipeLeft() {
        if (this.disabledLeftArrow) {
            return;
        }
        this._step += 1;
        if (Math.abs(this._tabEl.offsetLeft) <= MOVE_STEP) {
            this.disabledLeftArrow = true;
            this.tabLeft = '0px';
            this._step = -1;
        } else {
            this.tabLeft = `${this._tabEl.offsetLeft + MOVE_STEP}px`;
        }
        this.disabledRightArrow = false;
    }

    onSwipeRight() {
        if (this.disabledRightArrow) {
            return;
        }
        this._step -= 1;
        const minusLength = Math.abs(this._tabSetEl.clientWidth - this._tabEl.clientWidth);
        if (minusLength <= Math.abs(this._step * MOVE_STEP) && minusLength >= Math.abs((this._step + 1) * MOVE_STEP) ||
            minusLength <= MOVE_STEP) {
            this._step = -1;
            this.tabLeft = `${-1 * minusLength}px`;
            this.disabledRightArrow = true;
        } else {
            this.tabLeft = `${this._tabEl.offsetLeft - MOVE_STEP}px`;
        }
        this.disabledLeftArrow = false;
    }

    subscribeEvent(child) {
        this._destroySubscriptions.push(
            child.feedbackSubject$.pipe(skip(1)).subscribe((event) => {
                this._childSubscriptHandlerSkip(event);
            }),
            child.feedbackSubject$.subscribe((event) => {
                this._childSubscriptHandlerNotSkip(event);
            })
        );
    }

    private _childSubscriptHandlerSkip(event) {
        if (event.type === TabEventType.onTabClick) {
            const index = this._tabInfoArr.findIndex(tab => tab.id === event.payload.id);
            this._selectedIndex = index;
            this.onLineSlide(false, event.payload);
            this.tabActionSubject$.next({
                type: TabActionType.setActiveTab,
                payload: this._tabInfoArr[index].id
            });
            this.selectChange.emit(this._selectedIndex);
        } else if (event.type === TabEventType.onDeleteTab) {
            const index = this._tabInfoArr.findIndex(tab => tab.id === event.payload);
            const deleteTabWidth = this._tabInfoArr[index].width;
            const deleteTabLeft = this._tabInfoArr[index].left;
            const tabClientWidth = this._tabClientWidth - deleteTabWidth;
            this.showSwipe = this.resizePage(tabClientWidth);
            this._tabInfoArr.splice(index, 1);
            // delete active or before active
            if (index <= this._previousSelectedIndex) {
                if (this._tabInfoArr[this._selectedIndex]) {
                    let left;
                    // delete active tab &&  active tab index = 0
                    if (index === 0 && this._selectedIndex === 0) {
                        left = deleteTabLeft;
                    } else if (index === this._previousSelectedIndex) { // delete active tab, avtive tab index != 0
                        left = this._tabInfoArr[this._selectedIndex].left;
                    } else { // delete not active tab
                        const previousUnderLineLeft = this._tabInfoArr[this._selectedIndex].left;
                        left = previousUnderLineLeft - deleteTabWidth;
                    }
                    this.tabActionSubject$.next({
                        type: TabActionType.setActiveTab,
                        payload: this._tabInfoArr[this._selectedIndex].id
                    });
                    this.onLineSlide(false, {
                        width: this._tabInfoArr[this._selectedIndex].width,
                        left: left
                    });
                }
            }
            this._tabInfoArr.reduce((offsetLeft, tab) => {
                tab.left = offsetLeft;
                return offsetLeft += tab.width;
            }, 0);
        } else if (event.type === TabEventType.onEdit) {
            const index = this._tabInfoArr.findIndex(tab => tab.id === event.payload.id);
            const tabClientWidth = this._tabClientWidth - this._tabInfoArr[index].width + event.payload.width;
            this._tabInfoArr[index].width = event.payload.width;
            this.showSwipe = this.resizePage(tabClientWidth);
            if (index === this._selectedIndex) {
                this.onLineSlide(false, {
                    width: event.payload.width,
                    left: parseInt(this.underLineLeft.slice(0, -2), 10) - TAB_PADDING / 2
                });
            } else if (index < this._selectedIndex) {
                this.onLineSlide(false, {
                    width: this.underLineWidth,
                    left: this._tabInfoArr[index].left + event.payload.width
                });
            }
            if (event.payload.blur) {
                this.editTitle.emit({
                    index: index,
                    title: event.payload.title,
                    newValue: event.payload.newValue
                });
            }
            this._tabInfoArr.reduce((offsetLeft, tab) => {
                tab.left = offsetLeft;
                return offsetLeft += tab.width;
            }, 0);
        } else if (event.type === TabEventType.onMouseDown) {
            const index = this._tabInfoArr.findIndex(tab => tab.id === event.payload);
            this._downTabIndex = index;
        } else if (event.type === TabEventType.onMouseUp) {
            const index = this._tabInfoArr.findIndex(tab => tab.id === event.payload);
            this._mouseup(index);
        }
    }

    private _childSubscriptHandlerNotSkip(event) {
        if (event.type === TabEventType.initTabId) {
            this.disabledRightArrow = false;
            this._tabInfoArr.push(event.payload);
            if (this._tabInfoArr[this._selectedIndex]) {
                this.tabActionSubject$.next({
                    type: TabActionType.setActiveTab,
                    payload: this._tabInfoArr[this._selectedIndex].id
                });
            }
        } else if (event.type === TabEventType.initTabInfo) {
            this._tabInfoArr.forEach((info, index) => {
                if (info.id === event.payload.id) {
                    this._tabInfoArr[index] = event.payload;
                }
            });
            // click add button to create a new tab
            if (this._containerEl) {
                let clientTabWidth = 0;
                this._tabInfoArr.forEach((tab) => {
                    clientTabWidth += tab.width;
                });
                this.showSwipe = this.resizePage(clientTabWidth);
                if (this._tabInfoArr[this._selectedIndex]) {
                    this.onLineSlide(false, {
                        width: this._tabInfoArr[this._selectedIndex].width,
                        left: this._tabInfoArr[this._selectedIndex].left
                    });
                }
            }
        }
    }

    private _getParent(node, parentNodes = []) {
        if (node.parentNode) {
            parentNodes.push(node.parentNode);
            this._getParent(node.parentNode, parentNodes);
        }
        return parentNodes;
    }


    private _mouseup(index) {
        if (index === this._downTabIndex) {
            return;
        }
        this._upTabIndex = index;
        let width, left;
        if (this._downTabIndex > this._upTabIndex) { // to before
            if (this._selectedIndex === this._downTabIndex) {
                this._selectedIndex = this._upTabIndex;
                width = this._tabInfoArr[this._downTabIndex].width;
                left = this._tabInfoArr[this._upTabIndex].left;
            } else if (this._downTabIndex > this._selectedIndex) {
                if (this._upTabIndex <= this._selectedIndex) {
                    width = this._tabInfoArr[this._selectedIndex].width;
                    left = this._tabInfoArr[this._selectedIndex].left + this._tabInfoArr[this._downTabIndex].width;
                    this._selectedIndex += 1;
                }
            }
        } else { // to after
            if (this._selectedIndex === this._downTabIndex) {
                this._selectedIndex = this._upTabIndex;
                width = this._tabInfoArr[this._downTabIndex].width;
                left = this._tabInfoArr[this._upTabIndex].left - this._tabInfoArr[this._downTabIndex].width +
                    this._tabInfoArr[this._upTabIndex].width;
            } else if (this._downTabIndex < this._selectedIndex) {
                if (this._upTabIndex >= this._selectedIndex) {
                    width = this._tabInfoArr[this._selectedIndex].width;
                    left = this._tabInfoArr[this._selectedIndex].left - this._tabInfoArr[this._downTabIndex].width;
                    this._selectedIndex -= 1;
                }
            }
        }
        this._tabInfoArr.splice(this._upTabIndex, 0, this._tabInfoArr.splice(this._downTabIndex, 1)[0]);
        this._tabInfoArr.reduce((offsetLeft, tab) => {
            tab.left = offsetLeft;
            return offsetLeft += tab.width;
        }, 0);
        if (width && (left || left === 0)) {
            this.onLineSlide(false, {
                width: width,
                left: left
            });
        }
        this.tabActionSubject$.next({
            type: TabActionType.setActiveTab,
            payload: this._tabInfoArr[this._selectedIndex].id
        });
        this.reSortTab.emit({
            downIndex: this._downTabIndex,
            upIndex: this._upTabIndex,
            selectedIndex: this._selectedIndex
        });
    }
}
