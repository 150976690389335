/**
 * Created by Abner Sui on 04/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum MarketdataActionTypes {
    INITIALIZE = '[MARKETDATA] INITIALIZE',
    INITIALIZE_SUCCESS = '[MARKETDATA] INITIALIZE_SUCCESS',
    INITIALIZE_FAILED = '[MARKETDATA] INITIALIZE_FAILED'
}

export class Initialize implements Action {
    readonly type = MarketdataActionTypes.INITIALIZE;
}

export class InitializeSuccess implements Action {
    readonly type = MarketdataActionTypes.INITIALIZE_SUCCESS;

    constructor(public payload: any) { }
}

export class InitializeFailed implements Action {
    readonly type = MarketdataActionTypes.INITIALIZE_FAILED;

    constructor(public payload: any) { }
}

export type MarketdataActions = Initialize | InitializeSuccess | InitializeFailed;
