/**
 * Created by simon zhao on 11/2/2021.
 * Description:
 * TextAreaConfig Component
 * ------ maintenance history ------
 */
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { IControlConfigComponent, ControlConfigEvents } from './../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';
import { FieldType } from './../../../tamalelibs/models/workflow.model';

@Component({
    selector: 'tam-text-area-config',
    templateUrl: './text-area-config.component.html',
    styleUrls: ['./field-config.component.scss']
})
export class TextAreaConfigComponent implements OnInit, OnDestroy, IControlConfigComponent {
    //#region public properties
    @Input()
    config: FieldConfig;

    @ViewChild('base', { static: false }) base;

    dataType = FieldType.TEXT_AREA;

    staticDefaultValueInvalid = false;

    //#endregion

    //#region private properties

    /**
    * the subscription array that would be unsubscribed on destroying.
    */
    private _destroySubscriptions: Array<Subscription> = [];


    //#endregion

    constructor() { }

    //#region public methods

    getInvalidDetail(): FieldValidationError {
        return this.base.getInvalidDetail();
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    onLineLengthChanged(v: number) {
        this.config.maxlines = v;
        this._validateStaticDefaultValue();
    }

    validate(): boolean {
        const baseValid: boolean = this.base.validate();
        // run local validate
        const localValid = this._validateStaticDefaultValue();
        return baseValid && localValid;
    }

    valueChange(event): void {
        this.config.config.feedbackSubject$.next({
            type: ControlConfigEvents.VALUE_CHANGE,
            payload: this.config,
        });
    }

    valueChangeStaticDefaultValue(event): void {
        this._validateStaticDefaultValue();
        this.valueChange(event);
    }

    //#endregion

    //#region private methods

    private _validateStaticDefaultValue(): boolean {
        this.staticDefaultValueInvalid = false;
        if (this.config.minlength !== null && this.config.minlength !== undefined) {
            if (this.config.staticDefaultValue && this.config.staticDefaultValue.length < this.config.minlength) {
                this.staticDefaultValueInvalid = true;
            }
        }
        if (this.config.maxlength !== null && this.config.maxlength !== undefined) {
            if (this.config.staticDefaultValue && this.config.staticDefaultValue.length > this.config.maxlength) {
                this.staticDefaultValueInvalid = true;
            }
        }
        return !this.staticDefaultValueInvalid;
    }

    //#endregion
}
