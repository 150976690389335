import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IntralinksAccountDialogOpenParam } from './intralinks-account-dialog.view-model';

@Injectable()
export class IntralinksAccountDialogService {

    open$: Subject<any> = new Subject();

    constructor() { }

    open(param: IntralinksAccountDialogOpenParam) {
        this.open$.next(param);
    }
}
