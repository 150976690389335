/**
 * ---------maintenance history-------------
 * 2019.5.22 By Bowen Li: modified the standard order when click the add icon in dashboard detail page: this file need not to submit
 * Update by Marcus Zhao 09/10/2021 add action for change filter list
 * Update by Marcus Zhao 06/10/2022 add DefaultFilterItemsContact for contact filter.
 */

import { Subject } from 'rxjs';
import { adhocDBSelector } from '../../redux/reducers/adhoc.reducer';

export enum FieldEventType {
    select,
}

export enum FieldActionType {
    changeEntityList,
    changeEventList,
    changeRecipientList,
}

export const StandardFilterItems: Array<{ text: string, value: string, type: string, hide?: boolean }> = [
    { text: 'Display Date', value: 'DisplayDate', type: 'standard' },
    { text: 'Entities', value: 'Entities', type: 'standard' },
    { text: 'Entity Type', value: 'EntityType', type: 'standard' },
    { text: 'Note Type', value: 'NoteType', type: 'standard' },
    { text: 'Priority', value: 'Priority', type: 'standard' },
    { text: 'Sentiment', value: 'Sentiment', type: 'standard' },
    { text: 'Source', value: 'Source', type: 'standard' },
    { text: 'Subject', value: 'Subject', type: 'standard' },
    { text: 'Submitted Date', value: 'SubmittedDate', type: 'standard' },
    { text: 'Submitter', value: 'Submitter', type: 'standard' },
];

export const StandardFilterItemsContact: Array<{ text: string, value: string, type: string, hide?: boolean }> = [
    { text: 'Company', value: 'Company', type: 'standard' },
    { text: 'Job Title', value: 'JobTitle', type: 'standard' },
    { text: 'Job Function', value: 'JobFunction', type: 'standard' },
    { text: 'Alias', value: 'Alias', type: 'standard' },
    { text: 'City', value: 'City', type: 'standard' },
    { text: 'Country & State', value: 'Country&State', type: 'standard' },
    { text: 'Former Company', value: 'FormerCompany', type: 'standard' },
];

export const StandardFilterItemsContactForContactList: Array<{ text: string, value: string, type: string, hide?: boolean }> = [
    { text: 'Relationship', value: 'Relationship', type: 'standard' }
];

export const DefaultFilterItemsContact: Array<{ text: string, value: string, type: string, hide?: boolean }> = [
    { text: 'Sync', value: 'Status', type: 'standard' },
    { text: 'Company', value: 'Company', type: 'standard' },
    { text: 'Job Title', value: 'JobTitle', type: 'standard' },
    { text: 'Job Function', value: 'JobFunction', type: 'standard' },
];

export const StandardFilterItemsEvent: Array<{ text: string, value: string, type: string, hide?: boolean }> = [
    { text: 'Entities', value: 'Entities', type: 'standard' },
    { text: 'Participants', value: 'Participants', type: 'standard' },
    { text: 'Source', value: 'Source', type: 'standard' },
    { text: 'Subject', value: 'Subject', type: 'standard' },
];

export const StandardFilterItemsNotificationRule: Array<{ text: string, value: string, type: string, hide?: boolean }> = [
    { text: 'StartTime', value: 'StartTime', type: 'standard' },
    { text: 'EndTime', value: 'EndTime', type: 'standard' },
    { text: 'Entities', value: 'Entities', type: 'standard' },
    { text: 'Participants', value: 'Participants', type: 'standard' },
    { text: 'Source', value: 'Source', type: 'standard' },
    { text: 'Subject', value: 'Subject', type: 'standard' },
    { text: 'Entity type', value: 'EntityType', type: 'standard' },
    { text: 'Priority', value: 'Priority', type: 'standard' },
    { text: 'Sentiment', value: 'Sentiment', type: 'standard' },
    { text: 'Submitter', value: 'Submitter', type: 'standard' },
    { text: 'Team', value: 'Team', type: 'standard' },
    { text: 'Display Date', value: 'DisplayDate', type: 'standard' },
    { text: 'Submitted Date', value: 'SubmittedDate', type: 'standard' }
];

export const RecipientsFilterItemsEvent: Array<{ text: string, value: string, type: string, hide?: boolean }> = [
    { text: 'Specific contacts', value: 'Specific contacts', type: 'standard' },
    { text: 'Specific users', value: 'Specific users', type: 'standard' },
    { text: 'Specific teams', value: 'Specific teams', type: 'standard' },
    { text: 'Custom field', value: 'Custom field', type: 'standard' },
];

export class FieldConfigData {
    standardItem: Array<any>;
    showAdhoc: boolean;
    showSearch: boolean;
    selector?: any;
    otherAdhoc?: Array<any>;
    entityAdhoc?: Array<any>;
    eventAdhoc?: Array<any>;
    constructor() {
        this.showAdhoc = false;
        this.showSearch = false;
        this.selector = adhocDBSelector;
        this.standardItem = StandardFilterItems;
    }
}
export class FieldConfig {
    config?: FieldConfigData;
    events$: Subject<{ type: FieldEventType, payload?: any }>;
    // change filter list
    actions$?: Subject<{ type: FieldActionType, payload?: any }>;
    fieldIndex = 0;
    constructor() {
        this.events$ = new Subject();
        this.actions$ = new Subject();
        this.config = new FieldConfigData();
    }
}
