/**
 * Created by Alan Yang on 8/3/17.
 * Description:
 *
 * ------ maintenance history ------
 *
 * ver 2.0 modified by Yu Zhang on 9/11/17:
 *      add mergeArrayUniqueBy, uniqueArray, and uniqueArrayBy function
 *      use lodash for the implementation, lodash documentation: https://lodash.com/docs/#uniq
 *
 * 9/23/2021 Simon Zhao added a generic sort function.
 */

export const COMMON_DELIMITER = ',';

import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class ArrayHelperService {

    constructor() {
    }

    // array order by start//
    // if name is null, then order as string
    // else it's an object array, order by property name.
    public static orderBy(name) {
        return function (o, p) {
            if (name) {
                let a, b;
                if (typeof o === 'object' && typeof p === 'object' && o && p) {
                    a = o[name];
                    b = p[name];
                    if (a === b) {
                        return 0;
                    }
                    if (typeof a === typeof b) {
                        return a < b ? -1 : 1;
                    }
                    return typeof a < typeof b ? -1 : 1;
                } else {
                    throw new Error(('error'));
                }
            } else {
                if (o === p) {
                    return 0;
                }
                if (typeof o === typeof p) {
                    return o < p ? -1 : 1;
                }
                return typeof o < typeof p ? -1 : 1;
            }
        };
    }

    public static treeNodesOrderBy(type) {
        return function (o, p) {
            if (type === 'relationship') {
                let a, b;
                if (typeof o === 'object' && typeof p === 'object' && o && p) {
                    a = o.relationshipType['name'];
                    b = p.relationshipType['name'];
                    if (a === b) {
                        return o.childEntity['shortName'] < p.childEntity['shortName'] ? -1 : 1;
                    }
                    if (typeof a === typeof b) {
                        return a < b ? -1 : 1;
                    }
                    return typeof a < typeof b ? -1 : 1;
                } else {
                    throw new Error(('error'));
                }
            } else {
                let a, b;
                if (typeof o === 'object' && typeof p === 'object' && o && p) {
                    a = o.childEntity['shortName'];
                    b = p.childEntity['shortName'];
                    if (a === b) {
                        return 0;
                    }
                    if (typeof a === typeof b) {
                        return a < b ? -1 : 1;
                    }
                    return typeof a < typeof b ? -1 : 1;
                } else {
                    throw new Error(('error'));
                }
            }
        };
    }

    static mergeArrayUniqueBy(arr1, arr2, mainField: string = 'id') {
        return _(arr1)                         // start sequence
            .keyBy(mainField)                   // create a dictionary of the 1st array
            .merge(_.keyBy(arr2, mainField))    // create a dictionary of the 2nd array, and merge it to the 1st
            .values()                           // turn the combined dictionary to array
            .value();                           // return the value as an array
    }

    static uniqueArray(arr) {
        return _(arr).uniq().value();
    }

    static uniqueArrayBy(arr, mainField: string = 'id') {
        return _(arr).uniqBy(mainField).value();
    }

    static arrayContains(array, elem) {
        for (let index = 0; index < array.length; ++index) {
            if (this.isEqual(elem, array[index])) {
                return true;
            }
        }
        return false;
    }

    /**
     * case-insensitive comparator function
     * @param valueA 
     * @param valueB 
     * @returns 
     */
    static caseInsensitiveComparator(valueA, valueB) {
        const valueALower = valueA ? valueA.toString().toLowerCase() : '';
        const valueBLower = valueB ? valueB.toString().toLowerCase() : '';

        if (valueALower < valueBLower) {
            return -1;
        } else if (valueALower > valueBLower) {
            return 1;
        } else {
            return 0;
        }
    }

    /**
     * Finds an array member, the given property of which is the maximum within the array.
     * @param arr the source array.
     * @param comparingProp the name of the property for comparing, this property must be comparable, usually is numerical or date.
     */
    static max<T>(arr: Array<T>, comparingProp: string): T {
        return arr.reduce(
            (preItem: T, currentItem: T) => preItem[comparingProp] > currentItem[comparingProp] ? preItem : currentItem
        );
    }

    /**
     * Sorts an array in place.
     * This method mutates the array and returns a reference to the same array.
     * @static
     * @template T
     * @param {Array<T>} arr the source array.
     * @param {string} sortProp the property name used for sorting.
     * @param {boolean} [isAscOrder=true] default to asc order, setting as false the sorting result is in desc order.
     * @memberof ArrayHelperService
     */
    static sort<T>(arr: Array<T>, sortProp: string, isAscOrder: boolean = true, isSortByNumberType: boolean = false) {
        const sortFunc4Num = () => {
            arr.sort((a: T, b: T) => isAscOrder ? a[sortProp] - b[sortProp] : b[sortProp] - a[sortProp]);
        };

        const sortFunc4Str = () => {
            arr.sort((a: T, b: T) => {
                let compareResult = -1;
                const aLower = a[sortProp]?.toString().toLowerCase();
                const bLower = b[sortProp]?.toString().toLowerCase();
                if (aLower === bLower) {
                    compareResult = 0;
                } else if ((isAscOrder && aLower > bLower) || (!isAscOrder && bLower > aLower)) {
                    compareResult = 1;
                }

                return compareResult;
            });
        };

        if (!arr || !Array.isArray(arr)) {
            throw new Error('Invalid array is detected');
        } else if (arr.length === 0 || !arr[0] || arr[0][sortProp] === undefined || arr[0][sortProp] === null) {
            return;
        } else if (typeof (arr[0][sortProp]) === 'number' || isSortByNumberType) {
            sortFunc4Num();
        } else {
            sortFunc4Str();
        }
    }

    static isEqual(value: any, other: any, ignoreArrayOrder: boolean = false): boolean {
        const t1 = typeof value;
        const t2 = typeof other;
        if (t1 === t2) {
            switch (t1) {
                case 'object':
                    if (value === null) {
                        return other === null;
                    } else if (value instanceof Date) {
                        return other instanceof Date && value.getTime() === other.getTime();
                    } else if (Array.isArray(value)) { // Array
                        if (ignoreArrayOrder) {
                            if (Array.isArray(other)) {
                                if (value.length === other.length) {
                                    let result = true;
                                    const usedIndex: Set<number> = new Set();
                                    for (let i = 0; i < value.length; i++) {
                                        let hasTargetValue = false;
                                        for (let j = 0; j < value.length; j++) {
                                            if (!usedIndex.has(j) && this.isEqual(value[i], other[j], ignoreArrayOrder)) {
                                                usedIndex.add(j);
                                                hasTargetValue = true;
                                                break;
                                            }
                                        }
                                        if (!hasTargetValue) {
                                            result = false;
                                            break;
                                        }
                                    }
                                    return result;
                                } else {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        } else {
                            if (Array.isArray(other)) {
                                if (value.length === other.length) {
                                    let result = true;
                                    for (let i = 0; i < value.length; i++) {
                                        if (!this.isEqual(value[i], other[i], ignoreArrayOrder)) {
                                            result = false;
                                            break;
                                        }
                                    }
                                    return result;
                                } else {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        }
                    } else { // Object
                        const keys1 = Object.keys(value);
                        const keys2 = Object.keys(other);
                        if (keys1.length === keys2.length) {
                            let result = true;
                            for (let i = 0; i < keys1.length; i++) {
                                if (!this.isEqual(value[keys1[i]], other[keys1[i]], ignoreArrayOrder)) {
                                    result = false;
                                    break;
                                }
                            }
                            return result;
                        } else {
                            return false;
                        }
                    }
                case 'string':
                case 'number':
                case 'boolean':
                case 'undefined':
                    return value === other;
                default: // Do not support 'symbol', 'function' right now
                    return false;
            }
        } else {
            return false;
        }
    }
}
