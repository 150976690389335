/**
 * Created by Alan Yang on 3/16/2017
 * Description:
 *   TSItem is the base class for just about every Item.
 *
 * ---------maintenance history-------------
 */
import { TSGuid } from './ts-guid.model';
export class TSItem {
    id: TSGuid;
}
