/**
 * Created by Yu Zhang on 3/22/17.
 * Description: a list of threads, containing insertion, searching, sorting, and grouping methods
 *
 * ------ maintenance history ------
 */
import { TSItem } from './ts-item.model';
import { Thread } from './thread.model';

export class ThreadList extends TSItem {
    count = 0;
    next = '';
    threads: Thread[] = [];
}
