/**
 * Created by Daniel on 10/30/18.
 * Description:
 *
 * ------ maintenance history ------
 * 2018/12/23 Yoyo Add 'addSuffixCopyToName' method helping file name duplication
 * 2019/05/26 Bowen preview pdf files by service instead of store
 * 12/27/2022 Simon Zhao adjusted the preview function to support the preview of workflow files.
 */
import { Injectable } from '@angular/core';
import { AuthHelperService } from './auth-helper.service';
import { ToastService } from '../widgets/toast/toast.service';
import { NotificationStyles, NotificationOptions } from '../widgets/notification/notification.model';
import { Router } from '@angular/router';
import { WindowRef } from './window-ref';
import { CopyService } from './copy.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { AppConfig } from '../tamalelibs/models/app-config.model';
import { map, switchMap, take } from 'rxjs/operators';
import { PDFPreviewExtension } from '../tamalelibs/constants/business.constants';
import { HttpClient } from '@angular/common/http';
import { ManifestXMLFile, OfficeAddInManifest } from '../tamalelibs/models/office-addin.model';
import { businessConstants } from '../constants/business.constants';
import { OfficeAddinService } from './office-addin-service';


@Injectable({
    providedIn: 'root'
})
export class FileHelperService {
    downloadFile$: Subject<any> = new Subject();
    downloadCsvFile$: Subject<any> = new Subject();
    previewPdfFile$: Subject<any> = new Subject();

    constructor(
        private _authHelper: AuthHelperService,
        private _http: HttpClient,
        private _toast: ToastService,
        private _router: Router,
        private _winRef: WindowRef,
        private _copyService: CopyService,
        private _officeAddinService: OfficeAddinService
    ) { }
    /**
     * public function      : modify file name preventing duplicated names
     * @param nameCounts : check name groups {name:count}
     * @param filename      : checked name
     */
    public addSuffixCopyToName(nameCounts, filename) {
        const existingNames = Object.keys(nameCounts).reduce((acc, item) => {
            if (nameCounts[item] > 0) {
                acc.push(item);
            }
            return acc;
        }, []);
        const noExtensionFileName = filename.substring(0, filename.lastIndexOf('.'));
        const extension = filename.substring(filename.lastIndexOf('.'));
        // Rename duplicate filename
        let fileContainer = [];
        let regName = noExtensionFileName.replace(/\(/g, '\\(');
        regName = regName.replace(/\)/g, '\\)');
        regName = regName.replace(/\[/g, '\\[');
        regName = regName.replace(/\]/g, '\\]');
        regName = regName.replace(/\{/g, '\\{');
        regName = regName.replace(/\}/g, '\\}');
        regName = regName.replace(/\+/g, '\\+');
        regName = regName.replace(/\$/g, '\\$');
        regName = regName.replace(/\^/g, '\\^');

        const reg = new RegExp('^' + regName + '( - Copy( (\\(\\d+\\))?)?)?' + extension + '$');
        for (let i = 0; i < existingNames.length; i++) {
            const currentFileNameServer = existingNames[i];
            if (reg.test(currentFileNameServer)) {
                if (currentFileNameServer === noExtensionFileName + extension) {
                    fileContainer.push('0');
                } else if (currentFileNameServer === noExtensionFileName + ' - Copy' + extension) {
                    fileContainer.push('1');
                } else {
                    const indexBegin = currentFileNameServer.lastIndexOf('(');
                    const indexEnd = currentFileNameServer.lastIndexOf(')');
                    if (indexBegin > 0 && indexEnd > 0 && indexEnd > indexBegin) {
                        const num = currentFileNameServer.substring(indexBegin + 1, indexEnd);
                        fileContainer.push(num);
                    }
                }
            }
        }
        if (fileContainer.length === 0) {
            filename = noExtensionFileName + extension;
        } else {
            fileContainer = fileContainer.sort(this._sortNumber);
            filename = this._renameDuplicatedFile(fileContainer, filename, noExtensionFileName, extension);
        }
        return filename;
    }

    /**
     * public function      : remove special characters backend won't support
     * @param filename      : checked name
     */
    public cleanFileName(filename) {
        // replace all forbidden characters
        return filename.replace(/(\\|\/|\:|\*|\?|\"|\<|\>|\|)/g, '');
    }

    public copyFileLink(fileId) {
        const win = this._winRef.nativeWindow;
        if (fileId) {
            const prefixIndex = win.location.href.indexOf(this._router.url);
            const fileUrl = win.location.href.substring(0, prefixIndex) + '/filedownload/' + fileId;
            const success = this._copyService.copy(fileUrl);
            let toast: NotificationOptions;
            if (success) {
                toast = {
                    message: 'File link is copied to clipboard',
                    style: NotificationStyles.Success
                };
            } else {
                toast = {
                    message: 'File link copy failed',
                    style: NotificationStyles.Error
                };
            }
            this._toast.notify(toast);
        }
    }

    /**
     * public function      : download file by file url
     * @param fileUrl       : direct link of the file
     */
    public downloadFile(fileUrl: string, directlyDownload?: boolean) {
        try {
            if (!directlyDownload) {
                fileUrl = fileUrl + '?userid=' + this._authHelper.getUserId();
            }
            window.location.href = fileUrl;
        } catch (e) {
            console.error('Download attachment failed!');
        }
    }

    /**
     * support download file to browser.
     * @param data
     * @param name
     */
    public downloadFunction(data: ArrayBuffer, name: string) {
        const file = new Blob([data]);
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        const fileName = name;
        link.setAttribute('href', url);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', fileName);
        link.click();
        link.remove();
    }

    /**
    * public function      : download file by file url
    * @param fileUrl       : direct link of the file
    */
    public downloadCsvFile() {
        try {
            const endPoint = AppConfig.userEndpoint + '/template';
            const fileUrl = endPoint + '?userid=' + this._authHelper.getUserId();
            window.location.href = fileUrl;
        } catch (e) {
            console.error('Download attachment failed!');
        }
    }

    /**
    * public function      : new rest api for download file.
    * @param fileUrl       : direct link of the file
    */
    public downloadFileNew(fileName: string, fileExtension: string, fileId: string) {
        const tmpUrl = '/restapi/2.0/file/' + fileId + '?userid=' + this._authHelper.getUserId() + '&previewtopdf=false' + '&filename=' + encodeURIComponent(fileName) + '&extension=' + fileExtension;
        try {
            window.location.href = tmpUrl;
        } catch (e) {
            console.error('Download attachment failed!');
        }
    }

    /**
     * public function      : the data is a complete json
     * config               : json
     */
    public isCompleteJSON(config: string): boolean {
        if (typeof config === 'string') {
            try {
                const obj = JSON.parse(config);
                if (config.indexOf('{') > -1) {
                    return true;
                } else {
                    return false;
                }
            } catch {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * Determines whether a file is previewable by its extension.
     * @param f the given file info
     * @returns A flag indicating whether the given file is previewable.
     */
    public isFilePreviewable(f: { extension: string }): boolean {
        return f && f.extension && PDFPreviewExtension.includes(f.extension.replace('.', '').toLowerCase());
    }

    /**
     * public function      : check file by it's extension
     * file                 : the file name
     * extensions           : the extensions. Ex. .jpg|.png
     */
    public isSpecifiedExtensionFile(fileName: string, extensions: string): boolean {
        const strRegex = '(.*[' + extensions.toLowerCase() + '])$';
        const reg = new RegExp(strRegex);
        if (reg.test(fileName.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    }

    public getAddinManifestFiles(): Observable<OfficeAddInManifest> {
        const self = this;
        const officeAddInManifest = new OfficeAddInManifest();
        const observable = new Observable(subscriber => {
            subscriber.next();
            subscriber.complete();
        });

        return observable.pipe(
            switchMap(() => {
                const observables = [];
                observables.push(self.getManifestFile(officeAddInManifest, businessConstants.officeAddIn.manifestFile.outlook));
                observables.push(self.getManifestFile(officeAddInManifest, businessConstants.officeAddIn.manifestFile.word));
                observables.push(self.getManifestFile(officeAddInManifest, businessConstants.officeAddIn.manifestFile.powerPoint));
                observables.push(self.getManifestFile(officeAddInManifest, businessConstants.officeAddIn.manifestFile.excel));
                observables.push(self.getManifestFile(officeAddInManifest, businessConstants.officeAddIn.manifestFile.event));
                return forkJoin(observables).pipe(
                    take(1),
                    map(() => {
                        return officeAddInManifest;
                    })
                );
            })
        );
    }

    /**
     * Get xml document to memory
     * @param officeAddInManifest
     * @param fileName
     * @returns
     */
    public getManifestFile(officeAddInManifest: OfficeAddInManifest, fileName: string): Observable<OfficeAddInManifest> {
        const self = this;
        return new Observable(subscriber => {
            self._http
                .get(`/tamaleweb/assets/addinManifest/${fileName}`, { responseType: 'text' })
                .subscribe(data => {
                    if (data) {
                        data = data.replace(/{server name}/g, window.location.host);
                        officeAddInManifest.files.push(new ManifestXMLFile(fileName, data));
                    }
                    subscriber.next(officeAddInManifest);
                    subscriber.complete();
                });
        });
    }

    public getIconFileName(fileExt: string) {
        let iconFileName = 'unknown';

        switch (fileExt.toLowerCase()) {
            case 'csv':
            case 'xls':
            case 'xlsx':
                iconFileName = 'excel.svg';
                break;
            case 'doc':
            case 'docx':
            case 'rtf':
                iconFileName = 'word.svg';
                break;
            case 'msg':
            case 'eml':
                iconFileName = 'email.svg';
                break;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'bmp':
            case 'gif':
            case 'tif':
                iconFileName = 'image.svg';
                break;
            case 'pdf':
                iconFileName = 'pdf.svg';
                break;
            case 'mp3':
            case 'wav':
            case 'wma':
                iconFileName = 'audio.svg';
                break;
            case 'ppt':
            case 'pptx':
                iconFileName = 'ppt.svg';
                break;
            case 'txt':
            case 'log':
                iconFileName = 'text.svg';
                break;
            default:
                iconFileName = 'file.svg';
                break;
        }

        return iconFileName;
    }

    public getIconName(fileExt: string) {
        let iconName = 'unknown';

        switch (fileExt.toLowerCase()) {
            case 'csv':
            case 'xls':
            case 'xlsx':
                iconName = 'file-excel';
                break;
            case 'doc':
            case 'docx':
            case 'rtf':
                iconName = 'file-word';
                break;
            case 'msg':
            case 'eml':
                iconName = 'file-mail';
                break;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'bmp':
            case 'gif':
            case 'tif':
                iconName = 'file-image';
                break;
            case 'pdf':
                iconName = 'file-pdf';
                break;
            case 'mp3':
            case 'wav':
            case 'wma':
                iconName = 'file-audio';
                break;
            case 'ppt':
            case 'pptx':
                iconName = 'file-ppt';
                break;
            case 'txt':
            case 'log':
                iconName = 'file-text';
                break;
            default:
                iconName = 'file';
                break;
        }

        return iconName;
    }

    /**
     * Preview a given file.
     * @param fileId the file id
     * @param fileLink a direct link to the file
     * @param fileExtension the extension of file, e.x: 'png'
     * @param fileName the file name
     * @param directlyDownload a flag indicating whether the file should be provided without any further convesion.
     * @param isUsedFile a flag indicating whether the file is archived.
     * @param isFileIDIncluded a flag indicating whether the fileLink contains the file id.
     */
    public previewFile(fileId, fileLink, fileExtension, fileName, directlyDownload?: boolean, isUsedFile = false, isFileIDIncluded = false) {
        this.previewPdfFile$.next({
            fileId: fileId,
            fileLink: fileLink,
            fileExtension: fileExtension,
            fileName: fileName,
            directlyDownload: directlyDownload,
            isUsedFile: isUsedFile,
            isFileIDIncluded: isFileIDIncluded
        });
    }

    /**
     * Previe the given file in a new tab or window.
     * @param fileId the file id
     * @param fileLink a direct link to the file
     * @param fileExtension the extension of file, e.x: 'png'
     * @param fileName the file name
     * @param isUsedFile a flag indicating whether the file is archived.
     * @param isFileIDIncluded a flag indicating whether the filelink contains the file id.
     */
    public previewFileInNewTab(fileId, fileLink, fileExtension, fileName, isUsedFile = false, isFileIDIncluded = false) {
        this.previewPdfFile$.next({
            fileId: fileId,
            fileLink: fileLink,
            fileExtension: fileExtension,
            fileName: fileName,
            directlyDownload: false,
            isUsedFile: isUsedFile,
            isFileIDIncluded: isFileIDIncluded,
            isPreviewInNewTab: true
        });
    }

    private _renameDuplicatedFile(fileContainer, fileName, noExtensionFileName, extension) {

        let index = -1;
        let name = '';
        for (let i = 0; i < fileContainer.length; i++) {
            if (fileContainer[i] !== i.toString()) {
                index = i;
                break;
            }
        }
        if (index === -1) {
            index = fileContainer.length;
        }
        if (index === 0) {
            name = noExtensionFileName + extension;
        } else if (index === 1) {
            name = noExtensionFileName + ' - Copy' + extension;
        } else {
            name = noExtensionFileName + ' - Copy (' + index + ')' + extension;
        }
        return name;
    }

    private _sortNumber(a, b) {
        return a - b;
    }
}
