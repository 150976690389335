/**
 * Created by Yoyo Fang on 10/02/2018.
 * Description:
 *  Dev helper for redux store debugging. This is only available on non-production environments.
 *  When debug, modify the configs as needed, but do not commit the modification.
 * Reference:
 *  https://github.com/ngrx/platform/blob/master/docs/store-devtools/README.md
 * ------ maintenance history ------
 */
import { ModuleWithProviders } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

export class ReduxDevToolsService {
    public static modulePerEnvironment: ModuleWithProviders<any> | undefined[]
        = environment.production ? [] : StoreDevtoolsModule.instrument({
            maxAge: 30,
            logOnly: false,
            name: 'TAMALE WEB',
            // monitor
            // actionSanitizer
            // stateSanitizer
            // serialize
            // actionsBlacklist
            // actionsWhitelist
            // predicate
        });
}
