/**
 * created by Yu Zhang on 7/5/18
 * Description:
 *
 * ------ maintenance history ------
 * 07/2/2019 Marcus Zhao - Add CREATE_ENTITY_ALL_SUCCESS and EDIT_ENTITY_ALL_SUCCESS for merge entity request
 */
export const CLEAR_ENTITY_MSG = 'CLEAR_ENTITY_MSG';
export const CREATE_ENTITY = 'CREATE_ENTITY';
export const CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';
export const CREATE_ENTITY_FAILURE = 'CREATE_ENTITY_FAILURE';
export const CREATE_EXTRAOROFFICE = 'CREATE_EXTRAOROFFICE';
export const CREATE_EXTRAOROFFICE_SUCCESS = 'CREATE_EXTRAOROFFICE_SUCCESS';
export const CREATE_EXTRAOROFFICE_FAILURE = 'CREATE_EXTRAOROFFICE_FAILURE';
export const CREATE_MEMBER = 'CREATE_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAILURE = 'CREATE_MEMBER_FAILURE';
export const EDIT_ENTITY = 'EDIT_ENTITY';
export const EDIT_ENTITY_SUCCESS = 'EDIT_ENTITY_SUCCESS';
export const EDIT_ENTITY_FAILURE = 'EDIT_ENTITY_FAILURE';
export const CREATE_ENTITY_ALL_SUCCESS = 'CREATE_ENTITY_ALL_SUCCESS';
export const EDIT_ENTITY_ALL_SUCCESS = 'EDIT_ENTITY_ALL_SUCCESS';
