/**
 * Created by Abner Sui on 01/22/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

// blast email list related actions
export enum BlastEmailViewBlastEmailListActionTypes {
    SET_SELECTED_INDEX = '[BLASTEMAIL][BLASTEMAIL_LIST] SET_SELECTED_INDEX',
    SET_CURRENT_PAGE_INDEX = '[BLASTEMAIL][BLASTEMAIL_LIST] SET_CURRENT_PAGE_INDEX', // uselss, could use RELOAD and LOAD_MORE
    SET_LAYOUT_COLLAPSE = '[BLASTEMAIL][BLASTEMAIL_LIST] SET_COLLSPASE_MODE',
    RELOAD = '[BLASTEMAIL][BLASTEMAIL_LIST] RELOAD',
    RELOAD_SUCCESS = '[BLASTEMAIL][BLASTEMAIL_LIST] RELOAD_SUCCESS',
    RELOAD_FAILED = '[BLASTEMAIL][BLASTEMAIL_LIST] RELOAD_FAILED',
    LOAD_MORE = '[BLASTEMAIL][BLASTEMAIL_LIST] LOAD_MORE',
    LOAD_MORE_SUCCESS = '[BLASTEMAIL][BLASTEMAIL_LIST] LOAD_MORE_SUCCESS',
    LOAD_MORE_FAILED = '[BLASTEMAIL][BLASTEMAIL_LIST] LOAD_MORE_FAILED',
    DELETE_BLASTEMAIL = '[BLASTEMAIL][BLASTEMAIL_LIST] DELETE_BLASTEMAIL',
    DELETE_BLASTEMAIL_SUCESS = '[BLASTEMAIL][BLASTEMAIL_LIST] DELETE_BLASTEMAIL_SUCESS',
    DELETE_BLASTEMAIL_FAILED = '[BLASTEMAIL][BLASTEMAIL_LIST] DELETE_BLASTEMAIL_FAILED',
}
export class BlastEmailAction {
    constructor(public payload, prefixName: string, prefixValue: string) {
        if (prefixName && prefixValue) {
            this[prefixName] = prefixValue;
        }
    }
}
export class BlastEmailViewBlastEmailListSetSelectedIndex extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.SET_SELECTED_INDEX;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListSetCurrentPageIndex extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.SET_CURRENT_PAGE_INDEX;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListSetLayoutCollapse extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.SET_LAYOUT_COLLAPSE;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListReload extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.RELOAD;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListReloadSuccess extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.RELOAD_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListReloadFailed extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.RELOAD_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListLoadMore extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.LOAD_MORE;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListLoadMoreSuccess extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.LOAD_MORE_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListLoadMoreFailed extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.LOAD_MORE_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListDelete extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.DELETE_BLASTEMAIL;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListDeleteSucess extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.DELETE_BLASTEMAIL_SUCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewBlastEmailListDeleteFailed extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewBlastEmailListActionTypes.DELETE_BLASTEMAIL_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export type BlastEmailViewBlastEmailListActions = BlastEmailViewBlastEmailListSetSelectedIndex | BlastEmailViewBlastEmailListSetCurrentPageIndex | BlastEmailViewBlastEmailListSetLayoutCollapse
    | BlastEmailViewBlastEmailListReload | BlastEmailViewBlastEmailListReloadSuccess
    | BlastEmailViewBlastEmailListReloadFailed | BlastEmailViewBlastEmailListLoadMore | BlastEmailViewBlastEmailListLoadMoreSuccess | BlastEmailViewBlastEmailListLoadMoreFailed | BlastEmailViewBlastEmailListDelete | BlastEmailViewBlastEmailListDeleteSucess | BlastEmailViewBlastEmailListDeleteFailed;

// blast email view related actions
export enum BlastEmailViewActionTypes {
    GET_BLASTEMAIL_BODY = '[BLASTEMAIL][BLASTEMAIL_DETAIL] GET_BLASTEMAIL_BODY',
    GET_BLASTEMAIL_BODY_SUCCESS = '[BLASTEMAIL][BLASTEMAIL_DETAIL] GET_BLASTEMAIL_BODY_SUCCESS',
    GET_BLASTEMAIL_BODY_FAILED = '[BLASTEMAIL][BLASTEMAIL_DETAIL] GET_BLASTEMAIL_BODY_FAILED',
    SET_QUERY_INFO = '[BLASTEMAIL][QUERY_INFO] SET_VALUE',
    SET_KEY_WORD = '[BLASTEMAIL][KEYWORD] SET_VALUE',
    SET_SORT_INFO = '[BLASTEMAIL][SORT_INFO]_SET_VALUE',
    SET_AGGRID_COLUMN_STATUS = '[BLASTEMAIL][AGGRID] SET_COLUMN_STATUS',
}

export class BlastEmailViewGetBlastEmailBody extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewActionTypes.GET_BLASTEMAIL_BODY;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewGetBlastEmailBodySuccess extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewActionTypes.GET_BLASTEMAIL_BODY_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewGetBlastEmailBodyFailed extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewActionTypes.GET_BLASTEMAIL_BODY_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewSetQueryInfo extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewActionTypes.SET_QUERY_INFO;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewSetKeyWord extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewActionTypes.SET_KEY_WORD;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewSetSortInfo extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewActionTypes.SET_SORT_INFO;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailViewSetAGGridColumnStatus extends BlastEmailAction implements Action {
    readonly type = BlastEmailViewActionTypes.SET_AGGRID_COLUMN_STATUS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export type BlastEmailViewActions = BlastEmailViewSetQueryInfo | BlastEmailViewSetKeyWord | BlastEmailViewSetSortInfo | BlastEmailViewSetAGGridColumnStatus;

// blast email refineby related actions
export enum BlastEmailRefinebyActionTypes {
    LOAD = '[BLASTEMAIL][REFINEBY] LOAD',
    LOAD_SUCCESS = '[BLASTEMAIL][REFINEBY] LOAD_SUCCESS',
    LOAD_FAILED = '[BLASTEMAIL][REFINEBY] LOAD_FAILED',
    SET_SELECTED_VALUE = '[BLASTEMAIL][REFINEBY] SET_SELECTED_VALUE',
    SET_CATEGORY = '[BLASTEMAIL][REFINEBY] SET_CATEGORY',
    SET_CATEGORY_ORDER = '[BLASTEMAIL][REFINEBY] SET_CATEGORY_ORDER',
    SET_CATEGORY_STATUS = '[BLASTEMAIL][REFINEBY] SET_CATEGORY_STATUS',
}

export class BlastEmailRefinebyLoad extends BlastEmailAction implements Action {
    readonly type = BlastEmailRefinebyActionTypes.LOAD;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailRefinebyLoadSucess extends BlastEmailAction implements Action {
    readonly type = BlastEmailRefinebyActionTypes.LOAD_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailRefinebyLoadFailed extends BlastEmailAction implements Action {
    readonly type = BlastEmailRefinebyActionTypes.LOAD_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailRefinebySetSelectedValue extends BlastEmailAction implements Action {
    readonly type = BlastEmailRefinebyActionTypes.SET_SELECTED_VALUE;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailRefinebySetCategory extends BlastEmailAction implements Action {
    readonly type = BlastEmailRefinebyActionTypes.SET_CATEGORY;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailRefinebySetCategoryOrder extends BlastEmailAction implements Action {
    readonly type = BlastEmailRefinebyActionTypes.SET_CATEGORY_ORDER;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class BlastEmailRefinebySetCategoryStatus extends BlastEmailAction implements Action {
    readonly type = BlastEmailRefinebyActionTypes.SET_CATEGORY_STATUS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export type BlastEmailRefinebyActions = BlastEmailRefinebyLoad | BlastEmailRefinebyLoadSucess | BlastEmailRefinebyLoadFailed | BlastEmailRefinebySetSelectedValue | BlastEmailRefinebySetCategory | BlastEmailRefinebySetCategoryOrder | BlastEmailRefinebySetCategoryStatus;
