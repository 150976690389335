
/**
 * Created by Yu Zhang on 9/19/17.
 * Description:
 *
 * ------ maintenance history ------
 *   12/10/2018 Alex change to prepare data for kendo tree view
 *   2019/8/28  Bowen   change relationship type: add create and delete
 * Updated by Daniel Wang on 6/18/2024, Add function named getRelationshipListByEntityId(entityId) to show relationship list.
 */

import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';

import { AppConfig } from '../models/app-config.model';
import { TransportService } from './transport.service';
import { AdvFilterHelperService } from './adv-filter-helper.service';
import { Relationship } from '../models/relationship.model';
import { ArrayHelperService } from './array-helper.service';
import { delay } from 'lodash';
import { map } from 'rxjs/operators';
import { EntityBrief } from '../models/entity-brief.model';
import { RelationshipType } from '../models/relationship-type.model';

@Injectable()
export class RelationshipService {
    contactIdObservable: Subject<string> = new Subject<string>();
    entityIdObservable: Subject<string> = new Subject<string>();

    constructor(private _transportService: TransportService, private _advFilterHelper: AdvFilterHelperService) { }

    public static parseKendoTreeView(id: string, name: string, shortName: string, relationships: any[]) {
        const treeViewData: any[] = [];
        let treeNode = {};

        if (relationships) {
            const entityNodes: Array<Relationship> = new Array<Relationship>();
            const contactNodes: Array<Relationship> = new Array<Relationship>();
            const teamNodes: Array<Relationship> = new Array<Relationship>();
            const teamMemberNodes: Array<Relationship> = new Array<Relationship>();
            const itemList: Array<Relationship> = relationships;

            itemList.forEach(element => {
                if (element.relationshipType.name === 'is in team') {
                    if (element.childEntity.type.name !== 'Contact') {
                        teamNodes.push(element);
                    } else {
                        teamMemberNodes.push(element);
                    }
                } else {
                    if (element.childEntity.type.name !== 'Contact') {
                        entityNodes.push(element);
                    } else {
                        contactNodes.push(element);
                    }
                }
            });

            // append root node
            treeViewData.push({
                'id': id,
                'text': shortName,
                'level': '1',
                'shortName': shortName,
                'longName': name,
                'link': 'javascript:void(0)'
            });

            // append entity
            if (entityNodes.length > 0) {
                entityNodes.sort(ArrayHelperService.treeNodesOrderBy('relationship'));

                // append entity root node
                if (entityNodes.length > 0) {
                    treeViewData.push({
                        'id': 'ENTITY_GROUP',
                        'text': 'Entities',
                        'level': '2',
                        'parentId': id,
                        'link': 'javascript:void(0)'
                    });
                }

                const arrayEntityRelationshipId = [];

                entityNodes.forEach(element => {
                    // append relationship types
                    const relationshipTreeNodeId = `ENTITY_GROUP-${element.relationshipType.id}`;
                    treeNode = {
                        'id': relationshipTreeNodeId,
                        'text': element.relationshipType.name,
                        'level': '3',
                        'parentId': 'ENTITY_GROUP',
                        'link': 'javascript:void(0)'
                    };

                    if (arrayEntityRelationshipId.indexOf(element.relationshipType.id) === -1) {
                        treeViewData.push(treeNode);

                        arrayEntityRelationshipId.push(element.relationshipType.id);
                    }
                    // append child nodes
                    treeNode = {
                        'id': element.childEntity.id,
                        'text': element.childEntity.shortName,
                        'level': '4',
                        'isLeafNode': true,
                        'shortName': element.childEntity.shortName,
                        'longName': element.childEntity.name,
                        'parentId': relationshipTreeNodeId,
                        'link': RelationshipService._getNodeLink(element.childEntity)
                    };
                    treeViewData.push(treeNode);
                });
            }

            // append team
            if (teamNodes.length > 0) {
                // entityNodes.sort(ArrayHelperService.orderBy('text'));

                // append team root node
                if (teamNodes.length > 0) {
                    treeViewData.push({
                        'id': 'TEAM_NOTE_GROUP',
                        'text': 'Teams',
                        'level': '2',
                        'parentId': id,
                        'link': 'javascript:void(0)'
                    });
                }
                teamNodes.sort(ArrayHelperService.treeNodesOrderBy('childName'));
                const arrayTeamNodesId = [];
                teamNodes.forEach(element => {
                    // append child nodes
                    treeNode = {
                        'id': element.childEntity.id,
                        'text': element.childEntity.shortName,
                        'level': '3',
                        'isLeafNode': true,
                        'shortName': element.childEntity.shortName,
                        'longName': element.childEntity.name,
                        'parentId': 'TEAM_NOTE_GROUP',
                        'link': RelationshipService._getNodeLink(element.childEntity)
                    };
                    if (arrayTeamNodesId.indexOf(element.childEntity.id) === -1) {
                        treeViewData.push(treeNode);

                        arrayTeamNodesId.push(element.childEntity.id);
                    }
                });
            }

            // append contact
            if (contactNodes.length > 0) {
                contactNodes.sort(ArrayHelperService.treeNodesOrderBy('relationship'));

                // append contact root node
                if (contactNodes.length > 0) {
                    treeViewData.push({
                        'id': 'CONTACT_GROUP',
                        'text': 'Contacts',
                        'level': '2',
                        'parentId': id,
                        'link': 'javascript:void(0)'
                    });
                }
                const arrayContactRelationshipId = [];

                contactNodes.forEach(element => {
                    // append relationship types
                    const relationshipTreeNodeId = `CONTACT_GROUP-${element.relationshipType.id}`;
                    treeNode = {
                        'id': relationshipTreeNodeId,
                        'text': element.relationshipType.name,
                        'level': '3',
                        'parentId': 'CONTACT_GROUP',
                        'link': 'javascript:void(0)'
                    };

                    if (arrayContactRelationshipId.indexOf(element.relationshipType.id) === -1) {
                        treeViewData.push(treeNode);

                        arrayContactRelationshipId.push(element.relationshipType.id);
                    }

                    // append child nodes
                    treeNode = {
                        'id': element.childEntity.id,
                        'text': element.childEntity.shortName,
                        'level': '4',
                        'isLeafNode': true,
                        'shortName': element.childEntity.shortName,
                        'longName': element.childEntity.name,
                        'parentId': relationshipTreeNodeId,
                        'link': RelationshipService._getNodeLink(element.childEntity)
                    };
                    treeViewData.push(treeNode);
                });
            }

            // append team member
            if (teamMemberNodes.length > 0) {
                teamMemberNodes.sort(ArrayHelperService.treeNodesOrderBy('childName'));

                // append team member root node
                if (teamMemberNodes.length > 0) {
                    treeViewData.push({
                        'id': 'TEAM_MEMBER_GROUP',
                        'text': 'Team Members',
                        'level': '2',
                        'parentId': id,
                        'link': 'javascript:void(0)'
                    });
                }
                const arrayTeamMemberNodesId = [];
                teamMemberNodes.forEach(element => {
                    // append team member child nodes
                    treeNode = {
                        'id': element.childEntity.id,
                        'text': element.childEntity.shortName,
                        'level': '3',
                        'isLeafNode': true,
                        'shortName': element.childEntity.shortName,
                        'longName': element.childEntity.name,
                        'parentId': 'TEAM_MEMBER_GROUP',
                        'link': RelationshipService._getNodeLink(element.childEntity)
                    };
                    if (arrayTeamMemberNodesId.indexOf(element.childEntity.id) === -1) {
                        treeViewData.push(treeNode);

                        arrayTeamMemberNodesId.push(element.childEntity.id);
                    }
                });
            }
        } else if (shortName) {
            // append node when it has no relationship with other entities and contacts
            treeNode = {
                'id': 'id',
                'text': shortName,
                'level': '1',
                'shortName': shortName,
                'longName': name,
                'link': 'javascript:void(0)'
            };
            treeViewData.push(treeNode);
        }
        return treeViewData;
    }

    /**
     * direct to contact detail page if the type of the node is contact,otherwise direct to entity
     * param entity
     */
    private static _getNodeLink(entity) {
        let nodeLink = '';
        if (entity && entity['type'] && entity['type']['name'] && entity['type']['name'] === 'Contact') {
            nodeLink = 'contact/' + entity.id;
        } else {
            nodeLink = 'entity/' + entity.id;
        }
        return nodeLink;
    }

    getEntitiesByRelationshipType(entityIdArr: any, relationshipTypeId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.relationshipEndpoint}`;
        const params = {
            expand: 'child-entity;entity&fields=child-entity;',
            outputformat: 'json'
        };
        const advFilterArray = [];
        for (let m = 0; m < entityIdArr.length; m++) {
            if (entityIdArr[m].trim().length > 0) {
                const combineAdvFilter = [];
                combineAdvFilter.push(this._advFilterHelper.relationshipTypeFilter(relationshipTypeId));
                combineAdvFilter.push(this._advFilterHelper.parentEntityFilter(entityIdArr[m]));
                advFilterArray.push(this._advFilterHelper.joinFiltersByAnd(combineAdvFilter));
            }
        }
        const advFilter = this._advFilterHelper.joinFiltersByOr(advFilterArray);
        if (advFilter && advFilter.length > 0) {
            params['advfilter'] = advFilter;
        }
        const options = {
            header: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    createRelationships(relationshipArray): Observable<any> {
        const observables$ = [];
        for (let i = 0; i < relationshipArray.length; i++) {
            observables$.push(
                this.createRelationship(relationshipArray[i]));
        }
        return combineLatest(observables$);
    }

    createMultipleRelationship(relationship): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const url = `${AppConfig.newRelationshipEndpoint}`;
        const options = {
            headers: headers,
        };

        const formData = new URLSearchParams();
        formData.set('append', relationship.isAppend);
        formData.set('child-entity', relationship.childEntity);
        formData.set('createreciprocal', relationship.isCreateReciprocal);
        formData.set('parent-entity', relationship.parentEntity);
        formData.set('rel-type', relationship.relType);
        formData.set('return-failed', 'true');
        return this._transportService.post(url, formData.toString(), options);
    }

    creatIsInTeamOrTopicMemberRelationship(relationship): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const url = `${AppConfig.newRelationshipEndpoint}teamortopicmember/`;
        const options = {
            headers: headers,
            params: {
                'parent-entity-type': JSON.stringify(relationship.parentEntity),
                'userid': relationship.childEntity
            }
        };
        return this._transportService.put(url, null, options);
    }

    createRelationship(relationship): Observable<any> {
        const params = {
            'append': relationship.appendRelationship,
            'child-entity': relationship.currentControlValues.join(','),
            'createreciprocal': true,
            'parent-entity': relationship.dependenceControlValues.join(','),
            'rel-type': relationship.relTypeId
        };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const url = `${AppConfig.newRelationshipEndpoint}`;
        const options = {
            headers: headers,
            params: params
        };
        // According to debbug with  server developer and  PM confirmed. Fix this bug [TAM-37036] with a delay for each request.
        return this._transportService.post(url, null, options);
    }

    // will delete relationship according to the specific relationship id
    deleteRelationship(relationshipId: string, isDeletereciprocal: boolean): Observable<any> {
        const url = `${AppConfig.newRelationshipEndpoint}${relationshipId}/`;
        const params = {
            'deletereciprocal': isDeletereciprocal,
        };
        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            params: params
        };
        return this._transportService.delete(url, options);
    }

    // will delete the relationship according to rel-type id
    deleteRelationshipType(relationship): Observable<any> {
        const params = {
            'deletereciprocal': relationship.deletereciprocal,
            'parent-entity': relationship.parentEntity,
            'rel-type': relationship.relTypeId
        };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const url = `${AppConfig.newRelationshipEndpoint}`;
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.delete(url, options);
    }

    createValidRelationship(parentId: string, relatinshipTypeId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const url = `${AppConfig.relationshipTypeEndpoint}` + `${relatinshipTypeId}` + '/valid-relationships/';
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('child', parentId);
        formData.set('parent', parentId);
        formData.set('reciprocal', relatinshipTypeId);
        return this._transportService.post(url, formData.toString(), options);
    }

    /**
     * get relationship list by entity id
     * @param entityId
     * @returns
     */
    getRelationshipListByEntityId(entityId: string) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.newRelationshipEndpoint}entity/` + entityId;
        const params = {
            outputformat: 'json',
            expand: 'child-entity;entity;relationship-type',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options).pipe(
            map(response => {
                return this._mapRelationshipList(response);
            })
        );
    }

    private _mapRelationshipList(data) {
        if (!data) {
            return null;
        }
        const relationships = [];
        const relationshipList = data['relationship-list'];
        relationshipList.forEach(item => {
            const relationship = new Relationship();
            if (item['relationship-type'].data.id === RelationshipType.IS_IN_TEAM.id) {
                // to exchange the child and parent if the relationship type is 'is in team'
                relationship.childEntity = new EntityBrief(item['parent-entity'].data.id, item['parent-entity'].data['long-name']);;
                relationship.childEntity.shortName = item['parent-entity'].data['short-name'];
                relationship.parentEntity = new EntityBrief(item['child-entity'].data.id, item['child-entity'].data['long-name']);
                relationship.parentEntity.shortName = item['child-entity'].data['short-name'];
                relationship.relationshipId = item.id;
                relationship.relationshipType = new RelationshipType(item['relationship-type'].data.id, item['relationship-type'].data.name);
            } else {
                relationship.childEntity = new EntityBrief(item['child-entity'].data.id, item['child-entity'].data['long-name']);
                relationship.childEntity.shortName = item['child-entity'].data['short-name'];
                relationship.parentEntity = new EntityBrief(item['parent-entity'].data.id, item['parent-entity'].data['long-name']);
                relationship.parentEntity.shortName = item['parent-entity'].data['short-name'];
                relationship.relationshipId = item.id;
                relationship.relationshipType = new RelationshipType(item['relationship-type'].data.id, item['relationship-type'].data.name);
            }
            relationships.push(relationship);
        });
        return relationships;
    }
}
