<div class="notification-editor-container" loading-spin [isLoading]="loading" [loadingSize]="'big'">
    <div class="header-container">
        <label>{{ title }}</label>
        <smart-icon name="close" (click)="onSlideSheetClose()" class="light"></smart-icon>
    </div>
    <div class="notification-items-container">
        <div class="input-container tam-input-tip">
            <div class="tam-form-field">
                <label class="required">{{'general.name' | stringLiterals}}</label>
                <input [(ngModel)]="currNotification['name']" class="tam-input tam-textbox item-content"
                    (blur)="configChanged()"
                    [ngClass]="{'tam-input-bad':formErrorMsg.isNameRequired||formErrorMsg.nameExceedMaxLength||formErrorMsg.nameDuplicated}"
                    (input)="inputNameValueChange()" type="text" placeholder="Please enter a unique name"
                    value="{{ currNotification['name'] }}" [formControl]="name">
                <div class="tam-form-help required-field increase-space" *ngIf="formErrorMsg.nameDuplicated">
                    {{'general.name_already_exist' | stringLiterals}}
                </div>
                <div class="tam-form-help required-field increase-space" *ngIf="formErrorMsg.isNameRequired">
                    {{'general.this_is_required' | stringLiterals}}
                </div>
                <div class="tam-form-help required-field increase-space" *ngIf="formErrorMsg.nameExceedMaxLength">
                    {{'general.name_exceed_maximum' | stringLiterals: '128'}}
                </div>
            </div>
            <div class="tam-form-field reduce-space">
                <label>{{'general.description' | stringLiterals}}</label>
                <textarea [(ngModel)]="currNotification['description']" (ngModelChange)="validateDescription($event)"
                    (blur)="configChanged()" class="description-container"
                    [ngClass]="{'tam-input-bad': formErrorMsg.descriptionExceedMaxLength}"></textarea>
                <div class="required-field" *ngIf="formErrorMsg.descriptionExceedMaxLength">
                    {{'notification.description_exceed_1024_characters' | stringLiterals}}
                </div>
            </div>
            <label class="required">{{'notification.type_of_notification' | stringLiterals}}</label>
            <div class="tam-form-field">
                <multi-sel-dropdown-filter class="control-content" [dropdownConfig]='typeDropdownConfig'>
                    <ng-template #multiCustomContent>
                        <div class="selected-type text-ellipsis" (click)='onClickType($event)' (blur)="configChanged()">
                            {{typeName}}
                        </div>
                    </ng-template>
                </multi-sel-dropdown-filter>
                <div class="tam-form-help required-field reduce-space" *ngIf="!isTypeChecked">
                    {{'general.this_is_required' | stringLiterals}}
                </div>
            </div>
            <label class="required bold">{{'notification.when_to_send_first_notification' |
                stringLiterals}}</label>
            <div class="tam-form-field">
                <div class="item-content">
                    <div class="specific-option-container">
                        <tam-radio class="radio-entry" value="ENTRY" [(ngModel)]="specificActionType" [disabled]="false"
                            (change)="onSpecificActionTypeChange()">
                        </tam-radio>
                        <label class="lbltop">When</label>
                        <div class="checkbox-row item-left" tid="entry_action_checkbox">
                            <multi-sel-dropdown-filter [dropdownConfig]="entryActionConfig">
                                <ng-template #multiCustomContent>
                                    <div class="selected-item">
                                        <a (click)="onSelectEntryActionClick($event)">{{entryAction}}</a>
                                    </div>
                                </ng-template>
                            </multi-sel-dropdown-filter>
                        </div>
                        <label class="lbltop item-left">an entry</label>
                    </div>
                    <div class="tam-form-help required-field increase-space" *ngIf="!isEntryActionControlSelected">
                        {{'general.this_is_required' | stringLiterals}}
                    </div>
                    <tam-radio class="radio" value="TIME" [(ngModel)]="specificActionType"
                        [disabled]="isAPISubscriptionSelected" (change)="onSpecificActionTypeChange()"
                        (blur)="configChanged()">
                    </tam-radio>
                    <label>{{'notification.at_a_specific_time' | stringLiterals}}</label>
                </div>
            </div>
            <div class="tam-form-field reduce-space" *ngIf="filterRulesConfig&&filterRulesConfig.filtersConfigList">
                <label class="required bold">{{'notification.rules_to_trigger_notification' | stringLiterals}}</label>
                <div class="item-content">
                    <filter-panel [config]="filterRulesConfig"></filter-panel>
                    <div class="field-selector-container">
                        <tam-field-selector *ngIf="fieldRulesConfig" [config]="fieldRulesConfig"
                            (blur)="configChanged()">
                            <ng-template #customBtn>
                                <span
                                    class="add-filter-btn hover-link">{{'general.add_rule_filter'|stringLiterals}}</span>
                            </ng-template>
                        </tam-field-selector>
                    </div>
                </div>
                <div class="tam-form-help required-field" *ngIf="isEventTypeUnChecked">
                    {{'general.this_is_required' | stringLiterals}}
                </div>
            </div>
            <label class="required bold">{{'emailDigest.recipients' | stringLiterals}}</label>
            <div class="tam-form-field" *ngIf="filterRecipientsConfig&&filterRecipientsConfig.filtersConfigList">
                <div class="item-content">
                    <filter-panel [config]="filterRecipientsConfig"></filter-panel>
                    <div class="field-selector-container">
                        <tam-field-selector *ngIf="fieldRecipientsConfig" [config]="fieldRecipientsConfig"
                            (blur)="configChanged()">
                            <ng-template #customBtn>
                                <span
                                    class="add-filter-btn hover-link">{{'notification.add_more'|stringLiterals}}</span>
                            </ng-template>
                        </tam-field-selector>
                    </div>
                </div>
                <div class="tam-form-help required-field" *ngIf="!isRecipientsExist">
                    {{'general.this_is_required' | stringLiterals}}
                </div>
            </div>
            <div class="options reduce-space">
                <label class="bold">{{'general.options' | stringLiterals}}</label>
                <div class="options-items">
                    <div class="specific-option-container" *ngIf="!isSpecificEntryActionTriggered">
                        <label class="timeLeft lbltop">First</label>
                        <kendo-dropdownlist [popupSettings]="{appendTo: 'component'}"
                            class="kendo-dropdownlist item-left" [(ngModel)]='selectedDateType' [data]="dayTypeList"
                            (blur)="configChanged()" [textField]="'text'" [valueField]="'value'">
                        </kendo-dropdownlist>
                        <label class="lbltop item-left">after</label>
                        <div class="checkbox-row item-left" tid="entity_type_checkbox">
                            <tam-single-select-dropdown-filter [dropdownConfig]="dateTypeAdHocConfig">
                                <ng-template #customContent>
                                    <div class="selected-item">
                                        <a (click)="onSelectAdHocDateTypeClick($event)"
                                            (blur)="configChanged()">{{dateType}}</a>
                                    </div>
                                </ng-template>
                            </tam-single-select-dropdown-filter>
                        </div>
                        <label class="lbltop item-left">at</label>
                        <div class="select-time">
                            <time-select-dropdown (clickss)="timeEndClick($event)" (timeBlur)="endTimeBlur($event)"
                                [(myModel)]="selectTime" (timeModelChange)="onSpecificTimeChange($event)"
                                (blur)="configChanged()">
                            </time-select-dropdown>
                        </div>
                    </div>
                    <div class="tam-form-help required-field increase-space" *ngIf="!isDateControlSelected">
                        {{'general.this_is_required' | stringLiterals}}
                    </div>
                    <div class="scheduled-items" *ngIf="!isSpecificEntryActionTriggered">
                        <label>{{'notification.reminder_frequency' | stringLiterals}}</label>
                        <kendo-dropdownlist [popupSettings]="{appendTo: 'component'}"
                            class="kendo-dropdownlist-element item-left section" [(ngModel)]='selectedFrequency'
                            (blur)="configChanged()" [data]="frequencyList" [textField]="'text'" [valueField]="'value'">
                        </kendo-dropdownlist>
                        <label>{{'general.time_zone' | stringLiterals}}</label>
                        <div class="section">
                            <kendo-dropdownlist class="kendo-dropdownlist-element"
                                [popupSettings]="{appendTo: 'component'}" [filterable]="false" [data]="timezoneList"
                                [textField]="'name'" [valueField]="'id'" (blur)="configChanged()"
                                [value]="selectedTimezone" (valueChange)="onChangeTimezone($event)">
                            </kendo-dropdownlist>
                        </div>
                        <div>
                            <label class="criteria">{{'notification.criteria_to_stop_sending_notification' |
                                stringLiterals}}</label>
                            <div kendoTooltip position="top" class="tooltip"
                                [title]="'notification.criteria_info_tip' |stringLiterals ">
                                <smart-icon name="info" class="info">
                                </smart-icon>
                            </div>
                        </div>
                        <div class="section">
                            <kendo-dropdownlist [popupSettings]="{appendTo: 'component'}"
                                class="kendo-dropdownlist-element item-left" [(ngModel)]='selectedCriteria'
                                [data]="criteriaList" (blur)="configChanged()" [textField]="'text'"
                                [valueField]="'value'">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <label>{{'notification.send_as_group_or_individual_email' | stringLiterals}}</label>
                    <div class="section">
                        <kendo-dropdownlist [popupSettings]="{appendTo: 'component'}"
                            class="kendo-dropdownlist-element item-left" [(ngModel)]='selectedSendType'
                            [data]="sendTypeList" (blur)="configChanged()" [textField]="'text'" [valueField]="'value'">
                        </kendo-dropdownlist>
                    </div>
                    <label>{{'general.privacy' | stringLiterals}}</label>
                    <div class="section">
                        <kendo-dropdownlist [popupSettings]="{appendTo: 'component'}"
                            class="kendo-dropdownlist-element item-left" [(ngModel)]='selectedPrivacy'
                            [data]="privacyData" [textField]="'text'" [valueField]="'value'">
                        </kendo-dropdownlist>
                    </div>
                    <label>{{'notification.use_submitter_secure_email_address' | stringLiterals}}</label>
                    <div class="section">
                        <kendo-switch [checked]="isUseSubmitterSecureEmailAddress" onLabel="Yes" offLabel="No"
                            (valueChange)="onChangeUseSubmitterSecureEmailAddress($event)"></kendo-switch>
                    </div>
                    <label>{{'notification.include_submitter_in_bcc_field' | stringLiterals}}</label>
                    <div class="section">
                        <kendo-switch [checked]="isIncludeSubmitterInBCC" onLabel="Yes" offLabel="No"
                            (valueChange)="onChangeIncludeSubmitterInBCC($event)"></kendo-switch>
                    </div>
                </div>
            </div>
            <div class="notification_content">
                <label class="section bold">{{'notification.notification_content' |
                    stringLiterals}}</label>
                <div class="section notification_content_label">
                    <label>{{'notification.include_the_entity_list_in_the_subject' |
                        stringLiterals}}</label>
                    <kendo-dropdownlist [popupSettings]="{appendTo: 'component'}"
                        class="kendo-dropdownlist-element item-left" [(ngModel)]='selectedEntityListInSubject'
                        [data]="includeEntityListInSubjectDropdownConfigData" [textField]="'text'"
                        [valueField]="'value'">
                    </kendo-dropdownlist>
                </div>
                <label>{{'notification.content_to_be_included' | stringLiterals}}</label>
                <div class="section">
                    <kendo-dropdownlist [popupSettings]="{appendTo: 'component'}"
                        class="kendo-dropdownlist-element item-left" [(ngModel)]='selectedContent' [data]="contentData"
                        [textField]="'text'" [valueField]="'value'">
                    </kendo-dropdownlist>
                </div>
                <label>{{'notification.entry_detail_and_attachments' | stringLiterals}}</label>
                <div class="section">
                    <multi-sel-dropdown-filter class="control-content" [dropdownConfig]='entryDetailAttachmentsConfig'>
                        <ng-template #multiCustomContent>
                            <div class="selected-type text-ellipsis" (click)='onEntryDetailAttachmentsClick($event)'>
                                {{entryDetailAttachmentsNames}}
                            </div>
                        </ng-template>
                    </multi-sel-dropdown-filter>
                </div>
                <label>{{'notification.actions_to_be_included' | stringLiterals}}</label>
                <div class="section">
                    <multi-sel-dropdown-filter class="control-content" [dropdownConfig]='actionsDropdownConfig'>
                        <ng-template #multiCustomContent>
                            <div class="selected-type text-ellipsis" (click)='onActionClickType($event)'
                                (blur)="configChanged()">
                                {{actionTypeName}}
                            </div>
                        </ng-template>
                    </multi-sel-dropdown-filter>
                </div>
            </div>
        </div>
        <div class="button-container">
            <button tam-button [tamType]="'primary'" [tamSize]="'normal'" class="button" (click)="onSubmitClick()"
                [disabled]="formErrorMsg.descriptionExceedMaxLength" [tamLoading]="isProgressing"
                [disabled]="isProgressing">
                {{ title === 'Create new notification rule' ? 'Create' : 'Update'}}
            </button>
            <button tam-button [tamType]="'tertiary'" [tamSize]="'normal'" class="button" (click)="onSlideSheetClose()">
                {{'general.cancel' | stringLiterals}}
            </button>
        </div>
    </div>