/**
 * Created by Abner Sui on 1/5/2021
 * -------------------------------------
 */

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlType } from '../../../tamalelibs/models/template-control.model';
import { FieldType } from '../../../tamalelibs/models/workflow.model';
import { ControlConfigEvents, IControlConfigComponent } from '../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';

@Component({
    selector: 'tam-single-text-dropdown-config',
    templateUrl: './single-text-dropdown-config.component.html',
    styleUrls: ['./field-config.component.scss']
})
export class SingleTextDropdownConfigComponent implements OnInit, AfterViewInit, OnDestroy, IControlConfigComponent {

    @Input()
    config: FieldConfig;

    @ViewChild('base', { static: false }) base;
    @ViewChild('kendo', { static: false }) kendo;

    dataType = FieldType.TEXT;
    sourcePlaceHolder: string;
    staticDefaultValueData: Array<string> = [];
    source: string;
    sourceInvalid = false;

    private _controlType = ControlType.SINGLE_TEXT_DROP_DOWN;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor() { }

    getInvalidDetail(): FieldValidationError {
        const baseValidationDetail: FieldValidationError = this.base.getInvalidDetail();
        baseValidationDetail.isSourceInvalid = !this._validateSource();
        return baseValidationDetail;
    }

    ngOnInit(): void {
        // this._destroySubscriptions.push();
        if (this.config.source && this.config.source.length) {
            this.source = this.config.source.join('\n');
            this.staticDefaultValueData = this.config.source;
        }
        this.sourcePlaceHolder = `option 1\noption 2\noption 3\n...`;
    }

    ngAfterViewInit(): void {
        if (this.config.showValidateInfo) {
            this.validate();
        }
    }

    ngOnDestroy(): void {
        if (this._destroySubscriptions) {
            this._destroySubscriptions.forEach(item => item.unsubscribe());
        }
    }

    // for UT
    addSubscriptionToDestroy(subscription: Subscription): void {
        if (!this._destroySubscriptions) {
            this._destroySubscriptions = [];
        }
        this._destroySubscriptions.push(subscription);
    }

    filterStaticDefaultValue(event): void {
        this.staticDefaultValueData = this.config.source.filter(item => item.toLowerCase().indexOf(event.toLowerCase()) > -1);
    }

    focusStaticDefaultValue(): void {
        this.kendo.toggle(true);
    }

    validate(): boolean {
        const baseValid: boolean = this.base.validate();
        // run local validate
        const localValid = this._validateSource();
        return baseValid && localValid;
    }

    valueChange(event): void {
        this.config.config.feedbackSubject$.next({
            type: ControlConfigEvents.VALUE_CHANGE,
            payload: this.config,
        });
    }

    valueChangeSource(event): void {
        if (this.source && this.source.length > 0) {
            this.staticDefaultValueData = this.config.source = this.source.split('\n').filter(item => item);
        } else {
            this.staticDefaultValueData = this.config.source = [];
        }
        if (this.config.staticDefaultValue) {
            if (this.config.source.indexOf(this.config.staticDefaultValue) < 0) {
                this.config.staticDefaultValue = null;
            }
        }
        this._validateSource();
        this.valueChange(event);
    }

    private _validateSource(): boolean {
        this.sourceInvalid = !this.config.source || this.config.source.length === 0;
        return !this.sourceInvalid;
    }

}
