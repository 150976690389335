/**
 * Created by Marcus on 10/12/2021.
 * Description:
 *
 * ------ maintenance history ------
 * Update by marcus 03/12/2022 add ExchangeSyncStatus for sync contact action.
 */

import { Action } from '@ngrx/store';


export enum ExchangeActionTypes {
    CHANGE_EXCHANGE_STATUS_SUCCESS = '[EXCHANGE] CHANGE_EXCHANGE_STATUS_SUCCESS',
    CHANGE_EXCHANGE_SYNC_STATUS_SUCCESS = '[EXCHANGE] CHANGE_EXCHANGE_SYNC_STATUS_SUCCESS',

}

export class ExchangeStatus implements Action {
    readonly type = ExchangeActionTypes.CHANGE_EXCHANGE_STATUS_SUCCESS;
    payload: boolean;
    constructor(payload: boolean) {
        this.payload = payload;
    }
}

export class ExchangeSyncStatus implements Action {
    readonly type = ExchangeActionTypes.CHANGE_EXCHANGE_SYNC_STATUS_SUCCESS;
    payload: boolean;
    constructor(payload: boolean) {
        this.payload = payload;
    }
}


export type ExchangeActions = ExchangeStatus | ExchangeSyncStatus;

