/**
 * Created by Alex Xia on 23/04/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { combineReducers, createSelector, Action } from '@ngrx/store';
import { DataList } from '../../tamalelibs/models/data-list.model';
import { Thread } from '../../tamalelibs/models/thread.model';
import { ResearchViewLayoutActions, ResearchViewLayoutActionTypes, ResearchViewThreadListActionTypes, ResearchViewActionTypes, ResearchViewActions, ResearchViewThreadListActions, ResearchViewRefinebyActions, ResearchViewRefinebyActionTypes } from '../actions/research-view.actions';
import { AppState } from '..';
import { FiltersConfig } from '../../tamalelibs/components/filter-config';
import { DraftListActions, DraftViewActionTypes } from '../actions/draft-view.actions';

export function draftListItemsReducer(state: DataList<Thread> = new DataList<Thread>(), action: DraftListActions) {
    switch (action.type) {
        case DraftViewActionTypes.RELOAD_SUCCESS:
            return action.payload;
        case DraftViewActionTypes.LOAD_MORE_SUCCESS: {
            // below logic is used to prevent duplicated data in the list
            // the basic logic is load previous page with the next page and doing data comparaion
            if (action.payload.pageIndex === 2) {
                return action.payload.threads;
            } else {
                const threads = Object.assign(new DataList<Thread>(), state);
                threads.splice((action.payload.pageIndex - 2) * action.payload.pageSize, action.payload.pageSize); // remove the previous page

                // generate set from existing liste
                const threadMap = new Map();
                threads.forEach((thread, index) => {
                    threadMap.set(thread.id, index);
                });

                action.payload.threads.forEach((thread: Thread) => {
                    if (threadMap.has(thread.id)) {
                        return;
                    }
                    threads.push(thread);
                });

                threads.totalCount = action.payload.threads.totalCount;
                threads.hasNext = action.payload.threads.hasNext;

                return threads;
            }
        }
        default:
            return state;
    }
}

export function draftListErrorReducer(state: any, action: DraftListActions) {
    switch (action.type) {
        case DraftViewActionTypes.RELOAD_FAILED:
        case DraftViewActionTypes.LOAD_MORE_FAILED:
            return action.payload;
        default:
            return state;
    }
}

export function queryInfoReducer(state: any = {}, action: DraftListActions) {
    switch (action.type) {
        case DraftViewActionTypes.SET_QUERY_INFO:
            return action.payload;
        default:
            return state;
    }
}

export function sortInfoReducer(state: any = { sortBy: 'displayDate', sortOrder: 'desc' }, action: DraftListActions) {
    switch (action.type) {
        case DraftViewActionTypes.SET_SORT_INFO:
            return action.payload;
        default:
            return state;
    }
}

export const getDraftViewState = (state: AppState) => state.draftView;
export const getDraftListState = createSelector(getDraftViewState, (state: any) => state.draftList);
export const getDraftListSortInfoState = createSelector(getDraftViewState, (state: any) => state.sortInfo);
export const getDraftListQueryInfoState = createSelector(getDraftViewState, (state: any) => state.queryInfo);

const draftViewReducer = combineReducers({
    draftList: draftListItemsReducer,
    sortInfo: sortInfoReducer,
    queryInfo: queryInfoReducer,
});

export function getDraftViewRedcuer(state, action) {
    return draftViewReducer(state, action);
}
