<div class="container tam-form-field" [ngClass]="{'has-error':requireInvalid}">
    <div class="tam-form-label label-area">
        <field-label [label]="config.label||config.field.fieldDefinition.name"
            [tooltip]="config.description||config.field.fieldDefinition.description" [isRequired]="config.required"
            labelClassList="field-name body-3 text-ellipsis"></field-label>
    </div>
    <tam-file-upload class="tam-textbox field-control" *ngIf="config.editable" [config]="fileUploadConfig">
    </tam-file-upload>
    <div class="tam-form-help" *ngIf="config.editable&&requireInvalid&&config.required">
        {{'general.required_error_message' | stringLiterals}}
    </div>
    <tam-file-info class="field-value body-2" *ngIf="!config.editable" [config]="config.field.value" [isDefaultToPreview]="true">
    </tam-file-info>
</div>