/**
 * Created by Daniel on 11/01/2018.
 * Description:
 * ------ maintenance history ------
 * 11/12/2018   Yoyo    Add login constants
 * 11/25/2022   Holly   Add validReg constants
 *
 */

import { StringLiteralsPipe } from '../pipes/translate.pipe';
import { BriefObject } from '../tamalelibs/models/brief-object';

export const ResearchView = {
    topListPageSize: 100,
    sideListPageSize: 100,
    cacheBlockSize: 100
};
// validReg
export const validReg = {
    // to valid email
    emailReg: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
    // to valid phone
    countryFullReg: /(\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1))?(\d{7,11})/,
    phoneWith10DigitsReg: /^([0-9]{10})$/
};

// login/admin-login constants
export const AdminUserNames = ['admin', 'localIT'];
export const SSOCookiesKey = 'cookieInfo';

export const businessConstants = {
    // general
    common: {
        adhoc: 'adhoc',
        appAlertWindow: 'app-alert-window',
        changeDetectorDestroyedIdentifier: 'destroyed',
        configuration: 'configuration',
        contact: 'contact',
        currentUser: '(current user)',
        entry: 'entry',
        helpUrl: 'https://community.advent.com/producthelp?p=/products/tamale/help/tamale/basics/overview_tamale.htm',
        layoutDeleteFailed: 'Layout is deleted failed.',
        layoutDeleteSuccessfully: 'Layout successfully deleted.',
        navigateToErrorPage: '/error',
        none: 'None',
        noDataStyle: {
            flex: 'flex',
            none: 'none'
        },
        position: {
            right: 'right',
            left: 'left'
        },
        printPreviewEmailDigestFrameId: 'tamale-print-preview-email-digest-frame-000000',
        printPreviewFrameId: 'tamale-print-preview-frame-000000',
        renameSuccessfully: 'rename sucessfully.',
        standard: 'standard',
        status: 'Status',
        toobarButtonVerticalTemplate: {
            'moreRich': {
                'buttons': ['bold', 'italic', 'underline', 'textColor', 'backgroundColor',
                    'formatUL', 'formatOL', 'outdent', 'indent',
                    'fontFamily', 'fullscreen', 'fontSize', 'strikeThrough', 'clearFormatting', 'paragraphFormat', 'align',
                    'quote', 'insertLink', 'insertImage', 'insertTable', 'fontAwesome',
                    'insertHR', 'undo', 'redo',
                    'lineHeight', 'subscript', 'superscript', 'specialCharacters'],
                'buttonsVisible': 11
            },
        },
        // number
        titleMaxLength: 32,
        // image regex
        imgRex: /<img\s[^>]*?src\s*=\s*['\"]([^'\"]*?)['\"][^>]*?>/gi,
        // src regex
        srcReg: /src=[\'\"]?([^\'\"]*)[\'\"]?/i,
    },
    componentType: {
        entityDetail: 'EntityDetail'
    },
    screenType: {
        auditLog: 'auditlog',
        contact: 'contact',
        dashboard: 'dashboard',
        emailDigest: 'emailDigest',
        entity: 'entity',
        event: 'event',
        home: 'home',
        intralink: 'intralinks',
        livewire: 'feeds',
        research: 'research',
        settings: 'settings',
        workflow: 'workflow',
    },
    tip: 'Required field',
    iconAddress: {
        blue: './assets/images/mark-normal-blue.svg',
        blueSelected: './assets/images/mark-selected-blue.svg',
        yellow: './assets/images/mark-contact-normal.svg',
        yellowSelected: './assets/images/mark-contact-selected.svg',
        red: './assets/images/mark-normal-red.svg',
        redSelected: './assets/images/mark-selected-red.svg',
        gray: './assets/images/marker-gray.svg',
        graySelected: './assets/images/marker-gray-selected.svg',
        wathet: './assets/images/mark-event-normal.svg',
        wathetSelected: './assets/images/mark-event-selected.svg'
    },
    mapString: {
        multipleResult: 'Multiple Results'
    },
    impactedConfigTabs: {
        associated: 'Associated entities and entries',
        configurations: 'Impacted configurations'
    },
    lableSmall: {
        x: 12,
        y: 22
    },
    lableLarge: {
        x: 59,
        y: 11
    },
    fontFamily: {
        textSmall: 'Source Sans Pro SemiBold Regular',
        textLarge: 'Source Sans Pro Bold',
    },
    mapFilter: {
        isBlank: 'is Blank',
        notBlank: 'Not Blank',
        blank: 'Blank'
    },
    // the map between the type code and the type name of the data source.
    dataSourceTypeMap: {
        1: 'Flat File',
        2: 'SQL MDL',
        3: 'Oracle MDL'
    },
    currentUserField: {
        componentType: '',
        controlID: 'SUBMITTER',
        label: 'Current User'
    },
    dropdownConfig: {
        pageSize: 10
    },

    // admin console
    adminPage: {
        adminCredentialId: 'a3d539c059e045469edaccb4639c79d9',
    },

    // alertWindow
    alertWindow: {
        warnTitle: 'You have unsaved changes',
        warnContent: 'Are you sure you want to discard all your changes?'
    },

    // audit log
    auditLog: {
        Id: '3f9954bcd4734a05a49c3a2f3bbe6333',
        Caption: 'Audit Log'
    },

    // contact
    contactScreen: {
        closeContactScreen: 'CloseContactScreen',
        deleteTabMsg: ' is removed from your contact screen.',
    },
    Contacts: {
        Contact_Template_Id: 'b515d57c-1d0c-440b-b6ec-4a5333b2784a',
        contacts: 'contacts',
        Contacts: 'Contacts',
        MaxNumber: 100000000
    },
    addressType: {
        contact: 'Contact',
        entity: 'Entity',
        google: 'Google',
        duplicate: 'Duplicate',
        event: 'Event'
    },

    // context menu
    contextMenu: {
        renamedTab: 'Rename Tab',
        closeTab: 'Close Tab'
    },

    dataType: {
        eventType: 'eventtype',
        noteType: 'notetype',
        entryType: 'entrytype'
    },

    // dashboard
    dataTypeList: [
        { id: '0', value: 'Text' },
        { id: '1', value: 'Number' },
        { id: '2', value: 'Currency' },
        { id: '3', value: 'Percent' },
        { id: '4', value: 'Ratio' }
    ] as Array<BriefObject>,
    dashboard: {
        screenMenuName: 'Dashboard',
        screenMenuTid: 'screen_dashboard',
        screenMenuIconName: 'dashboard',
        field: {
            entry_file: 'entry__file',
            entry_file_special_guid: 'ba34ra056tee4acaaddefff44rfbe'
        }
    },
    displayAsList: [
        { id: '0', value: 'Full address' },
        { id: '1', value: 'Street' },
        { id: '2', value: 'City' },
        { id: '3', value: 'State/Province' },
        { id: '4', value: 'Country' }
    ] as Array<BriefObject>,
    displayList: [
        { id: '-1', value: 'ID' },
        { id: '0', value: 'Short Name' },
        { id: '1', value: 'Long Name' },
        { id: '2', value: 'Alias' }
    ] as Array<BriefObject>,
    refreshDashboard: 'REFRESH_DASHBOARD',

    // dialog
    dialogInfo: {
        name: 'name',
        description: 'description',
        isPublic: 'isPublic',
        defaultHomeLayout: 'defaultHomeLayout'
    },

    // dualList
    dualList: {
        userNotAllowedAccessTitle: 'Users/Teams not allowed access',
        userAllowedAccessTitle: 'Users/Teams allowed access',
        placeHolderText: 'Search User or Teams',
        dataTextField: 'name',
        dataValueField: 'id'
    },

    // entity
    entity: {
        shortName: 'shortName',
        name: 'name',
        entityType: {
            Team: 'Team'
        }
    },

    // entity screen
    entityScreen: {
        closeEntityScreen: 'CloseEntityScreen',
        deleteTabMsg: ' is removed from your entity screen.',
        activityDashboard: {
            id: '445dd11da3d44d6b93cd7898c374b51d',
            name: 'ActivityDashboard',
            type: 'dashboard',
            editable: false,
            deletable: false,
        }
    },

    // entry
    EntryTypes: {
        Deposited_Blast_Email_Id: '61d45c9281484c1da3ce3c2e044f74c5',
        note: 'Note',
        event: 'Event',
    },
    note: {
        deleteSuccessfully: 'Note(s) successfully deleted.',
        sideNoteFollowRule: 'sideNoteFollowRule',
        defaultSidenoteType: {
            firstNoteId: 'firstNote',
            firstNote: 'First note',
            latestNote: 'Latest note/sidenote'
        },
        AppendEntitySuccess: 'Append entity(s) successfully.',
        AppendEntityPartFailed: ' was(were) failed to append entity(s).',
        AppendEntityFailed: 'Failed to append entity(s).'
    },

    // error
    errorMessage: {
        ERR_NAME_EXISTED: 'ERR_NAME_EXISTED',
        ERR_FIRM_SETTING_ERROR: 'ERR_FIRM_SETTING_ERROR', // for intralink invalid username and password
        ERR_ARGUMENT_INVALID: 'ERR_ARGUMENT_INVALID',
        ERR_INTRALINKS_SERVER: 'ERR_INTRALINKS_SERVER'
    },

    // event
    eventScreen: {
        prefix: 'CalendarEvents',
        defaultEventScreenKey: 'eventView', // default screen key
        eventScreenId: '3f9954bcd4734a05a49c3a2f3bbe652b',
        allEventsCaption: 'All Events',
        eventsCaption: 'Events'
    },
    event: {
        deleteSuccessfully: 'Event(s) successfully deleted.',
    },

    // global config
    globalConfig: {
        entryConfig: 'entryConfig',
        intralinks: 'intralinks',
        systemConfig: 'systemConfig',
        workflow: 'workflow'
    },

    // help key-link mapping
    helpKeyContainer: {
        home: 'home',
        research: 'researchView',
        dashboard: 'dashboard',
        auditlog: 'auditTrail',
        entities: 'viewEntity',
        entity: 'entityDetail',
        contacts: 'contacts',
        calendar: 'calendar',
        'email-digest': 'emailDigest',
        workflow: 'workflow',
        entry: 'noteDetail',
        event: 'noteDetail',
        '/contact/': 'contactDetail',
        users: 'users',
        'auto-tag': 'autoTaggingSettings',
        'email-settings': 'emailServerSettings',
        exchange: 'exchangeServerIntegration',
        notifications: 'notificationSettings',
        'feeds/intralinks': 'intralinks',
        data: 'dataManagement',
        'admin-console/intralinks': 'adminConsoleIntralinksSettings',
        default: 'defaultPage'
    },

    // home screen
    homeScreen: {
        newResearchTabName: '(Research View)',
        singleConfigurationName: 'config',
        screenIdConnectionIdentifier: '_',
        prefixNewResearchViewID: 'forUIStore_',
        deleteTabMsg: ' is removed from your Home screen.',
        newLayoutName: 'new_layout',
        pageTypeText: 'HOME_SCREEN',
        saveLayout: {
            createTitle: 'New Layout',
            editTitle: 'Edit Detail',
            type: 'Layout'
        }
    },
    defaultHomeScreenId: 'd426f1fa0d804de5bca411d5d593642c',
    createNewLayoutForShare: 'CreateNewLayoutForShare',
    changeHomeScreenLayout: 'ChangeLayout',
    loadAllHomeScreenLayouts: 'LoadAllHomeScreenLayouts',

    // intralink screen
    intralinkScreen: {
        menuOption: {
            id: 'intralink',
            caption: 'SS&C Intralinks',
            type: 'feeds',
            icon: { name: '', style: '' },
            routerLink: null,
            items: null,
            tid: '',
            canAdd: true,
            useWidget: true,
            hide: true
        },
        createMenuOption: {
            id: 'intralink_add',
            caption: 'Connect an Account',
            type: 'add',
            icon: { name: 'add', style: '' },
            routerLink: null,
            items: [],
            tid: '',
            canAdd: false,
            useWidget: true
        },
        accountDialog: {
            createTitle: 'New SS&C Intralinks Account',
            editTitle: 'Edit SS&C Intralinks Account',
        },
        mappingDialog: {
            undeletableTitle: 'These values are required to submit the entry, but not required for mapping.'
        }
    },
    intralinksManage: {
        serverUrl: 'serverUrl',
        uiUrl: 'uiUrl',
    },

    // login usage report
    loginUsageReport: {
        Id: '3f9954bcd4734a05a49c3a2f3bbe6666',
        Caption: 'Login Usage Report'
    },

    // map tile
    agmApiKey: 'AIzaSyATyD3BGmf8_mdKCJIjmWURQb926zK8qF0',
    mapTileErrorMessage: {
        removeAutocompleteError: StringLiteralsPipe.translate('general.required_error_message'),
        emptyAutocompleteError: 'Please enter information for each field.',
        noResult: 'Sorry, the route cannot be calculated.',
        turnOffMapTile: 'The feature is turned off, to enable please contact administrator.'
    },

    // notification
    notification: {
        saveScreen: {
            editSuccessfullyMsg: ' successfully updated.',
            createSuccessfullyMsg: ' successfully saved.'
        },
        homeScreen: {
            editSuccessfullyMsg: ' layout successfully shared.',
            createSuccessfullyMsg: ' layout successfully saved.'
        },
        intralinks: {
            editSuccessfullyMsg: ' successfully updated.',
            createSuccessfullyMsg: ' successfully created.'
        },
        mdlConfig: {
            successfulMsg: ' configuration saved successfully.'
        }
    },

    // office add-in
    officeAddIn: {
        subjectMaxLength: 250,
        depositType: 'depositType',
        closeEntityFly: 'closeEntityFly',
        contactdialog: 'contactdialog',
        entitydialog: 'entitydialog',
        closeContactFly: 'closeContactFly',
        downloadManifestError: 'Please use the domain name to access Tamale Web and try again.',
        entityFly: 'entityFly',
        contactFly: 'contactFly',
        dialogBoxCloseCode: 12006,
        gotUser: 'gotUser',
        manifestFileZipName: 'Office_Addins_Manifest.zip',
        manifestFile: {
            outlook: 'manifest.outlook.xml',
            word: 'manifest.word.xml',
            excel: 'manifest.excel.xml',
            powerPoint: 'manifest.powerpoint.xml',
            event: 'manifest.event.xml',
        },
        noSubject: '(no subject)',
        emailType: 'eml',
        image: {
            base64StringForEmail: 'data:image/png;base64,',
        },
        sharepoint: 'sharepoint.com',
        defaultNoteType: {
            EMAIL_TYPEID: 'd70218d00a16ada09142334aacce0b36',
            EMAIL: 'Email',
            REPORT_TYPEID: 'd70218d00a16ada0c812c4269f6daa32',
            REPORT: 'Report',
            MODEL_TYPEID: 'd70218d00a16ada0fe2d4dfe178bd113',
            MODEL: 'Model',
            PRESENTATION_TYPEID: 'd70218d00a16ada0ceb1eb388c0d85ce',
            PRESENTATION: 'Presentation'
        },
        browserType: {
            Safari: 'Safari',
            Firefox: 'Firefox',
        }
    },

    adminNotification: {
        notificationType: {
            Email: 'Email'
        }
    },

    // research page
    research: {
        researchKey: 'id',
        createResearchStore: 'add-research',
        deleteResearchStore: 'delete-research',
        screenMenuName: 'Research View',
        screenMenuTid: 'screen_research',
        screenMenuIconName: 'research',
        pageTypeText: 'CREATE_RESEARCH',
        saveScreen: {
            createTitle: 'Save Research View',
            editTitle: 'Edit Detail',
            type: 'Screen',
            refineByTab: 'REFINE_BY',
            relationshipTab: 'RELATIONSHIP'
        }
    },
    allNotes: {
        id: 'a1f329a2a3024412be00571c02152394',
        name: 'All Notes',
        screenId: 'a1f329a2a3024412be00571c02152394',
        screenType: 'research',
        type: 'share',
        editable: false
    },
    myNotes: {
        id: '6e6dfd9a1938318662cf9e439efa97d5',
        name: 'My Notes',
        screenId: 'e9f34ed1bac142d9b662234bceaa2dc5',
        screenType: 'research',
        type: 'share',
        editable: false
    },

    // research screen
    researchScreen: {
        defaultResearchScreenKey: 'researchView', // default screen key
    },
    allNotesScreenId: 'a1f329a2a3024412be00571c02152394',
    myNotesScreenId: 'e9f34ed1bac142d9b662234bceaa2dc5',
    notesScreenId: 'a7e7703454784faf9e2c95edae8dc52b',
    detailsScreenId: 'a476603c9ad94b6189be1752faa23695',
    relationshipScreenId: '8476603c9ad94b6189be1752faa23699',
    activityScreenId: '445dd11da3d44d6b93cd7898c374b51d',
    allNotesCaption: 'All Notes',
    myNotesCaption: 'My Notes',
    activityCaption: 'Activity',
    adminUserId: '23f03fe70a16aed0d7e210357164e401',

    // tab
    tab: {
        renamedFailed: {
            alertTitle: 'Tab Renamed Failed',
            alertContent: StringLiteralsPipe.translate('general.tab_name_exceed_maximum', '32')
        }
    },

    // webPlugin
    webPlugin: {
        versionArray: ['21.4']
    },

    // workflow
    previousTaskOwner: {
        name: 'general.previous_task_owner',
        id: 'previousTaskOwner'
    },
    workflow: {
        screenMenuName: 'Workflow',
        screenMenuTid: 'screen_workflow',
        screenMenuIconName: 'workflow',
        approve: {
            projectWasDeletedMsg: 'The project has been deleted.',
            taskWasFinishedMsg: 'Someone else has already finished this task.'
        },
    },
    // icon name
    svgIconName: {
        contactStatus: {
            synch: 'synch',
            disconnected: 'disconnected',
            oneWayLinked: 'oneWayLinked'
        }
    }
};


