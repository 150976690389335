/**
 * Created by Yoyo Fang on 02/20/19.
 * Description:
 *  a singleton service for global register tasks and global trigger execution of registered tasks
 *  alternative to emit/on & subscription schemas.
 * ------ maintenance history ------
 * 07/03/2019   Yoyo Fang   Add batch operation for isEventTargetWithin
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class TamDropzoneDomSearchObject {
    constructor(
        public name: string,
        public domClassName: string,
        public searchDepth: number = 100
    ) {
    }
}

class TamDropzoneDomSearchTask extends TamDropzoneDomSearchObject {
    public finished = false;
    constructor(
        extendee: TamDropzoneDomSearchObject
    ) {
        super(extendee.name, extendee.domClassName, extendee.searchDepth);
    }
}

@Injectable({
    providedIn: 'root',
})
export class TamDropzoneService {
    public dragEnter$: Subject<any> = new Subject<any>();
    public dragLeave$: Subject<any> = new Subject<any>();
    public drop$: Subject<any> = new Subject<any>();
    public reset$: Subject<any> = new Subject<any>();
    public click$: Subject<any> = new Subject<any>();
    public mousedown$: Subject<any> = new Subject<any>();
    public isEventTargetWithin(event: Event, domClassSelector: string, searchDepth: number = 100) {
        let currentTarget: any = event.target;
        for (let i = 0; currentTarget && i < searchDepth; i++) {
            if (currentTarget.classList.contains(domClassSelector)) {
                return true;
            }
            currentTarget = currentTarget.parentElement;
        }
        return false;
    }
    public batchIsEventTargetWithin(event: Event, conditions: TamDropzoneDomSearchObject[]): string[] {
        const result: string[] = [];
        const _conditions: TamDropzoneDomSearchTask[] = conditions.map((item) => new TamDropzoneDomSearchTask(item));
        let currentTarget: any = event.target;
        let count = 0;
        for (let j = 0; currentTarget && _conditions.length > count; j++) {
            for (let i = 0; i < _conditions.length; i++) {
                if (_conditions[i].finished) {
                    // skip
                    continue;
                } else if (_conditions[i].searchDepth === j) {
                    _conditions[i].finished = true;
                    count++;
                } else if (currentTarget.classList.contains(_conditions[i].domClassName)) {
                    _conditions[i].finished = true;
                    count++;
                    result.push(_conditions[i].name);
                }
            }
            currentTarget = currentTarget.parentElement;
        }
        return result;
    }
}
