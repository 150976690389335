/**
 * Created by Abner Sui on 04/19/2019.
 * Description:
 *
 * ------ maintenance history ------
 * 04/02/2022 Simon Zhao changed all functions into static methods.
 */
export const ALPHABET_ARRAY = 'abcdefghijklmnopqrstuvwxyz'.split('');
export class IdHelperService {
    /**
     * create specified guid according to requirements
     * default is general format
     * type=1: starts with letter
     */
    static createGUID(type?: number): string {
        let d: number = new Date().getTime();
        const format: string = type === 1 ? 'xxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx' : 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
        let uuid: string = format.replace(/[xy]/g, function (c) {
            const r: number = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });

        if (type === 1) {
            uuid = ALPHABET_ARRAY[Math.round(Math.random() * 25) + 0] + uuid;
        }
        return uuid;
    }

    static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : r & 0x3 | 0x8;
            return v.toString(16);
        });
    }

}
