/**
 * ------ maintenance history ------
 * Updated by Ella Ma on 07/30/2019
 * Removed contextMenu->Hisroty permission limit, all user can view History[TAM-26446]
 * Updated by Daniel Wang on 10/9/2019 Added event properties
 * Updated by Daniel Wang on 11/4/2019 Handled edit event/note and sidenote
 * Updated by Daniel Wang on 11/6/2022 Show map tile when click location icon
 */

import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as MarkJS from 'mark.js/dist/mark';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ContextMenuConfig, ContextMenuShowAction } from '../../widgets/tam-context-menu/tam-context-menu.model';
import { PopoverConfig } from '../popover/popover.model';
import { NoteEntry } from '../../tamalelibs/models/note-entry.model';
import { IAppState } from '../../tamalelibs/redux/app.state';
import { NoteDialogOpenOptions, NoteDialogType } from '../note-dialog/note-dialog.model';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { AuditTrailDialogService } from '../audit-log/audit-trail/audit-trail.service';
import { DocumentEntry } from '../../tamalelibs/models/document-entry.model';
import { AttachmentView } from '../attachment-list/attachment-view.model';
import { AlertButton, AlertBtnTypes } from '../../widgets/alert-window/alert-window.model';
import { businessConstants } from '../../tamalelibs/constants/business.constants';
import { EntryClass } from '../../tamalelibs/models/entry-class.model';
import { CalendarDialogService } from '../calendar-dialog/calendar-dialog.service';
import { CalendarDialogOpenOptions, CalendarDialogType } from '../calendar-dialog/calendar-dialog.model';
import { Thread } from '../../tamalelibs/models/thread.model';
import { ThreadDetailService } from '../../services/thread-detail.service';
import { ThreadDetailPartialDisplay } from '../thread-detail/thread-detail.view-model';
import { SlideSheetActionTypes } from '../slide-sheet/slide-sheet.model';
import { SlideSheetService } from '../../services/slide-sheet.service';
import { WebTemplateViewModel } from '../template/template-web.view-model';
import { CalendarService } from '../../tamalelibs/services/calendar.service';
import { DashboardTileService } from '../tam-dashboard/dashboard-tile.service';
import { SingleSelectDropdownFilterConfig } from '../../widgets/single-select-dropdown-filter/single-select-dropdown-filter.model';
import { DialogAction, DialogService, DialogType } from '../../services/dialog.service';
import { MapContactModel } from '../tam-dashboard/tam-dashboard-map-tile/tam-dashboard-map-autocomplete/tam-dashboard-map-autocomplete.model';
import { EntityService } from '../../tamalelibs/services/entity.service';
import { Contact } from '../../tamalelibs/models/contact.model';
import { SlideSheetTempService } from '../../services/slide-sheet-temp.service';
import { take } from 'rxjs/operators';
import { getSystemConfigState } from '../../redux/reducers/system-config-view.reducer';
import { StoreQuerierService } from '../../services/store-querier.service';

export const AVAILABLE_LOCATION_DEFAULT_VALUE = 'update the location';

@Component({
    selector: 'note-detail-header',
    templateUrl: './note-detail-header.component.html',
    styleUrls: ['./note-detail-header.component.scss']
})
export class NoteDetailHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() note: NoteEntry;
    @Input() highlightText: string[];
    @Input() isPrintMode: boolean;
    @Input() thread: Thread;
    @Input() isShowMaximun: Boolean;
    @Input() isEmbedMode: boolean;
    @Input() isSideSheet: boolean;
    @Input() partialDisplay?: ThreadDetailPartialDisplay;
    @Output() toggle: EventEmitter<any> = new EventEmitter();
    @Output() editNoteSideNote: EventEmitter<any> = new EventEmitter();
    @Output() editSideNote: EventEmitter<any> = new EventEmitter();
    @Output() deleteSideNote: EventEmitter<any> = new EventEmitter();
    @Output() deleteAttachment: EventEmitter<any> = new EventEmitter();
    @ViewChild('submitInfo', { static: false }) submitInfoEle: ElementRef;
    @ViewChild('subject', { static: false }) subjectEle: ElementRef;

    availableLocation = AVAILABLE_LOCATION_DEFAULT_VALUE;
    collapsed = false;
    contextMenuConfig: ContextMenuConfig;
    date = '';
    isEvent = false;
    isDeletable = false;
    isExistAvailableCoordinate = false;
    isLinkedEntityAddressUpdatedInMultipleAddress = false;
    isLinkedEntityAddressUpdatedInSingleAddress = false;
    isLinkedEntityAddressDeleted = false;
    isLinkedEntityDeleted = false;
    isLocationHovered = false;
    isNoUpdatePermission = false;
    isShowLinkedEntityUpdateInfo = false;
    linkedEntityAddress;
    linkedEntityAddressConfig: SingleSelectDropdownFilterConfig = new SingleSelectDropdownFilterConfig(true);
    linkedEntityAddressType = '';
    linkedEntityLongName = '';
    location = '';
    locationExpandObj;
    locationExpandValueObj;
    locationShowInScreen = '';
    locationTooltip = 'Show on map view';
    particpants = [];
    popoverConfig: PopoverConfig;
    showMeatballInEvent = false;
    showHideFlags = {
        submitter: false,
        submittedDate: false
    };

    private _destroySubscriptions: Array<Subscription> = [];
    private _isMapTileEnable = false;
    private _pageId: string; // mark page id for distinguishing diffent pages that subscribe to the same message queue

    constructor(
        private _alertWindow: AlertWindowService,
        private _auditTrailService: AuditTrailDialogService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _calendarService: CalendarService,
        private _dashboardTileService: DashboardTileService,
        private _datepipe: DatePipe,
        private _dialogService: DialogService,
        private _entityService: EntityService,
        private _eventDialogService: CalendarDialogService,
        private _noteTemplatePageViewModel: WebTemplateViewModel,
        private _slideSheetService: SlideSheetService,
        private _store: Store<IAppState>,
        private _storeQuerier: StoreQuerierService,
        private _tempSlideSheet: SlideSheetTempService,
        private _threadDetailService: ThreadDetailService,
    ) {
        this._pageId = new Date().getTime().toString(); // get a random page id
    }

    ngOnInit() {
        this._initParamaters();
        this._initMapTileStatus();
        this._initComponents();
        this._initEventListener();
    }

    ngAfterViewInit() {
        if (!this.isPrintMode) {
            const submiteInfoMark = new MarkJS(this.submitInfoEle.nativeElement);
            const subjectMark = new MarkJS(this.subjectEle.nativeElement);
            submiteInfoMark.mark(this.highlightText, {
                'element': 'span',
                'className': 'highlight',
                'separateWordSearch': false
            });
            subjectMark.mark(this.highlightText, {
                'element': 'span',
                'className': 'highlight',
                'separateWordSearch': false
            });
        }
    }
    ngOnDestroy() {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
    }

    getSubmitInfoTitle() {
        let title = this.note.type.name + ' from ' + this.note.source.name;
        if (this.note.source.company) {
            title += ' of ' + this.note.source.company;
        }
        title += ' on ';
        if (!this.note.backdated) {
            title += this.getWeekdayByDate(this.note.displayDate) + ', ';
            title += this._datepipe.transform(this.note.displayDate, 'dd MMM yyyy, HH:mm');
        } else {
            title += this._datepipe.transform(this.note.displayDate, 'dd MMM yyyy');
        }
        if (!this.isEvent) {
            if (this.note.backdated || this.note.source.id !== this.note.submitter.id) {
                title += ' (Submitted';
                if (this.note.source.id !== this.note.submitter.id) {
                    title += ' by ' + this.note.submitter.name;
                }
                if (this.note.backdated) {
                    title += ' on ' + this.getWeekdayByDate(this.note.submittedDate) + ', ' + this._datepipe.transform(this.note.submittedDate, 'dd MMM yyyy, HH:mm');
                }
                title += ')';
            }
        } else {
            title += ' (Submitted';
            if (this.note.source.id !== this.note.submitter.id) {
                title += ' by ' + this.note.submitter.name;
            }
            title += ' on ' + this.getWeekdayByDate(this.note.submittedDate) + ', ' + this._datepipe.transform(this.note.submittedDate, 'dd MMM yyyy, HH:mm');
            title += ')';
        }
        return title;
    }

    getWeekdayByDate(value: any): any {
        if (value !== undefined) {
            const weekArray = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
            const myDate = new Date(value);
            const week = weekArray[myDate.getDay()];
            return week;
        }
    }

    /**
     * Determines whether the given subject is a long subject that a special tooltip class is needed.
     * @param subject the subject stringn.
     * @returns A flag indicating whether the subject is a long subject that a special tooltip class would be applied to it.
     */
    isLongSubject(subject: string) {
        // 78 english characters usually take one fourth of the header width.
        return !!subject && subject.length > 78;
    }

    @HostListener('document:click', ['$event'])
    onclick(e: MouseEvent) {
        if (this.popoverConfig) {
            this.popoverConfig.showPopover = false;
        }
    }

    onDeleteClick(event) {
        this.deleteAttachment.emit(event);
    }

    onMenuSelect(event) {
        if (event.payload) {
            const id = event.payload.id;
            if (id === '0') {
                if (this.note.threadPosition === 0) {
                    // edit note
                    if (this.isEvent) {
                        this._editEvent();
                    } else {
                        this._editNote(this.note);
                    }
                } else {
                    // edit side note
                    this._editSideNote();
                }
            } else if (id === '1') {
                const content: string[] = [];
                if (this.note.threadPosition === 0) {
                    const typeContainer = this.isEvent === true ? 'event' : 'note';
                    content.push('Are you sure you want to delete this ' + typeContainer + '?');
                    content.push('Any attachments or sidenotes will also be deleted.');
                } else {
                    content.push('Are you sure you want to delete this sidenote?');
                    content.push('Any attachments will also be deleted.');
                }
                const cancelBtn: AlertButton = {
                    text: 'Cancel',
                    type: AlertBtnTypes.tertiary
                };
                const confirmButton: AlertButton = {
                    text: 'Delete',
                    type: AlertBtnTypes.destructive
                };
                if (this.note.threadPosition === 0) {
                    this._alertWindow.openForSideSheet$.next(true);
                } else {
                    this._alertWindow.openForSideSheet$.next(false);
                }
                const subscription = this._alertWindow.
                    custom('Delete confirmation required', content, cancelBtn, confirmButton)
                    .subscribe((result: boolean) => {
                        if (result) {
                            this._deleteNote();
                            if (this.note.threadPosition !== 0) {
                                this.deleteSideNote.emit(this.note);
                            }
                        }
                        subscription.unsubscribe();
                    });

            } else if (id === '2') {
                // Do not distinguish between note and side note
                this._auditTrailService.open$.next({
                    noteId: this.note.id,
                    threadId: this.thread.id
                });
            } else if (id === '3') {
                this._threadDetailService.open$.next({
                    threadId: this.thread.id,
                    isMaximize: true,
                    closeSideSheetWhenClickMiniIcon: true
                });
            }

        }
    }

    onMouseDown() {
        if (this.popoverConfig) {
            this.popoverConfig.showPopover = false;
        }
    }

    onLinkedEntityAddressItemClick(event) {
        this.linkedEntityAddressConfig.open$.next(event.target);
    }

    onLocationHovered() {
        if (this._isMapTileEnable) {
            this.isLocationHovered = true;
        }
    }

    onLocationHoverLeave() {
        this.isLocationHovered = false;
    }

    onShowAttachmentList(e) {
        // assing data for popover
        this.popoverConfig.items = [];
        this.note.attachments.forEach((item: DocumentEntry, key: string, map: Map<string, DocumentEntry>) => {
            this.popoverConfig.items.push(AttachmentView.parseFromDocumentEntry(item));
        });
        if (this.thread.notes.length > 1) {
            e.offset = 40;
        } else {
            e.offset = 8;
        }

        this.popoverConfig.el = e;
        // show popover
        this.popoverConfig.showPopover = true;

        if (e && e.stopPropagation) {
            e.stopPropagation();
        } else {
            // tslint:disable-next-line: deprecation
            window.event.cancelBubble = true;
        }
    }

    onShowContextMenu(e: MouseEvent) {
        this._changeDetectorRef.detectChanges();
        this.contextMenuConfig.show$.next(new ContextMenuShowAction(e, ''));
        this.popoverConfig.showPopover = false;
    }

    onToggle() {
        this.collapsed = !this.collapsed;
        this.toggle.emit();
    }

    onTouch() {
        if (this.popoverConfig) {
            this.popoverConfig.showPopover = false;
        }
    }

    /**
     *show map tile when click location icon
     */
    onShowMapTile() {
        if (this._isMapTileEnable && this.isExistAvailableCoordinate) {
            // close the map tile dialog if it's opened
            const dialogAction = new DialogAction(DialogType.MapTileDialog);
            this._dialogService.dialogClose$.next(dialogAction);

            const mapContactModel = new MapContactModel();
            mapContactModel.selectedAddress = this.location;
            // eventProperties used of the tooltip which is shown on poi component
            mapContactModel.eventProperties = {
                entities: this.note.entities,
                eventType: this.note.type.name,
                particpants: this.particpants,
                location: this.locationShowInScreen,
                date: this.date,
            };
            // threadId used to show calendar side sheet when click the event on poi tooltip
            mapContactModel.threadId = this.thread.id;
            // the event type
            mapContactModel.entityType = 'Event';

            // get latitude and lonitude from linked Entity
            mapContactModel.latitude = this.locationExpandObj.latitude;
            mapContactModel.longitude = this.locationExpandObj.longitude;
            if (this.locationExpandObj.linkedEntityId && this.locationExpandValueObj && this.locationExpandValueObj.linkedEntityLongName) {
                mapContactModel.selectedAddressType = this.linkedEntityAddressType;
                mapContactModel.entityId = this.locationExpandObj.linkedEntityId;
                this._entityService.getEntityDetail(mapContactModel.entityId).pipe(take(1)).subscribe(entityDetail => {
                    const contact = Contact.parse(entityDetail);
                    mapContactModel.contact = contact;
                    mapContactModel.entityName = this.note.subject ? this.note.subject : this.note.calculatedSubject;
                    const action = new DialogAction(DialogType.MapTileDialog, mapContactModel);
                    this._dialogService.dialogOpen$.next(action);
                    this._tempSlideSheet.actionSubject$.next('');
                });
            } else {
                mapContactModel.entityId = this.note.id;
                mapContactModel.entityName = this.note.subject ? this.note.subject : this.note.calculatedSubject;
                const action = new DialogAction(DialogType.MapTileDialog, mapContactModel);
                this._dialogService.dialogOpen$.next(action);
                this._tempSlideSheet.actionSubject$.next('');
            }
        }
    }

    trackByFn(index, item) {
        return item.id;
    }

    updateLocationInfo(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.locationExpandObj && this.locationExpandValueObj) {
            if (this.locationExpandValueObj.linkedEntityAddress && this.locationExpandValueObj.linkedEntityAddress.length > 0) {
                this.location = this._dashboardTileService.getAddress(this.linkedEntityAddress);

                // get locaiton expand information
                this.locationExpandObj.latitude = this.linkedEntityAddress.latitude;
                this.locationExpandObj.longitude = this.linkedEntityAddress.longitude;
            }
            this.isShowLinkedEntityUpdateInfo = false;
            this.locationShowInScreen = this._calendarService.generateDisplayLocation(this.linkedEntityLongName, this.linkedEntityAddressType, this.location);
            // update event
            const params = {
                outputformat: '',
                expand: '',
                showpermission: false,
                showattachmentsbyentry: false,
                showattachmentserverfilename: false
            };
            const formData = {
                entryclass: 'event',
                location: this.location,
                locationExpand: JSON.stringify(this.locationExpandObj),
            };
            this._noteTemplatePageViewModel.updateNote(this.note.id, params, formData, false, false, null, true);
        }
    }

    viewContactDetailInSideSheet(source, event) {
        // prevent a.href auto redirect
        event.preventDefault();
        event.stopPropagation();
        if (source) {
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CONTACT_PANEL,
                payload: source.id
            });
        }
    }

    private _deleteNote() {
        const noteType = this.note.threadPosition === 0 ? 'DELETE_NOTE' : 'DELETE_SIDENOTE';
        this._store.dispatch({
            type: noteType,
            payload: {
                id: this.note.id,
                componentId: this.isEvent ? 'Calendar' : this._pageId
            }
        });
    }

    private _editEvent() {
        let _noteOption;
        if (this.isEvent) {
            _noteOption = new CalendarDialogOpenOptions();
            _noteOption.openType = CalendarDialogType.EditEvent;
            _noteOption.isPluginInEditEventMode = false;
        }
        _noteOption.entryId = this.note.id;
        _noteOption.minimizable = true;
        this._eventDialogService.dialogOpen$.next(_noteOption);
    }

    private _editNote(thread) {
        const _noteOption = new NoteDialogOpenOptions();
        _noteOption.entryId = thread.id;
        _noteOption.openType = NoteDialogType.EditNote;
        _noteOption.minimizable = true;
        this.editNoteSideNote.emit(_noteOption);
    }

    private _editSideNote() {
        const _noteOption = new NoteDialogOpenOptions();
        _noteOption.entryId = this.note.id;
        _noteOption.openType = NoteDialogType.EditSideNote;
        _noteOption.minimizable = true;
        this.editNoteSideNote.emit(_noteOption);

    }

    private _initComponents() {
        this._initPopover();
        this._initContextMenu();
    }

    private _initContextMenu() {
        this.contextMenuConfig = new ContextMenuConfig();
        this.contextMenuConfig.stateful = false;
        this.contextMenuConfig.showItemIcon = true;
        this.contextMenuConfig.clickOnDiv = false;
        this.contextMenuConfig.items = [];
        if (this.note.editable) {
            this.contextMenuConfig.items.push({ id: '0', text: 'Edit', disabled: false, icon: 'pencil' });
        }
        if (this.isDeletable) {
            this.contextMenuConfig.items.push({ id: '1', text: 'Delete', disabled: !this.isDeletable, icon: 'trash' });
        }

        if (this.thread.parentEntry && this.thread.parentEntry.entryClass !== EntryClass.Event) {
            this.contextMenuConfig.items.push({ id: '2', text: 'History', icon: 'audit' });
        }

        if (this.isEmbedMode) {
            this.contextMenuConfig.items.push({ id: '3', text: 'Expand', icon: 'window-maximize' });
        }

        this.contextMenuConfig.events$.subscribe(event => this.onMenuSelect(event));
    }

    private _initEventProperties() {
        if (this.isEvent) {
            if (this.note.adhocs && this.note.adhocs.size > 0) {
                // get particpants from event properties
                const attendees = this.note.adhocs.get(businessConstants.calendar.ownProperties.attendeesId);
                if (attendees) {
                    this.particpants = attendees.expandValue;
                }

                // get date from event properties
                const allDayEvent = this.note.adhocs.get(businessConstants.calendar.ownProperties.allDayId);
                let isAllDayEvent = false;
                if (allDayEvent) {
                    isAllDayEvent = allDayEvent.value.toLocaleLowerCase() === 'true' ? true : false;
                }
                const displayDate = this.note.displayDate;
                let endDate = 0;
                const endDateObj = this.note.adhocs.get(businessConstants.calendar.ownProperties.endDateId);
                if (endDateObj) {
                    endDate = parseFloat(endDateObj.value);
                }
                this.date = this._threadDetailService.getCalendarDateInfo(isAllDayEvent, displayDate, endDate);

                // get location from event properties
                const location = this.note.adhocs.get(businessConstants.calendar.ownProperties.locationId);
                if (location) {
                    this.location = location.value;
                    this.locationShowInScreen = this.location; // set default value for locationShowInScreen
                }

                // set location expand
                if (isAllDayEvent) {
                    endDate = new Date(this._datepipe.transform(endDateObj.value, 'yyyy-MM-dd') + ' 23:59:59.999+00').getTime();
                }
                this._initEventLocationExpand(endDate);
            }
        }
    }

    private _initEventListener() {
        this._initTouchListener();

        if (this._threadDetailService) {
            this._threadDetailService.scroll$.subscribe(() => {
                this.popoverConfig.showPopover = false;
            });
        }
    }

    private _initEventLocationExpand(endDate: number): void {
        // get location expand from event properties
        const locationExpand = this.note.adhocs.get(businessConstants.calendar.ownProperties.locationExpandId);
        if (locationExpand) {
            this.locationExpandObj = JSON.parse(locationExpand.value);
            this.locationExpandValueObj = locationExpand.expandValueObj;
            // Need to handle the case whose linked entity exist
            if (this.locationExpandObj && this.locationExpandObj.linkedEntityId && this.locationExpandValueObj && this.locationExpandValueObj.linkedEntityLongName !== businessConstants.common.noPermissioIdentifier) {
                this.linkedEntityLongName = this.locationExpandValueObj.linkedEntityLongName;
                this.linkedEntityAddressType = this.locationExpandObj.addressName;
                // set value for locationShowInScreen if the location has linked entity
                this.locationShowInScreen = this._calendarService.generateDisplayLocation(this.linkedEntityLongName, this.linkedEntityAddressType, this.location);
                // Do not show change message if the end date of an event is less than the current date
                if (endDate >= (new Date()).getTime()) {
                    // The linked entity exist
                    if (this.locationExpandValueObj && this.locationExpandValueObj.linkedEntityLongName) {
                        if (this.locationExpandValueObj.linkedEntityAddress.length > 0) {
                            // linked entity which only has one address has been changed.
                            if (this.locationExpandValueObj.linkedEntityAddress.length === 1) {
                                const newAddress = this._dashboardTileService.getAddress(this.locationExpandValueObj.linkedEntityAddress[0]);
                                if (this.location !== newAddress) {
                                    this.isShowLinkedEntityUpdateInfo = true;
                                    this.isLinkedEntityAddressUpdatedInSingleAddress = true;
                                    this.linkedEntityAddress = this.locationExpandValueObj.linkedEntityAddress[0];
                                } else {
                                    this.isShowLinkedEntityUpdateInfo = false;
                                }
                            } else { // linked entity which only has multiple address has been changed.
                                const listAddress = [];
                                this.isShowLinkedEntityUpdateInfo = true;
                                this.isLinkedEntityAddressUpdatedInMultipleAddress = true;
                                this.locationExpandValueObj.linkedEntityAddress.forEach((item, index) => {
                                    const newAddress = this._dashboardTileService.getAddress(item);
                                    listAddress.push(newAddress);
                                    if (newAddress === this.location) {
                                        this.isShowLinkedEntityUpdateInfo = false;
                                        this.isLinkedEntityAddressUpdatedInMultipleAddress = false;
                                        this.linkedEntityAddress = item;
                                    }
                                    // set source for linked entity address
                                    this.linkedEntityAddressConfig.data.push({
                                        id: item, // to get the detail information when update location
                                        name: newAddress,
                                        selected: false
                                    });
                                });
                            }
                        } else {
                            this.isShowLinkedEntityUpdateInfo = true;
                            this.isLinkedEntityAddressDeleted = true; // the linked entity address has been deleted.
                        }
                    } else {
                        this.isShowLinkedEntityUpdateInfo = true;
                        this.isLinkedEntityDeleted = true; // the linked entity has been deleted.
                    }

                    this._destroySubscriptions.push(
                        this.linkedEntityAddressConfig.onChangeValue$.subscribe(data => {
                            if (data) {
                                this.linkedEntityAddress = data.id;
                                this.availableLocation = data.name;
                                this.updateLocationInfo(null);
                            }
                        }),
                    );
                } else {
                    this.isShowLinkedEntityUpdateInfo = false;
                }
            } else {
                this.isShowLinkedEntityUpdateInfo = false;
            }
        } else {
            this.isShowLinkedEntityUpdateInfo = false;
        }
    }

    private _initMapTileStatus() {
        if (this.locationExpandObj) {
            if (this.locationExpandObj.latitude || this.locationExpandObj.longitude) {
                this.isExistAvailableCoordinate = true;
            }
        }

        const systemConfig = this._storeQuerier.queryBySelector(getSystemConfigState);
        if (systemConfig && systemConfig.maptile) {
            this._isMapTileEnable = systemConfig.maptile['enable'];
        }
        if (!this._isMapTileEnable || !this.isExistAvailableCoordinate) {
            this.locationTooltip = '';
        }
    }

    private _initParamaters() {
        this._isEvent();
        this._isDeletable();
        this._isEditable();
        this._showMeatballInEvent();
        this._initEventProperties();
        this._initUIShowHideFlags();
    }

    private _initPopover() {
        this.popoverConfig = new PopoverConfig();
        this.popoverConfig.items = [];
        this.note.attachments.forEach((item: DocumentEntry, key: string, map: Map<string, DocumentEntry>) => {
            this.popoverConfig.items.push(AttachmentView.parseFromDocumentEntry(item));
        });
        this.popoverConfig.showPopover = false;
    }

    private _initTouchListener() {
        window.addEventListener('touchstart', this.onTouch, false);
    }

    private _isDeletable(): boolean {
        this.isDeletable = this._threadDetailService.isDeletable(this.note, this.thread);
        return this.isDeletable;
    }

    private _isEditable(): void {
        this.isNoUpdatePermission = !this.note.editable;
    }

    private _isEvent() {
        if (this.thread.parentEntry
            && this.thread.parentEntry.entryClass === EntryClass.Event
            && this.note.threadPosition === 0) {
            this.isEvent = true;
        } else {
            this.isEvent = false;
        }
    }

    private _showMeatballInEvent() {
        if (this.thread.parentEntry
            && this.thread.parentEntry.entryClass !== EntryClass.Event) {
            this.showMeatballInEvent = true;
        } else {
            if (this.note.editable || this.isDeletable) {
                this.showMeatballInEvent = true;
            } else {
                this.showMeatballInEvent = false;
            }
        }
    }

    private _initUIShowHideFlags() {
        // submission=submitter||submittedDate
        // this.showHideFlags['submission'] = this.note.backdated || this.note.source.id !== this.note.submitter.id || this.isEvent;
        this.showHideFlags['submitter'] = (this.note.source.id !== this.note.submitter.id)
            && (!this.partialDisplay || this.partialDisplay.submitter);
        this.showHideFlags['submittedDate'] = (this.isEvent || this.note.backdated)
            && (!this.partialDisplay || this.partialDisplay.submittedDate);
    }

}
