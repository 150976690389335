/**
 * Created by Daniel Wang
 * Description: Added this service to support displaying thread detial in sidesheet
 *
 * ------ maintenance history ------
 * update by Daniel on 6/27
 * moved the parseKeywordsFromSearchString funciton position from thread detail component
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NoteEntry } from '../tamalelibs/models/note-entry.model';
import { Thread } from '../tamalelibs/models/thread.model';
import { DatePipe } from '@angular/common';
import { DateHelperService } from '../tamalelibs/services/date-helper.service';

@Injectable()
export class ThreadDetailService {
    open$: Subject<any> = new Subject<any>();
    close$: Subject<any> = new Subject<any>();
    scroll$: Subject<any> = new Subject<any>();
    private _lucenceSpecialCharacters = ['+', '-', '!', '(', ')', '{', '}', '[', ']', '^', '"', '~', '*', '?', ':', '\\', '&', '|'];

    constructor(
        private _dateHelper: DateHelperService,
        private _datepipe: DatePipe,
    ) { }

    get lucenceSpecialCharacters(): Array<string> {
        return Object.assign([], this._lucenceSpecialCharacters);
    }

    /**
     * split keyword search string to several keywords used by highlight feature.
     *
     * @private
     * @param {*} searchStr The keyword search string
     * @returns {string[]} The array of keyword that will be highlighted
     * @memberof ThreadDetailComponent
     */
    parseKeywordsFromSearchString(searchStr): string[] {
        const reg = /(".*?")/g;
        let keywords = [];
        const matches = searchStr.match(reg);
        let searchStrAfterStep1 = '';
        if (matches && matches.length > 0) {
            keywords = matches.map(element => element.slice(1, -1).trim());
            searchStrAfterStep1 = searchStr.replace(reg, ' ');
        } else {
            searchStrAfterStep1 = searchStr;
        }
        let searchStrAfterStep2 = searchStrAfterStep1;
        this.lucenceSpecialCharacters.forEach((character: string) => {
            while (searchStrAfterStep2.includes(character)) {
                searchStrAfterStep2 = searchStrAfterStep2.replace(character, ' ');
            }
        });
        keywords = keywords.concat(searchStrAfterStep2.split(' '));
        keywords = keywords.filter(element => element);

        return keywords;
    }

    isDeletable(note: NoteEntry, thread: Thread): boolean {
        if (note.threadPosition === 0) {
            const flag = thread.notes.find(item => {
                return item.deletable === false;
            });
            if (flag) {
                return false;
            } else {
                return true;
            }
        } else {
            return note.deletable;
        }
    }

    getCalendarDateInfo(isAllDayEvent: Boolean = false, displayDate: Date, endDateObj: number): string {
        let dateString = '';

        if (displayDate && endDateObj) {
            // create string value for start date
            const startDateSeconds = this._dateHelper.getShortDateByDateObject(displayDate).getTime();
            const startDateStr = this._datepipe.transform(displayDate, 'dd MMM yyyy HH:mm');
            // create string value for end date
            let endDateStr = '';
            const endDateSeconds = endDateObj;
            const endShortDate = this._dateHelper.getShortDateByDateObject(new Date(endDateSeconds));
            const endShortDateSeconds = endShortDate.getTime();
            endDateStr = this._datepipe.transform(new Date(endDateSeconds), 'dd MMM yyyy HH:mm');

            if (endShortDateSeconds - startDateSeconds > 0) {
                if (isAllDayEvent) {
                    const startDateWeek = this._dateHelper.getWeekdayByDate(displayDate);
                    const endDateWeek = this._dateHelper.getWeekdayByDate(endShortDate);
                    dateString = startDateWeek + ' ' + this._dateHelper.getDateString(displayDate, 'MM/dd/yyyy') + ' - ' + endDateWeek + ' ' + this._dateHelper.getDateString(endShortDate, 'MM/dd/yyyy') + ' (All Day)';
                } else {
                    dateString = startDateStr + ' - ' + endDateStr;
                }
            } else {
                const endDate = new Date(endDateSeconds);
                if (isAllDayEvent) {
                    dateString = this._dateHelper.getWeekdayByDate(endShortDate) + ' ' + this._dateHelper.getDateString(endShortDate, 'MM/dd/yyyy') + ' (All Day)';
                } else {
                    const endDateHM = endDate.getHours().toString().padStart(2, '0') + ':' + endDate.getMinutes().toString().padStart(2, '0');
                    dateString = this._dateHelper.getWeekdayByDate(endShortDate) + ' ' + startDateStr + ' - ' + endDateHM;
                }
            }
        }

        return dateString;
    }
}
