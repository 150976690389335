/**
 * Created by Daniel Wang on 12/5/2022.
 * Description: Relationship filter
 * ------ maintenance history ------
 */
import { Component, OnInit, Input, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { of as observableOf, Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { catchError, take, debounceTime, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../redux';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AppConfig } from '../../tamalelibs/models/app-config.model';
import { FiltersConfig } from '../../tamalelibs/components/filter-config';
import { FilterWidgetConfig, FilterWidgetFeedback, FilterWidgetFeedbackType } from '../../tamalelibs/components/filter-widget.config';
import { EntityService } from '../../tamalelibs/services/entity.service';
import { EntityBrief } from '../../tamalelibs/models/entity-brief.model';
import { RelationshipType } from '../../tamalelibs/models/relationship-type.model';
import { relationshipTypeSelector } from '../../redux/reducers/relationship-type.reducer';
import { MultiSelDropdownConfig, MultiSelDropdownFilterConfig } from '../multi-sel-dropdown-filter/multi-sel-dropdown-filter.model';
import { UtilsService } from '../../tamalelibs/services/utils.service';
import { businessConstants } from '../../tamalelibs/constants/business.constants';

export const ALL_SOURCE_NAME = 'All';
export const EMPTY_SOURCE_NAME = '';
/**
 * Filter widget for metadataType is relationship
 */
@Component({
    selector: 'tam-relationship-filter',
    templateUrl: 'tam-relationship-filter.component.html',
    styleUrls: ['tam-filter-container.component.scss']
})
export class TamRelationshipFilterComponent implements OnInit, OnDestroy {
    @Input() config: FiltersConfig; // data
    @Input() filterWidgetConfig: FilterWidgetConfig; // event

    @ViewChild('entityList', { static: false }) entityList;

    // #region properties
    // entity selected control
    entityItems: Array<EntityBrief>;
    entityItem: EntityBrief;
    isIncludeCurrentEmployment = true;
    // delete icon
    isItemHoverOn = false;
    // the selected operator
    operator: { name: string, value: string };
    operators: Array<{ name: string, value: string }>;
    // relationship type control
    relationshipTypeCheckedIds = EMPTY_SOURCE_NAME;
    relationshipTypeCheckedNames = EMPTY_SOURCE_NAME;
    relationshipTypeConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    relationshipTypeItems: Array<RelationshipType> = []; // relationship items based on search

    // subscriptions
    private _destroySubscriptions: Array<Subscription> = [];
    private _filterHandler$: Subject<string> = new Subject();

    private _filterValue: string;
    private _page = 0;
    private _pageSize: number = AppConfig.searchingPageSize;
    // #endregion

    // #region constructor
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _deviceService: DeviceDetectorService,
        private _entityService: EntityService,
        private _store: Store<AppState>,
        private _utilsService: UtilsService
    ) { }
    // #endregion

    // #region public functions
    ngOnInit() {
        this._initCloseIconStatusByDevice();
        this._initProperties(); // should be intit properties first.
        this._initSubscription();
        this._initControls();
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    onBtnCloseClicked() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.remove;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    onCheckBoxClick() {
        this._feedback();
    }

    onClickRelationshipType(event) {
        this.relationshipTypeConfig.open$.next(event.target);
    }

    /**
     * when input value, will trigger onEntityListFilterChange
     * when valueChange, kendo-dropdownlist will trigger onEntityListFilterChange
     */
    onEntityListFilterChange(value) {
        const filterStr = value.trim();
        if (this._filterValue && !filterStr) {
            this._searchingFirstPage();
        }
        this._filterHandler$.next(filterStr);
        this._filterValue = filterStr;
    }

    /**
     * on selected entities change
     * @memberof TamRelationshipFilterComponent
     */
    onEntityListValueChange() {
        if (!this.entityItem) {
            this._searchingFirstPage();
        }
        this._filterValue = '';
        this._feedback();
    }

    onMouseOver() {
        if (!this.config.deletable) {
            this.isItemHoverOn = true;
        }
    }

    onMouseOut() {
        if (!this.config.deletable) {
            this.isItemHoverOn = false;
        }
    }

    /**
     * on selected operator change
     * @memberof TamRelationshipFilterComponent
     */
    onValueChangeOperate(changeItem: { name: string, value: string }) {
        this._feedback();
    }
    // #endregion

    // #region private functions
    private _feedback() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.update;

        this.config.selectedOperator = this.operator.value;
        this.config.value = this.entityItem;
        this.config.relationshipType = this.relationshipTypeCheckedIds.toLowerCase();
        this.config.isIncludeCurrentEmployment = this.isIncludeCurrentEmployment;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    private _initCloseIconStatusByDevice() {
        // For use within normal web clients
        const isiPad = this._deviceService.isMobile() || this._deviceService.isTablet();
        if (isiPad) {
            this.isItemHoverOn = true;
        } else {
            this.isItemHoverOn = !this.config.deletable;
        }
    }

    private _initControls() {
        // operator
        this.operators = this.config.operators; // operator list
        this.operator = this.config.operators.find(item => item.value === this.config.selectedOperator); // the selected operator
        // relationship type
        if (this.relationshipTypeCheckedIds === ALL_SOURCE_NAME.toLowerCase()) {
            this.relationshipTypeCheckedNames = ALL_SOURCE_NAME;
        } else {
            const relationshipTypeCheckedItems = this.relationshipTypeItems.filter(item => this.relationshipTypeCheckedIds.split(businessConstants.common.separator.COMMA).includes(item.id));
            this.relationshipTypeCheckedNames = relationshipTypeCheckedItems.map(item => item.name).join(businessConstants.common.separator.COMMA);
        }
        // entity
        this.entityItem = this.config.value;
        if (this.entityItem) {
            this._searching(this.entityItem.shortName);
        } else {
            this._searchingFirstPage();
        }
        // includeCurrentEmployment
        this.isIncludeCurrentEmployment = this.config.isIncludeCurrentEmployment;
    }

    private _initProperties() {
        if (this.config.relationshipType) {
            this.relationshipTypeCheckedIds = this.config.relationshipType;
        }
    }

    private _initRelationshipType(items) {
        this.relationshipTypeItems = items;
        this.relationshipTypeItems.sort((a, b) => {
            return this._utilsService.sort(a.name, b.name);
        });
        this.relationshipTypeItems.forEach(item => {
            let isItemChecked = false;
            if (this.relationshipTypeCheckedIds === ALL_SOURCE_NAME.toLowerCase()) {
                isItemChecked = true;
            } else {
                if (this.relationshipTypeCheckedIds.split(businessConstants.common.separator.COMMA).includes(item.id)) {
                    isItemChecked = true;
                }
            }
            this.relationshipTypeConfig.data.push(MultiSelDropdownConfig.parse(item.id, isItemChecked, item.name, '', false));
        });
    }

    private _initSubscription() {
        this._destroySubscriptions.push(
            this._store.pipe(select(relationshipTypeSelector)).subscribe(items => this._initRelationshipType(items)),
            this.relationshipTypeConfig.onChangeValue$.subscribe(data => this._relationshipTypeChange(data)),
            this._filterHandler$.pipe(
                debounceTime(businessConstants.common.number.debounceTime),
                filter(filterStr => filterStr != null && filterStr.length > 0)
            ).subscribe(filterStr => this._searching(filterStr))
        );
    }

    private _relationshipTypeChange(data): void {
        if (data.length === 0) {
            this.relationshipTypeCheckedIds = EMPTY_SOURCE_NAME;
            this.relationshipTypeCheckedNames = EMPTY_SOURCE_NAME;
        } else if (data === ALL_SOURCE_NAME.toLowerCase()) {
            this.relationshipTypeCheckedIds = ALL_SOURCE_NAME;
            this.relationshipTypeCheckedNames = ALL_SOURCE_NAME;
        } else {
            this.relationshipTypeCheckedIds = data.map(item => item.id).join(businessConstants.common.separator.COMMA);
            this.relationshipTypeCheckedNames = data.map(item => item.name).join(businessConstants.common.separator.COMMA);
        }
        this._feedback();
    }

    private _searching(text) {
        this._page = 0;
        if (this.entityList) {
            this.entityList.loading = true;
        }
        this._entityService.getEntityListBySearchTextQuick(++this._page,
            this._pageSize, text).pipe(
                take(1),
                catchError((e) => {
                    return observableOf();
                })).subscribe(
                    res => {
                        this.entityItems = this._searchingDone(res);
                        this.entityList.loading = false;
                        this._changeDetectorRef.detectChanges();
                    }
                );
    }

    private _searchingDone(entityList): Array<EntityBrief> {
        return this._entityService.mapEntityBriefList(entityList);
    }

    private _searchingFirstPage() {
        this._searching('');
    }
    // #endregion
}
