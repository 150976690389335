/**
 * Created by Alan Yang on 3/20/17.
 * Description:
 *  TSDictionary<T>, uses it as Dictionary<string,T> in C#.  There isn't Dictionary type in Typescript,
 *  so we defines it.
 * { [index: string]: T }
 *
 * ------ maintenance history ------
 *
 * ver 1.0.1 modified by Yu Zhang on 3/30/17.
 *      change TSDictionary<T> interface to ITSDictionary<T> to remove circular dependency
 *      change function names to start with lower case letter
 *
 * ver 1.0.2 modified by Yu Zhang on 4/26/17.
 *      change Dictionary data structure, values and keys now always return the same array reference instead of creating a new array
 */

export interface ITSDictionary<T> {
    add(key: string, value: any): void;
    remove(key: string): void;
    count(): number;
    containsKey(key: string): boolean;
    keys(): string[];
    values(): any[];
}

export class TSDictionary<T> implements ITSDictionary<T> {
    private _keys: string[] = [];
    private _values: T[] = [];

    constructor(init: { key: string; value: any; }[] = null) {
        if (init != null) {
            for (let x = 0; x < init.length; x++) {
                this[init[x].key] = init[x].value;
                this._keys.push(init[x].key);
                this._values.push(init[x].value);
            }
        }
    }

    add(key: string, value: T) {
        this[key] = value;
        this._keys.push(key);
        this._values.push(value);
    }

    remove(key: string) {
        const index = this._keys.indexOf(key, 0);
        this._keys.splice(index, 1);
        this._values.splice(index, 1);

        delete this[key];
    }

    count(): number {
        return this._keys.length;
    }

    keys(): string[] {
        return this._keys;
    }

    values(): T[] {
        return this._values;
    }

    containsKey(key: string) {
        return !(typeof this[key] === 'undefined');
    }

    clear() {
        this._keys = [];
        this._values = [];
    }
}
