/**
 * Created by Abner Sui on 12/12/2018.
 * Description:
 *  Extension for formulajs, self defined formulas
 *  Copy logic code from Tamale Web 1.0, formulaExtension.js
 * ------ maintenance history ------
 *  11/08/2019 Ella Ma - add function daysBetween and isleapyear.
 */
import { Injectable } from '@angular/core';
import * as FORMULA from 'formulajs/dist/formula';
import { bizConstants } from '../constants/biz.constants';

@Injectable()
export class FormulajsExtensionService {

    constructor() { }

    /**
     * overrride ISERROR and ISERR
     */
    public ISERR(value: any): boolean {
        return FORMULA.ISERR(value) || value === bizConstants.VALUEERROR;
    }

    public ISERROR(value: any): boolean {
        return FORMULA.ISERROR(value) || value === bizConstants.VALUEERROR;
    }

    public ISBLANK(value: any): boolean {
        return value === null || value === '' || value === undefined;
    }

    /**
     *
     * @param {string} interval, possible values: d=Day, y=Day, h=Hour, n=minute,m=Month, q=Quarter, s=Second, w=Week, ww=Calendar week, yyyy=Year
     * @param {string} date1, date string
     * @param {string} date2, date string
     * @return {number} returns date2 - date1 diff specified by interval
     */
    public DATEDIFF(interval: any, date1: string, date2: string): any {
        if (!interval) {
            // return error code
            return bizConstants.VALUEERROR;
        }

        const d1 = Date.parse(date1); // TODO: catch parse error?
        const d2 = Date.parse(date2);
        if (isNaN(d1) || isNaN(d2)) {
            // return error code
            return bizConstants.VALUEERROR;
        }

        const diff = d2 - d1;
        const weekInterval = 604800000;
        const dayInterval = 86400000;
        const hourInterval = 3600000;
        const minuteInterval = 60000;
        const secondInterval = 1000;
        interval = interval.toLocaleLowerCase();
        if (interval === 'd' || interval === 'y') {// Day
            return parseInt(String(diff / dayInterval), 0);
        } else if (interval === 'h') {// hour
            return parseInt(String(diff / hourInterval), 0);
        } else if (interval === 'n') {// minute
            return parseInt(String(diff / minuteInterval), 0);
        } else if (interval === 'q') {// quarter
            const dd1 = new Date(d1);
            const dd2 = new Date(d2);
            const yearDiff = dd2.getFullYear() - dd1.getFullYear();
            const monthDiff = dd2.getMonth() - dd1.getMonth();
            return parseInt(String((yearDiff * 12 + monthDiff) / 3), 0);
        } else if (interval === 's') {// second
            return parseInt(String(diff / secondInterval), 0);
        } else if (interval === 'ww') {// calendar week: how many Saturdays between date1 and date2
            const biggerDate = Math.max(d1, d2);
            const smallerDate = Math.min(d1, d2);

            // first, get the week day of date1
            const weekday1 = new Date(smallerDate).getDay();
            // the first Saturday after weekday1
            const firstSaturday = smallerDate + (6 - weekday1) * dayInterval;
            if (biggerDate - firstSaturday <= 0) {
                return 0;
            } else {
                const notInteger = (biggerDate - firstSaturday) % weekInterval;
                return ((notInteger ? 1 : 0) + parseInt(String((biggerDate - firstSaturday) / weekInterval), 0) * (diff > 0 ? 1 : -1));
            }
        } else if (interval === 'w') {// week
            return parseInt(String(diff / weekInterval), 0);
        } else if (interval === 'm') {
            return new Date(d2).getMonth() - new Date(d1).getMonth() + (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
        } else if (interval === 'yyyy') {// year
            return new Date(d2).getFullYear() - new Date(d1).getFullYear();
        } else {
            // return error code
            return bizConstants.VALUEERROR;
        }
    }

    /**
     *
     * @param {string} interval, possible values: d=Day, y=Day, h=Hour, n=minute,m=Month, q=Quarter, s=Second, w=Week, ww=Calendar week, yyyy=Year
     * @param {number} delta, date difference specified by interval
     * @param {string} date, original date
     * @return {string} returns date + delta * interval
     */
    public DATEADD(interval: any, delta: any, date: any): any {
        delta = +delta; // convert to number
        if (!interval || isNaN(delta)) {
            // return error code
            return bizConstants.VALUEERROR;
        }
        const d = Date.parse(date);
        if (isNaN(d)) {
            return bizConstants.VALUEERROR;
        }
        const deltaInt = parseInt(delta, 0);

        const orgDate = new Date(d);
        interval = interval.toLocaleLowerCase();
        let result;
        if (interval === 'd' || interval === 'y') {// Day
            result = new Date(orgDate.setDate(orgDate.getDate() + deltaInt));
        } else if (interval === 'h') {// hour
            result = new Date(orgDate.setHours(orgDate.getHours() + deltaInt));
        } else if (interval === 'n') {// minute
            result = new Date(orgDate.setMinutes(orgDate.getMinutes() + deltaInt));
        } else if (interval === 'q') {// quarter
            result = new Date(orgDate.setMonth(orgDate.getMonth() + deltaInt * 3));
        } else if (interval === 's') {// second
            result = new Date(orgDate.setSeconds(orgDate.getSeconds() + deltaInt));
        } else if (interval === 'ww') {// calendar week: currently, implemented as the as w
            result = new Date(orgDate.setDate(orgDate.getDate() + deltaInt * 7));
        } else if (interval === 'w') {// week
            result = new Date(orgDate.setDate(orgDate.getDate() + deltaInt * 7));
        } else if (interval === 'm') {
            result = new Date(orgDate.setMonth(orgDate.getMonth() + deltaInt));
        } else if (interval === 'yyyy') {// year
            result = new Date(orgDate.setFullYear(orgDate.getFullYear() + deltaInt));
        } else {
            // return error code
            return bizConstants.VALUEERROR;
        }
        return result;
    }

    /**
     *
     * @param year: string | number
     * @return {boolean} Returns TRUE if the year is a leap year
     */
    public ISLEAPYEAR(year: string | number) {
        return new Date(+year, 1, 29).getMonth() === 1;
    }

    /**
    *
    * @param  date1, date string || number || Date
    * @param date2, date string || number || Date
    * @return {number} Returns the days between two date.
    */
    public DAYSBETWEEN(start_date, end_date) {
        const parseDate = (date) => {
            if ((date || date === 0) && (typeof date === 'string' || typeof date === 'number' || date instanceof Date)) {
                const value = new Date(date);
                // if value is not a date type, value.getTime()===NaN
                // milliseconds === 0, value is 1970
                const milliseconds = value.getTime();
                if (milliseconds || milliseconds === 0) {
                    return milliseconds;
                }
                return;
            }
            return;
        };
        const startDate = parseDate(start_date);
        const endDate = parseDate(end_date);
        if (startDate && endDate) {
            return Math.ceil((endDate - startDate) / 86400000);
        }
        return bizConstants.VALUEERROR;
    }

    get formulajs(): any {
        return FORMULA;
    }
}
