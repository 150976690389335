<div class="category" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" kendoTooltip position="bottom">
    <div class="item-header" title="{{dataConfig.name}}" tamDynamicTooltip>
        <div class="left-header" (click)="collapse()">
            <smart-icon class="angle-icon" name="{{dataConfig.status.collapsed ? 'angle-down' : 'angle-up'}}">
            </smart-icon>
            <div class="item-name">{{dataConfig.name}}</div>
        </div>
        <div class="right-header" [style.visibility]="isHoverOn?'visible':'hidden'">
            <smart-icon class="angle-icon" name="meatball" (click)="onMeatball($event)"></smart-icon>
        </div>
    </div>
    <div *ngIf="(options && options.length > 0) || from || to" [hidden]="dataConfig.status.collapsed">
        <div class="item-content">
            <div *ngFor="let option of options">
                <div class="option-item" (click)="changeSelectedValue(option, $event)">
                    <div class="item-ckb">
                        <smart-icon name="check" [style.display]="option.checked?'':'none'"></smart-icon>
                    </div>
                    <div class="name-count-panel" kendoTooltip position="bottom">
                        <div class="option-name" title="{{option.name}}">{{option.name}}</div>
                        <div class="option-count">{{option.count}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="date-range" *ngIf="!dataConfig.isSearching || from || to">
            <div class="option-item" (click)="onShowDatePicker()">
                <div class="item-ckb">
                    <smart-icon name="check" [style.display]="isShowDateRange?'':'none'"></smart-icon>
                </div>
                <div class="option-name">Custom Date Range</div>
            </div>
            <div *ngIf="isShowDateRange">
                <div class="date-range-item">
                    <input [(ngModel)]="inputFrom" (keyup)="onKeyup($event)" placeholder="From"
                        (focusout)="focusout()" />
                    <kendo-datepicker calendarType="classic" [(ngModel)]="from" [rangeValidation]="false"
                        (valueChange)="changeInputValue()" [min]="fromMin" [max]="fromMax"></kendo-datepicker>
                </div>
                <div class="dash">—</div>
                <div class="date-range-item">
                    <input [(ngModel)]="inputTo" (keyup)="onKeyup($event)" placeholder="To" (focusout)="focusout()" />
                    <kendo-datepicker calendarType="classic" [(ngModel)]="to" [rangeValidation]="false"
                        (valueChange)="changeInputValue()" [min]="toMin" [max]="toMax"></kendo-datepicker>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="(!options || options.length === 0) && !from && !to" class="no-options">No options available.</div>
</div>