<div *ngIf="type==='check-box'" class="admin-checkbox-container">
    <label tam-checkbox [disabled]="isDisabled" (click)="onCheckBoxClick()" class="checkbox"
        [(ngModel)]="checkboxValue"></label>
</div>
<div *ngIf="type==='radio-button'" class="radio-button-container">
    <tam-radio (click)="onRadioClick()" [value]="true" [(ngModel)]="isDefaultType" [disabled]="isDisabled"
        class="radio-button">
    </tam-radio>
</div>
<div *ngIf="type==='toggle-button'" class="toggle-button-container">
    <kendo-switch style="padding-right: 8px;" disabled class="admin-toggle" (click)="onToggleRow()" onLabel="True"
        offLabel="False" [(ngModel)]="toggleRow">
    </kendo-switch>
</div>