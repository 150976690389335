/**
 * Created by Yoyo Fang on 04/18/19.
 * Description:
 *
 * ------ maintenance history ------
 */
export const RELOAD_CONTACTS = 'RELOAD_CONTACTS';
export const RELOAD_CONTACTS_SUCCESS = 'RELOAD_CONTACTS_SUCCESS';
export const RELOAD_CONTACTS_FAIL = 'RELOAD_CONTACTS_FAIL';

export const DELETE_CONTACT = '[CONTACT] DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESS = '[CONTACT] DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAIL = '[CONTACT] DELETE_CONTACT_FAIL';
