/**
 * Created by Alan Yang on 2018.
 * Description:
 *
 * ------ maintenance history ------
 * Updated by Jiu Chen on 2019.2 to refactor it into widgets folder.
 */
import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FiltersConfig } from '../../../tamalelibs/components/filter-config';
import { FilterWidgetConfig, FilterWidgetFeedback, FilterWidgetFeedbackType } from '../../../tamalelibs/components/filter-widget.config';
import { FilterPanelActions, FilterPanelEvents, FilterPanelConfig } from './filter-panel.view-model';
import * as _ from 'lodash';
import { IdHelperService } from '../../../services/id-helper.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'filter-panel',
    templateUrl: 'filter-panel.component.html',
    styleUrls: ['filter-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FilterPanelComponent implements OnInit, OnDestroy {
    @Input() config: FilterPanelConfig;

    filtersConfigList: Array<FiltersConfig>;
    filterWidgetConfig: FilterWidgetConfig;

    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    ngOnInit() {
        this.filtersConfigList = _.cloneDeep(this.config.filtersConfigList);
        this.filtersConfigList.forEach(item => {
            if (!item.id) {
                item.id = IdHelperService.createGUID();
            }
        });
        this.filterWidgetConfig = new FilterWidgetConfig();
        this.filterWidgetConfig.isShowDynamicDate = this.config.isShowDynamicDate;
        this.filterWidgetConfig.isHiddenTrashIcon = this.config.isHiddenTrashIcon;
        this._destroySubscriptions.push(
            this.config.action$.pipe(
                filter((data) => data.type === FilterPanelActions.addFilter)
            ).subscribe((data) => {
                data.payload.forEach((item) => {
                    this.filtersConfigList.push(item);
                });
                this._detectChanges();
            }),
            this.config.action$.pipe(
                filter((data) => data.type === FilterPanelActions.resetFilter)
            ).subscribe(() => {
                this.filtersConfigList = [];
                this._detectChanges();
            }),
            this.config.action$.pipe(
                filter((data) => data.type === FilterPanelActions.restrictFilter)
            ).subscribe((data) => {
                this.filterWidgetConfig.actionSubject$.next({ type: 'reset', payload: data.payload });
                this._detectChanges();
            }),
            this.config.action$.pipe(
                filter((data) => data.type === FilterPanelActions.reloadFilter)
            ).subscribe((data) => {
                this.filtersConfigList = _.cloneDeep(data.payload);
                this._detectChanges();
            }),
            this.filterWidgetConfig.feedbackSubject$.subscribe((_filterWidgetFeedback) =>
                this._onFiltersConfigChange(_filterWidgetFeedback))
        );
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    private _onFiltersConfigChange(_filterWidgetFeedback: FilterWidgetFeedback) {
        const currentConfig = _filterWidgetFeedback.payload;
        const index = this.filtersConfigList.findIndex(x => x.id === currentConfig.id);

        if (_filterWidgetFeedback.type === FilterWidgetFeedbackType.update) {
            this.filtersConfigList[index] = currentConfig;
            this.config.events$.next({ type: FilterPanelEvents.change, payload: this.filtersConfigList, changedItem: currentConfig });
        } else if (_filterWidgetFeedback.type === FilterWidgetFeedbackType.remove) {
            this.filtersConfigList.splice(index, 1);
            this.config.events$.next({ type: FilterPanelEvents.delete, payload: this.filtersConfigList });
        }
    }

    private _detectChanges() {
        if (this._changeDetectorRef) {
            this._changeDetectorRef.detectChanges();
        }
    }
}
