/**
 * Created by Yu Zhang on 11/16/17.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Component, Input, OnInit } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'priority-star',
    templateUrl: './priority-star.component.html',
    styleUrls: ['./priority-star.component.scss']
})
export class PriorityStarComponent {
    @Input() value = 2;

    constructor() { }
}
