import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationOptions } from '../notification/notification.model';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    _toasts$: Subject<{ type: string, payload?: NotificationOptions[] }> = new Subject();

    notify(notification: NotificationOptions) {
        this._toasts$.next({
            type: 'TOAST',
            payload: [notification],
        });
        this._toasts$.next({
            type: 'CLEAR',
            payload: [],
        });
    }
}
