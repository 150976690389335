/**
 * Created by Bowen on 5/24/2020.
 * Description:
 * Add contact information item data model
 *
 * ------ maintenance history ------
 */

import { Contact } from '../../../../tamalelibs/models/contact.model';

export class ContactInformationItemDetail {
    title: string;
    value?: Array<ContactInformationItemValueDetail>;
}

export class ContactInformationItemValueDetail {
    value?: string;
    valueId?: string;
    clickable = false;
    description?: string;
    parentId?: string;
    itemType?: string;
}

export enum ContactInformationItemType {
    Entity = 'Entity',
    Contact = 'Contact',
    Email = 'Email',
    Note = 'Note',
    Event = 'Event',
    Link = 'Link',
    Task = 'Task',
}

export class ContactRelationshipNodeDetail {
    value?: string;
    valueId?: string;
    clickable = false;
    description?: string;
    startYear?: string;
    endYear?: string;
}

// the subject, for example: basic information, current employment
export enum ContactInformationItemSubject {
    BasicInformation = 'Basic Information',
    CurrentEmployment = 'Current Employment',
    ContactInformation = 'Contact Information',
    ContactSynch = 'Contact Synch',
    FormerEmployment = 'Former Employment',
    Address = 'Address',
    Internet = 'Internet',
    Note = 'Notes',
    Other = 'Others',
}

// The sync event trigger source. SlideSheet/Detail
export enum TriggerSource {
    Detail = 'Detail',
    SlideSheet = 'Slide Sheet'
}

export class ContactInformationDetail {
    subject: string;
    value?: Array<ContactInformationItemDetail> | Contact;
}


