/**
 * Created by Ella Ma on 1/9/17.
 * Description:
 *
 * ------ maintenance history ------
 * Modified by Simon Zhao on 08/08/2022 exposured two input properties, one for auto-focus of search box, the other one for popup's anchor alignment.
 */

import { Component, OnInit, OnDestroy, Input, ElementRef, ContentChild, TemplateRef, ViewChild } from '@angular/core';
import { MultiSelDropdownConfig, MultiSelDropdownFilterConfig } from './multi-sel-dropdown-filter.model';
import { Subscription } from 'rxjs';
import { ALL_VALUE } from '../../tamalelibs/constants/business.constants';
import { ChangeDetectorRef } from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';

/*
 *  demo example
 *
 *  <multi-sel-dropdown-filter [dropdownConfig]='dropdownConfig'>
 *      <ng-template #multiCustomContent>
 *          <p (click)='onClick($event)'>showBtn</p>
 *          <p>other</p>
 *       </ng-template>
 *  </multi-sel-dropdown-filter>
 *
 *  hideInput - value true hidden input, show template content. default false
 *  dropdownConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(hideInput);
 *
 *  onClick(event) {
 *      .......
 *      this.serice.openPopup(event.target);
 *      ......
 *  }
*/

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'multi-sel-dropdown-filter',
    templateUrl: 'multi-sel-dropdown-filter.component.html',
    styleUrls: ['./multi-sel-dropdown-filter.component.scss'],
})
export class MultiSelDropdownFilterComponent implements OnInit, OnDestroy {
    get allDisplayText(): string {
        return this.dropdownConfig && this.dropdownConfig.allDisplayText ? this.dropdownConfig.allDisplayText : 'All';
    }
    /**
     * a flag indicating whether the search box should be focused automatically.
     */
    @Input() autoFucus = true;
    // develop for TAM-38217 Enhance the multi-select control, Set the position of the kendo-popup
    @Input() anchorAlign: Align = { horizontal: 'left', vertical: 'bottom' };
    @Input() popupAlign: Align = { horizontal: 'left', vertical: 'top' };
    @Input() dropdownConfig: MultiSelDropdownFilterConfig;
    @ContentChild('multiCustomContent', { static: false }) multiCustomContent: TemplateRef<any>;
    @ViewChild('searchInput', { static: false }) searchBox: ElementRef;
    showSel = false;
    searchValue = '';
    hideInput = true;
    anchor: ElementRef;
    inputValue = '';
    checkedData = [];
    unCheckedData = [];

    private _subscription: Subscription[] = [];

    constructor(private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this._subscription.push(
            this.dropdownConfig.open$.subscribe((anchor) => {
                this.showPopup(anchor);
            }),
            this.dropdownConfig.clear$.subscribe(() => {
                this.clearSelAll();
            }),
            this.dropdownConfig.onChangeValue$.subscribe(item => {
                this.formatValue(true);
            }),
        );
        this.hideInput = this.dropdownConfig.hideInput;
        this.formatValue(true);
    }

    ngOnDestroy() {
        this._subscription.forEach(item => item.unsubscribe());
    }

    getCheckedData() {
        return this.dropdownConfig.data.filter(item => item.checked &&
            item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1);
    }

    getUnCheckedData() {
        return this.dropdownConfig.data.filter(item => !item.checked &&
            item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1);
    }

    getSelData() {
        return this.dropdownConfig.data.filter(item => item.checked);
    }

    getSelItemsValue() {
        let str = '';
        const len = this.getSelData().length;
        if (len !== 0) {
            if (len === this.dropdownConfig.data.length) {
                str = this.allDisplayText;
            } else {
                this.getSelData().forEach((item, index) => {
                    if (index === len - 1) {
                        str += item.name;
                    } else {
                        str += (item.name + ', ');
                    }
                });
            }
        }
        return str;
    }

    clearSearch(): void {
        this.searchValue = '';
        this.checkedData = this.getCheckedData();
        this.unCheckedData = this.getUnCheckedData();
    }

    onClickOutside(event: Object) {
        if (event && event['value'] === true) {
            this.showSel = false;
            this.clearSearch();
        }
    }

    formatValue(isNotSubmit: boolean = false) {
        const str = this.getSelItemsValue();
        this.inputValue = str;
        this.checkedData = this.getCheckedData();
        this.unCheckedData = this.getUnCheckedData();
        if (!isNotSubmit) {
            const value = str === this.allDisplayText ? ALL_VALUE : this.getSelData();
            this.dropdownConfig.onChangeValue$.next(value);
        }
    }

    showPopup(event: any) {
        this.anchor = event.target || event;
        this.showSel = true;
        // fix issue when change filter data status after ngOnInit(),the data status can't refresh.
        this.formatValue(true);
        if (this.autoFucus) {
            const thatObj = this;
            setTimeout(
                () => {
                    if (thatObj.searchBox && thatObj.searchBox.nativeElement) {
                        thatObj.searchBox.nativeElement.focus();
                    }
                }, 10
            );
        }
    }

    selAll() {
        const newData = [];
        this.dropdownConfig.data.forEach((item) => {
            item.checked = true;
            newData.push(item);
        });
        this.searchValue = '';
        this.formatValue();
    }

    clearSelAll() {
        const newData = [];
        this.dropdownConfig.data.forEach((item) => {
            item.checked = false;
            newData.push(item);
        });
        this.searchValue = '';
        this.formatValue();
    }

    searchData() {
        this.checkedData = this.getCheckedData();
        this.unCheckedData = this.getUnCheckedData();
    }

    changeCheckedState(item: MultiSelDropdownConfig, event) {
        const index_data = this.dropdownConfig.data.findIndex(data => data.id === item.id);
        this.dropdownConfig.data[index_data].checked = item.checked;
        this.formatValue();
        event.stopPropagation();
        this._changeDetectorRef.detectChanges();
    }

    trackByFn(index, item) {
        return item.id;
    }
}
