/**
 * Create by
 * Description:
 *
 * ------ maintenance history ------
 * 2020/2/26  Bowen: Add parseRelTreeNode function
 * 2020/5/11  Bowen: Change company and title to stirng in model level to match server changing
 */
import { businessConstants } from '../constants/business.constants';
import { DataModelBase } from './base.model';
import { EntityType } from './entity-type.model';

export class EntityBrief extends DataModelBase {
    company?: string;
    title?: string;
    isPublic: boolean;
    shortName: string;
    type: EntityType;

    static parse(serverResponse): EntityBrief {
        let entityType = new EntityType();
        if (serverResponse['entity-type']) {
            if (serverResponse['entity-type'] === businessConstants.EntityTypes.Contact_Id) {
                entityType = EntityType.CONTACT;
            } else if (serverResponse['entity-type'] === businessConstants.EntityTypes.Team_Id) {
                entityType = EntityType.TEAM;
            } else {
                entityType = EntityType.parse(serverResponse['entity-type']);
            }
        }
        return {
            company: serverResponse['contact-details'] && serverResponse['contact-details']['web-company'] ? serverResponse['contact-details']['web-company']['name'] : '',
            title: serverResponse['contact-details'] && serverResponse['contact-details']['web-job-title'] ? serverResponse['contact-details']['web-job-title'].map(res => res.name).join(',') : '',
            id: serverResponse['id'],
            name: serverResponse['long-name'],
            shortName: serverResponse['short-name'],
            // tslint:disable-next-line: max-line-length
            isPublic: serverResponse['entity-type'] && serverResponse['entity-type'] === businessConstants.EntityTypes.Contact_Id ? true : serverResponse['is-public'] === 'true',
            type: entityType
        };
    }

    // only for relationship tree new data model
    // and because the new data model don't have the entity-type attribut, we need to create a new function, not to reuse parse function
    static parseRelTreeNode(serverResponse): EntityBrief {
        return {
            company: serverResponse['contact-details'] && serverResponse['contact-details']['web-company'] ? serverResponse['contact-details']['web-company']['name'] : '',
            title: serverResponse['contact-details'] && serverResponse['contact-details']['web-job-title'] ? serverResponse['contact-details']['web-job-title'].map(res => res.name).join(',') : '',
            id: serverResponse['id'],
            name: serverResponse['long-name'],
            shortName: serverResponse['short-name'],
            isPublic: serverResponse['is-public'] === 'true',
            type: {
                id: serverResponse['type-id'],
                name: serverResponse['type-name']
            }
        };
    }
}
