/**
 * Created by Jiu Chen on 01/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { TeamsActions, TeamsActionTypes } from '../actions/teams.actions';
import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { DataModelBase } from '../../tamalelibs/models/base.model';
import { EDIT_ENTITY_SUCCESS } from '../../tamalelibs/redux/entity.actions';

export const allTeamSelector = (state: AppState) => state.teams;
// export const allTeamSelector = createSelector(teamSelector, (state: any) => state.default);

export function teamsReducer(state: Array<DataModelBase> = [], action: TeamsActions | any) {
    switch (action.type) {
        case TeamsActionTypes.INITIALIZE_SUCCESS:
            return action.payload;
        case TeamsActionTypes.INITIALIZE_FAILED:
            return [];
        case EDIT_ENTITY_SUCCESS:
            state.forEach(item => {
                if (item.id === action.payload.id) {
                    item.name = action.payload.name;
                }
            });
            return Object.assign([], state);
        default:
            return state;
    }
}
