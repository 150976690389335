/**
 * Created by Alex on 05/30/2023.
 * Description:
 * notification model
 *
 * ------ maintenance history ------
 */

export class NotificationModel {
    id: string;
    name: string;
    description: string;
    active: boolean;
    creator: string;
    creatTime: string;
    modifyTime: string;
    reminderType: ReminderType;
    metadataType: string;
    isCopy: boolean;
    configuration: NotificationConfigModel;

    static parse(serverResponse): NotificationModel {
        const result = new NotificationModel();
        result.id = serverResponse['id'];
        result.name = serverResponse['name'];
        result.description = serverResponse['description'];
        result.creator = serverResponse['creator'] ? serverResponse['creator']['name'] : '';
        result.creatTime = serverResponse['creatTime'];
        result.modifyTime = serverResponse['modifyTime'];
        result.active = serverResponse['active'];
        result.reminderType = serverResponse['reminderType'];
        result.metadataType = serverResponse['metadataType'];
        result.configuration = serverResponse['config'];

        return result;
    }
}

export enum ChangeType {
    CREATED = 'created',
    UPDATED = 'updated'
}

export enum MetadataType {
    EVENT = 'event',
    ENTRY = 'entry',
    NOTE = 'note'
}

export enum ReminderType {
    SCHEDULED = 'scheduled',
    REALTIME = 'realtime'
}

export enum RecipientType {
    CONTACT = 'contact',
    USER = 'user',
    TEAM = 'team',
    FROM_ENTRY_METADATA = 'entryMetaData'
}

export enum SpecificActionType {
    TIME = 'TIME',
    ENTRY = 'ENTRY'
}

export enum MessageContextType {
    SINGLE_ENTRY = 'singleEntry',
    FULL_THREAD = 'fullThread',
    NO_TEXT = 'noText'
}

export enum EntityLocationInSubject {
    START = 'start',
    END = 'end',
    NONE = 'none'
}

export class Filter {
    attrDefId: Array<any>;
    dataType: string;
    operator: string;
    values: any;
    target?: string;
    includeCurrentEmployment?: boolean;
    name?: string;
    controlType?: string;
    metadataType?: string;
}

export class NotificationFilterContent {
    logicalOperator = 'and';
    field: string;
    filters: Array<Filter>;
    constructor() {
        this.filters = new Array<Filter>();
        this.field = 'contact';
    }
}

export class NotificationFilterModel {
    logicalOperator = 'or';
    filters: Array<NotificationFilterContent>;
    constructor() {
        this.filters = new Array<NotificationFilterContent>();
    }
}

export class CardOption {
    action = [];
    messageContextType: MessageContextType;
    includeEntryDetailTable: boolean;
    includeWebAttachment: boolean;
    includeWebAttachementLink: boolean;
    entityLocationInSubject: EntityLocationInSubject;
    constructor() {
        this.messageContextType = MessageContextType.SINGLE_ENTRY;
        this.includeEntryDetailTable = false;
        this.includeWebAttachment = false;
        this.includeWebAttachementLink = false;
        this.entityLocationInSubject = EntityLocationInSubject.START;
    }
}

export class TriggerPeriodModel {
    every: number;
    frequency: string;
}

export class ScheduledStart {
    number: number;
    unit: string;
    operator: string;
    time: string;
    attDefId: string;
}

export class TriggerModel {
    scheduledStart: ScheduledStart;
    triggerPeriod: TriggerPeriodModel;
    timezone: string;

    constructor() {
        this.scheduledStart = new ScheduledStart();
        this.triggerPeriod = new TriggerPeriodModel();
    }
}

export class EmailActionDetailModel {
    groupMail: boolean;
    submitterBCC: boolean;
    secureEmail: boolean;
    constructor() {
        this.groupMail = true;
        this.submitterBCC = false;
        this.secureEmail = false;
    }
}

export class RecipientModel {
    recipientType: string;
    recipientId: string[];
}

export class StopCriteriaModel {
    sideNoteCreated = {
        'sideNotetype': 'default'
    };
}

export class NotificationConfigModel {
    metadataFilter: NotificationFilterModel;
    filtersConfigList: any;
    filtersRecipientsConfigList: any;
    dateTypeAdHocList: any;
    trigger: TriggerModel;
    numberLimit: number;
    stopCriteria: StopCriteriaModel;
    actionType = [];
    recipients: Array<RecipientModel>;
    emailActionDetail: EmailActionDetailModel;
    appActionDetail: any;
    cardOption: CardOption;
    changeType: Array<string>;
    overridePrivacy: boolean;

    constructor() {
        this.metadataFilter = new NotificationFilterModel();
        this.emailActionDetail = new EmailActionDetailModel();
        this.appActionDetail = {};
        this.cardOption = new CardOption();
        this.recipients = [];
        this.overridePrivacy = false;
    }
}
