/**
 * Created by Daniel on 12/09/2020.
 */

import { AutomationMappingItem } from './automation.model';

export enum Frequency {
    MONTHLY = 'monthly',
    WEEKLY = 'weekly',
    DAILY = 'daily',
}

export class Scheduler {
    time: string;
    timezone: string;
    every: number;
    startDate: Date;
    frequency: Frequency;
    on?: string;

    constructor() {
        this.every = 1;
        this.frequency = Frequency.MONTHLY;
        this.timezone = '';
        this.startDate = null;
        this.time = '06:30';
        this.on = '1:MON';
    }

    static parse(response): Scheduler {
        const result = new Scheduler();
        result.time = response['time'];
        result.timezone = response['timezone'];
        result.every = response['every'];
        result.startDate = response['startDate'];
        result.frequency = response['frequency'];
        if (response['on']) {
            result.on = response['on'];
        }
        return result;
    }
}

export enum SchedulerWeeklyOnSelectMode {
    MULTIPLE = 'multiple',
    SINGLE = 'single',
}

export class TimeZone {
    id: string;
    name: string;
    offset: number;
    zonename: string;
}

export class TriggerMethod {
    manual: boolean;
    scheduleJob: boolean;
    emailDeposit: boolean;
    noteDeposit: boolean;
    entityDeposit: boolean;

    constructor() {
        this.manual = true;
        this.scheduleJob = false;
        this.emailDeposit = false;
        this.noteDeposit = false;
        this.entityDeposit = false;
    }

    static parse(res): TriggerMethod {
        if (!res) {
            return null;
        }
        const result = new TriggerMethod();
        result.manual = res['manual'];
        result.scheduleJob = res['scheduleJob'];
        result.emailDeposit = res['emailDeposit'];
        result.noteDeposit = res['noteDeposit'];
        result.entityDeposit = res['entityDeposit'];
        return result;
    }
}

export class Relationship {
    type: string;
    parentEntity: string;

    constructor() {
        this.type = '';
        this.parentEntity = '';
    }

    static parse(res): Relationship {
        if (!res) {
            return null;
        }
        const result = new Relationship();
        result.type = res['type'];
        result.parentEntity = res['parentEntity'];
        return result;
    }
}

export class MultipleEntity {
    entityType: string;
    isEntityTypeOptionChecked: boolean;
    relationship: Relationship;
    isRelationshipOptionChecked: boolean;

    constructor() {
        this.entityType = '';
        this.isEntityTypeOptionChecked = false;
        this.relationship = new Relationship();
        this.isRelationshipOptionChecked = false;
    }

    static parse(res): MultipleEntity {
        if (!res) {
            return null;
        }
        const result = new MultipleEntity();
        result.entityType = res['entityType'];
        result.isEntityTypeOptionChecked = res['isEntityTypeOptionChecked'];
        result.relationship = Relationship.parse(res['relationship']);
        result.isRelationshipOptionChecked = res['isRelationshipOptionChecked'];
        return result;
    }
}

export class PrimaryEntityDrpItem {
    fieldDefId: string;
    fieldDefEntityName: string;
    constructor(fieldDefId?: string, fieldDefEntityName?: string) {
        this.fieldDefId = fieldDefId;
        this.fieldDefEntityName = fieldDefEntityName;
    }
}

export class ProjectName {
    name: string;
}

export class PrimaryEntity {
    fieldDefId: string;
}

export class PrimaryEntityModel {
    entityId: string;
    fieldDefId: string;
    shortName: string;

    static parse(res): PrimaryEntityModel {
        if (!res) {
            return null;
        }
        const result = new PrimaryEntityModel();
        result.entityId = res['entityId'];
        result.fieldDefId = res['fieldDefId'];
        result.shortName = res['shortname'];
        return result;
    }
}

export class DefinitionSetting {
    projectName: ProjectName;
    primaryEntity: PrimaryEntity;

    constructor() {
        this.projectName = new ProjectName();
        this.primaryEntity = new PrimaryEntity();
    }

    static parse(res): DefinitionSetting {
        if (!res) {
            return null;
        }
        const result = new DefinitionSetting();
        result.projectName = res['projectName'];
        result.primaryEntity = res['primaryEntity'];
        return result;
    }
}

export class WorkflowTriggerSource {
    /**
     * a unique id generated by web
     */
    id: string;

    /**
     * the trigger source type
     */
    objectType: 'entry' | 'entity' = 'entry';

    /**
     * the trigger source type id and name, the entry type id for the case of trigger by note deposit.
     */
    objectTypeID: string;
    objectTypeName?: string;

    /**
     * the trigger condition.
     */
    condition: string;

    /**
     * an array of mappings between ad-hoc fields in note templates and workflow fields.
     */
    mapping: Array<AutomationMappingItem> = [];

    static parse(sources: Array<WorkflowTriggerSource>): Array<WorkflowTriggerSource> {
        const triggerSources = [];
        if (sources && Array.isArray(sources)) {
            sources.forEach(s => {
                const copyS = new WorkflowTriggerSource();
                copyS.id = s.id;
                copyS.condition = s.condition;
                copyS.mapping = s.mapping;
                copyS.objectType = s.objectType;
                copyS.objectTypeID = s.objectTypeID;
                copyS.objectTypeName = s.objectTypeName;
                triggerSources.push(copyS);
            });
        }

        return triggerSources;
    }

}

export class WorkflowTriggerSettings {
    triggerMethod: TriggerMethod;
    triggerScheduler: Scheduler;
    triggerSource: Array<WorkflowTriggerSource> = [];
    multipleEntity: MultipleEntity;
    minuteOffset: number;

    constructor() {
        this.triggerMethod = new TriggerMethod();
        this.triggerScheduler = new Scheduler();
        this.multipleEntity = new MultipleEntity();
        this.minuteOffset = 0;
    }

    static parse(res): WorkflowTriggerSettings {
        if (!res) {
            return null;
        }
        const result = new WorkflowTriggerSettings();
        result.triggerMethod = TriggerMethod.parse(res['triggerMethod']);
        result.triggerScheduler = Scheduler.parse(res['triggerScheduler']);
        result.multipleEntity = MultipleEntity.parse(res['multipleEntity']);
        result.triggerSource = WorkflowTriggerSource.parse(res['triggerSource']);
        return result;
    }
}
