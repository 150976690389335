/**
 * Created by Tamale DEV
 * Description:
 *
 * ---------maintenance history-------------
 * Updated by Daniel Wang on 05/30/2022, Added triggerType for systemConfig, It's only for UI, used to recored the trigger component.
 */

import { AutoTagging } from '../tamalelibs/models/auto-tagging.model';
import { MapTile } from '../tamalelibs/models/map-tile.model';

export class EntryConfig {
    sideNoteFollowRule: string;
    constructor(_sideNoteFollowRule: string) {
        this.sideNoteFollowRule = _sideNoteFollowRule;
    }
}

export class ExchangeSetting {
    constructor(public enable = false) {
    }
}

export class ExchangeSyncSetting {
    constructor(public enable = false) {
    }
}

export class IntralinksSetting {
    enable: boolean;
    serverUrl: string;
    uiUrl: string;

    constructor(enable: boolean, serverUrl?: string, uiUrl?: string) {
        this.enable = enable;
        this.serverUrl = serverUrl || '';
        this.uiUrl = uiUrl || '';
    }
}

export class MQSetting {
    openWirePort: number;
    message: string;
    webSocketProtocol: string;
    webSocketPort: number;
}

export class PiwikSetting {
    piwikEnable: boolean;
    piwikServer: string;
    piwikSiteId: string;

    constructor(piwikEnable: boolean, piwikServer?: string, piwikSiteId?: string) {
        this.piwikEnable = piwikEnable;
        this.piwikServer = piwikServer || '';
        this.piwikSiteId = piwikSiteId || '';
    }
}

export class SsoSetting {
    ssoEnabled: boolean;
    idpHomeUrl: string;
    idpSsoUrl: string;

    constructor(ssoEnabled: boolean, idpHomeUrl?: string, idpSsoUrl?: string) {
        this.ssoEnabled = ssoEnabled;
        this.idpHomeUrl = idpHomeUrl || '';
        this.idpSsoUrl = idpSsoUrl || '';
    }
}

export class WorkflowSetting {
    constructor(public enable = false) {
    }
}

export class SystemConfig {
    entryConfig: EntryConfig;
    sso: SsoSetting;
    piwik: PiwikSetting;
    autotag: AutoTagging;
    maptile: MapTile;
    copyNumber: string;
    serverversion: string;
    enableSlaask: boolean;
    proxyURL: string;
    showTamaleWebLink: boolean;
    mq: MQSetting;
    accessTamaleDirectly: boolean;
    intralinks: IntralinksSetting;
    workflow: WorkflowSetting;
    exchange: ExchangeSetting;
    autoSyncSetConfig: ExchangeSyncSetting;
    triggerType: string;
    webTokenDesKey: string;
    mdlEncryptDesKey: string;

    constructor() {
        this.entryConfig = new EntryConfig('');
        this.sso = new SsoSetting(false);
        this.piwik = new PiwikSetting(false);
        this.autotag = new AutoTagging();
        this.maptile = new MapTile();
        this.enableSlaask = false;
        this.showTamaleWebLink = false;
        this.mq = new MQSetting();
        this.accessTamaleDirectly = false;
        this.intralinks = new IntralinksSetting(false);
        this.workflow = new WorkflowSetting();
        this.exchange = new ExchangeSetting();
        this.autoSyncSetConfig = new ExchangeSyncSetting();
        this.triggerType = '';
        this.webTokenDesKey = '';
        this.mdlEncryptDesKey = '';
    }

    static parse(res): SystemConfig {
        const result = new SystemConfig();
        result.sso = new SsoSetting(res['sso'].enableSSO, res['sso'].idphome, res['sso'].message);
        result.piwik = new PiwikSetting(res['piwik'].piwikEnable, res['piwik'].piwikServer, res['piwik'].piwikSiteId);
        result.autotag = AutoTagging.parse(res['autotag']);
        result.maptile = MapTile.parse(res['maptile']);
        result.copyNumber = res['copyNumber'];
        result.serverversion = res['serverversion'];
        result.enableSlaask = res['enableSlaask'];
        result.proxyURL = res['proxyURL'];
        result.showTamaleWebLink = res['showTamaleWebLink'];
        result.mq = res['mq'];
        result.accessTamaleDirectly = res['accessTamaleDirectly'];
        result.intralinks = new IntralinksSetting(res['intralinks'].enable, res['intralinks'].serverUrl, res['intralinks'].uiUrl);
        result.workflow = new WorkflowSetting(res['workflow'] ? res['workflow']['enable'] : false);
        result.exchange = res['exchange'];
        result.autoSyncSetConfig = res['autoSyncSetConfig'];
        result.entryConfig = new EntryConfig(res['entryConfig']['sideNoteFollowRule']);
        result.webTokenDesKey = res['webTokenDesKey'];
        result.mdlEncryptDesKey = res['mdlEncryptDesKey'];
        return result;
    }
}
