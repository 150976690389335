/**
 * Created by Alan Yang on 3/16/2017
 * Description:
 *   entry version data.  Exists in Tamale Core. Not in Tamale Web. Keep it here.
 *
 * ---------maintenance history-------------
 */
import { TSGuid } from './ts-guid.model';
import { TSItem } from './ts-item.model';
import { Sentiment } from './sentiment.model';
import { Priority } from './priority.model';
import { EntryClass } from './entry-class.model';
import { EntryFile } from './entry-file.model';
export class EntryVersion {
    id: TSGuid;
    displayDate: Date;
    submittedDate: Date;
    blurb: string;
    isBackdated: boolean;
    submitterID: TSGuid;
    sourceID: TSGuid;
    entryTypeID: TSGuid;
    title: string;
    entryFile: EntryFile;
    sentiment: Sentiment;
    priority: Priority;
    hidden: boolean = null;
}
