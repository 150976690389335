/**
 * Created by Alan Yang on 3/16/2017
 * Description:
 *   Entry is the base class of Note/Document/Calendar.
 *   For better usage, an entry can have notebody, entities, contacts, and attachments.
 *   The caller can control the response data by proper parameter in RestAPI.
 *      Eg:http://10.2.154.31/restapi/2.1/thread/?draft=false&outputformat=json&page=1&rpp=20&showpermission=true
 * &sortby=displaydateofthread&sortorder=desc
 *      &expand=thread;attachments;entry;entities;entity;source;submitter;
 *      &showattachmentsbyentry=true
 *      In this request, the caller wants server to return data with expanded attachment node, entity node, contact node
 *      with detailed info instead of only link.  it has full entity, contact info. For attachment, it's attachmentId.
 *
 *  "Side Note" is the same level thing with its parent note in Tamale system, it's just behind parentnote but not belongs to it.
 *  you can access it under thread via index, eg: thread.notes[1].  Not parentnote.sidenote.
 *
 * ---------maintenance history-------------
 *
 * ver 1.0.1 modified by Yu Zhang on 3/30/17.
 *      add attachmentIds: TSGuid[] array to save all attachment ids for this entry
 *
 * ver 1.0.2 modified by Yu Zhang on 7/31/17.
 *      change entityNames and entityLinks to entities, {name: string, link: string}[]
 */
import { TSGuid } from './ts-guid.model';
import { TSItem } from './ts-item.model';
import { Sentiment } from './sentiment.model';
import { Priority } from './priority.model';
import { TSAttribute } from './ts-attribute.model';
import { EntryClass } from './entry-class.model';
import { EntryFile } from './entry-file.model';
import { EntryType } from './entry-type.model';
import { EntryVersion } from './entry-version.model';

export class Entry extends TSItem {
    static ENTITY_ID = 'EntityID';
    static SOURCE_ID = 'SourceID';
    static SUBMITTER_ID = 'SubmitterID';
    static PRIORITY = 'Priority';
    static DISPLAY_DATE = 'DisplayDate';
    static PARENT_ENTRY_ID = 'ParentEntryID';
    static ENTRY_TYPE_ID = 'EntryTypeID';
    static SIZE = 'Size';
    static TITLE = 'Title';

    // metadata
    calculatedSubject = '';
    subject = '';
    subjectLink: string;
    entities: { name: string, link: string, type: string }[] = [];
    entitiesInfo: string;
    sourceName = '';
    sourceId: string;
    sourceLink: string;
    companyName: string;
    submitterName = '';
    submitterId: string;
    sentiment: Sentiment = 0;
    priority: Priority = 0;
    lastEditDate: Date;
    displayDate: Date = new Date();
    submittedDate: Date = new Date();
    noteType = '';           // note or side note
    backdated = false;

    // body
    adHocTable: any;
    body: any = '';
    bodyLink = '';
    date: Date;
    dateSpan: string;
    entryType: EntryType;
    editable = false;
    deletable = false;
    blurb: string; // it's the pre x {1<x<1024} charactors of notebody; showblue=true;

    // Tamale Core
    size: number;
    title: string;
    hidden: boolean;
    entryClass: EntryClass;
    entryFile: EntryFile;
    currentVersionID: TSGuid;
    parentEntryID: TSGuid;
    isTopLevelEntry: boolean;
    entityIDs: TSGuid[]; // expend=entities;entity; RestAPI returns all; or expend=entities, only return entityId list.
    entryTypeID: TSGuid;
    sourceID: TSGuid;
    submitterID: TSGuid;
    accessDate: Date;
    originalSubmittedDate: Date;
    hasMultipleSubmitters: boolean;
    parentEntry: Entry;
    tsAttributes: TSAttribute[]; // adhoc
    entryVersion: EntryVersion = new EntryVersion();
}
