import { Action } from '@ngrx/store';
import { FiltersConfig } from '../../tamalelibs/components/filter-config';

// audit log related actions
export enum AuditLogActionTypes {
    SET_FILTER_LIST = '[AUDIT][FILTER] SET_FILTER_LIST',
    ADD_FILTER_LIST = '[AUDIT][FILTER] ADD_FILTER_LIST',
    DELETE_FILTER_LIST = '[AUDIT][FILTER] DELETE_FILTER_LIST'
}

export class AuditLogSetFilterList implements Action {
    readonly type = AuditLogActionTypes.SET_FILTER_LIST;

    constructor(public payload: Array<FiltersConfig>) { }
}

export class AuditLogAddFilterList implements Action {
    readonly type = AuditLogActionTypes.ADD_FILTER_LIST;

    constructor(public payload: Array<FiltersConfig>) { }
}

export class AuditLogDelFilterList implements Action {
    readonly type = AuditLogActionTypes.DELETE_FILTER_LIST;

    constructor(public payload: Array<FiltersConfig>) { }
}

export type AuditLogActions = AuditLogSetFilterList | AuditLogAddFilterList | AuditLogDelFilterList;
