
/**
 * Created by Alex Xia on 10/11/18.
 * Description:
 *
 *  * ------ maintenance history ------
 * 08/17/21 Lucas Wang: added support for Admin Page Users Requests
 * 12/09/21 Cathy Yao: Add Password Management for Web-Only Users with Local Authentication
 * 09/06/22 Holly Li: Modify editUser function to delete attributes not changed before sent to server.
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../models/app-config.model';
import { EntityType } from '../models/entity-type.model';
import { User, USER_SETTING_ROOT_NODE_NAME } from '../models/user.model';
import { TransportService } from './transport.service';

@Injectable()
export class UsersService {

    constructor(
        private _transportService: TransportService
    ) { }

    createNewUser(contactId: string, newUser: User): Observable<any> {
        const url = `${AppConfig.userEndpoint}`;
        const params = {
            'contactID': contactId,
            'username': newUser.username,
            'password': newUser.password,
            'secure-email': newUser.primaryEmail,
            'credentials': newUser.credentials,
            'teams': newUser.inTeams,
        };
        const formData = new FormData();
        formData.append('user', JSON.stringify(params));
        return this._transportService.post(url, formData);
    }

    /**get user id from userendpoint*/
    deleteUser(userId: string): Observable<any> {
        const url = `${AppConfig.userEndpoint}${userId}`;
        return this._transportService.delete(url);
    }

    editUser(editUser: User, userId: string): Observable<any> {
        const url = `${AppConfig.userEndpoint}${userId}`;
        let params: object;
        params = {
            'username': editUser.username,
            'secure-email': editUser.primaryEmail,
        };
        if (editUser.password) {
            /** should put params with password when there is password */
            params['password'] = editUser.password;
        }
        if (editUser.inTeams) {
            params['teams'] = editUser.inTeams;
        }
        if (editUser.credentials) {
            params['credentials'] = editUser.credentials;
        }

        const formData = new FormData();
        formData.append('user', JSON.stringify(params));
        return this._transportService.put(url, formData);
    }

    getAdminPageUsersList(): Observable<any> {
        const url = `${AppConfig.userEndpoint}`;
        return this._transportService.get(url);
    }

    getAll(): Observable<any> {
        // MUST use entity endpoint to query user as BA's requirement is using Contact name rather than username
        const url = AppConfig.entityListEndpoint;
        const params = {
            advfilter: `(entity-type id equals "${EntityType.USER.id}")`,
            fields: 'id;long-name;short-name;aliases;primary-email;is-public',
            expand: 'aliases;alias',
            outputformat: 'json'
        };

        const options = {
            params: params
        };

        return this._transportService.get(url, options);
    }

    getUserSetting() {
        const url = AppConfig.userSettingEndPoint;
        const params = {
            outputformat: 'json'
        };
        const options = {
            params: params
        };
        return this._transportService.get(url, options);
    }

    getUsersByTeamId(teamId: string): Observable<any> {
        const url = `${AppConfig.teamEndpoint}${teamId}/members`;
        const headers = {
            'Content-Type': 'application/json'
        };
        const params = {
            expand: 'entity',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getUserDetail(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.userEndpoint}${id}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    updateUserSetting(config) {
        const url = AppConfig.userSettingEndPoint;
        const formData = new FormData();
        formData.append(USER_SETTING_ROOT_NODE_NAME, JSON.stringify(config));
        return this._transportService.post(url, formData);
    }

    /**
    * change password by patch
    *
    * @param {*} data
    * @returns {Observable<any>}
    * @memberof UserPasswordService
    */
    userPasswordChangePatch(id: string, password: string, oldpassword: String): Observable<any> {
        const formData = new FormData();
        formData.append('user', JSON.stringify({ 'oldPassword': oldpassword, 'password': password }));
        const url = `${AppConfig.userEndpoint}${id}`;
        return this._transportService.patch(url, formData, {});
    }
}
