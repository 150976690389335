/**
 * Created by Todd Yu on 06/20/2020.
 * Description: Editor component for creating and editing entity types
 * ------ maintenance history ------
 * Modified by Teddy Ding on 12/15/20. Support tamale slide-sheet component
 * Modified by Lucas Wang on 8/8/2021. Refine the code to match coding standard
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UntypedFormControl } from '@angular/forms';

import { AppState } from '../../../../../redux';
import { Subscription } from 'rxjs';
import * as _lodash from 'lodash';

import { CreateNewEntityType, EditEntityType } from '../../../../../redux/actions/admin-page.actions';
import { AdminPageState, AdminPageStatusTypes } from '../../../../../redux/reducers/admin-page.reducer';
import { SlideSheetService } from '../../../../../services/slide-sheet.service';
import { SlideSheetActionTypes } from '../../../../slide-sheet/slide-sheet.model';
import { StringLiteralsPipe } from '../../../../../pipes/translate.pipe';
import { AlertWindowService } from '../../../../../widgets/alert-window/alert-window.service';

@Component({
    selector: 'tam-entity-types-editor',
    templateUrl: './entity-types-editor.component.html',
    styleUrls: ['./entity-types-editor.component.scss']
})
export class EntityTypesEditorComponent implements OnInit, OnDestroy {
    @Input() entityData?: any;

    title = 'Create New Entity Type';
    currEntityData: any = {
        'name': '',
        'display-web': true,
        'id': '',
        'href': '',
    };
    isProgressing = false;
    nameDuplicated = false;
    name = new UntypedFormControl('');
    isChanged = false;

    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _alertWindow: AlertWindowService,
        private _store: Store<AppState>,
        private _slideSheetService: SlideSheetService,
    ) { }

    ngOnInit() {
        this.editorInit();
        this._destroySubscriptions.push(this._store.pipe(
            select('adminPage')
        ).subscribe((pageState: AdminPageState) => this._parsePageState(pageState))
        );
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    configChanged() {
        this.isChanged = true;
    }

    /**Initialize editor data when menu is opened*/
    editorInit() {
        if (!this.entityData) {
            this.title = 'Create New Entity Type';
            this.currEntityData = {
                'name': '',
                'display-web': true,
                'id': '',
                'href': '',
            };
        } else {
            this.title = 'Edit Entity Type';
            if ('display-web' in this.entityData) {
                this.currEntityData = _lodash.cloneDeep(this.entityData);
            } else {
                this.currEntityData = _lodash.cloneDeep(this.entityData);
                this.currEntityData['display-web'] = true;
            }
        }
    }

    onSlideSheetClose() {
        if (this.isChanged) {
            const message = `Are you sure you want to discard all your changes?`;
            const subscription = this._alertWindow.warn('You have unsaved changes',
                [message], StringLiteralsPipe.translate('general.discard'), StringLiteralsPipe.translate('general.go_back'))
                .subscribe((result: boolean) => {
                    if (result) {
                        this._slideSheetService.slideSheetActionSubject$.next({
                            type: SlideSheetActionTypes.CLOSE
                        });
                    }
                    subscription.unsubscribe();
                });
        } else {
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CLOSE
            });
        }
    }

    /** Check the length of name. When the length of the name is valid, dispatches action to the store to create new entity or edit entity.  */
    onSubmitClick() {
        this.name.setErrors(null);
        this.nameDuplicated = false;
        if (!this.name.value) {
            this.name.setErrors({
                required: true
            });
        } else if (this.name.value.length > 128) {
            this.name.setErrors({
                maxlength: true
            });
        } else {
            this.isProgressing = true;
            if (this.title === 'Create New Entity Type') {
                this._store.dispatch(new CreateNewEntityType({
                    name: this.currEntityData['name'],
                    data: this.currEntityData,
                    successMessage: `${this.currEntityData['name']} successfully created.`
                }));
            } else {
                this._store.dispatch(new EditEntityType({
                    name: this.currEntityData['name'],
                    id: this.currEntityData['id'],
                    data: this.currEntityData,
                    successMessage: `${this.currEntityData['name']} successfully edited.`
                }));
            }
            this.isChanged = false;
        }
    }

    /**Notify user of error of duplicate name when create/edit note types*/
    private _parsePageState(state: AdminPageState) {
        if (state.successStatus === AdminPageStatusTypes.FAILURE) {
            if (state.failureMessage.search('ERR_DUPLICATE_ENTITY_TYPE') !== -1) {
                this.nameDuplicated = true;
                this.isProgressing = false;
                return;
            }
        } else if (state.successStatus === AdminPageStatusTypes.SUCCESS) {
            this.isProgressing = false;
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CLOSE,
                payload: null
            });
        }
    }
}
