/**
 * Created by Daniel Wang 3/11/2024.
 * Description: the model of custom action column for ag-grid
 * ------ maintenance history ------
 */

export enum GridCustomColumnFeedbackTypes {
    EDIT = 'edit',
    DELETE = 'delete'
}

export class GridCustomColumnFeedback {
    type: GridCustomColumnFeedbackTypes;
    payload?: any;
}
