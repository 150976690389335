import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { EffectsModule } from '@ngrx/effects';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { LoginGuard, AdminLoginGuard } from './login.guard';
import { AuthEffects } from '../../redux/effects/auth.effects';
import { PluginErrorModule } from '../plugin-error/plugin-error.module';
import { LoginFormModule } from '../../components/login-form/login-form.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PluginErrorModule,
        LoginRoutingModule,
        LoginFormModule,
        EffectsModule.forFeature([
            AuthEffects
        ])
    ],
    declarations: [
        LoginComponent
    ],
    providers: [
        LoginGuard,
        AdminLoginGuard
    ]
})
export class LoginModule { }
