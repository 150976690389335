/**
 * Created by Yoyo Fang on 04/24/2019.
 * Description:
 * store the configurations of each smart component using smart reducer
 * ------ maintenance history ------
*/

export class SmartReducerViewConfigs {
    constructor(
        public ReducerName: string,
        public AddActionTypeName: string,
        public DeleteActionTypeName: string,
        public ActionKeyName: string,
        public OriginalParentStateName: string
    ) { }
}

export const ResearchViewConstants = new SmartReducerViewConfigs('researchScreenReducer', 'add-research', 'delete-research', 'prefix', 'researchPage');

export const BlastEmailViewConstants = new SmartReducerViewConfigs('blastEmailReducer', 'add-blast-email', 'delete-blastemail', 'prefix', 'blastEmailView');

export const FileViewConstants = new SmartReducerViewConfigs('fileViewReducer', 'add-file-view', 'delete-fileview', 'prefix', 'fileView');

export const ContactsViewConstants = new SmartReducerViewConfigs('contactListReducer', 'add-contacts', 'delete-contacts', 'prefix', 'contactList');

export const CalendarsViewConstants = new SmartReducerViewConfigs('calendarListReducer', 'add-calendars', 'delete-calendars', 'prefix', 'calendarList');

export const EntitiesViewConstants = new SmartReducerViewConfigs('entityListReducer', 'add-entities', 'delete-entities', 'prefix', 'entityList');

export const DashboardViewConstants = new SmartReducerViewConfigs('dashboardScreenReducer', 'add-dashboard', 'delete-dashboard', 'prefix', 'dashboardPage');

export const EntityConfigurationConstants = new SmartReducerViewConfigs('entryTemplateReducer', 'add-entryTemplate', 'delete-entryTemplate', 'prefix', 'entryTemplate');

export const CalendarConstants = new SmartReducerViewConfigs('calendarReducer', 'add-calendar', 'delete-calendar', 'prefix', 'calendar');

export const ContactConfigurationConstants = new SmartReducerViewConfigs('contactTemplateReducer', 'add-contactTemplate', 'delete-contactTemplate', 'prefix', 'contactTemplate');
