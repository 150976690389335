/**
 * Created by Yu Zhang on 5/23/17.
 * Description:
 *
 * ------ maintenance history ------
 */
import { createSelector } from '@ngrx/store';
import {
    GET_NOTE_SUCCESS, GET_NOTE_FAILURE,
    GET_NOTE_BODY_SUCCESS, GET_NOTE_BODY_FAILURE,
    DELETE_NOTE_SUCCESS, DELETE_NOTE_FAILURE,
    CREATE_NOTE_SUCCESS, CREATE_NOTE_FAILURE,
    UPDATE_NOTE_SUCCESS, UPDATE_NOTE_FAILURE,
    DELETE_SIDENOTE_SUCCESS, DELETE_SIDENOTE_FAILURE,
    DELETE_ATTACHMENT_SUCCESS, DELETE_ATTACHMENT_FAILURE,
    CLEAR_NOTE_MSG, CHANGE_NOTE_BODY_FOCUS, CLEAN_NOTE_BODY_FOCUS,
    CREATE_DRAFT_SUCCESS, UPDATE_DRAFT_SUCCESS, CREATE_DRAFT_FAILURE,
    UPDATE_DRAFT_FAILURE, DELETE_DRAFT_FAILURE, DELETE_DRAFT_SUCCESS,
    DELETE_EVENT_SUCCESS, DELETE_EVENT_FAILURE, DELETE_NOTES_SUCCESS,
    DELETE_NOTES_FAILURE, REMOVE_ENTITIES, CLEAN_REMOVE_ENTITIES, SAVE_NOTE,
    APPEND_ENTITIES,
    APPEND_ENTITIES_SUCCESS,
    APPEND_ENTITIES_FAILURE,
    APPEND_ALL_ENTITIES_SUCCESS,
    APPEND_ALL_ENTITIES_FAILURE
} from './note.actions';
import { NoteEntry } from '../models/note-entry.model';

export const getNoteState = (state: any) => state;
export const getNoteSelector = createSelector(getNoteState, (state: any) => state.note);

export function noteFocusReducer(state: NoteEntry, action) {
    switch (action.type) {
        case GET_NOTE_SUCCESS:
            return action.payload.note;
        case GET_NOTE_BODY_SUCCESS:
            const note = Object.assign({}, state);
            note.body = action.payload.noteBody.body;
            note.adHocTable = action.payload.noteBody.adHocTable;
            return note;
        default:
            return state;
    }
}

export function noteReducer(state: string = '', action) {
    switch (action.type) {
        case SAVE_NOTE:
            return action.payload;
        default:
            return state;
    }
}

export function noteMsgReducer(state: string = '', action) {
    switch (action.type) {
        case GET_NOTE_SUCCESS:
        case GET_NOTE_BODY_SUCCESS:
        case DELETE_SIDENOTE_SUCCESS:
        case DELETE_NOTE_SUCCESS:
            return action.type + '-' + action.payload.componentId;
        case CREATE_NOTE_SUCCESS:
        case UPDATE_NOTE_SUCCESS:
        case CREATE_DRAFT_SUCCESS:
        case UPDATE_DRAFT_SUCCESS:
            return action.type;
        case DELETE_NOTE_SUCCESS:
        case DELETE_NOTES_SUCCESS:
        case DELETE_EVENT_SUCCESS:
        case DELETE_DRAFT_SUCCESS:
        case DELETE_ATTACHMENT_SUCCESS:
            return action.type + '-' + action.payload;
        case GET_NOTE_FAILURE:
        case GET_NOTE_BODY_FAILURE:
        case CREATE_NOTE_FAILURE:
        case UPDATE_NOTE_FAILURE:
        case DELETE_NOTE_FAILURE:
        case DELETE_NOTES_FAILURE:
        case DELETE_EVENT_FAILURE:
        case CREATE_DRAFT_FAILURE:
        case UPDATE_DRAFT_FAILURE:
        case DELETE_DRAFT_FAILURE:
        case DELETE_SIDENOTE_FAILURE:
        case DELETE_ATTACHMENT_FAILURE:
            return `${action.type}: ${action.payload}`;
        case CLEAR_NOTE_MSG:
            return '';
        default:
            return state;
    }
}

export function noteBodyFocusReducer(state: string = '', action) {
    switch (action.type) {
        case CHANGE_NOTE_BODY_FOCUS:
            return action.payload;
        case CLEAN_NOTE_BODY_FOCUS:
            return '';
        default:
            return state;
    }
}

export function removeEntitiesReducer(state: [], action) {
    switch (action.type) {
        case REMOVE_ENTITIES:
            return action.payload;
        case CLEAN_REMOVE_ENTITIES:
            return [];
        default:
            return state;
    }
}

export function appendEntitiesReducer(state: [], action) {
    switch (action.type) {
        case APPEND_ENTITIES_SUCCESS:
        case APPEND_ENTITIES_FAILURE:
            return action.type;
        default:
            return state;
    }
}

export function appendAllEntitiesReducer(state: [], action) {
    switch (action.type) {
        case APPEND_ALL_ENTITIES_SUCCESS:
        case APPEND_ALL_ENTITIES_FAILURE:
            return action.type;
        default:
            return state;
    }
}

