/**
 * Created by Ella Ma on 5/9/19.
 * Description:
 *
 * ------ maintenance history ------
 * Updated by Abner Sui on 06/12/2019, Added context menu for relationship column.
 * Updated by Daniel Wang on 6/27/2019, Changed open thread function from opening a new tab to sidesheet.
 * Updated by Daniel Wang on 11/15/2023, Correct the number and date type when exporting data to excel from dashboard grid.
 */

import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable, Subscription } from 'rxjs';
import { NoteDialogOpenOptions, NoteDialogType } from '../../components/note-dialog/note-dialog.model';
import { NoteDialogService } from '../../components/note-dialog/note-dialog.service';
import {
    CREATE_NOTE_SUCCESS, UPDATE_NOTE_SUCCESS, DELETE_NOTE_SUCCESS,
    DELETE_SIDENOTE_SUCCESS,
    UPDATE_EVENT_SUCCESS,
    DELETE_NOTE,
    DELETE_SIDENOTE
} from '../../tamalelibs/redux/note.actions';
import {
    TamDashboardEvents, TileIcons, TamDashboardActions,
    TamDashboardItem, TamDashboardTileType
} from '../../components/tam-dashboard/tam-dashboard.model';
import { EntityDialogOpenOptions, EntityDialogType } from '../../components/entity-dialog/entity-dialog.model';
import { EntityDialogService } from '../../components/entity-dialog/entity-dialog.service';
import { TileService } from '../../tamalelibs/services/tile.service';
import { take, map, delay } from 'rxjs/operators';
import { EntryService } from '../../tamalelibs/services/entry.service';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { DateHelperWebService } from '../../tamalelibs/services/date-helper.web.service';
import * as anchorme from 'anchorme';
import { Entity } from '../../tamalelibs/models/entity.model';
import { defaultShortName, COLORS, TileColumnType, Alignment } from '../../tamalelibs/models/tile-column.model';
import { TileModel } from '../../tamalelibs/models/tile.model';
import { QueryService } from '../../services/query.service';
import { StoreQuerierService } from '../../services/store-querier.service';
import { availableEntryTypeSelector } from '../../redux/reducers/entry-type.reducer';
import { EntryType } from '../../tamalelibs/models/entry-type.model';
import { ThreadDetailService } from '../../services/thread-detail.service';
import { EntityService } from '../../tamalelibs/services/entity.service';
import { Contact } from '../../tamalelibs/models/contact.model';
import { EntityType } from '../../tamalelibs/models/entity-type.model';
import { lastCustomActionSelector } from '../../redux/reducers/action-history.reducer';
import { Store, select } from '@ngrx/store';
import { EDIT_CONTACT_SUCCESS } from '../../tamalelibs/redux/app.actions';
import { GridType } from 'angular-gridster2';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CalendarDialogOpenOptions, CalendarDialogType } from '../../components/calendar-dialog/calendar-dialog.model';
import { ContactTemplateOpenOptions, ContactTemplateType } from '../../components/contact-dialog-new/contact-dialog.model';
import { CalendarDialogService } from '../../components/calendar-dialog/calendar-dialog.service';
import { ProviderColumnType } from '../../tamalelibs/models/dataprovider.model';
import { NoteService } from '../../services/note.service';
import { EntryClass } from '../../tamalelibs/models/entry-class.model';
import { AppState } from '../../redux';
import * as _ from 'lodash';
import { DashboardActionTypes } from '../../redux/actions/dashboard.actions';
import { ContactTemplateService } from '../../components/contact-dialog-new/contact-template.service';
import { EDIT_ENTITY_SUCCESS } from '../../tamalelibs/redux/entity.actions';
import { DashboardGridNoteService } from './dashboard-grid-note.service';
import { NoteOpType, NumberType } from './dashboard.model';
import { AlertButton, AlertBtnTypes } from '../../widgets/alert-window/alert-window.model';
import { SlideSheetActionTypes } from '../../components/slide-sheet/slide-sheet.model';
import { SlideSheetService } from '../../services/slide-sheet.service';
import { GridColDef } from '../../components/tam-dashboard/tam-dashboard-tile.base.config';
import { businessConstants } from '../../constants/business.constants';
import { COMMON_DELIMITER } from '../../tamalelibs/services/array-helper.service';
import { NOT_FORMAT_LINK_PARA_NAME } from '../../tamalelibs/services/workflow.service';

const BLANK_VALUE = '(Blanks)';

@Injectable({
    providedIn: 'root'
})

export class DashboardService implements OnDestroy {

    embedInRichClient = false;
    dashboardPrintFromServer = false;

    private _destroySubscriptions: Array<Subscription> = [];
    private _feedbackSubject$: Subject<any>;
    private _actionSubject$: Subject<any>;
    private _tileId: string;
    private _isIPad = false;
    private _colors = _.cloneDeep(COLORS);
    private _tileConfig;
    private _tileEntityIdsMap = new Map();
    private _opNoteType: string;
    private _gridFilterParams;
    private _notePreviousEntityIds = [];

    constructor(
        private _alertWindow: AlertWindowService,
        private _contactDialogService: ContactTemplateService,
        private _calendarDialogService: CalendarDialogService,
        private _entryService: EntryService,
        private _entityService: EntityService,
        private _entityDialogService: EntityDialogService,
        private _noteDialogService: NoteDialogService,
        private _noteService: NoteService,
        private _queryService: QueryService,
        private _router: Router,
        private _slideSheetService: SlideSheetService,
        private _storeQuerier: StoreQuerierService,
        private _store: Store<AppState>,
        private _tileService: TileService,
        private _threadDetailService: ThreadDetailService,
        private _deviceService: DeviceDetectorService,
        private _dashboardGridNoteService: DashboardGridNoteService,
    ) {
        this._destroySubscriptions.push(
            this._store.pipe(select(lastCustomActionSelector)).subscribe(action => {
                this._successHandler(action['type']);
            })
        );
        this._isIPad = this._deviceService.isMobile() || this._deviceService.isTablet();
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    cellRenderer(col, params, isExportToExcel = false) {
        if (this.isEntityPrivacyColumn(col)) {
            if (params.value === 'true') {
                return 'Public';
            }
            if (params.value === 'false') {
                return 'Private';
            }
            return params.value;
        }
        if (this.isNumberType(col.dataType)) {
            let isShowCountCell = false; // group count | bottom grid count cell
            let decimalPlaces = col.settings.decimalPlaces;
            if (params.column && params.column.aggFunc === 'count') {
                // top grid group node | bottom grid common node - count not need format
                if (params.node.group || (params.colDef && params.colDef.fromGrid === 'bottom')) {
                    isShowCountCell = true;
                    decimalPlaces = 0;
                }
            }
            const factor: boolean = (params.value || params.value === 0) && !isNaN(Number(params.value)); // value can be '(Blanks)'
            if (isExportToExcel) {
                let currentData = params.value;
                if (col.dataType === NumberType.Currency) {
                    currentData = Math.abs(currentData);
                }
                if (col.settings) {
                    // logic to process abs for number
                    if ((col.settings.negatives + '') === '2') {
                        currentData = Math.abs(currentData);
                    }
                }
                return currentData;
            } else {
                if (col.dataType === NumberType.Ratio && factor) {
                    let str = params.value.toString();
                    str = this._wipeOffScientificNotation(str);
                    str = this._formatStr(str, decimalPlaces);
                    if (isShowCountCell) {
                        return str;
                    }
                    return str + 'x';
                }
                if (col.dataType === NumberType.Percent && factor) {
                    if (!isShowCountCell) {
                        params.value = params.value * 100;
                    }
                    let str = params.value.toString();
                    str = this._wipeOffScientificNotation(str);
                    str = this._formatStr(str, decimalPlaces);
                    if (isShowCountCell) {
                        return str;
                    }
                    return str + '%';
                }
                if (col.dataType === NumberType.Currency && factor) {
                    const isMinus: boolean = params.value < 0;
                    let str: string = Math.abs(params.value).toString();
                    str = this._wipeOffScientificNotation(str);
                    str = this._formatStr(str, decimalPlaces);
                    if (isShowCountCell) {
                        return str;
                    }
                    return isMinus ? '$(' + str + ')' : '$' + str;
                }
                if (col.dataType === NumberType.Number && factor) {
                    let str = params.value.toString();
                    str = this._wipeOffScientificNotation(str);
                    str = this._formatStr(str, decimalPlaces);
                    if (isShowCountCell) {
                        return str;
                    }
                    return str;
                }
            }
        }
        if (this.isDateType(col.dataType) && params.value && params.value !== BLANK_VALUE) {
            const dateType = this._formatDateType(col.settings.display);
            return DateHelperWebService.getDateString(new Date(params.value), dateType);
        }
        const isNonEmptyArray = params.value && Array.isArray(params.value) && Array.from(params.value).length > 0;
        if (this.isFileType(col.guid) || isNonEmptyArray) {
            // handle file-typed column, the value of which is an array and need to format prior to export.
            // there's a check above if current cell is file-typed, however the way it uses(hard-coded column id) does not work any more.
            const getArrayMemberDisplayNameFunc = (m: any) => {
                return m && m.displayName ? m.displayName : '';
            };
            if (isNonEmptyArray) {
                return Array.from(params.value).map(pV => getArrayMemberDisplayNameFunc(pV)).join(COMMON_DELIMITER);
            } else {
                return '';
            }
        }
        // formula example workday('2014/12/23',4), dataType-text
        if (params.value instanceof Date) {
            return params.value.toString();
        }
        if (params[NOT_FORMAT_LINK_PARA_NAME]) {
            return params.value;
        }
        params.value = this.formatLabelStrToString(params.value);
        if (typeof params.value === 'string' && col.dataType === 'text') {
            params.value = this.formatLabelStrToLink(params.value);

        }
        return params.value;
    }

    /**
     * Excel the date column only conforms to its own format, will be displayed as the date column
     * @param col
     * @param params
     */
    dateColumnCellRendererForExcel(col, params): String {
        if (params.value) {
            const tempValue = new Date(params.value);
            const display = col.settings.display;
            if (display === '0') { // Date and Time MM/dd/yyyy hh:mm TT -> yyyy-MM-ddTHH:mm
                const value = DateHelperWebService.getDateString(tempValue, 'yyyy-MM-dd HH:mm');
                const arr = value.split(' ');
                return `${arr[0]}T${arr[1]}`;
            } else if (display === '1') { // Date Only MM/dd/yyyy' -> yyyy-MM-dd
                return DateHelperWebService.getDateString(tempValue, 'yyyy-MM-dd');
            } else if (display === '2') { //  Month and Year 'MMM yyyy' -> yyyy-MM-dd
                return DateHelperWebService.getDateString(tempValue, 'yyyy-MM-dd');
            }
            // Year Only yyyy -> can not convert
            const dateType = this._formatDateType(display);
            return DateHelperWebService.getDateString(tempValue, dateType);
        }
        return params.value;
    }

    /**
     * Export the data of given grid into an XLS file.
     * @param columnApi the grid column api
     * @param gridColDefs an array of column definitions
     * @param gridApi the grid api
     * @param sheetName the sheet name
     * @param excelFileName the file name
     */
    exportToXLS(columnApi: any, gridColDefs: Array<any>, gridApi: any, sheetName: string, excelFileName: string) {
        const observable = new Observable(subscriber => {
            subscriber.next();
            subscriber.complete();
        });
        observable.pipe(
            take(1),
            delay(250) // wait for grid exportStyles to update
        ).subscribe(() => {
            const columns = columnApi.getColumnState();
            const groupColumn = columns.filter(item => item.rowGroupIndex !== null);
            const groupColumnSettings = [];
            const customHeader = [];
            const allCustomColumns = this.initGridHeader(columnApi);
            groupColumn.forEach(column => {
                const index = gridColDefs.findIndex(item => item.guid === column.colId);
                if (index !== -1) {
                    groupColumnSettings.push({ ...gridColDefs[index], rowGroupIndex: column.rowGroupIndex });
                }
            });
            groupColumnSettings.sort((a, b) => a.rowGroupIndex - b.rowGroupIndex);
            allCustomColumns.forEach(column => {
                customHeader.push({
                    data: {
                        type: 'String',
                        value: column.name,
                    },
                    styleId: 'font_size10'
                });
            });
            const exportExcelFunc = (fileName: string) => {
                gridApi.exportDataAsExcel({
                    fileName: fileName,
                    sheetName: _.escape(sheetName),
                    skipColumnHeaders: false,
                    processCellCallback: (param) => {
                        if (typeof param.value === 'string' && !param.value) {
                            return '';
                        }
                        if (param.node.group) {
                            if (typeof param.value === 'string' && param.value) {
                                // param.value = '-> 1460995200000'
                                const values = param.value.trim().split('->');
                                values.splice(0, 1);
                                let value = '';
                                if (values.length === 0) {
                                    value = `-> ${BLANK_VALUE} `;
                                } else {
                                    values.forEach((item, i) => {
                                        const paraObj = {
                                            value: item.trim()
                                        };
                                        paraObj[NOT_FORMAT_LINK_PARA_NAME] = true;
                                        const tempValue = this.cellRenderer(groupColumnSettings[i], paraObj);
                                        value = `${value} -> ${tempValue || BLANK_VALUE} `;
                                    });
                                }
                                return value;
                            }
                        }
                        param[NOT_FORMAT_LINK_PARA_NAME] = true;
                        const columnSetting = param.column.colDef.gridRefData.columnSetting;
                        if (this.isDateType(columnSetting.dataType)) {
                            return this.dateColumnCellRendererForExcel(columnSetting, param);
                        }
                        return this.cellRenderer(columnSetting, param, true);
                    }
                });
            };
            exportExcelFunc(excelFileName);
        });
    }

    initMenu(param, id, feedbackSubject$, actionSubject$, tileConfig: TileModel, gridFilterParams?) {
        const menu = [];
        if (param.api && param.api.clientSideRowModel) {
            const rowData = param.api.clientSideRowModel.rowsToDisplay[param.node.rowIndex].data;
            this._actionSubject$ = actionSubject$;
            this._feedbackSubject$ = feedbackSubject$;
            this._tileConfig = tileConfig;
            this._tileId = id;
            this._gridFilterParams = gridFilterParams;
            const domainUrl = window.location.href.substring(0, window.location.href.indexOf(this._router.url));
            const columnType = param.column.colDef.gridRefData.columnSetting.columnType;
            if (columnType === 'entityColumn') {
                this._buildMenuForEntityColumnCell(param, menu, domainUrl, rowData, param.column.colDef.gridRefData.columnSetting.config);
            } else if (columnType === 'entryColumn') {
                this._buildMenuForEntryColumnCell(param, menu, domainUrl, rowData, param.column.colDef.gridRefData.columnSetting.config);
            } else if (columnType === 'Relationship') {
                this._buildMenuForRelationshipColumnCell(param, menu, domainUrl, rowData, tileConfig);
            }
        }
        return menu;
    }

    getTileEntityIds(id: any): any {
        return this._tileEntityIdsMap.get(id);
    }

    getGridLayoutService(id, actionSubject$): Observable<any> {
        const subject$ = new Subject();
        setTimeout(() => {
            actionSubject$.next({
                id: id,
                subject$: subject$,
                type: TamDashboardActions.GET_TILE_GRID_LAYOUT
            });
        });
        return subject$.pipe(take(1));
    }

    getGridLayout({ columns, sorts, filters, expandAll, expandGroups }) {
        const tempColumns = [];
        let temGroups = [];
        columns.forEach((element, index) => {
            if (element.rowGroupIndex || element.rowGroupIndex === 0) {
                temGroups.push(element);
            }
            const config = {
                name: element.colId,
                width: element.width,
                hide: element.hide,
                pinned: element.pinned,
                autoHeight: element.autoHeight,
                aggFunc: element.aggFunc
            };
            tempColumns.push(config);
        });
        temGroups = temGroups.sort((a, b) => a.rowGroupIndex - b.rowGroupIndex).map(item => item.colId);
        const tempSorts = sorts.map(item => ({
            field: item.colId,
            order: item.sort
        }));
        const tempFilters = [];
        for (const item in filters) {
            if (filters.hasOwnProperty(item)) {
                tempFilters.push({
                    column: item,
                    value: filters[item].values,
                    filterType: filters[item].filterType,
                    isNotBlank: filters[item].isNotBlank
                });
            }
        }
        const configData = {
            columns: tempColumns,
            sorts: tempSorts,
            filters: tempFilters,
            groups: temGroups.join(','),
            expandAll: expandAll,
            expandGroups: expandGroups,
        };
        return configData;
    }

    isDateType(dataType) {
        return this._queryService.isDateType(dataType);
    }

    isFileType(id: string): boolean {
        let isFileColumn = false;
        if (id && id.length > 0) {
            isFileColumn = id.includes(businessConstants.dashboard.field.entry_file_special_guid);
        }
        return isFileColumn;
    }

    isNumberType(dataType) {
        return dataType === NumberType.Ratio || dataType === NumberType.Percent ||
            dataType === NumberType.Currency || dataType === NumberType.Number;
    }

    saveGridLayout(tileId, { columns, sorts, filters, expandAll, expandGroups }) {
        const configData = this.getGridLayout({ columns, sorts, filters, expandAll, expandGroups });
        this._tileService.saveGridTile(tileId, configData).pipe(
            take(1)
        ).subscribe();
    }

    setTileEntityIds(info) {
        this._tileEntityIdsMap.set(info.id, info.focusedEntityIds);
    }

    valueGetter(col, params) {
        // Note: in the feature, if any column use original value, should check where this function is called,
        // Pay special attention function setGridFilterSelect
        if (params.data) {
            const factor = params.data[col.guid] || params.data[col.guid] === 0;
            if (this.isDateType(col.dataType)) {
                // column type is formula, use value because has not _value
                if (col.columnType === 'Formula' && params.data[col.guid]) {
                    // formula value is Date
                    return this._formatEntireDate(params.data[col.guid], col.settings.display);
                }
                // use original value - params.data[`${col.guid}_value`], because DST
                // because filter/group 05/20/2019(value 1558330860000) and 05/20/2019(value 1558334400000)
                // show same 05/20/2019, is one group and filter
                let value = params.data[`${col.guid}_value`];
                if (value || value === 0) {
                    /**
                     * date - 2019-11-11 -> DST add ' 00:00:00'
                     * datetime - 2019-11-11 00:00:00.000
                     * datetimeoffset - 2019-11-11 00:00:00.0000000 +00:00
                     * smalldatetime - 2019-11-11 00:00:00
                     */
                    if (col.columnType === 'MDL' && col.dataType === ProviderColumnType.DATE) {
                        // get the real date for mdl date value
                        if (col?.settings?.display !== '3') {
                            value = params.data[col.guid];
                        }
                    }
                    // if yeat only -> new Date('2016') is error, should new Date(2016,0,0,0,0,0)
                    return this._formatEntireDate(value, col.settings.display);
                }

                return params.data[col.guid];
            } else if (this.isNumberType(col.dataType)) {
                // server back value is string, becausea 0.00000001 default change 1e-8
                // Number should avoid undefined, undefined will be converted to NaN in aggFunctions, '' can be converted to 0
                return factor ? (+params.data[col.guid]) : '';
            } else if (this.isFileType(col.guid)) {
                return params.data[col.guid];
            }
            // grid value can not be undefined and null, otherwise these rows will not be group.
            if (params.data[col.guid] === undefined || params.data[col.guid] === null) {
                return '';
            }
            return params.data[col.guid];
        }
        return '';
    }

    getGridAllData(id, actionSubject$) {
        const subject$ = new Subject();
        setTimeout(() => {
            actionSubject$.next({
                id: id,
                subject$: subject$,
                type: TamDashboardActions.GET_TILE_GRID_ALL_DATA
            });
        });
        return subject$.pipe(take(1));
    }

    formatGridColumns(dashboardItem) {
        const gridColumns = dashboardItem.tile.columns
            .filter((col: any) => col.hidden !== true)
            .map((col: any) => {
                const gridColDef: GridColDef = {
                    colId: col.guid,
                    field: col.guid,
                    headerName: col.title,
                    gridRefData: {
                        columnSetting: col,
                        tileConfig: dashboardItem.tile
                    },
                    sortingOrder: this.isDateType(col.dataType) ? ['desc', 'asc', null] : ['asc', 'desc', null],
                    tooltipValueGetter: (params) => {
                        params[NOT_FORMAT_LINK_PARA_NAME] = true;
                        const value = this.cellRenderer(col, params);
                        return value;
                    },
                    valueGetter: (params) => {
                        const value = this.valueGetter(col, params);
                        return value;
                    },
                    cellRenderer: (params) => {
                        const value = this.cellRenderer(col, params);
                        return value;
                    },
                };
                if (dashboardItem.tile && dashboardItem.tile.layout) {
                    const layout = dashboardItem.tile.layout.columns.filter(columnLayout => columnLayout.name === col.guid);
                    if (layout && layout.length) {
                        gridColDef.autoHeight = !!layout[0]['autoHeight'];
                    }
                }
                const isNumberType = this.isNumberType(col.dataType);
                const isDateType = this.isDateType(col.dataType);
                const isFileType = this.isFileType(col.guid);
                if (isFileType) {
                    gridColDef.cellRenderer = 'cellRenderer';
                    // used to change the value of parameters in custom group row inner renderer
                    gridColDef.keyCreator = this._setGridFileColumnKeyCreator.bind(this);
                }
                if (isDateType) {
                    gridColDef.comparator = (valueA, valueB, nodeA, nodeB) => this._setColumnComparator(nodeA, nodeB, col);
                }
                if (col.conditionalFormattings && col.conditionalFormattings.length > 0) {
                    gridColDef.cellStyle = (params) => {
                        const result = this._queryService.calculateColor(params.colDef['gridRefData'].tileConfig,
                            params.colDef['gridRefData'].columnSetting, params.data);
                        const groupStyle = this._getGridCellStyle(params);
                        return {
                            ...groupStyle,
                            color: result.color,
                            'background-color': result.backgroundColor,
                            textAlign: col.alignment ? col.alignment : (isNumberType ? Alignment.RIGHT : Alignment.LEFT)
                        };
                    };
                    gridColDef.cellClass = (params) => {
                        const result = this._queryService.calculateColor(params.colDef['gridRefData'].tileConfig,
                            params.colDef['gridRefData'].columnSetting, params.data);
                        const tempColorIndex = this._colors.findIndex(item => item === result.color);
                        const tempColorIndex2 = this._colors.findIndex(item => item === result.backgroundColor);
                        if (tempColorIndex === -1 && result.color) {
                            this._colors.push(result.color);
                        }
                        if (tempColorIndex2 === -1 && result.backgroundColor) {
                            this._colors.push(result.backgroundColor);
                        }
                        const fontColor = this._formatFontColor();
                        const backgroundColor = this._formatBackgroundColor();
                        const cellClass = [];
                        if (result.color) {
                            const tempFontColor = fontColor.find(item => item.font.color === result.color);
                            cellClass.push(`${tempFontColor.id}`);
                        }
                        if (result.backgroundColor) {
                            const tempBackgroundColor = backgroundColor.find(item => item.interior.color === result.backgroundColor);
                            cellClass.push(`${tempBackgroundColor.id}`);
                        }
                        cellClass.push('font_size10');
                        if (isDateType) {
                            const dateStyle = this.formatDateExcelStyle(col.settings.display);
                            if (dateStyle) {
                                cellClass.push(dateStyle.id);
                            }
                        }
                        cellClass.push(!params.colDef.autoHeight ? 'no-wrap-cell' : 'wrap-cell');
                        return cellClass;
                    };
                } else {
                    gridColDef.cellStyle = (params) => {
                        return {
                            textAlign: col.alignment ? col.alignment : (isNumberType ? Alignment.RIGHT : Alignment.LEFT),
                            ...(this._getGridCellStyle(params))
                        };
                    };
                    gridColDef.cellClass = (params) => {
                        const cellClass = ['font_size10', !params.colDef.autoHeight ? 'no-wrap-cell' : 'wrap-cell'];
                        if (isDateType) {
                            const dateStyle = this.formatDateExcelStyle(col.settings.display);
                            if (dateStyle) {
                                cellClass.push(dateStyle.id);
                            }
                        } else if (this.isNumberType(col.dataType)) {
                            if (col.dataType === NumberType.Currency) {
                                const isMinus: boolean = params?.data ? params.data[col?.guid] < 0 : false;
                                const numberStyle = this.formatNumberExcelStyle(col.settings.decimalPlaces, col.dataType, isMinus);
                                if (numberStyle) {
                                    cellClass.push(numberStyle.id);
                                }
                            } else {
                                const numberStyle = this.formatNumberExcelStyle(col.settings.decimalPlaces, col.dataType);
                                if (numberStyle) {
                                    cellClass.push(numberStyle.id);
                                }
                            }
                        }
                        return cellClass;
                    };
                }
                return gridColDef;
            });
        return gridColumns;
    }

    private _setColumnComparator(nodeA, nodeB, col) {
        const valueA = nodeA?.data[`${col?.guid}_value`] ? nodeA?.data[`${col?.guid}_value`] : nodeA?.data[`${col?.guid}`];
        const valueB = nodeB?.data[`${col?.guid}_value`] ? nodeB?.data[`${col?.guid}_value`] : nodeB?.data[`${col?.guid}`];
        if ((valueA === undefined && valueB === undefined) || (valueA === null && valueB === null)) {
            return 0;
        }
        if (valueA === undefined || valueA === null) {
            return -1;
        }
        if (valueB === undefined || valueB === null) {
            return 1;
        }
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            const alow = valueA.toLowerCase();
            const blow = valueB.toLowerCase();
            return alow === blow ? 0 : (alow > blow ? 1 : -1);
        } else if (!valueA && valueB && typeof valueB === 'string') {
            return -1;
        } else if (!valueB && valueA && typeof valueA === 'string') {
            return 1;
        }
        return valueA - valueB;
    }

    private _setGridFileColumnKeyCreator(params) {
        // handle the file object to show display name
        if (params.value && params.value.length > 0) {
            return params.value.map(item => item.displayName).join(', ');
        } else {
            return '';
        }
    }

    setGridFilterSelect(id, columns, gridFilters, gridColumns) {
        const filters = [];
        const gridFilter = gridFilters.filter(item => item.tileId === id);
        const column = gridColumns.filter(item => item.tileId === id);
        if (gridFilter && gridFilter.length > 0 && column && column.length > 0) {
            gridFilter[0].filter.forEach(filterItem => {
                let filterValue = [];
                const index = column[0].columns.findIndex(data => data.field === filterItem.column);
                const columnSetting = column[0].columns[index].gridRefData.columnSetting;
                const isNumberType = this.isNumberType(columnSetting.dataType);
                const isDateType = this.isDateType(columnSetting.dataType);
                if (index !== -1) {
                    const columnValues = columns.map(item => {
                        if (item) {
                            const tempColumn = column[0].columns[index];
                            const data = {
                                [tempColumn.colId]: item[tempColumn.colId]
                            };
                            if (tempColumn.gridRefData.columnSetting &&
                                this.isDateType(tempColumn.gridRefData.columnSetting.dataType) &&
                                tempColumn.gridRefData.columnSetting.columnType !== 'Formula') {
                                data[`${tempColumn.colId}_value`] = item[`${tempColumn.colId}_value`];

                            }
                            return this.valueGetter(tempColumn.gridRefData.columnSetting, { data });
                        } else {
                            return '';
                        }
                    });
                    if (filterItem.operator === 'isempty') {
                        filterValue = columnValues.filter(data => {
                            if (typeof data === 'string') {
                                return data.trim() === '';
                            }
                            return !data && data !== 0;
                        });
                    } else if (filterItem.operator === 'isnotempty') {
                        filterValue = columnValues.filter(data => {
                            if (typeof data === 'string') {
                                return data.trim() !== '';
                            }
                            return data || data === 0;
                        });
                    } else if (filterItem.operator === 'equals') {
                        filterValue = columnValues.filter(data => {
                            if (isNumberType) {
                                return data === +filterItem.value;
                            }
                            if (isDateType) {
                                const dateText = this.cellRenderer(columnSetting, {
                                    value: data
                                });
                                return dateText === filterItem.value;
                            }
                            if (typeof data === 'string') {
                                return data.toLowerCase() === filterItem.value.toLowerCase();
                            }
                        });
                    } else if (filterItem.operator === 'contains') {
                        filterValue = columnValues.filter(data => {
                            if (isNumberType) {
                                return data === +filterItem.value;
                            }
                            if (isDateType) {
                                const dateText = this.cellRenderer(columnSetting, {
                                    value: data
                                });
                                return dateText.toLowerCase().includes(filterItem.value.toLowerCase());
                            }
                            if (typeof data === 'string') {
                                return data.toLowerCase().includes(filterItem.value.toLowerCase());
                            }
                        });
                    } else if (!filterItem.operator && filterItem.filterType) {
                        if (filterItem.isNotBlank) {
                            // filter value show '(isBlank)' when !value && value !== 0 && value !== false
                            const arr = columnValues.filter(value => {
                                return (value && value !== BLANK_VALUE) || value === 0 || value === false;
                            });
                            filterValue.push(...arr);
                        } else if (filterItem.value && filterItem.value.length > 0) {
                            // filter element can be number, null, string, data can be string, null
                            filterItem.value.forEach(element => {
                                const arr = columnValues.filter(data => {
                                    const isBooleanType = typeof data === 'boolean';
                                    if (isBooleanType) {
                                        return element.toString() === data.toString();
                                    }
                                    if (element === BLANK_VALUE || (!element && element !== 0 && element !== false)) {
                                        return !data && data !== 0 && data !== false;
                                    }
                                    if (typeof data === 'string' && typeof element === 'string' && !isDateType) {
                                        return data.toLowerCase() === element.toLowerCase();
                                    }
                                    if (isDateType) {
                                        // element type is Date(refresh)|String(save), data type is Date, element and data both format by valueGetter
                                        return new Date(data).toString() === new Date(element).toString();
                                    }
                                    if (isNumberType) {
                                        return data === +element;
                                    }
                                    return data === element;
                                });
                                filterValue.push(...arr);
                            });
                        }
                    }
                    filters.push({
                        field: filterItem.column,
                        value: filterValue.map(item => {
                            if (!item && item !== 0 && item !== false) {
                                return BLANK_VALUE;
                            }
                            return item;
                        })
                    });
                }
            });
        }
        return filters;
    }

    setGridColumnsLayout(layout, gridColumns) {
        // The reason here is not set column sort, if it is multi sort, then, the need to maintain order in the sort column
        let groupColIds = [], _gridColumns = [];
        if (layout.groups) {
            groupColIds = layout.groups.split(',');
        }
        if (layout.columns && layout.columns.length > 0) {
            layout.columns.forEach((item) => {
                const index = gridColumns.findIndex(data => data.field === item.name);
                if (index !== -1) {
                    const column = gridColumns[index];
                    column.hide = !!item.hide;
                    column.pinned = item.pinned;
                    column.width = item.width;
                    column.autoHeight = item.autoHeight;
                    column.aggFunc = item.aggFunc;
                    _gridColumns.push(column);
                }
            });
            // Apply&Preview, add column
            gridColumns.forEach((item) => {
                const index = layout.columns.findIndex(data => data.name === item.field);
                if (index === -1) {
                    item['hide'] = !!item.gridRefData.columnSetting.hidden;
                    _gridColumns.push(item);
                }
            });
        } else {
            _gridColumns = gridColumns;
        }
        _gridColumns.forEach((element: any) => {
            const groupIndex = groupColIds.indexOf(element.colId);
            if (groupIndex > -1) {
                element.rowGroupIndex = groupIndex;
            }
        });
        return _gridColumns;
    }

    setColumnSortsOrExpandAll(id, layout, dashboardOptions, action = TamDashboardActions.SET_GRID_COLUMN_SORT) {
        let sorts = [];
        if (layout.sorts && layout.sorts.length > 0) {
            sorts = layout.sorts.map(sort => ({
                colId: sort.field || sort.colId,
                sort: sort.order || sort.sort
            }));
        } else if (!layout.sorts || layout.sorts.length === 0) {
            sorts = [{
                colId: defaultShortName.guid,
                sort: 'asc'
            }];
        }
        dashboardOptions.actionSubject$.next({
            id: id,
            type: action,
            payload: sorts
        });
        this.setGridExpandOrCollapseAll(id, dashboardOptions, layout.expandAll, layout.expandGroups);
    }

    setGridExpandOrCollapseAll(id, dashboardOptions, expandAll, expandGroups) {
        dashboardOptions.actionSubject$.next({
            id: id,
            type: TamDashboardActions.SET_EXPAND_OR_COLLAPSE_ALL,
            payload: {
                expandAll, expandGroups
            }
        });
    }

    formatLabelStrToLink(value) {
        const newValue = anchorme.default(value, {
            attributes: [
                (urlObj) => {
                    if (urlObj.protocol !== 'mailto:') {
                        return {
                            name: 'target',
                            value: '_blank'
                        };
                    }
                }
            ]
        });
        return newValue;
    }

    formatLabelStrToString(value) {
        if (typeof value === 'string' && value) {
            value = value.replace(/(\<)/g, '&lt;').replace(/(\>)/g, '&gt;');
        }
        return value;
    }

    formatExcelStyle() {
        const fontColors = this._formatFontColor();
        const backgroundColors = this._formatBackgroundColor();
        const dateStyles = this.formatDateExcelStyle();
        const numberStyles = this.formatNumberExcelStyle('', NumberType.Number);
        const ratioStyles = this.formatNumberExcelStyle('', NumberType.Ratio);
        const percentStyles = this.formatNumberExcelStyle('', NumberType.Percent);
        const currencyStyles = this.formatNumberExcelStyle('', NumberType.Currency);
        const minusCurrencyStyles = this.formatNumberExcelStyle('', NumberType.Currency, true);
        return fontColors.concat(backgroundColors, dateStyles, numberStyles, ratioStyles, percentStyles, currencyStyles, minusCurrencyStyles);
    }

    formatDateExcelStyle(displaySetting?): any {
        const styles = [{
            id: 'formatDateOnly',
            dataType: 'dateTime',
            displaySetting: '1',
            numberFormat: { format: 'MM/dd/yyyy' },
        }, {
            id: 'formatDateAndTime',
            dataType: 'dateTime',
            displaySetting: '0',
            numberFormat: { format: 'MM/dd/yyyy hh:MM' },
        }, {
            id: 'formatYearAndMonth',
            dataType: 'dateTime',
            displaySetting: '2',
            numberFormat: { format: 'MMM yyyy' },
        }];
        if (displaySetting) {
            return styles.find(item => item.displaySetting === displaySetting);
        }
        return styles;
    }

    formatNumberExcelStyle(decimalPlaces: string, type: string, isMinus: boolean = false): any {
        let before = '';
        let after = '';
        let identify = '';
        if (type === NumberType.Percent) {
            after = '%';
            identify = '%';
        } else if (type === NumberType.Ratio) {
            after = 'x';
            identify = 'x';
        } else if (type === NumberType.Currency) {
            identify = '$';
            if (isMinus) {
                before = '$(';
                after = ')';
            } else {
                before = '$';
            }
        }

        if (decimalPlaces) {
            decimalPlaces = decimalPlaces + identify;
            if (isMinus) {
                decimalPlaces = decimalPlaces + '-';
            }
        }
        const styles = [];
        if (isMinus) {
            styles.push(...[{
                id: 'formatNumber0' + identify + '-',
                numberFormat: { format: before + '#,##0' + after },
            }, {
                id: 'formatNumber1' + identify + '-',
                numberFormat: { format: before + '#,##0.0' + after },
            }, {
                id: 'formatNumber2' + identify + '-',
                numberFormat: { format: before + '#,##0.00' + after },
            }, {
                id: 'formatNumber3' + identify + '-',
                numberFormat: { format: before + '#,##0.000' + after },
            }, {
                id: 'formatNumber4' + identify + '-',
                numberFormat: { format: before + '#,##0.0000' + after },
            }, {
                id: 'formatNumber5' + identify + '-',
                numberFormat: { format: before + '#,##0.00000' + after },
            }, {
                id: 'formatNumber6' + identify + '-',
                numberFormat: { format: before + '#,##0.000000' + after },
            }, {
                id: 'formatNumber7' + identify + '-',
                numberFormat: { format: before + '#,##0.0000000' + after },
            }, {
                id: 'formatNumber8' + identify + '-',
                numberFormat: { format: before + '#,##0.00000000' + after },
            }, {
                id: 'formatNumber9' + identify + '-',
                numberFormat: { format: before + '#,##0.000000000' + after },
            }]);
        } else {
            styles.push(...[{
                id: 'formatNumber0' + identify,
                numberFormat: { format: before + '#,##0' + after },
            }, {
                id: 'formatNumber1' + identify,
                numberFormat: { format: before + '#,##0.0' + after },
            }, {
                id: 'formatNumber2' + identify,
                numberFormat: { format: before + '#,##0.00' + after },
            }, {
                id: 'formatNumber3' + identify,
                numberFormat: { format: before + '#,##0.000' + after },
            }, {
                id: 'formatNumber4' + identify,
                numberFormat: { format: before + '#,##0.0000' + after },
            }, {
                id: 'formatNumber5' + identify,
                numberFormat: { format: before + '#,##0.00000' + after },
            }, {
                id: 'formatNumber6' + identify,
                numberFormat: { format: before + '#,##0.000000' + after },
            }, {
                id: 'formatNumber7' + identify,
                numberFormat: { format: before + '#,##0.0000000' + after },
            }, {
                id: 'formatNumber8' + identify,
                numberFormat: { format: before + '#,##0.00000000' + after },
            }, {
                id: 'formatNumber9' + identify,
                numberFormat: { format: before + '#,##0.000000000' + after },
            }]);
        }

        if (decimalPlaces) {
            return styles.find(item => item.id.includes(decimalPlaces));
        }

        return styles;
    }

    //  dashboard view page, gridType - GridType.VerticalFixed;
    formatDashboardScreen(screen, dashboardOptions?, gridType?, printSaveTileConfig?) {
        const dashboardItems = screen.configuration.widgets.map(element => {
            const item = new TamDashboardItem();
            item.height = element.height;
            item.width = element.width;
            item.positionX = element.positionX;
            item.positionY = element.positionY;
            item.id = element.id;
            item.name = element.tile.tileName;
            item.isLoading = true; // TODO: the way to control loading is different than other place
            item.type = element.tile.visualization.type;
            if (item.type === TamDashboardTileType.GRID) {
                if (screen.configuration.widgets.length === 1 && gridType) {
                    item.height = 6;
                    item.width = 6;
                    gridType = GridType.Fit;
                }
            }
            return item;
        });
        if (!!printSaveTileConfig) {
            const printGsItemms = dashboardItems.map(element => ({
                id: element.id,
                info: {
                    cols: element.width,
                    rows: element.height,
                    x: element.positionX,
                    y: element.positionY,
                    type: element.type,
                    name: element.name,
                }
            }));
            this._store.dispatch({
                type: DashboardActionTypes.SAVE_TILE_INFO,
                payload: printGsItemms
            });
        }
        if (dashboardOptions) {
            // MUST first render dashboard items before send request to load data
            const param = {
                type: TamDashboardActions.RESET,
                payload: dashboardItems,
            };
            if (gridType) {
                param['gridType'] = gridType;
            }
            dashboardOptions.actionSubject$.next(param);
        }
        return screen;
    }

    openThreadDetailInSlideSheet(threadId, tileId?, feedbackSubject$?, actionSubject$?, tileConfig?: TileModel, entityIds?) {
        if (this.embedInRichClient || !this._isIPad) {
            this._openThreadDetailInSlideSheet(threadId, tileId, feedbackSubject$, actionSubject$, tileConfig, entityIds);
        }
    }

    isEntityPrivacyColumn(data) {
        return data.columnType === TileColumnType.ENTITY_COLUMN && data.field === 'entity__is_public';
    }

    initGridHeader(gridColumnApi) {
        const infoColumns = gridColumnApi.getColumns();
        const allColumns = gridColumnApi.getColumnState();
        const tempColumns = [];
        const pinnedLeftColumns = [];
        const pinnedRightColumns = [];
        const noPinnedColumns = [];
        let columns = [];
        allColumns.forEach((column, i) => {
            if (!column.hide) {
                if (column.pinned === 'left') {
                    pinnedLeftColumns.push(column);
                } else if (column.pinned === 'right') {
                    pinnedRightColumns.push(column);
                } else {
                    noPinnedColumns.push(column);
                }
            }
        });
        columns = [].concat(pinnedLeftColumns, noPinnedColumns, pinnedRightColumns);
        columns.forEach((column) => {
            const index = infoColumns.findIndex(item => item.colId === column.colId);
            if (index !== -1) {
                tempColumns.push({
                    name: infoColumns[index].colDef.headerName,
                    width: column.width,
                    id: column.colId
                });
            }
        });
        return tempColumns;
    }

    private _successHandler(res) {
        if (res) {
            if (res === CREATE_NOTE_SUCCESS || res === UPDATE_NOTE_SUCCESS ||
                res === DELETE_NOTE_SUCCESS || res === DELETE_SIDENOTE_SUCCESS ||
                res === UPDATE_EVENT_SUCCESS) {
                if (this._opNoteType === NoteOpType.OpenThreadSideSheet) {
                    if (res === CREATE_NOTE_SUCCESS) {
                        this._opNoteType = NoteOpType.CreateNote;
                    } else if (res === UPDATE_NOTE_SUCCESS) {
                        this._opNoteType = NoteOpType.EditNote;
                    } else if (res === UPDATE_EVENT_SUCCESS) {
                        this._opNoteType = NoteOpType.EditEvent;
                    } else if (res === DELETE_NOTE_SUCCESS) {
                        this._opNoteType = NoteOpType.DeleteNote;
                    } else if (res === DELETE_SIDENOTE_SUCCESS) {
                        this._opNoteType = NoteOpType.DeleteSideNote;
                    }
                }
                const focusedEntityIds = this._tileEntityIdsMap.get(this._tileId);
                this._dashboardGridNoteService.updateGridRow(this._tileId, this._actionSubject$, this._tileConfig, this._opNoteType, focusedEntityIds, this._notePreviousEntityIds, this._gridFilterParams);

            } else if ((res === EDIT_CONTACT_SUCCESS || res === EDIT_ENTITY_SUCCESS) &&
                this._feedbackSubject$ && this._tileId) {
                this._feedbackSubject$.next({
                    type: TamDashboardEvents.ITEM_ICON_CLICK,
                    payload: {
                        id: this._tileId,
                        icon: TileIcons.REFRESH.icon
                    }
                });
            }
        }
    }

    private _buildMenuForEntityColumnCell(param, menu, domainUrl, rowData, parameterValueConfig) {
        const entityId = param.node.data[`${param.column.colId}_attribute`].entityId;
        const ids = entityId ? entityId.split(',') : '';
        let value: string;
        if (parameterValueConfig && parameterValueConfig.parameterValue) {
            value = rowData[parameterValueConfig.parameterValue];
        } else {
            value = param.value;
        }
        if (entityId && ids.length === 1) {
            value = this.formatLabelStrToString(value);
            this._pushEntityMenu(menu, entityId, domainUrl, value);
        }
    }

    private _buildMenuForEntryColumnCell(param, menu, domainUrl, rowData, parameterValue) {
        const colAttribute = param.node.data[`${param.column.colId}_attribute`];
        const threadId = colAttribute.threadId;
        const entryId = colAttribute.entryId;
        const shortNameEntityId = rowData[`${defaultShortName.guid}_attribute`].entityId;
        const editNoteMenu = {
            name: 'Edit Note',
            action: () => {
                this._addOrEditNote(NoteDialogType.EditNote, entryId, shortNameEntityId, threadId);
            },
        };
        const addNoteMenu = {
            name: 'New Note',
            action: () => {
                this._addOrEditNote(NoteDialogType.CreateNote, entryId, shortNameEntityId, threadId);
            },
        };
        const deleteMenu = {
            name: 'Delete Note',
            action: () => {
                this._deleteNote(entryId, threadId);
            },
        };
        const copyLinkMenu = {
            name: 'Copy Link',
            action: () => {
                this._copyLink(entryId);
            },
        };
        if (entryId) {
            if (!this.embedInRichClient && !this._isIPad) {
                menu.push(editNoteMenu, addNoteMenu, deleteMenu, copyLinkMenu);
            }
        } else {
            if (!this.embedInRichClient) {
                menu.push(addNoteMenu);
            }
        }
        this._buildMenuForEntityColumnCell(param, menu, domainUrl, rowData, parameterValue);
    }

    private _buildMenuForRelationshipColumnCell(param, menu, domainUrl, rowData, tileConfig: TileModel) {
        const relationshipColumn = tileConfig.columns.find(item => item.guid === param.column.colId);
        if (relationshipColumn) {
            const parentColumn = tileConfig.columns.find(item => item.guid === relationshipColumn.config.parameterValue);
            if (parentColumn) {
                const entityId = rowData[`${parentColumn.guid}_attribute`].entityId;
                const ids = entityId ? entityId.split(',') : '';
                if (entityId && ids.length === 1) {
                    this._pushEntityMenu(menu, entityId, domainUrl, rowData[parentColumn.guid]);
                }
            }
        }
    }

    private _copyLink(entryId) {
        this._entryService.getEntryById(entryId).pipe(
            take(1)
        ).subscribe((response) => {
            const entryClass = response['entry-class'] === 'event' ? EntryClass.Event : EntryClass.Note;
            this._noteService.shareNote(entryId, entryClass);
        });
    }

    private _openThreadDetailInSlideSheet(threadId, tileId, feedbackSubject$, actionSubject$, tileConfig: TileModel, entityIds) {
        if (threadId) {
            if (this.embedInRichClient) {
                const url = 'entry/' + threadId;
                window.open(url);
            } else {
                // drill down page grid not has the tile id
                if (tileId) {
                    this._tileId = tileId;
                    this._actionSubject$ = actionSubject$;
                    this._feedbackSubject$ = feedbackSubject$;
                    this._tileConfig = tileConfig;
                    this._gridFilterParams = null;
                    if (entityIds) {
                        this._notePreviousEntityIds = entityIds.split(',');
                    }
                    this._opNoteType = NoteOpType.OpenThreadSideSheet;
                }
                const payload = {
                    threadId: threadId,
                    isMaximize: false
                };
                this._threadDetailService.open$.next(payload);
            }
        }
    }

    private _pushEntityMenu(menu, entityId, domainUrl, value) {
        const viewDetailMenu = {
            name: `View Details for ${value}`,
            action: () => {
                this._entityService.getEntityDetail(entityId).pipe(
                    take(1)
                ).subscribe((res) => {
                    let tmpUrl;
                    if (res['entity-type'].data.id === EntityType.CONTACT.id) {
                        if (this._isIPad) {
                            tmpUrl = `${domainUrl}${this._router.createUrlTree(['/contact', entityId])}`;
                            window.location.assign(tmpUrl);
                        } else {
                            this._slideSheetService.slideSheetActionSubject$.next({
                                type: SlideSheetActionTypes.CONTACT_PANEL,
                                payload: entityId
                            });
                        }
                    } else {
                        if (this._isIPad) {
                            tmpUrl = `${domainUrl}${this._router.createUrlTree(['/entity', entityId])}`;
                            window.location.assign(tmpUrl);
                        } else {
                            this._slideSheetService.slideSheetActionSubject$.next({
                                type: SlideSheetActionTypes.ENTITY_PANEL,
                                payload: entityId
                            });
                        }
                    }
                });
            },
        };
        const editDetailMenu = {
            name: `Edit Details for ${value}`,
            action: () => {
                this._entityService.getEntityDetail(entityId).pipe(
                    take(1)
                ).subscribe((res) => {
                    if (res['entity-type'].data.id === EntityType.CONTACT.id) {
                        const contact = Contact.parse(res, false);
                        const _contactDialogOpenOptions = new ContactTemplateOpenOptions();
                        _contactDialogOpenOptions.openType = ContactTemplateType.EditContact;
                        _contactDialogOpenOptions.contact = contact;
                        this._contactDialogService.dialogOpen$.next(_contactDialogOpenOptions);
                    } else {
                        const entityDialogOpenOptions = new EntityDialogOpenOptions();
                        entityDialogOpenOptions.openType = EntityDialogType.EditEntity;
                        entityDialogOpenOptions.entityId = entityId;
                        this._entityDialogService.dialogOpen$.next(entityDialogOpenOptions);
                    }
                });
            }
        };
        if (this.embedInRichClient) {
            menu.push(viewDetailMenu);
        } else {
            menu.push(viewDetailMenu, editDetailMenu);
        }
    }

    private _deleteNote(entryId, threadId) {
        const content: string[] = [];
        content.push('Are you sure you want to delete this note?');
        if (entryId === threadId) {
            content.push('Any attachments or sidenotes will also be deleted.');
        } else {
            content.push('Any attachments will be deleted.');
        }

        const cancelBtn: AlertButton = {
            text: 'Cancel',
            type: AlertBtnTypes.tertiary
        };
        const confirmButton: AlertButton = {
            text: 'Delete',
            type: AlertBtnTypes.destructive
        };
        const subscription = this._alertWindow.
            custom('Delete confirmation required', content, cancelBtn, confirmButton)
            .subscribe((result: boolean) => {
                if (result) {
                    this._getEntryById(entryId).subscribe((response) => {
                        if (response.editable) {
                            if (entryId === threadId) {
                                this._opNoteType = NoteOpType.DeleteNote;
                                this._store.dispatch({
                                    type: DELETE_NOTE,
                                    payload: {
                                        id: entryId,
                                        componentId: 'dashboard grid tile'
                                    }
                                });
                            } else {
                                this._opNoteType = NoteOpType.DeleteSideNote;
                                this._store.dispatch({
                                    type: DELETE_SIDENOTE,
                                    payload: {
                                        id: entryId,
                                        componentId: 'dashboard grid tile'
                                    }
                                });
                            }
                        } else {
                            const tempSubscription = this._alertWindow.error('You don\'t have permission',
                                ['You don\'t have permission to edit this note'])
                                .subscribe(() => {
                                    tempSubscription.unsubscribe();
                                });
                        }
                    });
                }
                subscription.unsubscribe();
            });
    }

    private _addOrEditNote(opType, entryId, shortNameEntityId, threadId) {
        const _noteOption = new NoteDialogOpenOptions();
        const noteCallback = () => {
            _noteOption.entityFieldEditable = true;
            _noteOption.minimizable = true;
            this._noteDialogService.dialogOpen$.next(_noteOption);

        };
        const eventCalback = () => {
            const _eventOption = new CalendarDialogOpenOptions();
            _eventOption.openType = CalendarDialogType.EditEvent;
            _eventOption.entryId = entryId;
            _eventOption.isPluginInEditEventMode = false;
            _eventOption.minimizable = true;
            this._calendarDialogService.dialogOpen$.next(_eventOption);
        };
        /** This function has two functions, if have entryId
         * (1) create note -> get entryTypeId
         * (2) edit note -> get permission
        */
        if (entryId) {
            this._getEntryById(entryId, opType === NoteDialogType.CreateNote).subscribe((response) => {
                if (opType === NoteDialogType.CreateNote) {
                    _noteOption.openType = NoteDialogType.CreateNote;
                    _noteOption.entity = new Entity();
                    _noteOption.entity.id = shortNameEntityId;
                    const tempEntryTypes: Array<EntryType> = this._storeQuerier.queryBySelector(availableEntryTypeSelector);
                    if (tempEntryTypes && tempEntryTypes.findIndex(item => item.id === response['entry-type'].data.id) > -1) {
                        _noteOption.entryTypeId = response['entry-type'].data.id;
                    }
                    this._opNoteType = NoteOpType.CreateNote;
                    noteCallback();
                } else {
                    if (response.editable) {
                        const isEvent = response['entry-class'] === 'event';
                        _noteOption.entryId = entryId;
                        if (threadId === entryId) {
                            if (isEvent) {
                                eventCalback();
                                this._opNoteType = NoteOpType.EditEvent;
                            } else {
                                _noteOption.openType = NoteDialogType.EditNote;
                                noteCallback();
                                this._opNoteType = NoteOpType.EditNote;
                            }
                        } else {
                            this._opNoteType = NoteOpType.EditSideNote;
                            _noteOption.openType = NoteDialogType.EditSideNote;
                            noteCallback();
                        }
                    } else {
                        const subscription = this._alertWindow.error('You don\'t have permission',
                            ['You don\'t have permission to edit this note'])
                            .subscribe(() => {
                                subscription.unsubscribe();
                            });
                    }
                }
            });
        } else {
            /** Create note, but not have entryId */
            this._opNoteType = NoteOpType.CreateNote;
            _noteOption.openType = NoteDialogType.CreateNote;
            _noteOption.entity = new Entity();
            _noteOption.entity.id = shortNameEntityId;
            noteCallback();
        }
    }

    private _getEntryById(entryId, notFormatEntityIds = false) {
        return this._entryService.getEntryById(entryId).pipe(
            take(1),
            map((response) => {
                if (!notFormatEntityIds) {
                    const entities = response.entities.data;
                    entities.forEach((entity) => {
                        this._notePreviousEntityIds.push(entity.data.id);
                    });
                }
                return response;
            })
        );
    }

    private _formatStr(str: string, num = '2') {
        const count = parseInt(num, 10);
        const reg = /(\d)(?=(?:\d{3})+$)/g;
        const index = str.indexOf('.');
        if (index > -1) {
            let decimal = str.slice(index + 1);
            const decimalLen = decimal.length;
            if (decimalLen < count) {
                for (let i = 0; i < count - decimalLen; i += 1) {
                    decimal += '0';
                }
            } else {
                const tempDecimal = (+`0.${decimal}`).toFixed(count);
                const i = tempDecimal.indexOf('.');
                decimal = tempDecimal.slice(i + 1);
            }
            if (count === 0) {
                str = (+str).toFixed(0);
                str = str.replace(reg, '$1,');
            } else {
                str = str.slice(0, index).replace(reg, '$1,') + '.' + decimal;
            }
        } else {
            if (count === 0) {
                str = (+str).toFixed(0);
                str = str.replace(reg, '$1,');
            } else {
                let decimal = '';
                for (let i = 0; i < count; i += 1) {
                    decimal += '0';
                }
                str = str.replace(reg, '$1,') + '.' + decimal;
            }
        }
        return str;
    }

    /**
     * (3, 4, true) will get 30000; (3, 4, false) will get 0.0003.
     * @param value
     * @param power
     * @param isMultiply
     */
    private _multiplyPowerX10ForString(value: string, power: number = 2, isMultiply: boolean = true): string {
        let result: string = value;
        if (power === 0) {
            return result;
        }
        if (power < 0) {
            isMultiply = !isMultiply;
            power = -power;
        }
        let prefix = '';
        if (value.startsWith('-')) {
            prefix = '-';
            value = value.substring(1);
        }
        const decimalIndex: number = value.indexOf('.');
        let integer: string;
        let decimal: string;
        if (isMultiply) {
            if (decimalIndex > -1) {
                integer = value.substring(0, decimalIndex);
                decimal = value.substring(decimalIndex + 1);
            } else {
                integer = value.toString();
                decimal = '';
            }
            for (let i = 0; i < power; i++) {
                decimal = decimal + '0';
            }
            integer = integer + decimal.substring(0, power);
            decimal = decimal.substring(power);
            let tempDecimal = '';
            for (let i = decimal.length - 1; i > 0; i--) {
                if (+decimal[i] === 0 && +decimal[i - 1] !== 0) {
                    tempDecimal = '.' + decimal.substring(0, i);
                    break;
                }
            }
            result = prefix + integer + tempDecimal;
        } else {
            if (decimalIndex > -1) {
                integer = value.substring(0, decimalIndex);
                decimal = value.substring(decimalIndex + 1);
            } else {
                integer = value.toString();
                decimal = '';
            }
            for (let i = 0; i < power; i++) {
                integer = '0' + integer;
            }
            decimal = integer.substring(integer.length - power) + decimal;
            integer = integer.substring(0, integer.length - power);
            let tempInteger = '0';
            for (let i = 0; i < integer.length - 1; i++) {
                if (+integer[i] === 0 && +integer[i + 1] !== 0) {
                    tempInteger = integer.substring(i + 1);
                    break;
                }
            }
            result = prefix + tempInteger + '.' + decimal;
        }
        return result;
    }

    /**
     * parse 1.23e+4 to 12300; paser scientific notation to no notation.
     * @param value
     */
    private _wipeOffScientificNotation(value: string): string {
        const snIndex: number = value.indexOf('e');
        if (snIndex > 0 && value.length > snIndex + 2) {
            const isPositiveSN: boolean = value[snIndex + 1] === '+';
            const power: number = +value.substring(snIndex + 2);
            let numberPart: string = value.substring(0, snIndex);
            if (isPositiveSN) {
                numberPart = this._multiplyPowerX10ForString(numberPart, power);
            } else {
                numberPart = this._multiplyPowerX10ForString(numberPart, power, false);
            }
            return numberPart;
        } else {
            return value;
        }
    }

    private _formatFontColor() {
        const obj = [];
        this._colors.forEach((item, index) => {
            obj.push({
                id: `font_${index}`,
                font: {
                    color: item
                }
            });
        });
        return obj;
    }

    private _formatBackgroundColor() {
        const obj = [];
        this._colors.forEach((item, index) => {
            obj.push({
                id: `background_${index}`,
                interior: {
                    pattern: 'Solid',
                    color: item
                }
            });
        });
        return obj;
    }

    private _formatDateType(type) {
        const dateFormatType = {
            '0': 'MM/dd/yyyy hh:mm TT', // Date and Time
            '1': 'MM/dd/yyyy', // Date Only
            '2': 'MMM yyyy', // Month and Year
            '3': 'yyyy' // Year Only
        };
        return dateFormatType[type] || 'MM/dd/yyyy';
    }

    private _formatEntireDate(date, format) {
        if (date) {
            const value = new Date(date);
            if (value instanceof Date && !isNaN(value.getTime())) {
                if (format === '0') { // MM/dd/yyyy hh:mm TT
                    // todo has performance issues, getHours and getMinutes
                    return new Date(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes(), 0);
                } else if (format === '2') { // MMM yyyy
                    return new Date(value.getFullYear(), value.getMonth(), 1, 0, 0, 0);
                } else if (format === '3') { // yyyy
                    return new Date(value.getFullYear(), 0, 1, 0, 0, 0);
                }
                return new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0);
            }
            return date;
        }
        return date;
    }

    // Indent the first cell for each row, order by group level - Always indent to the right, regardless of the display does not show down
    private _getGridCellStyle(params) {
        const cellStyleObj = {
            'background-color': '#FFFFFF'
        };
        if (params.node && params.node.columnApi) {
            const leftPinColumns = params.columnApi.getDisplayedLeftColumns();
            if (leftPinColumns.length > 0) {
                if (!params.node.group && params.column.left === 0 && params.node.level !== 0 && params.column.pinned === 'left') {
                    const paddingLeft = params.node.level * 30;
                    cellStyleObj['padding-left'] = `${paddingLeft}px`;
                }
            } else {
                if (!params.node.group && params.column.left === 0 && params.node.level !== 0) {
                    const paddingLeft = params.node.level * 30;
                    cellStyleObj['padding-left'] = `${paddingLeft}px`;
                }
            }
        }

        return cellStyleObj;
    }
}
