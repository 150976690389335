<div class="field-label-container">
    <div [class]="labelClassList">
        {{label}}
    </div>
    <div class="required-field" *ngIf="isRequired">*</div>
    <div class="icon" #anchor kendoTooltip tooltipClass="tooltip-adjust-right" showOn="none"
        (click)="showTooltip($event,anchor,null)" *ngIf="isIPAD && tooltip" [title]="tooltip">
        <smart-icon name="exclamation-circle" class="notification"></smart-icon>
    </div>
    <div class="icon" kendoTooltip tooltipClass="tooltip-adjust-right" *ngIf="!isIPAD && tooltip" [title]="tooltip">
        <smart-icon name="exclamation-circle" class="notification"></smart-icon>
    </div>
</div>
