<div class="category" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
    <div class="item-header" title="{{dataConfig.name}}">
        <div class="left-header" (click)="collapse()">
            <smart-icon class="angle-icon light" name="{{dataConfig.status.collapsed ? 'angle-down' : 'angle-up'}}">
            </smart-icon>
            <div class="item-name">{{dataConfig.name}}</div>
        </div>
        <div class="right-header" [style.visibility]="isHoverOn?'visible':'hidden'">
            <smart-icon class="angle-icon light" name="meatball" (click)="onMeatball($event)"></smart-icon>
        </div>
    </div>
    <div *ngIf="options && options.length > 0" [hidden]="dataConfig.status.collapsed">
        <div class="item-content">
            <div *ngFor="let option of options">
                <div class="option-item" (click)="changeSelectedValue(option, $event)">
                    <div class="item-ckb">
                        <smart-icon name="check" [style.display]="option.checked?'':'none'"></smart-icon>
                    </div>
                    <div class="name-count-panel">
                        <div class="option-name">
                            <tam-rating [rating]='option.id' [disabled]="true"></tam-rating>
                        </div>
                        <div class="option-count">{{option.count}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>