/**
 * Created by Daniel on 09/04/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum CalendarEventsActionTypes {
    SET_LAYOUT_COLLAPSE = '[CALENDAR][EVENTS] SET_COLLSPASE_MODE',
    RELOAD = '[CALENDAR][EVENTS] RELOAD',
    RELOAD_SUCCESS = '[CALENDAR][EVENTS] RELOAD_SUCCESS',
    RELOAD_FAILED = '[CALENDAR][EVENTS] RELOAD_FAILED',
    // focus related
    INITIALIZE_FOCUS = '[CALENDAR][EVENTS][FOCUS] INITIALIZE',
}

export class CalendarAction {
    constructor(public payload, prefixName: string, prefixValue: string) {
        if (prefixName && prefixValue) {
            this[prefixName] = prefixValue;
        }
    }
}

export class CalendarEventsReload extends CalendarAction implements Action {
    readonly type = CalendarEventsActionTypes.RELOAD;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CalendarEventsReloadSuccess extends CalendarAction implements Action {
    readonly type = CalendarEventsActionTypes.RELOAD_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CalendarEventsReloadFailed extends CalendarAction implements Action {
    readonly type = CalendarEventsActionTypes.RELOAD_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CalendarEventsInitializeFocus extends CalendarAction implements Action {
    readonly type = CalendarEventsActionTypes.INITIALIZE_FOCUS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export type CalendarActions = CalendarEventsReload | CalendarEventsReloadSuccess | CalendarEventsReloadFailed |
    CalendarEventsInitializeFocus;

// calendar list refineby related actions
export enum CalendarListRefinebyActionTypes {
    LOAD = '[CALENDARlIST][REFINEBY] LOAD',
    LOAD_SUCCESS = '[CALENDARlIST][REFINEBY] LOAD_SUCCESS',
    LOAD_FAILED = '[CALENDARlIST][REFINEBY] LOAD_FAILED',
    SET_SELECTED_VALUE = '[CALENDARlIST][REFINEBY] SET_SELECTED_VALUE',
    SET_CATEGORY = '[CALENDARlIST][REFINEBY] SET_CATEGORY',
    SET_CATEGORY_ORDER = '[CALENDARlIST][REFINEBY] SET_CATEGORY_ORDER',
    SET_CATEGORY_STATUS = '[CALENDARlIST][REFINEBY] SET_CATEGORY_STATUS',
}

export class CalendarListRefinebyLoad extends CalendarAction implements Action {
    readonly type = CalendarListRefinebyActionTypes.LOAD;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CalendarListRefinebyLoadSucess extends CalendarAction implements Action {
    readonly type = CalendarListRefinebyActionTypes.LOAD_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CalendarListRefinebyLoadFailed extends CalendarAction implements Action {
    readonly type = CalendarListRefinebyActionTypes.LOAD_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CalendarListRefinebySetSelectedValue extends CalendarAction implements Action {
    readonly type = CalendarListRefinebyActionTypes.SET_SELECTED_VALUE;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CalendarListRefinebySetCategory extends CalendarAction implements Action {
    readonly type = CalendarListRefinebyActionTypes.SET_CATEGORY;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CalendarListRefinebySetCategoryOrder extends CalendarAction implements Action {
    readonly type = CalendarListRefinebyActionTypes.SET_CATEGORY_ORDER;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CalendarListRefinebySetCategoryStatus extends CalendarAction implements Action {
    readonly type = CalendarListRefinebyActionTypes.SET_CATEGORY_STATUS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export type CalendarListRefinebyActions = CalendarListRefinebyLoad | CalendarListRefinebyLoadSucess | CalendarListRefinebyLoadFailed | CalendarListRefinebySetSelectedValue | CalendarListRefinebySetCategory | CalendarListRefinebySetCategoryOrder | CalendarListRefinebySetCategoryStatus;

