<kendo-dialog tid="select_window" *ngIf="open" class="container" [minWidth]="410" (click)="onClick($event)">
    <div class="select-title"><span class="select-title-content">{{title}}</span></div>
    <div class="select-body" tid="select_body" *ngIf="!isMultiple" (click)="onClick($event)">
        <div class="select-content" *ngFor="let item of data;index as i">
            <tam-radio [value]="i" [(ngModel)]="selectedData" (change)="onChangeOptions($event, i)">
            </tam-radio>
            <span class="one-content">{{item}}</span>
        </div>
    </div>
    <div class="select-body" tid="select_body" *ngIf="isMultiple" (click)="onClick($event)">
        <div class="select-content" *ngFor="let item of data;index as i">
            <label tam-checkbox [value]="selectedData[i]" (checkedChange)="onChangeOptions($event, i)">
                {{item}}
            </label>
        </div>
    </div>
    <div class="select-button">
        <button class="one-button" tam-button tid="cancel_button" tamType="secondary" [tamSize]="'normal'"
            [tamLoading]="false" (click)="close($event)">Cancel</button>
        <button class="one-button" tam-button tid="save_button" tamType="primary" [tamSize]="'normal'"
            [tamLoading]="false" (click)="save($event)">Save</button>
    </div>
</kendo-dialog>