<div class="container">
    <div *ngFor="let detail of detailConfigs;let i=index; trackBy:trackByFnDetail" class="next-page">
        <thread-detail [config]="detail"></thread-detail>
        <div *ngIf="includeAttachments&&attachments&&attachments.length&&attachments[i]&&attachments[i].length"
            class="next-page">
            <note-attachment-pdf *ngFor="let attachment of attachments[i]; trackBy:trackByFnAttachment"
                [attachment]="attachment">
            </note-attachment-pdf>
        </div>
    </div>
</div>