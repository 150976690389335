export class NotificationOptions {
    style: NotificationStyles;
    message: string;
    hasDetail?: boolean;
    details?: string[];
}

export enum NotificationStyles {
    Info,		// 0
    Warning,	// 1
    Success,	// 2
    Error		// 3
}
