/**
 * Created by Juno Peng 1/5/2024
 * Description:
 *  store types
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum PortfolioActionTypes {
    GETPORTFOLIOS = '[GETPORTFOLIO] GETPORTFOLIOS',
    GETPORTFOLIOS_SUCCESS = '[GETPORTFOLIO] GETPORTFOLIOS_SUCCESS',
    GETPORTFOLIOS_FAILED = '[GETPORTFOLIO] GETPORTFOLIOS_FAILED',

    CREATEPORTFOLIOS = '[CREATEPORTFOLIOS] CREATEPORTFOLIOS',
    CREATEPORTFOLIOS_SUCCESS = '[CREATEPORTFOLIOS] CREATEPORTFOLIOS_SUCCESS',
    CREATEPORTFOLIOS_FAILED = '[CREATEPORTFOLIOS] CREATEPORTFOLIOS_FAILED',

    EDITPORTFOLIOS = '[EDITPORTFOLIOS] EDITPORTFOLIOS',
    EDITPORTFOLIOS_SUCCESS = '[EDITPORTFOLIOS] EDITPORTFOLIOS_SUCCESS',
    EDITPORTFOLIOS_FAILED = '[EDITPORTFOLIOS] EDITPORTFOLIOS_FAILED'
}

export class GetPortfolios implements Action {
    readonly type = PortfolioActionTypes.GETPORTFOLIOS;

    constructor(public payload: any) { }
}

export class GetPortfoliosSuccess implements Action {
    readonly type = PortfolioActionTypes.GETPORTFOLIOS_SUCCESS;

    constructor(public payload: any) { }
}

export class GetPortfoliosFailed implements Action {
    readonly type = PortfolioActionTypes.GETPORTFOLIOS_FAILED;

    constructor(public payload: any) { }
}

export class CreatePortfolios implements Action {
    readonly type = PortfolioActionTypes.CREATEPORTFOLIOS;

    constructor(public payload: any) { }
}

export class CreatePortfoliosSuccess implements Action {
    readonly type = PortfolioActionTypes.CREATEPORTFOLIOS_SUCCESS;

    constructor(public payload: any) { }
}

export class CreatePortfoliosFailed implements Action {
    readonly type = PortfolioActionTypes.CREATEPORTFOLIOS_FAILED;

    constructor(public payload: any) { }
}

export class EditPortfolios implements Action {
    readonly type = PortfolioActionTypes.EDITPORTFOLIOS;

    constructor(public payload: any) { }
}

export class EditPortfoliosSuccess implements Action {
    readonly type = PortfolioActionTypes.EDITPORTFOLIOS_SUCCESS;

    constructor(public payload: any) { }
}

export class EditPortfoliosFailed implements Action {
    readonly type = PortfolioActionTypes.EDITPORTFOLIOS_FAILED;

    constructor(public payload: any) { }
}

export type PortfolioActions = GetPortfolios | GetPortfoliosSuccess | GetPortfoliosFailed | CreatePortfolios | CreatePortfoliosSuccess | CreatePortfoliosFailed | EditPortfolios | EditPortfoliosFailed | EditPortfoliosSuccess;
