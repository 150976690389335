/**
 * Created by Simon Zhao on 7/20/21.
 * Description:
 * the view model for mdl dialog component.
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../tamalelibs/redux/app.state';

@Injectable()
export class MdlViewModelForWeb {
    constructor(private _store: Store<IAppState>) { }
}
