<div tid="note_detail_note_attachment_menu" class="tag"
    [ngClass]="config.showPopover ? 'show-popover' : 'hidden-popover'" [style.top.px]="config.el.y + 23"
    [style.right.px]="config.el.offset">
    <div class="item-tag" *ngFor="let item of config.items;trackBy:trackByFn">
        <smart-icon [name]="getIconName(item.extension)" class="file-type"></smart-icon>
        <div class="filename" [title]="item.fileName" (click)="onFileAction(item)">{{item.fileName}}</div>
        <smart-icon name="trash" class="delete" (click)="onDeleteClick(item)" *ngIf="item.deletable"></smart-icon>
        <smart-icon name="download" (click)="onDownloadItem(item)" class="download"></smart-icon>
        <smart-icon *ngIf="!isIPAD" name="link-clone" (click)="onCopyLinkClick(item)" class="copy-link"></smart-icon>
    </div>
</div>