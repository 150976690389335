/**
 * Created by Alex on 3/8/2020.
 * Description:
 *
 * ------ maintenance history ------
 * 08/11/2021 Marcus Zhao add pullingCoordinatesReducer reducer.
 */

import { GET_MAP_TILE_STATUS_FAIL, GET_MAP_TILE_STATUS_SUCCESS, PULLING_COORDINATES, PULLING_COORDINATES_FAIL, PULLING_COORDINATES_PROGRESS, PULLING_COORDINATES_SUCCESS } from './map-tile.actions';
import { createSelector } from '@ngrx/store';

export const pullingCoordinatesSelector = createSelector((state: any) => state, state => state.pullingCoordinates);

export function mapTileStatusReducer(state: any = null, action) {
    switch (action.type) {
        case GET_MAP_TILE_STATUS_SUCCESS:
        case GET_MAP_TILE_STATUS_FAIL:
            return action.payload;
        default:
            return state;
    }
}

export function pullingCoordinatesReducer(state: any = null, action) {
    switch (action.type) {
        case PULLING_COORDINATES:
        case PULLING_COORDINATES_PROGRESS:
            return 0;
        case PULLING_COORDINATES_SUCCESS:
        case PULLING_COORDINATES_FAIL:
            return 1;
        default:
            return 0;
    }
}
