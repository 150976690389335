/**
 * Created by Abner Sui on 06/03/2020
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BottomSlideSheetService {

    bottomSlideSheetActionSubject$: Subject<{ type: string, payload?: any }> = new Subject();

}
